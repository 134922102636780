import React, { useEffect, useState } from "react";
import useDocumentTitle from "../../../hooks/use-document-title";
import { useTranslation } from "react-i18next";
import "./AmbassadorPaymentPage.scss";
import { Button } from "../../../components/form/button";
import { Message, Loader } from "semantic-ui-react";
import { API_AMBASSADOR_PAYMENT, API_STAT_ROADTRIP, API_AMBASSADOR_PAYMENT_MAIL } from "../../../utils/api";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';

import { useSelector } from "react-redux";
import useRequest from "../../../hooks/use-request";
import ModalForm from "../../../components/formModal/ModalForm";




const AmbassadorPaymentPage = () => {
  const {t} = useTranslation(["henriTrip"])
  useDocumentTitle(t("ambassador.paymentPage.documentTitle"));
  const {user} = useSelector(state=> state.user)
  const [handleRequest] = useRequest();
  const config = useSelector(state => state.data);
  const [solde , setSolde] = useState(0);
  const [roadTripData, setRoadTripData] = useState();
  const [open, setOpen] = useState(false);
  const [error, setError] = useState();
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);

  const [selected, setSelected] = useState(10);

  const handleChange = event => {
    setSelected(event.target.value);
  };

  const handleSubmit = () => {
    setLoading(true);
    const data = {
      currentBalance: currentBalance,
      amount: selected
    }
      handleRequest("post", API_AMBASSADOR_PAYMENT_MAIL, data, config, (data, status) => {
        if (status === "Done") {
          setOpen(false);
          setSuccess(true);
          setLoading(false);
        } else {
          console.error(data)
        }
      })
  }

  useEffect(() => {
    handleRequest("get", API_AMBASSADOR_PAYMENT, {}, config, (data, status) => {
      if (status === "Done") {
        if(data) {
          setSolde(data[0].sum);
        }
      }else {
        console.error(data)
      }
    })
    // eslint-disable-next-line
  }, [])


  useEffect(() => {
    handleRequest("get", API_STAT_ROADTRIP("all"), {}, config, (data, status) => {
      if (status === "Done") {
        if(data) {
          setRoadTripData((roadTripData) => {
              return data.map(el=> {
                return {
                  ...roadTripData,
                  vues: el.count,
                  gains: (el.count * user.ambassador_rate)/1000
                }
              })
            }
          )
        }
      }else {
        console.error(data)
      }
    })
    // eslint-disable-next-line
  }, [])

  let total = 0;
  roadTripData?.map(el => total += el.gains)

  let currentBalance = total - solde;


  const checkCurrentBalance = () => {
    if(currentBalance > 10) {
      setError(false)
      setOpen(true)
    } else {
      setError(true)
    }
  }

  return (
    <>
      <div className="container">
        <div className="row mt-3">
          <div className="col-12 col-lg-9">
            <h1>{t("ambassador.paymentPage.title")}</h1>
          </div>
        </div>
        <div className="row mt-3">
            <div className="col">
              <h2 className="current-balance">{t("ambassador.paymentPage.currentBalance")} : <strong>{currentBalance} €</strong> </h2>
            </div>
        </div>
        <div className="row mt-3">
          <div className="col">
            <p className="explain-paragraph">{t("ambassador.paymentPage.firstTextPart1")} <strong>10 € </strong>
              {t("ambassador.paymentPage.firstTextPart2")}
            </p>
            <p className="explain-paragraph">
              {t("ambassador.paymentPage.secondText")}
            </p>
          </div>
        </div>

  {/*      <div className="row ">
          <div className="col-12">
            <UpdateContainer
              titre="PayPal"
              handleSubmit={updateChanges}
            >
              <div className="col-12">
                <Button title="PayPal"/>
              </div>

            </UpdateContainer>
          </div>
        </div>

        <div className="row ">
          <div className="col-12">
            <UpdateContainer
              titre="N° SIRET"
              handleSubmit={updateChanges}
            >
              <div className="col-12">
                <Form>
                  <Form.Input
                    onChange={setChange}
                    name="siret"
                    value={userPaymentInfo.siret}
                    label={"SIRET"}
                    placeholder={userPaymentInfo.siret}
                  />
                </Form>
              </div>
            </UpdateContainer>
          </div>
        </div>*/}
        <Button className="mt-2" title={t("ambassador.paymentPage.button")} onClick={checkCurrentBalance}/>
        {
          error ?
            <Message negative>
              <Message.Header>{t("ambassador.paymentPage.errorHeader")}</Message.Header>
              <p>{t("ambassador.paymentPage.errorText")}</p>
            </Message>
          :
            <ModalForm
              isOpen={open}
              title={t("ambassador.paymentPage.modalTitle")}
              onClose={() => setOpen(false)}
            >
              <div className="p-3">
                <FormControl>
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                    onChange={handleChange}
                  >
                    <FormControlLabel value="10" control={<Radio />} label="10 €" />
                    {
                      currentBalance > 20 ?
                        <FormControlLabel value="20" control={<Radio />} label="20 €" />
                        :
                        <FormControlLabel value="disabled" disabled control={<Radio />} label="20 €"/>
                    }
                    {
                      currentBalance > 30 ?
                        <FormControlLabel value="30" control={<Radio />} label="30 €" />
                        :
                        <FormControlLabel value="disabled" disabled control={<Radio />} label="30 €"/>
                    }
                    {
                      currentBalance > 40 ?
                        <FormControlLabel value="40" control={<Radio />} label="40 €" />
                        :
                        <FormControlLabel value="disabled" disabled control={<Radio />} label="40 €"/>
                    }
                    {
                      currentBalance > 50 ?
                        <FormControlLabel value="50" control={<Radio />} label="50 €" />
                        :
                        <FormControlLabel value="disabled" disabled control={<Radio />} label="50 €"/>
                    }
                    {
                      currentBalance > 100 ?
                        <FormControlLabel value="100" control={<Radio />} label="100 €" />
                        :
                        <FormControlLabel value="disabled" disabled control={<Radio />} label="100 €"/>
                    }
                  </RadioGroup>
                  {
                    loading ?
                      <Loader active className="mt-4" />
                      :
                      <Button className="mt-2" title={t("ambassador.paymentPage.modalButton")} onClick={handleSubmit}/>
                  }
                </FormControl>
              </div>
            </ModalForm>
        }
        {
          success ?
            <Message positive>
              <Message.Header>{t("ambassador.paymentPage.successHeader")}</Message.Header>
              <p>{t("ambassador.paymentPage.successText")}</p>
            </Message>
            :
            <></>
        }
      </div>
    </>
  );
};

export default AmbassadorPaymentPage;