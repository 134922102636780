import NavbarPage from 'pages/HeaderPage/NavbarPage';
import React from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import i18next from "i18next";


const Navbar = () => {
  const { t } = useTranslation(["henriTrip"])

  const pages = [
    {
      id: 1,
      title: "Rejoindre la communauté",
      pageTitle: "Henri | Rejoindre la communauté",
      href: "/register",
      separator: true,
      account: true
    },
    {
      id: 2,
      title: "Road Trips",
      pageTitle: "Henri | Road Trips",
      href: "/roadtrips",
      separator: true,
      roadtrips: true
    },
    {
      id: 3,
      title: t("general.travelTools.title"),
      pageTitle: "Henri | Outils voyage",
      separator: true,
      outils: true
    },
    {
      id: 4,
      title: t("general.henriPro"),
      href: "https://pro.henritrip.fr",
      dynamic: false,
      separator: false,
      active: false,
      extern: true
    },
  ]

  const location = useLocation()
  const pageLinks = React.useMemo(() => pages.map(el => ({
    ...el,
    active: el.href === location.pathname,
    href: el.dynamic ? (location.pathname === "/" ? "" : location.pathname) + el.href : el.href
    // eslint-disable-next-line
  })), [location, i18next.language]);
  const { user } = useSelector(state => state.user);


  const hrefUser = React.useMemo(() => {
    if (user.id) {
      return "/user/account";
    }

    return "/login";
  }, [user.id])


  return (
    <NavbarPage location={location} pages={pageLinks} hrefUser={hrefUser} />
  );
};

export default Navbar;