import React, {useState} from "react";
import SliderContainerWithArrowNavigation from "../../../../_ui/_v2/components/SliderContainerWithArrowNavigation";
import {API_ORIGINAL_BLOB_URL} from "../../../../utils/api";
import VideoTitleImageForm from "./VideoTitleImageForm";
import styled from "styled-components";
import { ReactComponent as AddIcon } from '../../../../assets/img/logo/addIcon.svg';

const CardVideo = styled.div `
  width: 80px;
  margin: auto;
  height: 80px;
  display: flex;
  background-image: url(${props => props.url});
`;

const VideoUploadContainer = styled.div`
  background-image: ${props => props.backgroundImageUrl ? `url('${props.backgroundImageUrl}')` : 'none'};
  background: ${props => props.backgroundImageUrl ? '' : 'linear-gradient(203.51deg, rgba(255, 255, 255, 0.3) 1.43%, rgba(255, 255, 255, 0.045) 103.65%)'};
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 10px;
  
  ${props => !props.backgroundImageUrl && `
     border: 0.669205px solid #FFFFFF;
  `}
`;

const VideoTitleImageArray = ({onLayoutItemChange,videoTitleImageArray, onSuccess, activeStorageServiceName, id, onDelete}) => {
  const [loading, setLoading] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0);

  const handleLayoutItemChange = (layoutItemIndex, layoutItem, medias, callbacks = {}) => {
    const {onError, onSuccess} = callbacks;
    const newItems = videoTitleImageArray?.items ? [...videoTitleImageArray.items].map((item, index) => index === layoutItemIndex ? layoutItem : item) : [];
    if (layoutItemIndex >= (videoTitleImageArray.items?.length || 0)) {
      newItems.push(layoutItem);
    }
    const newLayoutItem = {type: 'VideoTitleImageArray', items: newItems};
    onLayoutItemChange(
      newLayoutItem,
      medias,
      {
        onSuccess: (response) => {
          onSuccess?.(response);
          setLoading(false);
        },
        onError: (error) => {
          console.error(error);
          onError?.(error);
          setLoading(false);
        },
      }
    );
  };

  const onDeleteItem = (itemIndex, callbacks = {}) => {
    const {onError, onSuccess} = callbacks;
    const newItems = [...videoTitleImageArray.items];
    newItems.splice(itemIndex, 1);
    const newLayoutItem = {type: 'VideoTitleImageArray', items: newItems};
    onLayoutItemChange(
      newLayoutItem,
      [],
      {
        onSuccess: (response) => {
          onSuccess?.(response);
          setLoading(false);
        },
        onError: (error) => {
          console.error(error);
          onError?.(error);
          setLoading(false);
        },
      }
    );
  };

  const videoTitleImage = videoTitleImageArray?.items?.[selectedIndex];
  return(
    <>
      <VideoTitleImageForm
        id={id}
        videoTitleImage={videoTitleImage ||{}}
        onLayoutItemChange={(...args) => handleLayoutItemChange(selectedIndex, ...args)}
        activeStorageServiceName={activeStorageServiceName}
        onDelete={
          videoTitleImageArray?.items?.length > 1 ?
            () => {
              onDeleteItem(selectedIndex)
            }
            : onDelete
        }
      />
      <SliderContainerWithArrowNavigation
        // onSelectedIndexChange={setSelectedIndex}
        // indexSelected={selectedIndex}
        style={{position: "relative", height: '7em', width: '60%'}}
      >
        {
          (videoTitleImageArray?.items || [])?.map((video, index) => (
            <CardVideo
              style={{flexDirection: 'row'}}
              onClick={() => setSelectedIndex(index)}
            >
              <VideoUploadContainer
                backgroundImageUrl={(video?.items[2]?.content?.signed_id ? API_ORIGINAL_BLOB_URL(video?.items[2]?.content?.signed_id, video?.items[2]?.content?.filename) : video?.items[2]?.image?.url ? video?.items[2]?.image?.url : '')}
                style={{border: "none"}}
              >
                <CardVideo
                  style={{
                    alignItems: 'flex-end',
                    justifyContent: 'center'
                  }}
                >
                  <span>#{index + 1}</span>
                </CardVideo>
              </VideoUploadContainer>
            </CardVideo>
          )).concat([
            <CardVideo
              style={{flexDirection: 'row'}}
              onClick={() => setSelectedIndex(videoTitleImageArray?.items?.length + 1 || 0)}
            >
              <VideoUploadContainer
                style={{border: "none"}}
              >
                <CardVideo
                  style={{
                    alignItems: 'center',
                    justifyContent: 'center'
                  }}
                >
                  <AddIcon
                    height='30'
                    width='30'
                  />
                </CardVideo>
              </VideoUploadContainer>
            </CardVideo>
          ])}
      </SliderContainerWithArrowNavigation>
      <hr style={{borderTop: '1px grey solid', width: '70%'}}></hr>
    </>
  );
};

export default VideoTitleImageArray;