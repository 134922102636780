import React from 'react';
import SVG from 'react-inlinesvg';
import {toAbsoluteUrl, transformSmallImageURL} from 'utils/utils';

import categoryIcons from 'utils/categories';
import Feedback from 'components/informations/feedback/feedback';
import { Link } from 'react-router-dom';

import "./touristicSiteCard.scss";
import ImgLazy from "../../Lazyload/ImgLazy/ImgLazy";

const TouristicSiteCard = ({openTsModal,touristicSite}) => {
  const color = touristicSite.category?.color || "#EF7E26";
  const id = touristicSite.category?.id;

  return (
    <div className="touristic-card">
      <div className="touristic-card bg-white shadow-lg row m-0 no-gutter position-relative d-flex flex-column" >
        <div className="h-100 p-0">
          <ImgLazy
            small
            errorImg="https://general-henri-1-paris.s3.fr-par.scw.cloud/small-woman.jpg"
            src={transformSmallImageURL(touristicSite.image)}
            className="touristic-card--img"
            offset={1400}
            overflow={false}
            visible={true}
          />
        </div>
        {
          touristicSite.henri_certified &&
          <div className="position-absolute shadow" style={{
            top: 10,
            left: 10,
            borderRadius: "50%"
          }}>
            <SVG className="text-primary" src={toAbsoluteUrl("/assets/icons/Fichier 43.svg")} width="30" />
          </div>
        }
        <div className="p-2 d-flex flex-row">
          {
            touristicSite.custom ?
              <>
              <div style={{height: 78}}>
                {
                  categoryIcons.custom_icons?.[id]
                    ? <img width="30" height="30" className="mr-1 align-top" color={color} src={(categoryIcons.custom_icons[id])} />
                    : <SVG width="30" height="30" className="mr-1 align-top" color={color} src={toAbsoluteUrl(categoryIcons.icons[color])} />
                }
              </div>
              <div style={{cursor: 'pointer'}} onClick={() => openTsModal && openTsModal(touristicSite)}>
                <h6 className="m-0 text-dark font-weight-bold" >
                  {touristicSite.title_fr}
                </h6>
                <p className="m-0 font-weight-medium touristic-card--address" data-toggle="tooltip" title={touristicSite.city}>{touristicSite.city} {touristicSite.zipcode}</p>
                <Feedback className="p-0" size="sm" note={touristicSite?.feedbacks?.note} feedbacks={touristicSite?.feedbacks?.len} />
              </div>
              </>
              :
              <>
                <div>
                  {
                  categoryIcons.custom_icons?.[id]
                    ? <img width="30" height="30" className="mr-1 align-top" color={color} src={(categoryIcons.custom_icons[id])} />
                    : <SVG width="30" height="30" className="mr-1 align-top" color={color} src={toAbsoluteUrl(categoryIcons.icons[color])} />
                  }
                </div>
                <div>
                  <Link className="touristic-card--title" target="_blank" to={"/touristic-site/" + touristicSite.id} data-toggle="tooltip" title={touristicSite.title_fr}>
                    <h6 className="m-0 text-dark font-weight-bold">
                      {touristicSite.title_fr}
                    </h6>
                  </Link>
                  <p className="m-0 font-weight-medium touristic-card--address" data-toggle="tooltip" title={touristicSite.city}>{touristicSite.city} {touristicSite.zipcode}</p>
                  <Feedback className="p-0" size="sm" note={touristicSite?.feedbacks?.note} feedbacks={touristicSite?.feedbacks?.len} />
                </div>
              </>
          }
        </div>
      </div>
    </div>
  );
};

export default TouristicSiteCard;
