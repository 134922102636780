import React from 'react';

const SelectorItem = ({title, id, isSelected, onSelect}) => {
    const color = isSelected ? "dark" : "muted";

    return (
        // eslint-disable-next-line
        <a className="py-2 col-6 p-0 d-flex align-items-between justify-content-between flex-column w-100" onClick={() => onSelect(id)}>
            <small className={`${isSelected ? "font-weight-bold" : ""} text-${color} mb-1`}>{title}</small>
            <div className={`bg-${color} w-100`} style={{height: 5}}></div>
        </a>
    )
}

const SelectorInline = ({options, onSelect, selection}) => {
    return (
        <div className="row no-gutter m-0">
            {
                (options && Array.isArray(options) && options.length) ?
                options?.map(el => <SelectorItem key={el.id} {...el} isSelected={el.id === selection} onSelect={onSelect} />)
                :
                <p className="col-12">Pas d'options</p>
            }
        </div>
    );
};

export default SelectorInline;