import React from 'react';
import './appointmentsCard.scss'
import {Icon} from "semantic-ui-react";
import {useTranslation} from "react-i18next";

const AppointmentsCard = ({info}) => {
  const {t} = useTranslation(["henriTrip"])

  function toHoursAndMinutes(totalMinutes) {
    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;
    if (hours === 0){
      return `${minutes > 0 ? ` ${minutes}m` : ''}`;
    }
    return `${hours}h${minutes > 0 ? ` ${minutes}m` : ''}`;
  }
  return(
    <div className="appointments-card shadow-lg ">
      <div className="w-100 py-1 px-2 d-flex flex-nowrap h-auto" style={{backgroundColor: new Date(info.start_date) > new Date() ? "#107aca" : "#2b4660"}}>
        <Icon name="calendar outline" className="icon m-0 p-0" />
        <p className="text-white m-0 mr-2 ml-1 p-0">{new Date(info.start_date).toLocaleDateString('fr-FR',{day: "numeric", month: "long", year: "numeric"})}</p>
        <Icon name="time outline" className="icon m-0 p-0" />
        <p className="text-white m-0 ml-1 p-0">{new Date(info.start_date).toLocaleTimeString('fr-FR', {hour: 'numeric', minute: 'numeric',})}</p>
      </div>
      <div className="w-100 py-1 px-2 d-flex flex-nowrap flex-column h-auto">
        <p className="font-weight-bold m-0 p-0">{t("general.category.activity")}</p>
        <p className="m-0 p-0 ml-1">{info.ticket.title}</p>
        <p className="m-0 p-0 ml-1">{parseFloat(info.ticket.price).toFixed(2)}€</p>
        <p className="m-0 p-0 ml-1">{toHoursAndMinutes(info.ticket.duration)}</p>
      </div>
      <div className="w-100 py-1 px-2  pb-2 d-flex flex-nowrap flex-column h-auto">
        <p className="font-weight-bold m-0 p-0 text-capitalize">{t("feedbacks.touristicSite")}</p>
        <p className="m-0 p-0 ml-1">{info.touristic_site.title}</p>
        <p className="m-0 p-0 ml-1">{info.touristic_site.email}</p>
        <p className="m-0 p-0 ml-1">{info.touristic_site.phone_number}</p>
        <p className="m-0 p-0 ml-1">{info.touristic_site.address}</p>
      </div>
    </div>
  )
}

export default AppointmentsCard;