import React from "react";
import Title from 'components/header/title/title';
import './PartnerSectionsServices.scss';
import { useTranslation } from "react-i18next";
import { Button } from "../../../components/form/button";

const PartnerSectionsServices = ({demo}) => {
  const {t} = useTranslation(["henriTrip"])

  const content = [{
    title: t("partner.servicesSections.accompaniment.title"),
    description: t("partner.servicesSections.accompaniment.text"),
    image: "https://general-henri-1-paris.s3.fr-par.scw.cloud/partenaire_page/accompagnement.png"
  },
  {
    title: t("partner.servicesSections.migration.title"),
    description: t("partner.servicesSections.migration.text"),
    image: "https://general-henri-1-paris.s3.fr-par.scw.cloud/partenaire_page/migration.png"
  },
  {
   title: t("partner.servicesSections.security.title"),
   description: t("partner.servicesSections.security.text"),
   image: "https://general-henri-1-paris.s3.fr-par.scw.cloud/partenaire_page/securit%C3%A9.png"
  },
  {
      title: t("partner.servicesSections.partners&info.title"),
      description: t("partner.servicesSections.partners&info.text"),
      image: "https://general-henri-1-paris.s3.fr-par.scw.cloud/partenaire_page/integration.png"
  },
  {
   title: t("partner.servicesSections.seasonality.title"),
   description: t("partner.servicesSections.seasonality.text"),
   image: "https://general-henri-1-paris.s3.fr-par.scw.cloud/partenaire_page/saisonnalit%C3%A9.png"
  },
  ]

  return (
    <div className="container">
      <div className="row d-flex justify-content-center text-center">
        {
          demo ?
            <Title title="Nos garanties"/>
            :
            <Title title={t("partner.servicesSections.title")}/>
        }

      </div>
      <div className="container_services">
        <div className="row m-auto">
          {
            content.map((el) =>
              <div className="col-12 col-lg-2 mt-1 mb-2 mr-lg-auto ml-lg-auto">
                <div className="row mt-1 d-flex justify-content-center">
                  <img src={el.image} alt="" className="image_style"/>
                </div>
                <div className="row mt-1 d-flex justify-content-center text-center">
                  <h4>{el.title}</h4>
                </div>
                {
                  demo ?
                    <></>
                    :
                    <div className="row mt-1 mr-auto ml-auto d-flex justify-content-center text-center">
                      <p>{el.description}</p>
                    </div>
                }

              </div>
            )
          }
        </div>
      </div>
      {
        demo ?
          <></>
          :
          <div className="row mt-2 d-flex justify-content-center">
            <Button  title={t("partner.requestDemo")} onClick={() => window.document.getElementById('searchBar').scrollIntoView({block: "center"})}/>
          </div>
      }
    </div>
  );
};

export default PartnerSectionsServices;