import React, { useState } from "react";
import { Button } from "components/form/button";
import { Form, Message, Modal } from "semantic-ui-react";
import ProfileForm from "./registerForms/ProfileForm";
import LogForm from "./registerForms/LogForm";
import BaseRegisterForm from "./registerForms/BaseRegisterForm";
import { useTranslation } from "react-i18next";

const PrevButton = ({ step, prev }) => {
  const {t} = useTranslation(["henriTrip"])
  return (
      <Button
        color="henri"
        onClick={prev}
        title={t("register.previous")}
        disabled={step === 0}
      />
    )
}


const NextButton = ({ step, handleSubmit, isDisabled, next }) => {
  const {t} = useTranslation(["henriTrip"])
  if(step === 1) {
    return (
      <Button
        color="henri"
        onClick={next}
        title={t("register.next")}
        disabled={isDisabled()}
      />
    )
  }else if(step === 2){
    return(
      <Button
        color="henri"
        onClick={handleSubmit}
        disabled={isDisabled()}
        title={t("general.register")}
      />
    )
  }
  return null
}

const convertDate = (date) => {
  return date.getFullYear() + '-' + String(date.getMonth() + 1).padStart(2, '0') + '-' + String(date.getDate()).padStart(2,'0');
}

const RegisterModal = ({ handleSubmit, handleFacebookSubmit, handleGoogleSubmit, error, success }) => {
  const {t} = useTranslation(["henriTrip"])
  const [state, setState] = useState({
    firstname: "",
    lastname: "",
    username: "",
    email: "",
    password: "",
    password_confirmation: "",
    phone: "",
    birthdate: "",
    gender: "",
    statement: false,
    newsletter: false,
    profile_photo: [],
  });
  const [captcha, setCaptcha] = useState(false)
  const [step, setStep] = useState(0)
  const [correct, setCorrect] = useState({
    birthdate: true,
    username: true,
    email: true,
    password: true,
    password_confirmation: true,
  })

  const today = new Date();
  const minDate = new Date(1900, 1, 1);

  const _next = () => {
    if(step === 0) {
      setStep(1);
    }else {
      if (state.birthdate < convertDate(minDate) || state.birthdate > convertDate(today)) {
        setCorrect({...correct, birthdate: false });
      } else {
        setCorrect({...correct, birthdate: true });
        setStep(step >= 2 ? 3 : step + 1)
      }
    }
  }

  const _prev = () => {
    setStep( step - 1)
  }

  const isDisabled = () => {
    switch (step) {
      case 1:
        return !(state.firstname !== "" && state.lastname !== "" && state.gender && state.birthdate)
      case 2:
        return !(state.username !== "" && state.email !== "" && state.password !== ""
          && state.password_confirmation !== "" && captcha && state.statement )
      default:
        return false
    }
  }

  const handleChange = (e, { name, value }) => {
    if(name === "captcha"){
      setCaptcha(value)
    } else if(name === "statement") {
      setState({...state, statement: !state.statement})
    } else if(name === "newsletter"){
      setState({...state, newsletter: !state.newsletter})
    } else {
      setState({ ...state, [name]: value })
      setCorrect({...correct, [name]: true})
      if(error[name]) error[name] = null
    }
  }

  const submit = () => {
    const usernameCorrect = state.username.match(/^([A-Za-z0-9_]){4,64}$/);
    const emailCorrect = state.email.match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
    const passwordCorrect = !(state.password.length < 8 || !state.password.match(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$:%^&;,{}<>=|~.*_+-]).{8,70}$/));
    const confirmPassword = state.password === state.password_confirmation;

    if(usernameCorrect && emailCorrect && passwordCorrect && confirmPassword){
      handleSubmit(state)
    } else {
      setCorrect({
        ...correct,
        username: usernameCorrect,
        email: emailCorrect,
        password: passwordCorrect,
        password_confirmation: confirmPassword,
      });
    }
  }


  return(
    <Modal.Content className={"px-4"}>
      <Form success={success}>
        {
          success ?
            <Message
              success
              header={t("register.successInscription.header")}
              content={t("register.successInscription.content")}
            />
            :
            <>
              <BaseRegisterForm
                currentStep={step}
                nextStep={_next}
                handleFacebookSubmit={handleFacebookSubmit}
                handleGoogleSubmit={handleGoogleSubmit}
              />
              <ProfileForm
                currentStep={step}
                nextStep={_next}
                prevStep={_prev}
                inputChange={handleChange}
                firstname={state.firstname}
                lastname={state.lastname}
                birthdate={state.birthdate}
                birthdateCorrect={correct.birthdate}
                gender={state.gender}
              />
              <LogForm
                error={error}
                currentStep={step}
                inputChange={handleChange}
                prevStep={_prev}
                nextStep={_next}
                username={state.username}
                email={state.email}
                password={state.password}
                statement={state.statement}
                newsletter={state.newsletter}
                password_confirmation={state.password_confirmation}
                correct={correct}
              />
              {
                Object.keys(JSON.parse(error?.text || "{}")).length > 0 && (
                  <ul>
                    {
                      Object.keys(JSON.parse(error?.text || "{}"))?.map((key, index) => (
                        <li index={index} style={{color: 'red'}}>
                          {key} : {JSON.parse(error?.text || "{}")[key].join(', ')}
                        </li>
                      ))
                    }
                  </ul>
                )
              }

              <Form.Group>
                {
                  step > 0 &&
                  <Form.Field>
                    <PrevButton prev={_prev} step={step} />
                  </Form.Field>
                }
                <Form.Field>
                  <NextButton
                    next={_next}
                    handleSubmit={submit}
                    isDisabled={isDisabled}
                    step={step}
                  />
                </Form.Field>
              </Form.Group>
            </>
        }
      </Form>
    </Modal.Content>
  )
}

export default RegisterModal;
