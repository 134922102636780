import styled from 'styled-components';

const PlusIcon = styled.span`
  --cross-color: ${props => props.theme.colors.white};
  
  display: inline-block;
  width: 20px;
  height: 20px;
  background-image:
          linear-gradient(45deg, var(--cross-color) 50%, var(--cross-color) 50%),
          linear-gradient(45deg, var(--cross-color) 50%, var(--cross-color) 50%);
  background-position:
          50% 50%,
          50% 50%;
  background-size:
          15% 70%,
          70% 15%;
  background-repeat: no-repeat;
  background-color: ${props => props.theme.colors.primary};
  border-radius: 50%;
  box-sizing: border-box;
  overflow: hidden;
  cursor: pointer;
  vertical-align: bottom;
`;

export default PlusIcon;