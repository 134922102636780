import React from 'react';
import Button from "../Button";
import FacebookLogin from "@greatsumini/react-facebook-login";

const FacebookLoginButton = ({ onLoginSuccess, onError }) => {
  const styles = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '1rem 0',
  };
  return (
    <FacebookLogin
      appId={process.env.REACT_APP_FACEBOOK_LOGIN_APP_ID}
      initParams={{
        version: 'v12.0'
      }}
      fields={"first_name, last_name, email, picture"}
      onFail={(error) => {
        console.log('Login Failed!', error);
        onError(error)
      }}
      onSuccess={(response) => {
        onLoginSuccess(response)
      }}
      dialogParams={{ response_type: 'code' }}
      render={({ onClick }) =>
        <Button
          style={styles}
          onClick={onClick}
        >
          <span
            style={{marginRight: '0.5rem'}}
          >
            <svg width="24" height="24" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M9.20932 0.0463867C4.52824 0.0463867 0.733582 3.84104 0.733582 8.52212C0.733582 13.2032 4.52824 16.9979 9.20932 16.9979C13.8904 16.9979 17.6851 13.2032 17.6851 8.52212C17.6851 3.84104 13.8904 0.0463867 9.20932 0.0463867ZM11.217 5.90347H9.943C9.79202 5.90347 9.62428 6.10212 9.62428 6.36611V7.28608H11.2179L10.9769 8.59805H9.62428V12.5366H8.12072V8.59805H6.75665V7.28608H8.12072V6.51443C8.12072 5.40729 8.88883 4.50763 9.943 4.50763H11.217V5.90347V5.90347Z" fill="white"/>
            </svg>
          </span>
          Se connecter avec Facebook
        </Button>
      }
    />
  );
};

export default FacebookLoginButton;