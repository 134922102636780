import {forwardRef, useRef, useState} from "react";
import styled from "styled-components";
import {breakpoint} from "styled-components-breakpoint";

import InformationFlipCard from "../../../_ui/_v2/components/InformationFlipCard";

import { ReactComponent as ActivityEditIcon } from "../../../../src/assets/img/logo/InformationFlipCard/activity-edit-icon.svg";
import { ReactComponent as ExperienceMapIcon } from "../../../../src/assets/img/logo/InformationFlipCard/experience-map-icon.svg";
import { ReactComponent as ToolDirectionIcon } from "../../../../src/assets/img/logo/InformationFlipCard/tool-direction-icon.svg";
import { ReactComponent as HenriIcon } from "../../../../src/assets/img/logo/InformationFlipCard/henri-icon.svg";
import {useTranslation} from "react-i18next";

const InformationFlipCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  height: 100%;
  
  svg {
    width: auto;
    max-width: 100%;
    height: 100%;
    margin: auto;
  }
  
  p {
    flex-grow: 1;
    text-align: center;
  }
`;

const InformationFlipCardTextRecto = styled.p`
  font-weight: 700;
  font-size: 1em;
`;

const InformationFlipCardTextVerso = styled.p`
  font-weight: 600;
  font-size: 0.9em;
`;

const ActivityInformationFlipCard = forwardRef((props, ref) => {
  const {t} = useTranslation(['experiences']);

  return (
    <InformationFlipCard
      ref={ref}
      RectoComponent={(
        <InformationFlipCardContainer>
          <ActivityEditIcon/>
          <InformationFlipCardTextRecto>
            {t("flipcards.activity.front")}
          </InformationFlipCardTextRecto>
        </InformationFlipCardContainer>
      )}
      VersoComponent={(
        <InformationFlipCardContainer>
          <HenriIcon/>
          <InformationFlipCardTextVerso>
            {t("flipcards.activity.front")}
          </InformationFlipCardTextVerso>
        </InformationFlipCardContainer>
      )}
      {...props}
    />
  );
});

const ExperienceInformationFlipCard = forwardRef((props, ref) => {
  const {t} = useTranslation(['experiences']);

  return (
    <InformationFlipCard
      ref={ref}
      RectoComponent={(
        <InformationFlipCardContainer>
          <ExperienceMapIcon/>
          <InformationFlipCardTextRecto>
            {t("flipcards.experience.front")}
          </InformationFlipCardTextRecto>
        </InformationFlipCardContainer>
      )}
      VersoComponent={(
        <InformationFlipCardContainer>
          <HenriIcon/>
          <InformationFlipCardTextVerso>
            {t("flipcards.experience.back")}
          </InformationFlipCardTextVerso>
        </InformationFlipCardContainer>
      )}
      {...props}
    />
  );
});

const ToolInformationFlipCard = forwardRef((props, ref) => {
  const {t} = useTranslation(['experiences']);

  return (
    <InformationFlipCard
      ref={ref}
      RectoComponent={(
        <InformationFlipCardContainer>
          <ToolDirectionIcon/>
          <InformationFlipCardTextRecto>
            {t("flipcards.tool.front")}
          </InformationFlipCardTextRecto>
        </InformationFlipCardContainer>
      )}
      VersoComponent={(
        <InformationFlipCardContainer>
          <HenriIcon/>
          <InformationFlipCardTextVerso>
            {t("flipcards.tool.front")}
          </InformationFlipCardTextVerso>
        </InformationFlipCardContainer>
      )}
      {...props}
    />
  );
});

const CardContainer = styled.div`
  display: inline-block;
  position: absolute;
  transform-style: preserve-3d;
  transition: transform 0.5s ease-in-out, left 0.5s ease-in-out;

  width: ${props => props.cardWidth}px;
  height: ${props => props.cardHeight}px;
  left: calc(50% - ${props => props.cardWidth / 2}px);
  transform: translateZ(${props => props.translateZ}px) translateX(${props => 100 * props.currentDirection}px);
  z-index: ${props => props.selected ? 2 : 1};

  ${breakpoint('md')`
    ${props => `
      left: calc(${50 + 30 * ((props.index + Math.floor(props.totalElements / 2)) % props.totalElements - Math.floor(props.totalElements / 2))}% - ${props.cardWidth / 2}px);
      transform: translateX(0px) scale(90%);
      z-index: 1;
      
      &:hover {
        transform: translateX(0px) scale(100%);
      }
    `}
  `}
`;

const ShadowCard = styled.div`
  position: absolute;
  inset: 0;

  display: block;
  ${breakpoint('md')`
    display: none;
  `}
`;

const CardsContainer = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  transform-style: preserve-3d;
  transition: transform 1s;
  margin: auto auto;
`;

const cards = [
  {
    Card: ActivityInformationFlipCard
  },
  {
    Card: ExperienceInformationFlipCard
  },
  {
    Card: ToolInformationFlipCard
  },
];

const InformationFlipCards = ({cards, cardWidth, cardHeight}) => {
  const [currentCard, setCurrentCard] = useState(0);
  const cardRefs = useRef([]);
  const touchStartClientX = useRef(0);

  const containerHeight = cardHeight;
  const perspective = 1000;
  const tz = Math.round( ( cardWidth / 2 ) / Math.tan( ( ( Math.PI * 2 ) / (cards.length) ) / 2 ) );

  const middlePosition = Math.floor(cards.length / 2);

  const computeDirection = index => (index + middlePosition + currentCard) % cards.length - middlePosition;

  const moveToCard = index => {
    cardRefs.current.forEach(cardRef => cardRef?.setRecto());
    setCurrentCard(prevCurrentCard =>
      (prevCurrentCard + cards.length - computeDirection(index)) % cards.length
    );
  };

  return (
    <div
      style={{
        width: '100%',
        height: containerHeight,
        position: 'relative',
        perspective: perspective,
      }}
    >
      <CardsContainer
        style={{transform: `translateZ(-${tz}px)`}}
        onTouchStart={event => touchStartClientX.current = event.touches[0].clientX}
        onTouchEnd={event => {
          if (Math.abs(event.changedTouches[0].clientX - touchStartClientX.current) < 5) {
            return;
          }
          const next = event.changedTouches[0].clientX < touchStartClientX.current ? -1 : 1;
          cardRefs.current.forEach(cardRef => cardRef?.setRecto());
          setCurrentCard(prevCurrentCard =>
            (prevCurrentCard + cards.length + next) % cards.length
          );
          event.stopPropagation();
        }}
      >
        {
          cards.map(({Card}, index) => (
            <CardContainer
              key={index}
              index={index}
              selected={index === currentCard}
              currentDirection={computeDirection(index)}
              cardWidth={cardWidth}
              cardHeight={cardHeight}
              translateZ={(index + currentCard)%cards.length === 0 ? 0 : -perspective * 0.2}
              totalElements={cards.length}
            >
              <Card
                ref={cardRef => cardRefs.current.push(cardRef)}
              />
              {
                (index + currentCard)%cards.length !== 0 && (
                  <ShadowCard
                    index={index}
                    onClick={event => {
                      moveToCard(index);
                      event.stopPropagation();
                    }}
                  />
                )
              }
            </CardContainer>
          ))
        }
      </CardsContainer>
    </div>
  );
};

const InformationFlipCardsSection = (props) => {
  const {t} = useTranslation(['experiences']);
  return (
    <div {...props}>
      <h2 className="align-center">
        HENRI TRIP&nbsp;:<br/>
        {t("flipcards.title")}
      </h2>
      <InformationFlipCards
        cards={cards}
        translation={props.translation}
        cardWidth={240}
        cardHeight={400}
      />
    </div>
  );
};

export default InformationFlipCardsSection;