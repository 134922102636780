import useSlick from "../../hooks/use-slick";
import {PHONE_WIDTH} from "../../utils/utils";
import SliderButton from "../form/slider/slider-button";
import SlickSlider from "../form/slider/slider";
import TouristicSiteElement from "../touristicSites/touristicSiteElement/TouristicSiteElement";
import React from "react";

const TouristicSiteSuggestions = ({suggestions, classNames, text}) => {
  const {data, current, onChange, slick, setCurrent, total} = useSlick(suggestions);
  return (
    <section className="overflow-hidden" style={{minHeight: 450}}>
      <div className={`d-flex justify-content-between ${classNames}`}>
        <div className="pt-2">
          <h1 className="title text-primary" style={{fontSize: 30}}>
            {text}
          </h1>
        </div>
        {
          window.innerWidth > PHONE_WIDTH ?
            <div>
              <SliderButton isInfinite current={current} total={total} onChange={onChange} />
            </div> : <></>
        }
      </div>
      <SlickSlider
        className="roadtrip-section--slider"
        slick={slick}
        handleBeforeChange={(oV,nV) => setCurrent(nV)}
        settings={{
          infinite: true,
          dots: false,
          autoplay: false,
        }}>
        {
          data.map((el, i) => <div key={i} className="pl-0 py-2 pr-2 d-flex flex-column align-items-center roadtrip-section--card">
            <TouristicSiteElement key={i} el={el}/>
          </div>)
        }
      </SlickSlider>
    </section>
  )
}

export default TouristicSiteSuggestions;