import React, { useState } from "react";
import MapGL, { Marker } from "react-map-gl";
import { ReactComponent as SvgMarker } from "../../../assets/img/marker-icons/mapbox-marker-icon-red.svg";
import mapboxgl from "mapbox-gl";
// eslint-disable-next-line import/no-webpack-loader-syntax
mapboxgl.workerClass = require("worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker").default;

const TouristicSiteMap = ({ lat, long }) => {
  return (
    <MapGL
      {...{
        latitude: lat,
        longitude: long,
        zoom: 14.0,
        bearing: 0,
        pitch: 0,
      }}
      mapStyle={"mapbox://styles/pierrefournier/ckta3rq5a6iqm17k7m3ly5gl3"}
      width="100%"
      height={400}
    >
      <Marker
        latitude={lat}
        longitude={long}
        offsetLeft={-20}
        offsetTop={-10}
      >
        <SvgMarker />
      </Marker>
    </MapGL>
  )
}

export default TouristicSiteMap;