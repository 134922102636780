import React, { useState } from "react";
import './PartnerSectionsSearch.scss';
import { useTranslation } from "react-i18next";
import AutocompletePlaceTouristicSites from "../../../components/search/autocompletesearchTouristicSites";



const PartnerSectionsSearch = () => {
  const {t} = useTranslation(["henriTrip"])

  const [address, setAddress] = useState({
    address : null
  });
  const [state, setState] = useState();

  const onSelectAddress = (address) => {
    setAddress({
      address: address.place_name,
      buttonAddressDisabled: false,
    });
  };

  const onClearAddress = () => {
    setAddress({
      address: null,
      locationIsLoading: false,
      buttonAddressDisabled: true,
    });
  };

  const onSelectTS = (id) => {
    window.open(`https://admin.henritrip.fr/auth/registration?touristic_site=${id}`)
  }

  const addressRef = React.createRef();

  return (
    <div id="searchBar"  className="container col-lg-8 col-12 bg-color-section-search mt-4 p-1">
      <div className="row">
        <div className="col pt-3">
          <h2 className="title-section-register"> {t("partner.searchSections.title")} </h2>
          <div className="overlap-group-suggest">
            <div className="adresse-gauche">
              <AutocompletePlaceTouristicSites
                className="o-allez-vous"
                placeholder={t("partner.searchSections.findActivity")}
                onSelect={onSelectAddress}
                onClear={onClearAddress}
                onSelectTS={onSelectTS}
                newAddress={address.address}
                ref={addressRef}
                onFocusInp={() => setState({focus: true})}
                onBlurInp={() => setState({focus: true})}
              />
            </div>
          </div>

        </div>
      </div>
    </div>
  );
};

export default PartnerSectionsSearch;