import React, { useState } from "react";
import TipContainer from "../../../_ui/_v2/components/TipContainer";
import {useTranslation} from "react-i18next";
import Button from "../../../_ui/_v2/components/Button/Button";

const TipsSection = ({ tips }) => {
  const [activeTip, setActiveTip] = useState();

  // const tipsParser = (tips) => {
  //   const parsed_tips = [];
  //   const tips_splitted = tips.split(/\n(?=[A-Z].*:\n)/);

  //   tips_splitted.forEach(tip => {
  //     let tmp = tip.split(':\n').map(e => e.trim());
  //     parsed_tips.push({
  //       title: tmp[0],
  //       content: tmp[1]
  //     });
  //   })
  //   return parsed_tips
  // };

  const data = tips || [];
  const {t, i18n} = useTranslation(["touristic-site"]);
  return (
    data.length <= 0
      ? <p>{t("tips.notice")}</p>
      : data.map((tip, index) => (
        <div
          key={index}
          style={{marginBottom: 20, cursor: 'pointer'}}
        >
          <TipContainer
            id={index}
            collapsed={activeTip === index}
            setActiveTip={setActiveTip}
            HeaderComponent={(
              <h4 style={{
                margin: 0,
                fontWeight: 600,
                fontSize: '20px',
              }}>
                {tip.title[`text_${i18n.language}`] || tip.title.text_fr}
              </h4>
            )}
          >
            <p
              className="small"
              style={{
                textAlign: "left",
                whiteSpace: "pre-wrap",
              }}
            >
              {tip.description[`text_${i18n.language}`] || tip.description.text_fr}
            </p>
            {tip.link && (
              <Button
                style={{margin: '0 auto', display: 'block'}}
                rounded
                onClick={() => window.open(tip.link, '_blank')}
              >
                {t("tips.link")}
              </Button>
            )}
          </TipContainer>
        </div>
      ))
  );
};

export default TipsSection;
