import {array, number, object, string} from 'yup';
import {mediaSchema} from './touristic_site_schema';

const REQUIRED_MESSAGE = 'Champ requis';

const textSchema = object({
  text_fr: string().default('').transform((_, value) => value === null ? '' : value),
  text_en: string().default('').transform((_, value) => value === null ? '' : value),
  text_sp: string().default('').transform((_, value) => value === null ? '' : value),
  text_ge: string().default('').transform((_, value) => value === null ? '' : value),
  text_it: string().default('').transform((_, value) => value === null ? '' : value),
  text_cn: string().default('').transform((_, value) => value === null ? '' : value),
  text_sa: string().default('').transform((_, value) => value === null ? '' : value),
  text_pt: string().default('').transform((_, value) => value === null ? '' : value),
  text_jp: string().default('').transform((_, value) => value === null ? '' : value),
  text_ru: string().default('').transform((_, value) => value === null ? '' : value),
});

const visitSheetSchema = object({
  activity_id: number().positive().integer().required(REQUIRED_MESSAGE),
  title: textSchema.default({}).transform((_, value) => typeof value === 'string' ? {text_fr: value} : value),

  medias: array().of(mediaSchema).ensure().min(1).default([{}]),
});

export default visitSheetSchema;