import {string, number, object, array, boolean} from 'yup';

const REQUIRED_MESSAGE = 'Champ requis';
const TELEPHONE = /^(\+)?[0-9\s]+$/;
const URL = /^(ftp|http|https):\/\/[^ "]+$/;

const categorySchema = object({
  id: number().positive().integer().required(REQUIRED_MESSAGE),
});

/*
** TODO export mediaSchema in other file
*/
const mediaSchema = object({
  language: string().default('fr').nullable().trim().required(REQUIRED_MESSAGE).transform((_, value) => (
    value === null ? '' : value
  )),
  signed_id: string().default('').trim().required(REQUIRED_MESSAGE),
});

const mainImageSchema = object({
  type: string().default('MainImage').trim().required(REQUIRED_MESSAGE),
  content: object({
    signed_id: string().default('').trim().required(REQUIRED_MESSAGE),
  }),
});

const priceSchema = object({
  text_fr: string().default('').trim(),
  price: number().default('').min(0).required(REQUIRED_MESSAGE),
  currency: string().default('EUR').trim().required(REQUIRED_MESSAGE),
});

const tipSchema = object({
  title: object({
    text_fr: string().nullable().default('').trim().required(REQUIRED_MESSAGE),
    text_en: string().nullable().default('').trim(),
    text_sp: string().nullable().default('').trim(),
  }),
  description: object({
    text_fr: string().nullable().default('').trim().required(REQUIRED_MESSAGE),
    text_en: string().nullable().default('').trim(),
    text_sp: string().nullable().default('').trim(),
  }),
  link: string().default('').nullable().trim(),
});

const openingHoursSchema = object({
  open_morning: string(),
  close_morning: string()
    .test({
      name: 'min',
      exclusive: false,
      params: {},
      message: '${path} must be more than open hour',
      test: (value, context) => (context.parent.closed_noon ? (value > context.parent.open_morning) : value === undefined),
    }),
  closed_noon: boolean(),
  open_afternoon: string()
    .when(
      'closed_noon',
      {
        is: true,
        then: schema => schema.required(REQUIRED_MESSAGE),
        otherwise: schema => (schema),
      }
    ),
  close_night: string()
    .test({
      name: 'min',
      exclusive: false,
      params: {},
      message: '${path} must be more than open hour',
      test: (value, context) => value === undefined || value > (context.parent.closed_noon ? context.parent.open_afternoon : context.parent.open_morning),
    }),
});

const scheduleSchema = object({
  lundi: openingHoursSchema.nullable().default(undefined),
  mardi: openingHoursSchema.nullable().default(undefined),
  mercredi: openingHoursSchema.nullable().default(undefined),
  jeudi: openingHoursSchema.nullable().default(undefined),
  vendredi: openingHoursSchema.nullable().default(undefined),
  samedi: openingHoursSchema.nullable().default(undefined),
  dimanche: openingHoursSchema.nullable().default(undefined),
});

const touristicSiteSchema = object({
  title_fr: string().default('').trim().required(REQUIRED_MESSAGE),
  title_en: string().default('').nullable().trim(),
  title_sp: string().default('').nullable().trim(),
  categories: array().of(categorySchema).ensure().min(1),
  address: string().default('').nullable(true).trim(),
  latitude: number().nullable(false),
  longitude: number().nullable(false),
  duration_minutes: number().positive().integer().default('').required(REQUIRED_MESSAGE).transform((_, value) => (
    value === NaN ? '' : Number(value)
  )),
  medias: array().of(mediaSchema).ensure().min(1).default([{}]),
  description_fr: string().default('').trim().nullable().required(REQUIRED_MESSAGE).transform((_, value) => (
    value === null ? '' : value
  )),
  description_en: string().default('').nullable(),
  description_sp: string().default('').nullable(),
  price_list: array().of(priceSchema).transform((_, value) => (
    value === null
      ? [{}]
      : typeof value === 'string'
        ? JSON.parse(value)
        : value
  )).ensure().min(0).default([{}]),
  access_transport: object({
    text_fr: string().default('').nullable().notRequired().transform((_, value) => (
      value === null ? '' : value
    )),
    text_en: string().default('').nullable().notRequired().transform((_, value) => (
      value === null ? '' : value
    )),
    text_sp: string().default('').nullable().notRequired().transform((_, value) => (
      value === null ? '' : value
    )),
  }),
  specificities: object({
    text_fr: string().default('').nullable().notRequired().transform((_, value) => (
      value === null ? '' : value
    )),
    text_en: string().default('').nullable().notRequired().transform((_, value) => (
      value === null ? '' : value
    )),
    text_sp: string().default('').nullable().notRequired().transform((_, value) => (
      value === null ? '' : value
    )),
  }),
  schedule: scheduleSchema.transform((_, value) => (
    value === null
      ? {}
      : typeof value === 'string'
        ? JSON.parse(value)
        : value
  )),
  touristic_site_tips: array().of(tipSchema).ensure().min(0).default([{}]),
  ambassador_review: object({
    text_fr: string().default('').nullable().notRequired().transform((_, value) => (
        value === null ? '' : value
    )),
    text_en: string().default('').nullable().notRequired().transform((_, value) => (
      value === null ? '' : value
    )),
    text_sp: string().default('').nullable().notRequired().transform((_, value) => (
      value === null ? '' : value
    )),
  }),
  phone_number: string().default('').test('is-valid-phone', 'Le numéro de téléphone n\'est pas valide', function(value) {
    if (value && value.trim() !== '') {
      return TELEPHONE.test(value);
    }
    return true;
  }).transform((_, value) => (
    value === null ? '' : value
  )),
  website_url: string().default('').test('is-valid-url', 'Entrer un lien valide', function(value) {
    if (value && value.trim() !== '') {
      return URL.test(value);
    }
    return true;
  }).transform((_, value) => (
    value === null ? '' : value
  )),
  ticket_link: string().default('').test('is-valid-url', 'Entrer un lien valide', function(value) {
    if (value && value.trim() !== '') {
      return URL.test(value);
    }
    return true;
  }).transform((_, value) => (
    value === null ? '' : value
  )),
  agency_id: number().positive().nullable(true).transform((_, value) => (
    value === null ? null : parseInt(value)
  )),
  instagram_post_link: string().default('').test('is-valid-url', 'Entrer un lien valide', function(value) {
    if (value && value.trim() !== '') {
      return URL.test(value);
    }
    return true;
  }).transform((_, value) => (
    value === null ? '' : value
  )),
  instagram_profile_link: string().default('').test('is-valid-url', 'Entrer un lien valide', function(value) {
    if (value && value.trim() !== '') {
      return URL.test(value);
    }
    return true;
  }).transform((_, value) => (
    value === null ? '' : value
  )),
  instagram_post_image: mediaSchema.transform((_, value) => (
    value === null ? {} : value
  )),
});

export default touristicSiteSchema;

export {
  mainImageSchema,
  mediaSchema,
  priceSchema,
  tipSchema,
  openingHoursSchema,
  scheduleSchema,
};
