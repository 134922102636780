import styled from "styled-components";
import landingImage from "../../../assets/img/logo/NewHomePage/landing.png";
import buttonAppstore from "../../../assets/img/logo/NewHomePage/appstore-button.png";
import buttonGgplay from "../../../assets/img/logo/NewHomePage/ggplay-button.png";
import { ReactComponent as PictoMouseClick } from "../../../assets/img/logo/Picto-mouse-clic.svg";
import React from "react";
import breakpoint from "styled-components-breakpoint";

const MainContainer = styled.div`
  margin: 50px 0;
  height: 90vh;
  background: ${(props) => props.theme.colors.white};
  border-radius: 17px;
  display: flex;
  width: 100%;
  flex-direction: column;

  ${breakpoint("sm")`
    height: 80vh;
    width: 80vw;
    margin: 70px 0;
    flex-direction: row;
    justify-content: center;
  `}
`;

const SideContainerLeft = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  
  ${breakpoint('sm')`
     width: 50%;
	`}
`;

const SideContainerRight = styled.div`
  width: 50%;
  display: none;
  align-items: center;
  justify-content: center;

  ${breakpoint('sm')`
     display: flex;
	`}
`;

const StyledImage = styled.img`
  width: 80%;

  ${breakpoint("sm")`
    width: 120%;
  `}
`;

const TextButtonsContainer = styled.div`
  margin: 0 5%;
  width: 90%;
  height: 80%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;

  ${breakpoint("sm")`
    margin: 0 20%;
    justify-content: space-evenly;
  `}
`;

const TitleBlock = styled.div`
  font-size: 45px;
  line-height: 1.1;
  font-weight: 800;
  text-align: center;

  ${breakpoint("sm")`
    font-size: 50px;
    text-align: start;
	`}
`;

const TextBlock = styled.div`
  p {
    font-size: 18px;
    line-height: 1.4;
    font-weight: 600;
    color: ${(props) => props.theme.colors.doveGray};
  }
  text-align: center;
  ${breakpoint("sm")`
    font-size: 50px;
    text-align: start;
	`}
`;

const ButtonBlock = styled.div`
  display: flex;
  justify-content: space-between;
`;

const StyledButton = styled.a`
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.4);
  border-radius: 10px;
`;

const ButtonImage = styled.img`
  width: 150px;
`;

const PictoBlock = styled.div`
  display: flex;
  justify-content: center;
`;

const ImageBlockMobile = styled.div`
  display: flex;
  justify-content: center;

  ${breakpoint('sm')`
     display: none;
	`}
`;

const HomeSectionNew = () => (
  <MainContainer>
    <SideContainerLeft>
      <TextButtonsContainer>
        <ImageBlockMobile>
          <StyledImage src={landingImage}/>
        </ImageBlockMobile>
        <TitleBlock>
          L'information
          <br/>
          Essentielle
        </TitleBlock>
        <TextBlock>
          <p>
            Où que vous soyez,
            <br/>
            Planifier votre voyage,
            <br/>
            Votre journée...
          </p>
        </TextBlock>
        <ButtonBlock>
          <StyledButton>
            <a href="https://apps.apple.com/us/app/henri-trip-guide-interactif/id6448281751">
              <ButtonImage src={buttonAppstore}/>
            </a>
          </StyledButton>
          <StyledButton>
            <a href="https://play.google.com/store/apps/details?id=com.henritripapp">
              <ButtonImage src={buttonGgplay}/>
            </a>
          </StyledButton>
        </ButtonBlock>
        <PictoBlock>
          <PictoMouseClick/>
        </PictoBlock>
      </TextButtonsContainer>
    </SideContainerLeft>
    <SideContainerRight>
      <StyledImage src={landingImage}></StyledImage>
    </SideContainerRight>
  </MainContainer>
);

export default HomeSectionNew;
