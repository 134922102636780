import './404.scss';
import { Button } from 'components/form/button';
import React, {useEffect} from 'react';
import { useHistory } from "react-router-dom";
import useDocumentTitle from "../../hooks/use-document-title";

const RoadTripPremiumError = () => {
  const history = useHistory();
  useDocumentTitle("Oops! - Erreur 500");
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <div className="error404__main-container">
      <div className="error404__text-container">
        <p className="error404__text">Suite à une forte demande pour ce RoadTrip, cette offre n'est plus disponible...</p>
        <h1 className="error404__number">Oops!</h1>
        <div className="error404__button-container">
          <p className="error404__text">Page non trouvée</p>
          <Button className="error404__button" title="Accueil" onClick={() => history.push('/')}/>
        </div>
      </div>
    </div>
  );
};

export default RoadTripPremiumError;