import styled from "styled-components";
import breakpoint from "styled-components-breakpoint";

const ExperienceCartTicketStyled = styled.div`
  ${breakpoint('md')`
    display: table;
    width: 100%;
  `}
`;

const FirstContainer = styled.div`
  position: relative;
  background-color: ${props => props.theme.colors.white};
  width: 100%;
  margin-bottom: 15px;
  border-top-right-radius: 1rem;
  border-top-left-radius: 1rem;
  
  
  ${breakpoint('md')`
    display: table-cell;
    height: 100%;
    width: auto;
    margin-bottom: 0px;
    border: 0px solid transparent;
    background-clip: padding-box;
    border-right-width: 15px;
    border-top-right-radius: revert;
    border-bottom-left-radius: 1rem;
  `}
  
  :before {
    display: block;
    content: ' ';
    border-top: 15px solid ${props => props.theme.colors.white};
    border-left: 20px solid transparent;
    border-right: 20px solid transparent;
    width: 100%;
    position: absolute;
    bottom: -15px;

    ${breakpoint('md')`
      width: auto;
      height: 100%;
      border-top: 20px solid transparent;
      border-bottom: 20px solid transparent;
      border-left: 15px solid ${props => props.theme.colors.white};
      border-right: none;
      right: -15px;
      bottom: revert;
  `}
  }
`;

const SecondContainer = styled.div`
  position: relative;
  background-color: ${props => props.theme.colors.white};
  width: 100%;
  margin-top: 30px;
  border-bottom-right-radius: 1rem;
  border-bottom-left-radius: 1rem;

  ${breakpoint('md')`
    display: table-cell;
    width: auto;
    height: 100%;
    margin-top: 0px;
    border: 0px solid transparent;
    background-clip: padding-box;
    border-left-width: 15px;
    border-top-right-radius: 1rem;
    border-bottom-left-radius: revert;
  `}
  
  :before {
    display: block;
    content: ' ';
    border-bottom: 15px solid ${props => props.theme.colors.white};
    border-left: 20px solid transparent;
    border-right: 20px solid transparent;
    width: 100%;
    position: absolute;
    top: -15px;

    ${breakpoint('md')`
      width: auto;
      height: 100%;
      border-top: 20px solid transparent;
      border-bottom: 20px solid transparent;
      border-right: 15px solid ${props => props.theme.colors.white};
      border-left: none;
      left: -15px;
      top: 0;
  `}
  }

  :after {
    display: block;
    content: ' ';
    width: calc(100% - 20px - 20px);
    left: 20px;
    right: 20px;
    margin: 0 auto;
    border-bottom: 4px dashed black;
    position: absolute;
    top: -17px;

    ${breakpoint('md')`
      width: 0px;
      height: calc(100% - 20px - 20px);
      border-bottom: none;
      border-right: 4px dashed black;
      border-left: none;
      left: -17px;
      top: 20px;
      right: revert;
  `}
  }
`;

const ExperienceCartTicket = ({FirstComponent, SecondComponent, ...rest}) => (
  <ExperienceCartTicketStyled {...rest}>
    <FirstContainer>
      {FirstComponent}
    </FirstContainer>
    <SecondContainer>
      {SecondComponent}
    </SecondContainer>
  </ExperienceCartTicketStyled>
);

export default ExperienceCartTicket;