import { Button } from 'components/form/button';
import MyCheckbox from 'components/form/checkbox/checkboxFormik';
import FileInput from 'components/form/file-browser/fileInput';
import InputFormik from 'components/form/input/inputFormik';
import TextAreaFormik from 'components/form/text-area/textAreaFormik';
import { Form } from 'formik';
import React, { useCallback, useEffect, useState } from 'react';
import { ReactComponent as Trash } from "../../../../assets/img/logo/delete.svg";
import { TouristicSiteCard } from "../../../../components/touristicSites/touristicSiteCard";
import _ from "underscore";
import "./createRoadTripPage.scss";
import ModalForm from "../../../../components/formModal/ModalForm";
import Login from "../../../../containers/Auth/Login";
import { Modal } from 'semantic-ui-react';
import FormModal from "../../../../components/formModal/InterestForm";
import { Create as CreateIcon } from "@material-ui/icons";
import { SvgIcon } from "@material-ui/core";
import { ReactComponent as More } from "../../../../assets/img/logo/Plus.svg";
import {API_GET_TOURISTIC_SITE} from "utils/api";
import useRequest from 'hooks/use-request';
import {useSelector} from 'react-redux';

import {
  DndContext,
  KeyboardSensor,
  MeasuringStrategy,
  MouseSensor,
  PointerSensor,
  TouchSensor,
  useDroppable,
  useSensor,
  useSensors
} from '@dnd-kit/core';
import {
  arrayMove,
  rectSortingStrategy,
  SortableContext,
  sortableKeyboardCoordinates,
  useSortable
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { unstable_batchedUpdates } from 'react-dom';
import { TABLET_WIDTH, transformSmallImageURL } from "utils/utils";
import ImgLazy from "../../../../components/Lazyload/ImgLazy/ImgLazy";
import { Link } from "react-router-dom";
import SVG from "react-inlinesvg";
import { useTranslation } from "react-i18next";
import CategoriesIcons from "../../../../components/informations/categories/categoriesIcons/CategoriesIcons";
import CloseIcon from '@mui/icons-material/Close';
import {mediasTyped} from "../../../../utils/media";

const RoadTripEmpty = ({ setFieldValue }) => {
  const { t } = useTranslation(["henriTrip"])
  useEffect(() => {
    setFieldValue("touristic_sites_ids", JSON.stringify([]));
  }, [setFieldValue])
  return (
    <p className="text-muted text-center px-2 my-3">{t("roadTripPage.noTouristicSite")}</p>
  )
}

const TouristicSiteElementDraggable = ({ openModifyModal, id, el, deleteItem }) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging
  } = useSortable({ id: id })
  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    width: 310,
    position: 'relative',
    padding: window.innerWidth > TABLET_WIDTH ? "15px 10px" : '5px 10px',
    height: isDragging ? (window.innerWidth > TABLET_WIDTH ? 300 : 'auto') : 'auto',
    userSelect: "none",
    cursor: "grab",
    opacity: isDragging ? '0.5' : '1',
    touchAction: 'none'
  };

  return (
    window.innerWidth > TABLET_WIDTH ?

      <li ref={setNodeRef} style={style} {...attributes} {...listeners}>
        <div className="position-relative">
          <div className="shadow-lg delete-btn" onClick={() => deleteItem(el)}>
            <Button color="danger" title={<Trash width="18" />} />
          </div>
          {
            el.custom &&
            <div className="shadow-lg modify-btn" onClick={() => openModifyModal(el)}>
              <Button color="primary" title={<SvgIcon component={CreateIcon} width="18" />
              } />
            </div>
          }

          <TouristicSiteCard touristicSite={el} />
        </div>
      </li>
      :
      <li ref={setNodeRef} style={style} {...attributes} {...listeners}>
        <div className="bg-white shadow-lg d-flex overflow-hidden" style={{ borderRadius: '1em' }}>
          <div className="position-relative" style={{ height: 75, width: 75 }}>
            <ImgLazy
              small
              errorImg="https://general-henri-1-paris.s3.fr-par.scw.cloud/small-woman.jpg"
              src={transformSmallImageURL(el.image)}
              offset={1400}
              overflow={false}
              style={{ objectFit: 'cover', objectPosition: 'center', height: 75, width: 75 }}
            />
            <div className="shadow-lg delete-btn" onClick={() => deleteItem(el)} style={{ top: "5%", left: "5%" }}>
              <button type="button" className="delete-st-btn"><Trash width="15" /></button>
            </div>
            {
              el.custom &&
              <div className="shadow-lg modify-btn" onClick={() => openModifyModal(el)}>
                <button type="button" className="modify-st-btn" ><SvgIcon component={CreateIcon} width="15" /></button>
              </div>

            }
          </div>
          {
            el.custom ?
              <h6 className="m-0 pt-2 pl-2 text-dark font-weight-bold">
                {el.title_fr}
              </h6>
              :
              <Link className="title" target="_blank" to={"/touristic-site/" + el.id} data-toggle="tooltip" title={el.title}>
                <h6 className="m-0 pt-2 pl-2 text-dark font-weight-bold">
                  {el.title_fr}
                </h6>

              </Link>
          }


        </div>
      </li>
  )
}

const ColumnDroppable = ({ roadtrip, openModifyModal, requestRoadTrip, modal, onHide, day, handleModifyRT, col, deleteItem }) => {
  const { setNodeRef } = useDroppable({ id: day });
  const formStyle = {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    marginBottom: 10,
    cursor: 'pointer'
  }
  const [formModal, setFormModal] = useState(false)
  const openFormModal = () => {
    setFormModal(true);
  }
  const droppableStyle = {
    padding: window.innerWidth > TABLET_WIDTH ? "20px 15px" : '0',
    display: 'flex',
    justifyContent: 'start',
    alignItems: 'center',
    flexDirection: 'column'
  };
  const buttonStyle = {
    borderRadius: 30
  };

  return (
    <SortableContext
      items={col.map(e => JSON.stringify(e))}
      strategy={rectSortingStrategy}
      id={day}
    >
      <ul ref={setNodeRef} style={droppableStyle}>
        <h3 className="pb-3 text-center">Jour {day}</h3>
        {
          col.map((site) =>
            <TouristicSiteElementDraggable openModifyModal={openModifyModal} onHide={onHide} key={JSON.stringify(site)} el={site} id={JSON.stringify(site)} deleteItem={deleteItem} />
          )
        }




        <Modal
          open={formModal}
          title={"Point d'interet"}
          onClose={() => setFormModal(false)}>
          <FormModal day={day} roadtrip={roadtrip} handleModifyRT={handleModifyRT} requestRoadTrip={requestRoadTrip} modal={modal} onHide={onHide} handleSubmit={() => setFormModal(false)} handleClose={() => setFormModal(false)} />
        </Modal>
        {/* <hr noshade="true" width="290" size="3" align="right"></hr>
        <button onClick={() => openFormModal} className="buttonAdd" color="secondary"><strong style={{fontSize: '20px'}}>+</strong></button> */}
        <div style={formStyle}>
          <hr noshade="true" width="290" size="1" align="center" style={{ borderTop: '1px solid rgba(0, 0, 0, 0.1)' }}></hr>
          <Button className='add-custom-ts'
            title={<More />} onClick={openFormModal} />
        </div>

      </ul>
    </SortableContext>
  )
}

const NewDayDroppable = ({ id, day, col }) => {
  const { setNodeRef } = useDroppable({ id: id, data: day });
  const droppableStyle = {
    padding: 100,
    display: 'flex',
    justifyContent: 'start',
    alignItems: 'center',
    flexDirection: 'column',
    border: 'dashed #ccc'
  };
  return (
    <SortableContext
      items={col}
      strategy={rectSortingStrategy}
      id={id}
    >
      <ul ref={setNodeRef} style={droppableStyle}>
        <h3 className="text-center">Jour {day}</h3>
      </ul>
    </SortableContext>
  )
}
const MultiSortable = ({ handleModifyRT, requestRoadTrip, roadtrip, st, onHide, modal, setFieldValue }) => {
  const [handleRequest, status] = useRequest();
  const config = useSelector(state => state.data);
  
  const reorderState = (state) => {
    let jour = 1
    const newState = {}
    Object.keys(state).forEach((key) => {
      if (state[key].length !== 0) {
        let newArr = []
        state[key].forEach((x, index) => {
          let exist = false
          newArr.forEach(e => {
            if (e.id === x.id) {
              exist = true
            }
          })
          if (!exist) {
            newArr.push({
              ...x,
              "day_number": jour,
              "rank": index + 1
            })
          }
        })
        newState[jour.toString()] = [...newArr]
        jour++
      }
    });
    return newState
  }
  const [sites, setSites] = useState(reorderState(st))
  const sensors = useSensors(
    useSensor(MouseSensor, {
      activationConstraint: { delay: 85, tolerance: 5 }
    }),
    useSensor(PointerSensor, {
      activationConstraint: { delay: 85, tolerance: 5 }
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates
    }),
    useSensor(TouchSensor, {
      activationConstraint: { delay: 85, tolerance: 5 }
    })
  );

  useEffect(() => {
    const newArray = []
    Object.keys(sites)?.forEach(day => {
      const arr = sites[day]
      arr.forEach(el => {
        newArray.push({
          touristic_sites_id: el.id,
          day_number: el.day_number,
          rank: el.rank
        })

      })
    })
    setFieldValue("touristic_sites_ids", JSON.stringify(newArray));
  }, [sites, setFieldValue])

  const removeAtIndex = (array, index) => {
    return [...array.slice(0, index), ...array.slice(index + 1)]
  };

  const insertAtIndex = (array, index, item) => {
    return [...array.slice(0, index), item, ...array.slice(index, array.length)]
  };

  const moveBetweenContainers = (
    items,
    activeContainer,
    activeIndex,
    overContainer,
    overIndex,
    item
  ) => {
    return {
      ...items,
      [activeContainer]: removeAtIndex(items[activeContainer], activeIndex),
      [overContainer]: insertAtIndex(items[overContainer], overIndex, item)
    };
  };

  const handleDragOver = (event) => {
    const { over, active } = event
    const overId = over?.id;

    if (!overId) {
      return;
    }
    const activeContainer = active?.data?.current?.sortable?.containerId;
    const overContainer = over?.data?.current?.sortable?.containerId || overId;

    if (!overContainer || overContainer === 'newDay') {
      return;
    }

    if (activeContainer !== overContainer) {
      setSites((items) => {
        const activeIndex = active.data.current.sortable.index;
        const overIndex = over.data.current?.sortable.index || -1;


        // const isBelowLastItem =
        //   over &&
        //   overIndex === items[overContainer].length - 1 &&
        //   delta.y > over.rect.offsetTop - over.rect.height;
        //
        //
        // const modifier = isBelowLastItem ? 1 : 0;
        //
        // const newIndex = overIndex >= 0 ? overIndex + modifier : items[overContainer].length + 1;

        const isBelowOverItem =
          over &&
          active.rect.current.translated &&
          active.rect.current.translated.top >
          over.rect.top + over.rect.height;

        const modifier = isBelowOverItem ? 1 : 0;

        const newIndex = overIndex >= 0 ? overIndex + modifier : items[overContainer].length + 1;

        return moveBetweenContainers(
          items,
          activeContainer,
          activeIndex,
          overContainer,
          newIndex,
          findItem(active.id)
        );
      });
    }
  }

  const handleDragEnd = ({ active, over }) => {
    if (!over) {
      return;
    }
    if (over.id === 'newDay') {
      const activeContainer = active.data.current.sortable.containerId;
      const overContainer = over.data.current.day;
      const activeIndex = active.data.current.sortable.index;
      const overIndex = 0;
      setSites((items) => {
        const newItems = {
          ...items,
          [over.data.current.day]: []
        }
        const movedItems = moveBetweenContainers(
          newItems,
          activeContainer,
          activeIndex,
          overContainer,
          overIndex,
          findItem(active.id)
        );
        return reorderState(movedItems);
      })
      return
    }
    if (active.id !== over.id) {
      const activeContainer = active.data.current.sortable.containerId;
      const overContainer = over.data.current?.sortable.containerId;
      const activeIndex = active.data.current.sortable.index;
      const overIndex = over.data.current?.sortable.index || 0;

      if (!activeContainer || !overContainer) {
        return;
      }
      setSites((items) => {
        let newItems;
        if (activeContainer === overContainer) {
          newItems = {
            ...items,
            [overContainer]: arrayMove(
              items[overContainer],
              activeIndex,
              overIndex
            )
          };
        } else {
          newItems = moveBetweenContainers(
            items,
            activeContainer,
            activeIndex,
            overContainer,
            overIndex,
            findItem(active.id)
          );
        }
        return reorderState(newItems);
      });
      return
    }
    unstable_batchedUpdates(() => {
      setSites((items) => {
        return reorderState(items)
      })
    })
  }

  const deleteItem = useCallback((el) => {
    setSites(items => {
      const data = {
        ...items,
        [el.day_number]: [...sites[el.day_number].filter(e => e.id !== el.id)]
      }
      return reorderState(data)
    })
  }, [sites])
  const [modalContent, setModalContent] = useState(null);
  const [modifyModal, setModifyModal] = useState(false);
  const openModifyModal = (el) => {
    setModalContent(el)
    setModifyModal(true);
  }

  const findItem = useCallback((id) => {
    let el = null
    Object.keys(sites).forEach(d => {
      sites[d].forEach(s => {
        if (id === JSON.stringify(s))
          el = s
      })
    })
    return el
  }, [sites])

  const handleModifyCTS = (data) => {
  

    handleRequest("get", API_GET_TOURISTIC_SITE(data.id), {}, config, (data, status) => {
      if (status === "Done") {
        let el = null
        Object.keys(sites).forEach(d => {
          sites[d].forEach(s => {
            if (data.id === s.id)
              el = s
          })
        })
        setSites(items => {
          const data2 = {
            ...items,
            [el.day_number]: [...sites[el.day_number].map(e => {
              if (e.id !== el.id) {
                return e
              }
              else {
                const temp_image = mediasTyped(data.medias)?.image?.[0];
                return {
                  ...e,
                  ...data,
                  henri_certified: !!data.agency_id,
                  custom: true,
                  category: { color: "#FFEE00" },
                  image: temp_image?.url || undefined,
                  feedbacks: {
                    note: data.avg_note,
                    len: data.notes_counter
                  }
                }
              }
            })]
          }
          return reorderState(data2)
        })
        handleModifyRT()

      } else if (status === "Error") {
        console.error(data);
      }
    })

  }
  return (
    <>
      <Modal
        open={modifyModal}
        title={"Point d'interet"}
        onClose={() => setModifyModal(false)}
      >

        <FormModal modalContent={modalContent} requestRoadTrip={requestRoadTrip} handleModifyRT={handleModifyCTS} modal={modal} onHide={onHide} roadtrip={roadtrip} handleClose={() => setModifyModal(false) && requestRoadTrip()} />

      </Modal>
      {/* <ModalForm
        isOpen={modifyModal}
        title={"Point d'interet"}
        onClose={() => setModifyModal(false)}
      >
      </ModalForm> */}
      <DndContext
        sensors={sensors}
        onDragOver={handleDragOver}
        onDragEnd={handleDragEnd}
        autoScroll={true}
        measuring={{
          droppable: {
            strategy: MeasuringStrategy.Always,
          },
        }}
      >
        {
          (!sites || Object.keys(sites).length === 0) ?
            <RoadTripEmpty setFieldValue={setFieldValue} />
            :
            <div className="d-flex dnd-container">
              {sites ? Object.keys(sites)?.map((day) =>
                <ColumnDroppable openModifyModal={openModifyModal} handleModifyRT={handleModifyCTS} modal={modal} onHide={onHide} roadtrip={roadtrip} day={day} key={day} col={sites[day]} deleteItem={deleteItem} />
              ) : null}
              <NewDayDroppable day={Object.keys(sites).length + 1} id="newDay" col={[]} />
            </div>
        }
      </DndContext>
    </>
  )
}
const CreateRoadTripPage = ({ formikProps, requestRoadTrip, createRTForm, createRT, isEdit, onHide, modal, loading, roadtrip, handleModifyRT, loadingUpdate }) => {
  const { t } = useTranslation(["henriTrip"])
  const [st, setST] = useState(null)
  const [dataCategory, setDataCategory] = useState([])

  const [dataTransport, setDataTransport] = useState([
    {
      id: 1,
      title: t("general.category.transport.car"),
      color: "#02A315",
      url: "https://general-henri-1-paris.s3.fr-par.scw.cloud/categories/voiture.png",
      selected: false
    },
    {
      id: 2,
      title: t("general.category.transport.onFoot"),
      color: "#F5E200",
      url: "https://general-henri-1-paris.s3.fr-par.scw.cloud/categories/walking-man.png",
      selected: false
    },
    {
      id: 3,
      title: t("general.category.transport.van"),
      color: "#0802A3",
      url: "https://general-henri-1-paris.s3.fr-par.scw.cloud/categories/van.png",
      selected: false
    },
    {
      id: 4,
      title: t("general.category.transport.campingCar"),
      color: "#B50000",
      url: "https://general-henri-1-paris.s3.fr-par.scw.cloud/categories/camping-car.png",
      selected: false
    },
    {
      id: 5,
      title: t("general.category.transport.bike"),
      color: "#FA9100",
      url: "https://general-henri-1-paris.s3.fr-par.scw.cloud/categories/bike.png",
      selected: false
    },
    {
      id: 6,
      title: t("general.category.transport.collectionCar"),
      color: "#006904",
      url: "https://general-henri-1-paris.s3.fr-par.scw.cloud/categories/collection-car.png",
      selected: false
    },
    {
      id: 7,
      title: t("general.category.transport.motorBike"),
      color: "#760071",
      url: "https://general-henri-1-paris.s3.fr-par.scw.cloud/categories/moto.png",
      selected: false
    },
  ])

  const [dataGroup, setDataGroup] = useState([
    {
      id: 8,
      title: t("general.category.withWhom.family"),
      color: "#A400CB",
      url: "https://general-henri-1-paris.s3.fr-par.scw.cloud/categories/family.png",
      selected: false
    },
    {
      id: 9,
      title: t("general.category.withWhom.friends"),
      color: "#00C1C1",
      url: "https://general-henri-1-paris.s3.fr-par.scw.cloud/categories/amis.png",
      selected: false
    },
    {
      id: 10,
      title: t("general.category.withWhom.couple"),
      color: "#FFFFFF",
      url: "https://general-henri-1-paris.s3.fr-par.scw.cloud/categories/couple.png",
      selected: false
    },
    {
      id: 12,
      title: t("general.category.withWhom.alone"),
      color: "#000000",
      url: "https://general-henri-1-paris.s3.fr-par.scw.cloud/categories/walking-man.png",
      selected: false
    },
    {
      id: 13,
      title: t("general.category.withWhom.student"),
      color: "#F0276C",
      url: "https://general-henri-1-paris.s3.fr-par.scw.cloud/categories/student.png",
      selected: false
    },
    {
      id: 11,
      title: t("general.category.withWhom.group"),
      color: "#844D00",
      url: "https://general-henri-1-paris.s3.fr-par.scw.cloud/categories/groupe.png",
      selected: false
    }
  ])

  const [dataSeason, setDataSeason] = useState([
    {
      id: 14,
      title: t("general.category.season.summer"),
      color: "#E30000",
      url: "https://general-henri-1-paris.s3.fr-par.scw.cloud/categories/sun.png",
      selected: false
    },
    {
      id: 15,
      title: t("general.category.season.autumn"),
      color: "#FF9D00",
      url: "https://general-henri-1-paris.s3.fr-par.scw.cloud/categories/feuille.png",
      selected: false
    },
    {
      id: 16,
      title: t("general.category.season.spring"),
      color: "#00AA07",
      url: "https://general-henri-1-paris.s3.fr-par.scw.cloud/categories/flower.png",
      selected: false
    },
    {
      id: 17,
      title: t("general.category.season.winter"),
      color: "#004B8C",
      url: "https://general-henri-1-paris.s3.fr-par.scw.cloud/categories/hiver.png",
      selected: false
    },
  ])

  useEffect(() => {
    let dataCategory2 = [];

    dataTransport.forEach(el => {
      if (el.selected === true) {
        dataCategory2.push(el.id)
      }
    })

    dataGroup.forEach(el => {
      if (el.selected === true) {
        dataCategory2.push(el.id)
      }
    })

    dataSeason.forEach(el => {
      if (el.selected === true) {
        dataCategory2.push(el.id)
      }
    })

    setDataCategory(dataCategory2);
  }, [dataSeason, dataGroup, dataTransport])

  useEffect(() => {
    formikProps.setFieldValue('category', dataCategory)
  }, [dataCategory])

  useEffect(() => {
    if (roadtrip && !loadingUpdate) {
      const arr_ts = [...roadtrip.touristic_sites]
     const s = _.groupBy(arr_ts.sort((a, b) => a.rank - b.rank), "day_number")
      setST(s)
      document.body.style.overflowX = 'unset'
      return () => {
        document.body.style.overflowX = null
      }
    }
  }, [roadtrip])


  const handlerClick = (title) => {
    setDataSeason(state => state.map(el => {
      if (el.title === title) {
        return { ...el, selected: !el.selected };
      }
      else {
        return el
      }
    }))

    setDataGroup(state => state.map(el => {
      if (el.title === title) {
        return { ...el, selected: !el.selected };
      }
      else {
        return el
      }
    }))

    setDataTransport(state => state.map(el => {
      if (el.title === title) {
        return { ...el, selected: !el.selected };
      }
      else {
        return el
      }
    }))
  }

  return (
    <Form onSubmit={formikProps.handleSubmit}>
      <div className="d-flex justify-content-end">
        <SvgIcon
          className="me-3 mb-1"
          component={CloseIcon}
          onClick={() => onHide()}
          width="20"
          height="20"
        />
      </div>
      <FileInput dropZone srcDefault={roadtrip?.picture || "https://general-henri-1-paris.s3.fr-par.scw.cloud/woman.jpg"} imgClass={`${createRT ? "touristic-site-page--header-image-RT" : "touristic-site-page--header-image"}`} accepts={'image/png,image/jpg,image/jpeg'} maxSize={1000000} {...formikProps} {...createRTForm.image} />
      <div className="mx-0 mx-md-2 mx-lg-2 w-100">
        <div className={`roadtrip-header ${createRT ? "px-4 py-4" : "px-2 px-md-3 px-lg-5"}`}>
          <InputFormik {...formikProps} {...createRTForm.title} classes="col col-md-6 col-lg-6" />
          <TextAreaFormik {...formikProps} {...createRTForm.description} classes="col col-lg-8" />
          {
            roadtrip?.user_transactions[0]?.user_id !== roadtrip?.user_id &&
            <>
              <MyCheckbox {...formikProps} {...createRTForm.state} />
              {
                formikProps.values.public === false &&
                <>
                  <InputFormik {...formikProps} {...createRTForm.password} classes="col col-md-6 col-lg-6" />
                </>
              }
            </>
          }
        </div>
        <section className={`${createRT ? "px-4 py-4" : "py-3 m-0 px-2 px-md-3 px-lg-5"}`}>
          <div className="row">
            <div className="col">
              <h4>{t("general.category.transport.title")}</h4>
            </div>
          </div>
          <div className="row">
            {
              dataTransport.map(el => {
                if (el.selected) {
                  return <CategoriesIcons key={el.title} name={el.title} selected={el.selected} color={el.color} icon={el.url} movable={true} handleClick={handlerClick} />
                }
                else {
                  return null
                }
              }
              )
            }
          </div>
          <hr />
          <div className="row">
            {
              dataTransport.map(el => {
                if (!el.selected) {
                  return <CategoriesIcons key={el.title} name={el.title} selected={el.selected} color={el.color} icon={el.url} movable={true} handleClick={handlerClick} />
                }
                else {
                  return null
                }
              }
              )
            }
          </div>
        </section>
        <section className={`${createRT ? "px-4 py-4" : "py-3 m-0 px-2 px-md-3 px-lg-5"}`}>
          <div className="row">
            <div className="col">
              <h4>{t("general.category.withWhom.title")}</h4>
            </div>
          </div>
          <div className="row">
            {
              dataGroup.map(el => {
                if (el.selected) {
                  return <CategoriesIcons key={el.title} name={el.title} selected={el.selected} color={el.color} icon={el.url} movable={true} handleClick={handlerClick} />
                } else {
                  return null
                }
              }
              )
            }
          </div>
          <hr />

          <div className="row">
            {
              dataGroup.map(el => {
                if (!el.selected) {
                  return <CategoriesIcons key={el.title} name={el.title} selected={el.selected} color={el.color} icon={el.url} movable={true} handleClick={handlerClick} />
                } else {
                  return null
                }
              }
              )
            }
          </div>
        </section>
        <section className={`${createRT ? "px-4 py-4" : "py-3 m-0 px-2 px-md-3 px-lg-5"}`}>
          <div className="row">
            <div className="col">
              <h4>{t("general.category.season.title")} :</h4>
            </div>
          </div>
          <div className="row">
            {
              dataSeason.map(el => {
                if (el.selected) {
                  return <CategoriesIcons key={el.title} name={el.title} selected={el.selected} color={el.color} icon={el.url} movable={true} handleClick={handlerClick} />
                }
                else {
                  return null
                }
              }
              )
            }
          </div>
          <hr />
          <div className="row">
            {
              dataSeason.map(el => {
                if (!el.selected) {
                  return <CategoriesIcons key={el.title} name={el.title} selected={el.selected} color={el.color} icon={el.url} movable={true} handleClick={handlerClick} />
                } else {
                  return null
                }
              }
              )
            }
          </div>
        </section>
        <section className={`${createRT ? "px-4 py-4" : "row py-3 m-0 px-2 px-md-3 px-lg-5"}`}>
          <div className="d-flex justify-content-start">
            <Button title={t("general.buttonCancel")} onClick={onHide} color="secondary" />
            <Button status={loading ? "Loading" : null} title={isEdit ? t("roadTripPage.createRoadTrip.save") : t("roadTripPage.createRoadTrip.create")} className="ml-1" type="submit" color="henri" onClick={formikProps.handleSubmit} />
          </div>
        </section>
        {createRT ?
          <></> :
          <>
            <div className="align-items-center d-flex justify-content-end pt-2">
              <div onClick={() => document.querySelector('.create-roadtrip-section').scrollLeft -= 200}>
                <SVG src="/assets/icons/Chevron_right_font_awesome.svg" width="35" className="slider-button__svg" style={{ transform: "scaleX(-1)" }} />
              </div>
              <div onClick={() => document.querySelector('.create-roadtrip-section').scrollLeft += 200}>
                <SVG src="/assets/icons/Chevron_right_font_awesome.svg" width="35" className="ml-3 text-dark slider-button__svg" />
              </div>
            </div>
            <section className="pt-3 mx-0 px-0 px-md-2 px-xl-3 create-roadtrip-section">
              {
                !isEdit ?
                  <RoadTripEmpty {...formikProps} />
                  :
                  <>
                    {
                      (!st || Object.keys(st).length === 0) ?
                        <RoadTripEmpty {...formikProps} />
                        :
                        <MultiSortable onHide={onHide} modal={modal} roadtrip={roadtrip} st={st} handleModifyRT={handleModifyRT} {...formikProps} />
                    }
                  </>
              }
            </section>
          </>}
      </div>
    </Form>
  );
};

export default CreateRoadTripPage;
