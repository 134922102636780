import styled from "styled-components";
import { ReactComponent as PineLocationIcon } from "../../../assets/img/logo/pine-location-icon.svg";
import { ReactComponent as BookedIcon } from "../../../assets/img/logo/booked-icon.svg";
import { ReactComponent as ArrowRight } from "../../../assets/img/logo/arrowRight.svg";
import { ReactComponent as AddIcon } from "../../../assets/img/logo/add-icon.svg";
import React, { useState } from "react";
import { useHistory, useLocation } from 'react-router-dom';
import {useSelector} from 'react-redux';


const JustAContainer = styled.div`
  width: 253px;
  height: fit-content;
  display: flex;
  justify-content: space-between;
  margin: 20px 10px 0 0;
  align-items: center;
`;

const ListItemStyled = styled.li`
  display: flex;
  gap: 6px;
  margin: 7px 15px;
  align-items: center;
  height: 23px;
`;

const ListStyled = styled.ul`
  list-style-type: none;
`;

const HiddenCityList = ({ touristic_sites }) => {
  const city_list = [];
  touristic_sites.forEach(site => {
    if (city_list.indexOf(site.city) === -1 && site.city !== null) {
      city_list.push(site.city);
    }
  });

  return (
    <ListStyled>
      {city_list.slice(0,5).map((city) => (
        <ListItemStyled key={city}>
          <PineLocationContainer>
            <PineLocationIcon />
          </PineLocationContainer>
          <DestinationFont>{city}</DestinationFont>
        </ListItemStyled>
      ))}
    </ListStyled>
  )
};


const CityList = ({ touristic_sites, click_function }) => {
  const city_list = [];
  touristic_sites.forEach(site => {
    if (city_list.indexOf(site.city) === -1 && site.city !== null) {
      city_list.push(site.city);
    }
  });

  return (
    <DestinationContainer>
      <PineLocationContainer>
        <PineLocationIcon />
      </PineLocationContainer>
      <DestinationFont>{city_list.join(' - ').toUpperCase()}</DestinationFont>
      {
        city_list.length > 1 && (
          <ButtonShowCity><AddIcon onClick={click_function} /></ButtonShowCity>
        )
      }
    </DestinationContainer>
  );
}

const FlagCountry = ({ country }) => (
  <i className={`${country || 'fr'} flag`}></i>
);

const CityListHiddenContainer = styled.div`
  display:flex;
  position: absolute;
  width: fit-content;
  height: 353px;

  background: rgba(255, 255, 255, 0.9);
  border-radius: 17px 0 0 17px;
  align-items: center;
`;

const CardContainer = styled.div`
  display: flex;
  gap: 30px;
`;

const DateContainer = styled.span`
  background: rgba(255, 255, 255, 0.65);
  border-radius: 3px;

  width: 64px;
  height: 23px;

  font-family: 'Inter', 'serif';
  font-style: normal;
  font-weight: 900;
  font-size: 12px;
  line-height: 140%;

  display: flex;
  align-items: center;
  justify-content: center;
`;

const TitleFlagContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  margin: 0 15px;
  padding-top: 10px;
`;

const TitleFont = styled.p`
  font-weight: 600;
  font-size: 17px;
  line-height: 17px;
  margin: 0 5px 0 0;
  color: ${props => props.theme.colors.primary};
`;

const BookedContainer = styled.span`
  display: flex;
  box-sizing: border-box;
  border-radius: 50%;
  justify-content: center;
  align-items: center;

  width: 41px;
  height: 41px;

  background: ${props => props.theme.colors.white};
  border: 1px solid ${props => props.theme.colors.white};
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.5);

  svg {
    color: ${props => props.theme.colors.primary};
  }
`;

const FlagIcon = styled.span`
  width: 16px;
`

const UserFont = styled.p`
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  margin: 0 15px;
  color: ${props => props.theme.colors.primary};
`;


const Card = styled.div`
  position: relative;
  width: 253px;
  height: 360px;
  background: ${props => props.theme.colors.white};
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 17px;
`;

const CardHead = styled.div`
  display: flex;
  width: 253px;
  height: 184px;
  background: no-repeat url(${props => props.picture});
  border-radius: 17px 17px 0 0;
  background-size: cover;
  justify-content: space-between;
`;

const DestinationFont = styled.div`
  font-family: 'Inter';
  font-weight: 700;
  font-size: 16px;
  line-height: 23px;
  color: #111827;
  letter-spacing: -0.5px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  }
`;

const DestinationContainer = styled.div`
  display: flex;
  gap: 6px;
  margin: 7px 15px;
  align-items: center;
  height: 23px;
`;

const ActivityViewtripContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0 15px;
`;

const ActivityFont = styled.p`
  font-family: 'Inter';
  font-weight: 600;
  font-size: 15px;
  line-height: 18px;
  margin-bottom: 10px;
`;

const ViewTrip = styled.div`
  display: flex;
  font-weight: 700;
  font-size: 13px;
  line-height: 16px;
  color: ${props => props.theme.colors.primary} !important;

  a:hover {
    color: ${props => props.theme.colors.primary} !important;
    text-decoration: underline !important;
    text-underline-offset: 2px !important;
  }

  svg {
    height: 13px;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  position: absolute;
  left: 41px;
  bottom: 10px;
`;

const ButtonAddTrip = styled.div`
  width: 171px;
  height: 35px;
  display: flex;
  background: ${props => props.theme.colors.primary};
  border-radius: 68px;
  justify-content: center;
  align-items: center;

  font-weight: 700;
  font-size: 13px;
  line-height: 16px;
  color: white;

`;

const ButtonDeleteTrip = styled.div`
  width: 171px;
  height: 35px;
  display: flex;
  width: 181px;
  height: 35px;
  background-color: ${props => props.theme.colors.white};
  border: 1px solid ${props => props.theme.colors.darkGrey};
  border-radius: 68px;
  justify-content: center;
  align-items: center;

  font-weight: 700;
  font-size: 13px;
  line-height: 16px;

  color: ${props => props.theme.colors.darkGrey};

  :hover {
    filter: invert(1);
  }

`;

const ButtonShowCity = styled.div`
  display: flex;

  svg {
    color: ${props => props.theme.colors.primary};
    width: 14px;
    height: 14px;
  }
`;

const PineLocationContainer = styled.div`
  width: 16px;
  height: 23px;
`;


const HiddenContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
`;

const RoadTripCardV2Unbooked = ({trip}) => {
  const location = useLocation()
  const {user} = useSelector(state => state.user);
  const history = useHistory();
  
  const handleAddTrip = (id) => {
  if (!user.id) {
    history.push(`/login?redirectURL=${location.pathname}`);
    return;
  }
  history.push(`/trips/${id}`);
  }
  const [isDivVisible, setIsDivVisible] = useState(false);
  const handleClick = () => {
    setIsDivVisible(!isDivVisible);
  };
  return (
    <Card>

      {isDivVisible && (
        <HiddenContainer onClick={handleClick}>
          <CityListHiddenContainer>
            <HiddenCityList touristic_sites={trip.touristic_sites} />
          </CityListHiddenContainer>
        </HiddenContainer>)}

      <CardHead picture={trip.picture}>
        <JustAContainer>
          {
            trip.trip_days.length > 0 && (
              <DateContainer>
                {`${trip.trip_days.length} ${trip.trip_days.length === 1 ? 'jour' : 'jours'}`}
              </DateContainer>
            )
          }
        </JustAContainer>
      </CardHead>

      <TitleFlagContainer>
        <TitleFont>{trip.title?.text_fr}</TitleFont>
        <FlagIcon><FlagCountry country={trip.country} /></FlagIcon>
      </TitleFlagContainer>

      <UserFont>{`par ${trip?.user?.firstname} ${trip?.user?.lastname}`}</UserFont>

      <CityList touristic_sites={trip.touristic_sites} click_function={handleClick} />

      <ActivityViewtripContainer>
        <ActivityFont>{`${trip.touristic_sites.length} activités`}</ActivityFont>
        <ViewTrip><a href={`/trips/${trip.id}`}>Voir le trip <ArrowRight /></a></ViewTrip>
      </ActivityViewtripContainer>

      <ButtonContainer>
        <a><ButtonAddTrip onClick={() => {handleAddTrip(trip.id)}}>Ajouter à mes trips</ButtonAddTrip></a>
      </ButtonContainer>

    </Card>
  );
}

// {
//   el.road_trip_premium ?
//     <></>
//     :
//     <Button rounded title={t("general.addToMyTrips")} className="mt-n3 px-3 py-1 font-weight-light shadow-lg roadtrip__buttonIndex"
//             onClick={() => handleCopyTrip(el.id)} />
// }



export default RoadTripCardV2Unbooked;