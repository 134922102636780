import React, { useEffect, useState } from 'react'
import { Modal } from 'semantic-ui-react'
import { PHONE_WIDTH } from "../../utils/utils";

const ModalExperience = ({ formikProps, title, isOpen, onClose, children }) => {
    const [show, setShow] = useState(false)
    const [widthState, setWidthState] = useState(window.innerWidth);
    const handleClose = () => {
        onClose()
        setShow(false)
    }
    const handleShow = () => setShow(true)

    useEffect(() => {
        if (isOpen === true) {
            handleShow()
        } if (isOpen === false) {
            handleClose()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOpen])
    return (
        <Modal
            size='large'
            open={show}
            onOpen={handleShow}
            onClose={handleClose}
            style={{ width: widthState < '600' ? '100%' : '80%', height: '93%' }}
        >
            {
                title &&
                <Modal.Header >
                    {title}
                </Modal.Header>
            }

            {children}
        </Modal>
    )
}

export default ModalExperience;
