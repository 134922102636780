import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

const user = {
    user: {},
    coords: {}
};

export default persistReducer({storage, key: "user-data", whitelist: ["user", "coords"]}, (state = user, action) => {
    switch (action.type) {
        case 'ADD_USER':
            return {
                ...state,
                user: action.payload
            };
        case 'REMOVE_USER':
            return {
                ...state,
                user: {}
            };
        case 'MODIFY_USER':
            return {
                ...state,
                user: {
                    ...state.user,
                    ...action.payload
                }
            }
        case 'ADD_COORDS':
            return {
                ...state,
                coords: action.payload
            };
        case 'REMOVE_COORDS':
            return {
                ...state,
                coords: {}
            }
        default:
            return state;
    }
})