import TipsSection from "../experiences/TipsSection";
import styled from "styled-components";
import breakpoint from "styled-components-breakpoint";
import SliderContainerWithArrowNavigation from "../../../_ui/_v2/components/SliderContainerWithArrowNavigation";
import { ReactComponent as CameraIcon } from '../../../assets/img/logo/Activity/icon_camera.svg';
import { ReactComponent as VideoIcon } from '../../../assets/img/logo/Activity/icon_video.svg';
import { ReactComponent as AnnotationIcon } from '../../../assets/img/logo/Activity/icon_annotation.svg';
import { ReactComponent as HeadphonesIcon } from '../../../assets/img/logo/Activity/icon_headphones.svg';
import { ReactComponent as TextIcon } from '../../../assets/img/logo/Activity/icon_text.svg';
import React, {useContext, useEffect, useState} from 'react';
import {mediasTyped} from '../../../utils/media';
import useTouristicSiteTranslation from "../../../hooks/touristic_sites/useTouristicSiteTranslation";
import {API_ORIGINAL_BLOB_URL} from "../../../utils/api";
import CarouselBuilder from "../../../_forms/_components/layout_builder/images/CarouselBuilder";
import TouristicSiteContext from "../../../_contexts/TouristicSiteContext";
import AudioToShow from "../../../_ui/_v2/components/Audio/AudioToShow";
import Quiz from "../../quiz/quiz";
import {useTranslation} from "react-i18next";

const TabWrapperMedia = styled.div `
  display: grid;
  grid-gap: 2em;
  justify-content: center;
  align-items: start;
  justify-items: start;
  padding: 5px 12px;
  margin-top: 2rem;
  color: ${props => props.theme.colors.white};
  ${breakpoint('md')`
    grid-template-columns: ${props => props.openMediaModal ? 'auto' : 'auto, 45%'};
  `}
`;

const TabWrapperActivity = styled.div`
  display: flex;
  flex-direction: column;
  color: ${props => props.theme.colors.white};
  overflow-y: auto;
  width: 100%;
`;

const TabWrapperVideo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  color: ${props => props.theme.colors.white};
  overflow-y: auto;
  width: 100%;
  max-height: 500px;
`;


const isAvailable = (element) => ![undefined, null].includes(element);

const ImageToShow = ({image}) => (
  <DayActivityStyled>
    <img
      src={API_ORIGINAL_BLOB_URL(image.signed_id, image.filename) ||
        "https://general-henri-1-paris.s3.fr-par.scw.cloud/small-woman.jpg"
      }
      alt="..." />
  </DayActivityStyled>
);

const VideoToShowContainer = styled.div `
  padding: .7rem;
  width: 100%;
`;

const VideoContainerStyled = styled.div `
  height: max-content;
  padding: 2em;
`;

const CardVideo = styled.div `
  width: 80px;
  margin: auto;
  height: 80px;
  display: flex;
  background-image: url(${props => props.url});
`;

const VideoUploadContainer = styled.div`
  background-image: ${props => props.backgroundImageUrl ? `url('${props.backgroundImageUrl}')` : 'none'};
  background: ${props => props.backgroundImageUrl ? '' : 'linear-gradient(203.51deg, rgba(255, 255, 255, 0.3) 1.43%, rgba(255, 255, 255, 0.045) 103.65%)'};
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 10px;
  ${props => !props.backgroundImageUrl && `
     border: 0.669205px solid #FFFFFF;
  `}
`;

const VideoTitleImageArrayToWatch = ({videosArray}) => {
  const {i18n} = useTranslation();
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [selectedVideo, setSelectedVideo] = useState(videosArray.items[selectedIndex]);

  useEffect(()=>{
    setSelectedVideo(videosArray.items[selectedIndex]);
  },[selectedIndex]);

  const currentVideo = selectedVideo?.items[1]?.video;
  const currentVideoUrl = currentVideo?.blob_id ? API_ORIGINAL_BLOB_URL(currentVideo.signed_id, currentVideo.filename) : undefined;

  const textKey = `text_${i18n.language}`;

  return(
    <VideoToShowContainer>
      <VideoContainerStyled key={selectedIndex}>
        <h3 style={{textAlign: 'center', color: 'white'}}>{selectedVideo?.items[0]?.text?.[textKey]}</h3>
        <video controls width="100%" style={{objectFit: 'cover', maxHeight: '350px'}} key={selectedIndex}>
          <source
            src={currentVideoUrl}
            type={currentVideo?.content_type}
            key={selectedIndex}
          />
        </video>
        <hr style={{borderTop: '1px grey solid', width: '70%'}}></hr>
      </VideoContainerStyled>
      <SliderContainerWithArrowNavigation
        onSelectedIndexChange={setSelectedIndex}
        indexSelected={selectedIndex}
        style={{position: "relative", height: '7em', width: '100%'}}
      >
        {
          videosArray.items.map((video,index) => {
            const preview = video?.items[2]?.image;
            const previewUrl = preview?.blob_id ? API_ORIGINAL_BLOB_URL(preview.signed_id, preview.filename) : '';

            return (
              <CardVideo
                style={{flexDirection: 'row'}}
                onClick={() => setSelectedIndex(index)}
              >
                <VideoUploadContainer
                  backgroundImageUrl={previewUrl}
                  style={{border: "none"}}
                >
                  <CardVideo
                    style={{
                      alignItems: 'flex-end',
                      justifyContent: 'center'
                    }}
                  >
                    <span>#{index + 1}</span>
                  </CardVideo>
                </VideoUploadContainer>
              </CardVideo>
            );
          })}
      </SliderContainerWithArrowNavigation>
    </VideoToShowContainer>
  );
};

const VideoToShow = ({video, site}) => {
  const {i18n} = useTranslation();
  const videoLayout = site?.layout?.length !== 0 && site?.layout?.filter(l => l.language === i18n.language)?.filter(text => text.type === "TitleVideo");
  videoLayout && videoLayout?.forEach((elm, index) => {
    elm.items.forEach((item) => {
      if(item.type === 'Title'){
        item.text = site.texts.find(text => text.id === item.content.id)
      }
      if(item.type === 'Video'){
        item.video = site.medias.find(media => media.blob_id === item?.content?.blob_id)
      }
    })
  });

  const videoImageLayout = site?.layout?.length !== 0 && site?.layout?.filter(text => text.type === "VideoTitleImageArray");
  videoImageLayout && videoImageLayout.forEach((videoImage => videoImage?.items?.forEach((elm, index) => {
    elm.items.forEach((item) => {
      if(item.type === 'Title'){
        item.text = site.texts.find(text => text.id === item.content.id)
      }
      if(item.type === 'Video'){
        item.video = site.medias.find(media => media.blob_id === item?.content?.blob_id)
      }
      if(item.type === 'Image'){
        item.image = site.medias.find(media => media.blob_id === item?.content?.blob_id)
      }
    })
  })));

  const textKey = `text_${i18n.language}`;
  return(
    <>
      {
        videoLayout &&
          (videoLayout).map((e,index)=>(
            <VideoToShowContainer>
              <VideoContainerStyled>
                <h3 style={{textAlign: 'center', color: 'white'}}>{e?.items[0]?.text?.[textKey]}</h3>
                <video key={index} controls width="100%" style={{objectFit: 'cover', maxHeight: '350px'}}>
                  <source
                    src={API_ORIGINAL_BLOB_URL(e?.items[1]?.video?.signed_id, e?.items[1]?.video?.filename)}
                    type={e?.items[1]?.video?.content_type}
                  />
                </video>
                <hr style={{borderTop: '1px grey solid', width: '70%'}}></hr>
              </VideoContainerStyled>
            </VideoToShowContainer>
          ))
      }
      {
        videoImageLayout.length > 0 &&
          videoImageLayout.map((e, index) => (
            <VideoToShowContainer>
              <VideoTitleImageArrayToWatch videosArray={e}/>
            </VideoToShowContainer>
        ))
      }
      { video.length > 0 && !videoLayout &&
          (video || []).map((e,index)=>(
            <video key={index} controls width="100%">
              <source
                src={API_ORIGINAL_BLOB_URL(e.signed_id, e.filename)}
                type={e.content_type}
              />
            </video>
          ))
      }
    </>
  );
};

const DayActivityStyled = styled.div`
  position: relative;
  box-sizing: border-box;
  box-shadow: 0px 10px 13px -7px #000000, 16px 16px 23px -2px rgb(0 0 0 / 17%);
  border-radius: 1rem;
  width: 16rem;
  height: 100%;

  img {
    width: 101%;
    height: 100%;
    object-fit: cover;
    border-radius: 1rem;
  }

  span {
    color: ${props => props.theme.colors.white};
    font-weight: 700;
    font-size : 1rem;
    line-height: 1em;
    margin-left: 0.5em;
  }

  ${breakpoint('md')`
    width: 19rem;

    span {
      font-size: 1.2em;
    }
  `}
`;

const TextToDescribe = ({title, description, site = {}}) => {
  return(
    <InformationCustomStyled>
      <h2>{title}</h2>
      <p style={{color: "white"}}>{description}</p>
    </InformationCustomStyled>
  );
};

const TextToDescribeLayout = ({title, description, site = {}}) => {
  return(
    <InformationCustomStyled>
      <h2>{title}</h2>
      <p style={{color: "white", whiteSpace: 'pre-wrap'}}>{description}</p>
    </InformationCustomStyled>
  );
};

const TextToDescribeWrapper = ({title, description, site = {}}) => {
  const titleLayout = site?.layout?.length !== 0 && site?.layout?.filter(text => text.type === "TitleText");
  titleLayout && titleLayout?.forEach((elm, index) => {
    elm.items.forEach((item) => item.text = site.texts.find(text => text.id === item.content.id))
  });
  const titleToTake = titleLayout ? titleLayout?.map((e) => e.items?.find(text => text?.type === 'Title')) : title
  const textToTake = titleLayout ? titleLayout?.map((e) => e?.items?.find(text => text?.type === 'Text')) : description
  const {i18n} = useTranslation();
  const textKey = `text_${i18n.language}`;
  return(
    <>
      {
        titleLayout ?
          titleLayout?.map((e,index) => (
              <TextToDescribeLayout
                key={index}
                site={site}
                title={titleToTake[index].text?.[textKey]}
                description={textToTake[index].text?.[textKey]}
              />
            )
          )
          :
          <TextToDescribe
            title={title}
            description={description}
            site={site}
          />
      }
    </>
  );
};

const InformationCustomStyled = styled.div `
  text-align: left;
  overflow-y: auto;
  margin: 1em;
  max-height: 40vh;
  ${breakpoint('md')`
    max-height: 35vh;
  `}
`;

const DayActivitiesContainer = styled(SliderContainerWithArrowNavigation)`
  position: relative;
  height: 25rem;
  padding: 1rem;
  width: 100%;
  margin: 2rem;
  ${breakpoint('md')`
    height: 26rem;
  `}
`;

const ImagesContainer = ({site}) => {
  const {touristicSite} = useContext(TouristicSiteContext);
  const images = mediasTyped(site?.medias).image;
  if (site.layout.length > 0) {
    const carousels = site.layout?.filter(object => object.type === 'Carousel')?.map(carousel => (
      {
        ...carousel,
        items: carousel.items.map(item => ({
          ...item,
          content: {...item.content, ...(touristicSite.medias.find(media => media.blob_id === item.content.blob_id) || {})}
        })),
      }
    )) || [];

    return (
      <>
        {
          carousels.map((carousel, index) => (
            <CarouselBuilder
              key={index}
              carousel={carousel}
            />
          ))
        }
      </>
    );
  }
  return (
    <DayActivitiesContainer>
      {images?.map((image, index) => (
        <ImageToShow key={index} image={image}/>
      ))}
    </DayActivitiesContainer>
  );
};

const IconWrapper = styled.div`
  display: inline-block;
  svg {
    color: ${props => props.disabled ? props.theme.colors.black : props.theme.colors.white};
  }
`;

const MediaTextTitle = ({site}) => {
  const { title } = useTouristicSiteTranslation(site);

  return (
    <IconWrapper
      disabled={!isAvailable(title)}
    >
      <TextIcon
        width="30"
        height="30"
      />
    </IconWrapper>
  );
};

const MediaTextDescription = ({site}) => {
  const { title, description } = useTouristicSiteTranslation(site);
  const titleLayout = site?.layout?.length !== 0 && site?.layout?.filter(text => text.type === "TitleText")
  titleLayout && titleLayout?.forEach((elm, index) => {
    elm.items.forEach((item) => item.text = site.texts.find(text => text.id === item.content.id))
  });
  const titleToTake = titleLayout && titleLayout?.map((e) => e.items?.find(text => text?.type === 'Title'))
  const textToTake = titleLayout && titleLayout?.map((e) => e?.items?.find(text => text?.type === 'Text'))
  const {i18n} = useTranslation();
  const textKey = `text_${i18n.language}`;
  return (
    <>
      {
        titleLayout ?
          titleLayout?.map((e,index) => (
            <TextToDescribeLayout
              key={textKey+index}
              site={site}
              title={titleToTake[index].text?.[textKey]}
              description={textToTake[index].text?.[textKey]}
            />
            )
          )
          :
          <TextToDescribe
            title={title}
            description={description}
            site={site}
          />
      }
    </>
  );
};

//gestion des differentes data a afficher en fonction de l'etat des booleans. isVisit => 1ere partie
//dans l'information il faut que je trouve un moyen de separer mon menu afin de pouvoir donner n'importe quel type de site/activité/points d'interets

const medias = (site, IllustrationComponent, isVisit , openMediaModal , isNotHome, finalLap, setOpenMediaModal) =>
  [
    {
      TitleComponent: <MediaTextTitle site={site}/>,
      ContentComponent: (
        <TabWrapperMedia openMediaModal={openMediaModal}>
          {
            finalLap
              ? <TextToDescribeWrapper site={site} title={site?.title} description={site?.description} />
              : isNotHome
                ? <TextToDescribeWrapper site={site} title={site?.title} description={site?.description} />
                : <MediaTextDescription site={site} title={site?.title} description={site?.description} />
          }
          {IllustrationComponent}
        </TabWrapperMedia>
      ),
    },
    {
      TitleComponent: (
        <IconWrapper
          disabled={!mediasTyped(site?.visit_sheet_contents?.[0]?.files || site?.medias)?.image}
        >
          <CameraIcon
            width="30"
            height="30"
          />
        </IconWrapper>
      ),
      ContentComponent: mediasTyped(site?.visit_sheet_contents?.[0]?.files || site?.medias)?.image && <ImagesContainer site={site}/>,
    },
    {
      TitleComponent: (
        <IconWrapper
          disabled={!mediasTyped(site?.visit_sheet_contents?.[0]?.files || site?.medias)?.video}
        >
          <VideoIcon
            width="30"
            height="30"
          />
        </IconWrapper>
      ),
      ContentComponent: mediasTyped(site?.visit_sheet_contents?.[0]?.files || site?.medias)?.video && (
        <TabWrapperVideo>
          <VideoToShow site={site} openMediaModal={openMediaModal} video={mediasTyped(site?.visit_sheet_contents?.[0]?.files || site?.medias)?.video}/>
        </TabWrapperVideo>
      ),
    },
    {
      TitleComponent: (
        <IconWrapper
          disabled={!mediasTyped(site?.visit_sheet_contents?.[0]?.files || site?.medias)?.audio}
        >
          <HeadphonesIcon
            width="30"
            height="30"
          />
        </IconWrapper>
      ),
      ContentComponent: mediasTyped(site?.visit_sheet_contents?.[0]?.files || site?.medias)?.audio && (
        <TabWrapperActivity style={{justifyContent: 'center'}}>
          <AudioToShow site={site} audio={mediasTyped(site?.visit_sheet_contents?.[0]?.files || site?.medias)?.audio}/>
        </TabWrapperActivity>
      ),
    },
    {
      TitleComponent: (
        <IconWrapper
          disabled={!site?.quizzes}
        >
          <AnnotationIcon
            width="30"
            height="30"
          />
        </IconWrapper>
      ),
      ContentComponent: site?.quizzes && (
        <TabWrapperVideo>
          <Quiz values={site?.quizzes[0]} onClickCloseTheQuizz={setOpenMediaModal}/>
        </TabWrapperVideo>
      ),
    },
  ];

export default medias;
