import React, {useEffect, useState} from 'react';
import ReactPlayer from 'react-player';

const Video = ({data}) => {
  const [urls, setUrls] = useState(
    data ? data.filter((el) => el.id).map((el) => el.url.replace(/\?.*/, '')) : []
  );

  useEffect(() => {
    setUrls(
      data ? data.filter((el) => el.id).map((el) => el.url.replace(/\?.*/, '')) : []
    );
  }, [data]);

  return (
    <ul className="my-3 w-100" style={{listStyle: 'none'}}>
      {urls.map((el, index) => {
        return (
          <li key={index} className="w-100 my-2">
            <ReactPlayer
              className="react-player__custom"
              width="100%"
              url={el}
              controls
            />
          </li>
        );
      })}
    </ul>
  );
};

export default Video;
