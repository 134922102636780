import useRequest from 'hooks/use-request';
import React, {useEffect, useState} from "react";
import {useSelector} from 'react-redux';
import {API_GET_ACTIVITY} from 'utils/api';
import ActivityPage from "../../pages/Activities/ActivityPage";
import ActivityPasswordModal from "../../pages/Activities/ActivityPasswordModal";
import {Modal} from 'semantic-ui-react'
import {StringParam, useQueryParam} from "use-query-params";
import jwt from "jsonwebtoken";
import {useHistory} from "react-router-dom";

const Activity = ({match}) => {
  const [handleRequest, status] = useRequest();
  const [activity, setActivity] = useState();
  const [update, setUpdate] = useState(0);
  const config = useSelector(state => state.data);
  const [token] = useQueryParam("token", StringParam);
  const history = useHistory();

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    handleRequest("get", API_GET_ACTIVITY(match.params.activity_uuid), {}, config, (data, status) => {
      if (status === "Done") {
        setActivity(data);
      } else if (status === "Error") {
        console.error(data);
        history.push('/error/404');
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [match.params.activity_uuid])
  const decoded = jwt.decode(token, {complete: true});
  const items = JSON.parse(localStorage.getItem("allow_activities"))
  if (items) {
    if (items.includes(match.params.activity_uuid)) {
      return (
        activity ?
          <ActivityPage
            loading={status !== "Done"}
            activity={activity}
          />
          : null
      );
    }
  }
  if (decoded){
    return (
      <Modal
        size={"tiny"}
        open={true}
      >
        <Modal.Header >
          Entrez le code à 4 chiffres pour déverrouiller le contenu
        </Modal.Header>
        <ActivityPasswordModal password={decoded.payload.password} uuid={match.params.activity_uuid} handleClose={() => setUpdate(state => state + 1)}/>
      </Modal>
    )
  }
  return (
    <Modal
      size={"tiny"}
      open={true}
    >
      <Modal.Header >
        Entrez le code à 4 chiffres pour déverrouiller le contenu
      </Modal.Header>
      <ActivityPasswordModal uuid={match.params.activity_uuid} handleClose={() => setUpdate(state => state + 1)}/>
    </Modal>
  )
};

export default Activity;
