import React, { useRef, useState, useEffect } from "react";
import Button from "../../components/form/button/button";
import { Dropdown, Icon } from "semantic-ui-react";
import { Formik, Field, FieldArray, enableReinitialize } from "formik";
import * as Yup from "yup";
import { API_POST_CREATE_TS_ROADTRIP } from "utils/api";
import { API_UPDATE_TS_ROADTRIP } from "utils/api";
import useRequest from "hooks/use-request";
import { useSelector } from "react-redux";
import AutocompletePlaceAddress from "../search/autocompletesearchaddressonly";
import { ReactComponent as SvgMarker } from "../../assets/img/marker-icons/mapbox-marker-icon-red.svg";
import MapGL, { Marker, NavigationControl } from "react-map-gl";
import { Modal } from "semantic-ui-react";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { ReactComponent as Close } from "../../assets/img/logo/Fermer.svg";

import { Create as CreateIcon } from "@material-ui/icons";
import "./InterestForm.scss";
import { indexOf } from "underscore";
import FormKahoot from "./FormKahoot.js";

const ListFileElement = ({ file, onClickHandler }) => {
  const [loading, setLoading] = useState(true);
  const [thumb, setThumb] = useState(undefined);
  const [fileExtension, setFileExtension] = useState("");

  useEffect(() => {
    if (!file) {
      return;
    }

    if (file) {
      let reader = new FileReader();
      reader.onloadend = () => {
        setThumb(reader.result);
        setLoading(false);
      };
      reader.readAsDataURL(file);
    } else {
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!file) {
    return null;
  }
  if (loading) {
    return <p>loading...</p>;
  }
  return (
    <div className="d-flex justify-content-between align-items-center my-1 px-lg-3">
      {thumb ? (
        <img src={thumb} height={50} width={50} />
      ) : (
        <div
          style={{ height: 50, width: 50, backgroundColor: "#efefef" }}
          className="d-flex justify-content-center align-items-center"
        >
          <span className="fw-bold text-black">{fileExtension}</span>
        </div>
      )}

      <div className="w-60 text-truncate text-center">
        <span>{file.name}</span>
      </div>
      <button
        className="btn btn-outline-none btn-danger"
        type="button"
        onClick={() => onClickHandler(file)}
      >
        X
      </button>
    </div>
  );
};

const FormModal = ({
  handleModifyRT,
  requestRoadTrip,
  onHide,
  del,
  roadtrip,
  day,
  handleClose,
  modal,
  modalContent,
}) => {
  const config = useSelector((state) => state.data);
  const [handleRequest, status] = useRequest();
  const history = useHistory();
  const dispatch = useDispatch();
  const handleSubmit = (values, formikBag) => {
    if (values.category_id == "") {
      values.category_id = 69;
    }
    const formData = {
      ...values,
      files: values.files.map(file => ({file: file, language: values.languages || 'fr'})),
      medias: values.medias.map(media => {
        const {url, ...rest} = media;
        return ({...rest, language: rest.language || values.languages || 'fr'});
      }),
    };
    Object.keys(formData).forEach((key) => {
      if (formData[key] === null || formData[key] === undefined) {
        delete formData[key];
      }
    });
    if (modalContent) {
      handleRequest(
        "put",
        API_UPDATE_TS_ROADTRIP(modalContent.id),
        formData,
        config,
        (data, status) => {
          if (status === "Done") {
            handleModifyRT(data);
            handleClose();
          } else if (status === "Error") {
            console.error(data);
          }
        }
      );
    } else {
      handleRequest(
        "post",
        API_POST_CREATE_TS_ROADTRIP(roadtrip.id),
        formData,
        config,
        (data, status) => {
          if (status === "Done") {
            window.location.reload();
          } else if (status === "Error") {
            console.error(data);
          }
        }
      );
    }
  };

  return (
    <Formik
      initialValues={{
        additional_description_fr:
          modalContent?.additional_description_fr || null,
        additional_title_fr: modalContent?.additional_title_fr || null,
        title_fr: modalContent?.title_fr || null,
        description_fr: modalContent?.description_fr || null,
        day_number: day,
        address: null || modalContent?.address,
        category_id: "",
        video: modalContent?.video || null,
        medias: [...(modalContent?.medias || [])],
        files: [],
        provider: 'public-henri-trip',
        quizz: [],
        quizz_to_delete: [],
      }}
      onSubmit={handleSubmit}
    >
      {(formik) => (
        <InterestForm
          {...formik}
          del={del}
          statusAPI={status}
          modalContent={modalContent}
          handleClose={handleClose}
        />
      )}
    </Formik>
  );
};

const InterestForm = ({
  setFieldValue,
  handleSubmit,
  setStatus,
  status,
  handleChange,
  getFieldProps,
  modalContent,
  handleClose,
  statusAPI,
  values,
  handleUpdate,
}) => {
  const [quizzContent, setQuizzContent] = useState([]);
  const [longitude, setLongitude] = useState(null);
  const [latitude, setLatitude] = useState(null);
  const [openMap, setOpenMap] = useState(false);
  const [viewport, setViewport] = useState({
    latitude: latitude || 46.227638,
    longitude: longitude || 2.213749,
    zoom: 2,
    bearing: 0,
    pitch: 0,
  });
  const [show, setShow] = useState(true);
  const [showext, setShowExt] = useState(true);
  const [showtext, setShowText] = useState(true);
  const fileRef = useRef();
  const audioRef = useRef();
  const videoRef = useRef();
  const [state, setState] = useState();
  const [address, setAddress] = useState({
    address: undefined,
  });
  const [languages, setLanguages] = useState("fr");
  const [langue, setLangues] = useState([]);
  const [quizz, setQuizz] = useState(false);
  const [quizzImg, setQuizzImg] = useState([]);
  const categorieFilter = [
    { key: "Restaurant", text: "Restaurant", value: 63 },
    { key: "Site Touristique", text: "Site Touristique", value: 64 },
    { key: "Lieu immanquable", text: "Lieu immanquable", value: 65 },
    { key: "Hébergement", text: "Hébergement", value: 66 },
    { key: "Transport", text: "Transport", value: 67 },
    { key: "Vue", text: "Vue", value: 68 },
    { key: "Autre", text: "Autre", value: 69 },
  ];

  const lang_available = [
    { key: "Français", text: "Français", value: "fr", flag: "fr" },
    { key: "English", text: "English", value: "en", flag: "gb" },
    { key: "German", text: "German", value: "ge", flag: "de" },
    { key: "Italian", text: "Italian", value: "it", flag: "it" },
    { key: "Spanish", text: "Spanish", value: "sp", flag: "es" },
    { key: "Chinese", text: "Chinese", value: "cn", flag: "cn" },
    { key: "Arab", text: "Arab", value: "sa", flag: "sa" },
    { key: "Portuguese", text: "Portuguese", value: "pt", flag: "pt" },
    { key: "Japanese", text: "Japanese", value: "jp", flag: "jp" },
    { key: "Russian", text: "Russian", value: "ru", flag: "ru" },
  ];

  // useEffect(() => {
  //   modalContent?.custom_touristic_site_files
  //     ?.filter((m) => m.kahoot_id !== null)
  //     .map((media, index) => setQuizzImg((state) => [...state, media]));
  // }, []);
  useEffect(() => {
    if (modalContent?.kahoots?.length !== 0) {
      setQuizzContent(modalContent?.kahoots);
    } else {
      setQuizzContent([
        {
          question: "",
          reponse1: "",
          reponse2: "",
          reponse3: "",
          reponse4: "",
          // detail1: '',
          // detail2: '',
          // detail3: '',
          // detail4: '',
          good_answer: "",
          image: [],
          quizz_index: [],
        },
      ]);
    }
  }, []);

  useEffect(() => {
    if (modalContent) {
      // setFieldValue("rank", modalContent.rank)
      // setFieldValue("day_number", modalContent.day_number)
      setFieldValue("languages", languages);
      setFieldValue([`title_${languages}`], modalContent[`title_${languages}`]);
      setFieldValue(
        [`description_${languages}`],
        modalContent[`description_${languages}`]
      );
      setFieldValue(
        [`additional_description_${languages}`],
        modalContent[`additional_description_${languages}`]
      );
      setFieldValue(
        [`additional_title_${languages}`],
        modalContent[`additional_title_${languages}`]
      );
      setLangues((state) => [...state, languages]);
    }
  }, [languages]);

  const onClearAddress = () => {
    setAddress({
      address: null,
      locationIsLoading: false,
      buttonAddressDisabled: true,
    });
    setFieldValue("address", null);
    setFieldValue("address_int", null);
    setFieldValue("longitude", undefined);
    setFieldValue("latitude", undefined);
    setLongitude(undefined);
    setLatitude(undefined);
  };

  const handleShow = () => {
    setShow((state) => !state);
  };
  const handleShowExt = () => {
    setShowExt((state) => !state);
  };
  const handleShowText = () => {
    setShowText((state) => !state);
  };
  const onDeleteHandler = (key) => {
    const filesStateClone = values.files.filter(file => file.name !== key.name);
    setFieldValue('files', filesStateClone);
  };

  return (
    <>
      <Modal open={quizz} onClose={() => setQuizz(false)} size="large">
        <FormKahoot
          setStatus={setStatus}
          values={values}
          quizzImg={quizzImg}
          quizz={quizzContent}
          handleChange={handleChange}
          handleClose={handleClose}
          getFieldProps={getFieldProps}
          setFieldValue={setFieldValue}
          setQuizz={setQuizz}
        ></FormKahoot>
      </Modal>
      <form onSubmit={handleSubmit}>
        <div
          style={{
            backgroundColor: "#DCDCDC",
            height: "120px",
            position: "absolute",
            top: "-50px",
            width: " 100%",
            left: 0,
            zIndex: "-1",
          }}
        ></div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <span>
            <Dropdown
              id="languages"
              inline
              labeled
              onChange={(n, v) => setLanguages(v.value)}
              options={lang_available}
              defaultValue={lang_available[0].value}
            />
          </span>
          <Button
            className="btn-header__close"
            onClick={handleClose}
            title={<Close />}
          />
        </div>
        <br></br>
        <div className="d-flex justify-content-center w-90 mt-2">
          <label htmlFor="files">
            <btn className="btn btn-outline-none btn-primary" type="button">
              Selectionner une image
            </btn>
          </label>
        </div>
        <input
          id="files"
          multiple
          style={{ visibility: "hidden" }}
          accept="image/png, image/jpg, image/jpeg"
          name="image"
          type="file"
          onChange={(event) => {
            if (event.currentTarget.files.length >= 1) {
              if (event.currentTarget.files[0].size < 5000000) {
                if (event.currentTarget.files.length >= 1) {
                  const filteredFiles = [...event.currentTarget.files].filter(file => (
                    ["image/png", "image/jpg", "image/jpeg"].includes(file?.type)
                  ));
                  setFieldValue('files', [...values.files, ...filteredFiles]);
                }
                setStatus(null);
              } else {
                setStatus({ message: "Fichier trop lourd" });
              }
            }
          }}
        />
        {
          values.medias
            ?.filter(media => media.language === languages)
            ?.map((media, index) => (
              <div
                className="row mb-3 d-flex align-items-center justify-content-between"
                key={index}
              >
                <img src={media.url} height={50} width={50}/>
                <p>{media.content_type}</p>
                <div className="col-3 d-flex flex-row-reverse ">
                  <button
                    type="button"
                    className="btn btn-outline-none btn-danger"
                    onClick={() => {
                      const filesStateClone = values.medias.filter(file => file.id !== media.id);
                      setFieldValue('medias', filesStateClone);
                    }}
                  >
                    X
                  </button>
                </div>
              </div>
            ))
        }

        {
          values.files.filter(media => media.type.split('/')[0] === 'image').map((file, i) => (
            <ListFileElement
              file={file}
              key={i}
              onClickHandler={onDeleteHandler}
            />
          ))
        }

        {
          values.files.filter(media => media.type.split('/')[0] === 'audio').map((file, i) => (
            <ListFileElement
              file={file}
              key={i}
              onClickHandler={onDeleteHandler}
            />
          ))
        }

        {
          values.files.filter(media => media.type.split('/')[0] === 'video').map((file, i) => (
            <ListFileElement
              file={file}
              key={i}
              onClickHandler={onDeleteHandler}
            />
          ))
        }

        {
          values.files.filter(media => media.type.split('/')[0] === 'text').map((file, i) => (
            <ListFileElement
              file={file}
              key={i}
              onClickHandler={onDeleteHandler}
            />
          ))
        }
        <div className="form-group fv-plugins-icon-container">
          {status && <p className="text-danger">{status?.message}</p>}
          <label htmlFor="title">Nom de point d'interet *</label>
          <input
            required
            id="title"
            type="text"
            placeholder="Nom"
            onChange={(e) => {
              setFieldValue(`title_${languages}`, e.target.value);
            }}
            value={values[`title_${languages}`] || ""}
            className="form-control form-control-solid h-auto py-1 px-2"
          />
        </div>
        <div className="form-group fv-plugins-icon-container">
          <label htmlFor="description">Description du point d'intérêt *</label>
          <textarea
            required
            id="description"
            type="text"
            placeholder="Description"
            onChange={(e) => {
              setFieldValue(`description_${languages}`, e.target.value);
            }}
            value={values[`description_${languages}`] || ""}
            className="form-control form-control-solid h-auto py-1 px-2"
          />
        </div>
        <input
          hidden={showtext}
          id="video"
          placeholder="Titre complémentaire"
          type="text"
          onChange={(e) => {
            setFieldValue(`additional_title_${languages}`, e.target.value);
          }}
          className="form-control form-control-solid h-auto py-1 px-2"
          value={values[`additional_title_${languages}`] || ""}
          style={{ marginBottom: "2%" }}
        />
        <textarea
          hidden={showtext}
          id="video"
          placeholder={"Description complémentaire"}
          type="text"
          onChange={(e) => {
            setFieldValue(
              `additional_description_${languages}`,
              e.target.value
            );
          }}
          value={values[`additional_description_${languages}`] || ""}
          className="form-control form-control-solid h-auto py-1 px-2"
          style={{ marginBottom: "2%" }}
        />

        <div className="d-flex align-items-center" style={{ gap: "10px" }}>
          <div className="form-control form-control-solid h-auto py-1 px-2">
            <AutocompletePlaceAddress
              className="form-control form-control-solid h-auto py-1 px-2"
              placeholder={
                "Ex : 45 Rue Alfred Dumeril, 31000 Toulouse" ||
                modalContent?.address
              }
              onSelect={(a) => {
                setViewport((state) => ({
                  ...state,
                  longitude: a.geometry.coordinates[0],
                  latitude: a.geometry.coordinates[1],
                }));
                setLongitude(a.geometry.coordinates[0]);
                setLatitude(a.geometry.coordinates[1]);
                openMap
                  ? setFieldValue("address_int", a.place_name)
                  : setFieldValue("address", a.place_name);
                setFieldValue("longitude", a.geometry.coordinates[0]);
                setFieldValue("latitude", a.geometry.coordinates[1]);
              }}
              onClear={onClearAddress}
              onFocusInp={() => setState({ focus: true })}
              onBlurInp={() => setState({ focus: true })}
              worldWideSearch={openMap}
              required
            />
          </div>
          <SvgMarker
            onClick={() => {
              onClearAddress();
              setOpenMap((state) => !state);
            }}
          />
        </div>
        {openMap && (
          <div style={{ width: "100%", height: "400px" }}>
            <MapGL
              {...viewport}
              mapStyle={"mapbox://styles/mapbox/streets-v9"}
              onViewportChange={setViewport}
              width="100%"
              height={400}
              mapboxAccessToken={process.env.REACT_APP_MAPBOX_ACCESS_TOKEN}
              onClick={(map, event) => {
                setFieldValue("longitude", map.lngLat[0]);
                setFieldValue("latitude", map.lngLat[1]);
                setLongitude(map.lngLat[0]);
                setLatitude(map.lngLat[1]);
              }}
            >
              <NavigationControl />
              {longitude && latitude && (
                <Marker
                  longitude={longitude}
                  latitude={latitude}
                  offsetLeft={-10}
                  offsetTop={-20}
                >
                  <SvgMarker />
                </Marker>
              )}
            </MapGL>
          </div>
        )}
        <br></br>
        <Dropdown
          placeholder="Categorie"
          id="category_id"
          fluid
          className="ui floating upward dropdown"
          scrolling={true}
          selection
          value={getFieldProps("category_id").value}
          onChange={(n, v) => setFieldValue("category_id", v.value)}
          options={categorieFilter}
        />
        <br></br>

        <input
          hidden={show}
          style={{ marginBottom: "2%" }}
          id="video"
          placeholder="Lien vers la vidéo"
          type="text"
          onChange={handleChange}
          className="form-control form-control-solid h-auto py-1 px-2"
          {...getFieldProps("video")}
        />

        <input
          hidden={showext}
          id="external_link"
          placeholder="Lien externe"
          type="text"
          onChange={handleChange}
          className="form-control form-control-solid h-auto py-1 px-2"
          {...getFieldProps("external_link")}
        />
        <br></br>
        <span>Éléments disponibles :</span>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            marginTop: "2%",
          }}
        >
          <Button style={{ margin: "15px" }} icon onClick={handleShow}>
            <Icon name="youtube" />
          </Button>

          <input id="share" multiple={false} hidden />

          <Button style={{ margin: "15px" }} icon onClick={handleShowExt}>
            <Icon name="share square" />
          </Button>

          <input id="external_link" multiple={false} hidden />

          <Button style={{ margin: "15px" }} icon onClick={handleShowText}>
            <Icon name="edit" />
          </Button>

          <Button
            style={{ margin: "15px" }}
            icon
            onClick={() => audioRef.current.click()}
          >
            <Icon name="headphones" />
          </Button>
          <input
            id="audio"
            type="file"
            accept="audio/*"
            multiple={true}
            ref={audioRef}
            hidden
            onChange={(event) => {
              if (event.currentTarget.files.length >= 1) {
                const filteredFiles = [...event.currentTarget.files].filter(file => (
                  ['audio'].includes(file.type.split("/")[0])
                ));
                setFieldValue('files', [...values.files, ...filteredFiles]);
              }
            }}
          />

          <Button icon onClick={() => videoRef.current.click()}>
            <Icon name="video" />
          </Button>

          <input
            id="video_customs"
            accept="video/*"
            ref={videoRef}
            onChange={(event) => {
              if (event.currentTarget.files.length >= 1) {
                const filteredFiles = [...event.currentTarget.files].filter(file => (
                  ['video'].includes(file.type.split("/")[0])
                ));
                setFieldValue('files', [...values.files, ...filteredFiles]);
              }
            }}
            multiple={true}
            type="file"
            hidden
          />

          <Button icon onClick={() => setQuizz(true)}>
            <Icon name="question" />
          </Button>
        </div>

        <br></br>
        <div
          style={{
            display: "flex",
            justifyContent: "space-evenly",
            marginTop: "5%",
          }}
        >
          <Button
            title={"Annuler"}
            type="button"
            onClick={handleClose}
            className="mx-2"
            color="primary"
          />

          <Button
            status={statusAPI}
            title={"Enregistrer"}
            type="submit"
            className="mx-2"
            color="primary"
          />
        </div>
      </form>
    </>
  );
};
export default FormModal;
