import React from "react";
import PropTypes from "prop-types";

import Select from "react-select";

const styles = {
  container: provided => ({
    ...provided,
    height: "100%"
  }),
  control: (provided, state) => ({
    ...provided,
    height: "100%",
    borderWidth: 3,
    borderColor: state.isFocused ? "#000" : "#ced4da",
    padding: "0.15rem",
    backgroundColor: "#fff",
    width: "100%",
    ":hover": {
      borderColor: state.isFocused ? "#000" : "#ced4da"
    }
  }),
  dropdownIndicator: provided => ({
    ...provided,
    color: "#707070"
  }),
  menuList: provided => ({
    ...provided,
    height: "165px"
  })
}

const SelectFormik = props => {
  const classNames = [
    "form-group fv-plugins-icon-container",
    props.classNames
  ]
    .filter(el => el)
    .join(" ");

  const handleChange = (v, action) => {
    if (!props.setFieldValue) {
      props.onChange(v.value);
      return;
    }
    if (props.isMulti) {
      props.setFieldValue(
        props.name,
        v.map(el => el.value)
      );
      return;
    }
    props.setFieldValue(props.name, v?.value ?? 0);
  };

  const handleBlur = () => {
    if (props.setFieldTouched) props.setFieldTouched(props.name, true);
  };

  return (
    <div className={classNames}>
      {props.label && (
        <div className="d-flex justify-content-between">
          <label className="font-size-h6 text-dark">
            {props.label}
            {props.required ? " *" : ""}
          </label>
          {props.labelSuffix}
        </div>
      )}
      <Select
        {...props}
        onChange={handleChange}
        onBlur={handleBlur}
        placeholder={props.placeholder}
        styles={props.styles || styles}
      />
      {props.touched &&
      props.touched[props.name] &&
      props.errors[props.name] ? (
        <div className="fv-plugins-message-container">
          <div className="fv-help-block text-danger">
            {props.errors[props.name]}
          </div>
        </div>
      ) : null}
      {props.error && <p className="text-danger">{props.invalidMessage}</p>}
      {props.helper && <small>{props.helper}</small>}
    </div>
  );
};

SelectFormik.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    })
  ),
  classNames: PropTypes.string,
  isSearchable: PropTypes.bool,
  isMulti: PropTypes.bool,
  isDisabled: PropTypes.bool,
  placeholder: PropTypes.string,
  value: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  autoFocus: PropTypes.bool,
  defaultValue: PropTypes.object,
  label: PropTypes.string,
  onInputChange: PropTypes.func,
  error: PropTypes.bool,
  invalidMessage: PropTypes.string,
  noOptionsMessage: PropTypes.func,
  required: PropTypes.bool,
  helper: PropTypes.string
};

export default SelectFormik;
