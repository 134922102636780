import React, {useState, useEffect} from 'react';
import "./categoriesIcons.scss"
import { SvgIcon } from "@material-ui/core";
import { Add, DeleteOutlined } from "@material-ui/icons";

const CategoriesIcons = ({selected, name, color, icon, circle, movable, handleClick}) => {

  const [textColor, setTextColor] = useState("white")

  useEffect(() => {
    if(color === "#FFFFFF") {
      setTextColor("black");
    }
    if(color === "#F5E200") {
      setTextColor("black");
    }
  }, [color])


  return (
        circle ?
        <div className="card_container_circle" style={{ backgroundColor: color }}>
          <img src={icon} height="20" width="20" alt="icon" />
        </div>
        :
        movable ?
            <div className="card_container" style={{ backgroundColor: color }}>
              <div className="card_text" style={{ color: textColor }}>
                {name}
              </div>
              <div className="card_image">
                <img src={icon} height="26" width="26" alt="icon" />
              </div>
              <div className="card_container_movable ml-1" style={{ border: selected ? "" : "0.01vw solid", backgroundColor: selected ? "#F53F22" : "#FFFFFF" }} onClick={() => handleClick(name)}>
                <SvgIcon style={{ filter: selected ? "invert(1)" : "invert(0)" }} component={selected ? DeleteOutlined : Add} width="20" height="20" />
              </div>
            </div>
          :
          <div className="card_container" style={{ backgroundColor: color }}>
            <div className="card_text" style={{ color: textColor }}>
              {name}
            </div>
            <div className="card_image">
              <img src={icon} height="26" width="26" alt="icon" />
            </div>
          </div>
  );
};

export default CategoriesIcons;