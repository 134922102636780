
// eslint-disable-next-line
const MOBILE_REGEX = /^([0-9]+[ \.]?)+$/;
// eslint-disable-next-line
const EMAIL_REGEX = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const HAS_MAYUS = /[A-Z]+/;
const HAS_MINUS = /[a-z]+/;
const HAS_NBR = /[0-9][0-9]+/;

export const TABLET_WIDTH = 991;
export const PHONE_WIDTH = 750;

export const handleMobileNumber = (value) => {
    if (!value)
        return false;
    if (value?.match(MOBILE_REGEX))
        return false;
    return true;
}

export const handleEmail = (value) => {
    if (!value)
        return false;
    if (value?.match(EMAIL_REGEX))
        return false;
    return true;
}

export const isGoodPassword = (value) => {
    if (!value)
        return false;
    if (!value?.match(HAS_MAYUS) || !value?.match(HAS_MINUS) || !value?.match(HAS_NBR))
        return true;
    return false;
}

export const camelToSnake = (string) => {
    return string.replace(/[\w]([A-Z])/g, function (m) {
        return m[0] + "_" + m[1]
    }).toLowerCase();
}

export const keysToSnake = (o) => {
    if (o === Object(o) && !Array.isArray(o) && typeof o !== 'function') {
        const n = {};

        Object.keys(o).forEach(el => {
            n[camelToSnake(el)] = keysToSnake(o[el]);
        });
        return n;
    } else if (Array.isArray(o)) {
        return o.map(el => keysToSnake(el));
    }
    return o;
}

export const onlyGetModified = (newObj, oldObj) => {
    const changedObj = {};

    Object.keys(newObj).forEach(el => {
        if (newObj[el] !== oldObj[el]) {
            changedObj[el] = newObj[el];
        }
    })
    return changedObj;
}

export const capitalize = (string) => {
    return string?.charAt(0).toUpperCase() + string?.slice(1);
}

export const loadImage = (url, image) => {
    if (!image)
        return "";
    if (image.includes("assets/"))
        return image;
    if (image.includes("base64"))
        return image;
    if (url)
        return url + image;
    return image;
}

export function isEmpty(obj) {
    for(var prop in obj) {
        if(obj.hasOwnProperty(prop)) {
            return false;
        }
    }
    return JSON.stringify(obj) === JSON.stringify({});
}

export const toAbsoluteUrl = pathname => process.env.PUBLIC_URL + pathname;

export const toFormData = (v) => {
    const data = new FormData();

    Object.keys(v).forEach(el => {
        if (v[el] === undefined) {
            return
        }
        if (Array.isArray(v[el])) {
            v[el].forEach(elD => {
                if (typeof v[elD] === "boolean") {
                    v[elD] = v[elD] ? 1 : 0;
                }

                data.append(el + "[]", elD);
            })

            return;
        }

        if (typeof v[el] === "boolean") {
            v[el] = v[el] ? 1 : 0;
        }

        data.append(el, v[el]);
    })

    return data;
  }

export const toFormDataWithObject = (v) => {
    const data = new FormData();

    Object.keys(v).forEach((el) => {
        if (Array.isArray(v[el])) {
            v[el].forEach((elD) => {
                if (typeof elD === 'boolean') {
                    data.append(el + '[]', elD ? 1 : 0);
                    return;
                }
                if (typeof elD === 'object' && !(elD instanceof File)) {
                    Object.keys(elD).forEach((key) => {
                        data.append(el + `[][${key}]`, elD[key]);
                    });
                    return;
                }
                data.append(el + '[]', elD);
            });
            return;
        }

        if (typeof v[el] === 'boolean') {
            v[el] = v[el] ? 1 : 0;
        }

        data.append(el, v[el]);
    });

    return data;
};

export const transformSmallImageURL = (url) => {
    return url; // TODO
    const p = url;
    try {
      const urlObject = new URL(p);
      if(!urlObject.href.includes('touristic-site-henri-1-paris') && !urlObject.href.includes('roadtrip-henri-1-paris')){
          return url
      }
      const path = urlObject.pathname.slice(1).split('/');
      path.splice(path.length-1, 0, 'resized');
      const newPath = path.join('/');
      return p.replace(urlObject.pathname.slice(1), newPath)
    } catch (_) {
      return url
    }
}

export const metaAdder = (queryProperty, value) => {
    // Get an element if it exists already
    let element = document.querySelector(`meta[${queryProperty}]`);

    // Check if the element exists
    if (element) {
        // If it does just change the content of the element
        element.setAttribute("content", value);
    } else {
        // It doesn't exist so lets make a HTML element string with the info we want
        element = `<meta ${queryProperty} content="${value}" />`;

        // And insert it into the head
        document.head.insertAdjacentHTML("beforeend", element);
    }
};

export const linkAdder = (value) => {
    // Get an element if it exists already
    let element = document.querySelector("link[rel='canonical']");

    // Check if the element exists
    if (element) {
        // If it does just change the content of the element
        element.setAttribute("href", value);
    } else {
        // It doesn't exist so lets make a HTML element string with the info we want
        element = `<link rel="canonical" href="${value}" />`;
        // And insert it into the head
        document.head.insertAdjacentHTML("beforeend", element);
    }
};

const accentsMap = new Map([
    ["A", "Á|À|Ã|Â|Ä"],
    ["a", "á|à|ã|â|ä"],
    ["E", "É|È|Ê|Ë"],
    ["e", "é|è|ê|ë"],
    ["I", "Í|Ì|Î|Ï"],
    ["i", "í|ì|î|ï"],
    ["O", "Ó|Ò|Ô|Õ|Ö"],
    ["o", "ó|ò|ô|õ|ö"],
    ["U", "Ú|Ù|Û|Ü"],
    ["u", "ú|ù|û|ü"],
    ["C", "Ç"],
    ["c", "ç"],
    ["N", "Ñ"],
    ["n", "ñ"]
]);

export const categoryId = [53,54,55,56,57,58,59,60,61,62]
export const transportsId = [1,2,3,4,5,6,7]
export const groupsId = [8,9,10,11,12,13]
export const seasonsId = [14,15,16,17]

const reducer = (acc, [key]) =>
  acc.replace(new RegExp(accentsMap.get(key), "g"), key);

export const removeAccents = (text) => [...accentsMap].reduce(reducer, text);