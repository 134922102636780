import mapIcon1 from "components/maps/icons/mapIcons/mapIcon1";
import mapIcon10 from "components/maps/icons/mapIcons/mapIcon10";
import mapIcon2 from "components/maps/icons/mapIcons/mapIcon2";
import mapIcon3 from "components/maps/icons/mapIcons/mapIcon3";
import mapIcon4 from "components/maps/icons/mapIcons/mapIcon4";
import mapIcon5 from "components/maps/icons/mapIcons/mapIcon5";
import mapIcon6 from "components/maps/icons/mapIcons/mapIcon6";
import mapIcon7 from "components/maps/icons/mapIcons/mapIcon7";
import mapIcon8 from "components/maps/icons/mapIcons/mapIcon8";
import mapIcon9 from "components/maps/icons/mapIcons/mapIcon9";

const categories = {
    text_colors: {
        "#FFEE00": "dark",
        "#EF7E26": "white",
        "#967DB3": "white",
        "#E3CB9B": "dark",
        "#4DA1C4": "white",
        "#00A544": "white",
        "#9E7241": "white",
        "#A42422": "white",
        "#F2939E": "white",
        "#004B8C": "white"
    },
    custom_icons: {
        63: "https://general-henri-1-paris.s3.fr-par.scw.cloud/categories/thumbnail_restaurant.png",
        64: "https://general-henri-1-paris.s3.fr-par.scw.cloud/categories/thumbnail_placeholder-filled-point.png",
        65: "https://general-henri-1-paris.s3.fr-par.scw.cloud/categories/thumbnail_star.png",
        66: "https://general-henri-1-paris.s3.fr-par.scw.cloud/categories/thumbnail_bed.png",
        67: "https://general-henri-1-paris.s3.fr-par.scw.cloud/categories/thumbnail_bus.png",
        68: "https://general-henri-1-paris.s3.fr-par.scw.cloud/categories/thumbnail_binoculars.png",
        69: "https://general-henri-1-paris.s3.fr-par.scw.cloud/categories/thumbnail_more.png"
    },
    custom_icons_white: {
        63: "https://general-henri-1-paris.s3.fr-par.scw.cloud/categories/white_custom/thumbnail_restaurant_white.png",
        64: "https://general-henri-1-paris.s3.fr-par.scw.cloud/categories/white_custom/placeholder-filled-point_white.png",
        65: "https://general-henri-1-paris.s3.fr-par.scw.cloud/categories/white_custom/thumbnail_star_white.png",
        66: "https://general-henri-1-paris.s3.fr-par.scw.cloud/categories/white_custom/thumbnail_bed_white.png",
        67: "https://general-henri-1-paris.s3.fr-par.scw.cloud/categories/white_custom/thumbnail_bus_white.png",
        68: "https://general-henri-1-paris.s3.fr-par.scw.cloud/categories/white_custom/thumbnail_binoculars_white.png",
        69: "https://general-henri-1-paris.s3.fr-par.scw.cloud/categories/white_custom/thumbnail_more_white.png"
    },
    icons: {
        "#FFEE00": "/assets/icons/Fichier12.svg",
        "#EF7E26": "/assets/icons/Fichier4.svg",
        "#967DB3": "/assets/icons/Fichier14.svg",
        "#E3CB9B": "/assets/icons/Fichier10.svg",
        "#4DA1C4": "/assets/icons/Fichier8.svg",
        "#00A544": "/assets/icons/Fichier13.svg",
        "#9E7241": "/assets/icons/Fichier6.svg",
        "#A42422": "/assets/icons/Fichier7.svg",
        "#F2939E": "/assets/icons/Fichier3.svg",
        "#004B8C": "/assets/icons/Fichier9.svg"
    },
    icons_with_current_color: {
        "#FFEE00": "/assets/icons/ExperienceSVG/Fichier12.svg",
        "#EF7E26": "/assets/icons/ExperienceSVG/Fichier4.svg",
        "#967DB3": "/assets/icons/ExperienceSVG/Fichier14.svg",
        "#E3CB9B": "/assets/icons/ExperienceSVG/Fichier10.svg",
        "#4DA1C4": "/assets/icons/ExperienceSVG/Fichier8.svg",
        "#00A544": "/assets/icons/ExperienceSVG/Fichier13.svg",
        "#9E7241": "/assets/icons/ExperienceSVG/Fichier6.svg",
        "#A42422": "/assets/icons/ExperienceSVG/Fichier7.svg",
        "#F2939E": "/assets/icons/ExperienceSVG/Fichier3.svg",
        "#004B8C": "/assets/icons/ExperienceSVG/Fichier9.svg"
    },
    icons_category: {
        "#FFEE00": "https://general-henri-1-paris.s3.fr-par.scw.cloud/categories/touristic%20sites%20categories%20couleurs/Musee.png",
        "#EF7E26": "https://general-henri-1-paris.s3.fr-par.scw.cloud/categories/touristic%20sites%20categories%20couleurs/Activite.png",
        "#967DB3": "https://general-henri-1-paris.s3.fr-par.scw.cloud/categories/touristic%20sites%20categories%20couleurs/parcAttraction.png",
        "#E3CB9B": "https://general-henri-1-paris.s3.fr-par.scw.cloud/categories/touristic%20sites%20categories%20couleurs/Religieux.png",
        "#4DA1C4": "https://general-henri-1-paris.s3.fr-par.scw.cloud/categories/touristic%20sites%20categories%20couleurs/Chateaux.png",
        "#00A544": "https://general-henri-1-paris.s3.fr-par.scw.cloud/categories/touristic%20sites%20categories%20couleurs/parcJardin.png",
        "#9E7241": "https://general-henri-1-paris.s3.fr-par.scw.cloud/categories/touristic%20sites%20categories%20couleurs/Grotte.png",
        "#A42422": "https://general-henri-1-paris.s3.fr-par.scw.cloud/categories/touristic%20sites%20categories%20couleurs/Oenotourisme.png",
        "#F2939E": "https://general-henri-1-paris.s3.fr-par.scw.cloud/categories/touristic%20sites%20categories%20couleurs/bienEtre.png",
        "#004B8C": "https://general-henri-1-paris.s3.fr-par.scw.cloud/categories/touristic%20sites%20categories%20couleurs/choixHenri.png"
    },
    map_icons: {
        "#FFEE00": "/assets/icons/Fichier 22.svg",
        "#EF7E26": "/assets/icons/Fichier 16.svg",
        "#967DB3": "/assets/icons/Fichier 24.svg",
        "#E3CB9B": "/assets/icons/Fichier 21.svg",
        "#4DA1C4": "/assets/icons/Fichier 19.svg",
        "#00A544": "/assets/icons/Fichier 23.svg",
        "#9E7241": "/assets/icons/Fichier 17.svg",
        "#A42422": "/assets/icons/Fichier 18.svg",
        "#F2939E": "/assets/icons/Fichier 15.svg",
        "#004B8C": "/assets/icons/Fichier 20.svg"
    },
    map_icons_component: {
        "#FFEE00": mapIcon8,
        "#EF7E26": mapIcon2,
        "#967DB3": mapIcon10,
        "#E3CB9B": mapIcon7,
        "#4DA1C4": mapIcon5,
        "#00A544": mapIcon9,
        "#9E7241": mapIcon3,
        "#A42422": mapIcon4,
        "#F2939E": mapIcon1,
        "#004B8C": mapIcon6
    },
    images: {
        "#FFEE00": "/assets/img/categories/museum.jpg",
        "#EF7E26": "/assets/img/categories/activity.jpg",
        "#967DB3": "/assets/img/categories/parc_atraction.jpg",
        "#E3CB9B": "/assets/img/categories/religion.jpg",
        "#4DA1C4": "/assets/img/categories/castle.jpg",
        "#00A544": "/assets/img/categories/parc.jpg",
        "#9E7241": "/assets/img/categories/prehistorical.jpg",
        "#A42422": "/assets/img/categories/wines.jpg",
        "#F2939E": "/assets/img/categories/well_being.jpg",
        "#004B8C": "/assets/img/categories/henri_choice.jpg"
    },
    name: {
        53: "Activité",
        54: "Site religieux",
        55: "Parc / Jardin",
        56: "Oenotourisme / Spiritourisme",
        57: "Château / Monument historique",
        58: "Grotte / Site préhistorique",
        59: "Musée / Exposition",
        60: "Bien-être",
        61: "Parc d'attraction / Parc animalier",
        62: "Le choix d'Henri",
        63: "Restaurant",
        64: "Site Touristique",
        65: "Lieu exclusif",
        66: "Hébergement",
        67: "Transport",
        68: "Vue",
        69: "Autre"
    }
}

export default categories;