import React, {useState} from 'react';
import LocationOn from '@material-ui/icons/LocationOn';
import MapGL, {Marker, NavigationControl} from 'react-map-gl';
import {ReactComponent as SvgMarker} from '../../../assets/img/marker-icons/mapbox-marker-icon-red.svg';
import 'mapbox-gl/dist/mapbox-gl.css';

import mapboxgl from 'mapbox-gl';
import TouristicSiteMap from "../../TouristicSitesPage/TouristicSitePage/TouristicSiteMap";
// eslint-disable-next-line import/no-webpack-loader-syntax
mapboxgl.workerClass =
  // eslint-disable-next-line import/no-webpack-loader-syntax
  require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;

const MapModule = ({data}) => {
  const [viewport, setViewport] = useState({
    longitude: data.longitude,
    latitude: data.latitude,
    zoom: 16,
    bearing: 0,
    pitch: 0,
  });
  return (
    <div className="w-100 rounded my-3">
      <div className="d-flex align-items-center">
        <LocationOn fontSize="large" />
        <p className="m-0">
          {data.location && data.location !== ''
            ? data.location
            : `${data.longitude} ${data.latitude}`}
        </p>
      </div>
    <TouristicSiteMap long={data.longitude} lat={data.latitude}/>
    </div>
  )
};

export default MapModule;
