import {useContext, useEffect, useState} from 'react';
import {useFormik} from 'formik';
import styled, {ThemeContext} from 'styled-components';
import {useHistory} from 'react-router';
import {
  API_CREATE_VISIT_SHEET,
  API_PATCH_VISIT_SHEET,
  API_ORIGINAL_BLOB_URL
} from '../../../../utils/api';
import {mediaSchema,mainImageSchema} from '../../../_schemas/touristic_site_schema';
import Button from '../../../../_ui/_v2/components/Button';
import Loader from '../../../../_ui/_v2/components/Loader';
import {
  TextInput,
  FileInput,
  Form,
  FileInputLabel
} from '../../../../_ui/_v2/components/FormElements';
import {ReactComponent as ImageUploadIcon} from '../../../../assets/img/logo/image-upload-icon.svg';
import ActivityContext from "../../../../_contexts/ActivityContext";
import PlusIcon from "../_commons/PlusIcon";
import visitSheetSchema from "../../../_schemas/visit_sheet_schema";
import InterestPointContext from "../../../../_contexts/InterestPointContext";
import {useTranslation} from "react-i18next";

const visitSheetSchemaForActivityForm = visitSheetSchema.pick([
  'activity_id',
  'title',
  'medias',
]);

const FileInputContainer = styled.div`
  height: 200px;
  display: flex;
  background-color: ${props => props.theme.colors.fileInputEmpty};
  background-image: ${props => props.backgroundImageUrl ? `url('${props.backgroundImageUrl}')` : 'none'};
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  
  ${props => !props.backgroundImageUrl && `
  border: 2px dashed ${props.theme.colors.henriBlue};
  `}
`;

const InterestPointForm = ({ onSuccess, onCancel }) => {
  const {i18n} = useTranslation();
  const history = useHistory();
  const theme = useContext(ThemeContext);
  const [loading, setLoading] = useState(false);
  const { activity, setActivity } = useContext(ActivityContext);
  const { interestPoint, setInterestPoint } = useContext(InterestPointContext);
  console.log(interestPoint)
  const [previewUrlImage, setPreviewUrlImage] = useState();
  const [mainImageLayout, setMainImageLayout] = useState();

  const textKey = `text_${i18n.language}`;

  const token = localStorage.getItem("Authorization");

  useEffect(() => {
    const mainImage = interestPoint.layout?.find(item => item.type === 'MainImage');
    if (mainImage && JSON.stringify(mainImageLayout) !== JSON.stringify(mainImage)){
      setMainImageLayout(mainImageSchema.cast({
        ...mainImage,
        content: interestPoint.medias.find(m => m.blob_id === mainImage.content.blob_id)
      }));
    }
    else {
      setMainImageLayout(mainImageSchema.cast({}))
    }
  }, [interestPoint]);

  const handleSubmitForm = (values, actions) => {
    const payload = {
      ...visitSheetSchemaForActivityForm.cast(values),
      layout: [...(values.layout || [])].filter(item => item.type !== 'MainImage').concat([mainImageLayout]),
    };

    setLoading(true);
    fetch(
      interestPoint.id ? API_PATCH_VISIT_SHEET(interestPoint.id) : API_CREATE_VISIT_SHEET(),
      {
        method: interestPoint.id ? 'PATCH' : 'POST',
        cache: 'default',
        body: JSON.stringify({...values, ...payload, published: true}),
        headers: {
          "Authorization": `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        mode: 'cors',
      }
    )
      .then(res => res.json())
      .then(response => {
        setActivity(prev => ({...prev, ...response}));
        if (onSuccess) {
          onSuccess(response);
        } else {
          history.push(`/touristic-sites/${response.touristic_site_id}/edit`);//TODO export in a CONSTANTS file
        }
      })
      .catch(error => {
        console.error(error);
        setLoading(false);
      });
  };

  const {
    handleSubmit,
    handleChange,
    handleBlur,
    values,
    errors,
    touched,
    setFieldValue,
  } = useFormik({
    initialValues: visitSheetSchemaForActivityForm.cast(interestPoint),
    validationSchema: visitSheetSchemaForActivityForm,
    onSubmit: handleSubmitForm,
  });

  const fileImageUrl = mainImageLayout?.content?.blob_id
    ? API_ORIGINAL_BLOB_URL(mainImageLayout.content.signed_id, mainImageLayout.content.filename)
    : previewUrlImage;

  const mainImageIndex = mainImageLayout?.content?.blob_id ? values.medias.findIndex(item => item.blob_id === mainImageLayout?.content?.blob_id) : 0;
  const currentIndex = mainImageIndex === -1 ? (values.layout?.length || 0 ): mainImageIndex

  if ( !mainImageLayout){
    return null
  }

  return (
    <Form onSubmit={handleSubmit}>
      <FileInputContainer
        backgroundImageUrl={fileImageUrl}
      >
        <FileInput
          name={`medias[${currentIndex}].signed_id`}
          accept="image/*"
          activeStorageServiceName="activity_scaleway"
          onChange={(event) => {
            if (event.target.value !== mainImageLayout?.content?.signed_id) {
              setMainImageLayout(mainImageSchema.cast({content: {signed_id: event.target.value }}));
              setFieldValue(`medias[${currentIndex}]`, mediaSchema.cast({ signed_id: event.target.value }));
            } else {
              handleChange(event);
            }
          }}
          onBlur={handleBlur}
          value={values.medias?.[currentIndex]?.signed_id}
          hasError={errors.medias?.[currentIndex]?.signed_id && touched.medias?.[currentIndex]?.signed_id}
          errorMessage={errors.medias?.[currentIndex]?.signed_id}
          onBlobUrlChange={setPreviewUrlImage}
          style={{margin: 'auto'}}
        >
          {
            ({ready, globalProgress}) => (
              <FileInputLabel
                disabled={!ready}
                progress={globalProgress}
                hasError={errors.medias?.[currentIndex]?.signed_id && touched.medias?.[currentIndex]?.signed_id}
                style={{border: "none"}}
              >
                {ready
                  ? (
                    <>
                      <ImageUploadIcon style={{marginRight: '1em', height: 25 }}/>
                      {mainImageLayout?.content?.signed_id ? 'Modifier l\'image' : 'Ajouter une image'}
                    </>
                  ) : (
                    <>
                      <Loader style={{display: 'inline', margin: '0 1em 0 0', height: 25}}/>
                      {'Upload en cours'}
                    </>
                  )
                }
              </FileInputLabel>
            )
          }
        </FileInput>
      </FileInputContainer>
      <TextInput
        type="text"
        name={`title[${textKey}]`}
        onChange={handleChange}
        onBlur={handleBlur}
        value={values.title?.[textKey]}
        hasError={errors.title?.[textKey] && touched.title?.[textKey]}
        errorMessage={errors.title?.[textKey]}
        placeholder={'Votre titre'}
      />
      <div
        style={{
          display: 'flex',
          margin: '3em auto 0 auto',
          justifyContent: 'center',
          gap: '2em',
        }}
      >
        <Button
          rounded
          disabled={loading}
          onClick={onCancel}
          type="button"
        >
          <PlusIcon style={{transform: 'rotateZ(45deg)'}}/>
        </Button>
        <Button
          rounded
          disabled={loading}
          type="submit"
        >
          {
            loading
              ? <Loader size="1.5em" color={theme.colors.white}/>
              : 'Enregistrer'
          }
        </Button>
      </div>
    </Form>
  );
};

export default InterestPointForm;