import {useContext, useState} from 'react';
import {useFormik} from 'formik';
import styled, {ThemeContext} from 'styled-components';
import {API_ORIGINAL_BLOB_URL, API_PATCH_TOURISTIC_SITE} from '../../../utils/api';
import touristicSiteSchema, {mediaSchema} from '../../_schemas/touristic_site_schema';
import {TextInput, Form, FileInput, FileInputLabel} from '../../../_ui/_v2/components/FormElements';
import Button from '../../../_ui/_v2/components/Button';
import Loader from '../../../_ui/_v2/components/Loader';
import TouristicSiteContext from '../../../_contexts/TouristicSiteContext';
import {ReactComponent as ImageUploadIcon} from '../../../assets/img/logo/image-upload-icon.svg';

const touristicSiteSchemaForTouristicSitePhotoSpotForm = touristicSiteSchema.pick([
  'instagram_post_image',
  'instagram_post_link',
  'instagram_profile_link',
]);

const FileInputContainer = styled.div`
  height: 200px;
  display: flex;
  background-color: ${props => props.theme.colors.fileInputEmpty};
  background-image: ${props => props.backgroundImageUrl ? `url('${props.backgroundImageUrl}')` : 'none'};
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  
  ${props => !props.backgroundImageUrl && `
  border: 2px dashed ${props.theme.colors.henriBlue};
  `}
`;

const TouristicSitePhotoSpotForm = ({onSuccess, site: touristicSite = {}}) => {
  const theme = useContext(ThemeContext);
  const [loading, setLoading] = useState(false);
  const { setTouristicSite } = useContext(TouristicSiteContext);
  const [previewUrlImage, setPreviewUrlImage] = useState();

  const handleSubmitForm = (values, actions) => {
    const token = localStorage.getItem("Authorization");

    setLoading(true);
    fetch(
      API_PATCH_TOURISTIC_SITE(touristicSite.id),
      {
        method: 'PATCH',
        cache: 'default',
        body: JSON.stringify(values),
        headers: {
          "Authorization": `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        mode: 'cors',
      }
    )
      .then(res => res.json())
      .then(response => {
        setTouristicSite(response);
        setLoading(false);
        onSuccess?.(response);
      })
      .catch(error => {
        console.error(error);
        setLoading(false);
      });
  };

  const {
    handleSubmit,
    handleChange,
    handleBlur,
    values,
    errors,
    touched,
    setFieldValue,
  } = useFormik({
    initialValues: touristicSiteSchemaForTouristicSitePhotoSpotForm.cast(touristicSite),
    validationSchema: touristicSiteSchemaForTouristicSitePhotoSpotForm,
    onSubmit: handleSubmitForm,
  });

  const fileImageUrl = touristicSite.instagram_post_image?.blob_id
    ? API_ORIGINAL_BLOB_URL(touristicSite.instagram_post_image.signed_id, touristicSite.instagram_post_image.filename)
    : previewUrlImage;

  return (
    <Form onSubmit={handleSubmit}>
      <FileInputContainer
        backgroundImageUrl={fileImageUrl}
      >
        <FileInput
          name={`instagram_post_image.signed_id`}
          accept="image/*"
          activeStorageServiceName="touristic_site_scaleway"
          onChange={(event) => {
            if (event.target.value !== touristicSite.instagram_post_image?.signed_id) {
              setFieldValue(`instagram_post_image`, mediaSchema.cast({ signed_id: event.target.value }));
            } else {
              handleChange(event);
            }
          }}
          onBlur={handleBlur}
          value={values.instagram_post_image?.signed_id}
          hasError={errors.instagram_post_image?.signed_id && touched.instagram_post_image?.signed_id}
          errorMessage={errors.instagram_post_image?.signed_id}
          onBlobUrlChange={setPreviewUrlImage}
          style={{margin: 'auto'}}
        >
          {
            ({ready, globalProgress}) => (
              <FileInputLabel
                disabled={!ready}
                progress={globalProgress}
                hasError={errors.instagram_post_image?.signed_id && touched.instagram_post_image?.signed_id}
                style={{border: "none"}}
              >
                {ready
                  ? (
                    <>
                      <ImageUploadIcon style={{marginRight: '1em', height: 25 }}/>
                      {values.instagram_post_image?.signed_id ? 'Modifier l\'image' : 'Ajouter une image'}
                    </>
                  ) : (
                    <>
                      <Loader style={{display: 'inline', margin: '0 1em 0 0', height: 25}}/>
                      {'Upload en cours'}
                    </>
                  )
                }
              </FileInputLabel>
            )
          }
        </FileInput>
      </FileInputContainer>
      <TextInput
        type="text"
        name="instagram_post_link"
        onChange={handleChange}
        onBlur={handleBlur}
        value={values.instagram_post_link}
        hasError={errors.instagram_post_link && touched.instagram_post_link}
        errorMessage={errors.instagram_post_link}
        placeholder={'Lien du post instagram'}
      />
      <TextInput
        type="text"
        name="instagram_profile_link"
        onChange={handleChange}
        onBlur={handleBlur}
        value={values.instagram_profile_link}
        hasError={errors.instagram_profile_link && touched.instagram_profile_link}
        errorMessage={errors.instagram_profile_link}
        placeholder={'Lien du profil instagram'}
      />
      <Button
        rounded
        disabled={loading}
        type="submit"
        style={{
          display: 'block',
          margin: '3em auto 0 auto',
        }}
      >
        {
          loading
            ? <Loader size="1.5em" color={theme.colors.white}/>
            : 'Enregistrer'
        }
      </Button>
    </Form>
  );
};

export default TouristicSitePhotoSpotForm;