import React from 'react';
import useDocumentTitle from "../../hooks/use-document-title";
import AuthPageStructure from "./AuthPageStructure";
import { Link } from "react-router-dom";
import {useTranslation} from "react-i18next";

const ThanksYouPage = () => {
  const {t} = useTranslation(["henriTrip"])
  useDocumentTitle("Merci • Henri Trip")
  return(
    <AuthPageStructure>
          <div className="p-3 bg-success mb-3 text-white">
            {t("authPage.thanksYou")}<br/>
            {t("authPage.nextStep")} <Link to="/login">{t("authPage.nextStepLogin")}</Link> !
          </div>
    </AuthPageStructure>
  )
}

export default ThanksYouPage;