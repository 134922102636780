import React from 'react';
import useDocumentTitle from "../../hooks/use-document-title";
import AuthPageStructure from "./AuthPageStructure";
import { Link } from "react-router-dom";
import {useTranslation} from "react-i18next";

const ConfirmEmailPage = ({ valid }) => {
  useDocumentTitle("Confirmation de l'adresse email • Henri Trip")
  const {t} = useTranslation(["henriTrip"])

  if (valid === "Loading"){
    return <AuthPageStructure>
      <p>{t("authPage.processing")}...</p>
    </AuthPageStructure>
  }

  return(
    <AuthPageStructure>
      {
        valid === "Error" ?
          <div className="p-3 bg-danger mb-3 text-white">
            {t("authPage.linkExpired")}
          </div>
          :
          <div className="p-3 bg-success mb-3 text-white">
            {t("authPage.emailSuccess")}<br/>
            {t("authPage.nextStep")} <Link to="/login">{t("authPage.nextStepLogin")}</Link> !
          </div>
      }
    </AuthPageStructure>
  )
}

export default ConfirmEmailPage;