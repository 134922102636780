import React from "react";
import { Button } from 'components/form/button';
import Title from 'components/header/title/title';
import './PartnerSectionsPartner.scss';
import { useTranslation } from "react-i18next";



const PartnerSectionsPartner = () => {
  const {t} = useTranslation(["henriTrip"])
  return (

  <div className="container mt-3">
    <Title title={t("partner.partnerSections.title")} className="d-flex justify-content-center" />
    <div className="row mt-md-3">
      <div className="col-12 col-lg-4">
        <div className="row ">
          <div className="row patner_section_partner__taille-image">
            <div className="col-lg-4 col-sm-3 col-6 pt-2 d-flex justify-content-center">
              <img src="https://general-henri-1-paris.s3.fr-par.scw.cloud/partenaire_page/partenaires/Chamonix-logo.png"
                   className="partner-info__image__logos" alt="Chamonix-logo" />
            </div>
            <div className="col-lg-4 col-sm-3 col-6 d-flex justify-content-center">
              <img src="https://general-henri-1-paris.s3.fr-par.scw.cloud/partenaire_page/partenaires/Tignes-logo.png"
                   className="partner-info__image__tigne-logo" alt="Tignes-logo" />
            </div>
            <div className="col-lg-4 col-sm-3 col-6 pt-0 pt-lg-2 d-flex justify-content-center">
              <img
                src="https://general-henri-1-paris.s3.fr-par.scw.cloud/partenaire_page/partenaires/Val-disere-logo.png"
                className="partner-info__image__logos" alt="Val-disere-logo" />
            </div>
            <div className="col-lg-6 col-sm-3 col-6 d-inline d-lg-none">
              <img
                src="https://general-henri-1-paris.s3.fr-par.scw.cloud/partenaire_page/partenaires/Ax-3domaines-logo.png"
                className="partner-info__image__logos" alt="Ax-3domaines-logo" />
            </div>
            <div className="col-lg-6  col-12 d-flex justify-content-center">
              <img
                src="https://general-henri-1-paris.s3.fr-par.scw.cloud/partenaire_page/partenaires/Font-romeu-Pyrenees-2000-logo.png"
                className="partner-info__image__font_romeu-logo" alt="Font-romeu-Pyrenees-2000-logo" />
            </div>
            <div className="col-lg-6 col-12 d-none d-lg-inline">
              <img
                src="https://general-henri-1-paris.s3.fr-par.scw.cloud/partenaire_page/partenaires/Ax-3domaines-logo.png"
                className="partner-info__image__logos" alt="Ax-3domaines-logo" />
            </div>
          </div>
          <div className="col-12 col-md-12 mt-md-2 d-flex justify-content-center text-center">
            <p className="patner_section_partner__text">{t("partner.partnerSections.firstContent")}</p>
          </div>
        </div>
      </div>
      <div className="col-12 col-lg-4">
        <div className="row h-25 d-inline-block">
          <div className="row patner_section_partner__taille-image">
            <div className="col-12 d-flex justify-content-center">
              <img src="https://general-henri-1-paris.s3.fr-par.scw.cloud/partenaire_page/Social-media.png"
                   className="patner_section_partner__image" alt="Chamonix-logo" />
            </div>
          </div>
          <div className="col-12 col-md-12 mt-1 mt-md-2 d-flex justify-content-center text-center">
            <p className="patner_section_partner__text">{t("partner.partnerSections.secondContent")}</p>
          </div>
        </div>
      </div>
      <div className="col-12 col-lg-4">
        <div className="row  h-25 d-inline-block">
          <div className="row patner_section_partner__taille-image">
            <div className="col-12 d-flex justify-content-center">
              <img src="https://general-henri-1-paris.s3.fr-par.scw.cloud/partenaire_page/Billetteries.png"
                   className="patner_section_partner__image" alt="Chamonix-logo" />
            </div>
          </div>
          <div className="col-12 col-md-12 mt-1 mt-md-2 d-flex justify-content-center text-center">
            <p className="patner_section_partner__text">{t("partner.partnerSections.thirdContent")}</p>
          </div>
        </div>
      </div>
    </div>
    <div className="row mt-4 d-flex justify-content-center text-center">
      <Title title={t("partner.partnerSections.joinUs")} />
    </div>
    <div className="row mt-2 d-flex justify-content-center">
      <Button title={t("partner.requestDemo")}
              onClick={() => window.document.getElementById('searchBar').scrollIntoView({ block: "center" })} />
    </div>
  </div>)
}

export default PartnerSectionsPartner;
