import {forwardRef} from 'react';
import styled from 'styled-components';

const Label = styled.label`
  cursor: pointer;
  margin-bottom: .1rem;
  font-size: .75em;
  font-weight: 600;
`;

const LabelWrapper = forwardRef(({children, name, label, LabelComponent, ...rest}, ref) => (
  <div
    ref={ref}
    {...rest}
  >
    {label && <Label htmlFor={name}>{label}</Label>}
    {LabelComponent && <LabelComponent htmlFor={name}>{label}</LabelComponent>}
    {children}
  </div>
));

export default LabelWrapper;

export {
  Label,
};