import React, { useState } from "react";
import Title from 'components/header/title/title';
import HomeCard from 'components/homePage/homeCard/HomeCard';
import { Button } from 'components/form/button';
import "../../HomePage/DisconnectedHomeSectionsPage/HomeSectionShowMore.scss";
import PartnerSectionsSlider from "./PartnerSectionsSlider";
import { useTranslation } from "react-i18next";




const PartnerSectionsShowMore = () => {
  const {t} = useTranslation(["henriTrip"])

  const content = [
    {
      title : t("partner.showMoreSection.firstCard.title"),
      topDescription : t("partner.showMoreSection.firstCard.topDescription"),
      description : t("partner.showMoreSection.firstCard.description"),
      image : "https://general-henri-1-paris.s3.fr-par.scw.cloud/partenaire_page/acc1.jpg",
      margin: "mt-1 mt-md-3 mt-lg-2"
    },
    {
      title : t("partner.showMoreSection.secondCard.title"),
      topDescription : t("partner.showMoreSection.secondCard.topDescription"),
      description : t("partner.showMoreSection.secondCard.description"),
      image : "https://general-henri-1-paris.s3.fr-par.scw.cloud/partenaire_page/acc2.jpg",
      margin: "mt-4 mt-md-4 mt-lg-2"
    },
    {
      title : t("partner.showMoreSection.thirdCard.title"),
      topDescription : t("partner.showMoreSection.thirdCard.topDescription"),
      description : t("partner.showMoreSection.thirdCard.description"),
      image : "https://general-henri-1-paris.s3.fr-par.scw.cloud/partenaire_page/acc3.jpg",
      margin: "mt-4 mt-md-4 mt-lg-2"
    }
  ]

  const [open, setOpen] = useState(false);
  const handleClick = () => {
    setOpen(!open);
  }

  return (
    <section>
      <div className="w-100 d-flex justify-content-center pt-2 mt-3 mt-lg-4 ">
        <Button rounded className="px-4 " title={ open ? t("general.reduce") : t("general.showMore")} onClick={handleClick} />
      </div>
      {
        open &&
        <>
          <div className="container">
            <Title title={t("partner.showMoreSection.title")}/>
            <div className="row">
              {
                content?.map((el, i) =>
                  <div key={i} className="col-lg-4">
                    <HomeCard title={el.title} description={el.description} image={el.image} margin={el.margin} topDescription={el.topDescription} showMoreHomePage={false}/>
                  </div>
                )
              }
            </div>
            <div className="row mt-4 d-flex justify-content-center">
              <Button rounded title={t("partner.becomePartner")} onClick={() => window.document.getElementById('searchBar').scrollIntoView({block: "center"})}/>
            </div>
          </div>

          <PartnerSectionsSlider/>
        </>
      }
    </section>
  );
};

export default PartnerSectionsShowMore;