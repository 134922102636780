import { combineReducers } from 'redux';
import data from './app-data';
import user from './user';
import touristicSites from './touristicSites';
import categories from './categories';
import roadtrips from './road-trips';

export default combineReducers({
    data,
    user,
    touristicSites,
    categories,
    roadtrips,
});