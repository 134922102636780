import {useCallback, useContext, useEffect, useRef, useState} from 'react';
import FullScreenNavigationModal from '../../../_ui/_v2/components/FullScreenNavigationModal';
import InterestPointContext, {InterestPointProvider} from '../../../_contexts/InterestPointContext';
import ActivityContext from '../../../_contexts/ActivityContext';
import DayActivityDetails from '../experiences/DayActivityDetails';
import useOnScreen from '../../../hooks/useOnScreen';
import henriMapFrontUrl from '../../../utils/henri-map-front-url';
import {useTranslation} from "react-i18next";

const DayActivityDetailsWithContexts = () => {
  const {interestPoint} = useContext(InterestPointContext);

  return (
    <DayActivityDetails
      editable={false}
      sites={[interestPoint]}
      isNotHome={true}
      finalLap={true}
      selectedIndex={0}
      cardToFix={true}
      fullDetails={true}
      showAllVisitCards={true}
      style={{
        maxWidth: 1200,
        margin: "0 auto",
      }}
    />
  );
};

const Map = ({mapId}) => {
  const {i18n} = useTranslation();
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const {activity} = useContext(ActivityContext);
  const iframeRef = useRef();

  const isVisible = useOnScreen(iframeRef);

  const visitSheet = activity.visit_sheets?.find(vs => vs.id === modalIsOpen);

  const textKey = `text_${i18n.language}`;

  const handleMessage = useCallback((event) => {
      setModalIsOpen(event.data);
  }, [setModalIsOpen]);

  useEffect(() => {
    isVisible && window.addEventListener('message', handleMessage, false);
    return () => {
      isVisible && window.removeEventListener('message', handleMessage, false);
    }
  }, [handleMessage, isVisible]);

  return (
    <>
      <iframe
        ref={iframeRef}
        src={henriMapFrontUrl.maps.show(mapId)}
        referrerPolicy="origin"
        frameBorder="0"
        style={{
          width: "100%",
          height: "100%",
          border: 0,
        }}
      />
      {
        modalIsOpen && visitSheet && isVisible && (
          <FullScreenNavigationModal
            isOpen={!!modalIsOpen}
            onRequestClose={() => setModalIsOpen(false)}
            TitleComponent={<h1>{visitSheet?.title?.[textKey] || visitSheet?.title?.text_fr}</h1>}
            headerProps={{
              style: {
                width: "100%",
                maxWidth: 1200,
                alignSelf: "center",
                zIndex: 400000,
              },
            }}
          >
            <InterestPointProvider
              initialInterestPoint={{
                activity_id: activity?.id,
                ...(visitSheet || {}),
              }}
            >
              <DayActivityDetailsWithContexts/>
            </InterestPointProvider>
          </FullScreenNavigationModal>
        )
      }
  </>
  );
};

export default Map;