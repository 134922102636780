import * as React from "react"

function Icon5(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 88.56 106.9"
      style={{color: "#4DA1C4"}}
      {...props}
    >
      <defs>
        <style>{".prefix__cls-2{fill:#fff}"}</style>
      </defs>
      <g id="prefix__Calque_2" data-name="Calque 2">
        <g id="prefix__Calque_1-2" data-name="Calque 1">
          <path
            fill="currentColor"
            d="M44.28 0a44.27 44.27 0 00-31.16 75.73l31.16 31.17 31.16-31.17A44.27 44.27 0 0044.28 0"
          />
          <path
            className="prefix__cls-2"
            d="M28.65 56.52h31.26V35.09l5.84-9.4v-8.11h-8.59v5.8h-8.59v-5.8H40v5.8h-8.6v-5.8h-8.59v8.11l5.84 9.4zM40 39a4.29 4.29 0 014.29-4.29A4.29 4.29 0 0148.57 39v6.42H40zM28.65 59.32l-5.84 3.51v8.15h42.94v-8.15l-5.84-3.51H28.65z"
          />
        </g>
      </g>
    </svg>
  )
}

export default Icon5
