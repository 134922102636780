import NotificationPage from 'pages/UserPage/NotificationPage/NotificationPage';
import React from 'react';




const Notification = () => {

    return (
        <NotificationPage/>
    );
};

export default Notification;