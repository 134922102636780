import React, {useContext} from 'react';
import { Suspense } from 'react';
import { Redirect, Route, Switch, useHistory } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DndProvider } from 'react-dnd';

import {
  QueryParamProvider,
  transformSearchStringJsonSafe,
} from 'use-query-params';
import { addToken } from 'actions/token';
import Errors from 'pages/Errors';
import Home from 'containers/Home/Home';
import Search from 'containers/Search/Search';
import { Footer } from 'containers/Footer';
import SearchView from 'containers/Search/SearchView/SearchView';
import Login from 'containers/Auth/Login';
import Register from 'containers/Auth/Register';
import ForgotPassword from 'containers/Auth/ForgotPassword';
import ResetPassword from 'containers/Auth/ResetPassword';
import PrivateRoutes from './private-routes';
import User from 'containers/User/User';
import RoadTrips from 'containers/RoadTrips/RoadTrips';
import RoadTrip from 'containers/RoadTrips/RoadTrip/RoadTrip';
import ExperienceLanding from 'containers/RoadTrips/ExperienceLanding/ExperienceLanding';
import useRequest from 'hooks/use-request';
import { API_GET_CATEGORIES, API_GET_PROFILE, API_GET_USER_ROADTRIPS } from './api';
import { addCategories } from 'actions/categories';
import { addUser } from 'actions/user';
import Logout from 'containers/Auth/Logout';
import TouristicSites from 'containers/TouristicSites/TouristicSites';
import { addUserRoadTrips } from 'actions/road-trips';
import moment from 'moment';
import Partner from 'containers/Partner/Partner';
import PartnerDemo from 'containers/Partner/PartnerDemo';
import Ambassador from 'containers/Ambassador/Ambassador';
import Checkout from 'containers/Checkout/Checkout';
import ConfirmEmail from "../containers/Auth/ConfirmEmail";
import { Loader } from "semantic-ui-react";
import RoadTripPremium from "../containers/RoadTrips/RoadTripPremium/RoadTripPremium";
import ThanksYouPage from "../pages/AuthPage/ThanksYouPage";
import Activity from "../containers/Activity/Activity";
import CheckoutSuccessPage from "../pages/CheckoutPage/CheckoutSuccessPage";
import ExperienceToComplete from 'pages/UserPage/ExperiencePage/ExperienceToComplete';
import NavBarContext, {NavBarProvider} from "../_contexts/NavBarProvider";
import NewTouristicSite from "../containers/TouristicSites/TouristicSite/NewTouristicSite";
import TouristicSitesNewPage from "../pages/TouristicSitesPage/TouristicSitesNewPage";

/*
 Only for iframes from admin.henritrip.fr
 Do not use this function for an another use
*/
const getAccessTokenForIframeUseOnly = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const accessToken = urlParams.get('access_token');

  if (accessToken) {
    localStorage.setItem('Authorization', accessToken);
  }
};

const FooterWrapper = (props) => {
  const navBarContext = useContext(NavBarContext);

  return (
    <>
      {navBarContext.footerVisible() && <Footer {...props} />}
    </>
  );
};

const EscapeFromRouter = ({location}) => {
  window.location.assign(location.pathname + location.search);
  return null;
};

const Router = () => {
  const dispatch = useDispatch();
  const { user } = useSelector(state => state.user);
  const [handleRequest] = useRequest()
  const history = useHistory();

  const queryStringifyOptions = {
    transformSearchString: transformSearchStringJsonSafe,
  };

  React.useEffect(() => {
    getAccessTokenForIframeUseOnly();

    const token = localStorage.getItem("Authorization");

    handleRequest("get", API_GET_CATEGORIES, {}, {}, (data, status) => {
      if (status === "Done") {

        dispatch(addCategories(data))
      } else {
        console.error(data);
      }
    })

    if (token) {
      dispatch(addToken(token));

      handleRequest("get", API_GET_PROFILE, {}, { headers: { "Authorization": "Bearer " + token } }, (data, status) => {
        if (status === "Done") {
          const userData = {
            ...data.user,
            user_id: data.user.id,
            ...data.profile,
            profile_id: data.profile.id,
            gender: data.profile.gender,
            address: data.profile.address,
            city: data.profile.city,
            zipcode: data.profile.zipcode,
            birthdate: moment(data.profile.birthdate).format("DD/MM/YYYY") || ""
          };
          dispatch(addUser(userData));
        } else {
          console.error(data);
          history.push("/logout")
        }
      })

      handleRequest("get", API_GET_USER_ROADTRIPS, {}, { headers: { "Authorization": "Bearer " + token } }, (data, status) => {
        if (status === "Done") {
          const parsedRT = data.road_trips.map(el => {
            el.feedbacks = {
              note: el.avg_notes || 0,
              len: el.notes_counter,
            }
            el.user = {
              firstname: user?.firstname,
              lastname: user?.lastname,
            }
            return el;
          });

          dispatch(addUserRoadTrips(parsedRT))
        } else if (status === "Error") {
          console.error(data);
        }
      })
    }
    // eslint-disable-next-line
  }, [])

  return (
    <Suspense fallback={<Loader active className="mt-4 position-fixed" />}>
      <QueryParamProvider ReactRouterRoute={Route} stringifyOptions={queryStringifyOptions}>
        <DndProvider backend={HTML5Backend}>
          <NavBarProvider initialValues={{navBarName: 'default', footerVisible: true}}>
          {/* <ServiceWor kerWrapper /> */}
            <Switch>
              {process.env.NODE_ENV !== 'production' && <Route path="/" component={Home} exact />}
              <Route path="/home">
                <Redirect to="/" />
              </Route>
              <Route path="/login" component={Login} />
              <Route path="/register" component={Register} >
                {
                  user.id && <Redirect to="/" />
                }
              </Route>
              <Route path="/logout" component={Logout} />
              <Route path="/forgot-password" component={ForgotPassword} />
              <Route path="/reset-password" component={ResetPassword} />
              <Route path="/confirm-email" component={ConfirmEmail} />
              <Route path="/thanks-you" component={ThanksYouPage} />
              <Route path="/partner" component={Partner} />
              <Route path="/partner-demo" component={PartnerDemo} />
              <Route path="/ambassador" component={Ambassador} />
              <PrivateRoutes path="/user" component={User} />


              <Route path="/checkout" component={Checkout} />
              <Route path="/order/:orderId/complete" component={CheckoutSuccessPage} />
              <Route path="/touristic-site/:touristic_site_id/activity/:activity_uuid" component={Activity} />
              <Route path="/touristic-sites/new" component={TouristicSitesNewPage} />
              <Route path="/touristic-sites/:touristic_site_id/edit" render={(props) => ( <NewTouristicSite editable {...props}/> )} />
              <Route path="/touristic-sites/:touristic_site_id" component={NewTouristicSite} />
              <Redirect
                from="/touristic-site/:touristic_site_id"
                to="/touristic-sites/:touristic_site_id"
              />
              <Route path="/touristic-sites" component={TouristicSites} />
              <Route path="/search-view" component={SearchView} />
              <Route path="/checkout" component={Checkout} />
              <Route path="/roadtrips" component={RoadTrips} />
              <Route path="/roadtrip/:id" component={RoadTrip} />
              <Route path="/road-trip-premium/:id" component={RoadTripPremium} />
              <Route path="/trips/:id" render={(props) => ( <ExperienceLanding roadtripType="trip" {...props}/> )} />
              <Route path="/experiences/:id" render={(props) => ( <ExperienceLanding roadtripType="experience" {...props}/> )} />
              <Route path="/experience" component={ExperienceToComplete} />
              <Route path="/error" component={Errors} />
              <Route path="*" component={EscapeFromRouter} />
              <Redirect to="/error/404" />
            </Switch>
            <FooterWrapper />
          </NavBarProvider>
        </DndProvider>
      </QueryParamProvider>
    </Suspense>
  );
};

export default Router;