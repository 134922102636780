import {mediasTyped} from '../media';

const mainImage = (touristicSite) => {
  const mainImage = touristicSite.layout?.find(layoutItem => layoutItem.type === 'MainImage');

  const firstCarousel = touristicSite.layout?.find(layoutItem => layoutItem.type === 'Carousel');

  if (mainImage) {
    return touristicSite.medias?.find(media => media.blob_id === mainImage.content?.blob_id);
  } else if (firstCarousel) {
    return touristicSite.medias?.find(media => media.blob_id === firstCarousel.items?.[0]?.content?.blob_id);
  }

  return mediasTyped(touristicSite.medias).image?.[0];
};

export default mainImage;