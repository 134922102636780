import Title from 'components/header/title/title';
import React, { useEffect, useState, Suspense } from "react";
import { Link, useHistory } from 'react-router-dom';
import _ from "underscore";

import ImgLazy from "../../../components/Lazyload/ImgLazy/ImgLazy";
import { Button } from "components/form/button";
import useDocumentTitle from 'hooks/use-document-title';
import "./RoadTripPremium.scss"
import { HeaderButton } from "components/header/buttons";
import { useSelector } from "react-redux";

import ContentLoader from 'react-content-loader'
import { Loader } from "semantic-ui-react";
import TouristicSiteElementPremium from "../../../components/touristicSites/touristicSiteElement/TouristicSiteElementPremium";
import { useTranslation } from "react-i18next";
import { linkAdder, metaAdder, TABLET_WIDTH } from "../../../utils/utils";
import { SvgIcon } from "@material-ui/core";
import { PHONE_WIDTH } from '../../../utils/utils';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import { BottomSheet } from "react-spring-bottom-sheet";
import 'react-spring-bottom-sheet/dist/style.css'

import RoadTripPremiumMap from "./RoadTripPremiumMap";
import { pushUserRoadTrip } from 'actions/road-trips';
import useRequest from 'hooks/use-request';
import { useDispatch } from 'react-redux';
import ModalForm from "../../../components/formModal/ModalForm";
import useCart from "../../../hooks/Cart/use-cart";
import PlaceIcon from '@mui/icons-material/Place';
const ClassicPostLoader = props => {
  return (
    <ContentLoader viewBox="0 0 500 400" height={375} width={500} {...props}>
      <path
        d="M484.52,64.61H15.65C7.1,64.61.17,71.2.17,79.31V299.82c0,8.12,6.93,14.7,15.48,14.7H484.52c8.55,0,15.48-6.58,15.48-14.7V79.31C500,71.2,493.07,64.61,484.52,64.61Zm-9,204.34c0,11.84-7.14,21.44-15.94,21.44H436.39L359.16,171.52c-7.1-10.92-19.67-11.16-27-.51L258.64,277.94C253.78,285,245.73,286,240,280.2l-79.75-80.62c-6-6.06-14.33-5.7-20,.88L62.34,290.39H40.63c-8.8,0-15.94-9.6-15.94-21.44V110.19c0-11.84,7.14-21.44,15.94-21.44H459.54c8.8,0,15.94,9.6,15.94,21.44Z" />
      <rect x="0.53" y="328.35" width="87.36" height="16.48" rx="4.5" />
      <rect x="95.84" y="328.35" width="87.36" height="16.48" rx="4.5" />
      <rect x="195.38" y="328.35" width="304.45" height="16.48" rx="4.5" />
      <rect x="412.47" y="358.52" width="87.36" height="16.48" rx="4.5" />
      <rect x="291.22" y="358.52" width="113.31" height="16.48" rx="4.5" />
      <rect x="0.53" y="358.52" width="282.21" height="16.48" rx="4.5" />
    </ContentLoader>
  )
}

const HeaderMobile = ({ noTopPad, roadtrip }) => {

  return (
    <div>
      <Title noTopPad={noTopPad} roadtrip={roadtrip} title={roadtrip.title} />
    </div>
  )
}

export const TouristicSiteSection = ({ loading, rtTs, isUserRT, openTsModal, setTsModal, handlePurchase }) => {
  const { t } = useTranslation(["henriTrip"])
  const [openMap] = useState(window.innerWidth > PHONE_WIDTH);
  const [widthState, setWidthState] = useState(window.innerWidth);
  const sites = _.groupBy(rtTs, "day_number")
  const days = Object.keys(sites)
  useEffect(() => {
    function handleResize() {
      setWidthState(window.innerWidth)
    }
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize)
  }, []);
  return (
    <section className={`${openMap ? 'px-1' : ""}  ${widthState <= PHONE_WIDTH ? 'mx-0' : ''}`}>

      {
        loading ?
          <div className="position-relative">
            <Loader active className="mt-4" />
          </div>
          :
          days.length !== 0 ?
            days.map((day) =>
              <div key={day}
                className={`col-sm-12 col-md-6 ${days.length > 2 ? 'col-lg-4' : 'col-lg-6'} ${openMap ? 'pt-4' : ""} d-inline-block align-top roadtrip-day`}>
                <div style={{
                  display: "flex",
                  justifyContent: "center"
                }}>
                  <HeaderButton
                    text={`${t("roadTripPage.day")} ${day}`}
                    color="information-block"
                    className="mb-2 mx-0"
                  />
                </div>
                {
                  sites[day].slice(0, 1).map((site) =>
                    <TouristicSiteElementPremium openTsModal={openTsModal} setTsModal={setTsModal} key={site.id} el={site} />
                  )
                }
                {
                  sites[day].slice(1).map((site) =>
                    <div key={site.id} className="position-relative blur-roadtrip-container">
                      <TouristicSiteElementPremium openTsModal={openTsModal} setTsModal={setTsModal} key={site.id} el={site} />
                      <div className="blur-roadtrip d-flex justify-content-center align-items-center">
                        <HeaderButton
                          icon="/assets/icons/Fichier 40.svg"
                          className="lock-btn p-2"
                          color={"danger"}
                        />
                        <Button color={"danger"} className={`header-button roadtrip-auth__btn ${widthState <= PHONE_WIDTH ? 'p-1  my-3' : ''}`} rounded onClick={handlePurchase}>
                          <p className="py-0 m-0 pr-2">{t("general.buyRoadTrip")}</p>
                          <SvgIcon component={ShoppingCartOutlinedIcon} width="20" height="20" />
                        </Button>
                      </div>
                    </div>
                  )
                }
              </div>
            )
            :
            <>
              <p className="text-muted text-center px-2">{t("roadTripPage.noTouristicSite")} {
                isUserRT &&
                <Link to="/touristic-sites" className="text-primary font-weight-bold">{t("roadTripPage.noTouristicSiteUser")}</Link>

              }
              </p>
            </>
      }
    </section>
  )
}
const SearchViewPageMobile = ({ children, header }) => {
  return (
    <BottomSheet
      open
      blocking={false}
      defaultSnap={({ maxHeight }) => maxHeight / 5}
      header={header}
      snapPoints={({ maxHeight }) => [
        maxHeight - maxHeight / 6,
        90
      ]}
      className="bottom-sheet"
      style={{ zIndex: 111, position: "absolute" }}
    >
      {children}
    </BottomSheet>
  )
}

const RoadTripPremiumPage = ({ loading, roadtrip, rtTs, isUserRT, roadTripPremium }) => {
  const { t } = useTranslation(["henriTrip"])
  useDocumentTitle(`${roadtrip?.title ? roadtrip.title + " • " : ""}Road Trip • Henri Trip`)
  const history = useHistory();
  const [widthState, setWidthState] = useState(window.innerWidth);
  const [menuState, setMenuState] = useState(1);
  const { actions } = useCart();

  const [tsContent, setTsContent] = useState(null)
  const [tsModal, setTsModal] = useState(false);
  
  const openTsModal = (el) => {
    setTsContent(el)
    setTsModal(true);
  }
  const [btndisable, setBtnDisable] = useState(true)

  const [formModal, setFormModal] = useState({
    copy: false,
  });
  const [openMap, setOpenMap] = useState(false);

  const onMapClick = () => {

    setBtnDisable(state => !state)
    setOpenMap(state => !state)
  }
  useEffect(() => {
    if (roadtrip && roadtrip.title) {
      let keywords = roadtrip?.touristic_sites?.map(e => e.title).join(",")
      let description = `Découvrez et partagez avec la communauté d'Henri Trip l'ensemble des sites touristiques du road trip "${roadtrip?.title}": ${keywords}`.slice(0, 200)
      metaAdder('name="description"', description)
      metaAdder('name="author"', "Henri Trip")
      metaAdder('name="keywords"', "autour de moi, quoi faire, voyage, touriste, comparer, choses à faire, activités touristiques, tours, attractions, billets, trip, road, roadtrip, informations, henri trip, " + keywords)
      linkAdder(document.location.href)
    }
  }, [roadtrip]);

  useEffect(() => {
    function handleResize() {
      setWidthState(window.innerWidth)
    }
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize)
  }, []);
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  const sites = _.groupBy(rtTs, "day_number")
  const days = Object.keys(sites)
  const handlePurchase = () => {
    actions.setCart(
      {
        roadtrip: roadtrip,
        roadtrip_premium: roadTripPremium
      }
    )
    history.push('/checkout')
  }
  return (
    <>

      {
        widthState <= PHONE_WIDTH &&
        <>


          <div className={`${widthState >= PHONE_WIDTH && !openMap ? 'd-none' : 'col-12 col-md-6 px-0'} position-sticky`} style={{ zIndex: 100, top: 0 }}>



          </div>
        </>
      }
      <div className={`${openMap ? '' : 'container'}roadtrip-page min-vh-75${widthState <= PHONE_WIDTH ? 'px-0 py-3 mx-0 my-0' : ''} `} >
        <div className="row m-0">
          <div className={` col-md-${openMap ? 6 : 12} m-0 pr-0 pl-0 roadtrip-premium-image result-list ${widthState <= PHONE_WIDTH ? '' : 'mt-0'} ${widthState <= PHONE_WIDTH && openMap ? 'd-none' : ''}`}>
            <div style={{ backgroundColor: "white", position: "absolute", zIndex: "2", width: "20%", top: "20%" }}>
              <p style={{ fontWeight: "bold" }}>{days} jours</p>
            </div>
            {
              loading ?
                <ClassicPostLoader className="roadtrip-img " width="100%" height="100%" />
                :

                <ImgLazy src={roadtrip.picture} className=" roadtrip-premium__image" />

            }


            <div className="container">
              <div>
                <div className='exp_title'>
                  <PlaceIcon />
                  <p style={{
                    color: 'black',
                    fontWeight: '500',
                    fontSize: '1.5em'
                  }}>{roadtrip.title}</p>
                  {
                    widthState >= PHONE_WIDTH &&
                    <p style={{
                      color: 'white',
                      fontWeight: '300',
                      fontSize: '1.8em',
                      width: '60%',
                    }}>{roadtrip?.description?.slice(0, 300)}</p>
                  }

                </div>
                <div className='exp_menu'>
                  {/* if menu === planning  
                  peut etre faire avec int pour plus opti. 
                  charger les composants
                  
                
                */}
                  <ul>
                    <li onClick={() => setMenuState(1)}>
                      Planning
                    </li>
                    <li onClick={() => setMenuState(2)}>
                      Carte
                    </li>
                    {
                      roadtrip.tips !== null &&
                      <li onClick={() => setMenuState(3)}>
                        Astuce
                      </li>
                    }
                    {
                      roadtrip.review !== null &&
                      <li onClick={() => setMenuState(4)}>
                        Avis
                      </li>
                    }


                  </ul>


                  {
                    menuState == 1 &&


                    menuState == 2 && <RoadTripPremiumMap onMapClick={true} openMap={openMap} btndisable={btndisable} rtTs={rtTs} handlePurchase={handlePurchase} />

                  }

                </div>

                {
                  loading ?
                    <ContentLoader speed={2} width="100%" height={200} viewBox="0 0 500 200" backgroundColor="#f3f3f3"
                      foregroundColor="#ecebeb">
                      <rect x="0" y="15" rx="5" ry="5" width="220" height="10" />
                      <rect x="0" y="45" rx="5" ry="5" width="220" height="10" />
                      <rect x="0" y="77" rx="5" ry="5" width="100%" height="75" />
                    </ContentLoader>
                    :
                    <>

                      <div className={`${widthState <= PHONE_WIDTH ? 'd-flex flex-column-reverse' : 'row'} mt-3`}>
                        <div className={`${widthState <= PHONE_WIDTH ? '' : 'col-6'}`}>
                          <div className={`${widthState <= PHONE_WIDTH ? 'row justify-content-end pb-3' : 'd-flex justify-content-end'}`}>


                          </div>
                        </div>
                      </div>
                    </>
                }
              </div>

              <TouristicSiteSection rtTs={rtTs} loading={loading} isUserRT={isUserRT} openTsModal={openTsModal} setTsModal={setTsModal} handlePurchase={handlePurchase} />
            </div>
          </div>
          {
            widthState >= PHONE_WIDTH &&
            <div className={`${widthState >= PHONE_WIDTH && !openMap ? 'd-none' : 'col-12 col-md-6 px-0'}`}>

              <div className="map-rt col-12 col-md-12 px-0 d-block position-sticky" style={{ width: "100%", height: "calc(100vh - 89px)", top: 89 }}>
                {
                  roadtrip?.touristic_sites &&
                  <Suspense fallback={<Loader active className="mt-4 fixed-top" />}>
                    <RoadTripPremiumMap onMapClick={onMapClick} btndisable={btndisable} openMap={openMap} rtTs={rtTs} handlePurchase={handlePurchase} />
                  </Suspense>
                }
              </div>

            </div>
          }
        </div>
        <CopyRoadTrip show={formModal.copy} onHide={() => setFormModal(state => ({ ...state, copy: false }))}
          id={roadtrip.id} />
      </div>
    </>
  );
}

export default RoadTripPremiumPage;







const CopyRoadTrip = ({ show, onHide, id }) => {
  const [handleRequest, status] = useRequest();
  const config = useSelector(state => state.data);
  const dispatch = useDispatch()
  const history = useHistory();

  const handleCopy = () => {
    handleRequest("get", `/api/v1/road_trip_premium/${id}/duplicate`, {}, config, (data, status) => {
      if (status === "Done") {
        const parsedRoadTrip = data
        parsedRoadTrip.feedbacks = {
          note: parsedRoadTrip.avg_notes,
          len: parsedRoadTrip.notes_counter,
          comments: []
        }
        dispatch(pushUserRoadTrip(parsedRoadTrip));
        history.push(`/roadtrip/${data.id}`)
      } else if (status === "Error") {
        console.error(data);
      }
    })
  }

  return (
    <ModalForm
      isOpen={show}
      onClose={onHide}
      title="Voulez-vous enregistrer ce road trip ?"
    >
      <div className="p-3">
        <p>Toutes les données de ce road trip seront copiées dans un road trip qui vous appartiendra. Vous aurez ainsi une copie que vous pourrez modifier à votre goût.</p>
        <div className="d-flex justify-content-between">
          <Button title="Annuler" color="secondary" className="mr-1" extended onClick={onHide} />
          <Button title="Enregistrer" color="henri" className="ml-1" extended onClick={handleCopy} status={status === "Loading" ? status : null} disabled={status === "Loading"} />
        </div>
      </div>
    </ModalForm>
  );
};