import React from "react";
import styled from "styled-components";

import {ReactComponent as ArrowRightIcon} from "../../../../assets/img/logo/arrowRight.svg";

const ArrowStyled = styled.div`
  display: block;
  width: 35px;
  height: 35px;
  padding: 5px;
  border: 1px solid;
  border-radius: 50%;
  vertical-align: middle;
  background-color: ${props => props.theme.colors.white60};
  transition: transform 0.5s ease-in-out;
  cursor: pointer;
  user-select: none;
  box-sizing: border-box;
  overflow: visible;
  min-width: 35px;
  min-height: 35px;
  
  :hover {
    transform: scale(120%);
  }
  
  svg {
    height: 100%;
    display: block;
    margin: auto auto;
    transform: ${props => props.direction === 'left' ? 'rotate(180deg)' : 'none'};
  }
  
  ${props => props.disabled && `
    opacity: 50%;
    cursor: default;
    
    :hover {
      transform: none;
    }
  `}
`;
const Arrow = (props) => (
  <ArrowStyled {...props}>
    <ArrowRightIcon/>
  </ArrowStyled>
);

export default Arrow;