import groupBy from "./groupBy";

const mediasTyped = medias => {
  if (!medias || medias.length === 0) {
    return {};
  }
  return groupBy(
    medias.filter(m => m !== undefined),
      media => media.content_type.split('/')[0]
  );
};

export {
  mediasTyped,
} ;
