import * as React from "react"

function Icon8(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 88.56 106.9"
      style={{color: "#FFEE00"}}
      {...props}
    >
      <defs>
        <style>{".prefix__cls-2{fill:#fff}"}</style>
      </defs>
      <g id="prefix__Calque_2" data-name="Calque 2">
        <g id="prefix__Calque_1-2" data-name="Calque 1">
          <path
            fill="currentColor"
            d="M44.28 0a44.27 44.27 0 00-31.16 75.73l31.16 31.17 31.16-31.17A44.27 44.27 0 0044.28 0"
          />
          <path
            className="prefix__cls-2"
            d="M71.43 64.9V71h-54.3v-6.1zM22.24 37.16V62.1h44.08V37.16h-10V59.3h-7V37.16h-10V59.3h-7V37.16zM44.28 17.58L17.13 34.36h54.3L44.28 17.58z"
          />
        </g>
      </g>
    </svg>
  )
}

export default Icon8
