import * as React from "react"

function Icon6(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 88.56 106.9"
      style={{color: "#004B8C"}}
      {...props}
    >
      <defs>
        <style>{".prefix__cls-2{fill:#fff}"}</style>
      </defs>
      <g id="prefix__Calque_2" data-name="Calque 2">
        <g id="prefix__Calque_1-2" data-name="Calque 1">
          <path
            fill="currentColor"
            d="M44.28 0a44.27 44.27 0 00-31.16 75.73l31.16 31.17 31.16-31.17A44.27 44.27 0 0044.28 0"
          />
          <path
            className="prefix__cls-2"
            d="M44.63 30.29c4.07 0 7.89.2 11.26.54v-3.57a9.74 9.74 0 00-9.72-9.72h-3.73a9.74 9.74 0 00-9.72 9.72v3.67a98.18 98.18 0 0111.91-.64zM57.63 33.36a16 16 0 00-3.43.35 48.16 48.16 0 01-9.28.89h-1.29a48.13 48.13 0 01-9.27-.89 20.05 20.05 0 00-3.43-.35c-3 0-5.41 1.1-5.41 2.44s2.44 2.43 5.41 2.43h1.74c0-1.79 5.21-3.28 11.61-3.28s11.61 1.49 11.61 3.28h1.74c3 0 5.41-1.1 5.41-2.43s-2.39-2.44-5.41-2.44zM47.9 47.46a7.59 7.59 0 01-3.62.79 7.73 7.73 0 01-3.62-.79A10.05 10.05 0 0037.18 64l7 7 7.05-7a10 10 0 003-7.15 9.81 9.81 0 00-6.33-9.39zm-3.62 13.79a4.42 4.42 0 114.42-4.42 4.43 4.43 0 01-4.42 4.42z"
          />
        </g>
      </g>
    </svg>
  )
}

export default Icon6
