import { css } from 'styled-components';

const commonStyleMixin = css`
  display: block;
  box-sizing: border-box;
  margin: 0;
  padding: 10px 12px;
  border: 1px solid ${props => props.theme.colors.darkGrey};
  border-radius: 5px;
  background-color: ${props => props.theme.colors.white};
  width: 100%;
  
  &::placeholder {
    color: ${props => props.theme.colors.black};
  }
  
  ${props => props.hasError && `
  border-color: ${props.theme.colors.error};
  `}
`;

export { commonStyleMixin };