import React, {useEffect, useState} from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';


const ImageVS = ({data}) => {
  const [urls, setUrls] = useState(
    data ? data.filter((el) => el.id).map((el) => el.url.replace(/\?.*/, '')) : []
  );

  useEffect(() => {
    setUrls(
      data ? data.filter((el) => el.id).map((el) => el.url.replace(/\?.*/, '')) : []
    );
  }, [data]);
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <div className="w-100 my-3" style={{maxHeight: 500}}>
      <Slider {...settings}>
        {urls.map((el, index) => {
          return (
            <div key={index} className="w-100 h-100">
              <img
                src={el}
                alt={el}
                style={{
                  width: '90%',
                  maxHeight: 500,
                  objectFit: 'contain',
                  objectPosition: 'center',
                  borderRadius: '1em'}}
              />
            </div>
          );
        })}
      </Slider>
    </div>
  );
};

export default ImageVS;
