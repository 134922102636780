import React, { useState } from "react";

import {toAbsoluteUrl} from 'utils/utils';
import SVG from 'react-inlinesvg';
import categoryIcons from 'utils/categories';
import Feedback from 'components/informations/feedback/feedback';
import "./touristic-site-map-popup.scss"
import "../touristicSiteCard/touristicSiteCard.scss"
import RoomOutlinedIcon from '@mui/icons-material/RoomOutlined';
import { useTranslation } from "react-i18next";
import { HeaderButton } from "components/header/buttons";
import { Button } from "components/form/button";
import Login from "../../../containers/Auth/Login";
import ModalForm from "../../../components/formModal/ModalForm";
import {mediasTyped} from "../../../utils/media";


const SvgIcon = ({ color }) => {
  return (
    <SVG width="20" color={color} style={{marginRight: "4px"}} height="20" src={toAbsoluteUrl(categoryIcons.icons[color])} />
  )
}

const SvgIconCustom = ({ id }) => {
  return (
    <img alt={"categories icon"} width="20" style={{marginRight: "4px"}} height="20" color="#000000" src={toAbsoluteUrl(categoryIcons.custom_icons[id])} />
  )
}

const TouristicSiteMapPopup = ({ data, openTsModal, user, checkedPassword }) => {
  const { t } = useTranslation(["henriTrip"])
  const [open, setOpen] = useState(false);
  const openModal = () => {
    setOpen(true);
  }
  const style = {
    marginTop: "6%"
  }

  const image = mediasTyped(data.medias).image?.[0];

  return (
    <>
      <ModalForm
        isOpen={open}
        title={t("register.title")}
        onClose={() => setOpen(false)}>
        <Login modal={true} handleSubmit={() => setOpen(false)} handleClose={() => setOpen(false)} />
      </ModalForm>
      <div className="popup-widget__touristiccard">
        
        {
          data.custom  ?

            <div onClick={()=>openTsModal(data)}>
                  <img src={data?.image || "https://general-henri-1-paris.s3.fr-par.scw.cloud/woman.jpg"} className="popup-widget__image" alt="..."/>
            </div>

            :

            <a href={`https://www.henritrip.fr/touristic-site/${data.id}`} target="_blank" >
              {
                image &&
                  <img src={image.url || "https://general-henri-1-paris.s3.fr-par.scw.cloud/woman.jpg"} className="popup-widget__image" alt="..."/>
              }
            </a>

        }

        <div className="popup-widget__touristicBody" style={ data?.feedbacks?.note === 0 ? style : {}}>
          {
            data.custom  ?
              <h6 className="popup-widget__title" onClick={()=>openTsModal(data)}>
                <SvgIconCustom id={data.category_id} />
                {data.title_fr}
              </h6>
              :
              <a href={`https://www.henritrip.fr/touristic-site/${data.id}`} target="_blank">
                <h6 className="popup-widget__title">
                  <SvgIcon color={data.category.color} />
                  {data.title_fr}
                </h6>
              </a>
          }
          {
            data.zipcode &&
            <div>
              <span className="popup-widget__iconCat"><RoomOutlinedIcon /></span>
              {`${data.zipcode} ${data.city}`}
            </div>
          }
          <Feedback className="p-0 mt-1" size="sm" note={data?.feedbacks?.note} feedbacks={data?.feedbacks?.len}  />
        </div>
      </div>
    </>
  );
};

export default TouristicSiteMapPopup;