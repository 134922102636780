import { pushUserRoadTrip } from 'actions/road-trips';
import { Button } from 'components/form/button';
import useRequest from 'hooks/use-request';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { API_COPY_ROADTRIP } from 'utils/api';
import ModalForm from "../../../../components/formModal/ModalForm";
import {useHistory} from "react-router-dom";

const CopyRoadTrip = ({ show, onHide, id }) => {
  const [handleRequest, status] = useRequest();
  const config = useSelector(state => state.data);
  const dispatch = useDispatch()
  const history = useHistory();

  const handleCopy = () => {
    handleRequest("get", API_COPY_ROADTRIP(id), {}, config, (data, status) => {
      if (status === "Done") {
        const parsedRoadTrip = data
        parsedRoadTrip.feedbacks = {
          note: parsedRoadTrip.avg_notes,
          len: parsedRoadTrip.notes_counter,
          comments: []
        }
        dispatch(pushUserRoadTrip(parsedRoadTrip));
        history.push(`/roadtrip/${data.id}`)
        onHide();
      } else if (status === "Error") {
        console.error(data);
      }
    })
  }

  return (
    <ModalForm
      isOpen={show}
      onClose={onHide}
      title="Voulez-vous enregistrer ce road trip ?"
    >
      <div className="p-3">
        <p>Toutes les données de ce road trip seront copiées dans un road trip qui vous appartiendra. Vous aurez ainsi une copie que vous pourrez modifier à votre goût.</p>
        <div className="d-flex justify-content-between">
          <Button title="Annuler" color="secondary" className="mr-1" extended onClick={onHide} />
          <Button title="Enregistrer" color="henri" className="ml-1" extended onClick={handleCopy} status={status === "Loading" ? status : null} disabled={status === "Loading"}/>
        </div>
      </div>
    </ModalForm>
  );
};

export default CopyRoadTrip;