import {array, number, object, string} from "yup";

const REQUIRED_MESSAGE = 'Champ requis';

const imageSchema = object({
  type: string().default('Image').trim().required(REQUIRED_MESSAGE),
  content: object({
    signed_id: string().default('').trim().required(REQUIRED_MESSAGE),
  }),
});

const carouselSchema = object({
  type: string().default('Carousel').trim().required(REQUIRED_MESSAGE),
  items: array().of(imageSchema).ensure().min(1).default([]),
});

const videoSchema = object({
  type: string().default('Video').trim().required(REQUIRED_MESSAGE),
  content: object({
    signed_id: string().default('').trim().required(REQUIRED_MESSAGE),
  }),
});

const audioSchema = object({
  type: string().default('Audio').trim().required(REQUIRED_MESSAGE),
  content: object({
    signed_id: string().default('').trim().required(REQUIRED_MESSAGE),
    language: string().default('').transform(value => value === null ? '' : value)
  }),
});

const titleSchema = object({
  type: string().default('Title').required(REQUIRED_MESSAGE),
  content: object({
    text_fr: string().default('').transform((_, value) => value === null ? '' : value),
    text_en: string().default('').transform((_, value) => value === null ? '' : value),
    text_sp: string().default('').transform((_, value) => value === null ? '' : value),
    text_ge: string().default('').transform((_, value) => value === null ? '' : value),
    text_it: string().default('').transform((_, value) => value === null ? '' : value),
    text_cn: string().default('').transform((_, value) => value === null ? '' : value),
    text_sa: string().default('').transform((_, value) => value === null ? '' : value),
    text_pt: string().default('').transform((_, value) => value === null ? '' : value),
    text_jp: string().default('').transform((_, value) => value === null ? '' : value),
    text_ru: string().default('').transform((_, value) => value === null ? '' : value),
  }),
});

const textSchema = object({
  type: string().default('Text').required(REQUIRED_MESSAGE),
  content: object({
    text_fr: string().default('').transform((_, value) => value === null ? '' : value),
    text_en: string().default('').transform((_, value) => value === null ? '' : value),
    text_sp: string().default('').transform((_, value) => value === null ? '' : value),
    text_ge: string().default('').transform((_, value) => value === null ? '' : value),
    text_it: string().default('').transform((_, value) => value === null ? '' : value),
    text_cn: string().default('').transform((_, value) => value === null ? '' : value),
    text_sa: string().default('').transform((_, value) => value === null ? '' : value),
    text_pt: string().default('').transform((_, value) => value === null ? '' : value),
    text_jp: string().default('').transform((_, value) => value === null ? '' : value),
    text_ru: string().default('').transform((_, value) => value === null ? '' : value),
  }),
});

const titleTextSchema = object({
  type: string().default('TitleText').required(REQUIRED_MESSAGE),
  items: array().ensure().min(2).max(2).test('is-TitleTextItem',
    (d) => `${d.path} is not a TitleTextItem`,
    (value) => ((textSchema.isValidSync(value[0]) && titleSchema.isValidSync(value[1])) || (textSchema.isValidSync(value[1]) && titleSchema.isValidSync(value[0])))
  ).transform((_, value)=>value.map((item) => item?.type === 'Title' ? titleSchema.cast(item) : textSchema.cast(item)))
  ,
})

const videoTitleSchema = object({
  type: string().default('TitleVideo').trim().required(REQUIRED_MESSAGE),
  items: array().ensure().min(2).max(2).test('is-VideoTitleItem',
    (d) => `${d.path} is not a VideoTitleItem`,
    (value) => ((videoSchema.isValidSync(value[0]) && titleSchema.isValidSync(value[1])) || (videoSchema.isValidSync(value[1]) && titleSchema.isValidSync(value[0])))
    ).transform((_, value)=>value.map((item) => item?.type === 'Title' ? titleSchema.cast(item) : videoSchema.cast(item)))
  ,
});

const audioTitleSchema = object({
  type: string().default('TitleAudio').trim().required(REQUIRED_MESSAGE),
  items: array().ensure().min(2).max(2).test('is-AudioTitleItem',
    (d) => `${d.path} is not a AudioTitleItem`,
    (value) => ((audioSchema.isValidSync(value[0]) && titleSchema.isValidSync(value[1])) || (audioSchema.isValidSync(value[1]) && titleSchema.isValidSync(value[0])))
  ).transform((_, value)=>value.map((item) => item?.type === 'Title' ? titleSchema.cast(item) : audioSchema.cast(item)))
  ,
});

const videoTitleImageSchema = object({
  type: string().default('VideoTitleImage').trim().required(REQUIRED_MESSAGE),
  items: array().ensure().min(3).max(3).test('is-VideoTitleImage',
    (d) => `${d.path} is not a VideoTitleImage`,
    (value) => ((videoSchema.isValidSync(value[0]) && titleSchema.isValidSync(value[1])) && imageSchema.isValidSync(value[2])
      ||
      (videoSchema.isValidSync(value[0]) && titleSchema.isValidSync(value[2]) && imageSchema.isValidSync(value[1]))
      ||
      (videoSchema.isValidSync(value[1]) && titleSchema.isValidSync(value[0]) && imageSchema.isValidSync(value[2]))
      ||
      (videoSchema.isValidSync(value[1]) && titleSchema.isValidSync(value[2]) && imageSchema.isValidSync(value[0]))
      ||
      (videoSchema.isValidSync(value[2]) && titleSchema.isValidSync(value[0]) && imageSchema.isValidSync(value[1]))
      ||
      (videoSchema.isValidSync(value[2]) && titleSchema.isValidSync(value[1]) && imageSchema.isValidSync(value[0]))
    )).transform((_, value)=>value.map((item) => (
    item?.type === 'Title'
      ? titleSchema.cast(item)
      : item?.type === 'Video'
        ? videoSchema.cast(item)
        : imageSchema.cast(item)
  )))
  ,
});

const audioTitleImageSchema = object({
  type: string().default('TitleAudioImage').trim().required(REQUIRED_MESSAGE),
  items: array().ensure().min(3).max(3).test('is-AudioTitleImageItem',
    (d) => `${d.path} is not a AudioTitleImageItem`,
    (value) => ((audioSchema.isValidSync(value[0]) && titleSchema.isValidSync(value[1])) && imageSchema.isValidSync(value[2])
      ||
      (audioSchema.isValidSync(value[0]) && titleSchema.isValidSync(value[2]) && imageSchema.isValidSync(value[1]))
      ||
      (audioSchema.isValidSync(value[1]) && titleSchema.isValidSync(value[0]) && imageSchema.isValidSync(value[2]))
      ||
      (audioSchema.isValidSync(value[1]) && titleSchema.isValidSync(value[2]) && imageSchema.isValidSync(value[0]))
      ||
      (audioSchema.isValidSync(value[2]) && titleSchema.isValidSync(value[0]) && imageSchema.isValidSync(value[1]))
      ||
      (audioSchema.isValidSync(value[2]) && titleSchema.isValidSync(value[1]) && imageSchema.isValidSync(value[0]))
    )).transform((_, value)=>value.map((item) => (
    item?.type === 'Title'
      ? titleSchema.cast(item)
      : item?.type === 'Audio'
        ? audioSchema.cast(item)
        : imageSchema.cast(item)
  )))
  ,
});

export {
  imageSchema,
  carouselSchema,
  titleSchema,
  videoSchema,
  videoTitleSchema,
  audioSchema,
  audioTitleSchema,
  textSchema,
  titleTextSchema,
  audioTitleImageSchema,
  videoTitleImageSchema,
};