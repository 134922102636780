import React, {useContext, useEffect, useState} from 'react';
import TouristicSiteContext from './TouristicSiteContext';

const ActivityContext = React.createContext({});

const ActivityProvider = ({initialActivity = {}, children}) => {
  const {touristicSite, setTouristicSite} = useContext(TouristicSiteContext);
  const [activity, setActivity] = useState(initialActivity);

  // useEffect(() => {
  //   if (JSON.stringify(initialActivity) !== JSON.stringify(activity)) {
  //     setActivity(initialActivity);
  //   }
  // }, [initialActivity]);

  useEffect(() => {
    if (activity?.id && touristicSite?.activities) {
      let newActivities = [...touristicSite.activities];
      if (newActivities.find(act => act.id === activity.id)) {
        newActivities = newActivities.map(act => act.id === activity.id ? {...activity} : act);
      } else {
        newActivities.push({...activity});
      }
      setTouristicSite(prev => ({...prev, activities: newActivities}));
    }
  }, [activity]);

  const publicMethods = {
    activity,
    setActivity,
  };

  return (
    <ActivityContext.Provider value={publicMethods}>
      {children}
    </ActivityContext.Provider>
  );
};

export {ActivityProvider};

export default ActivityContext;