import Title from 'components/header/title/title';
import React from 'react';

const BoughtHistoryPage = () => {
    return (
        <div>
            <Title title="Mes achats" description="Liste de tous mes achats" />
            <p className="text-muted">Vous n'avez pas encore acheté de tickets</p>
        </div>
    );
};

export default BoughtHistoryPage;