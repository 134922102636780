import "./kahoot_result.scss";
import Button from "../form/button/button";
import { useState, useEffect, useForm } from "react";

const Kahoot_result = ({ result, setQuizz }) => {
  const [end, setEnd] = useState("Bravo");
  useEffect(() => {
    if (result > 10) {
      setEnd("Bravo");
    }
    if (result < 10) {
      setEnd("Tu peux mieux faire");
    }
    if (result > 15) {
      setEnd("Toutes les felicitations d'Henri");
    }
  }, []);
  return (
    <div className="Kahoot-result">
      <p className="kahoot-end">Quizz terminé !</p>

      <p style={{ fontWeight: "700", fontSize: "1.5rem" }}>
        {end}, votre score est de :
      </p>
      <div className="kahoot-middle">
        <div className="kahoot-circle">
          {result >= 10 ? (
            <span className="result-top">{result}</span>
          ) : (
            <span className="result-top">0{result}</span>
          )}

          <hr className="result-lign"></hr>
          <span className="result-bottom">20</span>
        </div>

        <Button
          type="button"
          onClick={() => {
            setQuizz(false);
          }}
          className="btn-next"
        >
          Terminer
        </Button>
      </div>
    </div>
  );
};
export default Kahoot_result;
