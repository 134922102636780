import React, {useEffect, useState} from 'react'
import {Modal} from 'semantic-ui-react'

const ModalForm = ({title, isOpen, onClose, children}) => {
    const [show, setShow] = useState(false)

    const handleClose = () => {
        onClose()
        setShow(false)
    }
    const handleShow = () => setShow(true)

    useEffect(()=>{
        if(isOpen === true){
            handleShow()
        } if(isOpen === false) {
            handleClose()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOpen])

    return(
        <Modal
            size={"tiny"}
            open={show}
            onOpen={handleShow}
            onClose={handleClose}
        >
            {
                title &&
                <Modal.Header >
                    {title}
                </Modal.Header>
            }

            {children}
        </Modal>
    )
}

export default ModalForm;
