import React from 'react';
import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'
import './i18n';

import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { GoogleOAuthProvider } from '@react-oauth/google';
import Router from './utils/router';
import store, { persistor } from './utils/store';
import { PersistGate } from 'redux-persist/integration/react';

import 'semantic-ui-css/semantic.min.css'
import './assets/scss/app.scss';
import 'jquery/dist/jquery.min.js'
import 'bootstrap/dist/js/bootstrap.min.js'
import CartProvider from "./hooks/Cart/cart-provider";

Bugsnag.start({
  apiKey: '55d83d71d42c4976ea19e657937960f0',// TODO .env
  plugins: [new BugsnagPluginReact()],
  enabledReleaseStages: [ 'production', 'preprod' ],
  // otherOptions: value
});

if (!/bot|crawl|prerender|spider/i.test(window.navigator.userAgent)){
  const termly = document.createElement('script');
  termly.type = "text/javascript"
  termly.src = "https://app.termly.io/embed.min.js";
  termly.async = true;
  termly.setAttribute("data-auto-block", "off");
  termly.setAttribute("data-website-uuid", "9692a64a-1629-493e-bd62-1a1a4324e499");

  document.head.appendChild(termly);
}

const ErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React);

const ErrorView = () => (
  <div>
    <p>An error occurred. Please retry later.</p>
  </div>
);


const App = () => (
  <ErrorBoundary FallbackComponent={ErrorView}>
    <Provider store={store}>
      <PersistGate persistor={persistor} loading={<div></div>}>
        <CartProvider>
          <BrowserRouter>
            <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_LOGIN_APP_ID}>
              <Router/>
            </GoogleOAuthProvider>
          </BrowserRouter>
        </CartProvider>
      </PersistGate>
    </Provider>
  </ErrorBoundary>
);

const parentElement = document.getElementById('root');

ReactDOM.render(<App/>, parentElement);
