import React from 'react';
import { cartContext, CART_REDUCER_TYPES } from './cart-provider';

const useCart = () => {
    const cartProv = React.useContext(cartContext);

    const actions = {
        addElement: (payload) => {
            cartProv.dispatch({
                type: CART_REDUCER_TYPES.ADD_ELEMENT,
                payload
            });
        },
        setCart: (payload) => {
            cartProv.dispatch({
                type: CART_REDUCER_TYPES.SET_CART,
                payload
            })
        },
        removeCart: () => {
            cartProv.dispatch({
                type: CART_REDUCER_TYPES.REMOVE_CART
            })
        },
        removeElement: (payload) => {
            cartProv.dispatch({
                type: CART_REDUCER_TYPES.REMOVE_ELEMENT,
                payload
            })
        },
        updateElement: (payload) => {
            cartProv.dispatch({
                type: CART_REDUCER_TYPES.UPDATE_ELEMENT,
                payload
            })
        }
    }

    return {cartProv, actions};
};

export default useCart;