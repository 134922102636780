import React from 'react';
import "./filter-buttons.scss";
import { Button } from "../../form/button";
import { isMobile } from 'react-device-detect';

const FilterButtons = ({text, logo, onClick, className, color, showText}) => {
    return (
      <Button color={color ?? "primary"} className={`header-button ${className}`} rounded onClick={onClick}>
        {
          logo &&
          <img className="ml-1" src={logo} width="20" height="20" alt={text}/>
        }
        { text &&
          (showText ? (isMobile ? <></> : <p>{text}</p>) : <p className="px-1">{text}</p>)
        }
      </Button>
    );
};

export default FilterButtons;