import {array, number, object, string} from 'yup';
import {mediaSchema} from './touristic_site_schema';

const REQUIRED_MESSAGE = 'Champ requis';

const activitySchema = object({
  touristic_site_id: number().positive().integer().required(REQUIRED_MESSAGE),
  title: string().default('').trim().required(REQUIRED_MESSAGE),
  description: string().default('').trim().nullable().transform((_, value) => (
    value === null ? '' : value
  )),
  medias: array().of(mediaSchema).ensure().min(1).default([{}]),
});

export default activitySchema;