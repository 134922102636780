import { Checkbox, FormControlLabel } from "@material-ui/core";
import { Field } from "formik";
import React from "react";

const MyCheckbox = props => {
  const classNames = ["text-primary", props.className]
    .filter(el => el)
    .join(" ");

  if (props.arrayHelpers) {
    return (
      <>
        <FormControlLabel
          control={
            <Checkbox
              className="text-primary"
              value={props.el.id}
              key={props.el.title}
              name={toString(props.el.id)}
              checked={props.values[props.name]?.includes(props.el.id)}
              onChange={() => {
                if (!props.values[props.name]?.includes(props.el.id)) {
                  props.arrayHelpers.push(props.el.id);
                } else {
                  var id = props.values[props.name]?.indexOf(props.el.id);
                  props.arrayHelpers.remove(id);
                }
              }}
            >
            </Checkbox>
          }
          label={props.el.title}
        ></FormControlLabel>
        {props.touched[props.name] && props.errors[props.name] ? (
          <div className="fv-plugins-message-container">
            <div className="fv-help-block text-danger">
              {props.errors[props.name]}
            </div>
          </div>
        ) : null}
      </>
    );
  }

  if (props.onChange) {
    return <FormControlLabel
      control={
        <Field
          component={({ field, form, ...props }) => {
            return <Checkbox {...field} {...props} />;
          }}
          className={classNames}
          name={props.name}
          value={props.value}
          checked={props.checked}
          type="checkbox"
          onChange={props.onChange}
        />
      }
      label={props.label + (props.required ? " *" : "")}
    />
  }

  return (
    <>
      <FormControlLabel
        control={
          <Field
            component={({ field, form, ...props }) => {
              return <Checkbox {...field} {...props} />;
            }}
            className={classNames}
            name={props.name}
            value={props.value}
            type="checkbox"
            onChange={props.handleChange}
            onBlur={props.handleBlur}
          />
        }
        label={props.label + (props.required ? " *" : "")}
      />
      {props.touched[props.name] && props.errors[props.name] ? (
        <div className="fv-plugins-message-container">
          <div className="fv-help-block text-danger">
            {props.errors[props.name]}
          </div>
        </div>
      ) : null}
    </>
  );
};

export default MyCheckbox;
