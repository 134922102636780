import React, { useLayoutEffect } from "react";
import { init, cleanup } from "./fb";

export function Facebook() {
  useLayoutEffect(() => {
    console.log("init facebook");
    init();

    return () => {
      cleanup();
    };
  }, []);

  return (
    <div className="mb-3">
      <div id="fb-root"></div>

      <div id="fb-customer-chat" className="fb-customerchat"></div>
    </div>
  );
}