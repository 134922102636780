import styled, {useTheme} from 'styled-components';
import {forwardRef, useEffect, useImperativeHandle, useState} from "react";
import Button from "../Button";
import {useTranslation} from "react-i18next";

const InformationFlipCardStyled = styled.div`
  position: relative;
  background-color: transparent;
  width: 100%;
  height: 100%;
  perspective: 1000px;
`;

const FlipCardFaceContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  text-align: center;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  border-radius: 20px;
  background-color: ${props => props.theme.colors.white};
  transition: transform 0.6s;
  transform-style: preserve-3d;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
`;

const FlipCardFace = styled.div`
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  padding: 20px;
  display: grid;
  text-align: center;
  grid-template-rows: 3fr 1fr;
`;

const ButtonContainer = styled.div`
  display: flex;
  width: 100%;
  
  button {
    display: block;
    margin: auto auto;
  }
`;

// Face prop accepts ['recto', 'verso']
const InformationFlipCard = forwardRef(
  ({RectoComponent, VersoComponent, face = 'recto', ...rest}, ref) => {
  const {t} = useTranslation(['experiences']);
  const theme = useTheme();
  const [isReturned, setIsReturned] = useState(face === 'verso');

  useEffect(() => {
    setIsReturned(face === 'verso');
  }, [face]);

  useImperativeHandle(ref, () => {
    return {
      reset: () => setIsReturned(false),
      setRecto: () => setIsReturned(false),
      setVerso: () => setIsReturned(true),
      setFace: (face) => setIsReturned((face === 'verso')),
    };
  }, []);

    return (
      <InformationFlipCardStyled {...rest}>
        <FlipCardFaceContainer
          style={{
            transform: `rotateY(${isReturned ? 180 : 0}deg)`,
            zIndex: isReturned ? 1 : 2,
          }}
        >
          <FlipCardFace>
            <div style={{width: '100%', overflow: 'hidden'}}>
              {RectoComponent}
            </div>
            <ButtonContainer>
              <Button
                rounded
                onClick={event => {
                  setIsReturned(true);
                  event.stopPropagation();
                }}
              >
                {t("flipcards.buttons.front")}
              </Button>
            </ButtonContainer>
          </FlipCardFace>
        </FlipCardFaceContainer>
        <FlipCardFaceContainer
          style={{
            transform: `rotateY(${isReturned ? 0 : -180}deg)`,
            zIndex: isReturned ? 2 : 1,
          }}
        >
          <FlipCardFace>
            <div style={{width: '100%', overflow: 'hidden'}}>
              {VersoComponent}
            </div>
            <ButtonContainer>
              <Button
                rounded
                style={{backgroundColor: theme.colors.darkGrey}}
                onClick={event => {
                  setIsReturned(false);
                  event.stopPropagation();
                }}
              >
                {t("flipcards.buttons.back")}
              </Button>
            </ButtonContainer>
          </FlipCardFace>
      </FlipCardFaceContainer>
    </InformationFlipCardStyled>
  );
});

export default InformationFlipCard;