import React, { useState, useEffect } from "react";

import { PHONE_WIDTH, toAbsoluteUrl } from 'utils/utils';
import SVG from 'react-inlinesvg';
import categoryIcons from 'utils/categories';
import Feedback from 'components/informations/feedback/feedback';
import './touristic-site-map-popup.scss'
import "../touristicSiteCard/touristicSiteCard.scss";
import RoomOutlinedIcon from '@mui/icons-material/RoomOutlined';
import { HeaderButton } from "components/header/buttons";
import { Button } from "components/form/button";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import categories from "../../../utils/categories";
import { ReactComponent as ChevronDown } from "../../../../src/assets/img/logo/chevron_down.svg"
import { color } from "@mui/system";
import styled from "styled-components";
import { ReactComponent as Hours } from '../../../assets/img/logo/horloge.svg';
import {mediasTyped} from "../../../utils/media";

const SvgIcon = ({ color }) => {
  return (
    <SVG width="20" color={color} height="20" src={toAbsoluteUrl(categoryIcons.icons[color])} />
  )
}

const SvgIconCustom = ({ id, color }) => {
  return (
    <img width="20" height="20" color={`${color} !important`} src={toAbsoluteUrl(categoryIcons.custom_icons[id])} />
  )
}





// pour finir la page Experience il faut ajouter a la selection d'une activité une animation pour cibler l'element selectionné
const TouristicSiteMapPopupPremium = ({ data, handlePurchase, setPopup, openMap, openMapFull }) => {
  const { t } = useTranslation(["henriTrip"])
  const [widthState, setWidthState] = useState(window.innerWidth);
  const history = useHistory();
  const style = {
    marginTop: "6%"
  }
  useEffect(() => {
    function handleResize() {
      setWidthState(window.innerWidth)
    }
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize)
  }, []);
  const [heures, setHeures] = useState(null)
  const [minutes, setMinutes] = useState(null)
  useEffect(()=>{
    setHeures( Math.floor(data.duration/60))
    data.duration%60 > 15 && data.duration%60 < 45 && setMinutes(30);
  },[data.duration])

  const image = mediasTyped(data.medias).image?.[0]?.url || undefined;

  return (
    <div className={`popup-widget__touristiccard `} >
      {
        widthState < "1200" ?
          <div style={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}><hr style={{ width: "20%" }}></hr><div onClick={() => setPopup(null)} ><ChevronDown width="25" height="25" color="#B0B2B5" /></div><hr style={{ width: "20%" }}></hr></div>
            :
            <div style={{   display: 'flex', width: '100%', justifyContent: 'flex-end', marginRight: '2em' }} onClick={() => setPopup(null)} ><div style={{border : "1px solid #B0B2B5", borderRadius: "100%", padding: '0.2em'}}><ChevronDown width="20" height="20" color="#D7D7D7" /></div></div>
      }
      <div style={{ display: "flex"}}>
        {
          data.ts_type == "custom" ?
            <div style={{ display: "flex", justifyContent: "center", width: "65%" }}>
              {
                image ?
                  <img src={image} className="popup-widget__img" alt="..." />
                  :
                  <img src="https://general-henri-1-paris.s3.fr-par.scw.cloud/woman.jpg" className="popup-widget__img" alt="..." />
              }
            </div>
            :
    <div style={{ display: "flex", justifyContent: "center", width: "55%" }}>
            <a href={`https://www.henritrip.fr/touristic-site/${data.id}`} style={{objectFit: "cover",
              objectPosition: "center",
              height: "8em",
              width: "80%"
            }} target="_blank" >
              {
                image ?
                  <img src={image?.picture} className="popup-widget__img" alt="..." />
                  :
                  <img src="https://general-henri-1-paris.s3.fr-par.scw.cloud/woman.jpg" className="popup-widget__img" alt="..." />
              }
            </a>
    </div>
        }

        <div className="popup-widget__touristicBody">
          {
            data.ts_type == "custom" ?
              <div className="popup-widget__header-card">
                <div className="popup-widget__container_category" style={{ border: `1px solid ${data.category.color}` }}>
                  <SvgIconCustom color={data.category.color} id={data.category_id} />
                </div>
                <h6 className="popup-widget__title">
                  {categories.name[data.category_id]}
                </h6>
              </div>

              :
              <div className="popup-widget__header-card">
                <div className="popup-widget__container_category" style={{ border: `1px solid ${data.categories[0].color}` }}>
                  <SvgIcon color={data.categories[0].color} />
                </div>
                <h6 className="popup-widget__title">
                  {data.categories[0].title}
                </h6>
              </div>
          }

          {
            data.title &&
            <div style={{display: "flex", justifyContent: "center"}}>
              <span style={{ fontWeight: "800" }} className="popup-widget__iconCat">{data.title}</span>
            </div>
          }

            {
                data.title_fr &&
                <div style={{display: "flex", justifyContent: "center"}}>
                    <span style={{ fontWeight: "800" }} className="popup-widget__iconCat">{data.title_fr}</span>
                </div>
            }
          {
            data.duration &&
              <div style={{ fontSize: "0.8rem", display: "flex" }}>
                <span>Estimation du temps de visite</span>
                <span style={{width: "90%"}}><Hours />{heures} heures</span>
              </div>
          }

          {/* {
            e.incontournable &&
            <div style={{ backgroundColor: "#54A8FC", borderRadius: "0.2em", textAlign: "center" }}>
              <span style={{ color: "white", fontWeight: "800", fontSize: "0.8em" }}>Incontournable</span>
            </div>
          } */}
        </div>
      </div>
    </div>
  );
};

export default TouristicSiteMapPopupPremium;
