import * as React from "react"

function Icon3(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 88.56 106.9"
      style={{color: "#9E7241"}}
      {...props}
    >
      <defs>
        <style />
      </defs>
      <g id="prefix__Calque_2" data-name="Calque 2">
        <g id="prefix__Calque_1-2" data-name="Calque 1">
          <path
            fill="currentColor"
            d="M44.28 0a44.27 44.27 0 00-31.16 75.73l31.16 31.17 31.16-31.17A44.27 44.27 0 0044.28 0"
          />
          <path
            d="M67.2 41.65l-15.45-9.16L60 35.06l-3-11.89-12.72-5.59-12.78 5.59-2.42 24.56-.81-11.61-6.39 3L15.43 71h57.7zM44.28 68.18H36l2-15 6.35-5.5 6.35 5.5 1.95 15z"
            fill="#fff"
          />
        </g>
      </g>
    </svg>
  )
}

export default Icon3
