import React from 'react';
import useDocumentTitle from "../../hooks/use-document-title";
import PartnerSectionsHeader from './PartnerSectionsPage/PartnerSectionsHeader';
import PartnerSectionsInfos from './PartnerSectionsPage/PartnerSectionsInfos';
import PartnerSectionsMiddleInfos from "./PartnerSectionsPage/PartnerSectionsMiddleInfos";
import PartnerSectionsShowMore from "./PartnerSectionsPage/PartnerSectionsShowMore";
import PartnerSectionsTrustSlider from "./PartnerSectionsPage/PartnerSectionsTrustSlider";
import PartnerSectionsActivity from "./PartnerSectionsPage/PartnerSectionsActivity";
import PartnerSectionsNavbar from "./PartnerSectionsPage/PartnerSectionsNavbar";
import PartnerSectionsTop from "./PartnerSectionsPage/PartnerSectionsTop";
import PartnerSectionsSwitch from "./PartnerSectionsPage/PartnerSectionsSwitch";
import PartnerSectionsServices from "./PartnerSectionsPage/PartnerSectionsServices";
import PartnerSectionsPartner from "./PartnerSectionsPage/PartnerSectionsPartner";
import PartnerSectionsSearch from './PartnerSectionsPage/PartnerSectionsSearch';
import PartnerSectionsTouristicSites from './PartnerSectionsPage/PartnerSectionsTouristicSites';

const PartnerPage = (props) => {
    useDocumentTitle("Henri Trip : Devenez partenaires !")

    return (
        <div style={{overflow: "hidden"}}>
            <PartnerSectionsNavbar/>
            <PartnerSectionsHeader background="https://general-henri-1-paris.s3.fr-par.scw.cloud/partenaire_page/image-baniere-haut.jpg" />
            <div className="container">
                <PartnerSectionsTouristicSites/>
                <PartnerSectionsActivity/>
                <PartnerSectionsTop/>
              {/*                <PartnerSectionsInfos/>

                 <PartnerSectionsMiddleInfos/>*/}
                  <PartnerSectionsShowMore/>
                  <PartnerSectionsSearch/>
                  <PartnerSectionsSwitch/>
                  <PartnerSectionsServices/>
                  <PartnerSectionsSearch/>
                  <PartnerSectionsPartner/>
                  <PartnerSectionsTrustSlider/>
            </div>
        </div>
    )
};

export default PartnerPage;