import React from 'react';
import { Radio } from 'semantic-ui-react'
import useDocumentTitle from 'hooks/use-document-title';
import { useTranslation } from "react-i18next";

const NotificationPage = () => {
    const {t} = useTranslation(["henriTrip"])
    useDocumentTitle(`Notifications • Henri Trip`)
    return (
        <div className="container">
            <div className="col-lg-7 offset-md-1 mt-4">
                <div className="row">
                    <h1>{t("general.notifications")}</h1>
                </div>
                <div className="row mt-4">
                    <div className="col-lg-12">
                        <h3>{t("account.notificationPage.messages.title")}</h3>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <p>{t("account.notificationPage.messages.description")}</p>
                    </div>
                </div>
                <div className="row mt-4">
                    <div className="col-10">
                        <p>E-Mail</p>
                    </div>
                    <div className="col-2">
                        <Radio toggle/>
                    </div>
                </div>
                <div className="row mt-2">
                    <div className="col-10">
                        <p>SMS</p>
                    </div>
                    <div className="col-2">
                        <Radio toggle/>
                    </div>
                </div>
                <div className="row mt-2">
                    <div className="col-10">
                        <p>{t("account.notificationPage.messages.notif")}</p>
                    </div>
                    <div className="col-2">
                        <Radio toggle/>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default NotificationPage;