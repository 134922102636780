import React, {useEffect, useState} from 'react';
import ReactPlayer from 'react-player';

const LinkVS = ({data, activeContent}) => {
  const [object, setObject] = useState(
    data ? activeContent[data.table_name].find((x) => x.id === data.id) : null
  );

  useEffect(() => {
    setObject(data ? activeContent[data.table_name].find((x) => x.id === data.id) : null);
  }, [activeContent, data]);

  return (
    object ? (
      <>
        {ReactPlayer.canPlay(object?.url || '') ? (
          <ReactPlayer
            className="react-player__custom my-3"
            width="100%"
            url={object?.url}
            controls
          />
        ) : (
          <ul className="my-3">
            <li>
              <a target="_blank" href={object?.url} rel="noreferrer">
                {object?.link_title || object?.url}
              </a>
            </li>
          </ul>
        )}
      </>
    ) : null
  )
};

export default LinkVS;
