import { addUserRoadTrips } from 'actions/road-trips';
import useRequest from 'hooks/use-request';
import RoadtripsPage from 'pages/UserPage/RoadtripsPage/RoadtripsPage';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { API_GET_USER_ROADTRIPS } from 'utils/api';
import { groupsId, seasonsId, transportsId } from "../../../utils/utils";

const Roadtrips = () => {
  const dispatch = useDispatch();
  const [currentPage, onChangePage] = useState(0);
  const [handleRequest, status] = useRequest()
  const config = useSelector(state => state.data);
  const { userRoadtrips } = useSelector(state => state.roadtrips)
  const {user} = useSelector(state => state.user);

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    if(config.headers.Authorization){
      handleRequest("get", API_GET_USER_ROADTRIPS, {}, config, (data, status) => {
        if (status === "Done") {
          const parsedRT = data.road_trips.map(el => {
            let categoriesTransport = [];
            let categoriesGroup = [];
            let categoriesSeasons = [];

            el.categoriesTransport= categoriesTransport
            el.categoriesGroup= categoriesGroup
            el.categoriesSeasons = categoriesSeasons

            el.categories.forEach(el =>
            {
              if(transportsId.includes(el.id)){
                categoriesTransport.push(el)
              }
            })

            el.categories.forEach(el =>
            {
              if(groupsId.includes(el.id)){
                categoriesGroup.push(el)
              }
            })

            el.categories.forEach(el =>
            {
              if(seasonsId.includes(el.id)){
                categoriesSeasons.push(el)
              }
            })

            el.feedbacks = {
              note: el.avg_notes || 0,
              len: el.notes_counter,
            }

            el.user = {
              firstname: user?.firstname,
              lastname: user?.lastname,
            }

            return el;
          });
          dispatch(addUserRoadTrips(parsedRT))
        } else if (status === "Error") {
          console.error(data);
        }
      })
    }
    // eslint-disable-next-line
  }, [])

  return (
    <RoadtripsPage
      loading={status !== "Done"}
      roadtrips={userRoadtrips}
      currentPage={currentPage}
      onChangeStep={onChangePage}
    />
  );
};

export default Roadtrips;
