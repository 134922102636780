import React, { useState} from 'react';
import {API_MODIFY_USER_BY_ID} from '../../../utils/api'
import { Button,  Message, Form } from 'semantic-ui-react'
import {useDispatch, useSelector} from "react-redux";
import {updateUser} from "../../../actions/user";
import useRequest from "../../../hooks/use-request";
import { useTranslation } from "react-i18next";


const FormAccount = (props) => {

    const {t} = useTranslation(["henriTrip"])

    const {user} = useSelector(state => state.user);
    const [open, setOpen] = useState({
        username: false,
        email: false,
        mdp: false
    });
    const [correct, setCorrect] = useState({
        username: true,
        email: true
    });

    const [state, setState] = useState({
        username: user.username,
        email: user.email
    });
    const [handleRequest] = useRequest();
    const dispatch = useDispatch();
    
    function handleClick(name){
        setOpen({...open, [name]: !open[name]});
    }

    const [input, setInput] = useState({
        oldPassword: '',
        newPassword: '',
        confirmPassword:'',
        oldPasswordCorrect: true,
        newPasswordCorrect: true,
        confirmPasswordCorrect: true,
    })

    const setChange = (e, { name, value }) => {
        setState({...state, [name]: value });
    };

    const handleChange = (e, {name, value}) => {
        setInput({...input, [name]: value})
        console.log(input);
    }

    const handleSubmit = (e) => {
        switch (e) {
            case "username":
                return {
                    ...user,
                    username: state.username,
                }
            case "email":
                const emailCorrect = state.email.match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
                setCorrect({
                    ...correct,
                    email : emailCorrect,
                })
                if (emailCorrect){
                    return {
                        email: state.email,
                    }
                }
                else{
                    return false
                }
            case "mdp":
                setInput({
                    ...input,
                    newPasswordCorrect: input.newPassword.length >= 8,
                    confirmPasswordCorrect: input.newPassword === input.confirmPassword
                })
            return false
            default:
                return false
        }
    }

    const updateUserSecurityInfo = (e) => {
        let res = handleSubmit(e)
        if (res) {
            const token = localStorage.getItem("Authorization");
            handleRequest("put", API_MODIFY_USER_BY_ID(user.id), res, {headers: {"Authorization": "Bearer " + token}}, (data, status) => {
                if (status === "Done") {
                    dispatch(updateUser(data))
                    switch (e) {
                        case 'username':
                            setOpen({...open, username: false});
                            break
                        case 'email':
                            setOpen({...open, email: false});
                            break
                        default:
                            break
                    }
                } else {
                    console.error(data);
                }
            })
        }
    }
    return (
            <div>
                <div className="row col-7">
                    <h1>{t("general.connect")}</h1>
                </div>
                <div className="col-12">
                    <div className="row mt-3">
                        <div className="col-9">
                            <h3>{t("account.loginSecurityPage.pseudo.title")}</h3>
                        </div>
                        <div className="col-3">
                            <strong><button type="button" onClick={() => handleClick('username')} className="btn btn-link" >
                                {
                                    open.username ? t("general.buttonCancel") : t("general.buttonModify")
                                }
                            </button></strong>
                        </div>
                    </div>
                    {
                        open.username ?
                          <div>
                              <Form className="ui form segment error" size="large">
                                <Form.Input
                                  name="username"
                                  value={state.username}
                                  onChange={setChange}
                                  placeholder={user.username}
                                  disabled={false}
                                />
                                <Button color="blue" size="large" onClick={() => updateUserSecurityInfo('username')} type='submit'>{t("account.loginSecurityPage.pseudo.button")}</Button>
                              </Form>
                          </div>

                          :

                          <div className="row mt-2">
                              <div className="col-12">
                                  <p>{user.username}</p>
                              </div>
                          </div>
                    }

                    <hr />
                    <div className="row mt-3">
                        <div className="col-9">
                            <h3>{t("account.loginSecurityPage.mail.title")}</h3>
                        </div>
                        <div className="col-3">
                            <strong><button type="button" onClick={() => handleClick('email')} className="btn btn-link" >
                                {
                                    open.email ? t("general.buttonCancel") : t("general.buttonModify")
                                }
                            </button></strong>
                        </div>
                    </div>
                    {
                    open.email ?
                      <div>
                          <Form className="ui form segment error" size="large">
                              {correct.email ? (
                                <Form.Input
                                  name="email"
                                  type="email"
                                  value={state.email}
                                  onChange={setChange}
                                  placeholder={user.email}
                                  disabled={false}
                                />
                              ) : (
                                <Form.Input
                                  name="email"
                                  type="email"
                                  value={state.email}
                                  onChange={setChange}
                                  placeholder={user.email}
                                  disabled={false}
                                  error={{
                                      content: t("loginSecurityPage.mail.error"),
                                      pointing: "above",
                                  }}
                                />
                              )}
                              <Button color="blue" size="large" onClick={() => updateUserSecurityInfo('email')} type='submit'>{t("account.loginSecurityPage.mail.button")}</Button>
                          </Form>
                      </div>

                      :

                      <div className="row mt-2">
                          <div className="col-12">
                              <p>{user.email}</p>
                          </div>
                      </div>
                    }

                    <hr />
                    <div className="row mt-3">
                        <div className="col-9">
                            <h3>{t("general.password")}</h3>
                        </div>
                        <div className="col-3">
                            <strong><button type="button" onClick={() => handleClick('mdp')} className="btn btn-link" >
                                {
                                    open.email ? t("general.buttonCancel") : t("general.buttonModify")
                                }
                            </button></strong>
                        </div>
                    </div>
                    {
                        open.mdp ?
                        <div>
                        <Form className="ui form segment error" size="large">
                            <Form.Input
                                name='oldPassword'
                                label={t("general.password")}
                                placeholder={t("general.password")}
                                type='password'
                                value={input.oldPassword}
                                onChange={handleChange}
                            />
                            <Message
                                id="messageActuel"
                                hidden={true}
                                header={t("general.errorPassword.header")}
                                content={t("general.errorPassword.content")}
                            />
                            <Form.Input
                                name="newPassword"
                                label={t("account.loginSecurityPage.password.newPassword")}
                                placeholder={t("account.loginSecurityPage.password.newPassword")}
                                type='password'
                                value={input.newPassword}
                                onChange={handleChange}
                            />
                            <Message
                                hidden={input.newPasswordCorrect}
                                header={t("general.errorNewPassword.header")}
                                content={t("general.errorNewPassword.content")}
                            />
                            <Form.Input
                                name="confirmPassword"
                                label={t("general.confirmPassword")}
                                placeholder={t("general.confirmPassword")}
                                type='password'
                                value={input.confirmPassword}
                                onChange={handleChange}
                            />
                            <Message
                                hidden={input.confirmPasswordCorrect}
                                header={t("general.errorConfirmPassword.header")}
                                content={t("general.errorConfirmPassword.content")}
                            />
                            <Button color="blue" size="large" onClick={() => updateUserSecurityInfo('mdp')} type='submit'>{t("account.loginSecurityPage.password.button")}</Button>
                        </Form>
                        </div>

                        :<></>
/*
                        <div className="row mt-2">
                            <div className="col-12">
                                <p>Ajoutez un mode de paiement à l’aide de notre système de paiement sécurisé, puis achetez vos billets chez nos partenaires.</p>
                            </div>
                        </div>*/
                    }
                </div>
            </div>
         );
    }


export default FormAccount;