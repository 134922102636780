import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Error404 from './404';
import RoadTripPremiumError from "./RoadTripPremiumError";

const Errors = ({match}) => {
    return (
        <Switch>
            <Route path={`${match.path}/404`} component={Error404} exact />
            <Route path={`${match.path}/500`} component={RoadTripPremiumError} exact />
            <Route path={`${match.path}`} component={Error404} />
        </Switch>
    );
};

export default Errors;