import useRequest from 'hooks/use-request';
import TouristicSitesPage from 'pages/TouristicSitesPage/TouristicSitesPage';
import React, {useEffect, useState} from 'react';
import {NumberParam, StringParam, useQueryParams} from 'use-query-params';
import {API_GET_ALL_TOURISTIC_SITES} from 'utils/api';
import {mediasTyped} from "../../utils/media";

const TouristicSites = () => {
  const [sortQuery, setSortQuery] = useQueryParams({
    title_fr: StringParam,
    latitude: NumberParam,
    longitude: NumberParam,
    sw: StringParam,
    ne: StringParam,
    category: StringParam,
    current_page: NumberParam,
    per_page: NumberParam,
    order: StringParam,
    insee: StringParam,
    place: StringParam,
  });
  const [touristicSites, setTouristicSites] = useState()
  const [numberPages, setNumberPages] = useState(0)
  const [handleRequest, status] = useRequest()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    handleRequest("get", API_GET_ALL_TOURISTIC_SITES(sortQuery), {}, {}, (data, status) => {
      if (status === "Done") {
        const parsedResults = data?.touristic_sites?.map((el) => {
          el.henri_certified = !!el.agency_id;
          el.category = el.categories[0] || {
            color: "#EF7E26",
            id: 53,
            title: "Activité",
            url: "https://general-henri-1-paris.s3.fr-par.scw.cloud/categories/activites_sportives.jpg"
          };
          el.image = mediasTyped(el.medias).image?.[0]?.url;
          el.feedbacks = {
            note: el.avg_note,
            len: el.notes_counter
          };
          return el;
        })
        setTouristicSites(parsedResults)

        if (numberPages !== data.count) {
          setNumberPages(data.count);
        }
      } else if(status === "Error") {
        console.error(data);
      }
    })
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortQuery])

  const handleSetQueryParams = ({
                                  title_fr = undefined,
                                  latitude = undefined,
                                  longitude = undefined,
                                  sw = undefined,
                                  ne = undefined,
                                  category = undefined,
                                  current_page = undefined,
                                  per_page = undefined,
                                  order = undefined,
                                  insee = undefined,
                                  place = undefined,
                                }) => {

    setSortQuery({
      title_fr: title_fr,
      latitude: latitude,
      longitude: longitude,
      sw: sw,
      ne: ne,
      category: category,
      current_page: current_page,
      per_page: per_page,
      order: order,
      insee: insee,
      place: place
    });
  }

  return (
    <TouristicSitesPage
      touristicSites={touristicSites}
      loading={status === "Loading"}
      numberPages={numberPages}
      handleQueryParams={handleSetQueryParams}
      sortQuery={sortQuery}
    />
  );
};

export default TouristicSites;
