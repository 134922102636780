import {useEffect, useRef, useState} from 'react';
import styled from 'styled-components';
import {DirectUploadProvider} from 'react-activestorage-provider';
import {commonStyleMixin} from './common';
import Loader from '../Loader';
import InputErrorWrapper from './InputErrorWrapper';
import {Label} from './LabelWrapper';
import {API_DIRECT_UPLOAD_URL, API_ORIGINAL_BLOB_URL} from '../../../../utils/api';
import {ReactComponent as ImageUploadIcon} from '../../../../assets/img/logo/image-upload-icon.svg';
import isFunction from "../../../../utils/isFunction";

const FileInputStyled = styled.input`
  display: none;
`;

const FileInputLabelStyled = styled(Label)`
  ${commonStyleMixin};
  
  position: relative;
  background-color: ${props => props.theme.colors.white80};
  background-image: linear-gradient(90deg, ${props => props.theme.colors.success}44, ${props => props.theme.colors.success}44);
  background-position: 0 0;
  background-size: ${props => props.progress}% 100%;
  background-repeat: no-repeat;
  transition: all 1s;
  display: flex;
  justify-content: center;
  align-items: center;

  ${props => props.disabled && `
    cursor: wait;
  `}
`;

const FileInputLabel = props => <FileInputLabelStyled as="div" {...props} />;

const calculateSingleUploadProgress = (upload) => (
  upload.state === 'uploading'
    ? upload.progress
    : upload.state === 'finished'
      ? 100
      : 0
);

const calculateGlobalProgress = (uploads) => {
  if (!uploads || uploads.length === 0) {
    return (0);
  }

  const uploadedSize = uploads.reduce(
    (accumulator, upload) => accumulator + (calculateSingleUploadProgress(upload) / 100.0) * upload.file.size,
    0
  );
  const totalSize = uploads.reduce((accumulator, upload) => accumulator + upload.file.size, 0);
  return (uploadedSize / (totalSize || 1) * 100);
};

const CustomDirectUploadProvider = ({render, ...rest}) => {

  const renderWithGlobalProgress = (args) => render(
    {
      ...args,
      globalProgress: calculateGlobalProgress(args.uploads)
    }
  );

  return (
    <DirectUploadProvider
      render={renderWithGlobalProgress}
      {...rest}
    />
  );
};

const FileInput = (
  {
    multiple = false,
    errorMessage,
    hasError,
    onChange,
    onBlobUrlChange,
    name,
    value,
    style,
    activeStorageServiceName,
    children,
    id,
    ...rest
  }
) => {
  const inputRef = useRef();
  const [internalValue, setInternalValue] = useState(value);
  const [originalBlobUrl, setOriginalBlobUrl] = useState();
  const filesRef = useState([]);

  const _internalName = `__internal_${name}_label${id || ''}`;

  useEffect(() => {
    var event = new Event('change', { 'bubbles': true });
    inputRef.current?.dispatchEvent(event);
    onChange?.(event);

    if (internalValue && filesRef.current[0]?.file?.name) {
      const blobUrls = filesRef.current.map((file) => API_ORIGINAL_BLOB_URL(internalValue, file?.file?.name));
      setOriginalBlobUrl(multiple ? blobUrls : blobUrls[0]);
    }
  }, [internalValue]);

  useEffect(() => {
    onBlobUrlChange?.(originalBlobUrl);
  }, [originalBlobUrl]);

  const handleSuccess = (response) => {
    setInternalValue(response);
  };

  const handleError = (response) => {
    console.log("error", response)
  };

  return (
    <CustomDirectUploadProvider
      directUploadsPath={API_DIRECT_UPLOAD_URL(activeStorageServiceName)}
      onSuccess={handleSuccess}
      onError={handleError}
      multiple={multiple}
      render={({handleUpload, uploads, ready, globalProgress}) => (
        (filesRef.current = uploads) && <>
          <div style={style}>
            {children && (
              <label htmlFor={_internalName}>
                {
                  isFunction(children)
                    ? children?.({ready, globalProgress})
                    : children
                }
              </label>
            )}
            <input
              ref={inputRef}
              name={name}
              type="hidden"
              value={internalValue}
            />
            <InputErrorWrapper
              hasError={hasError}
              errorMessage={errorMessage}
            >
              <FileInputStyled
                disabled={!ready}
                name={_internalName}
                id={_internalName}
                type="file"
                onChange={event => handleUpload(event.currentTarget.files)}
                multiple={multiple}
                {...rest}
              />
            </InputErrorWrapper>
          </div>
        </>
      )}
    />
  );
};

export default FileInput;

export {
  FileInputLabel,
};