import React, {useContext, useEffect, useState} from "react";
import { VideoFormToChoose} from "./FormikVideo";
import styled, {ThemeContext} from "styled-components";
import Title from "./title";
import TouristicSiteContext from '../../../_contexts/TouristicSiteContext';
import TitleDescription from '../../../_forms/_components/avpmBuilder/information/TitleDescription'
import CarouselBuilder from "../layout_builder/images/CarouselBuilder";
import FormikAudio from "./audio/FormikAudio";
import {AudioToShowForm, AudioImageToShowForm} from "./audio/AudioToShowForm";
import ActivityContext from "../../../_contexts/ActivityContext";
import TitleVideoForm from "./video/TitleVideoForm";
import VideoTitleImageArray from "./video/VideoTitleImageArray";
import Kahoot from "./quizz/Kahoot";
import {API_POST_KAHOOT, API_PUT_KAHOOT} from "../../../utils/api";
import {useTranslation} from "react-i18next";

const AVPMFormContainer = styled.div `
  width: 100%; 
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 50px;
`;

const layoutResourceCategories = {
  texts: ['Title','Text','TitleText'].concat(['Image', 'ImageText']),
  images: ['Carousel','TitleCarousel','CarouselText'],
  videos: ['Video','VideoTitleImage', 'TitleVideo', 'VideoTitleImageArray'],
  audios: ['PlaylistCarousel', 'TitleAudio','TitleAudioImage'],
};

const FormWrapper = ({editConfig, currentObject, editable=true, resourceCategory}) => {
  const [formOpen, setOpenForm] = useState(false);
  const [selectedForm, setSelectedForm] = useState(null);
  const [kahootLoading, setKahootLoading] = useState(false);
  const { setTouristicSite } = useContext(TouristicSiteContext);
  const { setActivity } = useContext(ActivityContext);
  const { touristicSite } = useContext(TouristicSiteContext);
  //hooks pour modifs form
  const [formToChoose, setFormToChoose] = useState(null);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const updateCurrentObject = setActivity || setTouristicSite;
  const layoutFiltered = (currentObject?.layout || []).map(layoutItem => layoutResourceCategories[resourceCategory]?.includes(layoutItem.type) ? layoutItem : null);
  const layoutFilteredIsEmpty = layoutFiltered.filter(layoutItem => layoutItem).length === 0;
  const quizz = currentObject?.quizzes;
  const {i18n} = useTranslation();

  useEffect(()=>{
    setFormToChoose(selectedForm)
  },[selectedForm]);

  useEffect(() => {
    setSelectedForm(null);
  },[formOpen]);

  const updateLayoutWithText = (layoutItemIndex,layoutItem, medias, update, callbacks = {}) => {
    const {onError, onSuccess} = callbacks;
    const newMedias = [...currentObject.medias];
    medias?.forEach((media) => {
      if (!media?.id && !newMedias.find(m => m.signed_id === media.signed_id) && media.type !== 'Title') {
        newMedias.push({signed_id: media.content.signed_id});
      }
    });
    const newLayout = [...currentObject.layout]
    !update ? newLayout.push({items: layoutItem}) : newLayout.splice(layoutItemIndex, 1, { items: layoutItem})
    const token = localStorage.getItem("Authorization");
    fetch(
      editConfig.api(currentObject.id),
      {
        method: 'PATCH',
        cache: 'default',
        body: JSON.stringify({layout: newLayout, medias: newMedias}),
        headers: {
          "Authorization": `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        mode: 'cors',
      }
    )
      .then(res => res.json())
      .then(response => {
        updateCurrentObject(prev => ({...prev, ...response}));
        onSuccess?.(response);
      })
      .catch(error => {
        onError?.(error);
      });
  };

  const deleteLayout = (layoutItemIndex, callbacks = {}) => {
    const {onError, onSuccess} = callbacks;
    const newLayout = [...currentObject.layout];
    newLayout.splice(layoutItemIndex, 1);

    const token = localStorage.getItem("Authorization");
    fetch(
      editConfig.api(currentObject.id),
      {
        method: 'PATCH',
        cache: 'default',
        body: JSON.stringify({layout: newLayout, medias: currentObject.medias}),
        headers: {
          "Authorization": `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        mode: 'cors',
      }
    )
      .then(res => res.json())
      .then(response => {

        updateCurrentObject(prev => ({...prev, ...response}));
        onSuccess?.(response);
      })
      .catch(error => {
        onError?.(error);
      });
  };

  const handleSubmitForm = (values, provider, provider_id) => {
    setKahootLoading(true);
    const token = localStorage.getItem("Authorization");
    values?.map((quizz,index)=>{
      quizz.position = index
      if (index === values?.length - 1 && quizz?.question?.text_fr?.length < 1){
        values.pop()
      }
    });
    const KahootToSend = {
      record_type: provider,
      record_id: provider_id,
      questions: values,
    };

    fetch(
      values[0]?.id ? API_PUT_KAHOOT(values[0].quiz_id) : API_POST_KAHOOT,
      {
        method: values[0]?.id ? 'PUT' : 'POST',
        cache: 'default',
        body: JSON.stringify(KahootToSend),
        headers: {
          "Authorization": `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        mode: 'cors',
      }
    )
      .then(res => res.json())
      .then(response => {
        const objectToPush = currentObject.quizzes.length > 0 ? currentObject.quizzes[0] = response : {quizzes: response};
        updateCurrentObject(prev => ({...prev, ...objectToPush}));
        setKahootLoading(false);
      })
      .catch(error => {
        console.error(error);
      });
  };

  const updateQuizzOnRecord = (values) => {
    const provider = (
      updateCurrentObject === setActivity
        ? 'Activity'
        : updateCurrentObject === setTouristicSite
          ? 'TouristicSite'
          : 'VisitSheet'
    );
    const provider_id = currentObject?.id;
    values.map((e,index) => {
      if(!e.question.text_fr.length > 0){
        values.splice(index, 1)
      }
    });
    handleSubmitForm(values, provider, provider_id);
  };

  const updateLayout = (layoutItemIndex, layoutItem, medias, callbacks = {}) => {
    const {onError, onSuccess} = callbacks;
    const newMedias = [...currentObject.medias];
    medias?.forEach((media) => {
      if (!media?.id && !newMedias.find(m => m.signed_id === media.signed_id) && media.type !== 'Title') {
        newMedias.push({signed_id: media.signed_id, language: media?.language});
      }
    });
    const newLayout = [...currentObject.layout].map((item, index) => index === layoutItemIndex ? layoutItem : item);
    if (layoutItemIndex >= (currentObject.layout?.length || 0)) {
      newLayout.push(layoutItem);
    }

    const token = localStorage.getItem("Authorization");
    fetch(
      editConfig.api(currentObject.id),
      {
        method: 'PATCH',
        cache: 'default',
        body: JSON.stringify({layout: newLayout, medias: newMedias}),
        headers: {
          "Authorization": `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        mode: 'cors',
      }
    )
      .then(res => res.json())
      .then(response => {
        updateCurrentObject(prev => ({...prev, ...response}));
        onSuccess?.(response);
      })
      .catch(error => {
        onError?.(error);
      });
  };

  const layoutFilteredWithTextsAndMedias = layoutFiltered?.map(layoutItem => (
    layoutItem ? (
        {
          ...layoutItem,
          items: layoutItem.items.map(item => ({
            ...item,
            content: item.content ? {
              ...item.content,
              ...(currentObject.medias.find(media => media?.blob_id === item.content.blob_id) || {}),
              ...(currentObject.texts.find(text => text.id === item.content.id) || {})
            } : undefined,
            items: item.items?.map(item => ({
              ...item,
              content: {
                ...item.content,
                ...(currentObject.medias.find(media => media.blob_id === item.content.blob_id) || {}),
                ...(currentObject.texts.find(text => text.id === item.content.id) || {})
              }
            })) || undefined,
          })),
        }
      )
      : null
  ));

  //TODO : PREMIERE PARTIE DU RETURN SERT POUR AFFICHER LA DATA UNE FOIS UPLOAD
  return(
    <AVPMFormContainer>
      {
        resourceCategory === 'texts' && !layoutFilteredIsEmpty && (
          <>
            {
              layoutFilteredWithTextsAndMedias.filter(m => m?.language === i18n.language).map((text,index)=> (
                text
                  ? (
                    <TitleDescription
                      key={index}
                      valuesProvided={text}
                      setOpenForm={setOpenForm}
                      onLayoutItemChange={(...args) => {
                        updateLayout(index, ...args)
                      }}
                      onDelete={() => {
                        deleteLayout(index)
                      }}
                      canDelete = {true}
                    />
                  )
                  : null
              ))
            }
            <hr style={{borderTop: '1px grey solid', width: '70%'}}></hr>
            <TitleDescription
              setOpenForm={setOpenForm}
              onLayoutItemChange={(...args) => updateLayout(layoutFilteredWithTextsAndMedias.length, ...args)}
            />
          </>
      )}
      {
        resourceCategory === 'images' && !layoutFilteredIsEmpty && (
          <>
            {
              layoutFilteredWithTextsAndMedias.map((carousel, index) =>
                carousel
                  ? (
                    <CarouselBuilder
                      key={index}
                      carousel={carousel}
                      onLayoutItemChange={(...args) => updateLayout(index, ...args)}
                      editable={editable}
                      activeStorageServiceName={editConfig?.activeStorageServiceName}
                    />
                  )
                  : null
              )
            }
          </>
        )}
      {
        resourceCategory === 'videos' && !layoutFilteredIsEmpty && (
          layoutFilteredWithTextsAndMedias.filter(m => m?.language === i18n.language).map((video, index) => {
            switch (video?.type) {
              case 'TitleVideo':
                return (
                  <>
                    <TitleVideoForm
                      key={index + i18n.language}
                      setSelectedIndex={setSelectedIndex}
                      selectedIndex={selectedIndex}
                      videosToDisplay={video}
                      onLayoutItemChange={(...args) => updateLayout(index, ...args)}
                      updateLayoutWithText={updateLayoutWithText}
                      onDelete={() => {
                        deleteLayout(index)
                      }}
                      update={true}
                      activeStorageServiceName={editConfig?.activeStorageServiceName}
                      canDelete={true}
                    />
                  </>
                );
              case 'VideoTitleImageArray':
                return (
                  <VideoTitleImageArray
                    key={index + 1 + i18n.language}
                    videoTitleImageArray={video}
                    onLayoutItemChange={(...args) => updateLayout(index, ...args)}
                    onDelete={(...args) => deleteLayout(index, ...args)}
                    activeStorageServiceName={editConfig?.activeStorageServiceName}
                  />
                );
              default:
                return null;
            }
          })
        )
      }
      {
        resourceCategory === 'audios' && !layoutFilteredIsEmpty && (
          layoutFilteredWithTextsAndMedias.filter(m => m?.language === i18n.language).map((audio, index) => {
            switch (audio?.type) {
              case 'TitleAudio':
                return (
                  <AudioToShowForm
                    id={index + i18n.language}
                    audio={audio}
                    key={index}
                    editable={true}
                    activeStorageServiceName={editConfig?.activeStorageServiceName}
                    onLayoutItemChange={(...args) => updateLayoutWithText(index, ...args)}
                    onDelete={deleteLayout}
                  />
                );
              case 'TitleAudioImage':
                return (
                  <AudioImageToShowForm
                    id={index}
                    audio={audio}
                    key={index + 1 + i18n.language}
                    editable={true}
                    activeStorageServiceName={editConfig?.activeStorageServiceName}
                    onLayoutItemChange={(...args) => updateLayout(index, ...args)}
                    onDelete={deleteLayout}
                  />
                );
              default:
                return null;
            }
          })
        )
      }
          {
            resourceCategory === 'texts' && layoutFilteredIsEmpty && (
              <TitleDescription
                key={layoutFiltered.length + 1}
                setSelectedForm={setSelectedForm}
                selectedForm={selectedForm}
                setOpenForm={setOpenForm}
                onLayoutItemChange={(...args) => updateLayout(layoutFiltered.length, ...args)}
              />
            )
          }
          {
            resourceCategory === 'images' && layoutFilteredIsEmpty && (
              <CarouselBuilder
                key={layoutFiltered.length + 2}
                onLayoutItemChange={(...args) => updateLayout(layoutFiltered.length, ...args)}
                editable={editable}
                activeStorageServiceName={editConfig?.activeStorageServiceName}
              />
            )
          }
          {
            resourceCategory === 'videos' && (
              <VideoFormToChoose
                id={layoutFiltered.length}
                key={layoutFiltered.length + 3}
                setSelectedIndex={setSelectedIndex}
                selectedIndex={selectedIndex}
                onLayoutItemChange={(...args) => updateLayout(layoutFiltered.length, ...args)}
                activeStorageServiceName={editConfig?.activeStorageServiceName}
              />
            )
          }
          {
            resourceCategory === 'audios' && (
              <FormikAudio
                key={layoutFiltered.length + 4}
                id={layoutFiltered.length}
                onLayoutItemChange={(...args) => updateLayout(layoutFiltered.length, ...args)}
                activeStorageServiceName={editConfig?.activeStorageServiceName}
              />
            )
          }
        {
          resourceCategory === 'quizzes' && (
            <Kahoot
              key={layoutFiltered.length + 4}
              quizzToShow={quizz || {}}
              onLayoutItemChange={(...args) => updateQuizzOnRecord(...args)}
              loading={kahootLoading}
            />
          )
        }
    </AVPMFormContainer>
  );
};

export default FormWrapper;