import {string, number, object, array, boolean} from 'yup';
import * as Yup from "yup";

const quizz_schema = Yup.array().of(
  Yup.object().shape({
    id: Yup.number().required('Champ requis'),
    quiz_id: Yup.number().required('Champ requis'),
    question_type: Yup.string(),
    picture: Yup.string(),
    question_id: Yup.number().required('Champ requis'),
    status: Yup.number().required('Champ requis'),
    position: Yup.number().required('Champ requis'),
    created_at: Yup.date().required('Champ requis'),
    updated_at: Yup.date().required('Champ requis'),
    question: Yup.object().shape({
      id: Yup.number().required('Champ requis'),
      record_type: Yup.string(),
      record_id: Yup.string(),
      text_fr: Yup.string().required('Champ requis'),
      text_en: Yup.string(),
      text_ge: Yup.string(),
      text_it: Yup.string(),
      text_sp: Yup.string(),
      text_cn: Yup.string(),
      text_sa: Yup.string(),
      text_pt: Yup.string(),
      text_jp: Yup.string(),
      text_ru: Yup.string(),
      created_at: Yup.date().required('Champ requis'),
      updated_at: Yup.date().required('Champ requis'),
    }),
    medias: Yup.array().of(
      Yup.object().shape({
        id: Yup.number().required('Champ requis'),
        name: Yup.string().required('Champ requis'),
        record_type: Yup.string().required('Champ requis'),
        record_id: Yup.string().required('Champ requis'),
        blob_id: Yup.number().required('Champ requis'),
        created_at: Yup.date().required('Champ requis'),
        alternate_text: Yup.string(),
        labels: Yup.array(),
        language: Yup.string(),
        signed_id: Yup.string().required('Champ requis'),
        url: Yup.string().required('Champ requis'),
        content_type: Yup.string().required('Champ requis'),
        filename: Yup.string().required('Champ requis'),
      })
    ),
    responses: Yup.array().of(
      Yup.object().shape({
        id: Yup.number().required('Champ requis'),
        question_id: Yup.number().required('Champ requis'),
        response_id: Yup.number().required('Champ requis'),
        detail_id: Yup.string(),
        good_answer: Yup.boolean().required('Champ requis'),
        status: Yup.number().required('Champ requis'),
        created_at: Yup.date().required('Champ requis'),
        updated_at: Yup.date().required('Champ requis'),
        value: Yup.string(),
        group_uuid: Yup.string().required('Champ requis'),
        activity_id: Yup.string(),
        response: Yup.object().shape({
          id: Yup.number().required('Champ requis'),
          record_type: Yup.string(),
          record_id: Yup.string(),
          text_fr: Yup.string().min(1).required('Champ requis'),
          text_en: Yup.string(),
          text_ge: Yup.string(),
          text_it: Yup.string(),
          text_sp: Yup.string(),
          text_cn: Yup.string(),
          text_sa: Yup.string(),
          text_pt: Yup.string(),
          text_jp: Yup.string(),
          text_ru: Yup.string(),
          created_at: Yup.date().required('Champ requis'),
          updated_at: Yup.date().required('Champ requis'),
        }),
      })
    ).ensure().min(4),
  })
);

export default quizz_schema;