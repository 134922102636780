import React from 'react';
import { useTranslation } from "react-i18next";

const Status = ({state}) => {
    const {t} = useTranslation(["henriTrip"])
    if (state) {
        return (
            <p className="text-primary">{t("roadTripPage.public")}</p>
        )
    }
    return (
        <p className="text-muted">{t("roadTripPage.private")}</p>
    );
};

export default Status;