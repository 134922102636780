import React, { useEffect, useState } from "react";
import { Button } from 'components/form/button';
import "./PartnerSectionsHeader.scss";
import { useTranslation } from "react-i18next";

const PartnerSectionsHeader = ({ background, demo }) => {
  const {t} = useTranslation(["henriTrip"])
  // eslint-disable-next-line
  const [width, setWidth] = useState(window.innerWidth)

  useEffect(() => {
    function handleResize() {
      setWidth(window.innerWidth)
    }
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize)
  }, []);

  return (
    <div className={`${ demo ? "partner__header-background-demo" : "partner__header-background"}`} style={{backgroundImage: `url(${background})`}}>
      <div className="container mt-4">
        <div className="row">
          <div className="col-12 col-lg-6">
            <p className="partner__header-title__top"> { t("partner.headerSection.becomePartner")} </p>
          </div>
        </div>
        <div className="row mt-2 mt-lg-1">
          <div className="col-12 col-lg-8">
            <p className="partner__header-title__middle"> {t("partner.headerSection.title")} </p>
          </div>
        </div>
        <div className="row mt-3 mt-lg-1">
          <div className="col-12 col-lg-7">
            <p className="partner__header-title__bot">{t("partner.headerSection.subtitle")}</p>
          </div>
        </div>
        {
          demo ?
            <Button rounded title="Essayer gratuitement" className="mt-3 partner__button" onClick={() => window.open("https://calendly.com/henri-trip/30min", '_blank')}/>
            :
            <Button rounded title={t("partner.headerSection.button")} className="mt-3 partner__button" onClick={() => window.document.getElementById('searchBar').scrollIntoView({block: "center"})}/>
        }
      </div>
    </div>
  );
}

export default PartnerSectionsHeader;
