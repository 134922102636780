import { Button } from 'components/form/button';
import Title from 'components/header/title/title';
import SliderButton from 'components/form/slider/slider-button';
import useSlick from 'hooks/use-slick';
import React from 'react';

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import SlickSlider from 'components/form/slider/slider';
import { useSelector } from 'react-redux';
import {PHONE_WIDTH} from "../../../utils/utils";

import "./PartnerSectionsSlider.scss"
import { useTranslation } from "react-i18next";




const PartnerSectionsSlider = ( props ) => {
  const {t} = useTranslation(["henriTrip"])
  const {touristic_sites} = useSelector(state => state.touristicSites);
  const {current, onChange, slick, setCurrent, total} = useSlick(touristic_sites || []);

  const content = [
    {
      title: t("partner.showMoreSection.slider.firstCard.title"),
      description: t("partner.showMoreSection.slider.firstCard.description"),
      image: "https://general-henri-1-paris.s3.fr-par.scw.cloud/partenaire_page/blog1.jpg",
      margin: "mt-1",
    },
    {
      title: t("partner.showMoreSection.slider.secondCard.title"),
      description: t("partner.showMoreSection.slider.secondCard.description"),
      image: "https://general-henri-1-paris.s3.fr-par.scw.cloud/partenaire_page/blog2.jpg",
      margin: "mt-4",
    },
    {
      title: t("partner.showMoreSection.slider.thirdCard.title"),
      description: t("partner.showMoreSection.slider.thirdCard.description"),
      image: "https://general-henri-1-paris.s3.fr-par.scw.cloud/partenaire_page/blog3.jpg",
      margin: "mt-4",
    },
    {
      title: t("partner.showMoreSection.slider.fourthCard.title"),
      description: t("partner.showMoreSection.slider.fourthCard.description"),
      image: "https://general-henri-1-paris.s3.fr-par.scw.cloud/partenaire_page/blog4.jpg",
      margin: "mt-4",
    },
  ]

  return (
    <section>
      <div className=" mt-3">
        <div className="row">
          <div className="col">
            <Title title={t("partner.showMoreSection.slider.title")}/>
          </div>
          {

            window.innerWidth > PHONE_WIDTH ?
              <>
                <div className="col-1 pt-3 pt-lg-4 p-0">
                  <SliderButton current={current} total={total} onChange={onChange} />
                </div>
                <div className="col-12 ">
                  <SlickSlider  slick={slick} handleBeforeChange={(oV, nV) => setCurrent(nV)}>
                    {
                      content?.map((el,i) =>
                        <>
                          <img src={el.image} className="partner__image-style" alt={i} />
                          <div className="partner__content-text">
                            <p className="partner__slider__title">{el.title}</p>
                            <p className="partner__slider__description">{el.description}</p>
                          </div>
                        </>

                      )
                    }
                  </SlickSlider>
                </div>
              </>
              :
              <>
                <div className="container">
                  {
                    content?.map((el,i) =>
                      <div className={`${el.margin}`}>
                        <img src={el.image} className="image-style"  alt={i} />
                        <p className="partner__slider__title mt-2">{el.title}</p>
                        <p className="partner__slider__description">{el.description}</p>
                      </div>
                    )
                  }
                </div>

              </>
          }

        </div>
      </div>
      <div className="row mt-5">
        <div className="col d-flex justify-content-center">
          <Button title={t("partner.becomePartner")} className="align-items-center" rounded onClick={() => window.document.getElementById('searchBar').scrollIntoView({block: "center"})} />
        </div>
      </div>
    </section>
  );
};

export default PartnerSectionsSlider;