import React from "react";
import styled from "styled-components";
import breakpoint from "styled-components-breakpoint";
import Button from "../../../_ui/_v2/components/Button";
import PopupModal from "../../../_ui/_v2/components/PopupModal";
import CardsInformationSlider from "../../../_ui/_v2/components/CardsInformationSlider";

import {ReactComponent as AccommodationIllustration} from "../../../assets/img/logo/PostPurchaseCard/accommodation-illustration.svg";
import {ReactComponent as TicketingIllustration} from "../../../assets/img/logo/PostPurchaseCard/ticketing-illustration.svg";
import {ReactComponent as MusicIllustration} from "../../../assets/img/logo/PostPurchaseCard/music-illustration.svg";

import {ReactComponent as BusIcon} from "../../../assets/img/logo/transport/bus-icon.svg";
import {ReactComponent as CarIcon} from "../../../assets/img/logo/transport/car-icon.svg";
import {ReactComponent as PlaneIcon} from "../../../assets/img/logo/transport/plane-icon.svg";
import {ReactComponent as TrainIcon} from "../../../assets/img/logo/transport/train-icon.svg";

const PostPurchaseCardsStyled = styled.div`
  background-color: ${props => props.theme.colors.white};
  border-radius: 20px;
  padding: 0 0 20px 0;
  height: 100%;
`;

const CommonCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  padding: 0 20px 0 20px;
  
  h3 {
    font-weight: 700;
    font-size: 1.8em;
  }
  
  p {
    font-weight: 600;
    font-size: 1.1em;
    margin-bottom: 0;
  }
  
  svg {
    width: 100%;
    height: auto;
    padding: 1em;
  }
`;

const ButtonMargin = styled(Button)`
  ${breakpoint('md')`
    margin-left: 40px;
    margin-right: 40px;
  `}
`;

const AccommodationCard = () => (
  <CommonCardContainer>
    <h3>Logement</h3>
    <p>Avez-vous déjà votre logement&nbsp;?</p>
    <AccommodationIllustration/>
    <ButtonMargin
      onClick={() => window.open('https://www.stay22.com/allez/booking?&aid=henritrip&checkin=&checkout=&adults=&children=&link=https://booking.com/','_blank')}
    >
      Réserver
    </ButtonMargin>
  </CommonCardContainer>
);

const TicketingCard = () => (
  <CommonCardContainer>
    <h3>Billetterie</h3>
    <p>N'oubliez pas de réserver vos activités à l'avance&nbsp;!</p>
    <TicketingIllustration/>
    <ButtonMargin
      onClick={() => window.open('https://www.getyourguide.com?partner_id=21JVMM5&cmp=share_to_earn','_blank')}
    >
      Réserver
    </ButtonMargin>
  </CommonCardContainer>
);

const MusicCard = () => (
  <CommonCardContainer>
    <h3>Musique</h3>
    <p>Votre playlist voyage</p>
    <MusicIllustration/>
    <ButtonMargin
      onClick={() => window.open('https://open.spotify.com/playlist/7FJzNwyzS6ub2SFXnKfdLh','_blank')}
    >
      En profiter
    </ButtonMargin>
  </CommonCardContainer>
);

const TransportCardOptionButton = styled(Button)`
  width: 100%;
  background-color: ${props => props.theme.colors.lightGrey};
  color: ${props => props.theme.colors.black};
  text-align: left;
  display: flex;
  flex-direction: row;
  gap: 1em;

  :hover, :active {
    background-color: ${props => props.theme.colors.primary};
    color: ${props => props.theme.colors.white};
    
    svg {
      color: ${props => props.theme.colors.white};
    }
  }
  
  svg {
    height: 100%;
    width: 100%;
    padding: 0;
    color: ${props => props.theme.colors.primary};
  }
  
  > * {
    margin: auto 0;
  }
`;

const TransportCardOption = ({explanation, buttonText, ButtonIcon, onButtonClick}) => (
  <div
    style={{
      textAlign: 'left',
    }}
  >
    <span
      style={{
        fontSize: '.75em',
        fontWeight: 600,
      }}
    >
      {explanation}
    </span>
    <TransportCardOptionButton
      onClick={onButtonClick}
    >
      <span style={{width: 25, height: 25, textAlign: 'center'}}>
        <ButtonIcon/>
      </span>
      <span>{buttonText}</span>
    </TransportCardOptionButton>
  </div>
);

const transportOptions = [
  {
    explanation: 'Comparez les vols',
    buttonText: 'Billets d’avion',
    ButtonIcon: PlaneIcon,
    onButtonClick: () => window.open('www.kiwi.com/deep?affilid=henritriphenrideeplink&lang=fr&pageName=tilesPage&return=anytime&transport=aircraft','_blank'),
  },
  {
    explanation: 'Comparez les trains',
    buttonText: 'Billets de train',
    ButtonIcon: TrainIcon,
    onButtonClick: () => window.open('www.kiwi.com/deep?affilid=henritriphenrideeplink&lang=fr&pageName=tilesPage&return=anytime&transport=train','_blank'),
  },
  {
    explanation: 'Comparez les bus',
    buttonText: 'Prendre le bus',
    ButtonIcon: BusIcon,
    onButtonClick: () => window.open('www.kiwi.com/deep?affilid=henritriphenrideeplink&lang=fr&pageName=tilesPage&return=anytime&transport=bus','_blank'),
  },
  {
    explanation: 'Comparez les locations',
    buttonText: 'Louer une voiture',
    ButtonIcon: CarIcon,
    onButtonClick: () => window.open('https://www.discovercars.com/?a_aid=henri-trip&a_bid=efec0e0d','_blank'),
  },
];

const TransportCard = () => (
  <CommonCardContainer>
    <h3>Transport</h3>
    <p>Avez vous déjà votre moyen de transport&nbsp;?</p>
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        height: '100%',
      }}
    >
      {
        transportOptions.map((option, index) => (
          <TransportCardOption
            key={index}
            {...option}
          />
        ))
      }
    </div>
  </CommonCardContainer>
);

const cards = [
  <TransportCard/>,
  <AccommodationCard/>,
  <TicketingCard/>,
  <MusicCard/>,
];

const PostPurchaseCards = (props) => (
  <PostPurchaseCardsStyled {...props}>
    <CardsInformationSlider
      cards={cards}
      style={{
        maxWidth: 400,
        height: 500,
      }}
    />
  </PostPurchaseCardsStyled>
);

const PostPurchaseCardsModal = (props) => {
  return (
    <PopupModal
      overlay
      withCloseIcon
      {...props}
    >
      <PostPurchaseCards/>
    </PopupModal>
  );
};

export default PostPurchaseCards;

export {
  PostPurchaseCardsModal,
};