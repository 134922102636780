import styled from 'styled-components';
import breakpoint from 'styled-components-breakpoint';
import {ReactComponent as PlusIcon} from '../../../assets/img/logo/plus-icon.svg';

const VisitCardStyled = styled.div`
  position: relative;
  box-sizing: border-box;
  box-shadow: 0 10px 13px -7px #000000, 16px 16px 23px -2px rgb(0 0 0 / 17%);
  border-radius: 1rem;
  width: 16em;
  height: 100%;
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  justify-content: center;
  align-content: flex-start;
  gap: ${props => props.gap ? props.gap : '40px'};
  background-color: ${props => props.color || '#3880EB'};

  ${breakpoint('md')`
    width: ${props => props.width || '19rem'};
  `}
`;

const NumberOfDaysVisit = styled.div`
  height: 25px;
  width: 25px;
  position: absolute;
  right: -25px;
  top: -25px;
  background-color: ${props => props.colorChip};
  padding: 1em;
  border-radius: 2em;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2em;
  color: white;
  font-weight: 800;
`;

const VisitsContainerStyled = styled.div `
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 15px;
  height: 20%;
`;
const OverflowContainer = styled.div `
  overflow: hidden;
  width: 225px;
  height: 235px;
`;

const ImageVisitCardContainerStyled = styled.div `
  height: 65px;
  width: 65px;
`;

const TitleComponentStyled = styled.h4 `
  width: 60%;
  text-align: center;
  color: ${props => props.theme.colors.white};
  margin-top: 10px;
`;

const MainVisitCardStyled = ({TitleComponent, colorChip, children, ...rest}) => (
  <VisitCardStyled
    color="#A5A7AB" 
    gap="20px" 
    width="18.5rem"
    {...rest}
  >
    <NumberOfDaysVisit colorChip={colorChip}>{children.length || 0}</NumberOfDaysVisit>
    <TitleComponentStyled>{TitleComponent}</TitleComponentStyled>
    <OverflowContainer>
      <VisitsContainerStyled>
        {children.map((child, index) => (
          <ImageVisitCardContainerStyled key={index}>
            {child}
          </ImageVisitCardContainerStyled>
        ))}
      </VisitsContainerStyled>
    </OverflowContainer>
  </VisitCardStyled>
);

const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1em;
  border: 2px solid ${props => props.theme.colors.white};
  border-radius: 50%;
  margin: 0 auto;
  width: 80px;
  height: 80px;
  
  svg {
    width: 30px;
    height: 30px;
  }
`;

const EmptyCard = styled(VisitCardStyled)`
  flex-direction: column;
  align-content: center;
  align-items: center;
  gap: 20px;
  color: ${props => props.theme.colors.white};
  font-weight: 700;
  font-size: 22px;
`;

const EmptyVisitCardStyled = styled(EmptyCard)`
  background-color: ${props => props.theme.colors.activity};
`;

const EmptyInterestPointCardStyled = styled(EmptyCard)`
  background-color: ${props => props.theme.colors.interestPoint};
`;

const EmptyVisitCard = () => (
  <EmptyVisitCardStyled>
    <IconContainer>
      <PlusIcon/>
    </IconContainer>
    <div>Visite</div>
  </EmptyVisitCardStyled>
);

const EmptyInterestPointCard = () => (
  <EmptyInterestPointCardStyled>
    <IconContainer>
      <PlusIcon/>
    </IconContainer>
    <div>Créer un point d’intérêt</div>
  </EmptyInterestPointCardStyled>
);

export default MainVisitCardStyled;

export {
  EmptyVisitCard,
  EmptyInterestPointCard,
};