import i18n from "i18next";
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from "react-i18next";

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)

i18n.use(Backend).use(LanguageDetector).use(initReactI18next).init({
  backend: {
    loadPath: "/assets/i18n/{{ns}}/{{lng}}.json",
  },
  lng: localStorage.getItem("i18nextLng") || "fr",
  fallbackLng: ['fr', 'en'],
  debug: false,
  ns: ["henriTrip"],

  interpolation: {
    espaceValue: false,
    formatSeparator: ',',
  },
  react: {
    useSuspense: true,
  },
  });
export default i18n;