import React, {useState, useEffect} from 'react';
import "./categoriesFilter.scss"

const CategoriesFilter = ({selected, name, handleClick, value}) => {

  const [textColor, setTextColor] = useState("white")

  useEffect(() => {
      if(selected) {
        setTextColor("white");
      } else {
        setTextColor("black");
      }
  }, [selected])


  return (
          <div className="card_container" style={{ backgroundColor: selected ? "#004F91" : "#FFFFFF" }} onClick={() => handleClick(value)}>
            <div className="card_text" style={{ color: textColor }}>
              {name}
            </div>
          </div>
  );
};

export default CategoriesFilter;