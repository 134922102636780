import React from 'react';
import useDocumentTitle from 'hooks/use-document-title';
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const PaymentPage = ({transactions}) => {
    const {t} = useTranslation(["henriTrip"])

    useDocumentTitle(t("account.paymentPage.documentTitle"))

    return (
    <div className="container">
        <div className="col-lg-7 offset-md-1 mt-4">
            <div className="row">
                <div className="col">
                    <h2>{t("account.paymentPage.title")}</h2>
                </div>
            </div>

            <table className="table table-bordered mt-2">
                <thead className="thead-dark">
                <tr>
                    <th scope="col">{t("account.paymentPage.invoice.orderNumber")}</th>
                    <th scope="col">{t("account.paymentPage.invoice.product")}</th>
                    <th scope="col">{t("account.paymentPage.invoice.price")}</th>
                    <th scope="col">{t("account.paymentPage.invoice.date")}</th>
                    <th scope="col">PDF</th>
                </tr>
                </thead>
                <tbody>
                {
                    transactions?.map(el => (
                      <tr key={el.id}>
                          <td scope="row">{el.transaction_number}</td>
                          <th>
                              <Link to={el.record.amount > 0 ? `/experiences/${el.record.id}` : `/roadtrip/${el.record.id}`}>
                                  {el.record.title}
                              </Link>
                          </th>
                          <td>{el.amount} €</td>
                          <td>{new Date(el.created_at).toLocaleDateString()}</td>
                          <td>
                              <a href={el.invoice_url} target="_blank">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    width="24"
                                  >
                                      <path d="M0 0h24v24H0z" fill="none" />
                                      <path d="M20 2H8c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2zm-8.5 7.5c0 .83-.67 1.5-1.5 1.5H9v2H7.5V7H10c.83 0 1.5.67 1.5 1.5v1zm5 2c0 .83-.67 1.5-1.5 1.5h-2.5V7H15c.83 0 1.5.67 1.5 1.5v3zm4-3H19v1h1.5V11H19v2h-1.5V7h3v1.5zM9 9.5h1v-1H9v1zM4 6H2v14c0 1.1.9 2 2 2h14v-2H4V6zm10 5.5h1v-3h-1v3z" />
                                  </svg>
                              </a>

                          </td>
                      </tr>
                    ))
                }


                </tbody>
            </table>
        </div>
    </div>
           

    );
};

export default PaymentPage;