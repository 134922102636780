import React, {useLayoutEffect, useRef, useState} from "react";
import styled from "styled-components";
import breakpoint from "styled-components-breakpoint";
import LinkWrapper from "../LinkWrapper";

const StickyBottomNavBarStyled = styled.nav`
  display: block;
  position: fixed;
  bottom: 0;
  background-color: ${props => props.theme.colors.white};
  width: 100%;
  
  ${breakpoint('md')`
    display: none;
  `}
  
  ul {
    list-style: none;
    margin: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    
    li {
      margin: 0;
      flex-grow: 1;
      text-align: center;
      padding: 10px;
      cursor: pointer;
      font-weight: 700;
      font-size: 11px;
      
      a {
        color: ${props => props.theme.colors.black};
      }
    }
  }
`;

const StickyBottomNavBarWrapper = styled.div`
  max-height: calc(100vh - ${props => props.bottomBarAppearOnScroll ? Math.min(props.currentScrollTop, props.bottomGap) : props.bottomGap}px);
  max-width: 100vw;
  overflow-x: hidden;
  overflow-y: auto;
  padding-top: ${props => props.topGap}px;

  /* Hide scrollbar for Chrome, Safari and Opera */
  ::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
  
  ${breakpoint('md')`
    max-height: 100vh;
    
    /* Hide scrollbar for Chrome, Safari and Opera */
    ::-webkit-scrollbar {
      display: block;
    }
    -ms-overflow-style: initial;  /* IE and Edge */
    scrollbar-width: initial;  /* Firefox */
  `}
`;

const StickyBottomNavBar = (
  {
    onScroll = () => {
    },
    topGap = 0,
    entries = [],
    bottomBarAppearOnScroll = false,
    children
  }
) => {
  const navBarRef = useRef();
  const [offsetHeight, setOffsetHeight] = useState(0);
  const [currentScrollTop, setCurrentScrollTop] = useState(0);

  useLayoutEffect(() => {
    const observer = new ResizeObserver(() => {
      setOffsetHeight(navBarRef.current?.offsetHeight);
    });
    observer.observe(navBarRef.current);

    return () => observer.disconnect();
  }, [navBarRef]);

  return (
    <>
      <StickyBottomNavBarWrapper
        topGap={topGap}
        bottomGap={offsetHeight}
        currentScrollTop={currentScrollTop}
        onScroll={event => {
          if (bottomBarAppearOnScroll) {
            setCurrentScrollTop(event.target.scrollTop);
          }
          onScroll(event);
        }}
        bottomBarAppearOnScroll={bottomBarAppearOnScroll}
      >
        {children}
      </StickyBottomNavBarWrapper>
      <StickyBottomNavBarStyled ref={navBarRef} style={{bottom: bottomBarAppearOnScroll ? Math.min(-offsetHeight + currentScrollTop, 0) : 0}}>
        <ul>
          {
            entries.map(({Icon, label, to, href, onClick, ...rest}, index) => (
              <li
                key={index}
                onClick={event => onClick?.(event)}
              >
                <LinkWrapper
                  to={to}
                  href={href}
                  {...rest}
                >
                  <Icon style={{display: 'block', margin: '0 auto', width: 35, height: 35}}/>
                  <span>{label}</span>
                </LinkWrapper>
              </li>
            ))
          }
        </ul>
      </StickyBottomNavBarStyled>
    </>
  );
};

export default StickyBottomNavBar;