import React, { useEffect, useState } from "react";
import {PHONE_WIDTH} from "../../../utils/utils";
import { LineChart, Line, CartesianGrid, XAxis, YAxis, Tooltip } from 'recharts';
import useDocumentTitle from "../../../hooks/use-document-title";
import { useTranslation } from "react-i18next";
import { Dropdown } from "semantic-ui-react";
import { Button } from "../../../components/form/button";
import Title from 'components/header/title/title';
import "./AmbassadorDashboardPage.scss";
import { useHistory } from "react-router";
import { useSelector } from "react-redux";
import { API_STAT_ROADTRIP } from "../../../utils/api";
import useRequest from "../../../hooks/use-request";

const AmbassadorDashboardPage = () => {
  const {t} = useTranslation(["henriTrip"])
  const [handleRequest] = useRequest();
  useDocumentTitle(t("ambassador.dashboard.documentTitle"));
  const {user} = useSelector(state=> state.user)
  const { userRoadtrips } = useSelector(state => state.roadtrips)
  const config = useSelector(state => state.data);
  const history = useHistory();
  const [roadTripData, setRoadTripData] = useState();
  const [filter, setFilter] = useState("all");

  useEffect(() => {
    handleRequest("get", API_STAT_ROADTRIP(filter), {}, config, (data, status) => {
      if (status === "Done") {
        if(data) {
          setRoadTripData((roadTripData) => {
            return data.map(el=> {
              return {
                ...roadTripData,
                date: formatDate(el.created_at),
                vues: el.count,
                gains: (el.count * user.ambassador_rate)/1000
              }
            })
          }
          )
        }
      }else {
        console.error(data)
      }
    })
    // eslint-disable-next-line
  }, [filter])


 const formatDate = (date) => {
   return date.substring(0,10).slice(-5).substring(3,5) + "/" + date.substring(0,10).slice(-5).substring(0,2);
 }

  let roadTripsOptions = [
    {
      key: 'all',
      text: t("ambassador.dashboard.roadtripFilter"),
      value: 'all'
    },
    ...userRoadtrips?.map((el) => {
      return {
        key: el.id,
        text: el.title,
        value: el.id
      }
    })
  ]

  let numberView = 0;
  roadTripData?.map(el => numberView += el.vues)
  const estimatedGains = (numberView * user.ambassador_rate)/1000;

  const onChangeFilter = (e, data) => {
    setFilter(data.value);
  }

  return (
    <>
    <div className="container">
        <div className="row mt-3">
          <div className="col-12 col-lg-9">
            <h1>{t("ambassador.dashboard.ambassador")} Henri Trip</h1>
          </div>
          <div className="col-12 col-lg-3 mt-2 mt-lg-0">
            <Button title={t("ambassador.dashboard.paymentParams")} rounded color="information-block" onClick={()=> history.push("/user/ambassador-payment")}/>
          </div>
        </div>
        <div className="row col-12">
          <Title title={t("ambassador.dashboard.statistics")}/>
        </div>
          <div className="row mt-2">
            <div className="col-12 col-lg-3">
              <Dropdown
                button
                className="icon"
                placeholder="Tous mes Road Trips"
                icon="sliders horizontal"
                options={roadTripsOptions}
                labeled
                onChange={onChangeFilter}
              />
            </div>
          </div>
          <div className="row">
              <div className="col-12">
                <Title title="Sessions"/>
              </div>
          </div>
          <div className="container">
            <div className="row">
              <div className="col-12 col-lg-4">
                <div className="row subtitle">
                  <p>{t("ambassador.dashboard.numberView")}</p>
                </div>
                <div className="row text-primary text-data mt-2">
                  <p> {numberView} </p>
                </div>
              </div>
              <div className="col-12 col-lg-6 mt-1 mt-lg-0">
                {
                  window.innerWidth > PHONE_WIDTH ?
                    <LineChart width={600} height={300} data={roadTripData} margin={{ top: 5, right: 20, bottom: 5, left: 0 }}>
                      <Line type="monotone" dataKey="vues" stroke="#8884d8" />
                      <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
                      <XAxis dataKey="date" />
                      <YAxis />
                      <Tooltip />
                    </LineChart>
                    :
                    <LineChart width={300} height={300} data={roadTripData} margin={{ top: 5, right: 20, bottom: 5, left: 0 }}>
                      <Line type="monotone" dataKey="vues" stroke="#8884d8" />
                      <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
                      <XAxis dataKey="date" />
                      <YAxis />
                      <Tooltip />
                    </LineChart>
                }
              </div>
            </div>
          </div>

          <div className="container mt-2">
            <div className="row">
              <div className="col-12 col-lg-4">
                <div className="row title-xlarge">
                  <p>{t("ambassador.dashboard.yourEarnings")}</p>
                </div>
                <div className="row mt-2 subtitle">
                  <p>{t("ambassador.dashboard.earnings")}</p>
                </div>
                <div className="row text-primary text-data mt-2">
                  <p> {estimatedGains} €</p>
                </div>
              </div>
              <div className="col-12 col-lg-6 mt-1 mt-lg-0">
                {
                  window.innerWidth > PHONE_WIDTH ?
                    <LineChart width={600} height={300} data={roadTripData} margin={{ top: 5, right: 20, bottom: 5, left: 0 }}>
                      <Line type="monotone" dataKey="gains" stroke="#8884d8" />
                      <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
                      <XAxis dataKey="date" />
                      <YAxis />
                      <Tooltip />
                    </LineChart>
                    :
                    <LineChart width={300} height={300} data={roadTripData} margin={{ top: 5, right: 20, bottom: 5, left: 0 }}>
                      <Line type="monotone" dataKey="gains" stroke="#8884d8" />
                      <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
                      <XAxis dataKey="date" />
                      <YAxis />
                      <Tooltip />
                    </LineChart>
                }
              </div>
            </div>
          </div>
    </div>
      </>
  );
};

export default AmbassadorDashboardPage;