import { createGlobalStyle, css } from 'styled-components';
import { normalize } from 'styled-normalize';
import typography from './typography';

const GlobalStyle = createGlobalStyle`${
    css`
    // css Normalize
    ${normalize}
    ${typography}

    // global styles
    body {
      padding: 0;
      font-family: ${props => props.theme.fonts.sans};
      font-size: 16px;
      line-height: 1.5;
      color: ${props => props.theme.colors.text};
      overflow-x: hidden;

      &.ReactModal__Body--open {
        overflow-y: hidden;
      }
    }

    html, body {
      margin:0;
      padding:0;
      width:100%;
      height:100%;
      overflow-x: hidden;
      overflow-y: auto;
    }

    .align-center {
      text-align: center;
    }

    .align-left {
      text-align: left;
    }

    .align-right {
      text-align: right;
    }

    .color-primary {
      color: ${props => props.theme.colors.primary};
    }

    .color-secondary {
      color: ${props => props.theme.colors.secondary};
    }

    .error * {
      color: inherit;
    }
  `
}`;

export { GlobalStyle };
export default GlobalStyle;
