import {API_DIRECT_UPLOAD_TEXT, API_PATCH_TOURISTIC_SITE} from "../../../utils/api";
import {useFormik} from "formik";
import * as Yup from "yup";
import {object, string} from "yup";
import styled from "styled-components";
import Button from "../../../_ui/_v2/components/Button";
import ButtonFormValidation from "./ButtonFormValidation";
import {useState} from "react";

const InputStyled = styled.input`
  background-color: rgba(0, 0, 0, .8);
  border: 1px solid rgba(169, 169, 169, 0.6);
  color: white;
  padding: .7em 1em .5em .5em;
  resize: none;
  border-radius: .5em;
  width: 100%;
  ::placeholder{
    color: white;
  }
`;

const FormValidation = ({setOpenForm, setJson}) => {
  return (
    <>
      <div>
        <Button onClick={() => setOpenForm(false)}>
          X
        </Button>
        <Button>
          Valider
        </Button>
      </div>
    </>
  );
};

const FormTitleStyled = styled.form `
  display: flex;
  flex-direction: column;
  gap: ${props => props.gap ? props.gap : '20px'}
`;

const Title = ({callback, valuesProvided, setOpenForm}) => {
  const [loading, setLoading] = useState(false);
  const handleSubmitForm = (values) => {
    const token = localStorage.getItem("Authorization");
    const form = new FormData();
    Object.keys(values).forEach((key,index) => {
      form.append(key, values[key]);
    })
    setLoading(true);
    fetch(
      API_DIRECT_UPLOAD_TEXT,
      {
        method: valuesProvided ? 'POST' : 'PATCH',
        cache: 'default',
        body: form,
        headers: {
          "Authorization": `Bearer ${token}`,
        },
        mode: 'cors',
      }
    )
      .then(res => res.json())
      .then(response => {
        callback({id: response.id}, 'Title')
      })
      .catch(error => {
        setLoading(false);
        console.error(error);
      });
  };

  const {
    handleSubmit,
    handleChange,
    handleBlur,
    values,
  } = useFormik({
    initialValues: valuesProvided || {
      text_fr: ''
    },
    validationSchema: object({
      text_fr: string().required('Champs requis')
    }),
    onSubmit: handleSubmitForm,
  });

  return(
    <FormTitleStyled onSubmit={handleSubmit} gap='60px'>
        <InputStyled
          type='text'
          name={'text_fr'}
          onChange={handleChange}
          onBlur={handleBlur}
          value={values['text_fr']}
          placeholder='Titre'
        />
      <ButtonFormValidation loading={loading} setOpenForm={setOpenForm} />
    </FormTitleStyled>
  );
};

export default Title;