import React from 'react';
import PropTypes from 'prop-types';
import {toAbsoluteUrl} from 'utils/utils';

import './avatar.scss';

const SIZES = {
    xs: 25,
    sm: 35,
    md: 45,
    lg: 65,
    xl: 85
}

const Avatar = props => {
    const sizes = React.useMemo(() => ({
        width: SIZES[props.size || "md"],
        height: SIZES[props.size || "md"]
    }), [props.size]);

    return (
        <div className={props.classNames} style={sizes}>
            {
                props.img ?
                <img className="avatar-img rounded-circle" style={{
                    objectFit: "cover",
                    objectPosition: "center",
                    width: 100 + "%",
                    height: 100 + "%"
                }} src={props.img} alt={props.alt} loading="lazy" />
                :
                <img src={toAbsoluteUrl("/assets/icons/Fichier 43.svg")} width={sizes.width} alt="lol" loading="lazy" />
            }
            {
                props.children
            }
        </div>
    );
};

Avatar.propTypes = {
    size: PropTypes.oneOf(["xs", "sm", "md", "lg", "xl"]),
    img: PropTypes.string,
    border: PropTypes.bool,
    alt: PropTypes.string,
    bgColor: PropTypes.string,
    classNames: PropTypes.string
};

export default Avatar;