import * as React from "react"

function Icon1(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 88.56 106.9"
      style={{color: "#F2939E"}}
      {...props}
    >
      <defs>
        <style>{".prefix__cls-2{fill:#fff}"}</style>
      </defs>
      <g id="prefix__Calque_2" data-name="Calque 2">
        <g id="prefix__Calque_1-2" data-name="Calque 1">
          <path
            fill="currentColor"
            d="M44.28 0a44.27 44.27 0 00-31.16 75.73l31.16 31.17 31.16-31.17A44.27 44.27 0 0044.28 0"
          />
          <path
            className="prefix__cls-2"
            d="M44.28 56s8-8.61 8-19.22-8-19.22-8-19.22-8 8.61-8 19.22 8 19.22 8 19.22z"
          />
          <path
            className="prefix__cls-2"
            d="M48.81 54.48c3.85-1.65 9.25-4.82 12.5-10.43C66.77 34.61 64 21.84 64 21.84a31.37 31.37 0 00-11 5.29 27.91 27.91 0 011.83 9.67c.01 7.46-3.44 13.9-6.02 17.68z"
          />
          <path
            className="prefix__cls-2"
            d="M49.08 57.37c3.51.65 8.4.86 12.79-1.45 8-4.18 11.26-15.05 11.26-15.05A26.44 26.44 0 0066 39.19a26.71 26.71 0 01-2.61 6.38c-3.76 6.58-10.09 10.09-14.31 11.8zM39.75 54.48c-2.58-3.78-6-10.22-6-17.68a27.91 27.91 0 011.83-9.67 31.37 31.37 0 00-11-5.29s-2.73 12.77 2.73 22.21c3.19 5.61 8.59 8.78 12.44 10.43z"
          />
          <path
            className="prefix__cls-2"
            d="M26.69 55.92C31.08 58.23 36 58 39.48 57.37c-4.22-1.71-10.55-5.22-14.35-11.8a26.71 26.71 0 01-2.61-6.38 26.44 26.44 0 00-7.09 1.68s3.29 10.87 11.26 15.05zM67.33 55.28A19.51 19.51 0 0163 58.46a19.78 19.78 0 01-9.3 2.16 26.89 26.89 0 01-5-.48l-.84-.14a17 17 0 009.06 4.61c7.35.9 14-5.34 14-5.34a25.28 25.28 0 00-3.59-3.99zM39.89 60.14a26.89 26.89 0 01-5 .48 19.78 19.78 0 01-9.3-2.16 19.51 19.51 0 01-4.38-3.18 25.28 25.28 0 00-3.59 4s6.65 6.24 14 5.34A17 17 0 0040.7 60zM46.27 62.17l-2-1.8-2 1.8a23.77 23.77 0 01-4.46 3.24c-.28.15-.56.3-.85.44L44.28 71l7.3-5.13c-.29-.14-.57-.29-.85-.44a23.77 23.77 0 01-4.46-3.26z"
          />
        </g>
      </g>
    </svg>
  )
}

export default Icon1
