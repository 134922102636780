import { Button } from 'components/form/button';
import InputFormik from 'components/form/input/inputFormik';
import { Form } from 'formik';
import React from 'react';
import AuthPageStructure from './AuthPageStructure';
import useDocumentTitle from 'hooks/use-document-title';
import {useTranslation} from "react-i18next";

const ResetPasswordPage = (props) => {
  const {t} = useTranslation(["henriTrip"])
  useDocumentTitle(`Mot de passe oublié • Henri Trip`)
  if (props.valid === "Loading") {
    return <AuthPageStructure>
      <p>{t("authPage.processing")}...</p>
    </AuthPageStructure>
  }

  return (
    <AuthPageStructure>
      {
        props.valid === "Error" ?
          <div className="p-3 bg-danger mb-3 text-white">
            {t("authPage.linkExpired")}
          </div>
          :
          <Form onSubmit={props.handleSubmit}>
            {
              props.error &&
              <div className="p-3 bg-danger mb-3 text-white">
                {props.error}
              </div>
            }
            <InputFormik {...props} type="password" {...props.resetPwdForm.password} />
            <InputFormik {...props} type="password" {...props.resetPwdForm.password_confirmation} />
            <Button extended color="henri" type="submit" title={t("authPage.resetPassword")} />
          </Form>
      }
    </AuthPageStructure>
  );
};

export default ResetPasswordPage;