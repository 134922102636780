import useCart from 'hooks/Cart/use-cart';
import CheckoutPage from 'pages/CheckoutPage/CheckoutPage';
import React from 'react';
import { Redirect, useHistory } from 'react-router';

const Checkout = () => {
    const {cartProv: {cart}, actions} = useCart();
    const history = useHistory();

    const handleBack = () => {
        history.goBack();
    }

    if (!cart.cart)
        return <Redirect to="/home" />

    return (
        <CheckoutPage cart={cart.cart} handleBack={handleBack} />
    );
};

export default Checkout;