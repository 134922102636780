import React from 'react';
import L from 'leaflet';
import ReactDOMServer from 'react-dom/server';
import Icon6 from '../ReactIcons/icon6';
import { ICON_ANCHOR, ICON_SIZE } from '.';

const mapIcon6 = L.divIcon({
    html: ReactDOMServer.renderToString(<Icon6 />),
    iconSize: ICON_SIZE,
    iconAnchor: ICON_ANCHOR
});

export default mapIcon6;