import styled from 'styled-components';

const InputErrorWrapperStyled = styled.div`
  .form-error-message {
    display: inline-block;
    color: ${props => props.theme.colors.error};
  }
`;

const InputErrorWrapper = ({children, hasError, errorMessage, ErrorComponent, errorComponentProps, ...rest}) => (
  <InputErrorWrapperStyled {...rest}>
    { children }
    {
      hasError && (errorMessage || ErrorComponent) && (
        ErrorComponent
          ? <ErrorComponent { ...errorComponentProps }/>
          : <span className="form-error-message small">{ errorMessage }</span>
      )
    }
  </InputErrorWrapperStyled>
);

export default InputErrorWrapper;