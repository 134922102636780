import React, { Fragment, useRef, useState } from "react";
import Navbar from "../containers/Header/Navbar";
import NavbarBottom from "../containers/Header/NavBarBottomV2.jsx";
import { ThemeProvider } from "styled-components";
import theme from "../_ui/_v2/styles/theme";
import StickyNavBar from "../_ui/_v2/components/StickyNavBar";
import { LoginModal } from "../_ui/_v2/components/Login/Login";
import GlobalStyle from "../_ui/_v2/styles/global_style";

const NavBarContext = React.createContext({});

const DefaultNavBar = ({ children, ...rest }) => (
  <>
    <Navbar {...rest} />
    {children}
    <NavbarBottom {...rest} />
  </>
);

const V2NavBar = ({ navBarList = [], onPageScroll, loginModalProps, closeLoginModal, bottomBarAppearOnScroll, children }) => (
  <ThemeProvider theme={theme}>
    <GlobalStyle />
    <StickyNavBar
      onPageScroll={onPageScroll}
      bottomBarAppearOnScroll={bottomBarAppearOnScroll}
      entries={navBarList}
    >
      {children}
    </StickyNavBar>
    {loginModalProps?.isOpen && (
      <LoginModal
        {...loginModalProps}
        onRequestClose={(params) => {
          loginModalProps?.onRequestClose?.(params);
          closeLoginModal();
        }}
        onLoginSucceeded={(params) => {
          loginModalProps?.onLoginSucceeded?.(params);
          closeLoginModal();
        }}
      />
    )}
  </ThemeProvider>
);

const navBars = {
  default: DefaultNavBar,
  v2: V2NavBar,
  without: Fragment,
};

const NavBarProvider = ({ children, initialValues = {} }) => {
  const [values, setValues] = useState(initialValues);
  const historyNavBar = useRef([]);
  const historyFooter = useRef([]);
  const historyOnPageScroll = useRef([]);
  const historyNavBarList = useRef([]);

  const navBar = () => values.navBarName;

  const setNavBar = name => {
    setValues(
      prev => {
        historyNavBar.current.push(prev.navBarName);
        return ({ ...prev, navBarName: name });
      }
    );
  };

  const restoreNavBar = () => {
    const previousState = historyNavBar.current.pop();
    setValues(prev => ({ ...prev, navBarName: previousState }));
  };

  const footerVisible = () => values.footerVisible;

  const setFooterVisible = value => {
    historyFooter.current.push(footerVisible());
    setValues(prev => ({ ...prev, footerVisible: value }));
  };

  const restoreFooterVisible = () => {
    const previousState = historyFooter.current.pop();
    setValues(prev => ({ ...prev, footerVisible: previousState }));
  };

  const bottomBarAppearOnScroll = () => values.bottomBarAppearOnScroll;

  const setBottomBarAppearOnScroll = value => {
    setValues(prev => ({ ...prev, bottomBarAppearOnScroll: value }));
  };

  const onPageScroll = event => historyOnPageScroll.current.forEach(func => func(event));

  const subscribeOnPageScroll = value => historyOnPageScroll.current.push(value);

  const unsubscribeOnPageScroll = () => historyOnPageScroll.current.pop();

  const navBarList = () => values.navBarList;

  const setNavBarList = value => {
    historyNavBarList.current.push(navBarList());
    setValues(prev => ({ ...prev, navBarList: value }));
  };

  const restoreNavBarList = () => {
    const previousState = historyNavBarList.current.pop();
    setValues(prev => ({ ...prev, navBarList: previousState }));
  };

  const loginModalProps = () => values.loginModalProps;

  const openLoginModal = (loginModalProps) => {
    setValues(prev => ({ ...prev, loginModalProps: { isOpen: true, ...loginModalProps } }));
  };

  const closeLoginModal = () => {
    setValues(prev => ({ ...prev, loginModalProps: { isOpen: false } }));
  };

  const publicMethods = {
    navBar,
    setNavBar,
    restoreNavBar,
    footerVisible,
    setFooterVisible,
    restoreFooterVisible,
    onPageScroll,
    bottomBarAppearOnScroll,
    setBottomBarAppearOnScroll,
    subscribeOnPageScroll,
    unsubscribeOnPageScroll,
    navBarList,
    setNavBarList,
    restoreNavBarList,
    loginModalProps,
    openLoginModal,
  };

  const CurrentNavBar = navBars[navBar()];

  return (
    <NavBarContext.Provider value={publicMethods}>
      <CurrentNavBar
        onPageScroll={onPageScroll}
        bottomBarAppearOnScroll={bottomBarAppearOnScroll()}
        navBarList={navBarList()}
        loginModalProps={loginModalProps()}
        closeLoginModal={closeLoginModal}
      >
        {children}
      </CurrentNavBar>
    </NavBarContext.Provider>
  );
};

export { NavBarProvider };

export default NavBarContext;