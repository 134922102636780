
import {useState} from "react";
import Loader from "../../../_ui/_v2/components/Loader";
import Button from "../../../_ui/_v2/components/Button";


const ChatGPTButton = ({id, onChange}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();

  const callChatGPTApi = () => {
    setIsLoading(true);
    setError(undefined);
    fetch(
      // `https://981e-2a01-cb19-9910-f100-74cf-7fef-81ab-9064.ngrok-free.app/api/v1/internal/tools/search?query=${encodeURI(query)}`,
      // `https://a356-2a01-cb19-9910-f100-f7b9-e615-b87c-4dca.ngrok-free.app/api/v1/internal/tools/seed_touristic_site?id=${id}`,
      // `https://db46-2a01-cb19-8580-d000-5dc0-cc8-8aa2-f3bf.ngrok-free.app/api/v1/internal/tools/seed_touristic_site?id=${id}`,
      `https://631c-2a01-cb19-8580-d000-a519-1465-b4b3-9522.ngrok-free.app/api/v1/internal/tools/seed_touristic_site?id=${id}`,
      {
        method: 'PUT',
        headers: {
          "ngrok-skip-browser-warning": 'yes'
        }
      }
    )
      .then((response) => response.json())
      .then((data) => {
        onChange?.(data);
      })
      .catch(response => setError(JSON.stringify(response || "error")))
      .finally(() => setIsLoading(false));
  }


  return (
    <>
      <Button
        disabled={isLoading}
        rounded
        onClick={() => callChatGPTApi()}
      >
        {
          isLoading
            ? <Loader color={'white'}/>
            : 'ChatGPT'
        }
      </Button>
      {error && <p>error</p>}
    </>
  );
};

export default ChatGPTButton;