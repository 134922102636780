import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import LazyLoad, { forceVisible } from "react-lazyload";
import axios from 'axios';

const ImgLazy = ({ small, src, errorImg, offset = 100, throttle = 0, overflow = false, visible = false, ...props }) => {
  const [imgSrc, setSrc] = useState(src || '')

  const onLoad = useCallback(() => {
    setSrc(src)
  }, [src])

  const onError = useCallback(() => {
    setSrc(errorImg)
  }, [errorImg])

  const onErrorFallBack = useCallback(() => {
    setSrc(errorImg)
    try {
      const url = new URL(src)
      let baseUrl = null
      if (url.href.includes('touristic-site-henri-1-paris')) {
        baseUrl = 'https://resizeimageebwpwvup-resizeimage.functions.fnc.fr-par.scw.cloud/'
      }
      if (url.href.includes('roadtrip-henri-1-paris')) {
        baseUrl = 'https://resizeimageebwpwvup-resizeimageroadtrips.functions.fnc.fr-par.scw.cloud/'
      }
      if (baseUrl) {
        const path = url.pathname.slice(1).split('/')
        path.splice(path.length - 2, 1)
        const newPath = path.join('/')
        const newUrl = baseUrl + newPath
        axios.get(newUrl)
          .then(response => {
            if (response.status === 200) {
              setSrc(src)
            }
          }).catch(function (error) {
            if (error.response) {
              console.log(error.response);
            }
          })
      }
    } catch (_) {
    }
  }, [src, errorImg])

  useEffect(() => {
    if (visible) {
      forceVisible()
    }
  }, [visible])

  useEffect(() => {
    const img = new Image();
    img.src = src;
    img.addEventListener("load", onLoad);
    img.addEventListener("error", onError);
    return () => {
      img.removeEventListener("load", onLoad)
      img.removeEventListener("error", onError)
    }
  }, [src, onError, onLoad])
  return (
    <LazyLoad throttle={throttle} offset={offset} overflow={overflow}>
      <img
        {...props}
        width="100%"
        height="100%"
        src={imgSrc}
        alt={imgSrc}
        onError={small ? onErrorFallBack : onError}
      />
    </LazyLoad>
  );
}

ImgLazy.propTypes = {
  src: PropTypes.string.isRequired,
  errorImg: PropTypes.string
}

ImgLazy.defaultProps = {
  src: "",
  errorImg: "https://general-henri-1-paris.s3.fr-par.scw.cloud/woman.jpg"
}

export default ImgLazy;