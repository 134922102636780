import {API_DIRECT_UPLOAD_TEXT, API_PATCH_TOURISTIC_SITE, API_UPDATE_TEXTS} from "../../../../utils/api";
import {useFormik} from "formik";
import {
  textSchema,
  titleSchema,
  titleTextSchema,
} from "../../../_schemas/media_layout_builder_schemas";
import ButtonFormValidation from "../ButtonFormValidation";
import {useState} from "react";
import styled from "styled-components";
import {Label} from "../../../../_ui/_v2/components/FormElements";
import {useContext} from "react";
import {ThemeContext} from "styled-components";
import {useTranslation} from "react-i18next";

const InputStyled = styled.input`
  background-color: rgba(0, 0, 0, .8);
  border: 1px solid rgba(169, 169, 169, 0.6);
  color: white;
  padding: .7em 1em .5em .5em;
  resize: none;
  border-radius: .5em;
  width: 100%;
  ::placeholder{
    color: white;
  }
`;

const TextAreaStyled = styled.textarea`
  background-color: rgba(0, 0, 0, .8);
  border: 1px solid rgba(169, 169, 169, 0.6);
  color: white;
  padding: .7em 1em .5em .5em;
  resize: none;
  border-radius: .5em;
  width: 100%;
  ::placeholder{
    color: white;
  }
`;

const FormStyled = styled.form `
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 70%;
  gap: 15px;
`;

const LabelStyled = styled(Label)`
  text-align: left;
  font-size: 1em;
`;

const FormDisplayStyled = styled.div `
  display: flex;
  flex-direction: column;
`;

const ButtonDelete = styled.button`
  display: inline-block;
  box-sizing: border-box;
  padding: 0.5rem 2rem;
  font-weight: 600;
  border: none;
  background-color: ${props => props.theme.colors.danger};
  color: ${props => props.theme.colors.white};
  border-radius: 0.2rem;
  vertical-align: middle;
`;

const TitleDescription = ({valuesProvided = {}, setOpenForm, onLayoutItemChange, onDelete, canDelete = false}) => {
  const theme = useContext(ThemeContext);
  const [loading, setLoading] = useState(false);

  const callBack = (payload) => {
    const builderSchema = {
      type: 'TitleText',
      items: payload
    };
    onLayoutItemChange(builderSchema)
  }

  const handleSubmitForm = (values) => {
    const token = localStorage.getItem("Authorization");
    const arrayToPush = []
    const title = values.items.filter(item => item.type === 'Title')[0]
    const text = values.items.filter(item => item.type === 'Text')[0]
    arrayToPush.push(title.content, text.content)
    setLoading(true);
    fetch(
      title.content?.id ? API_UPDATE_TEXTS : API_DIRECT_UPLOAD_TEXT,
      {
        method: title.content?.id ? 'PUT' :'POST',
        cache: 'default',
        body: JSON.stringify({texts: arrayToPush}),
        headers: {
          "Authorization": `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        mode: 'cors',
      }
    )
      .then(res => res.json())
      .then(response => {
        const textToAddAtLayout = [{type: 'Title', content: {id : response[0].id}},{type: 'Text', content: {id: response[1].id}}]
        callBack(textToAddAtLayout);
        resetForm();
        setLoading(false);
      })
      .catch(error => {
        setLoading(false);
        console.error(error);
      });
  };
  const {
    handleSubmit,
    handleBlur,
    values,
    setFieldValue,
    resetForm,
  } = useFormik({
    initialValues: titleTextSchema.cast(valuesProvided),
    enableReinitialize: true,
    validationSchema: titleTextSchema,
    onSubmit: handleSubmitForm,
  });
  const {i18n} = useTranslation();
  const textKey = `text_${i18n.language}`;
  return(
    <FormStyled onSubmit={handleSubmit}>
      <FormDisplayStyled>
        <LabelStyled>Titre</LabelStyled>
        <InputStyled
          type='text'
          name={`items[0].content.${textKey}`}
          onChange={(event) => {
            Object.keys(valuesProvided).length > 0 ?
              setFieldValue(`items[0].content[${textKey}]`,
                event.target.value
              )
              :
              setFieldValue(
                `items[0]`,
                titleSchema.cast({content: {[textKey]: event.target.value}})
              );
          }}
          onBlur={handleBlur}
          value={values?.items?.[0]?.content?.[textKey] || ''}
          placeholder='Le titre'
          style={{flexBasis: '20%'}}
        />
      </FormDisplayStyled>
      <FormDisplayStyled>
        <LabelStyled>Description</LabelStyled>
        <TextAreaStyled
          type='text'
          name={`items[1].content.${textKey}`}
          onChange={(event) => {
            Object.keys(valuesProvided).length > 0 ?
              setFieldValue(`items[1].content[${textKey}]`,
                event.target.value
              )
              :
              setFieldValue(
                `items[1]`,
                textSchema.cast({content: {[textKey]: event.target.value}})
              );
          }}
          onBlur={handleBlur}
          value={values?.items?.[1]?.content?.[textKey] || ''}
          placeholder='La description'
          style={{flexBasis: '80%'}}
        />
      </FormDisplayStyled>
      
      <div
        style={{
          display: 'flex',
          gap: '20px',
          justifyContent: 'center'
        }}
        >
          <ButtonFormValidation loading={loading} setOpenForm={setOpenForm} onClick={() => resetForm()}/>
          {canDelete ? <ButtonDelete onClick={onDelete} type="button" theme={theme}>Supprimer</ButtonDelete> : null}
      </div>
    </FormStyled>
  );
};

export default TitleDescription;