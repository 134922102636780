import {useContext, useState} from 'react';
import {useFormik} from 'formik';
import {API_PATCH_TOURISTIC_SITE} from '../../../utils/api';
import touristicSiteSchema, {priceSchema} from '../../_schemas/touristic_site_schema';
import {ThemeContext} from 'styled-components';
import Button from '../../../_ui/_v2/components/Button';
import Loader from '../../../_ui/_v2/components/Loader';
import {TextInput, Form, InputErrorWrapper} from '../../../_ui/_v2/components/FormElements';
import PlusButton from './_commons/PlusButton';
import PlusIcon from './_commons/PlusIcon';
import RemoveIcon from './_commons/RemoveIcon';
import TouristicSiteContext from '../../../_contexts/TouristicSiteContext';
import {useTranslation} from "react-i18next";

const touristicSiteSchemaForTouristicSitePricingForm = touristicSiteSchema.pick([
  'price_list',
]);
const TouristicSitePricingForm = ({onSuccess, touristicSite = {}}) => {
  const theme = useContext(ThemeContext);
  const [loading, setLoading] = useState(false);
  const { setTouristicSite } = useContext(TouristicSiteContext);
  const {i18n} = useTranslation(["touristic-site"]);

  const handleSubmitForm = (values, actions) => {
    const token = localStorage.getItem("Authorization");

    const payload = {...values};
    payload.price_list = JSON.stringify(payload.price_list);

    setLoading(true);
    fetch(
      API_PATCH_TOURISTIC_SITE(touristicSite.id),
      {
        method: 'PATCH',
        cache: 'default',
        body: JSON.stringify(payload),
        headers: {
          "Authorization": `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        mode: 'cors',
      }
    )
      .then(res => res.json())
      .then(response => {
        setTouristicSite(response);
        // setLoading(false);
        onSuccess?.(response);
      })
      .catch(error => {
        console.error(error);
        setLoading(false);
      })
  };

  const {
    handleSubmit,
    handleChange,
    handleBlur,
    values,
    errors,
    touched,
    setFieldValue,
    setFieldError,
    setFieldTouched,
  } = useFormik({
    initialValues: touristicSiteSchemaForTouristicSitePricingForm.cast(touristicSite),
    validationSchema: touristicSiteSchemaForTouristicSitePricingForm,
    onSubmit: handleSubmitForm,
  });

  const addPrice = () => {
    setFieldValue(
      'price_list',
      values.price_list.concat([priceSchema.cast({})]),
    );
  };

  const removePrice = (index) => {
    setFieldValue(
      'price_list',
      values.price_list.filter((_, tempIndex) => tempIndex !== index),
    );
    errors.price_list && setFieldError(
      'price_list',
      errors.price_list.filter((_, tempIndex) => tempIndex !== index),
    );
    touched.price_list && setFieldTouched(
      'price_list',
      touched.price_list.filter((_, tempIndex) => tempIndex !== index),
    );
  };

  return (
    <Form onSubmit={handleSubmit}>
      {
        values.price_list.map((_, index) => (
          <InputErrorWrapper
            key={index}
            hasError={(errors.price_list?.[index]?.text && touched.price_list?.[index]?.text) || (errors.price_list?.[index]?.price && touched.price_list?.[index]?.price)}
            errorMessage={errors.price_list?.[index]?.text || errors.price_list?.[index]?.price}
            style={{
              display: 'grid',
              gridTemplateColumns: 'auto 80px 20px',
              gap: '1em',
            }}
          >
            <TextInput
              type="text"
              name={`price_list[${index}][text_${i18n.language}]`}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.price_list?.[index]?.[`text_${i18n.language}`] || ''}
              hasError={errors.price_list?.[index]?.[`text_${i18n.language}`] && touched.price_list?.[index]?.[`text_${i18n.language}`]}
              placeholder={'Nom du billet'}
            />
            <TextInput
              type="number"
              name={`price_list[${index}][price]`}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.price_list?.[index]?.price || ''}
              hasError={errors.price_list?.[index]?.price && touched.price_list?.[index]?.price}
              placeholder={'Prix'}
              step="0.01"
            />
            <RemoveIcon
              onClick={() => removePrice(index)}
              style={{
                margin: 'auto',
              }}
            />
          </InputErrorWrapper>
        ))
      }
      <PlusButton
        type="button"
        onClick={addPrice}
      >
        <PlusIcon/>
      </PlusButton>
      <Button
        rounded
        disabled={loading}
        type="submit"
        style={{
          display: 'block',
          margin: '3em auto 0 auto',
        }}
      >
        {
          loading
            ? <Loader size="1.5em" color={theme.colors.white}/>
            : 'Enregistrer'
        }
      </Button>
    </Form>
  );
};

export default TouristicSitePricingForm;