import { Button } from 'components/form/button';
import Title from 'components/header/title/title';
import { RoadTripCard } from 'components/roadTrips/roadTripCard';
import React, { useEffect, useRef, useState } from "react";
import { Dropdown, Loader } from "semantic-ui-react";
import useDocumentTitle from 'hooks/use-document-title';
import AutocompletePlaceAddress from "../../components/search/autocompletesearchaddressonly";
import {Search as SearchIcon} from "@material-ui/icons";
import { HeaderButton } from "../../components/header/buttons";
import CreateRoadTrip from "../../containers/User/Roadtrips/CreateRoadTrip/CreateRoadTrip";
import {useHistory} from "react-router-dom";
import CopyRoadTrip from "../../containers/User/Roadtrips/CopyRoadTrip/CopyRoadTrip";
import Pagination from "../../components/pagination/pagination";
import { ThemeProvider } from "styled-components";
import "./roadtrips-page.scss";
import { useTranslation } from "react-i18next";
import {linkAdder, metaAdder} from "../../utils/utils";
import TripCard from "../../components/_v2/trip/TripCard";
import theme from "../../_ui/_v2/styles/theme";

const RoadTripsPage = ({user, location, loading, numberPages, roadtrips, handleQueryParams, sortQuery}) => {

  const {t} = useTranslation(["henriTrip"])

  useDocumentTitle("Road Trips • Henri Trip");
  useEffect(() => {
    metaAdder('name="description"', "Découvrez l'ensemble des road trips partout en France, créez des itinéraires de voyages et partagez les avec la communauté d'Henri Trip.")
    metaAdder('name="author"', "HenriTrip")
    metaAdder('name="keywords"', "touriste, comparer, choses à faire, activités touristiques, tours, attractions, billets, trip, road, roadtrip, informations, henri trip")
    linkAdder(document.location.href)
  }, []);

  const dayOptions = [
    {
      key: "0",
      text: t("roadTripsPage.filter.byDay.title"),
      value: "[1,20]",
      content: t("roadTripsPage.filter.byDay.all")
    },
    {
      key: "1",
      text: "1 jour",
      value: "[1,1]",
      content: "1"
    },
    {
      key: "2",
      text: "2 jours",
      value: "[2,2]",
      content: "2"
    },
    {
      key: "3",
      text: "3 jours",
      value: "[3,3]",
      content: "3"
    },
    {
      key: "4+",
      text: "4+ jours",
      value: "[4,20]",
      content: "4+"
    },

  ];

  const optionsSort = [
    {
      key: "Plus récents",
      text: t("roadTripsPage.filter.bySeniority.latestSelected"),
      value: "Plus récents",
      content: t("roadTripsPage.filter.bySeniority.latest")
    },
    {
      key: "Plus anciens",
      text: t("roadTripsPage.filter.bySeniority.olderSelected"),
      value: "Plus anciens",
      content: t("roadTripsPage.filter.bySeniority.older")
    },
    {
      key: "Mieux noté",
      text: t("roadTripsPage.filter.bySeniority.topRatedSelected"),
      value: "Mieux noté",
      content: t("roadTripsPage.filter.bySeniority.topRated")
    },
    {
      key: "Moins noté",
      text: t("roadTripsPage.filter.bySeniority.leastRatedSelected"),
      value: "Moins noté",
      content: t("roadTripsPage.filter.bySeniority.leastRated")
    }];

  const typeFilter = [
    {
      key: "free",
      text: t("roadTripsPage.filter.byPrice.free"),
      value: "free",
      content: t("roadTripsPage.filter.byPrice.free")
    },
    {
      key: "premium",
      text: t("roadTripsPage.filter.byPrice.premium"),
      value: "premium",
      content: t("roadTripsPage.filter.byPrice.premium"),
    }
  ]

  const countryOptions = [
    { name: 'Afghanistan', countryCode: 'af' },
    { name: 'Aland Islands', countryCode: 'ax' },
    { name: 'Albania', countryCode: 'al' },
    { name: 'Algeria', countryCode: 'dz' },
    { name: 'American Samoa', countryCode: 'as' },
    { name: 'Andorra', countryCode: 'ad' },
    { name: 'Angola', countryCode: 'ao' },
    { name: 'Anguilla', countryCode: 'ai' },
    { name: 'Antigua', countryCode: 'ag' },
    { name: 'Argentina', countryCode: 'ar' },
    { name: 'Armenia', countryCode: 'am' },
    { name: 'Aruba', countryCode: 'aw' },
    { name: 'Australia', countryCode: 'au' },
    { name: 'Austria', countryCode: 'at' },
    { name: 'Azerbaijan', countryCode: 'az' },
    { name: 'Bahamas', countryCode: 'bs' },
    { name: 'Bahrain', countryCode: 'bh' },
    { name: 'Bangladesh', countryCode: 'bd' },
    { name: 'Barbados', countryCode: 'bb' },
    { name: 'Belarus', countryCode: 'by' },
    { name: 'Belgium', countryCode: 'be' },
    { name: 'Belize', countryCode: 'bz' },
    { name: 'Benin', countryCode: 'bj' },
    { name: 'Bermuda', countryCode: 'bm' },
    { name: 'Bhutan', countryCode: 'bt' },
    { name: 'Bolivia', countryCode: 'bo' },
    { name: 'Bosnia', countryCode: 'ba' },
    { name: 'Botswana', countryCode: 'bw' },
    { name: 'Bouvet Island', countryCode: 'bv' },
    { name: 'Brazil', countryCode: 'br' },
    { name: 'British Virgin Islands', countryCode: 'vg' },
    { name: 'Brunei', countryCode: 'bn' },
    { name: 'Bulgaria', countryCode: 'bg' },
    { name: 'Burkina Faso', countryCode: 'bf' },
    { name: 'Burma', countryCode: 'mm', alias: 'Myanmar' },
    { name: 'Burundi', countryCode: 'bi' },
    { name: 'Caicos Islands', countryCode: 'tc' },
    { name: 'Cambodia', countryCode: 'kh' },
    { name: 'Cameroon', countryCode: 'cm' },
    { name: 'Canada', countryCode: 'ca' },
    { name: 'Cape Verde', countryCode: 'cv' },
    { name: 'Cayman Islands', countryCode: 'ky' },
    { name: 'Central African Republic', countryCode: 'cf' },
    { name: 'Chad', countryCode: 'td' },
    { name: 'Chile', countryCode: 'cl' },
    { name: 'China', countryCode: 'cn' },
    { name: 'Christmas Island', countryCode: 'cx' },
    { name: 'Cocos Islands', countryCode: 'cc' },
    { name: 'Colombia', countryCode: 'co' },
    { name: 'Comoros', countryCode: 'km' },
    { name: 'Congo', countryCode: 'cd' },
    { name: 'Congo Brazzaville', countryCode: 'cg' },
    { name: 'Cook Islands', countryCode: 'ck' },
    { name: 'Costa Rica', countryCode: 'cr' },
    { name: 'Cote Divoire', countryCode: 'ci' },
    { name: 'Croatia', countryCode: 'hr' },
    { name: 'Cuba', countryCode: 'cu' },
    { name: 'Cyprus', countryCode: 'cy' },
    { name: 'Czech Republic', countryCode: 'cz' },
    { name: 'Denmark', countryCode: 'dk' },
    { name: 'Djibouti', countryCode: 'dj' },
    { name: 'Dominica', countryCode: 'dm' },
    { name: 'Dominican Republic', countryCode: 'do' },
    { name: 'Ecuador', countryCode: 'ec' },
    { name: 'Egypt', countryCode: 'eg' },
    { name: 'El Salvador', countryCode: 'sv' },
    { name: 'England', countryCode: 'gb eng' },
    { name: 'Equatorial Guinea', countryCode: 'gq' },
    { name: 'Eritrea', countryCode: 'er' },
    { name: 'Estonia', countryCode: 'ee' },
    { name: 'Ethiopia', countryCode: 'et' },
    { name: 'Europeanunion', countryCode: 'eu' },
    { name: 'Falkland Islands', countryCode: 'fk' },
    { name: 'Faroe Islands', countryCode: 'fo' },
    { name: 'Fiji', countryCode: 'fj' },
    { name: 'Finland', countryCode: 'fi' },
    { name: 'France', countryCode: 'fr' },
    { name: 'French Guiana', countryCode: 'gf' },
    { name: 'French Polynesia', countryCode: 'pf' },
    { name: 'French Territories', countryCode: 'tf' },
    { name: 'Gabon', countryCode: 'ga' },
    { name: 'Gambia', countryCode: 'gm' },
    { name: 'Georgia', countryCode: 'ge' },
    { name: 'Germany', countryCode: 'de' },
    { name: 'Ghana', countryCode: 'gh' },
    { name: 'Gibraltar', countryCode: 'gi' },
    { name: 'Greece', countryCode: 'gr' },
    { name: 'Greenland', countryCode: 'gl' },
    { name: 'Grenada', countryCode: 'gd' },
    { name: 'Guadeloupe', countryCode: 'gp' },
    { name: 'Guam', countryCode: 'gu' },
    { name: 'Guatemala', countryCode: 'gt' },
    { name: 'Guinea', countryCode: 'gn' },
    { name: 'Guinea-Bissau', countryCode: 'gw' },
    { name: 'Guyana', countryCode: 'gy' },
    { name: 'Haiti', countryCode: 'ht' },
    { name: 'Heard Island', countryCode: 'hm' },
    { name: 'Honduras', countryCode: 'hn' },
    { name: 'Hong Kong', countryCode: 'hk' },
    { name: 'Hungary', countryCode: 'hu' },
    { name: 'Iceland', countryCode: 'is' },
    { name: 'India', countryCode: 'in' },
    { name: 'Indian Ocean Territory', countryCode: 'io' },
    { name: 'Indonesia', countryCode: 'id' },
    { name: 'Iran', countryCode: 'ir' },
    { name: 'Iraq', countryCode: 'iq' },
    { name: 'Ireland', countryCode: 'ie' },
    { name: 'Israel', countryCode: 'il' },
    { name: 'Italy', countryCode: 'it' },
    { name: 'Jamaica', countryCode: 'jm' },
    { name: 'Jan Mayen', countryCode: 'sj', alias: 'Svalbard' },
    { name: 'Japan', countryCode: 'jp' },
    { name: 'Jordan', countryCode: 'jo' },
    { name: 'Kazakhstan', countryCode: 'kz' },
    { name: 'Kenya', countryCode: 'ke' },
    { name: 'Kiribati', countryCode: 'ki' },
    { name: 'Kuwait', countryCode: 'kw' },
    { name: 'Kyrgyzstan', countryCode: 'kg' },
    { name: 'Laos', countryCode: 'la' },
    { name: 'Latvia', countryCode: 'lv' },
    { name: 'Lebanon', countryCode: 'lb' },
    { name: 'Lesotho', countryCode: 'ls' },
    { name: 'Liberia', countryCode: 'lr' },
    { name: 'Libya', countryCode: 'ly' },
    { name: 'Liechtenstein', countryCode: 'li' },
    { name: 'Lithuania', countryCode: 'lt' },
    { name: 'Luxembourg', countryCode: 'lu' },
    { name: 'Macau', countryCode: 'mo' },
    { name: 'Macedonia', countryCode: 'mk' },
    { name: 'Madagascar', countryCode: 'mg' },
    { name: 'Malawi', countryCode: 'mw' },
    { name: 'Malaysia', countryCode: 'my' },
    { name: 'Maldives', countryCode: 'mv' },
    { name: 'Mali', countryCode: 'ml' },
    { name: 'Malta', countryCode: 'mt' },
    { name: 'Marshall Islands', countryCode: 'mh' },
    { name: 'Martinique', countryCode: 'mq' },
    { name: 'Mauritania', countryCode: 'mr' },
    { name: 'Mauritius', countryCode: 'mu' },
    { name: 'Mayotte', countryCode: 'yt' },
    { name: 'Mexico', countryCode: 'mx' },
    { name: 'Micronesia', countryCode: 'fm' },
    { name: 'Moldova', countryCode: 'md' },
    { name: 'Monaco', countryCode: 'mc' },
    { name: 'Mongolia', countryCode: 'mn' },
    { name: 'Montenegro', countryCode: 'me' },
    { name: 'Montserrat', countryCode: 'ms' },
    { name: 'Morocco', countryCode: 'ma' },
    { name: 'Mozambique', countryCode: 'mz' },
    { name: 'Namibia', countryCode: 'na' },
    { name: 'Nauru', countryCode: 'nr' },
    { name: 'Nepal', countryCode: 'np' },
    { name: 'Netherlands', countryCode: 'nl' },
    { name: 'Netherlandsantilles', countryCode: 'an' },
    { name: 'New Caledonia', countryCode: 'nc' },
    { name: 'New Guinea', countryCode: 'pg' },
    { name: 'New Zealand', countryCode: 'nz' },
    { name: 'Nicaragua', countryCode: 'ni' },
    { name: 'Niger', countryCode: 'ne' },
    { name: 'Nigeria', countryCode: 'ng' },
    { name: 'Niue', countryCode: 'nu' },
    { name: 'Norfolk Island', countryCode: 'nf' },
    { name: 'North Korea', countryCode: 'kp' },
    { name: 'Northern Mariana Islands', countryCode: 'mp' },
    { name: 'Norway', countryCode: 'no' },
    { name: 'Oman', countryCode: 'om' },
    { name: 'Pakistan', countryCode: 'pk' },
    { name: 'Palau', countryCode: 'pw' },
    { name: 'Palestine', countryCode: 'ps' },
    { name: 'Panama', countryCode: 'pa' },
    { name: 'Paraguay', countryCode: 'py' },
    { name: 'Peru', countryCode: 'pe' },
    { name: 'Philippines', countryCode: 'ph' },
    { name: 'Pitcairn Islands', countryCode: 'pn' },
    { name: 'Poland', countryCode: 'pl' },
    { name: 'Portugal', countryCode: 'pt' },
    { name: 'Puerto Rico', countryCode: 'pr' },
    { name: 'Qatar', countryCode: 'qa' },
    { name: 'Reunion', countryCode: 're' },
    { name: 'Romania', countryCode: 'ro' },
    { name: 'Russia', countryCode: 'ru' },
    { name: 'Rwanda', countryCode: 'rw' },
    { name: 'Saint Helena', countryCode: 'sh' },
    { name: 'Saint Kitts and Nevis', countryCode: 'kn' },
    { name: 'Saint Lucia', countryCode: 'lc' },
    { name: 'Saint Pierre', countryCode: 'pm' },
    { name: 'Saint Vincent', countryCode: 'vc' },
    { name: 'Samoa', countryCode: 'ws' },
    { name: 'San Marino', countryCode: 'sm' },
    { name: 'Sandwich Islands', countryCode: 'gs' },
    { name: 'Sao Tome', countryCode: 'st' },
    { name: 'Saudi Arabia', countryCode: 'sa' },
    { name: 'Scotland', countryCode: 'gb sct' },
    { name: 'Senegal', countryCode: 'sn' },
    { name: 'Serbia', countryCode: 'cs' },
    { name: 'Serbia', countryCode: 'rs' },
    { name: 'Seychelles', countryCode: 'sc' },
    { name: 'Sierra Leone', countryCode: 'sl' },
    { name: 'Singapore', countryCode: 'sg' },
    { name: 'Slovakia', countryCode: 'sk' },
    { name: 'Slovenia', countryCode: 'si' },
    { name: 'Solomon Islands', countryCode: 'sb' },
    { name: 'Somalia', countryCode: 'so' },
    { name: 'South Africa', countryCode: 'za' },
    { name: 'South Korea', countryCode: 'kr' },
    { name: 'Spain', countryCode: 'es' },
    { name: 'Sri Lanka', countryCode: 'lk' },
    { name: 'Sudan', countryCode: 'sd' },
    { name: 'Suriname', countryCode: 'sr' },
    { name: 'Swaziland', countryCode: 'sz' },
    { name: 'Sweden', countryCode: 'se' },
    { name: 'Switzerland', countryCode: 'ch' },
    { name: 'Syria', countryCode: 'sy' },
    { name: 'Taiwan', countryCode: 'tw' },
    { name: 'Tajikistan', countryCode: 'tj' },
    { name: 'Tanzania', countryCode: 'tz' },
    { name: 'Thailand', countryCode: 'th' },
    { name: 'Timorleste', countryCode: 'tl' },
    { name: 'Togo', countryCode: 'tg' },
    { name: 'Tokelau', countryCode: 'tk' },
    { name: 'Tonga', countryCode: 'to' },
    { name: 'Trinidad', countryCode: 'tt' },
    { name: 'Tunisia', countryCode: 'tn' },
    { name: 'Turkey', countryCode: 'tr' },
    { name: 'Turkmenistan', countryCode: 'tm' },
    { name: 'Tuvalu', countryCode: 'tv' },
    { name: 'U.A.E.', countryCode: 'ae', alias: 'United Arab Emirates' },
    { name: 'Uganda', countryCode: 'ug' },
    { name: 'Ukraine', countryCode: 'ua' },
    { name: 'United Kingdom', countryCode: 'gb', alias: 'uk' },
    { name: 'United States', countryCode: 'us', alias: 'America' },
    { name: 'Uruguay', countryCode: 'uy' },
    { name: 'US Minor Islands', countryCode: 'um' },
    { name: 'US Virgin Islands', countryCode: 'vi' },
    { name: 'Uzbekistan', countryCode: 'uz' },
    { name: 'Vanuatu', countryCode: 'vu' },
    { name: 'Vatican City', countryCode: 'va' },
    { name: 'Venezuela', countryCode: 've' },
    { name: 'Vietnam', countryCode: 'vn' },
    { name: 'Wales', countryCode: 'gb wls' },
    { name: 'Wallis and Futuna', countryCode: 'wf' },
    { name: 'Western Sahara', countryCode: 'eh' },
    { name: 'Yemen', countryCode: 'ye' },
    { name: 'Zambia', countryCode: 'zm' },
    { name: 'Zimbabwe', countryCode: 'zw' },
  ]

  const categorieFilter = [
    {key:'Activité', text:t('general.category.activity'), value:53},
    {key:'Site Religieux', text:t('general.category.religiousSite'), value:54},
    {key:'Parc / Jardin', text:t('general.category.parkGarden'), value: 55},
    {key:'Oenotourisme / Spiritourisme', text:t('general.category.wineTourisme'), value:56},
    {key:'Chateaux / Monument historique', text:t('general.category.historicalMonument'), value:57},
    {key:'Grotte / Site préhistorique', text:t('general.category.cavePrehistoric'), value:58},
    {key:'Musée / Exposition', text:t('general.category.museum'), value:59},
    {key:'Bien-être', text:t('general.category.wellness'), value:60},
    {key:'Parc d\'attraction / Parc animalier', text:t('general.category.park'), value:61},
    {key:'Le choix d\'Henri', text:t('general.category.henriChoice'), value:62},
  ]

  const categorieTransportFilter = [
    {key:'Voiture', text:t("general.category.transport.car"), value:1, image: { avatar: true, src: 'https://general-henri-1-paris.s3.fr-par.scw.cloud/categories/voiture.png'}},
    {key:'A pied', text:t("general.category.transport.onFoot"), value:2, image: { avatar: true, src: 'https://general-henri-1-paris.s3.fr-par.scw.cloud/categories/walking-man.png'}},
    {key:'Van', text:"Van", value: 3, image: { avatar: true, src: 'https://general-henri-1-paris.s3.fr-par.scw.cloud/categories/van.png'}},
    {key:'Camping car', text:t("general.category.transport.campingCar"), value:4, image: { avatar: true, src: 'https://general-henri-1-paris.s3.fr-par.scw.cloud/categories/camping-car.png'}},
    {key:'Vélo', text:t("general.category.transport.bike"), value:5, image: { avatar: true, src: 'https://general-henri-1-paris.s3.fr-par.scw.cloud/categories/bike.png'}},
    {key:'Voiture de collection', text:t("general.category.transport.collectionCar"), value:6, image: { avatar: true, src: 'https://general-henri-1-paris.s3.fr-par.scw.cloud/categories/collection-car.png'}},
    {key:'Moto', text:t("general.category.transport.motorBike"), value:7, image: { avatar: true, src: 'https://general-henri-1-paris.s3.fr-par.scw.cloud/categories/moto.png'}},
  ]

  const categorieWhithWhomFilter = [
    {key:'Famille', text:t("general.category.withWhom.family"), value:8, image: { avatar: true, src: 'https://general-henri-1-paris.s3.fr-par.scw.cloud/categories/family.png' }},
    {key:'Amis', text:t("general.category.withWhom.friends"), value:9, image: { avatar: true, src: 'https://general-henri-1-paris.s3.fr-par.scw.cloud/categories/amis.png'}},
    {key:'Couple', text:t("general.category.withWhom.couple"), value: 10, image: { avatar: true, src: 'https://general-henri-1-paris.s3.fr-par.scw.cloud/categories/couple.png'}},
    {key:'Groupe', text:t("general.category.withWhom.group"), value:11, image: { avatar: true, src: 'https://general-henri-1-paris.s3.fr-par.scw.cloud/categories/groupe.png'}},
    {key:'Seul', text:t("general.category.withWhom.alone"), value:12, image: { avatar: true, src: 'https://general-henri-1-paris.s3.fr-par.scw.cloud/categories/walking-man.png'}},
    {key:'Etudiant', text:t("general.category.withWhom.student"), value:13, image: { avatar: true, src: 'https://general-henri-1-paris.s3.fr-par.scw.cloud/categories/student.png'}},
  ]

  const categorieSeasonFilter = [
    {key:'Éte', text:t("general.category.season.summer"), value:14, image: { avatar: true, src: 'https://general-henri-1-paris.s3.fr-par.scw.cloud/categories/sun.png'}},
    {key:'Automne', text:t("general.category.season.autumn"), value:15, image: { avatar: true, src: 'https://general-henri-1-paris.s3.fr-par.scw.cloud/categories/feuille.png'}},
    {key:'Printemps', text:t("general.category.season.spring"), value: 16, image: { avatar: true, src: 'https://general-henri-1-paris.s3.fr-par.scw.cloud/categories/flower.png'}},
    {key:'Hiver', text:t("general.category.season.winter"), value:17, image: { avatar: true, src: 'https://general-henri-1-paris.s3.fr-par.scw.cloud/categories/hiver.png'}},
  ]

  const history = useHistory();
  const [query, setQuery] = useState({
    sort_type : sortQuery.sort_type || "updated_at",
    sort_direction : sortQuery.sort_direction || "DESC",
    number_days : sortQuery.number_days,
    latitude : sortQuery.latitude,
    longitude : sortQuery.longitude,
    distance : sortQuery.distance,
    title: sortQuery.title,
    current_page: sortQuery.current_page || 1,
    category: sortQuery.category ? sortQuery.category.split(',').map(Number) : undefined,
    category_roadtrip: sortQuery.category_roadtrip ? sortQuery.category_roadtrip.split(',').map(Number) : undefined,
    roadtrip_type: sortQuery.roadtrip_type
  });
  const [state, setState] = useState({
    buttonAddressDisabled: null,
    locationIsLoading: false
  })
  const [input, setInput] = useState(sortQuery.title || '')
  const [addressFocus, setAddressFocus] = useState(false)

  const [modal, setModal] = useState({
    create: false,
    copy: false,
    id: null
  })

  const addressRef = useRef();
  const titleRef = useRef();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [sortQuery])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [modal.create])

  useEffect(() => {
    handleQueryParams(query);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query])

  const handleFilters = (n, v) => {
    setQuery(prev =>{
      return{
        ...prev,
        category: v
      }
    })
  }

  const handleFiltersCategoryRoadTrip = (n,v) => {
    setQuery(prev =>{
      return{
        ...prev,
        category_roadtrip: v
      }
    })
  }

  const handleChangeTypeRoadTrip = (event, data) => {
    setQuery(prev =>{
      return{
        ...prev,
        roadtrip_type: data.value
      }
    })
  }

  const handleChangeSort = (event, data) => {
    switch(data.value){
      case optionsSort[0].value:
        setQuery(prev =>{
          return{
            ...prev,
            sort_type: "updated_at",
            sort_direction: "DESC",
            current_page: 1
          }
        })
        break;

      case optionsSort[1].value:
        setQuery(prev =>{
          return{
            ...prev,
            sort_type : "updated_at",
            sort_direction :"ASC",
            current_page: 1
          }
        })
        break;

      case optionsSort[2].value:
        setQuery(prev =>{
          return{
            ...prev,
            sort_type : "notes",
            sort_direction :"DESC",
            current_page: 1
          }
        })
        break;

      case optionsSort[3].value:
        setQuery(prev =>{
          return{
            ...prev,
            sort_type : "notes",
            sort_direction :"ASC",
            current_page: 1
          }
        })
        break;

      case dayOptions[0].value:
        setQuery(prev =>{
          return{
            ...prev,
            number_days : undefined,
            current_page: 1
          }
        })
        break;

      case dayOptions[1].value: case dayOptions[2].value: case dayOptions[3].value: case dayOptions[4].value:
        setQuery(prev =>{
          return{
            ...prev,
            number_days : data.value,
            current_page: 1
          }
        })
        break;

      default:
        break;
    }
  };

  const onSelectAddress = (address) => {
    setQuery(prev =>{
      return{
        ...prev,
        latitude: address.geometry.coordinates[1],
        longitude: address.geometry.coordinates[0],
        distance: 10,
        current_page: 1
      }
    })

    setState(prev =>{
      return{
        ...prev,
        buttonAddressDisabled: false,
      }
    });
    setAddressFocus(true)
  };

  const checkAddress = (b) => {
    setState(prev =>{
      return{
        ...prev,
        buttonAddressDisabled: b,
      }
    });
  };

  const onClearAddress = () => {
    setState({
      locationIsLoading: false,
      buttonAddressDisabled: true,
    });
    setQuery(prev =>{
      return{
        ...prev,
        distance: undefined,
        latitude: undefined,
        longitude: undefined,
        current_page: 1
      }
    })
    setAddressFocus(false)
  };

  const handleChangeDistance = (v) => {
    setQuery(prev =>{
      return{...prev, distance: v.target.value, current_page: 1}
    })
  }

  const handleCreateRoadTrip = () => {
    if (user.id) {
      setModal(state => ({
        ...state,
        create: true
      }))
    } else {
      history.push(`/login?redirectURL=${location.pathname}`);
    }
  }

  const handleMyRoadTrip = () => {
    if (user.id){
      history.push("/user/roadtrips");
    } else {
      history.push(`/login?redirectURL=${location.pathname}`);
    }
  }

  const handleMyFavorite = () => {
    if (user.id) {
      history.push("/user/roadtrips/favorite");
    } else {
      history.push(`/login?redirectURL=${location.pathname}`);
    }
  }

  const handleCopyTrip = (id) => {
    if (user.id) {
      setModal(state => ({
        ...state,
        copy: true,
        id
      }))
    } else {
      history.push(`/login?redirectURL=${location.pathname}`);
    }
  }


  return (
    modal.create ?
      <div className="container min-vh-75 pt-5">
        <CreateRoadTrip onCancel={() => setModal(state => ({...state, create: false}))} modal={modal} />
      </div>
      :
      <ThemeProvider theme={theme}>
      <div className="container min-vh-75 pt-5">
        <Title title="Road Trips" description={t("roadTripsPage.description")} />

        <Button title={t("general.createRoadTrip")} rounded color="information-block" className="m-1" onClick={handleCreateRoadTrip} />
        <Button title={t("general.myRoadTrips")} rounded color="information-block" className="m-1" onClick={handleMyRoadTrip} />
        <Button title={t("general.myFavorite")} rounded color="information-block" className="m-1" onClick={handleMyFavorite}/>

        <div className="my-2 pb-3 px-2 border-bottom row">

          <div className="col-md-6 col-xs-12 mb-2 mt-md-2 col-lg-6 roadtrip__input-container roadtrip__input-search"
               onClick={() => titleRef.current.focus()}>
            <input
              className="roadtrip__input"
              placeholder={t("roadTripsPage.searchTitle")}
              ref={titleRef}
              value={input}
              onInput={(e) => setInput(e.target.value)}
            />
            <HeaderButton
              materialIcon={SearchIcon}
              className="title-button"
              onClick={()=> setQuery({...query, title: input !== "" ? input : undefined, current_page: 1})}
            />
          </div>

          <div className="col-md-6 col-lg-6 mt-md-2 mb-5">
            <div className="roadtrip__input-container roadtrip__input-location ml-md-1" onClick={() => addressRef.current.click()}>
              <AutocompletePlaceAddress
                name="address"
                className="roadtrip__input"
                placeholder={t("roadTripsPage.searchLocation")}
                onSelect={onSelectAddress}
                onClear={onClearAddress}
                ref={addressRef}
                onFocusInp={() => setState({...state, focus: true})}
                onBlurInp={() => setState({...state, focus: false})}
                isAddressComplete={checkAddress}
              />
            </div>
            {
              addressFocus &&
              <div className="mb-1 d-flex flex-column align-items-center slider-search" >
                <p className="p-0 m-0">{query.distance || 0} km</p>
                <div className="d-flex justify-content-around align-items-center w-100">
                  <p className="p-0 m-0">10</p>
                  <input
                    type="range"
                    className="form-range w-75"
                    min="10"
                    max="200"
                    step="10"
                    value={query.distance || 10}
                    onChange={handleChangeDistance}
                  />
                  <p className="p-0 m-0">200</p>
                </div>
              </div>
            }
          </div>

          <div className="text-center col-md-4 col-lg-3 mb-2 mb-md-0">
            <Dropdown
              clearable
              button
              className="icon col-sm-12 col-md-12 mt-md-2 m-0"
              placeholder={t("general.category.title")}
              icon="sliders horizontal"
              labeled
              multiple
              selection
              settings={{useLabels: false}}
              options={categorieFilter}
              value={query.category || []}
              onChange={(event, data) => handleFilters("category", data?.value ?? 0) }
            />
          </div>

          <div className="text-center col-md-4 col-lg-3 mb-2 mb-md-0">
            <Dropdown
              clearable
              button
              className="icon col-sm-12 col-md-12 mt-md-2 m-0"
              placeholder={t("general.category.transport.title")}
              icon="car"
              labeled
              multiple
              selection
              settings={{useLabels: false}}
              options={categorieTransportFilter}
              value={query.category_roadtrip || []}
              onChange={(event, data) => handleFiltersCategoryRoadTrip("category_roadtrip", data?.value ?? 0) }
            />
          </div>

          <div className="text-center col-md-4 col-lg-3 mb-2 mb-md-0">
            <Dropdown
              clearable
              button
              className="icon col-sm-12 col-md-12 mt-md-2 m-0"
              placeholder={t("general.category.withWhom.title")}
              icon="child"
              labeled
              multiple
              selection
              settings={{useLabels: false}}
              options={categorieWhithWhomFilter}
              value={query.category_roadtrip || []}
              onChange={(event, data) => handleFiltersCategoryRoadTrip("category_roadtrip", data?.value ?? 0) }
            />
          </div>

          <div className="text-center col-md-4 col-lg-3 mb-2 mb-md-0">
            <Dropdown
              clearable
              button
              className="icon col-sm-12 col-md-12 mt-md-2 m-0"
              placeholder={t("general.category.season.title")}
              icon="calendar"
              labeled
              multiple
              selection
              settings={{useLabels: false}}
              options={categorieSeasonFilter}
              value={query.category_roadtrip || []}
              onChange={(event, data) => handleFiltersCategoryRoadTrip("category_roadtrip", data?.value ?? 0) }
            />
          </div>

          <div className="text-center col-md-4 col-lg-3 mb-2 mb-md-0 ">
            <Dropdown
              button
              className="icon col-sm-12 col-md-12 mt-md-2 m-0"
              placeholder={t("filter.byDay.title")}
              icon="calendar"
              labeled
              options={dayOptions}
              value={query.number_days || "[1,20]"}
              settings={{useLabels: false}}
              onChange={handleChangeSort}
            />
          </div>

          <div className="text-center col-md-4 col-lg-3 mb-2 mb-md-0 ">
            <Dropdown
              className="icon col-sm-12 col-md-12 mt-md-2 m-0"
              defaultValue={optionsSort[0].value}
              icon="filter"
              button
              labeled
              options={optionsSort}
              onChange={handleChangeSort}
            />
          </div>

          <div className="text-center col-md-4 col-lg-3 mb-2 mb-md-0 ">
          <Dropdown
            className="icon col-sm-12 col-md-12 mt-md-2 m-0"
            defaultValue={typeFilter[0].value}
            placeholder="Gratuit"
            icon="money"
            button
            labeled
            options={typeFilter}
            onChange={handleChangeTypeRoadTrip}
          />
        </div>
        </div>
        <div className="row">
          {
            !loading ?
              roadtrips?.length !== 0 ?
                <>
                  {
                    roadtrips?.slice(0, 6)?.map(el =>
                      <div key={el.id} className="col-md-6 col-lg-4 py-2 d-flex flex-column align-items-center">
                        <TripCard trip={el} />

                      </div>
                    )
                  }
                </>
                :
                <p className="text-muted text-center w-100">Aucun résultat trouvé</p>
              :
              <div className="my-5 align-items-center roadtrip__loader">
                <Loader active className="mt-4 position-fixed" />
              </div>
          }
        </div>
        <div className="w-100 justify-content-center pt-2 roadtrip__pagination">
          <Pagination itemsNumber={numberPages || 1} itemsPerPage={6} onChange={(page) => setQuery({...query, current_page: page + 1})}/>
        </div>

        <CopyRoadTrip show={modal.copy} onHide={() => setModal(state =>({...state, copy: false, id: null}))} id={modal.id} />
      </div>
      </ThemeProvider>
  );
};

export default RoadTripsPage;
