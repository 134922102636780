import React from "react";
import {Form, Message} from "semantic-ui-react"
import HCaptcha from "@hcaptcha/react-hcaptcha";
import { useTranslation } from "react-i18next";

const LogForm = (props) => {

  const {t} = useTranslation(["henriTrip"])

  if(props.currentStep !== 2) {
    return null
  }


  return(
    <>
      <Form.Input
        id="username"
        name="username"
        required={true}
        type="name"
        placeholder={t("register.username")}
        label={t("register.username")}
        onChange={props.inputChange}
        value={props.username}
      />
      <Message
        color="red"
        hidden={props.correct.username}
        header={t("errorUsername.firstError.header")}
        content={t("errorUsername.firstError.content")}
      />
      <Message
        color="red"
        hidden={!(props.error?.username)}
        header={t("errorUsername.secondError.header")}
        content={t("errorUsername.secondError.content")}
      />
      <Form.Input
        id="email"
        name="email"
        required={true}
        type="email"
        placeholder="hello@henri.fr"
        label="Email"
        onChange={props.inputChange}
        value={props.email}
      />
      <Message
        color="red"
        hidden={props.correct.email}
        header={t("register.errorEmail.firstError.header")}
        content={t("register.errorEmail.firstError.content")}
      />
      <Message
        color="red"
        hidden={!(props.error?.email)}
        header={t("register.errorEmail.secondError.header")}
        content={t("register.errorEmail.secondError.content")}
      />
      <Form.Input
        id="password"
        name="password"
        required={true}
        type="password"
        placeholder={"•••••••••"}
        label={t("general.password")}
        onChange={props.inputChange}
        value={props.password}
      />
      <Message
        color="red"
        hidden={props.correct.password}
        header={t("general.errorPassword.header")}
        content={t("general.errorPassword.content")}
      />
      <Form.Input
        id="password_confirmation"
        name="password_confirmation"
        required={true}
        type="password"
        placeholder={"•••••••••"}
        label={t("general.confirmPassword")}
        onChange={props.inputChange}
        value={props.password_confirmation}
      />
      <Message
        color="red"
        hidden={props.correct.password_confirmation}
        header={t("general.errorConfirmPassword.header")}
        content={t("general.errorConfirmPassword.content")}
      />
      <Form.Checkbox
        id="statement"
        name="statement"
        value="statement"
        label={
          <label>
            {t("register.accept")} <a target="_blank" className="font-weight-bold" href={"https://support.henritrip.fr/conditions-generales-dutilisation-du-site-internet-henri-trip-et-notifications/"} rel="noreferrer">{t("login.conditionOfUse.secondPart")}</a> {t("login.conditionOfUse.thirdPart")} <a target="_blank" className="font-weight-bold" href={"https://support.henritrip.fr/politique-de-confidentialite/"} rel="noreferrer">{t("login.conditionOfUse.fourthPart")}</a>.
          </label>
        }
        onChange={props.inputChange}
        checked={props.statement === true}
      />

      <Form.Checkbox
        id="newsletter"
        name="newsletter"
        value="newsletter"
        label={
          <label>
            {t("register.newsletter")}
          </label>
        }
        onChange={props.inputChange}
        checked={props.newsletter === true}
      />
      <Form.Field>
        <HCaptcha
          sitekey={process.env.REACT_APP_HCAPTCHA_API_KEY}
          onVerify={(e) => props.inputChange(e, {name: 'captcha', value: true})}
          onExpire={(e) => props.inputChange(e, {name: 'captcha', value: false})}
        />
      </Form.Field>
    </>
  )
}

export default LogForm;
