import React, { useState } from "react";
import Title from 'components/header/title/title';
import './PartnerSectionsSwitch.scss';
import { useTranslation } from "react-i18next";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../utils/utils";
import DropContainer from "../../../components/dropContainer/dropContainer";
import Button from "../../../components/form/button/button";




const PartnerSectionsSwitch = ({demo}) => {
  const {t} = useTranslation(["henriTrip"])

  const categories_touristic = [
    {
      title: t("partner.activitySections.category.title3"),
      subtitle: t("partner.activitySections.category.subtitle3"),
      color: "#ffbb00",
      icon: "assets/icons/Fichier12.svg",
      id: "museumId"
    },
    {
      title: t("partner.activitySections.category.title5"),
      subtitle: t("partner.activitySections.category.subtitle5"),
      color: "#e3cb9b",
      icon: "assets/icons/Fichier10.svg",
      id: "religiousId"
    },
    {
      title: t("partner.activitySections.category.title7"),
      subtitle: t("partner.activitySections.category.subtitle7"),
      color: "#00a544",
      icon: "assets/icons/Fichier13.svg",
      id: "parkId"
    },
    {
      title: t("partner.activitySections.category.title8"),
      subtitle: t("partner.activitySections.category.subtitle8"),
      color: "#967db3",
      icon: "assets/icons/Fichier14.svg",
      id: "amusementParkId"
    },
    {
      title: t("partner.activitySections.category.title9"),
      subtitle: t("partner.activitySections.category.subtitle9"),
      color: "#9e7241",
      icon: "assets/icons/Fichier6.svg",
      id: "grottoChasmId"
    },
    {
      title: t("partner.activitySections.category.title10"),
      subtitle: t("partner.activitySections.category.subtitle10"),
      color: "#4da1c4",
      icon: "assets/icons/Fichier8.svg",
      id: "castleId"
    },
  ]

  const categories_activity = [
    {
      title: t("partner.activitySections.category.title1"),
      subtitle: null,
      color: "#ef7e26",
      icon: "assets/icons/Fichier4.svg",
      margin: "pr-1 pr-sm-2",
      col: "col-6",
      id: "activityId"
    },
    {
      title: t("partner.activitySections.category.title4"),
      subtitle: t("partner.activitySections.category.subtitle4"),
      color: "#a42422",
      icon: "assets/icons/Fichier7.svg",
      margin: "",
      col: "col-6",
      id: "wineTourismId"
    },
    {
      title: t("partner.activitySections.category.title2"),
      subtitle: null,
      color: "#f7a38e",
      icon: "assets/icons/Fichier3.svg",
      margin: "pl-1 pl-sm-2",
      col: "col-12",
      id: "wellnessId"
    }
  ]

  const categories_officeTourism = [
    {
      title: t("partner.activitySections.category.title11"),
      subtitle: null,
      color: "#004b8c",
      icon: "assets/icons/Fichier4.svg",
      image: "https://general-henri-1-paris.s3.fr-par.scw.cloud/partenaire_page/info.png"
    },
    {
      title: t("partner.activitySections.category.title12"),
      subtitle: null,
      color: "#707070",
      icon: "assets/icons/location-dot-solid.svg",
      image: "https://general-henri-1-paris.s3.fr-par.scw.cloud/partenaire_page/localisation.png"
    },

    {
      title: t("partner.activitySections.category.title12"),
      subtitle: null,
      color: "#ff9d00",
      icon: "assets/icons/Fichier7.svg",
      image: "https://general-henri-1-paris.s3.fr-par.scw.cloud/partenaire_page/terre.png"
    },
  ]





  const [selected, setSelected] = useState(false);

  const handleClickTouristicSite = () => {
    setSelected(false);
  }
  const handleClickOfficeTourisme = () => {
    setSelected(true);
  }

  return (
    <div className="container">
      <div id="offreId" className="row d-flex justify-content-center mt-2 mt-lg-0">
        <Title title={t("partner.switchSections.title")}/>
      </div>
      {
        demo ?
          <></>
          :
          <div className="row d-flex justify-content-center text-center align-middle">
            <div className={`col-6 p-0 ${ selected ? "" : "text-primary font-weight-bold"}`} onClick={handleClickTouristicSite}>
              <h2 className="title-switch pt-4 pt-sm-0">{t("partner.switchSections.touristicSites")}</h2>
              <hr className="hr-class" color={selected ? "#FFFFFF" : "#0275d8"}/>
            </div>
            <div className={`col-6 p-0 ${ selected ? "text-primary font-weight-bold" : ""}`} onClick={handleClickOfficeTourisme}>
              <h2 className="title-switch">{t("partner.switchSections.destinationManagementOrganisation")}</h2>
              <hr className="hr-class" color={selected ? "#0275d8" : "#FFFFFF"}/>
            </div>
          </div>
      }

      {
        selected ?
          <div className="row">
            <div className="col-12">
              <div className="m-2 container_switch">
                <div className="container">
                  <div className="row mt-2">
                    <div className="col mt-2">
                      <p className="container_title">{t("partner.switchSections.offers.title")}</p>
                    </div>
                  </div>
                  <div className="row">
                    {
                      categories_officeTourism.map(el =>
                        <div className="ml-2">
                          <div className="badge carte_ot text-wrap mb-2" style={{backgroundColor: el.color}}>
                            <img src={el.image} height="26" width="26" alt=""/>
                            <div className="text-white">
                              <div className="row d-flex align-middle">
                                <div className="col-12 text-card">
                                  {el.title}
                                </div>
                                <div className="col-12">
                                  { el.subtitle ? el.subtitle : null}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )
                    }
                  </div>
                  <div className="row mb-2">
                    <div className="col">
                      <p>{t("partner.switchSections.subtitle")}
                      </p>
                    </div>
                  </div>

                  <div className="row">
                    <DropContainer titre={t("partner.switchSections.offers.seo.title")} description={t("partner.switchSections.offers.seo.text")}/>
                  </div>
                  <div className="row">
                    <DropContainer titre={t("partner.switchSections.offers.reviews.title")} description={t("partner.switchSections.offers.reviews.text")}/>
                  </div>
                  <div className="row">
                    <DropContainer titre={t("partner.switchSections.offers.chat.title")} description={t("partner.switchSections.offers.chat.text")}/>
                  </div>
                  <div className="row">
                    <DropContainer titre={t("partner.switchSections.offers.alertIncident.title")} description={t("partner.switchSections.offers.alertIncident.text")}/>
                  </div>
                  <div className="row">
                    <DropContainer titre={t("partner.switchSections.offers.manageActivity.title")} description={t("partner.switchSections.offers.manageActivity.text")}/>
                  </div>
                  <div className="row">
                    <DropContainer titre={t("partner.switchSections.offers.multimedia.title")} description={t("partner.switchSections.offers.multimedia.text")}/>
                  </div>
                  <div className="row">
                    <DropContainer titre={t("partner.switchSections.offers.translate.title")} description={t("partner.switchSections.offers.translate.text")}/>
                  </div>
                  <div className="row">
                    <DropContainer titre="Audio-guide" description={t("partner.switchSections.offers.audio.text")}/>
                  </div>
                  <div className="row">
                    <DropContainer titre={t("partner.switchSections.offers.ticketing.title")} description={t("partner.switchSections.offers.ticketing.text")}/>
                  </div>
                  <div className="row">
                    <DropContainer titre={t("partner.switchSections.offers.reservation.title")} description={t("partner.switchSections.offers.reservation.text")}/>
                  </div>
                  <div className="row">
                    <DropContainer ot={true} option={true} titre={t("partner.switchSections.offers.map.title")} description={t("partner.switchSections.offers.map.text")}/>
                  </div>

                  <div className="row mb-2">
                    <div className="col">
                      <h2 className="text-primary">{t("partner.switchSections.whyChoose.title")}</h2>
                    </div>
                  </div>
                  <div className="row">
                    <DropContainer titre={t("partner.switchSections.whyChoose.increaseData.title")} description={t("partner.switchSections.whyChoose.increaseData.text")}/>
                  </div>
                  <div className="row">
                    <DropContainer titre={t("partner.switchSections.whyChoose.communicateOffer.title")} description={t("partner.switchSections.whyChoose.communicateOffer.text")}/>
                  </div>
                  {
                    demo ?
                      <div className="row mb-2">
                        <div className="col-12 mb-2 d-flex justify-content-center">
                          <Button title="Essayer gratuitement" onClick={() => window.open("https://calendly.com/henri-trip/30min", '_blank')}/>
                        </div>
                      </div>
                      :
                      <div className="row mb-2">
                        <div className="col-12 mb-2 d-flex justify-content-center">
                          <Button title={t("partner.becomePartner")} onClick={() => window.document.getElementById('searchBar').scrollIntoView({block: "center"})}/>
                        </div>
                      </div>
                  }
                </div>
              </div>
            </div>
          </div>
          :
          <div className="row">
            <div className="col-12 col-lg-6">
              <div className="col-lg-11 m-2 container_switch">
                <div className="container">
                  <div className="row mt-2">
                    <div className="col mt-2 mb-1">
                      <p className="container_title">{t("partner.switchSections.offers.title")}</p>
                    </div>
                  </div>
                  <div className="row">
                    {
                      categories_touristic.map(el =>
                        <div className="col-6 col-lg-4">
                              <div className="badge carte text-wrap mb-2" id={el.id}  style={{backgroundColor: el.color}}>
                                  <SVG style={{color: "white"}} src={toAbsoluteUrl(el.icon)} height="26" width="20"/>
                                  <div className=" text-white">
                                    <div className="row d-flex align-middle">
                                      <div className={`col-12 ${ el.subtitle ? "" : "text-card"}`}>
                                        {el.title}
                                      </div>
                                      <div className="col-12">
                                        { el.subtitle ? el.subtitle : null }
                                      </div>
                                    </div>
                                  </div>
                              </div>
                        </div>
                      )
                    }
                  </div>

                  {
                    demo ?
                      <>
{/*                      <div className="col mb-1">
                        <div className="row">
                          À partir de : <p className="priceText"> 79.90 € / mois</p>
                        </div>
                      </div>*/}
                      </>
                      :
                      <></>
                  }
                  <div className="row">
                    <DropContainer titre={t("partner.switchSections.offers.seo.title")} description={t("partner.switchSections.offers.seo.text")}/>
                  </div>
                  <div className="row">
                    <DropContainer titre={t("partner.switchSections.offers.reviews.title")} description={t("partner.switchSections.offers.reviews.text")}/>
                  </div>
                  <div className="row">
                    <DropContainer titre={t("partner.switchSections.offers.chat.title")} description={t("partner.switchSections.offers.chat.text")}/>
                  </div>
                  <div className="row">
                    <DropContainer titre={t("partner.switchSections.offers.alertIncident.title")} description={t("partner.switchSections.offers.alertIncident.text")}/>
                  </div>
                  <div className="row">
                    <DropContainer titre={t("partner.switchSections.offers.manageActivity.title")} description={t("partner.switchSections.offers.manageActivity.text")}/>
                  </div>
                  <div className="row">
                    <DropContainer titre={t("partner.switchSections.offers.multimedia.title")} description={t("partner.switchSections.offers.multimedia.text")}/>
                  </div>
                  <div className="row">
                    <DropContainer titre={t("partner.switchSections.offers.translate.title")} description={t("partner.switchSections.offers.translate.text")}/>
                  </div>
                  <div className="row">
                    <DropContainer titre="Audio-guide" description={t("partner.switchSections.offers.audio.text")}/>
                  </div>
                  <div className="row">
                    <DropContainer titre={t("partner.switchSections.offers.ticketing.title")} description={t("partner.switchSections.offers.ticketing.text")}/>
                  </div>
                  <div className="row">
                    <DropContainer titre={t("partner.switchSections.offers.reservation.title")} description={t("partner.switchSections.offers.reservation.text")}/>
                  </div>
                  <div className="row">
                    <DropContainer option={true} titre={t("partner.switchSections.offers.map.title")} description={t("partner.switchSections.offers.map.text")}/>
                  </div>
                  {
                    demo ?
                      <div className="row mb-2">
                        <div className="col-12 mb-2 d-flex justify-content-center">
                          <Button title="Essayer gratuitement" onClick={() => window.open("https://calendly.com/henri-trip/30min", '_blank')}/>
                        </div>
                      </div>
                      :
                      <div className="row mb-2">
                        <div className="col-12 mb-2 d-flex justify-content-center">
                          <Button title={t("partner.becomePartner")} onClick={() => window.document.getElementById('searchBar').scrollIntoView({block: "center"})}/>
                        </div>
                      </div>
                  }
                </div>
              </div>
            </div>
            <div className="col-12 col-lg-6">
              <div className="col-lg-11 m-2 container_switch">
                <div className="container">
                  <div className="row mt-2">
                    <div className="col mt-2 mb-1">
                      <p className="container_title">{t("partner.switchSections.offers.title")}</p>
                    </div>
                  </div>

                  <div className="row">
                    {
                      categories_activity.map(el =>
                        <div className={`${el.col} col-sm-4 ${el.margin} `}>
                          <div className="badge carte text-wrap mb-2" id={el.id} style={{backgroundColor: el.color}}>
                            <SVG style={{color: "white"}} src={toAbsoluteUrl(el.icon)} height="26" width="20"/>
                            <div className="text-white">
                              <div className="row d-flex align-middle">
                                <div className={`col-12 ${ el.subtitle ? "" : "text-card"}`}>
                                  {el.title}
                                </div>
                                <div className="col-12">
                                  { el.subtitle ? el.subtitle : null }
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )
                    }
                  </div>
                  {
                    demo ?
                      <>
{/*                      <div className="col mb-1">
                        <div className="row">
                          À partir de : <p className="priceText"> 19.90 € / mois</p>
                        </div>
                      </div>*/}
                      </>
                      :
                      <></>
                  }

                  <div className="row">
                    <DropContainer titre={t("partner.switchSections.offers.seo.title")} description={t("partner.switchSections.offers.seo.text")}/>
                  </div>
                  <div className="row">
                    <DropContainer titre={t("partner.switchSections.offers.reviews.title")} description={t("partner.switchSections.offers.reviews.text")}/>
                  </div>
                  <div className="row">
                    <DropContainer titre={t("partner.switchSections.offers.chat.title")} description={t("partner.switchSections.offers.chat.text")}/>
                  </div>
                  <div className="row">
                    <DropContainer titre={t("partner.switchSections.offers.alertIncident.title")} description={t("partner.switchSections.offers.alertIncident.text")}/>
                  </div>
                  <div className="row">
                    <DropContainer titre={t("partner.switchSections.offers.reservation.title")} description={t("partner.switchSections.offers.ticketing2.text")}/>
                  </div>
                  <div className="row">
                    <DropContainer titre={t("partner.switchSections.offers.ticketing2.title")} description={t("partner.switchSections.offers.ticketing2.text")}/>
                  </div>
                  {
                    demo ?
                      <div className="row mb-2">
                        <div className="col-12 mb-2 d-flex justify-content-center">
                          <Button title="Essayer gratuitement" onClick={() => window.open("https://calendly.com/henri-trip/30min", '_blank')}/>
                        </div>
                      </div>
                      :
                      <div className="row mb-2">
                        <div className="col-12 mb-2 d-flex justify-content-center">
                          <Button title={t("partner.becomePartner")} onClick={() => window.document.getElementById('searchBar').scrollIntoView({block: "center"})}/>
                        </div>
                      </div>
                  }
                </div>
              </div>
            </div>
          </div>
      }
    </div>
  );
};

export default PartnerSectionsSwitch;