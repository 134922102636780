import { ReactComponent as AddIcon } from '../../../../assets/img/logo/addIcon.svg';
import {Fragment, useContext, useEffect, useRef, useState} from "react";
import styled, {ThemeContext} from "styled-components";
import {FileInput, FileInputLabel, TextArea, TextInput} from "../../../../_ui/_v2/components/FormElements";
import Loader from "../../../../_ui/_v2/components/Loader";
import {useFormik} from "formik";
import {
  API_ORIGINAL_BLOB_URL,
} from "../../../../utils/api";
import {ReactComponent as ImageUploadIcon} from '../../../../assets/img/logo/image-upload-icon.svg';
import SwitchInput from "../../../../_ui/_v2/components/SwitchInput/SwitchInput";
import Button from "../../../../_ui/_v2/components/Button";
import breakpoint from "styled-components-breakpoint";
import SliderContainerWithArrowNavigation from "../../../../_ui/_v2/components/SliderContainerWithArrowNavigation";
import quizz_schema from "../../../_schemas/quizz_schema";

const ButtonQuestionStyled = styled.div `
  background-color: ${props => props.theme.colors.black};
  width: 50px;
  height: 50px;
  padding: .5em;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  
  span {
    font-size: 2rem;
    font-weight: 700;
  }
`;

const FileInputContainer = styled.div`
  height: 200px;
  width: 100%;
  display: flex;
  background-color: ${(props) => props.theme.colors.fileInputEmpty};
  background-image: ${(props) => props.backgroundImageUrl ? `url('${props.backgroundImageUrl}')` : "none"};
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  ${(props) =>
   !props.backgroundImageUrl &&
  `
  border: 2px dashed ${props.theme.colors.henriBlue};
  `}
`;

const InputStyled = styled(TextInput)`
  background-color: transparent;
  border: none;
  color: white;
  resize: none;
  border-radius: .5em;
  width: 100%;
  height: 2em;
  
  ::placeholder{
    color: white;
  }
`;

const TextContainer = styled.div `
  background-color: rgba(0, 0, 0, .6);
  border: 1px solid rgba(169, 169, 169, 0.6);
  color: white;
  padding: 0 1em;
  resize: none;
  border-radius: .5em;
  width: 100%;
  
  ::placeholder{
    color: white;
  }
`;

const ButtonStyled = ({loading, placeholder, ...rest}) => {
  const theme = useContext(ThemeContext);

  return (
    <Button
      type='button'
      style={{padding: '0.5rem 2rem'}}
      {...rest}
    >
      {
        loading
          ? <Loader color={theme.colors.white} size="20px"/>
          : placeholder
      }
    </Button>
  );
};

const InputQuestionStyled = styled.input`
  background-color: rgba(0, 0, 0, .6);
  border: 1px solid rgba(169, 169, 169, 0.6);
  color: white;
  padding: .7em 1em .5em .5em;
  resize: none;
  border-radius: .5em;
  width: 100%;
  
  ::placeholder{
    color: white;
  }
`;

const KahootQuestionContainer = styled.div `
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  padding: 1em;
  width: 90%;
  
  ${breakpoint('md')`
    width: 80%;
  `}
`;

const MenuKahootDisplay = ({index, arrayOfQuestion, onQuestionChange, onDeleteQuestion, loading}) => {

  const {
    setFieldValue,
    values,
    handleChange,
    errors,
    touched,
  } = useFormik({
    initialValues: arrayOfQuestion,
    validationSchema: quizz_schema,
  });

  const [previewUrlImage, setPreviewUrlImage] = useState(values?.[index]?.medias?.[0]?.signed_id ? values?.[index]?.medias?.[0]?.signed_id : '');

  useEffect(() => {
    setPreviewUrlImage(values?.[index]?.medias?.[0]?.id ? API_ORIGINAL_BLOB_URL(values?.[index]?.medias[0].signed_id, values?.[index]?.medias[0].filename) : values?.[index]?.medias?.[0]?.signed_id)
  },[index]);

  const [cancelReq, setCancelReq] = useState(false);
  const onCheckandSendRequest = ({values}) => {
    errors[index]?.responses?.map((response,i)=>{
      if(response[i]?.response?.text_fr.length < 1){
        setCancelReq(true)
      }
    })
    if (!cancelReq){
      onQuestionChange(values)
    }
  };

  return(
    <KahootQuestionContainer key={index}>
      <label style={{fontWeight: '700', fontSize: '1.4rem'}}>Question n°{index + 1}</label>
      <InputQuestionStyled
        name={`[${index}]question.text_fr`}
        value={values?.[index]?.question?.text_fr}
        type='text'
        placeholder='La question est la suivante...'
        onChange={handleChange}
      />
      <FileInputContainer
        backgroundImageUrl={previewUrlImage}
        key={index}
      >
        <FileInput
          id={1}
          name={`[${index}]?.medias[0]`}
          accept="image/*"
          activeStorageServiceName="touristic_site_scaleway"
          hasError={errors.medias?.[index]?.signed_id && touched.medias?.[index]?.signed_id}
          errorMessage={errors.medias?.[index]?.signed_id}
          onChange={(event) => {
            if (event.target.value !== values?.[index]?.image?.content?.signed_id) {
              setFieldValue(
                `[${index}]medias[0]`,
                {signed_id: event.target.value}
              );
            } else {
              handleChange(event);
            }
          }}
          value={values?.[index]?.medias?.[0]?.signed_id}
          onBlobUrlChange={setPreviewUrlImage}
          style={{margin: 'auto'}}
        >
          {
            ({ready, globalProgress}) => (
              <FileInputLabel
                disabled={!ready}
                progress={globalProgress}
                style={{border: "none"}}
              >
                {ready
                  ? (
                    <div style={{color: 'black'}}>
                      <ImageUploadIcon style={{marginRight: '1em', height: 25 }}/>
                      {values?.[index]?.medias?.[0]?.signed_id ? 'Modifier l\'image' : 'Ajouter une image'}
                    </div>
                  ) : (
                    <>
                      <Loader style={{display: 'inline', margin: '0 1em 0 0', height: 25}}/>
                      {'Upload en cours'}
                    </>
                  )
                }
              </FileInputLabel>
            )
          }
        </FileInput>
      </FileInputContainer>
      {
        values?.[index]?.responses?.length > 0 && values?.[index]?.responses?.map((response,i) => (
          <TextContainer checked={response.good_answer} key={i}>
            <div>
              <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-around'}}>
                <InputStyled
                  name={`[${index}].responses[${i}].response.text_fr`}
                  type='text'
                  placeholder='Ajouter une réponse...'
                  style={{padding: '1.3rem', width: '70%'}}
                  hasError={errors[index]?.responses?.[i]?.response?.text_fr}
                  errorMessage={errors[index]?.responses?.[i]?.response?.text_fr}
                  value={values?.[index]?.responses[i]?.response?.text_fr}
                  onChange={handleChange}
                />
                <SwitchInput
                  labelText={['Vrai', 'Faux']}
                  name={`[${i}].good_answer`}
                  checked={response.good_answer}
                  key={i}
                  onChange={(event) => setFieldValue(`[${index}].responses[${i}].good_answer`, !response.good_answer)}
                />
              </div>
            </div>
            <div>
            {
              response?.good_answer &&
              <div style={{padding: "1em"}}>
                <TextArea
                  label='Description de la bonne réponse'
                  name={`[${index}].responses[${i}].detail.text_fr`}
                  style={{backgroundColor: 'rgba(0,0,0)', color: 'white'}}
                  value={values?.[index]?.responses[i]?.detail?.text_fr}
                  onChange={(event) => setFieldValue(`[${index}].responses[${i}].detail.text_fr`, event.target.value)}
                />
              </div>
            }
          </div>
          </TextContainer>
        ))
      }
      <div style={{display:'flex', justifyContent: 'space-around', marginBottom: "2em"}}>
        <ButtonContainer>
          <ButtonStyled placeholder='Enregistrer' loading={loading} onClick={(event) => {
            onCheckandSendRequest({values})
          }}>Enregistrer</ButtonStyled>
            {
              !arrayOfQuestion?.length <= 1 && (
                <ButtonStyled
                  placeholder='Supprimer'
                  style={{backgroundColor: 'red', padding: '.5rem 2rem'}}
                  onClick={(event) => {
                    onDeleteQuestion(values, index)
                  }}
                ></ButtonStyled>
              )
            }
        </ButtonContainer>
      </div>
    </KahootQuestionContainer>
 );
};

const QuestionsContainerOnDesktop = styled.div `
  display: none;

  ${breakpoint('md')`
    margin-top: 2em;
    margin-right: 2em;
    display: flex;
    flex-direction: column;
    gap: 20px;
  `}
`;

const QuestionsContainer = styled(SliderContainerWithArrowNavigation) `
  height: 110px;
  width: 100%;
  
  div:first-child {
    div{
      display: flex;
      align-items: center;
    }
  }
  
  ${breakpoint('md')`
    display: none;
  `}
`;


const AddQuestionToQuizz = ({quizzArray, selectedIndex, setSelectedIndex, onQuestionChange, onClickChangeQuestion, refLeft}) => {

  const reponseStub = {
    description: '',
    good_answer: false,
    detail: {text_fr: ''}
  };

  const quizzObjectToStub = {
    question: '',
    responses: [reponseStub,reponseStub,reponseStub,reponseStub],
    image:''
  };

  return(
  <Fragment key={selectedIndex + 2}>
      <QuestionsContainer key={selectedIndex} ref={refLeft}>
        {
          quizzArray?.length && quizzArray?.map((e,index) => (
            <Fragment key={index}>
              <ButtonQuestionStyled
                style={{backgroundColor: `${index === selectedIndex ? '#54A8FC' : 'black'}`, cursor: 'pointer'}}
                onClick={() => {
                  setSelectedIndex(index)
                }}
              >
                <span>{index + 1}</span>
              </ButtonQuestionStyled>
            </Fragment>
          )).concat([
            <div onClick={() => {
              onClickChangeQuestion(quizzArray?.length, quizzArray[selectedIndex])
              onQuestionChange(quizzObjectToStub)
            }}>
              <AddIcon />
            </div>
          ])
        }
      </QuestionsContainer>
      <QuestionsContainerOnDesktop key={quizzArray} ref={refLeft}>
        {
          quizzArray?.length && quizzArray?.map((e,index) => (
            <Fragment key={index}>
              <ButtonQuestionStyled
                style={{backgroundColor: `${index === selectedIndex ? '#54A8FC' : 'black'}`, cursor: 'pointer'}}
                onClick={() => {
                  onClickChangeQuestion(index, quizzArray[selectedIndex])
                  setSelectedIndex(index)
                }}
              >
                <span>{index + 1}</span>
              </ButtonQuestionStyled>
            </Fragment>
          )).concat([
            <div onClick={() => {
              // quizzArray.push(quizzObjectToStub)
              // setArrayOfQuestions(state => [...state, quizzObjectToStub])
              onQuestionChange(quizzObjectToStub)
            }}>
              <AddIcon />
            </div>
          ])
        }
      </QuestionsContainerOnDesktop>
    </Fragment>

  );
};

const QuizzContainer = styled.div `
  display: flex;
  flex-direction: column-reverse;
  justify-content: center;
  align-items: center;
  
  ${breakpoint('md')`
      flex-direction: row;
      align-items: flex-start;
  `}
`;

const ButtonContainer = styled.div `
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-left: ${props => `calc(${props.refLeft?.current?.offsetWidth}px + 2em)}`};
  gap: 20px;
`;

const FormContainerStyled = styled.form `
  display: flex;
  flex-direction: column; 
  width: 100%;
  justify-content: center;

  ${breakpoint('md')`
      width: 90%;
  `}
`;

const Kahoot = ({quizzToShow, onLayoutItemChange, loading}) => {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const refLeft = useRef();
  const [marginLeft, setMarginLeft] = useState(0);

  useEffect(() => {
    if (refLeft.current) {
      setMarginLeft(refLeft.current.offsetWidth)
    }
  }, []);

  const reponseStub = {
    response: {text_fr:''},
    good_answer: false,
    detail: {text_fr: ''}
  };

  const questionContent = {
    question: {text_fr: ''},
    responses: [reponseStub, reponseStub, reponseStub, reponseStub],
    medias: [],
    position: ''
  };

  const [quizzArray, setQuizzArray] = useState(quizzToShow[0]?.id ? quizzToShow[0].questions : [questionContent]);

  const deleteQuiz = (quizz, index) =>{
    quizzArray.splice(index, 1)
    setSelectedIndex(state => state - 1)
  };

  const deleteQuestionOnQuizz = (layoutItemIndex) => {
    layoutItemIndex === quizzArray.length - 1 && setQuizzArray(state => [...state, questionContent])
  };

  useEffect(()=>{
    if(quizzToShow?.questions?.length >= 1){
      setQuizzArray(quizzToShow?.questions)
    }
  },[quizzToShow?.questions]);

  useEffect(()=>{
    if(quizzToShow?.[0]?.questions?.length >= 1){
      setQuizzArray(quizzToShow?.[0]?.questions)
    }
  },[quizzToShow?.[0]?.questions]);

  return(
    <>
      <FormContainerStyled onSubmit={(event) => {
        onLayoutItemChange(quizzArray)
      }}>
        <QuizzContainer>
          <AddQuestionToQuizz
            quizzArray={quizzArray}
            setSelectedIndex={setSelectedIndex}
            selectedIndex={selectedIndex}
            key={selectedIndex}
            onClickChangeQuestion={(...args) => deleteQuestionOnQuizz(...args)}
            onQuestionChange={(...args) => setQuizzArray(state => [...state, questionContent])}
            refLeft={refLeft}
          />
          <MenuKahootDisplay
            index={selectedIndex}
            arrayOfQuestion={quizzArray}
            key={selectedIndex + 1}
            onQuestionChange={(...args) => onLayoutItemChange(...args)}
            onDeleteQuestion={(...args) => deleteQuiz(...args)}
            loading={loading}
          />
        </QuizzContainer>
      </FormContainerStyled>
    </>
  )
};

export default Kahoot;