import styled, { keyframes, ThemeContext } from "styled-components";
import breakpoint from "styled-components-breakpoint";
import { ReactComponent as ChevronIcon } from "../../../assets/img/logo/chevron_down.svg";
import { Fragment, useContext, useEffect, useRef, useState } from "react";
import FullScreenNavigationModal from "../../../_ui/_v2/components/FullScreenNavigationModal";
import DayActivityDetails from "../experiences/DayActivityDetails";
import {
  API_DELETE_ACTIVITY,
  API_DELETE_VISIT_SHEET,
  API_MOVE_VISIT_SHEET,
  API_GET_ACTIVITY,
  API_MOVE_ACTIVITY,
} from "../../../utils/api";
import useRequest from "../../../hooks/use-request";
import { useSelector } from "react-redux";
import Loader from "../../../_ui/_v2/components/Loader/Loader";
import PlusButton from "../../../_forms/_components/avpm/_commons/PlusButton";
import PlusIcon from "../../../_forms/_components/avpm/_commons/PlusIcon";
import TriangleIcon from "../../../_forms/_components/avpm/_commons/TriangleIcon";

import { ReactComponent as ModifierIcon } from "../../../assets/img/logo/pencil-icon.svg";

import ActivityContext, {
  ActivityProvider,
} from "../../../_contexts/ActivityContext";
import ActivityForm from "../../../_forms/_components/avpm/activities/ActivityForm";
import TouristicSiteContext from "../../../_contexts/TouristicSiteContext";
import InterestPointContext, {
  InterestPointProvider,
} from "../../../_contexts/InterestPointContext";
import InterestPointForm from "../../../_forms/_components/avpm/interestPoint/InterestPointForm";
import Map from "../map/Map";
import {useTranslation} from "react-i18next";

const slideout = keyframes`
  to {
    top: 90%;
  }
`;

const slidein = keyframes`
  to {
    top: 15%;
  }
`;

const visitSlideout = keyframes`
  to {
    top: 65%;
  }
`;

const MediaToShowStyled = styled.div`
  display: block;
  position: fixed;
  top: ${(props) => props.topMenu}px;
  transform: translateY(
    ${(props) =>
    props.topState < -props.topMenu ? -props.topMenu : props.topState}px
  );
  height: calc(
    ${(props) => (props.isVisit ? "100vh" : "100%")} -
      env(safe-area-inset-bottom)
  );
  box-sizing: border-box;
  width: 100%;
  z-index: 2;
  animation: ${(props) => (props.openMediaModal ? slidein : slideout)};
  animation-duration: 1s;
  animation-fill-mode: forwards;
  padding-bottom: ${(props) => props.topMenu}px;

  ${breakpoint("md")`
    top: 90%;
    height: 100%;
    box-sizing: border-box;
    width: 61%;
    left: 20%;
    transform: none;
  `}
`;
const InformationsMedias = styled.div`
  display: ${(props) => (props.isMedia || props.isVisit ? "block" : "none")};
  position: fixed;
  top: ${(props) => props.topMenu}px;
  transform: translateY(
    ${(props) =>
    props.topState < -props.topMenu ? -props.topMenu : props.topState}px
  );
  height: 100%;
  box-sizing: border-box;
  width: 100%;
  z-index: 2;
  --padding-main-information: ${(props) =>
    !props.openMediaModal ? 0 : "1rem"};
  background: linear-gradient(
      203.67deg,
      rgba(84, 168, 252, 0.3) 0%,
      rgba(0, 0, 0, 0.27) 100.82%
    ),
    #1e222c;
  max-width: 900px;
  margin: 0 auto;
  padding: ${(props) =>
    !props.openMediaModal ? "0" : "2rem 0 var(--padding-main-information) 0"};
  border-radius: 2rem 2rem 0 0;

  > * {
    &:last-child {
      max-width: 100%;
      margin-bottom: calc(3rem + 1.5vw);
      margin-top: 2rem;
      width: 100%;
    }
  }
  ${breakpoint("md")`
    position: relative;
    max-height: 700px;
    --padding-main-information: 6rem;
    border-radius: 2rem;
    padding:${(props) =>
      props.isMedia
        ? 0
        : "8rem var(--padding-main-information) var(--padding-main-information) var(--padding-main-information)"};
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  `}
`;

const HeaderTouristicSite = styled.div`
  display: flex;
  justify-content: center;
  padding: ${(props) => (props.isVisit ? "2rem 0 0 0" : "2rem 2rem 2rem 2rem")};
  flex-direction: column;
  width: 100%;
`;

const InteractionToShow = styled.div`
  display: block;
  position: absolute;
  right: 25px;
  top: 20px;

  ${breakpoint("md")`
      display: ${(props) =>
      props.isVisit || props.isNotHome ? "none" : "block"};
      top: 15px;
    `}
`;

const ButtonContainerStyled = styled.button`
  border: 1px white solid;
  padding: 0.3em;
  border-radius: 2em;
  background-color: transparent;

  ${breakpoint("md")`
    padding: 0.5em;
  `}
`;

const MenuContainerStyled = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  border: 2px black solid;
  padding: 1rem;

  ${breakpoint("md")`
    justify-content: center;
    border: none;
    gap: 25px;
  `}
`;

const TitleActiveStyled = styled.div`
  background-color: ${(props) => props.active && props.theme.colors.henriBlue};
  border-radius: 2em;
  padding: 0.7rem;
  display: block;
  cursor: pointer;
  svg {
    color: ${(props) => props.active && props.theme.colors.white};
  }
`;

const TabbedMedias = ({
  activeTabIndex,
  selectedIndex,
  isMedia,
  onActiveTabIndexChange,
  items,
  centerTitles,
  siteToChoose,
  finalLap = false,
  visibleTitles = true,
  ...rest
}) => {
  const [activeTab, setActiveTab] = useState(activeTabIndex || 0);
  const titlesRef = useRef();
  const initialScrollGap = useRef(0);

  const computeScrollGap = () => {
    if (initialScrollGap.current) {
      return initialScrollGap.current;
    }
    const tabLeft =
      titlesRef.current?.children?.[0]?.getBoundingClientRect()?.left || 0;
    const titlesLeft = titlesRef.current?.getBoundingClientRect()?.left || 0;

    initialScrollGap.current = tabLeft - titlesLeft;

    return initialScrollGap.current;
  };

  const moveToActiveTab = () => {
    computeScrollGap();

    const activeTabWithShadow = activeTab + (centerTitles ? activeTab : 0);

    const tabLeft =
      titlesRef.current?.children?.[
        activeTabWithShadow
      ]?.getBoundingClientRect()?.left || 0;

    const titlesLeft = titlesRef.current?.getBoundingClientRect()?.left || 0;

    const scrollLeft = tabLeft - titlesLeft - initialScrollGap.current;

    titlesRef.current?.scrollBy(scrollLeft, 0);
  };

  useEffect(() => {
    onActiveTabIndexChange?.(activeTab);
    moveToActiveTab();
  }, [activeTab]);

  useEffect(() => {
    setActiveTab(0);
  }, [selectedIndex]);

  return (
    <div {...rest}>
      {visibleTitles && (
        <MenuContainerStyled ref={titlesRef}>
          {items.map((item, index) => (
            <Fragment key={index}>
              {centerTitles && index !== 0 && (
                <div
                  style={{
                    width: "100%",
                    cursor: "pointer",
                    padding: 0,
                    margin: 0,
                  }}
                />
              )}
              <TitleActiveStyled
                active={index === activeTab}
                onClick={() => {
                  if (index !== activeTab && items[index]?.ContentComponent) {
                    setActiveTab(index);
                  }
                }}
              >
                {item.TitleComponent}
              </TitleActiveStyled>
            </Fragment>
          ))}
        </MenuContainerStyled>
      )}
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          maxHeight: "100%",
          overflowY: "auto",
        }}
      >
        {items[activeTab]?.ContentComponent}
      </div>
    </div>
  );
};

const ButtonToShowMedia = ({ setOpenMediaModal, openMediaModal }) => (
  <ButtonContainerStyled
    onClick={() => {
      setOpenMediaModal(!openMediaModal);
    }}
  >
    <ChevronIcon
      width="30"
      height="30"
      color="white"
      style={{ transform: `rotate(${openMediaModal ? 0 : 180}deg)` }}
    />
  </ButtonContainerStyled>
);

const TitleMediaStyled = styled.h3`
  display: ${(props) => (props.isVisit ? "none" : "block")};
  text-align: center;
  color: ${(props) => props.theme.colors.white};
  margin-bottom: 0;
  margin-top: 1em;
`;

const ButtonBackActivities = styled.button`
  padding: 0.7em;
  border-radius: 2em;
  border: 1px solid black;
  background: linear-gradient(
    rgba(255, 255, 255, 0.08) 0%,
    rgba(255, 255, 255, 0) 100%
  );
  width: 60%;
  font-size: 1.2rem;
  font-weight: 800;
  color: white;
`;

const ListOfVisitStyled = styled.ol`
  padding: 2rem 4rem 2rem 4rem;
  height: 100%;
  margin-bottom: 0;
  counter-reset: li;
  /* Initiate a counter */
  list-style: none;
  /* Remove default numbering */
  *list-style: decimal;
  /* Keep using default numbering for IE6/7 */

  li > :first-child::before {
    background-color: ${(props) => props.color};
  }
`;

const ListItemStyled = styled.li`
  margin-left: 0;
  color: ${(props) =>
    props.selected ? props.theme.colors.darkGrey : props.theme.colors.white};
  display: flex;
  margin-bottom: 1.75em;

  ::before {
    display: none;
  }

  > :first-child::before {
    counter-increment: li;
    content: counter(li);
    height: 35px;
    width: 35px;
    padding: 0.8em;
    border-radius: 2em;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: 1.3em;
    color: white;
    font-weight: 800;
    margin-right: 10px;
  }

  ${(props) =>
    props.selected &&
    `
    background-color: ${props.theme.colors.white};
    border-radius: 17px;
  `}
`;

const ItemsForVisitContainerStyled = styled.div`
  overflow-y: auto;
  text-align: left;
  max-height: 65%;
  width: 100%;
`;

const LoaderContainer = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;

  ${breakpoint("md")`
    position: absolute;
    height: 100%;
    width: 100%;
    backdrop-filter: blur(5px);
    > div {
      top: 50%;
      position: relative;
    }
 `}
`;

const EditModeButton = styled(PlusButton)`
  background-color: ${(props) => props.theme.colors.lightGrey90};
  border: 1px solid ${(props) => props.theme.colors.darkGrey};

  span {
    --cross-color: ${(props) => props.theme.colors.darkGrey};
    background-color: ${(props) => props.theme.colors.white};
  }
`;

const MoreInformationButtonStyled = styled.button`
  width: 35px;
  height: 35px;
  min-width: 35px;
  min-height: 35px;
  border-radius: 50%;
  border: 2px solid ${(props) => props.theme.colors.white};
  background-color: ${(props) =>
    props.selected ? props.theme.colors.white : "transparent"};
  padding: 0;
  display: flex;
  justify-content: center;

  > span {
    display: inline-block;
    width: 3px;
    height: 3px;
    border-radius: 50%;
    background-color: ${(props) =>
    props.selected ? props.theme.colors.darkGrey : props.theme.colors.white};
    margin: auto 2px;
  }
`;

const MoreInformationButton = (props) => (
  <MoreInformationButtonStyled {...props}>
    <span></span>
    <span></span>
    <span></span>
  </MoreInformationButtonStyled>
);

const Item = ({
  visit,
  editable = false,
  onEdit,
  onDelete,
  onRead,
  onMove,
  upArrowVisible = false,
  downArrowVisible = false,
}) => {
  const {i18n} = useTranslation();
  const [moreInformation, setMoreInformation] = useState(false);
  const textKey = `text_${i18n.language}`;

  return (
    <ListItemStyled selected={moreInformation}>
      {moreInformation ? (
        <>
          <div
            style={{
              display: "inline-flex",
              width: "100%",
              position: "relative",
            }}
          >
            <span
              style={{
                cursor: "pointer",
                width: editable ? "calc(100% - 40px)" : "100%",
                display: "inline-flex",
                alignItems: "center",
              }}
            >
              <span
                style={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "space-around",
                }}
              >
                <span
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    maxHeight: "20px",
                  }}
                >
                  <TriangleIcon
                    onClick={() => {
                      if (visit.hasOwnProperty("touristic_site_id")) {
                        onMove(
                          "activity",
                          visit.rank - 1,
                          visit.id,
                          null,
                          visit.touristic_site_id
                        );
                      } else if (visit.hasOwnProperty("activity_id")) {
                        onMove(
                          "visit_sheet",
                          visit.rank - 1,
                          visit.activity_id,
                          visit.id
                        );
                      }
                    }}
                    style={{
                      bottom: "40px",
                      display: `${upArrowVisible ? "block" : "none"}`,
                    }}
                  />
                  <TriangleIcon
                    onClick={() => {
                      if (visit.hasOwnProperty("touristic_site_id")) {
                        onMove(
                          "activity",
                          visit.rank + 1,
                          visit.id,
                          null,
                          visit.touristic_site_id
                        );
                      } else if (visit.hasOwnProperty("activity_id")) {
                        onMove(
                          "visit_sheet",
                          visit.rank + 1,
                          visit.activity_id,
                          visit.id
                        );
                      }
                    }}
                    style={{
                      rotate: "180deg",
                      top: 40,
                      display: `${downArrowVisible ? "block" : "none"}`,
                    }}
                  />
                </span>
                <div
                  style={{
                    display: "flex",
                    gap: "5px",
                    alignItems: "center",
                  }}
                >
                  <ModifierIcon
                    style={{
                      cursor: "pointer",
                      borderRadius: "70%",
                      backgroundColor: "gray",
                      color: "gray",
                      fill: "white",
                      width: "20px",
                      height: "20px",
                      padding: "3px",
                    }}
                  />
                  <span
                    onClick={() => onEdit?.(visit)}
                    style={{
                      cursor: "pointer",
                      display: "flex",
                      textAlign: "center",
                      verticalAlign: "middle",
                    }}
                  >
                    Modifier
                  </span>
                </div>
                <div
                  style={{
                    display: "flex",
                    gap: "5px",
                    alignItems: "center",
                  }}
                >
                  <PlusIcon
                    style={{
                      rotate: "45deg",
                      backgroundColor: "gray",
                    }}
                  />
                  <span
                    onClick={() => onDelete?.(visit)}
                    style={{
                      cursor: "pointer",
                      display: "inline-block",
                      textAlign: "center",
                    }}
                  >
                    Supprimer
                  </span>
                </div>
              </span>
            </span>
          </div>
        </>
      ) : (
        <span
          onClick={() => onRead?.(visit)}
          style={{
            cursor: "pointer",
            width: editable ? "calc(100% - 40px)" : "100%",
            display: "inline-block",
          }}
        >
          {visit.title?.[textKey] || visit.title?.text_fr || visit.title || visit?.visit_sheet_contents[0]?.title}
        </span>
      )}
      {editable && (
        <div style={{ display: "inline-block", marginLeft: 5 }}>
          <MoreInformationButton
            selected={moreInformation}
            onClick={() => {
              setMoreInformation(!moreInformation)
            }}
          />
        </div>
      )}
    </ListItemStyled>
  );
};

const ActivityFormContainer = styled.div`
  padding: 2em;
  text-align: center;

  > h2 {
    font-size: calc(0.75rem + 0.75vw);
    color: ${(props) => props.theme.colors.white};
  }
`;

const DayActivityDetailsWithContexts = ({
  editable,
  type,
  visitModalIsOpen,
  setVisitModalIsOpen,
  finalLap,
  cardToFix,
}) => {
  const { activity } = useContext(ActivityContext);
  const { interestPoint } = useContext(InterestPointContext);

  if (type === 'activity' && activity.map_id && !editable) {
    return (
      <Map
        key={`map-${activity.map_id}`}
        mapId={activity.map_id}
        style={{
          width: '100%',
          height: '100%'
        }}
      />
    );
  }

  return (
    <DayActivityDetails
      editable={editable}
      sites={type === "interestPoint" ? [interestPoint] : [activity]}
      visitModalIsOpen={visitModalIsOpen}
      setVisitModalIsOpen={setVisitModalIsOpen}
      isNotHome={true}
      finalLap={finalLap}
      selectedIndex={0}
      cardToFix={cardToFix}
      fullDetails={true}
      showAllVisitCards={true}
      style={{
        maxWidth: 1200,
        margin: "0 auto",
      }}
    />
  );
};

const ItemsForVisit = ({
  items,
  isNotHome,
  isVisit,
  color = "orange",
  setSiteToVisualize,
  siteToVisualize,
  editable = false,
}) => {
  const [visitModalIsOpen, setVisitModalIsOpen] = useState(false);
  const [cardToFix, setCardToFix] = useState(false);
  const [finalLap, setFinalLap] = useState(false);
  const [visitSheetToVisualize, setVisitSheetToVisualize] = useState([]);
  const [handleRequest, status] = useRequest();
  const config = useSelector((state) => state.data);
  const [loading, setLoading] = useState(false);
  const [title, setTitle] = useState("");
  const [addCard, setAddCard] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const { touristicSite, setTouristicSite } = useContext(TouristicSiteContext);
  const { activity, setActivity } = useContext(ActivityContext);
  const { i18n } = useTranslation();
  const textKey = `text_${i18n.language}`;

  // set card to fix permet d'indiquer a DayActivityDetails qu'il faut afficher le medias des visites
  // setVisitSheetToVisualize permet de recuperer la data
  const openCardVisit = (e) => {
    setLoading(true);
    handleRequest(
      "get",
      API_GET_ACTIVITY(e.uuid, localStorage.getItem('resourceAccessToken')),
      {},
      config,
      (data, status) => {
        if (status === "Done") {
          setLoading(false);
          setTitle(e.title);
          setAddCard(true);
          setVisitSheetToVisualize({ ...data });
          setCardToFix(true);
          setVisitModalIsOpen(true);
          setFinalLap(isNotHome && isVisit && true);
        } else if (status === "Error") {
          console.error(data);
        }
      }
    );
  };

  const openInterestPoint = (e) => {
    setTitle(e.title);
    setAddCard(true);
    setSiteToVisualize({ ...e });
    setCardToFix(true);
    setFinalLap(isNotHome && isVisit && true);
    setVisitModalIsOpen(true);
  };
  // Ici j'ai géré la possibilité de pouvoir click sur un element et de gerer l'affichage.

  const deleteVisit = ({ id }) => {
    const token = localStorage.getItem("Authorization");

    setLoading(true);
    fetch(API_DELETE_ACTIVITY(id), {
      method: "DELETE",
      cache: "default",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      mode: "cors",
    })
      .then((res) => res.json())
      .then((response) => {
        setTouristicSite((prev) => ({
          ...prev,
          activities: [...prev.activities.filter((act) => act.id !== id)],
        }));
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      });
  };

  const deleteVisitSheet = ({ id }) => {
    const token = localStorage.getItem("Authorization");

    setLoading(true);
    fetch(API_DELETE_VISIT_SHEET(id), {
      method: "DELETE",
      cache: "default",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      mode: "cors",
    })
      .then((res) => res.json())
      .then((response) => {
        setActivity(response);
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      });
  };

  //call api rank edit

  const editRank = (
    type,
    rank,
    activity_id = null,
    visit_sheet_id = null,
    touristic_site_id = null
  ) => {
    const token = localStorage.getItem("Authorization");
    let url = null;
    if (type === "visit_sheet") {
      url = API_MOVE_VISIT_SHEET(activity_id, visit_sheet_id);
    } else if (type === "activity") {
      url = API_MOVE_ACTIVITY(touristic_site_id, activity_id);
    }
    setLoading(true);
    fetch(url, {
      method: "PATCH",
      cache: "default",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      mode: "cors",
      body: JSON.stringify({
        rank: rank,
      }),
    })
      .then((res) => res.json())
      .then((response) => {
        if (type === "activity") {
          setTouristicSite(response)
        } else if (type === "visit_sheet") {
          setActivity(response);

        }
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      });
  };

  const visitSheet = activity?.visit_sheets?.find(
    (vs) => vs.id === siteToVisualize?.[0]?.id
  );

  return (
    <>
      <ItemsForVisitContainerStyled>
        {editMode ? (
          !(isNotHome && isVisit) ? (
            <ActivityProvider
              initialActivity={{
                touristic_site_id: touristicSite.id,
                ...editMode,
              }}
            >
              <ActivityFormContainer>
                <h2>
                  {editMode.id
                    ? "Modification d’une visite"
                    : "Création d’une visite"}
                </h2>
                <ActivityForm
                  onSuccess={() => setEditMode(false)}
                  onCancel={() => setEditMode(false)}
                />
              </ActivityFormContainer>
            </ActivityProvider>
          ) : (
            <InterestPointProvider
              initialInterestPoint={{ activity_id: activity?.id, ...editMode }}
            >
              <ActivityFormContainer>
                <h2>
                  {editMode.id
                    ? "Modification d’un point d’intérêt"
                    : "Création d’un point d’intérêt"}
                </h2>
                <InterestPointForm
                  onSuccess={() => setEditMode(false)}
                  onCancel={() => setEditMode(false)}
                />
              </ActivityFormContainer>
            </InterestPointProvider>
          )
        ) : (
          <ListOfVisitStyled color={color}>
            {items?.sort((a, b) => a.rank - b.rank).map((visit, index) => (
              <Item
                key={`${index}-${visit.id}`}
                editable={editable}
                visit={visit}
                upArrowVisible={index !== 0}
                downArrowVisible={index !== items.length - 1}
                onRead={() =>
                  isNotHome && isVisit
                    ? openInterestPoint(visit)
                    : openCardVisit(visit)
                }
                onEdit={() => setEditMode({ ...visit })}
                onDelete={isNotHome && isVisit ? deleteVisitSheet : deleteVisit}
                onMove={editRank}
              />
            ))}
            {editable && (
              <EditModeButton
                key={items?.length}
                rounded
                onClick={() => setEditMode({})}
              >
                <PlusIcon />
              </EditModeButton>
            )}
          </ListOfVisitStyled>
        )}
      </ItemsForVisitContainerStyled>
      {loading ? (
        <LoaderContainer>
          <Loader />
        </LoaderContainer>
      ) : (
        visitModalIsOpen &&
        loading !== true && (
          <FullScreenNavigationModal
            isOpen={visitModalIsOpen !== false}
            onRequestClose={() => setVisitModalIsOpen(false)}
            TitleComponent={<h1>{title?.[textKey] || title?.text_fr || title}</h1>}
            headerProps={{
              style: {
                width: "100%",
                maxWidth: 1200,
                alignSelf: "center",
                zIndex: "10000",
              },
            }}
          >
            {isNotHome && isVisit ? (
              visitSheet && (
                <InterestPointProvider
                  initialInterestPoint={{
                    activity_id: activity?.id,
                    ...(visitSheet || {}),
                  }}
                >
                  <DayActivityDetailsWithContexts
                    editable={editable}
                    type={"interestPoint"}
                    visitModalIsOpen={visitModalIsOpen}
                    setVisitModalIsOpen={setVisitModalIsOpen}
                    finalLap={finalLap}
                    cardToFix={cardToFix}
                  />
                </InterestPointProvider>
              )
            ) : (
              <ActivityProvider
                initialActivity={{
                  touristic_site_id: touristicSite.id,
                  ...visitSheetToVisualize,
                }}
              >
                <DayActivityDetailsWithContexts
                  editable={editable}
                  type={"activity"}
                  visitModalIsOpen={visitModalIsOpen}
                  setVisitModalIsOpen={setVisitModalIsOpen}
                  finalLap={finalLap}
                  cardToFix={cardToFix}
                />
              </ActivityProvider>
            )}
          </FullScreenNavigationModal>
        )
      )}
    </>
  );
};

const VisitContainerStyled = styled.div`
  display: block;
  position: fixed;
  top: ${(props) => props.topMenu}px;
  transform: translateY(
    ${(props) =>
    props.topState < -props.topMenu ? -props.topMenu : props.topState}px
  );
  height: calc(
    ${(props) => (props.isVisit ? `100vh - 15%` : "100%")} -
      env(safe-area-inset-bottom)
  );
  box-sizing: border-box;
  width: 100%;
  z-index: 2;
  animation: ${(props) => (props.openMediaModal ? slidein : visitSlideout)};
  animation-duration: 1s;
  animation-fill-mode: forwards;

  ${breakpoint("md")`
    top: 11%;
    transform: translateY(${(props) =>
      props.topState < -props.topMenu
        ? -props.topMenu
        : props.topState + 50}px);
    height: 67%;
    box-sizing: border-box;
    width: 41%;
    left: 10%;
    transform: none;
    position: fixed;
    animation: none;
  `}
`;

const HeaderVisitStyled = styled.div`
  background: linear-gradient(
    rgba(255, 255, 255, 0.08) 0%,
    rgba(255, 255, 255, 0) 100%
  );
  padding-top: 0.8em;
  padding-bottom: 0.8em;
  display: ${(props) => (props.isMedia ? "none" : "block")};
  border: 2px solid black;
  margin-top: 3em;
  cursor: pointer;
  p {
    color: ${(props) => props.active && props.theme.colors.white};
  }
`;

const TitleStyled = styled.div`
  text-align: center;
  > h4 {
    color: white;
  }
`;

const VisitHeader = ({ isNotHome, isVisit, title, sites }) => {
  const [visitModalIsOpen, setVisitModalIsOpen] = useState(false);
  const [siteToVisualize, setSiteToVisualize] = useState(null);
  const [cardToFix, setCardToFix] = useState(false);
  const [finalLap, setFinalLap] = useState(false);
  const [addCard, setAddCard] = useState(false);

  const onClickOpenAll = () => {
    setCardToFix(true);
    setVisitModalIsOpen(true);
    setFinalLap(isNotHome && isVisit);
    setAddCard(true);
    setSiteToVisualize(sites);
  };

  const {i18n} = useTranslation();
  const textKey = `text_${i18n.language}`;

  return (
    <>
      <HeaderVisitStyled>
        <TitleStyled onClick={() => onClickOpenAll()}>
          <h4>{title?.[textKey] || title?.text_fr || title}</h4>
        </TitleStyled>
      </HeaderVisitStyled>
      {visitModalIsOpen && (
        <FullScreenNavigationModal
          isOpen={visitModalIsOpen !== false}
          onRequestClose={() => setVisitModalIsOpen(false)}
          TitleComponent={
            <h1>
              {siteToVisualize?.length >= 1
                ? siteToVisualize[0].title
                : siteToVisualize.title}
            </h1>
          }
          headerProps={{
            style: {
              width: "100%",
              maxWidth: 1200,
              alignSelf: "center",
            },
          }}
        >
          <DayActivityDetails
            sites={siteToVisualize || []}
            addCard={addCard}
            visitModalIsOpen={visitModalIsOpen}
            setVisitModalIsOpen={setVisitModalIsOpen}
            isNotHome={true}
            finalLap={finalLap}
            selectedIndex={0}
            cardToFix={cardToFix}
            fullDetails={true}
            showAllVisitCards={true}
            style={{
              maxWidth: 1200,
              margin: "0 auto",
            }}
          />
        </FullScreenNavigationModal>
      )}
    </>
  );
};

const BackButtonContainer = styled.div`
  top: 20px;

  ${breakpoint("md")`
    padding: 0;
    width: 80%;
  `}

  button {
    display: block;
    margin: auto;
  }
`;

const MediaToModify = ({
  topMenu,
  topState,
  openMediaModal,
  isMedia,
  isVisit,
  isNotHome,
  items,
  selectedIndex,
  setOpenMediaModal,
  editable = true,
  ...rest
}) => (
  <MediaToShowStyled
    topMenu={topMenu}
    topState={topState}
    openMediaModal={openMediaModal}
    isMedia={isMedia}
    isVisit={isVisit}
  >
    <InformationsMedias
      isMedia={isMedia}
      openMediaModal={openMediaModal}
      editable={editable}
    >
      <HeaderTouristicSite>
        <TitleMediaStyled>Les médias</TitleMediaStyled>
      </HeaderTouristicSite>
      <InteractionToShow isVisit={isVisit} isNotHome={isNotHome}>
        <ButtonToShowMedia
          openMediaModal={openMediaModal}
          setOpenMediaModal={setOpenMediaModal}
        />
      </InteractionToShow>
      <TabbedMedias
        items={items || []}
        isMedia={isMedia}
        selectedIndex={selectedIndex}
      />
    </InformationsMedias>
  </MediaToShowStyled>
);

const MediaToDisplay = ({
  topMenu,
  topState,
  openMediaModal,
  isMedia,
  isVisit,
  isNotHome,
  items,
  selectedIndex,
  setOpenMediaModal,
  ...rest
}) => (
  <MediaToShowStyled
    topMenu={topMenu}
    topState={topState}
    openMediaModal={openMediaModal}
    isMedia={isMedia}
    isVisit={isVisit}
    {...rest}
  >
    <InformationsMedias isMedia={isMedia} openMediaModal={openMediaModal}>
      <HeaderTouristicSite>
        <TitleMediaStyled>Les médias</TitleMediaStyled>
      </HeaderTouristicSite>
      <InteractionToShow isVisit={isVisit} isNotHome={isNotHome}>
        <ButtonToShowMedia
          openMediaModal={openMediaModal}
          setOpenMediaModal={setOpenMediaModal}
        />
      </InteractionToShow>
      <TabbedMedias
        items={items || []}
        isMedia={isMedia}
        selectedIndex={selectedIndex}
      />
    </InformationsMedias>
  </MediaToShowStyled>
);

const BackButton = ({ setSelectedIndex, label }) => (
  <BackButtonContainer>
    <ButtonBackActivities onClick={() => setSelectedIndex(0)}>
      {label}
    </ButtonBackActivities>
  </BackButtonContainer>
);

const VisitToDisplay = ({
  topMenu,
  topState,
  setOpenMediaModal,
  openMediaModal,
  isMedia,
  isVisit,
  setFinalLap,
  isNotHome,
  SiteToChoose,
  setIsNotHome,
  setSelectedIndex,
  setSiteToVisualize,
  siteToVisualize,
  finalLap,
  editable = false,
  ...rest
}) => {
  const theme = useContext(ThemeContext);

  return (
    <VisitContainerStyled
      topMenu={topMenu}
      topState={topState}
      setOpenMediaModal={setOpenMediaModal}
      openMediaModal={openMediaModal}
      isMedia={isMedia}
      isVisit={isVisit}
      {...rest}
    >
      <InformationsMedias
        isMedia={isVisit}
        isVisit={isVisit}
        openMediaModal={openMediaModal}
      >
        <HeaderTouristicSite isVisit={isVisit}>
          {
            isNotHome && isVisit && (
              <VisitHeader
                title={
                  isNotHome && isVisit
                    ? "Voir tous les points d'intérêt"
                    : "Voir toutes les visites"
                }
                sites={SiteToChoose}
                isNotHome={isNotHome}
                isVisit={isVisit}
              />
            )
          }
        </HeaderTouristicSite>
        <InteractionToShow isVisit={isVisit} isNotHome={isNotHome}>
          <ButtonToShowMedia
            openMediaModal={openMediaModal}
            setOpenMediaModal={setOpenMediaModal}
          />
        </InteractionToShow>
        <ItemsForVisit
          setSiteToVisualize={setSiteToVisualize}
          siteToVisualize={[siteToVisualize]}
          isNotHome={isNotHome}
          isVisit={isVisit}
          editable={editable}
          items={SiteToChoose}
          color={
            isNotHome && isVisit
              ? theme.colors.interestPoint
              : theme.colors.activity
          }
        />
        <BackButton
          label={
            isNotHome && isVisit ? "Retour à la visite" : "Retour à l'activité"
          }
          setSelectedIndex={setSelectedIndex}
        />
      </InformationsMedias>
    </VisitContainerStyled>
  );
};

const InterestPointsToDisplay = ({
  topMenu,
  isMedia,
  finalLap,
  selectedIndex,
  isVisit,
  interestPoints,
  items,
  topState,
  setOpenMediaModal,
  openMediaModal,
  isNotHome,
  editable = false,
  ...rest
}) => (
  <VisitContainerStyled
    topMenu={topMenu}
    topState={topState}
    setOpenMediaModal={setOpenMediaModal}
    openMediaModal={openMediaModal}
    isMedia={isNotHome}
    isVisit={isNotHome}
    {...rest}
  >
    <InformationsMedias isMedia={isNotHome} openMediaModal={openMediaModal}>
      <HeaderTouristicSite>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <InteractionToShow isVisit={isVisit} isNotHome={isNotHome}>
            <ButtonToShowMedia
              openMediaModal={openMediaModal}
              setOpenMediaModal={setOpenMediaModal}
            />
          </InteractionToShow>
        </div>
      </HeaderTouristicSite>
      <TabbedMedias
        items={items || []}
        siteToChoose={interestPoints}
        isMedia={isMedia}
        finalLap={finalLap}
        selectedIndex={selectedIndex}
      ></TabbedMedias>
    </InformationsMedias>
  </VisitContainerStyled>
);

const MediaToShow = ({
  items,
  editable,
  selectedIndex,
  finalLap,
  setFinalLap,
  setIsNotHome,
  isNotHome,
  setIsMedia,
  experience = false,
  sites,
  topMenu,
  topState,
  setOpenMediaModal,
  openMediaModal,
  isMedia,
  setSelectedIndex,
  isInterest = false, //Not used ?
  isVisit = false,
  ...rest
}) => {
  const [siteToVisualize, setSiteToVisualize] = useState(null);
  const { activity } = useContext(ActivityContext);

  /* Jbouille notes
   **
   ** if finalLap => interestPoint
   ** if !finalLap && isNotHome => visit/activity
   ** if !finalLap && !isNotHome => touristicSite
   */

  const {i18n} = useTranslation();
  const textKey = `text_${i18n.language}`;

  // If compatibility with old layout break, it's here (check git history)
  const siteWrapper =
    activity?.visit_sheets?.map((visitSheet, index) => ({
      ...visitSheet,
      title:
        visitSheet.title ||
        visitSheet.visit_sheet_contents?.[0]?.title ||
        "Sans titre",
      description:
        visitSheet.visit_sheet_contents?.[0]?.description || "",
      text: [], //visitSheetText
    })) || [];

  return (
    (isMedia && !isNotHome && (
      <MediaToDisplay
        topMenu={topMenu}
        topState={topState}
        openMediaModal={openMediaModal}
        isMedia={isMedia}
        selectedIndex={selectedIndex}
        isVisit={isVisit}
        isNotHome={isNotHome}
        items={items}
        setOpenMediaModal={setOpenMediaModal}
        {...rest}
      />
    )) ||
    (isVisit && (
      <VisitToDisplay
        editable={editable}
        setOpenMediaModal={setOpenMediaModal}
        openMediaModal={openMediaModal}
        isMedia={isMedia}
        isVisit={isVisit}
        isNotHome={isNotHome}
        topState={topState}
        topMenu={topMenu}
        selectedIndex={selectedIndex}
        siteToVisualize={siteToVisualize}
        setSiteToVisualize={setSiteToVisualize}
        setIsNotHome={setIsNotHome}
        setSelectedIndex={setSelectedIndex}
        SiteToChoose={
          siteWrapper.length !== 0
            ? siteWrapper
            : sites[selectedIndex - 1]?.activities
        }
      />
    )) ||
    (isNotHome && (
      <InterestPointsToDisplay
        editable={editable}
        topState={topState}
        topMenu={topMenu}
        isNotHome={isNotHome}
        isVisit={isVisit}
        openMediaModal={openMediaModal}
        isMedia={isMedia}
        selectedIndex={selectedIndex}
        setOpenMediaModal={setOpenMediaModal}
        items={items}
        interestPoints={sites[selectedIndex - 1]?.visit_sheets}
        finalLap={finalLap}
      />
    )) ||
    (editable && (
      <MediaToModify
        topMenu={topMenu}
        topState={topState}
        openMediaModal={openMediaModal}
        isMedia={isMedia}
        selectedIndex={selectedIndex}
        isVisit={isVisit}
        isNotHome={isNotHome}
        items={items}
        setOpenMediaModal={setOpenMediaModal}
        style={{ height: "100%" }}
        editable={editable}
      />
    ))
  );
};

export default MediaToShow;
