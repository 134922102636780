import React from "react";
import { Button } from 'components/form/button';
import Title from 'components/header/title/title';
import QuoteTemplate from 'components/ambassador/quote/quoteTemplate';
import './AmbassadorSectionsMiddle.scss';

const content = [{
    description: "Gagne des activités gratuites tous les mois et pars à la découverte de toute la France.",
    image: "https://general-henri-1-paris.s3.fr-par.scw.cloud/ambassadors/france.png"
    },
    {
        description: "Vis des aventures uniques avec Henri Trip ton nouveaux compagnon de voyage 2.0",
        image: "https://general-henri-1-paris.s3.fr-par.scw.cloud/ambassadors/instagram-post.png"
    },
    {
        description: "Partage tes expériences sur les réseaux sociaux et développe ta communauté.",
        image: "https://general-henri-1-paris.s3.fr-par.scw.cloud/ambassadors/reseaux-sociaux.png"
    },
]

const AmbassadorSectionsMiddle = () =>  (
    <div className="container mt-3">
        <Title title="Alors rejoins l'aventure et pars en voyage gratuitement tous les mois avec Henri Trip"/>
        <QuoteTemplate content="Notre seule liberté est de rêver. Rêvons ensemble du patrimoine français." author="Henri Trip"/>
        <div className="row mt-md-3">
            {
                    content?.map((el, i) =>
                        <div className="col-12 col-md-4">
                                <div className="row mt-2">
                                    <div className="col-4 col-md-12 d-flex justify-content-center">
                                        <img src={el.image} alt="ambassador-middle__card" className="ambassador-middle__card-image"/>
                                    </div>
                                    <div className="col-8 col-md-12 mt-3 mt-md-2">
                                        <p className="ambassador-middle__text">{el.description}</p>
                                    </div>
                                </div>
                        </div>
                    )
            }
                <div className="col d-flex justify-content-center mt-4">
                        <Button rounded className="px-3" title="Candidater" onClick={() => window.open("https://forms.office.com/r/gh46npYQ6S", '_blank')}/>
                </div>
        </div>
    </div>
)

export default AmbassadorSectionsMiddle;
