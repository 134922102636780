import React from "react";
import {toAbsoluteUrl} from "../../../utils/utils";
import Title from 'components/header/title/title';
import "./PartnerSectionsActivity.scss"
import SVG from "react-inlinesvg";
import { useTranslation } from "react-i18next";



const PartnerSectionsActivity = () => {

  const {t} = useTranslation(["henriTrip"])

  const categories = [
    {
      title: t("partner.activitySections.category.title1"),
      subtitle: "",
      color: "#ef7e26",
      icon: "assets/icons/Fichier4.svg",
      id: "activityId"
    },
    {
      title: t("partner.activitySections.category.title2"),
      subtitle: "",
      color: "#f7a38e",
      icon: "assets/icons/Fichier3.svg",
      id: "wellnessId"
    },
    {
      title: t("partner.activitySections.category.title3"),
      subtitle: t("partner.activitySections.category.subtitle3"),
      color: "#ffbb00",
      icon: "assets/icons/Fichier12.svg",
      id: "museumId"
    },
    {
      title: t("partner.activitySections.category.title4"),
      subtitle: t("partner.activitySections.category.subtitle4"),
      color: "#a42422",
      icon: "assets/icons/Fichier7.svg",
      id: "wineTourismId"
    },
    {
      title: t("partner.activitySections.category.title5"),
      subtitle: t("partner.activitySections.category.subtitle5"),
      color: "#e3cb9b",
      icon: "assets/icons/Fichier10.svg",
      id: "religiousId"
    },
    {
      title: t("partner.activitySections.category.title6"),
      subtitle: "",
      color: "#004b8c",
      icon: "assets/icons/Fichier9.svg",
      id: "offreId"
    },
    {
      title: t("partner.activitySections.category.title7"),
      subtitle: t("partner.activitySections.category.subtitle7"),
      color: "#00a544",
      icon: "assets/icons/Fichier13.svg",
      id: "parkId"
    },
    {
      title: t("partner.activitySections.category.title8"),
      subtitle: t("partner.activitySections.category.subtitle8"),
      color: "#967db3",
      icon: "assets/icons/Fichier14.svg",
      id: "amusementParkId"
    },
    {
      title: t("partner.activitySections.category.title9"),
      subtitle: t("partner.activitySections.category.subtitle9"),
      color: "#9e7241",
      icon: "assets/icons/Fichier6.svg",
      id: "grottoChasmId"
    },
    {
      title: t("partner.activitySections.category.title10"),
      subtitle: t("partner.activitySections.category.subtitle10"),
      color: "#4da1c4",
      icon: "assets/icons/Fichier8.svg",
      id: "castleId"
    },
  ]

  return (
    <div className="container">
      <Title noTopPad={true} title={t("partner.activitySections.title")}/>
      <div className="row">
        {
          categories.map((el, i) =>
            <div className="activity">
              <button onClick={() => window.document.getElementById(el.id).scrollIntoView({block: "center"})} className="rounded-lg btn button-style font-weight-medium w-40" style={{backgroundColor: el.color, marginBottom: "2rem"}}>
                <div className="row align-items-center my-1">
                  <div className="pl-1 pl-md-2">
                    <SVG style={{color: "white"}} src={toAbsoluteUrl(el.icon)} height="26" width="26"/>
                  </div>
                  <div className="pl-0 pl-md-1 w-75">
                    <p className="text-white mb-0">{el.title}</p>
                    { el.subtitle ? <p className="text-white">{el.subtitle}</p> : "" }
                  </div>
                </div>
              </button>
            </div>
          )
        }
      </div>
      <div className="row">
        <div className="col">
          <h4>{t("partner.activitySections.description")}</h4>
        </div>
      </div>
    </div>
  )
};
export default PartnerSectionsActivity;
