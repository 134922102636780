import styled from 'styled-components';

const Form = styled.form`
  text-align: left;
  
  > * {
    margin: .75em 0;
  }
`;

export default Form;