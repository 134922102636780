const HENRI_MAP_FRONT_URL = process.env.REACT_APP_HENRI_MAP_FRONT_URL;

const maps = {
  show: (id) => `${HENRI_MAP_FRONT_URL}/map/${id}`,
};

const henriMapFrontUrl = {
  maps,
};

export default henriMapFrontUrl;
