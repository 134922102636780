import styled from 'styled-components';
import {Fragment, useEffect, useRef, useState} from "react";

const TabbedNavigationStyled = styled.div`
  box-sizing: border-box;
  width: 100%;
`;

const TabbedNavigationTitles = styled.div`
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  padding: 0 12px;
  margin-bottom: 1rem;
  max-width: 100%;
  overflow-x: scroll;
  scroll-behavior: smooth;
  
  -ms-overflow-style: none;

  &::-webkit-scrollbar{
    display: none;
  }
`;

const TabbedNavigationTitle = styled.div`
  display: inline-block;
  display: flex;
  position: relative;
  box-sizing: border-box;
  
  padding: 5px 12px;
  align-items: center;
  cursor: pointer;
  margin: 0 1rem;
  color: ${props => props.theme.colors.darkGrey90};
  
  ${props => props.active && `
  color: ${props.theme.colors.primary};
  font-weight: 700;
  
  &::after {
    position: absolute;
    display: block;
    content: '';
    width: 100%;
    height: 4px;
    bottom: 0;
    left: 0;
    border-radius: 2px;
    background-color: ${props.theme.colors.primary};
  }
  `}
`;

const TabbedNavigationContent = styled.div`
  box-sizing: border-box;
  padding: 5px 12px;
`;

const TabbedNavigation = ({activeTabIndex, onActiveTabIndexChange, items, centerTitles, visibleTitles = true, ...rest}) => {
  const [activeTab, setActiveTab] = useState(activeTabIndex || 0);
  const titlesRef = useRef();
  const initialScrollGap = useRef(0);

  useEffect(()=>{
    setActiveTab(activeTabIndex || 0);
  },[activeTabIndex]);

  const computeScrollGap = () => {
    if (initialScrollGap.current) {
      return (initialScrollGap.current);
    }
    const tabLeft = titlesRef.current?.children?.[0]?.getBoundingClientRect()?.left || 0;
    const titlesLeft = titlesRef.current?.getBoundingClientRect()?.left || 0;

    initialScrollGap.current = tabLeft - titlesLeft;

    return (initialScrollGap.current);
  };

  const moveToActiveTab = () => {
    computeScrollGap();

    const activeTabWithShadow = activeTab + (centerTitles ? activeTab : 0);

    const tabLeft = (titlesRef.current?.children?.[activeTabWithShadow]?.getBoundingClientRect()?.left || 0)

    const titlesLeft = titlesRef.current?.getBoundingClientRect()?.left || 0;

    const scrollLeft = tabLeft - titlesLeft - initialScrollGap.current;

    titlesRef.current?.scrollBy(scrollLeft, 0);
  };

  useEffect(() => {
    onActiveTabIndexChange?.(activeTab);
    moveToActiveTab();
  }, [activeTab]);

  return (
    <TabbedNavigationStyled {...rest}>
      {
        visibleTitles && (
          <TabbedNavigationTitles ref={titlesRef}>
            {
              items.map((item, index) => (
                <Fragment key={index}>
                  {centerTitles && index !== 0 && (
                    <div
                      style={{
                        width: '100%',
                        cursor: 'auto',
                        padding: 0,
                        margin: 0,
                      }}
                    />
                  )}
                  <TabbedNavigationTitle
                    active={index === activeTab}
                    onClick={() => {
                      if (index !== activeTab) {
                        setActiveTab(index);
                      }
                    }}
                  >
                    { item.TitleComponent }
                  </TabbedNavigationTitle>
                </Fragment>
              ))
            }
          </TabbedNavigationTitles>
        )
      }
      <TabbedNavigationContent>
        { items[activeTab]?.ContentComponent }
      </TabbedNavigationContent>
    </TabbedNavigationStyled>
  );
};

export default TabbedNavigation;