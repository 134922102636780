// Categories

import { removeAccents } from "./utils";

export const API_GET_CATEGORIES = "/api/v1/categories";

export const API_SEARCH_CITIES = (name) => {
  const url = new URLSearchParams();

  url.append("name", name);

  return `/api/v1/location/search?${url}`;
};

//Stripe

export const API_STRIPE_PAYMENT_INTENT = "/api/v1/create-payment-intent";

export const API_STRIPE_PAYMENT_INTENT_V2 = "/api/v1/create-payment-intent-v2";

export const API_STRIPE_PROMOTION_CODES = "/api/v1/promotion_codes";

export const API_IS_USER_CONNECTED = "/api/v1/users/is_connected";

// Auth

export const API_LOGIN = "/api/v1/users/login";

export const API_REGISTER = "/api/v1/users";

export const API_GOOGLE_LOGIN = "/api/v1/users/google_login";

export const API_FACEBOOK_LOGIN = "/api/v1/users/facebook_login";

export const API_FORGOT_PASSWORD = "/api/v1/users/send_reset_password";

export const API_RESET_PWD_CHECK = (token) =>
  `/api/v1/users/reset_verify?token=${token}`;

export const API_RESET_PASSWORD = "/api/v1/users/reset_password";

export const API_GET_PROFILE = "/api/v1/users/profile";

export const API_MODIFY_PROFILE = `/api/v1/users/profile`;

export const API_MODIFY_USER_BY_ID = (userID) => `/api/v1/users?id=${userID}`;

export const API_CONFIRM_EMAIL = (token) =>
  `/api/v1/confirm_email?token=${token}`;

export const API_AMBASSADOR_PAYMENT = "/api/v1/ambassador/payments";

export const API_AMBASSADOR_PAYMENT_MAIL =
  "/api/v1/ambassador/payments_request";

// Feedbacks

export const API_CREATE_FEEDBACK_ACTIVITY = (id) =>
  `/api/v1/activities/${id}/review`;

export const API_CREATE_FEEDBACK_ROAD_TRIP = (id) =>
  `/api/v1/road_trips/${id}/review`;

export const API_CREATE_FEEDBACK_TOURISTIC_SITE = (id) =>
  `/api/v1/touristic_sites/${id}/review`;

export const API_SEND_SUGGESTIONS = "/api/v1/touristic_sites/suggestion";

export const API_SEND_PARTNERSHIP = "";

// Roadtrips

export const API_GET_HOME_ROADTRIPS = "/api/v1/road_trips?max_results=6";

export const API_GET_USER_ROADTRIPS = "/api/v1/user/road_trips";

export const API_GET_ROADTRIPS_FILTERS = ({
  number_days,
  latitude,
  longitude,
  distance,
  sort_type,
  sort_direction,
  title,
  current_page,
  category,
  category_roadtrip,
  roadtrip_type,
}) => {
  const url = new URLSearchParams();
  if (number_days) url.append("number_days", number_days);
  if (latitude) url.append("latitude", latitude);
  if (longitude) url.append("longitude", longitude);
  if (distance) url.append("distance", distance);
  if (sort_type) url.append("sort_type", sort_type);
  if (sort_direction) url.append("sort_direction", sort_direction);
  if (title) url.append("title", title);
  if (current_page) url.append("current_page", current_page);
  if (category) url.append("category", category);
  if (category_roadtrip) url.append("category_roadtrip", category_roadtrip);
  if (roadtrip_type) url.append("roadtrip_type", roadtrip_type);
  return `/api/v1/road_trips?${url}`;
};

export const API_GET_CUSTOM_TS_ROADTRIP = (id) => `/api/v1/custom_TS/${id}`;

export const API_GET_ROADTRIP = (id) => `/api/v1/road_trips/${id}`;

export const API_GET_ROADTRIP_PREMIUM = (id) =>
  `/api/v1/road_trip_premium/${id}`;

export const API_GET_EXPERIENCE = (id) => `/api/v1/experiences/${id}`;

export const API_GET_EXPERIENCES = "/api/v1/experiences";

export const API_GET_ROADTRIP_SUGGESTIONS = (id) =>
  `/api/v1/road_trips/${id}/suggestions`;

export const API_CREATE_ROADTRIP = "/api/v1/road_trips/create";

export const API_CREATE_ROADTRIP_ADD_TOURISTIC_SITE = `/api/v1/road_trips/create/add_site`;

export const API_DELETE_ROADTRIP = (id) => `/api/v1/road_trips/${id}`;

export const API_UPDATE_ROADTRIP = (id) => `/api/v1/road_trips/${id}/edit`;

export const API_ADD_SITE_ROADTRIP = (id) =>
  `/api/v1/road_trips/${id}/add_site`;

export const API_REMOVE_SITE_ROADTRIP = (id) =>
  `/api/v1/road_trips/${id}/remove_site`;

export const API_GET_FAVORITE = `/api/v1/road_trips/favorite`;

export const API_GET_ORDERS = `/api/v1/users/orders`;

export const API_GET_RESERVATIONS = `/api/v1/users/reservations`;

export const API_GET_TRANSACTIONS = `/api/v1/users/transactions`;

export const API_ADD_FAVORITE_ROADTRIP = (id) =>
  `/api/v1/road_trips/${id}/favorite/add`;

export const API_DELETE_FAVORITE_ROADTRIP = (id) =>
  `/api/v1/road_trips/${id}/favorite/delete`;

export const API_COPY_ROADTRIP = (id) => `/api/v1/road_trips/${id}/copy`;

export const API_EXPORT_ROADTRIP = (id) => `/api/v1/road_trips/${id}/export`;

export const API_STAT_ROADTRIP = (id) => {
  const url = new URLSearchParams();
  if (id === "all") return `/api/v1/road_trips/stats`;
  if (id) url.append("road_trip_id", id);
  return `/api/v1/road_trips/stats?${url}`;
};

export const API_GET_ROADTRIP_PASSWORD = (id) =>
  `/api/v1/road_trips/${id}/password`;

export const API_POST_CREATE_TS_ROADTRIP = (id) =>
  `/api/v1/road_trips/${id}/create_ts`;
// Touristic-site
export const API_CREATE_TOURISTIC_SITE = () =>
  `${process.env.REACT_APP_API_URL}/api/v1/touristic_sites`;
export const API_PATCH_TOURISTIC_SITE = (id) =>
  `${process.env.REACT_APP_API_URL}/api/v1/touristic_sites/${id}`;

export const API_UPDATE_TS_ROADTRIP = (id) => `/api/v1/touristic_sites/${id}`;

export const API_GET_TOURISTIC_SITE_PARTNER = `/api/v1/touristic_sites/partner`;

export const API_GET_TOURISTIC_SITE_DETAILS = (id, resourceAccessToken='') =>
  `/api/v1/touristic_sites/${id}/details${resourceAccessToken ? `?resource_access_token=${resourceAccessToken}` : ''}`;

export const API_ADD_TOURISTIC_SITE_TICKET_VIEW = (id) =>
  `/api/v1/touristic_sites/${id}/add_ticket_view`;

export const API_GET_TOURISTIC_SITE = (id) => `/api/v1/touristic_sites/${id}`;

export const API_GET_TOURISTIC_SITES = (
  longitude,
  latitude,
  sw,
  ne,
  max_results,
  start_date,
  end_date,
  place,
  category
) => {
  const url = new URLSearchParams();
  if (latitude) url.append("latitude", latitude);
  if (longitude) url.append("longitude", longitude);
  if (max_results) url.append("max_results", max_results);
  if (start_date && end_date) {
    url.append("start_date", start_date);
    url.append("end_date", end_date);
  }
  if (place) url.append("place", place);
  if (category) url.append("category", category);
  if (sw) url.append("sw", sw);
  if (ne) url.append("ne", ne);

  return `/api/v1/touristic_sites/nearby?${url}`;
};

export const API_GET_ALL_TOURISTIC_SITES = ({
  title_fr,
  latitude,
  longitude,
  sw,
  ne,
  category,
  current_page,
  per_page,
  order,
  homepage,
  insee,
}) => {
  const url = new URLSearchParams();
  url.append("published", "true");

  if (title_fr) url.append("title_fr", title_fr);
  if (latitude) url.append("latitude", latitude);
  if (longitude) url.append("longitude", longitude);
  if (sw) url.append("sw", sw);
  if (ne) url.append("ne", ne);
  if (category) url.append("category", category);
  if (current_page) url.append("current_page", current_page);
  if (per_page) url.append("per_page", per_page);
  if (order) url.append("order", order);
  if (homepage) url.append("homepage", homepage);
  if (insee) url.append("insee", insee);

  return `/api/v1/touristic_sites?${url}`;
};

export const API_SEARCH_TOURISTIC_SITE = (title) => {
  const url = new URLSearchParams();
  if (title) url.append("title_fr", removeAccents(title));
  return `/api/v1/touristic_sites/search_bar/search?${url}`;
};

export const API_POST_KAHOOT  = `${process.env.REACT_APP_API_URL}/api/v1/quizzes`;

export const API_PUT_KAHOOT  = (id) => `${process.env.REACT_APP_API_URL}/api/v1/quizzes/${id}`;

export const API_DELETE_KAHOOT = (id) => `${process.env.REACT_APP_API_URL}/api/v1/quizzes/${id}`;

// UPLOAD TEXT
export const API_DIRECT_UPLOAD_TEXT = `${process.env.REACT_APP_API_URL}/api/v1/texts`;

export const API_UPDATE_TEXT = (id) =>
  `${process.env.REACT_APP_API_URL}/api/v1/texts/${id}`;

export const API_UPDATE_TEXTS = `${process.env.REACT_APP_API_URL}/api/v1/texts/batch`;

// Experiences
export const API_POST_EXPERIENCE_MESSAGE = "/api/v1/experience_messages";

// Activities
export const API_GET_ACTIVITY = (uuid, resourceAccessToken = '') => `/api/v1/activities/${uuid}${resourceAccessToken ? `?resource_access_token=${resourceAccessToken}` : ''}`;

export const API_CHECK_ACTIVITY_PASSWORD = (uuid) =>
  `/api/v1/activities/${uuid}/check_password`;

export const API_CREATE_ACTIVITY = () =>
  `${process.env.REACT_APP_API_URL}/api/v1/pro/activities`;

export const API_PATCH_ACTIVITY = (id) =>
  `${process.env.REACT_APP_API_URL}/api/v1/pro/activities/${id}`;

export const API_DELETE_ACTIVITY = (id) =>
  `${process.env.REACT_APP_API_URL}/api/v1/pro/activities/${id}`;

// VisitSheets (InterestPoints)

export const API_CREATE_VISIT_SHEET = () =>
  `${process.env.REACT_APP_API_URL}/api/v1/pro/visit_sheets`;

export const API_PATCH_VISIT_SHEET = (id) =>
  `${process.env.REACT_APP_API_URL}/api/v1/pro/visit_sheets/${id}`;

export const API_DELETE_VISIT_SHEET = (id) =>
  `${process.env.REACT_APP_API_URL}/api/v1/pro/visit_sheets/${id}`;

export const API_MOVE_ACTIVITY = (touristic_site_id, activity_id) =>
  `${process.env.REACT_APP_API_URL}/api/v1/touristic_sites/${touristic_site_id}/activities/${activity_id}`;

export const API_MOVE_VISIT_SHEET = (activity_id, visit_sheet_id) =>
  `${process.env.REACT_APP_API_URL}/api/v1/pro/activities/${activity_id}/visit_sheets/${visit_sheet_id}`;

//resource_accesses
export const API_RESOURCE_ACCESSES = () =>
  `${process.env.REACT_APP_API_URL}/api/v1/resource_accesses`;

export const API_RESOURCE_ACCESSES_ACTIVITY = (id) => `${process.env.REACT_APP_API_URL}/api/v1/activities/${id}`;

// Reservations
export const API_CREATE_RESERVATION = "/api/v1/reservations";

export const API_RESERVATION_VERIFY_CODE = "/api/v1/reservations/verify_code";

export const API_RESERVATION_RESEND_CODE = (reservation_id) =>
  `/api/v1/reservations/${reservation_id}`;

// Conversations

export const API_CREATE_CONVERSATION =
  "https://henri-messaging.slapp.me/conversation/create";

export const API_CONTACT_UNREGISTERED = (ts_id) => ``;

// Maps

export const API_GET_MAP = (id) => `${process.env.REACT_APP_API_URL}/api/v1/maps/${id}`;

/*
 ** Below: Routes for ActiveStorage / DirectUpload
 */

export const API_DIRECT_UPLOAD_URL = (service_name) =>
  `${process.env.REACT_APP_API_URL}/rails/active_storage/direct_uploads?service_name=${service_name}`;

export const API_ORIGINAL_BLOB_URL = (signed_id, filename) =>
  `${process.env.REACT_APP_API_URL}/rails/active_storage/blobs/redirect/${signed_id}/${filename}`;

export const API_LIST_AGENCIES = `${process.env.REACT_APP_API_URL}/api/v1/pro/agencies`;
