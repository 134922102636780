import {Fragment, useEffect, useState} from "react";
import styled from "styled-components";
import  { ReactComponent as WrongAnswerIcon } from "../../../src/assets/img/logo/AVPMBuilder/quizz/wrong_icon.svg";
import  { ReactComponent as ValidAnswerIcon } from "../../../src/assets/img/logo/AVPMBuilder/quizz/valid_icon.svg";
import  { ReactComponent as StarIcon } from "../../../src/assets/img/logo/AVPMBuilder/quizz/Etoile_rating.svg";
import {API_ORIGINAL_BLOB_URL} from "../../utils/api";
import theme from "../../_ui/_v2/styles/theme";
import Button from "../../_ui/_v2/components/Button/Button"
import breakpoint from "styled-components-breakpoint";
import SliderContainerWithArrowNavigation from "../../_ui/_v2/components/SliderContainerWithArrowNavigation";

const ButtonQuestionStyled = styled.div `
  background-color: black;
  width: 50px;
  height: 50px;
  padding: .5em;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  span {
    font-size: 2rem;
    font-weight: 700;
  }
`;

const StyledButton = styled.button `
  display: flex;
  padding: 1em;
  background-color: ${theme.colors.primary};
  border-radius: 1em;
  color: white;
  border: none;
`;

const TextContainer = styled.div `
  background-color: rgba(255, 255, 255, .8);
  border: 1px solid rgba(169, 169, 169, 0.6);
  color: black;
  padding: 0 1em;
  resize: none;
  border-radius: 3em;
  width: 90%;
  ...rest
  ::placeholder{
    color: white;
  }
`;

const KahootGetQuestionContainer = styled.div `
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 5px;
  align-items: center;
  overflow-y: auto;
  ${breakpoint('md')`
    gap: 10px;
  `}
`;


const QuestionsNumberContainer = styled(SliderContainerWithArrowNavigation) `
  height: 70px;
  width: 100%;
  margin-top: 1em;
  ${breakpoint('md')`
    display: none;
  `}
`;


const ImageContainerStyled = styled.div `
  max-height: 12em; 
  max-width: 12em;

  ${breakpoint('md')`
    max-height: 15em; 
    max-width: 15em;
  `}
`;

const AdditionalDescriptionContainer = styled.div `
  width: 90%;
  background-color: rgba(0,0,0, 0.4);
  padding: .8em;
  border-radius: 1em;
  color: white;
  font-weight: 500;
  font-style: italic;
`;

const MenuKahootDisplay = ({quiz, onCheck, onQuestionNext, selectedIndex, onEnd}) => {
  const [selectedResponse, setSelectedResponse] = useState([]);
  const [showGoodAnwser, setGoodAnswer] = useState(false);

  return(
    <KahootGetQuestionContainer>
      {
        quiz?.[selectedIndex]?.medias.length > 0 && (
          <ImageContainerStyled>
            <img
              src={API_ORIGINAL_BLOB_URL(quiz?.[selectedIndex]?.medias[0].signed_id, quiz?.[selectedIndex]?.medias[0].filename)}
              style={{
                width: '101%',
                height: '100%',
                objectFit: 'cover',
                borderRadius: '1rem'
              }}
            />
          </ImageContainerStyled>
        )
      }
      <span style={{color: 'white', fontWeight: '700'}}>{quiz?.[selectedIndex]?.question?.text_fr}</span>
      {
        quiz?.[selectedIndex]?.responses.length > 0 && quiz?.[selectedIndex]?.responses?.map((quizElement,index) => (
          !showGoodAnwser ?
            <TextContainer
              style={{border: `${selectedResponse.includes(quizElement) ? '3px #54A8FC solid' : 'none'}`}}
              onClick={() =>
                selectedResponse.includes(quizElement) ? setSelectedResponse(selectedResponse.filter(indexOfArray => indexOfArray !== quizElement), 1)
                :
                setSelectedResponse(state =>  [...state, quizElement])
            }
            >
              <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-around', padding: '0.5rem 0 0.5rem 0', fontWeight: '700', cursor: 'pointer'}} >
                <div>
                  {quizElement?.response?.text_fr}
                </div>
              </div>
            </TextContainer>
            :
            <>
              <TextContainer
                style={{background: `${quizElement.good_answer ? 'green' : 'red'}`}}
              >
                <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: '0.5rem 0 0.5rem 0', fontWeight: '700', cursor: 'pointer', overflowY: 'auto'}} >
                  <div>
                    {quizElement?.response?.text_fr}
                  </div>
                  {
                    quizElement?.good_answer ?
                      <div>
                        <ValidAnswerIcon height="20" width="20"/>
                      </div>
                      :
                      <div>
                        <WrongAnswerIcon height="20" width="20" />
                      </div>
                  }
                  </div>
              </TextContainer>
              {
                quizElement?.detail?.text_fr && (
                  <AdditionalDescriptionContainer>
                    {quizElement?.detail?.text_fr}
                  </AdditionalDescriptionContainer>
                )
              }
            </>
        ))
      }
      {
        !showGoodAnwser ?
          <StyledButton style={{padding: '.5em'}} onClick={() => {
            setGoodAnswer(true)
            onCheck({selectedResponse: selectedResponse, currentQuestion: quiz[selectedIndex]})
          }}>Valider</StyledButton>
          :
          <StyledButton style={{padding: '.5em'}} onClick={() => selectedIndex === quiz.length - 1 ? onEnd() : onQuestionNext()}>Suivant</StyledButton>
      }
    </KahootGetQuestionContainer>
  );
};

const QuestionsContainer = styled.div `
  display: none;
  ${breakpoint('md')`
      margin-top: 2em;
      margin-right: 2em;
      display: flex;
      flex-direction: column;
      gap: 20px;
      justify-content: flex-start;
      margin: 1em;
  `}
`;

const AddQuestionToQuizz = ({quizzArray, selectedIndex}) => (
  <>
    <QuestionsContainer key={quizzArray}>
      {
        quizzArray?.length && quizzArray?.map((e,index) => (
          <Fragment key={index}>
            <ButtonQuestionStyled
              style={{backgroundColor: `${index === selectedIndex ? '#54A8FC' : 'black'}`, cursor: 'pointer', color: `${index === selectedIndex ? 'white' : 'grey'}`}}
            >
              <span>{index + 1}</span>
            </ButtonQuestionStyled>
          </Fragment>
        ))
      }
    </QuestionsContainer>
    <QuestionsNumberContainer key={quizzArray}>
      {
        quizzArray?.length && quizzArray?.map((e,index) => (
          <Fragment key={index}>
            <ButtonQuestionStyled
              style={{backgroundColor: `${index === selectedIndex ? '#54A8FC' : 'black'}`, cursor: 'pointer', color: `${index === selectedIndex ? 'white' : 'grey'}`}}
            >
              <span>{index + 1}</span>
            </ButtonQuestionStyled>
          </Fragment>
        ))
      }
    </QuestionsNumberContainer>
  </>
);

const NoteContainer = styled.div `
  color: ${props => props.theme.colors.primary}
`;

const ButtonContainer = styled.div `
  display: flex;
  justify-content: center;
  button:first-child {
    background-color: ${props => props.theme.colors.primary};
  }
`;

const ResultContainer = styled.div `
  padding: 2.5rem;
  position: relative;
`;

const ResultToShow = ({result, onClickCloseTheQuizz}) => {
  const [end, setEnd] = useState("Bravo");

  useEffect(() => {
    if (result < 10) {
      setEnd("Aïe, dommage...");
    }
    if (result >= 10 && result <= 13) {
      setEnd("Vous avez la moyenne !");
    }
    if (result > 13 && result <= 19) {
      setEnd("Très bon score !");
    }
    if (result === 20) {
      setEnd("C'est un sans faute !");
    }
  }, []);

  return (
    <div>
      <ResultContainer>
          {
            result >= 10 && result <= 13 &&  <div style={{position: 'absolute', top: '2%', left: '42%'}}><StarIcon /></div>
          }
          {
            result > 13 && result <= 19 && (<div style={{position: 'absolute', top: '2%', left: '34%'}}><StarIcon /><StarIcon /></div>)
          }
          {
            result === 20 && (<div style={{position: 'absolute', top: '2%', left: '26%'}}><StarIcon /><StarIcon /><StarIcon /></div>)
          }
          <div style={{
            borderRadius: '1em',
            backgroundColor: 'white',
            padding: '4rem'
          }}>
          <p style={{ fontWeight: "700", fontSize: "1rem", color: 'black', textAlign: 'center'}}>
            {end}
          </p>
          <p style={{ fontWeight: "700", fontSize: "1rem", color: 'black', textAlign: 'center'}}>
            Voici votre note finale :
          </p>
          <div>
            <NoteContainer>
              <div style={{textAlign: 'center', display: 'flex', justifyContent: 'center'}}>
                {result >= 10 ? (
                  <span style={{fontSize: "4rem", fontWeight: '700'}}>{Math.round(result)}</span>
                ) : (
                  <span style={{fontSize: "4rem", fontWeight: '700'}}>0{Math.round(result)}</span>
                )}
                <span style={{fontSize: "1.6rem", fontWeight: '700'}}>/20</span>
              </div>
            </NoteContainer>
          </div>
        </div>
      </ResultContainer>
      <ButtonContainer>
        <Button
          type="button"
          onClick={() => {
            onClickCloseTheQuizz(false);
          }}
          className="btn-next"
        >
          Terminer
        </Button>
      </ButtonContainer>
    </div>
  );
};

const QuizzContainer = styled.div `
  display: flex;
  flex-direction: column;
  justify-content: center;
  
  ${breakpoint('md')`
     flex-direction: row;
  `}
`;

const QuizzGetContainer = styled.div `
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  padding: 0;

  ${breakpoint('md')`
      padding: 2rem;
  `}
`;

const Quiz = ({values, onClickCloseTheQuizz}) => {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [score, setScore] = useState(0);
  const [scoreToShow, setScoreToShow] = useState(false);
  const [pointToAddition, setPointToAddition] = useState(0);
  const [totalGoodResponse, setGoodResponse] = useState(0);
  const onQuestionNext = () => {
    setSelectedIndex(state => state + 1)
  };

  const checkedResponses = ({selectedResponse, currentQuestion}) => {
    currentQuestion.responses.forEach((response)=>{
        if(response.good_answer){
          setGoodResponse(state => state + 1);
        }
      })

    selectedResponse?.forEach((e,index)=>{
      if(e.good_answer){
        setPointToAddition(state => state + 1)
      }
      else {
        setPointToAddition(state => state - 0.5)
      }
    })
  };

  const getScoreUserAndShowWindow = () => {
    setScore((pointToAddition/totalGoodResponse) * 20)
    setScoreToShow(true);
  };

  return(
    <>
      {
        !scoreToShow ?
          <QuizzGetContainer>
              <QuizzContainer>
                <AddQuestionToQuizz
                  quizzArray={values?.questions}
                  setSelectedIndex={setSelectedIndex}
                  selectedIndex={selectedIndex}
                  key={selectedIndex}
                />
                <MenuKahootDisplay
                  setSelectedIndex={setSelectedIndex}
                  selectedIndex={selectedIndex}
                  quiz={values?.questions}
                  updateData={values}
                  key={selectedIndex + 1}
                  onEnd={() => {
                    getScoreUserAndShowWindow();
                  }}
                  onCheck={(...args) => checkedResponses(...args)}
                  onQuestionNext={(...args) => onQuestionNext(...args)}
                />
              </QuizzContainer>
          </QuizzGetContainer>
          :
          <ResultToShow result={score} onClickCloseTheQuizz={onClickCloseTheQuizz}/>
      }
    </>
  )
};

export default Quiz;