import styled from 'styled-components';

const Button = styled.button`
  display: inline-block;
  box-sizing: border-box;
  padding: ${props => props.small ? '4px 8px' : '8px 16px'};
  font-weight: 600;
  border: none;
  background-color: ${props => props.outlined ? 'transparent' : props.theme.colors.primary};
  color: ${props => props.outlined ? props.theme.colors.primary : props.theme.colors.white};
  border-radius: ${props => props.rounded ? '2rem' : '0.2rem'};
  vertical-align: middle;
  
  ${props => props.outlined && `border: 2px solid ${props.theme.colors.primary};`}
  
  :hover {
    ${props => props.outlined && `
      background-color: ${props.theme.colors.primary};
      color: ${props.theme.colors.white};
    `}
  }
`;

export default Button;