import React from 'react';

const useSlick = (items) => {
    const [data, setData] = React.useState(items);
    const total = React.useMemo(() => items.length - 2, [items]);
    const [current, setCurrent] = React.useState(0);
    const slick = React.useRef();

    React.useEffect(() => {
        setData(items);
    }, [items])

    const onChange = (d) => {
        const c = current + d
        if (d === 0) {
            return;
        }
        if (c >= 0 || c < total) {
            if (d > 0) {
                slick.current.slickNext();
            } else {
                slick.current.slickPrev();
            }
        }
    }

    return {data, current, onChange, setCurrent, slick, total};
};

export default useSlick;