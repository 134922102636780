import styled from "styled-components";
import {breakpoint} from "styled-components-breakpoint";
import { ReactComponent as PlaneIcon } from '../../../assets/img/logo/plane-icon.svg';
import {useEffect, useState} from "react";
import {API_GET_EXPERIENCES} from "../../../utils/api";
import useRequest from "../../../hooks/use-request";
import countries from '../../../utils/countries.json';

const HomeSectionExperienceBuilder = () => {
  const [handleRequest] = useRequest();
  const [roadtrips, setRoadtrips] = useState(null);

  useEffect(() => {
    handleRequest("get", API_GET_EXPERIENCES + '?published=true', {}, {}, (data, status) => {
      if (status === "Done") {
        setRoadtrips(data)
      } else {
        console.error(data);
      }
    })
  }, []);

  return (
    roadtrips && <HomeSectionExperience roadtrips={roadtrips} />
  );
};

const HomeExperienceContainer = styled.div `
  ${breakpoint('lg')`
    display: flex;
    justify-content: center;
  `}
`;
const subdivideArray = (array, chunkSize)=> {
  if (chunkSize < 1) {
    throw new Error("Cannot create chunk < 1");
  }
  const result = [];
  for (let i = 0; i < array.length; i += chunkSize) {
    result.push(array.slice(i, i + chunkSize));
  }
  const lastElement = result[result.length -1]
  if(lastElement.length < chunkSize && array.length > 1) {
    const beforeLastElement = result[result.length -2];
    const populatedArray = [...lastElement, ...beforeLastElement.slice(0, chunkSize - lastElement.length)];
    result[result.length -1] = populatedArray
  }
  return result;
};

const HomeSectionExperience = ({roadtrips}) => {
  const [activeTab, setActiveTab] = useState( 0);
  const [randomTab, setRandomTab] = useState(0);
  const [randomRoadTripsArray, setRandomRoadTripsArray] = useState(subdivideArray(roadtrips, 4));
  const [makePhotoRandom, setMakePhotoRandom] = useState(0);

  useEffect(() => {
    setTimeout(() => {
      setActiveTab((activeTab + 1) % randomRoadTripsArray[randomTab].length);
      setMakePhotoRandom(state => state+1);
      if(randomRoadTripsArray[randomTab].length - 1 === activeTab){
        setRandomTab((randomTab + 1) % randomRoadTripsArray.length)
      }
    }, 9000);
  }, [makePhotoRandom]);

  useEffect(() => {
    setRandomRoadTripsArray(subdivideArray(roadtrips, 4));
  }, []);

  return(
    <HomeSectionStyled>
      <h2 className="title text-primary pb-1">Nos Expériences</h2>
      <StyledSpan>HENRI TRIP : VOTRE COMPAGNON DE VOYAGE 2.0</StyledSpan>
      <HomeExperienceContainer>
        <SpanHomeStyled>Sortez des sentiers battus, immergez vous dans la culture locale...</SpanHomeStyled>
        <CardSectionHome>
          <HomeExperienceMainCard roadtrip={randomRoadTripsArray[randomTab][activeTab]}/>
        </CardSectionHome>
        <SecondCardsStyle>
          {
            randomRoadTripsArray.length !== 0 && randomRoadTripsArray[randomTab]?.map((e, index)=>(
                <div onClick={() => setActiveTab(randomRoadTripsArray[randomTab].length - 1 === activeTab ? 0 : index)}>
                  <HomeExperienceCard
                    roadtrip={e}
                    setActivetab={setActiveTab}
                    key={index}
                    style={{display: e?.id === randomRoadTripsArray[randomTab][activeTab]?.id ? 'none' : 'block'}}
                  />
                </div>
              )
            )
          }
        </SecondCardsStyle>
        <TripSpanFooter>Ne voyagez plus, vivez!</TripSpanFooter>
      </HomeExperienceContainer>
    </HomeSectionStyled>
  );
};

const StyledTrip = styled.div`
  position: absolute;
  background-color: white;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  padding: 5px 10px;
  border-radius: 60%;
  height: 9vw;
  width: 9vw;
  svg {
    height: 7vw;
    width: 7vw;
  }
  ${breakpoint('md')`
    height: 7vw;
    width: 7vw;
    svg {
    height: 5vw;
    width: 5vw;
  }
`}
`;

const StyledNumberDays = styled.div`
  position: absolute;
  background-color: white;
  border-radius: 0 0.3em 0.3em 0;
  padding: 5px 10px 5px 10px;
  top: 55%;
  left: 8vw;
  translate: 0 -50%;
  > p {
    font-weight: 700;
    color: #024F91;
    font-size: 2vw;
  }

  ${breakpoint('md')`
    padding: 5px 10px 5px 20px;
    top: 46%;
    left: 6vw;
  `}
`;

const ParagraphStyled = styled.p`
  @keyframes slideout {
    0% {
      transform: translateX(-80%);
    }
    50% {
      font-size: 0rem;
      transform: translateX(-300%);
    }
    100% {
      transform: translateX(0);
    }
  }
  animation-duration: 1s;
  animation-name: slideout;
`;

const DayTripsContainer = styled.div`
  position: relative;
  height: 8vw;
  width: 100%;
  top: 10%;
  left: 5%;
`;

const TripDuration = ({duration = 0}) => (
  <DayTripsContainer>
    <StyledNumberDays key={Math.random()}>
      <ParagraphStyled key={Math.random()}>{duration} jours</ParagraphStyled>
    </StyledNumberDays>
    <StyledTrip>
      <PlaneIcon color="#024F91" height="5vw" width="5vw"/>
    </StyledTrip>
  </DayTripsContainer>
);

const DayActivityStyled = styled.div`
  position: relative;
  box-sizing: border-box;
  box-shadow: 0px 10px 13px -7px #000000, 16px 16px 23px -2px rgb(0 0 0 / 17%);
  border-radius: 1rem;
  width: 100%;
  height: 65vw;
  max-height: 580px;
  max-width: 850px;

  ${breakpoint('md')`
    width: 55vw;
    height: 35vw;
  `}

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 1rem;
  }

  span {
    color: white;
    font-weight: 700;
    font-size : 1.3em;
    line-height: 1em;
    margin-left: 0.5em;
    animation-duration: 1s;
    animation-name: slidein;
    transition-property: opacity, left, top, height;
    transition-duration: 0.5s, 2s;

    ${breakpoint('md')`
    font-size : 1.5em;
  `}
  }
`;

const SpanContainerStyled = styled.div `
  position: absolute;
  bottom: 0;
  height: 20%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  padding: 5px 10px;
  border-radius: 0 0 1rem 1rem;
`;

const SpanAnimatedStyled = styled.div`
  @keyframes slideleft {
    0% {
      transform: initial;
    }
    33% {
      transform: translateX(-1000px);
    }
    100% {
      transform: translateX(initial);
    }
  }
  animation-duration: 1s;
  animation-name: slideleft;

  span {
    display: flex;
    flex-direction: column;
    gap: 5px;
  }
`;

const TripDurationContainer = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 2;
`;

const ImageAnimatedContainer = styled.div`
  width: 100%;
  height: 100%;
  img {
    @keyframes fade {
      10%{ opacity: 0 }
      50% { opacity: 0.5 }
      100% {opacity: 1}
    }
    animation-duration: 1.5s;
    animation-name: fade;
  }
`;

const HomeExperienceMainCard = ({roadtrip}) => {
  const city = roadtrip?.place ? JSON.parse(roadtrip.place) : "";
  const country = countries.find((country) => country.value === roadtrip.country)?.label || roadtrip.country;

  return(
    <DayActivityStyled
      props={roadtrip}
      style={{cursor: "pointer"}}
      onClick={()=> window.open(`experiences/${roadtrip.id}`, '_blank')}
    >
      <TripDurationContainer>
        <TripDuration duration={roadtrip?.trip_days?.length}/>
      </TripDurationContainer>
      <ImageAnimatedContainer key={Math.random()}>
        <img src={roadtrip?.picture} alt={roadtrip.title?.text_fr} />
      </ImageAnimatedContainer>
      {
        roadtrip.title?.text_fr && (
          <SpanContainerStyled>
            <SpanAnimatedStyled key={Math.random()} >
              <span>{roadtrip.title?.text_fr}</span>
              {/*<span>{city?.[0]?.data.text || ""}</span>*/}
              <span>{country}</span>
            </SpanAnimatedStyled>
          </SpanContainerStyled>
        )
      }
    </DayActivityStyled>
  );
};

const CardActivityStyled = styled.div`
  position: relative;
  box-sizing: border-box;
  box-shadow: 0px 10px 13px -7px #000000, 16px 16px 23px -2px rgb(0 0 0 / 17%);
  border-radius: 1rem;
  width: 12vw;
  height: 20vw;
  max-height: 250px;
  max-width: 150px;

  img {
    @keyframes fade {
      10%{ opacity: 0 }
      50% { opacity: 0.5 }
      100% {opacity: 1}
    }
    animation-duration: 1s;
    animation-name: fade;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 1rem;
  }

  span {
    color: white;
    font-weight: 700;
    font-size : 1rem;
    line-height: 1em;
    margin-left: 0.5em;
  }
`;

const HomeExperienceCard = ({roadtrip, ...rest}) => (
  <div {...rest}>
    <div>
      <CardActivityStyled style={{cursor: "pointer"}}>
        <img src={roadtrip.picture} alt={roadtrip.title?.text_fr} />
      </CardActivityStyled>
    </div>
  </div>
);

const SecondCardsStyle = styled.div `
  position: absolute;
  right: -30%;
  display: flex;
  bottom: -15%;
  gap: 20px;

  ${breakpoint('md')`
    right: 0;
    > p {
    font-size: 1.4vw;
    }
`}
`;

const StyledSpan = styled.h3 `
  font-size: calc(1rem + 0.6vw);
  font-weight: 700;
`;

const CardSectionHome = styled.div `
  position: relative;
  margin-bottom: 2em;
  margin-top: 7em;

${breakpoint('md')`
  margin-top: 2em;
`}
`;

const HomeSectionStyled = styled.section `
  display: block;
  position: relative;
  height: auto;
  margin-top: 3rem;
  margin-bottom: 10rem;
  max-width: 800px;

  ${breakpoint('lg')`
    max-width: 1100px;
  `}
`;

const SpanHomeStyled = styled.span `
  position: absolute;
  right: 0;
  top: 23%;
  width: 100%;
  font-weight: 500;
  font-size: 1.5rem;
  font-style: italic;
  line-height: 1;
  ${breakpoint('sm')`
    top: 15%;
`}

  ${breakpoint('md')`
    position: absolute;
    right: -100px;
    top: 30%;
    width: 30%;
    font-size: 1.8rem;
    line-height: 1.5;
  `};

  ${breakpoint('lg')`
    width: 25%;
    right: -140px;
    top: 40%;
  `};
  
  ${breakpoint('xl')`
    right: -170px;
    top: 40%;
  `}
`;

const TripSpanFooter = styled.span `
  font-weight: 800;
  font-style: italic;
  color: #004f91;
  position: absolute;
  translate-x: -100%;
  bottom: -90px;
  font-size: 1.6em;

  ${breakpoint('md')`
    position: absolute;
    right: 0px;
    bottom: -160px;
  `}
`;

export default HomeSectionExperienceBuilder;