import React from "react";
import {Link} from "react-router-dom";

const LinkWrapper = ({to, href, onClick, children, ...rest}) => (
  to
    ? (
      <Link
        to={to}
        {...rest}
      >
        {children}
      </Link>
    ) : (
      <a
        href={href}
        {...rest}
      >
        {children}
      </a>
    )
);

export default LinkWrapper;