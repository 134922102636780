import React, {useEffect, useLayoutEffect, useRef, useState} from "react";
import styled from "styled-components";
import breakpoint from "styled-components-breakpoint";
import Arrow from "../Arrow";

const SliderContainerStyled = styled.div`
  display: flex;
  overflow-x: scroll;
  max-width: 100%;
  scroll-behavior: smooth;
  padding-bottom: 25px;
  height: 24em;
  -ms-overflow-style: none;
  &::-webkit-scrollbar{
    display: none;
  }
`;

const Slide = styled.div`
  position: relative;
  height: 100%;
  transform: ${props => props.withScale && props.selected ? 'none' : 'scale(80%)'};
  transition: transform 0.5s ease-in-out;
  
  &:first-child {
    margin-left: ${props => (
      props.centered
        ? `calc(50% - ${props.marginWidth}px)`
        : props.right ? `calc(${50 * 2}% - ${2 * props.marginWidth}px)` : '20px'
    )};
  }

  &:last-child {
    margin-right: ${props => (
      props.centered
        ? `calc(50% - ${props.marginWidth}px)`
        : props.right ? '20px' : `calc(${50 * 2}% - ${2 * props.marginWidth}px)`
    )};
  }
`;

const CardContainer = styled.div`
  height: 100%;
  cursor: pointer;
`;

const ArrowNavigation = styled(Arrow)`
  display: none;
  position: absolute;
  top: 50%;
  bottom: 50%;
  
  ${breakpoint('md')`
    display: ${props => props.disabled ? 'none' : 'block'};
  `}
`;

const SliderContainer = (
  {
    children,
    withArrows = false,
    withScale = false,
    centered = false,
    pas = 1,
    selectedIndex = 0,
    onSelectedIndexChange = () => {},
    ...rest
  }
) => {
  const [selected, setSelected] = useState(selectedIndex);
  const [marginWidth, setMarginWidth] = useState(0);
  const containerRef = useRef();
  const touchStartClientX = useRef(0);
  useEffect(() => {
    if (selectedIndex !== selected) {
      setSelected(selectedIndex);
    }
  }, [selectedIndex]);

  useEffect(() => {
    if (containerRef.current) {
      const scrollX = selected * containerRef.current.children?.[0]?.offsetWidth || 0;
      containerRef.current.scrollTo(scrollX, 0);
    }
    onSelectedIndexChange(selected);
  }, [selected]);

  useLayoutEffect(() => {
    const observer = new ResizeObserver((entries) => {
      for (const entry of entries) {
        setMarginWidth(containerRef.current?.children?.[0]?.offsetWidth / 2);
      }
    });

    observer.observe(containerRef.current);

    return () => observer.disconnect();
  }, [containerRef]);

  const nextCard = () => setSelected(Math.min(selected + pas, children.length - 1));
  const prevCard = () => setSelected(Math.max(selected - pas, 0));

  return (
    <SliderContainerStyled
      ref={containerRef}
      onTouchStart={event => touchStartClientX.current = event.touches[0].clientX}
      onTouchEnd={event => (
        event.changedTouches[0].clientX < touchStartClientX.current
          ? nextCard()
          : prevCard()
      )}
      {...rest}
    >
      {
        children.map((child, index) => (
          <Slide
            key={index}
            selected={selected === index}
            withScale={withScale}
            marginWidth={marginWidth}
            centered={centered}
          >
            <CardContainer
              onClick={() => setSelected(index)}
            >
              {child}
            </CardContainer>
            {withArrows && selected === index && (
              <>
                <ArrowNavigation
                  direction="left"
                  onClick={prevCard}
                  disabled={index === 0}
                  style={{left: -15}}
                />
                <ArrowNavigation
                  direction="right"
                  onClick={nextCard}
                  disabled={index === children.length - 1}
                  style={{right: -15}}
                />
              </>
            )}
          </Slide>
        ))
      }
    </SliderContainerStyled>
  );
};

export default SliderContainer;