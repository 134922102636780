import AmbassadorPaymentPage from 'pages/UserPage/AmbassadorPaymentPage/AmbassadorPaymentPage';
import React from 'react';


const AmbassadorPayment = () => {
  return (
    <AmbassadorPaymentPage/>
  );
};

export default AmbassadorPayment;