import {useCallback, useEffect, useRef, useState} from 'react';
import styled from 'styled-components';
import TextInput from './TextInput';
import Loader from '../Loader';

const Options = styled.ul`
  position: absolute;
  z-index: 1;
  border-radius: 5px;
  box-shadow: 10px 10px 13px -7px rgb(0 0 0 / 17%), 16px 16px 23px -2px rgb(0 0 0 / 17%);
  
  li:first-child {
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
  }
  
  li:last-child {
    border-bottom-right-radius: 5px; 
    border-bottom-left-radius: 5px;
  }
`;

const Option = styled.li`
  width: 100%;
  padding: 10px 20px;
  margin: 0;
  list-style: none;
  background-color: ${props => props.theme.colors.white};
  
  ${props => !props.disabled && `
  cursor: pointer;
  
  :hover {
    background-color: ${props.theme.colors.lightGrey};
  }
  `}
`;

const EmptyComponentDefault = () => (
  <Option disabled className="align-center">
    Aucun résultat
  </Option>
);

const LoadingComponentDefault = () => (
  <Option disabled>
    <Loader/>
  </Option>
);

const OptionComponentDefault = ({item, ...rest}) => (
  <Option
    title={item.label || item.value}
    {...rest}
  >
    {item.label || item.value}
  </Option>
);

const AutocompleteInput = (
  {
    onChange,
    onCurrentItemChange,
    value,
    fetchParams,
    formatResponse,
    ...rest
  }
) => {
  const [currentSearch, setCurrentSearch] = useState(value);
  const [currentItem, setCurrentItem] = useState(undefined);
  const [items, setItems] = useState();
  const [optionsListVisible, setOptionsListVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const inputRef = useRef();
  const containerRef = useRef();
  const optionsRef = useRef();
  const eventRef = useRef();
  const controllerRef = useRef();

  useEffect(() => {
    fetchItems(currentSearch);
    eventRef.current && onChange(eventRef.current);
  }, [currentSearch]);

  useEffect(() => {
    onCurrentItemChange?.(currentItem);
    handleChange();
  }, [currentItem]);

  const handleClickOutside = useCallback((event) => {
    const clickOutside = !(
      containerRef.current?.contains(event.target) ||
        optionsRef.current?.contains(event.target)
    );

    if (clickOutside && optionsListVisible) {
      setCurrentItem(undefined);
      setOptionsListVisible(false);
    }
  }, [containerRef, optionsRef, optionsListVisible]);

  useEffect(() => {
    window.addEventListener('click', handleClickOutside);

    return () => window.removeEventListener('click', handleClickOutside);
  }, [handleClickOutside]);

  const handleChange = () => {
    setOptionsListVisible(false);
    if (eventRef.current?.target?.value) {
      eventRef.current.target.value = currentItem?.value || '';
    }
    eventRef.current && onChange(eventRef.current);

    if (currentItem?.label) {
      setCurrentSearch(currentItem.label);
    }
  };

  const fetchItems = (searchValue) => {
    if (!searchValue || searchValue.length <= 1) {
      setItems(undefined);
      return ;
    }
    setLoading(true);
    controllerRef.current?.abort();

    controllerRef.current = new AbortController();
    const signal = controllerRef.current.signal;
    fetch(...fetchParams(searchValue, signal))
      .then((response) => response.json())
      .then((data) => {
        setItems(formatResponse(data));
        setLoading(false);
      });
  };

  return (
    <div
      ref={containerRef}
      style={{position: 'relative'}}
    >
      <TextInput
        ref={inputRef}
        onChange={event => {
          event.persist();
          setCurrentSearch(event.target.value)
          eventRef.current = event;
        }}
        onFocus={() => setOptionsListVisible(true)}
        value={currentSearch}
        {...rest}
      />
      {
        optionsListVisible && (
          <Options
            ref={optionsRef}
            style={{
              top: (inputRef.current?.offsetHeight || 0),
              width: (inputRef.current?.offsetWidth || 0),
            }}
          >
            {
              loading
                ? <LoadingComponentDefault/>
                : (
                  items?.length <= 0
                    ? <EmptyComponentDefault/>
                    : (
                      items?.map((item, index) => (
                        <OptionComponentDefault
                          key={index}
                          item={item}
                          onClick={(event) => {
                            setCurrentItem(item);
                            event.stopPropagation();
                          }}
                        />
                      ))
                    )
                )
            }
          </Options>
        )
      }
    </div>
  );
};

export default AutocompleteInput;

export {
  Option,
};