import Feedback from 'components/informations/feedback/feedback';
import React, {useState} from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { transformSmallImageURL } from "utils/utils";
import {VisibilityOutlined, VisibilityOffOutlined} from "@material-ui/icons";
import { SvgIcon } from "@material-ui/core";
import './roadTrip.scss';
import ImgLazy from "../../Lazyload/ImgLazy/ImgLazy";
import { ReactComponent as ArrowRight } from "../../../assets/img/logo/arrowRight.svg"
import { useTranslation } from "react-i18next";
import CategoriesIcons from "../../informations/categories/categoriesIcons/CategoriesIcons";
import road_trip_premium_logo from "../../../assets/img/logo/road_trip_premium_logo.svg";
import Spinner from "react-bootstrap/Spinner";


const RoadTripCard = ({ roadtrip, home=false }) => {

  const {t} = useTranslation(["henriTrip"])
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(!open);
  }

  const all_touristic_sites = roadtrip?.touristic_sites?.map(el => el.title_fr) || [];

  return (
    <div className={`${home ?  "home-page-road-trip-card" : "roadtrip-card"} bg-white shadow-lg`}>

      <div className="roadtrip-card--head">
        {
          roadtrip.road_trip_premium &&
            <div className="roadtrip-card--macaron">
              <img src={road_trip_premium_logo} alt="logo" />
              <p className="roadtrip-card--macaron-currency">€</p>
            </div>
        }

        <ImgLazy
        small
        errorImg="https://general-henri-1-paris.s3.fr-par.scw.cloud/small-woman.jpg"
        src={transformSmallImageURL(roadtrip.picture) || ''}
        className={"card-img-top roadtrip-card--img"}
        style={{height: 150, objectFit: "cover", objectPosition: "center"}} />
      </div>
      <div className="p-3">
        {
          roadtrip.road_trip_premium ?
            <Link to={`/road-trip-premium/${roadtrip.road_trip_premium.id}`}>
              <h3 className="font-weight-bold text-primary m-0 ellipsis-title-card">{roadtrip?.title}</h3>
              <h5 className="m-0 font-weight-medium ellipsis-title-card">{t("by")} {roadtrip?.user?.firstname} {roadtrip?.user?.lastname?.toUpperCase()}</h5>
            </Link>
            :
                roadtrip.experience ?
                    <Link to={`/experiences/${roadtrip.id}`}>
                      <h3 className="font-weight-bold text-primary m-0 ellipsis-title-card">{roadtrip?.title}</h3>
                      <h5 className="m-0 font-weight-medium ellipsis-title-card">{t("by")} {roadtrip?.user?.firstname} {roadtrip?.user?.lastname?.toUpperCase()}</h5>
                    </Link>
                    :
                    <Link to={roadtrip.amount > 0 ? `/experiences/${roadtrip.id}` : `/roadtrip/${roadtrip.id}`}>
                      <h3 className="font-weight-bold text-primary m-0 ellipsis-title-card">{roadtrip?.title}</h3>
                      <h5 className="m-0 font-weight-medium ellipsis-title-card">{t("by")} {roadtrip?.user?.firstname} {roadtrip?.user?.lastname?.toUpperCase()}</h5>
                    </Link>

        }
        {
          roadtrip.feedbacks.note ?
            <Feedback size="sm" note={roadtrip?.feedbacks?.note} feedbacks={roadtrip?.feedbacks?.comments?.length || roadtrip.feedbacks?.len} />
            :
            <></>
        }

        {
          open ?
            <>
            <div className="row">
              <SvgIcon className="ml-3" component={VisibilityOffOutlined} width="20" height="20" />
              <p className="ml-1 font-weight-bold primary text-primary mb-1" onClick={handleClick}><u>{t("roadTripsPage.categories.hiddenCategories")}</u></p>
            </div>
              {
                roadtrip.categoriesTransport?.length > 0 ?
                  <>
                  <div className="row">
                    <div className="col-12">
                      <p className="font-weight-bold">{t("general.category.transport.title")} :</p>
                    </div>
                    {
                      roadtrip.categoriesTransport?.map(el =>
                        <CategoriesIcons name={el.title} color={el.color} icon={el.url} circle={true}/>
                      )
                    }
                  </div>

                  </>
                  :
                <></>
              }

              {
                roadtrip.categoriesGroup?.length > 0 ?
                 <>
                   <hr />
                   <div className="row">
                     <div className="col-12">
                       <p className="font-weight-bold">{t("general.category.withWhom.title")}</p>
                     </div>
                       {
                         roadtrip.categoriesGroup?.map(el =>
                         <CategoriesIcons name={el.title} color={el.color} icon={el.url} circle={true}/>
                         )
                       }
                   </div>

                 </>
                  :
                  <></>
              }

              {
                roadtrip.categoriesSeasons?.length > 0 ?
                  <>
                    <hr/>
                    <div className="row">
                      <div className="col-12">
                        <p className="font-weight-bold">{t("general.category.season.title")} :</p>
                      </div>
                      {
                        roadtrip.categoriesSeasons?.map(el =>
                        <CategoriesIcons name={el.title} color={el.color} icon={el.url} circle={true}/>
                        )
                      }
                    </div>
                  </>
                  :
                  <></>
              }
              {
                roadtrip.categoriesSeasons?.length > 0 ?
                  <></>
                  :
                    roadtrip.categoriesGroup?.length > 0 ?
                      <></>
                      :
                        roadtrip.categoriesTransport?.length > 0 ?
                          <></>
                          :
                          <p>{t("roadTripsPage.categories.noCategories")}</p>

              }
            </>
            :
            <>
            <div className="row">
              <SvgIcon className="ml-3" component={VisibilityOutlined} width="20" height="20" />
              <p className="ml-1 font-weight-bold primary text-primary" onClick={handleClick}><u>{t("roadTripsPage.categories.showCategories")}</u></p>
            </div>
            </>
        }


        <hr />
        <ul className={`${home ? "home-page-road-trip-card--roadtrip-wrapper" : "roadtrip-card--roadtrip-wrapper"}`}>
          {
            all_touristic_sites.sort((a, b) => a.rank - b.rank).slice(0, 4)?.map((el, i) =>
              <li key={i} className={`${home ?  "home-page-road-trip-card--ellipsis-content-card" : "roadtrip-card--ellipsis-content-card"} list-icon`}>
                <p className="font-weight-medium">{el}</p>
              </li>)
          }
        </ul>
        {
          roadtrip.road_trip_premium ?
            <div className="text-primary px-3">
              <Link to={`/road-trip-premium/${roadtrip.road_trip_premium.id}`} className="text-primary font-weight-medium d-flex align-items-center">{t("general.showDetails")}<ArrowRight className="ml-1" width="7" /></Link>
            </div>
            :
            <div className="text-primary px-3">
              <Link to={roadtrip.amount > 0 ? `/experiences/${roadtrip.id}` : `/roadtrip/${roadtrip.id}`} className="text-primary font-weight-medium d-flex align-items-center">{t("general.showDetails")}<ArrowRight className="ml-1" width="7" /></Link>
            </div>
        }
      </div>
    </div>
  );
};

RoadTripCard.propTypes = {
  roadtrip: PropTypes.object.isRequired,
}

export default RoadTripCard;