import React from "react";
import './Divider.scss'

const Divider = ({ children }) => {
  return (
    <div className="divider">
      <div className="divider__border" />
      <span className="divider__content">
        {children}
      </span>
      <div className="divider__border" />
    </div>
  );
};

export default Divider;
