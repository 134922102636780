import React, {useContext, useEffect, useState} from "react";
import {useSelector} from "react-redux";
import styled from "styled-components";
import {
  Elements,
  useStripe,
  useElements,
  PaymentElement,
} from "@stripe/react-stripe-js";
import {loadStripe} from "@stripe/stripe-js"
import {API_IS_USER_CONNECTED, API_STRIPE_PAYMENT_INTENT_V2} from "../../../../utils/api";
import Button from "../Button";
import TextInput from "../FormElements/TextInput";
import Loader from "../Loader";
import NavBarContext from "../../../../_contexts/NavBarProvider";
import {getCookie} from "../../../../utils/cookies";

const stripePromise = loadStripe(`${process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY}`);

const PaymentFormContainerStyled = styled.div`
`;

const PaymentFormStyled = styled.form`
  input {
    width: 100%;
    padding: 0.75rem;
    background-color: #fff;
    border-radius: 5px;
    transition: background 0.15s ease, border 0.15s ease, box-shadow 0.15s ease, color 0.15s ease;
    border: 1px solid #e6e6e6;
    box-shadow: 0 1px 1px rgb(0 0 0 / 3%), 0 3px 6px rgb(0 0 0 / 2%);
  }
  input:focus {
    outline: 0;
    border-color: hsla(210, 96%, 45%, 50%);
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.03),
    0 3px 6px rgba(0, 0, 0, 0.02),
    0 0 0 3px hsla(210, 96%, 45%, 25%),
    0 1px 1px 0 rgba(0, 0, 0, 0.08);
  }
  label {
    margin-bottom: 0.25rem;
    font-size: 1.0625rem;
    font-weight: 400;
    color: #30313d;
    text-align: left;
  }
  > div {
    text-align: left;
    margin-bottom: 0.75rem;
  }
  > button {
    width: 100%;
    margin-top: 15px;
  }
  .form-error-message {
    display: inline-block;
    color: ${props => props.theme.colors.error};
  }
`;
const PaymentFormContainer = ({roadtrip_id, promotionCode, onPaymentSucceeded = () => {}, onPaymentCanceled = () => {}, ...rest}) => {
  const config = useSelector(state => state.data);
  const [options, setOptions] = useState({});
  const navBarContext = useContext(NavBarContext);

  useEffect(() => {
    window.dataLayer.push({'event':'experienceMiseAuPanier', 'road_trip_id': roadtrip_id});
  }, []);

  useEffect(() => {
    const controller = new AbortController();
    const controller2 = new AbortController();
    const loadPaymentIntent = (token) => {
      getPaymentIntent(roadtrip_id, controller2.signal, token).
        then(response => {
          if (response.ok) {
            response.json().then(json => {
              // passing the client secret obtained from the server
              setOptions({clientSecret: json.clientSecret,});
            });
          }
        });
    };
    isUserConnected(controller.signal).
      then(response => {
        if (response.ok) {
          loadPaymentIntent();
        } else {
          navBarContext.openLoginModal(
            {
              onRequestClose: () => {
                onPaymentCanceled();
              },
              onLoginSucceeded: (data) => {
                loadPaymentIntent(data.token);
              },
            }
          );
        }
      })
    return () => {
      controller2.abort();
      controller.abort();
    };
  },[promotionCode]);

  const isUserConnected = (signal) => {
    return fetch(
      process.env.REACT_APP_API_URL + API_IS_USER_CONNECTED,
      {
        method: "GET",
        mode: 'cors',
        headers: {
          'Authorization': config?.headers?.Authorization,
        },
        signal: signal
      }
    );
  };

  const getPaymentIntent = (roadtrip_id, signal, token) => {
    const webflowPartnerItemId = getCookie('webflowPartnerItemId');
    return fetch(
      process.env.REACT_APP_API_URL + API_STRIPE_PAYMENT_INTENT_V2,
      {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Authorization': token ? `Bearer ${token}` : config?.headers?.Authorization,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          'roadtrip_id': roadtrip_id,
          'code': promotionCode || '',
          'webflow_partner_item_id': webflowPartnerItemId || ''
        }),
        signal: signal
      }
    );
  };

  return (
    <PaymentFormContainerStyled {...rest}>
      <div>
        {
          !options?.clientSecret
            ? <Loader/>
            : (
              <Elements key={options.clientSecret} stripe={stripePromise} options={options}>
                <PaymentForm onPaymentSucceeded={onPaymentSucceeded}/>
              </Elements>
            )
        }
      </div>
    </PaymentFormContainerStyled>
  );
};
const PaymentForm = ({onPaymentSucceeded = () => {}}) => {
  const stripe = useStripe();
  const elements = useElements();
  const {user} = useSelector(state => state.user);
  const [email, setEmail] = useState(user?.email || '');
  const [name, setName] = useState(user?.firstname && user?.lastname ? `${user?.firstname} ${user?.lastname}` : '');
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState();


  const handleSubmit = async (event) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    window.dataLayer.push({'event':'experienceCheckOut'});

    setLoading(true);
    const result = await stripe.confirmPayment({
      //`Elements` instance that was used to create the Payment Element
      elements,
      confirmParams: {
        return_url: window.location.href,
        payment_method_data : {
          billing_details : {
            name : name,
            email : email
          }
        }
      },
      redirect: 'if_required'
    });


    if (result.error) {
      // Show error to your customer (for example, payment details incomplete)
      setError(result.error.message);
      setLoading(false);
    } else {
      window.dataLayer.push({'event':'experienceSuccedCheckOut'});
      // setLoading(false);
      onPaymentSucceeded();
      // Your customer will be redirected to your `return_url`. For some payment
      // methods like iDEAL, your customer will be redirected to an intermediate
      // site first to authorize the payment, then redirected to the `return_url`.
    }
  };

  return(
    <PaymentFormStyled onSubmit={handleSubmit}>
      {error && (
        <span className="form-error-message small">Message: {error}</span>
      )}
      <div>
        <label htmlFor='email'>Email</label>
        <TextInput
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          name='email'
          type='email'
          placeholder='Email'
          required={true}
        />
      </div>
      <div>
        <label htmlFor='email'>Nom du propriétaire</label>
        <TextInput
          value={name}
          onChange={(e) => setName(e.target.value)}
          name='nom'
          type='text'
          placeholder='Nom'
          required={true}
        />
      </div>
      <PaymentElement />
      <Button>
        {
          loading
            ? <Loader color={'white'}/>
            : 'Valider'
        }
      </Button>
    </PaymentFormStyled>
  );
};

export default PaymentFormContainer;