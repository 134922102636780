import { forwardRef, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { ellipsis } from "polished";
import Arrow from "../Arrow";
import { ReactComponent as StarIcon } from "assets/img/logo/star-icon.svg";
import { ReactComponent as ChevronDown } from "assets/img/logo/chevron_down.svg";
import {useTranslation} from "react-i18next";

const StarIconStyled = styled(StarIcon)`
  width: 15px;
  height: 15px;
  stroke: ${(props) => props.theme.colors.primary};
  fill: ${(props) => (props.filled ? props.theme.colors.primary : "none")};
`;
const StarListStyled = styled.div`
  display: inline-block;
`;

const ReviewsHeaderStyled = styled.div`
  display: flex;
  align-items: center;
  gap: 1em;
`;

const CommentStyled = styled.div`
  display: grid;
  padding: 1em 0;
  border-top: 2px solid ${(props) => props.theme.colors.black};
  grid-template-columns: auto 1fr auto;
  column-gap: 1em;
  grid-template-areas:
    "avatar username note"
    "avatar date ."
    ". comment comment";
  & > img {
    grid-area: avatar;
  }
  & > h3 {
    margin: 0;
    font-weight: 900;
    grid-area: username;
  }
  & > p {
    grid-area: date;
  }
  & > div:first-of-type {
    grid-area: note;
  }
  & > div:last-of-type {
    grid-area: comment;
  }
`;

const CommentTextStyled = styled.div`
  ${({ showMore }) => showMore && ellipsis(undefined, 3)}
  white-space: pre-wrap;
`;

const ReviewsContentStyled = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 1em;
`;

const PaginationStyled = styled.div`
  display: flex;
  margin-left: auto;
  margin-top: 1em;
  gap: 2em;
`;

const StarList = ({ note }) => {
  const roundedNote = Math.floor(note);

  return (
    <StarListStyled>
      {[...Array(5)].map((_, i) => (
        <StarIconStyled key={i} filled={i < roundedNote} />
      ))}
    </StarListStyled>
  );
};

const Note = styled.span`
  color: ${(props) => props.theme.colors.primary};
  display: inline-block;
  font-weight: 700;
  font-size: 2.5em;
`;

const NoteCounter = styled.span`
  color: ${(props) => props.theme.colors.darkGrey90};
  display: block;
  font-weight: 600;
  font-size: 1.125em;
`;

const ReviewsHeader = ({ note, len, road_trip_reviews }) => {
  const [statsOpen, setStatsOpen] = useState(false);
  const statsRef = useRef();
  const chevronRef = useRef();

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        statsRef.current &&
        !statsRef.current.contains(event.target) &&
        chevronRef.current &&
        !chevronRef.current.contains(event.target)
      ) {
        setStatsOpen(false);
      }
    };

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const noteFormatted = new Intl.NumberFormat("fr-FR", {
    minimumFractionDigits: 1,
    maximumFractionDigits: 1,
  }).format(note);

  return (
    <ReviewsHeaderStyled>
      <Note>{noteFormatted}</Note>
      <div>
        <StarList note={note} />
        <NoteCounter>{len} votes</NoteCounter>
      </div>
      {len > 0 && (
        <div style={{ cursor: "pointer", position: "relative" }}>
          <ChevronDown
            ref={chevronRef}
            onClick={() => setStatsOpen((prev) => !prev)}
            width={15}
            height={15}
            style={{ userSelect: "none" }}
          />
          {statsOpen && (
            <ReviewsStats
              ref={statsRef}
              comments={road_trip_reviews}
              style={{
                position: "absolute",
              }}
            />
          )}
        </div>
      )}
    </ReviewsHeaderStyled>
  );
};

const Comment = ({ comment }) => {
  const [isShowMore, setIsShowMore] = useState(true);

  const dateFormatted = new Date(comment.created_at).toLocaleDateString(
    "fr-FR",
    { day: "numeric", month: "long", year: "numeric" }
  );
  const toggleReadMore = () => setIsShowMore((show) => !show);
  return (
    <CommentStyled>
      <img src={"/assets/icons/Fichier 43.svg"} width={45} alt="avatar" />
      <h3>{comment?.user?.user_profile?.firstname || comment?.username}</h3>
      <p>{dateFormatted}</p>
      <StarList note={comment.note} />
      <CommentTextStyled showMore={isShowMore} onClick={toggleReadMore}>
        {comment.content}
      </CommentTextStyled>
    </CommentStyled>
  );
};

const Pagination = ({ len, currentPage, perPage, onPageChange }) => {
  const maxPage = len / perPage;

  return (
    <PaginationStyled>
      <Arrow
        direction="left"
        onClick={() => currentPage > 0 && onPageChange((state) => state - 1)}
        disabled={currentPage - 1 < 0}
      />
      <Arrow
        direction="right"
        onClick={() =>
          currentPage < maxPage - 1 && onPageChange((state) => state + 1)
        }
        disabled={currentPage + 1 >= maxPage}
      />
    </PaginationStyled>
  );
};

const ReviewsContent = ({ comments, perPage = 3 }) => {
  const [page, setPage] = useState(0);
  return (
    <ReviewsContentStyled>
      {comments?.length !== 0 &&
        comments
          ?.slice(perPage * page, perPage * (page + 1))
          .map((comment) => <Comment key={comment.id} comment={comment} />)}
      {comments?.length > 3 && (
        <Pagination
          len={comments.length}
          currentPage={page}
          perPage={perPage}
          onPageChange={setPage}
        />
      )}
    </ReviewsContentStyled>
  );
};

const ReviewsStatsStyled = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
  border-radius: 20px;
  transform: translateX(-50%);
  background-color: ${(props) => props.theme.colors.white};
  box-shadow: 0px 10px 13px -7px #000000, 16px 16px 23px -2px rgb(0 0 0 / 17%);
  min-width: 220px;
  width: calc(200px + 10vw);
  max-width: 90vw;
`;

const ReviewsStats = forwardRef(({ comments, ...rest }, ref) => {
  const getPercentage = (note) => {
    let percentage = Math.round(
      (comments?.filter((el) => el?.note === note).length / comments?.length) * 100
    );
    return isNaN(percentage) ? 0 : percentage;
  };

  return (
    <ReviewsStatsStyled ref={ref} {...rest}>
      {Array.from(Array(5), (_, i) => (
        <NotePercentage
          key={5 - i}
          note={5 - i}
          percentage={getPercentage(5 - i)}
        />
      ))}
    </ReviewsStatsStyled>
  );
});

const NotePercentageStyled = styled.div`
  display: flex;
  flex-wrap: nowrap;
  padding: 5px 0;
  gap: 0.75em;
  width: 100%;

  div:nth-child(2) {
    height: 20px;
    width: 100%;
    background-color: ${(props) => props.theme.colors.lightGrey};
    border-radius: 5px;
    box-sizing: border-box;

    div {
      height: 100%;
      background-color: ${(props) => props.theme.colors.primary};
      border-radius: 5px;
      width: ${(props) => props.percentage}%;
    }
  }

  p {
    display: inline-block;
    margin: 0;
  }

  div:first-of-type {
    width: 30px;
    min-width: 30px;

    span {
      margin-right: 2px;
    }
  }
`;

const AbassadorCommentStyled = styled(CommentStyled)`
  padding: 25px;
  border: none;
  h3 {
    font-size: 17px;
  }

  p {
    font-family: "Inter";
    font-size: 16px;
  }
  grid-template-areas:
    "avatar username"
    "avatar comment";
  & > img {
    grid-area: avatar;
  }
  & > h3 {
    margin: 0;
    font-weight: 900;
    grid-area: username;
  }
  & > div {
    padding: 10px 0 0 0;
    grid-area: comment;
  }
`;

const AmbassadorComment = ({ ambassador_review, translation }) => {
  const [isShowMore, setIsShowMore] = useState(true);
  const toggleReadMore = () => setIsShowMore((show) => !show);
  const {i18n} = useTranslation(["touristic-site"]);
  return (
    <AbassadorCommentStyled>
      <img src={"/assets/icons/Fichier 43.svg"} width={45} alt="avatar" />
      <h3>{translation("reviews.name")}</h3>
      <CommentTextStyled showMore={isShowMore} onClick={toggleReadMore}>
        {ambassador_review?.[`text_${i18n.language}`] ? ambassador_review?.[`text_${i18n.language}`] : translation("reviews.notice")}
      </CommentTextStyled>
    </AbassadorCommentStyled>
  );
};
const AmbassadorAvatar = styled.div`
  width: 63px;
  height: 57px;
  background-color: ${(props) => props.theme.colors.white};
`;

const AmbassadorReviewContainer = styled.div`
  display: flex;
  background: ${(props) => props.theme.colors.primary};
  border-radius: 5px;
  width: 100%;
  height: fit-content;
`;

const NotePercentage = ({ note, percentage }) => (
  <NotePercentageStyled percentage={percentage}>
    <div className="align-right">
      <span>{note}</span>
      <StarIconStyled filled />
    </div>
    <div style={{ flex: "1 1 auto" }}>
      <div />
    </div>
    <p className="align-right" style={{ minWidth: 40 }}>
      {percentage}%
    </p>
  </NotePercentageStyled>
);

const Reviews = ({
  avg_notes,
  notes_counter,
  road_trip_reviews,
  ambassador_review,
  translation,
  ...rest
}) => (
  <div {...rest}>
    {avg_notes ? <ReviewsHeader note={avg_notes} len={notes_counter} road_trip_reviews={road_trip_reviews} /> : null}
    <AmbassadorReviewContainer>
      <AmbassadorComment ambassador_review={ambassador_review} translation={translation}/>
    </AmbassadorReviewContainer>
    {road_trip_reviews ? <ReviewsContent comments={road_trip_reviews} /> : null}
  </div>
);

export default Reviews;
