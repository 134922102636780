import styled from "styled-components";
import {useState} from "react";

const SwitchLabelStyled = styled.label `
  position: relative;
  display: inline-block;
  width: 80px;
  height: 34px;
  margin: 0;

  input {
    opacity: 0;
    width: 0;
    height: 0;
  }
`;

const StyledSlider = styled.span `
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0);
  -webkit-transition: .7s;
  transition: .7s;
  border-radius: 25px;
  border: 2px solid ${props => props.checked ? props.theme.colors.black : props.theme.colors.primary};

  :before {
    position: absolute;
    content: "";
    color: white;
    height: 26px;
    width: 26px;
    left: 2px;
    bottom: 2px;
    background-color: ${props => props.checked ? props.theme.colors.white : props.theme.colors.primary};
    -webkit-transition: .7s;
    transition: .7s;
    border-radius: 50%;
  }
`;

const CheckBoxInput = styled.input `
  ${props => props.checked && `
    :checked + .slider {
      background-color: ${props.theme.colors.success};
    } 
 
    :checked + .slider:before {
      -webkit-transform: translateX(45px);
      -ms-transform: translateX(45px);
      transform: translateX(45px);
    }
  `
  }}

  :focus + .slider {
    box-shadow: 0 0 1px #2196F3;
  }
`;

const SwitchInput = ({labelText = ['',''], onChange, checked}) => {
  const [checkedBox, setChecked] = useState(checked);
  return(
    <SwitchLabelStyled className="switch">
      <CheckBoxInput checked={checkedBox} type="checkbox" onClick={() => {
        setChecked(!checkedBox)
      }} value={checkedBox ? labelText[0] : labelText[1]} onChange={onChange}/>
      <StyledSlider
        checked={checkedBox} className="slider round"
        style={{border: ``}}
      />
      <div style={{position: "relative", top: "-20px", transform: `${checkedBox ? 'translate(-15px)' : 'translate(15px)'}`}}>
        <span style={{color: 'white', fontSize: "1rem", fontWeight: '700'}}>{checkedBox ? 'Vrai' : 'Faux'}</span>
      </div>
    </SwitchLabelStyled>
  );
};

export default SwitchInput;