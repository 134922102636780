import { Button } from 'components/form/button';
import { Form, Message } from 'semantic-ui-react';
import React, { useEffect, useState } from "react";
import { Link, useLocation } from 'react-router-dom';
import AuthPageStructure from './AuthPageStructure';
import HCaptcha from "@hcaptcha/react-hcaptcha";
import useDocumentTitle from 'hooks/use-document-title';
import FacebookLoginButton from "../../components/Oauth/FacebookLoginButton";
// import GoogleLoginButton from "../../components/Oauth/GoogleLoginButton";
import Divider from "../../components/common/Divider";
import { useTranslation } from "react-i18next";
import GoogleLoginButton from 'components/Oauth/GoogleLoginButton';






const convertDate = (date) =>
  date.getFullYear() + '-'
  + String(date.getMonth() + 1).padStart(2, '0') + '-'
  + String(date.getDate()).padStart(2, '0');


const RegisterPage = ({ handleSubmit, error, success, handleFacebookSubmit, handleGoogleSubmit }) => {


  const { t } = useTranslation(["henriTrip"])

  const genders = [
    { text: t("general.male"), value: "Male" },
    { text: t("general.female"), value: "Female" },
    { text: t("general.other"), value: "Other" }
  ]

  useDocumentTitle(t("register.documentTitleRegister"));
  const query = new URLSearchParams(useLocation().search)
  const [state, setState] = useState({
    firstname: "",
    lastname: "",
    username: "",
    email: "",
    password: "",
    password_confirmation: "",
    phone: "",
    birthdate: "",
    gender: "",
    statement: false,
    newsletter: false,
    profile_photo: [],
  })
  const [captcha, setCaptcha] = useState(false)
  const [correct, setCorrect] = useState({
    birthdate: true,
    username: true,
    email: true,
    password: true,
    password_confirmation: true,
  })
  const today = new Date();
  const minDate = new Date(1900, 1, 1);
  
  //Facebook login Register
  const [login, setLogin] = useState(null);
 
 

  useEffect(() => {
    if (login) {
      handleFacebookSubmit(login)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [login])


  const captchaRef = React.createRef();

  const handleChange = (e, { name, value, checked }) => {
    if (name === "statement") {
      setState({ ...state, statement: checked })
    } else if (name === "newsletter") {
      setState({ ...state, newsletter: checked })
    } else {
      setState({ ...state, [name]: value })
      setCorrect({ ...correct, [name]: true })
      if (error[name]) error[name] = null
    }
  }

  const submit = () => {
    const birthdateCorrect = !(state.birthdate < convertDate(minDate) || state.birthdate > convertDate(today));
    const usernameCorrect = state.username.match(/^([A-Za-z0-9_]){4,64}$/);
    const emailCorrect = state.email.match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
    const passwordCorrect = !(state.password.length < 8 || !state.password.match(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$:%^&;,{}<>=|~.*_+-]).{8,70}$/));
    const confirmPassword = state.password === state.password_confirmation;

    if (birthdateCorrect && usernameCorrect && emailCorrect && passwordCorrect && confirmPassword) {
      handleSubmit(state)
    } else {
      setCorrect({
        username: usernameCorrect,
        email: emailCorrect,
        password: passwordCorrect,
        password_confirmation: confirmPassword,
        birthdate: birthdateCorrect,
      })
      setCaptcha(false)
    }
    captchaRef.current.resetCaptcha()
  }

  return (
    <AuthPageStructure>
    <Form success={success} >
      <h2 className="text-center pb-3">{t("register.title")}</h2>
      {
        success ?
          <Message
            success
            header={t("register.successInscription.header")}
            content={t("register.successInscription.content")}
          />
          :
            <>
              {/* <Form.Field>
              <GoogleLoginButton handleGoogleSubmit={handleGoogleSubmit} title={t("register.buttonRegisterGoogle")}/>
                </Form.Field> */}
                <Form.Field>
                  <FacebookLoginButton onLoginSuccess={setLogin} title={t("register.buttonRegisterFacebook")} />
                </Form.Field>
                <Form.Field>
                  <div>
                    {t("login.conditionOfUse.firstPart")}<a target="_blank" className="font-weight-bold" href={"https://support.henritrip.fr/conditions-generales-dutilisation-du-site-internet-henri-trip-et-notifications/"} rel="noreferrer">{t("login.conditionOfUse.secondPart")}</a> {t("login.conditionOfUse.thirdPart")} <a target="_blank" className="font-weight-bold" href={"https://support.henritrip.fr/politique-de-confidentialite/"} rel="noreferrer">{t("login.conditionOfUse.fourthPart")}</a>.
                  </div>
                </Form.Field>
                <Form.Field>
                  <Divider>ou</Divider>
                </Form.Field>
                <Form.Input
                  id="firstname"
                  required={true}
                  name="firstname"
                  type="name"
                  onChange={handleChange}
                  value={state.firstname}
                  label={t("general.firstName")}
                  placeholder={"Henri"}
                />
                <Form.Input
                  id="lastname"
                  required={true}
                  name="lastname"
                  type="name"
                  onChange={handleChange}
                  value={state.lastname}
                  placeholder={"De Toulouse-Lautrec"}
                  label={t("general.name")}
                />
                <Form.Select
                  fluid
                  id="gender"
                  required={true}
                  name="gender"
                  onChange={handleChange}
                  value={state.gender}
                  options={genders}
                  label={t("general.gender")}
                  placeholder={t("general.gender")}
                />
                <Form.Input
                  id="birthdate"
                  name="birthdate"
                  min="1900-01-01"
                  max={convertDate(today)}
                  type="date"
                  required={true}
                  onChange={handleChange}
                  value={state.birthdate}
                  label={t("general.birthdate")}
                />
                <Message
                  color="red"
                  hidden={correct.birthdate}
                  header={t("register.errorBirthdate.header")}
                  content={t("register.errorBirthdate.content")}
                />
                <Form.Input
                  id="username"
                  name="username"
                  required={true}
                  type="name"
                  placeholder={t("register.username")}
                  label={t("register.username")}
                  onChange={handleChange}
                  value={state.username}
                />
                <Message
                  color="red"
                  hidden={correct.username}
                  header={t("register.errorUsername.firstError.header")}
                  content={t("register.errorUsername.firstError.content")}
                />
                <Message
                  color="red"
                  hidden={!(error?.username)}
                  header={t("register.errorUsername.secondError.header")}
                  content={t("register.errorUsername.secondError.content")}
                />
                <Form.Input
                  id="email"
                  name="email"
                  required={true}
                  type="email"
                  placeholder="Hello@henri.fr"
                  label="Email"
                  onChange={handleChange}
                  value={state.email}
                />
                <Message
                  color="red"
                  hidden={correct.email}
                  header={t("register.errorEmail.firstError.header")}
                  content={t("register.errorEmail.firstError.content")}
                />
                <Message
                  color="red"
                  hidden={!(error?.email)}
                  header={t("register.errorEmail.secondError.header")}
                  content={t("register.errorEmail.secondError.content")}
                />
                <Form.Input
                  id="password"
                  name="password"
                  required={true}
                  type="password"
                  placeholder={"•••••••••"}
                  label={t("general.password")}
                  onChange={handleChange}
                  value={state.password}
                />
                <Message
                  color="red"
                  hidden={correct.password}
                  header={t("general.errorPassword.header")}
                  content={t("general.errorPassword.content")}
                />
                <Form.Input
                  id="password_confirmation"
                  name="password_confirmation"
                  required={true}
                  type="password"
                  placeholder={"•••••••••"}
                  label={t("general.confirmPassword")}
                  onChange={handleChange}
                  value={state.password_confirmation}
                />
                <Message
                  color="red"
                  hidden={correct.password_confirmation}
                  header={t("general.errorConfirmPassword.header")}
                  content={t("general.errorConfirmPassword.content")}
                />
                <Form.Checkbox
                  id="statement"
                  name="statement"
                  value="statement"
                  checked={state.statement === true}
                  label={
                    <label>
                      {t("register.accept")} <a target="_blank" className="font-weight-bold" href={"https://support.henritrip.fr/conditions-generales-dutilisation-du-site-internet-henri-trip-et-notifications/"} rel="noreferrer">{t("login.conditionOfUse.secondPart")}</a> {t("login.conditionOfUse.thirdPart")} <a target="_blank" className="font-weight-bold" href={"https://support.henritrip.fr/politique-de-confidentialite/"} rel="noreferrer">{t("login.conditionOfUse.fourthPart")}</a>.
                    </label>
                  }
                  onChange={handleChange}
                />
                <Form.Checkbox
                  id="newsletter"
                  name="newsletter"
                  value="newsletter"
                  checked={state.newsletter === true}
                  label={
                    <label>
                      {t("register.newsletter")}
                    </label>
                  }
                  onChange={handleChange}
                />
                <Form.Field>
                  <HCaptcha
                    ref={captchaRef}
                    sitekey={process.env.REACT_APP_HCAPTCHA_API_KEY}
                    onVerify={() => setCaptcha(true)}
                  />
                </Form.Field>
                <Form.Field>
                  <Button
                    extended
                    onClick={submit}
                    color="henri"
                    title={t("general.register")}
                    disabled={
                      state.firstname === "" ||
                      state.lastname === "" ||
                      state.gender === "" ||
                      state.birthdate === "" ||
                      state.email === "" ||
                      state.username === "" ||
                      state.password === "" ||
                      state.password_confirmation === "" ||
                      !state.statement ||
                      !captcha
                    }
                  />
                </Form.Field>
                <Link to={`/login?redirectURL=${query.get("redirectURL")}`} className="font-weight-bolder text-center mt-2">{t("register.haveAccount")}</Link>
              </>
        }
            </Form>
    </AuthPageStructure>
  );
};

export default RegisterPage;
