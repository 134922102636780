import React, {useState} from "react";
import { ReactComponent as AddIcon } from '../../../../assets/img/logo/addIcon.svg';
import {ReactComponent as AudioPlayerIcon} from '../../../../assets/img/logo/AVPMBuilder/audio/icon_player.svg';
import {ReactComponent as AudioPlayerImageIcon} from '../../../../assets/img/logo/AVPMBuilder/audio/icon_audio_image.svg';
import TitleAudioImageForm from "./TitleAudioImageForm";
import TitleAudioForm from "./TitleAudioForm";
import styled from "styled-components";

const FormContainerStyled = styled.div`
  display:flex;
  flex-direction: column; 
  gap: 40px;
  cursor: pointer; 
  width: 70%; 
  padding: 0.5em; 
  background-color: rgba(255,255,255, 0.15); 
  border-radius: 1.3em;
`;

const AudioFormToChoose = ({onLayoutItemChange, id, audio, editable=true, activeStorageServiceName}) => {
  const [selectedForm, setSelectedForm] = useState(null);
  const [formOpen, setFormOpen] = useState(null);

  const dataInformation = [
    {
      title:
        <div style={{gap: '20px', display: 'flex', alignItems: "center", justifyContent: 'center'}}>
          <AudioPlayerIcon/>
          <span style={{color: 'black', fontWeight: '700'}}>Audio + Titre</span>
        </div>,
      content:
        <TitleAudioForm
          onLayoutItemChange={onLayoutItemChange}
          editable={editable}
          activeStorageServiceName={activeStorageServiceName}
          audiosToDisplay={audio}
          id={id}
        />
    },
    {
      title:
        <div style={{gap: '20px', display: 'flex', alignItems: "center", justifyContent: 'center'}}>
          <AudioPlayerImageIcon/>
          <span style={{color: 'black', fontWeight: '700'}}>Parcours Audio</span>
        </div>,
      content:
        <TitleAudioImageForm
          onLayoutItemChange={onLayoutItemChange}
          editable={editable}
          activeStorageServiceName={activeStorageServiceName}
          audiosToDisplay={audio}
          id={id}
        />
    }
  ];

  return (
    <FormContainerStyled>
      <div>
        <div onClick={() => {
               setFormOpen(!formOpen)
               setSelectedForm(!selectedForm)
             }}>
          <AddIcon style={{cursor: "pointer", color: `${!selectedForm ? '#54A8FC' : 'white'}`}}/>
        </div>
      </div>
      {
        !selectedForm && dataInformation.map((formData, index) => (
          <div
            key={index}
            onClick={() => setSelectedForm(formData.content)}
            style={{cursor: 'pointer', padding: '0.5em', backgroundColor: 'white', borderRadius: '1.3em'}}
          >
            <span style={{color: 'black', fontWeight: '700'}}>{formData.title}</span>
          </div>
        ))
      }
      {selectedForm}
    </FormContainerStyled>
  );
};

const FormikAudio = ({id, onLayoutItemChange, onSuccess, audiosToDisplay, activeStorageServiceName}) => {
  return(
      <AudioFormToChoose
        key={id}
        id={id}
        activeStorageServiceName={activeStorageServiceName}
        onLayoutItemChange={onLayoutItemChange}
        onSuccess={onSuccess}
        audio={audiosToDisplay}
      />
  );
};

export default FormikAudio;