import SliderButton from 'components/form/slider/slider-button';
import usePagination from 'hooks/use-pagination';
import React from 'react';

const Pagination = ({list, itemsNumber, itemsPerPage, onChange}) => {
  const [currentPage, pages, setCurrentPage] = usePagination(list, itemsPerPage, itemsNumber);

  const handleButton = (d) => {
    if (currentPage + d >= 0 && currentPage + d < pages?.length) {
      setCurrentPage(currentPage + d);
      if (onChange) {
        onChange(currentPage + d);
      }
    }
  }

  const handlePageSelection = (n) => {
    setCurrentPage(n);
    if (onChange) {
      onChange(n);
    }
  }

  const l = itemsNumber ?? list?.length;

  return (
    <div className="d-flex flex-column align-items-center">
      <div className="row py-3">
        {
          pages?.map((el, i) => {
            if ((i < currentPage - 2 && i !== 0) || (i > currentPage + 2 && pages.length - 1 > i)) {
              return null;
            }

            return (<div className="col px-1" key={el}>
              <div className={`border-bottom border-${currentPage === i ? "dark" : "muted"}`} onClick={() => handlePageSelection(i)} style={{cursor: "pointer"}}>
                {
                  ((i === currentPage - 2 && i !== 0) || (i === currentPage + 2 && pages.length - 1 !== i)) ?
                    <p className="my-0 mx-1 text-muted">...</p>
                    :
                    <p className={`my-0 mx-1 text-${currentPage === i ? "dark" : "muted"}`}>{el}</p>
                }
              </div>
            </div>);
          })
        }
      </div>
      <SliderButton current={currentPage} total={l ?? 0} onChange={handleButton} />
    </div>
  );
};

export default Pagination;
