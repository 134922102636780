import styled from 'styled-components';
import LabelWrapper from './LabelWrapper';
import InputErrorWrapper from './InputErrorWrapper';

const SwitchInputStyled = styled.div`
  input {
    display: none;
  }
  
  input + label {
    box-sizing: border-box;
    display: inline-block;
    width: 3rem;
    height: 1.5rem;
    border-radius: 1.5rem;
    padding: 2px;
    background-color: ${props => props.theme.colors.lightGrey};
    transition: all 0.5s;
    vertical-align: middle;
  }
  
  input + label::before {
    box-sizing: border-box;
    display: block;
    content: "";
    height: calc(1.5rem - 4px);
    width: calc(1.5rem - 4px);
    border-radius: 50%;
    background-color: ${props => props.theme.colors.white};
    transition: all 0.5s ;
  }

  input:checked + label {
    background-color: ${props => props.theme.colors.primary};
  }
  
  input:checked + label::before {
    margin-left: 1.5rem ;
  }
`;

const SwitchLabel = styled(LabelWrapper)`
  width: 100%;
  display: grid;
  grid-template-columns: auto min-content;
  grid-gap: 1em;
  
  label {
    margin-bottom: initial;
    font-size: initial;
  }
`;

const SwitchInput = ({name, label, errorMessage, ErrorComponent, hasError, style, ...props}) => (
  <InputErrorWrapper
    errorMessage={errorMessage}
    ErrorComponent={ErrorComponent}
    hasError={hasError}
    style={style}
  >
    <SwitchLabel
      name={name}
      label={label}
    >
      <SwitchInputStyled>
        <input
          type="checkbox"
          name={name}
          id={name}
          {...props}
        />
        <label htmlFor={name}/>
      </SwitchInputStyled>
    </SwitchLabel>
  </InputErrorWrapper>
);

export default SwitchInput;