import React, {useEffect, useState} from 'react';

const File = ({data}) => {
  const [urls, setUrls] = useState(
    data
      ? data
          .filter((el) => el.id)
          .map((el) => ({url: el.url.replace(/\?.*/, ''), text: el.filename}))
      : []
  );

  useEffect(() => {
    setUrls(
      data
        ? data
            .filter((el) => el.id)
            .map((el) => ({url: el.url.replace(/\?.*/, ''), text: el.filename}))
        : []
    );
  }, [data]);

  return (
    <ul className="my-3">
      {urls.map((el, index) => {
        return (
          <li key={index}>
            <a target="_blank" href={el.url} rel="noreferrer">
              {el.text}
            </a>
          </li>
        );
      })}
    </ul>
  )
};

export default File;
