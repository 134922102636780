import styled from "styled-components";
import {breakpoint} from "styled-components-breakpoint";
import Button from '../../../_ui/_v2/components/Button';

import { ReactComponent as PictoVoyage } from "../../../../src/assets/img/logo/Picto_voyage.svg";
import { ReactComponent as PineIcon } from "../../../../src/assets/img/logo/pine_localisation.svg";
import {useTranslation} from "react-i18next";

const RoadTripHeaderStyled = styled.div`
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: auto auto;
  column-gap: 1em;
  font-size: 22px;
  
  svg {
    color: ${props => props.theme.colors.primary}
  }

  > * {
    &:first-child {
      display: none;
    }
    
    &:last-child{
      display: inline-flex;
      align-items: center;
      justify-content: flex-end;
    }

  }


  ${breakpoint('md')`
  grid-template-columns: 1fr 2fr 1fr;

  > * {
    &:first-child {   
      display: inline-flex;
      align-items: center;
      justify-content: flex-start;
    }
    &:nth-child(2) {
      display: inline-flex;
      align-item: center;
      justify-content: center;
    }
  }
  `}
`;

const BuyButton = styled(Button)`
  display: none;
  
  
  ${breakpoint('md')`
    display: inline-block;
    margin-left: .75em;
    font-size: 16px;
  `}
`;

const RoadTripHeader = ({ roadtrip, days, onBuyButtonClick = () => {},...rest }) => {
  const {t, i18n} = useTranslation(["experiences"]);
  const title = roadtrip.title?.[`text_${i18n.language}`] || roadtrip.title?.text_fr;

  return (
    <RoadTripHeaderStyled {...rest}>
      <div>
        <PictoVoyage width="40" height="55" />
        <span
          style={{
            margin: "0 0 0 .5em",
            fontWeight: 600,
          }}
        >
        {days} {t(days <= 1 ? "title-component.day" : "title-component.days")}
      </span>
      </div>
      <div style={{ display: "inline-flex", alignItems: "center" }}>
        <PineIcon width="20" height="30" />
        <h1
          style={{ margin: "0 0 0 0.5em" }}
        >
          {title}
        </h1>
      </div>
      {roadtrip.amount > 0 && !roadtrip.purchased && (
        <div>
        <span
          style={{
            fontWeight: 700,
          }}
        >
          {
            new Intl.
            NumberFormat('fr-FR', { style: 'currency', currency: roadtrip.currency }).
            format(roadtrip.amount / 100)
          }
        </span>
          <BuyButton
            rounded
            outlined
            small
            onClick={onBuyButtonClick}
          >
            {t("title-component.button")}
          </BuyButton>
        </div>
      )}
    </RoadTripHeaderStyled>
  );
};

const RoadTripHeaderSection = RoadTripHeader;

export default RoadTripHeaderSection;