import {configureStore} from '@reduxjs/toolkit';
import reducers from '../reducers'
import { persistStore } from 'redux-persist';

const store = configureStore({
    reducer: reducers
})

export const persistor = persistStore(store);

export default store;