import styled from "styled-components";
import {useEffect, useRef, useState} from "react";

const CardsInformationSliderStyled = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 1em;
  position: relative;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  text-align: center;
`;

const NavigationDonut = styled.div`
  display: inline-block;
  width: 15px;
  height: 15px;
  margin: 2px;
  border-radius: 50%;
  border: 1px solid ${props => props.selected ? props.theme.colors.primary : props.theme.colors.darkGrey90};
  background-color: ${props => props.selected ? props.theme.colors.primary : props.theme.colors.lightGrey};
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;
  max-width: 100%;
  overflow-x: scroll;
  touch-action: none;
  scroll-behavior: smooth;
  -ms-overflow-style: none;
  &::-webkit-scrollbar{
    display: none;
  }
`;

const ChildContainer = styled.div`
  display: inline-block;
  height: 100%;
  width: 100%;
  flex: 0 0 100%;
`;

const CardsInformationSlider = ({selectedIndex = 0, cards, ...rest}) => {
  const [selected, setSelected] = useState(selectedIndex);
  const containerRef = useRef();
  const touchStartClientX = useRef(0);

  useEffect(() => {
    setSelected(selectedIndex);
  }, [selectedIndex]);

  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.scrollTo(containerRef.current.scrollWidth / cards.length * selected, 0);
    }
  }, [selected]);

  return (
    <CardsInformationSliderStyled {...rest}>
      <ContentContainer
        ref={containerRef}
        onWheel={() => (false)}
        onTouchStart={event => touchStartClientX.current = event.touches[0].clientX}
        onTouchEnd={event => {
          if (Math.abs(event.changedTouches[0].clientX - touchStartClientX.current) < 5) {
            return;
          }
          const next = event.changedTouches[0].clientX < touchStartClientX.current ? 1 : -1;
          setSelected(prev => ((prev + cards.length + next) % cards.length));
          event.stopPropagation();
        }}
      >
        {
          cards.map((card, index) => (
            <ChildContainer
              key={index}
              selected={index === selected}
            >
              {card}
            </ChildContainer>
          ))
        }
      </ContentContainer>
      <div
        style={{
          padding: 10,
        }}
      >
        {
          cards.map((_, index) => (
            <NavigationDonut
              key={index}
              selected={index === selected}
              onClick={() => setSelected(index)}
            />
          ))
        }
      </div>
    </CardsInformationSliderStyled>
  );
};

export default CardsInformationSlider;