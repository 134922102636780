import { Button } from 'components/form/button';
import React from 'react';

import './title.scss'

const Title = ({title, description, button, children, handleButton, noTopPad, className}) => {
  return (
    <div className={`${noTopPad || "pt-3 pt-lg-4"} main ${className}`}>
      <div>
        <h1 className="title text-primary pb-1">
          {title}
        </h1>
        {
        description &&
        <h3 className="text-dark size-description-title">{description}</h3>
        }
      </div>
      {
        button &&
        <Button rounded outline color="dark" className="px-4 shadow-lg" title={button} onClick={handleButton} />
      }
      {
        children
      }
    </div>
  );
};

export default Title;
