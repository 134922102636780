import React, { useContext, useState } from "react";
import {
  API_DIRECT_UPLOAD_TEXT,
  API_ORIGINAL_BLOB_URL,
  API_UPDATE_TEXTS,
} from "../../../../utils/api";
import { useFormik } from "formik";
import styled, { ThemeContext } from "styled-components";
import FileInput, {
  FileInputLabel,
} from "../../../../_ui/_v2/components/FormElements/FileInput";
import Loader from "../../../../_ui/_v2/components/Loader";
import ButtonFormValidation from "./../ButtonFormValidation";
import { ReactComponent as AudioIcon } from "../../../../assets/img/logo/Activity/icon_headphones.svg";
//les icones pour photo
import {
  audioSchema,
  audioTitleSchema,
  titleSchema,
  imageSchema,
  audioTitleImageSchema,
} from "../../../_schemas/media_layout_builder_schemas";
import { OneAudioToShow } from "../../../../_ui/_v2/components/Audio/AudioToShow";
import { ReactComponent as ImageUploadIcon } from "../../../../assets/img/logo/image-upload-icon.svg";
import {useTranslation} from "react-i18next";

const FormVideoStyled = styled.form`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 25px;
`;

const ContainerVideo = styled.div`
  width: 80%;

  label {
    width: 80%;
  }
`;

const ButtonDelete = styled.button`
  display: inline-block;
  box-sizing: border-box;
  padding: 0.5rem 2rem;
  font-weight: 600;
  border: none;
  background-color: ${(props) => props.theme.colors.danger};
  color: ${(props) => props.theme.colors.white};
  border-radius: 0.2rem;
  vertical-align: middle;
`;

const InputStyled = styled.input`
  background-color: rgba(0, 0, 0, 0.8);
  border: 1px solid rgba(169, 169, 169, 0.6);
  color: white;
  padding: 0.7em 1em 0.5em 0.5em;
  resize: none;
  border-radius: 0.5em;
  width: 100%;
  ::placeholder {
    color: white;
  }
`;

const FirstPartOfAudioStyled = styled.div`
  border: 2px dashed ${(props) => props.theme.colors.black};
  border: ${(props) => props.finished && "none"};
  width: 75%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 25px;
`;

const VideoFormContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  margin-top: 1em;
  margin-bottom: 1em;
`;

const FileInputContainer = styled.div`
  height: 200px;
  display: flex;
  background-color: ${(props) => props.theme.colors.fileInputEmpty};
  background-image: ${(props) =>
    props.backgroundImageUrl ? `url('${props.backgroundImageUrl}')` : "none"};
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  ${(props) =>
    !props.backgroundImageUrl &&
    `
  border: 2px dashed ${props.theme.colors.henriBlue};
  `}
`;

const AudioToShowForm = ({
  audio,
  activeStorageServiceName,
  id,
  onLayoutItemChange,
  onSuccess,
  onDelete,
}) => {
  const [previewUrlAudio, setPreviewUrlAudio] = useState();
  const [finished, setFinished] = useState(false);
  const theme = useContext(ThemeContext);
  const [loading, setLoading] = useState(false);
  const callback = (payload) => {
    const medias = values?.items.filter((e, index) => e.type !== "Title");
    const update = Object.keys(audio).length > 0;
    const builderSchema = {
      type: 'Title',
      content: payload
    }
    values.items.splice(0, 1, builderSchema)
    onLayoutItemChange(values, medias, update, {
      onSuccess: (response) => {
        onSuccess?.(response);
        setLoading(false);
      },
      onError: (error) => {
        console.error(error);
        setLoading(false);
      },
    });
  };

  const handleSubmitForm = (values) => {
    setLoading(true);
    const token = localStorage.getItem("Authorization");
    const arrayToPush = [];
    const title = values.items.filter((item) => item.type === "Title")[0]
      .content;
    arrayToPush.push(title);
    setLoading(true);
    fetch(title?.id ? API_UPDATE_TEXTS : API_DIRECT_UPLOAD_TEXT, {
      method: title?.id ? "PUT" : "POST",
      cache: "default",
      body: JSON.stringify({ texts: arrayToPush }),
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      mode: "cors",
    })
      .then((res) => res.json())
      .then((response) => {
        // Object.keys(values).forEach((key,index) => {
        //   arrayToPush.push(values[key].items.filter(item => item.type === 'Title').splice(index, 1, )
        // })
        callback(response.length > 1 ? response : { id: response[0].id });
      })
      .catch((error) => {
        setLoading(false);
        console.error(error);
      });
  };
  const { handleSubmit, setFieldValue, values, errors } = useFormik({
    initialValues: audioTitleSchema.cast(audio),
    validationSchema: audioTitleSchema,
    onSubmit: handleSubmitForm,
  });

  const fileAudio = values?.items?.[1]?.content;
  const fileAudioUrl = fileAudio?.blob_id
    ? API_ORIGINAL_BLOB_URL(fileAudio.signed_id, fileAudio.filename)
    : previewUrlAudio;

  const {i18n} = useTranslation();
  const textKey = `text_${i18n.language}`;
  return (
    <FormVideoStyled onSubmit={handleSubmit}>
      <FirstPartOfAudioStyled finished={finished}>
        {
          <VideoFormContainer>
            <div style={{width: '65%'}}>
              <InputStyled
                id={textKey}
                name={`items[0].content.${textKey}`}
                value={values?.items?.[0]?.content?.[textKey]}
                placeholder="Titre"
                onChange={(event) => {
                  Object.keys(audio).length > 0
                    ? setFieldValue(
                        `items[0][content][${textKey}]`,
                        event.target.value
                      )
                    : setFieldValue(
                        `items[0]`,
                        titleSchema.cast({
                          content: { [textKey]: event.target.value },
                        })
                      );
                }}
                type="text"
              />
            </div>
            <ContainerVideo>
              <FileInput
                id={id}
                name={`items[1].content.signed_id`}
                accept="audio/aac, audio/mp3, audio/ogg"
                activeStorageServiceName={activeStorageServiceName}
                value={values?.items?.[1]?.content?.signed_id}
                onBlobUrlChange={setPreviewUrlAudio}
                onChange={(event) => {
                  if (
                    event.target.value !==
                    values?.items?.[1]?.content?.signed_id
                  ) {
                    setFieldValue(
                      `items[1]`,
                      audioSchema.cast({
                        content: { signed_id: event.target.value },
                      })
                    );
                  }
                }}
              >
                {({ ready, globalProgress }) => (
                  <FileInputLabel
                    disabled={!ready}
                    progress={globalProgress}
                    style={{ border: "none", backgroundColor: "black" }}
                  >
                    {ready ? (
                      <div
                        style={{
                          display: "flex",
                          gap: "25px",
                          alignItems: "center",
                        }}
                      >
                        <AudioIcon />
                        {/*trouver condition pour modifier/charger */}
                        {values?.items?.[1]?.content?.signed_id
                          ? "Modifier votre audio"
                          : "Charger votre audio"}
                      </div>
                    ) : (
                      <>
                        <Loader
                          style={{
                            display: "inline",
                            margin: "0 1em 0 0",
                            height: 25,
                          }}
                        />
                        {"Upload en cours"}
                      </>
                    )}
                  </FileInputLabel>
                )}
              </FileInput>
              {fileAudioUrl && <OneAudioToShow audio={fileAudioUrl} />}
            </ContainerVideo>
          </VideoFormContainer>
        }
      </FirstPartOfAudioStyled>
      <div
        style={{
          display: "flex",
          gap: "20px",
          justifyContent: "center",
        }}
      >
        <ButtonFormValidation loading={loading} placeholder="Enregistrer" />
        <ButtonDelete onClick={onDelete} type="button" theme={theme}>
          Supprimer
        </ButtonDelete>
      </div>
    </FormVideoStyled>
  );
};

const AudioImageToShowForm = ({
  audio,
  activeStorageServiceName,
  id,
  onLayoutItemChange,
  onSuccess,
  onDelete,
}) => {
  const [previewUrlImage, setPreviewUrlImage] = useState();
  const [previewUrlAudio, setPreviewUrlAudio] = useState();
  const [finished, setFinished] = useState(false);
  const theme = useContext(ThemeContext);
  const [loading, setLoading] = useState(false);
  const callback = (payload) => {
    const medias = values?.items.filter((e, index) => e.type !== "Title");
    const update = Object.keys(audio).length > 0;
    const builderSchema = {
      type: 'Title',
      content: payload
    }
    values.items.splice(0, 1, builderSchema)
    onLayoutItemChange(values, medias, update, {
      onSuccess: (response) => {
        onSuccess?.(response);
        setLoading(false);
      },
      onError: (error) => {
        console.error(error);
        setLoading(false);
      },
    });
  };

  const handleSubmitForm = (values) => {
    setLoading(true);
    const token = localStorage.getItem("Authorization");
    const arrayToPush = [];
    const title = values.items.filter((item) => item.type === "Title")[0].content;
    arrayToPush.push(title);
    setLoading(true);
    fetch(title?.id ? API_UPDATE_TEXTS : API_DIRECT_UPLOAD_TEXT, {
      method: title?.id ? "PUT" : "POST",
      cache: "default",
      body: JSON.stringify({ texts: arrayToPush }),
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      mode: "cors",
    })
      .then((res) => res.json())
      .then((response) => {
        // Object.keys(values).forEach((key,index) => {
        //   arrayToPush.push(values[key].items.filter(item => item.type === 'Title').splice(index, 1, )
        // })
        callback(response.length > 1 ? response : { id: response[0].id });
      })
      .catch((error) => {
        setLoading(false);
        console.error(error);
      });
  };
  const { handleSubmit, setFieldValue, values, errors } = useFormik({
    initialValues: audioTitleImageSchema.cast(audio),
    validationSchema: audioTitleImageSchema,
    onSubmit: handleSubmitForm,
  });

  const fileAudio = values?.items?.[1]?.content;
  const fileAudioUrl = fileAudio?.blob_id
    ? API_ORIGINAL_BLOB_URL(fileAudio.signed_id, fileAudio.filename)
    : previewUrlAudio;

  const fileImage = values?.items?.[2]?.content;
  const fileImageUrl = fileImage?.blob_id
    ? API_ORIGINAL_BLOB_URL(fileImage.signed_id, fileImage.filename)
    : previewUrlImage;

  const {i18n} = useTranslation();
  const textKey = `text_${i18n.language}`;
  return (
    <FormVideoStyled onSubmit={handleSubmit}>
      <FirstPartOfAudioStyled finished={finished}>
        {
          <VideoFormContainer>
            <div style={{width: '65%'}}>
              <InputStyled
                id={textKey}
                name={`items[0].content.${textKey}`}
                value={values?.items?.[0]?.content?.[textKey]}
                placeholder="Titre"
                onChange={(event) => {
                  Object.keys(audio).length > 0
                    ? setFieldValue(
                        `items[0][content][${textKey}]`,
                        event.target.value
                      )
                    : setFieldValue(
                        `items[0]`,
                        titleSchema.cast({
                          content: { [textKey]: event.target.value },
                        })
                      );
                }}
                type="text"
              />
            </div>
            <ContainerVideo>
              <FileInput
                id={id}
                name={`items[1].content.signed_id`}
                accept="audio/aac, audio/mp3, audio/ogg"
                activeStorageServiceName={activeStorageServiceName}
                value={values?.items?.[1]?.content?.signed_id}
                onBlobUrlChange={setPreviewUrlAudio}
                onChange={(event) => {
                  if (
                    event.target.value !==
                    values?.items?.[1]?.content?.signed_id
                  ) {
                    setFieldValue(
                      `items[1]`,
                      audioSchema.cast({
                        content: { signed_id: event.target.value },
                      })
                    );
                  }
                }}
              >
                {({ ready, globalProgress }) => (
                  <FileInputLabel
                    disabled={!ready}
                    progress={globalProgress}
                    style={{ border: "none", backgroundColor: "black" }}
                  >
                    {ready ? (
                      <div
                        style={{
                          display: "flex",
                          gap: "25px",
                          alignItems: "center",
                        }}
                      >
                        <AudioIcon />
                        {/*trouver condition pour modifier/charger */}
                        {values?.items?.[1]?.content?.signed_id
                          ? "Modifier votre audio"
                          : "Charger votre audio"}
                      </div>
                    ) : (
                      <>
                        <Loader
                          style={{
                            display: "inline",
                            margin: "0 1em 0 0",
                            height: 25,
                          }}
                        />
                        {"Upload en cours"}
                      </>
                    )}
                  </FileInputLabel>
                )}
              </FileInput>
              {fileAudioUrl && <OneAudioToShow audio={fileAudioUrl} />}
              <FileInputContainer backgroundImageUrl={fileImageUrl}>
                <FileInput
                  id={id}
                  name={`items[2].content.signed_id`}
                  accept="image/*"
                  activeStorageServiceName="touristic_site_scaleway"
                  onChange={(event) => {
										if (
											event.target.value !== values?.items?.[2]?.content?.signed_id
										) {
											setFieldValue(
												`items[2]`,
												imageSchema.cast({
													content: { signed_id: event.target.value },
												})
											);
										}
									}}
                  value={values?.items?.[2]?.content?.signed_id}
                  onBlobUrlChange={setPreviewUrlImage}
                  style={{ margin: "auto" }}
                >
                  {({ ready, globalProgress }) => (
                    <FileInputLabel
                      disabled={!ready}
                      progress={globalProgress}
                      style={{ border: "none" }}
                    >
                      {ready ? (
                        <>
                          <ImageUploadIcon
                            style={{ marginRight: "1em", height: 25 }}
                          />
                          {values?.items?.[2]?.content?.signed_id
                            ? "Modifier l'image"
                            : "Ajouter une image"}
                        </>
                      ) : (
                        <>
                          <Loader
                            style={{
                              display: "inline",
                              margin: "0 1em 0 0",
                              height: 25,
                            }}
                          />
                          {"Upload en cours"}
                        </>
                      )}
                    </FileInputLabel>
                  )}
                </FileInput>
                <input
                  type="hidden"
                  name={`items[2]`}
                  value={values?.items?.[2]?.content?.signed_id}
                />
              </FileInputContainer>
            </ContainerVideo>
          </VideoFormContainer>
        }
      </FirstPartOfAudioStyled>
      <div
        style={{
          display: "flex",
          gap: "20px",
          justifyContent: "center",
        }}
      >
        <ButtonFormValidation loading={loading} placeholder="Enregistrer" />
        <ButtonDelete onClick={onDelete} type="button" theme={theme}>
          Supprimer
        </ButtonDelete>
      </div>
    </FormVideoStyled>
  );
};

export { AudioToShowForm, AudioImageToShowForm };
