import React, {useEffect, useState} from 'react';
import './activity.scss'
import {useTranslation} from "react-i18next";

export function ActivityCard({
  item,
  defaultImg,
  language,
  setActiveVisitSheet,
  activeVisitSheet,
}) {
  const {t} = useTranslation(["henriTrip"]);
  const [element, setElement] = useState(null);
  useEffect(() => {
    let content = language ? item.visit_sheet_contents.filter((e) => e.language === language.value) : [];
    if (content.length > 0) {
      setElement(content[0]);
    } else {
      setElement(null);
    }
  }, [item, language]);


  const style = {
    userSelect: 'none',
    cursor: 'pointer',
    maxWidth: 300,
    borderRadius: '10px',
    overflow: 'hidden',
    outline: activeVisitSheet?.id === item.id ? '3px solid #004f91' : 'none',
  };

  return (
    <li
      style={style}
      onClick={() => {
        window.scrollTo(0, 0);
        setActiveVisitSheet(item);
      }}
      className="row flex-nowrap w-100 d-flex flex-column justify-content-center mb-5 bg-white shadow-lg position-relative"
    >
      <img
        width={'100%'}
        height={'100%'}
        src={
          element?.layout
            ? JSON.parse(element?.layout)['0']?.url?.replace(/\?.*/, '') || defaultImg
            : defaultImg
        }
        alt="bg"
        style={{maxHeight: 200}}
      />
      <div className="w-100 d-flex flex-row align-items-center m-2">
        <div
          style={{width: 25, height: 25, borderRadius: '50%', border: '1px solid black'}}
          className="d-flex justify-content-center align-items-center font-weight-bolder"
        >
          {item.rank}
        </div>
        <h4 className="w-100 m-0 font-weight-bold font-size-h4 px-2 text-truncate">
          {element?.title || 'Pas de Titre'}
        </h4>
      </div>
      <div className="m-2" style={{height: '2.4em'}}>
        <p className="description">
          {element?.description || t("touristicSites.touristicSitePage.description.noContent")}
        </p>
      </div>
    </li>
  );
}
