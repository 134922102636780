import { useState, useEffect, useForm } from "react";

import "./FormKahoot.scss";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";

import { Typography } from "@mui/material";
import { createSvgIcon } from "@material-ui/core";
import { Button } from "components/form/button";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import SVG from "react-inlinesvg";
import { ReactComponent as Trash } from "../../assets/img/logo/delete.svg";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

import { PHONE_WIDTH } from "../../utils/utils";

const ListFileElement = ({ file, onClickHandler }) => {
  const [loading, setLoading] = useState(true);
  const [thumb, setThumb] = useState(undefined);
  const [fileExtension, setFileExtension] = useState("");

  useEffect(() => {
    if (!file) {
      return;
    }

    if (file) {
      let reader = new FileReader();
      reader.onloadend = () => {
        setThumb(reader.result);
        setLoading(false);
      };
      reader.readAsDataURL(file);
    } else {
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!file) {
    return null;
  }
  if (loading) {
    return <p>loading...</p>;
  }
  return (
    <div className="d-flex justify-content-between align-items-center my-1 px-lg-3">
      {thumb ? (
        <img src={thumb} height={50} width={50} />
      ) : (
        <div
          style={{ height: 50, width: 50, backgroundColor: "#efefef" }}
          className="d-flex justify-content-center align-items-center"
        >
          <span className="fw-bold text-black">{fileExtension}</span>
        </div>
      )}

      <div className="w-60 text-truncate text-center">
        <span>{file.name}</span>
      </div>
      <button
        className="btn btn-outline-none btn-danger"
        type="button"
        onClick={() => onClickHandler(file)}
      >
        X
      </button>
    </div>
  );
};

const FormKahoot = ({
  setFieldValue,
  setQuizz,
  quizz,
  values,
  quizzImg,
  handleSubmit,
  setStatus,
  status,
  handleChange,
  getFieldProps,
  modalContent,
  handleClose,
}) => {
  const TriangleIcon = createSvgIcon(
    <path d="M7.938 2.016A.13.13 0 0 1 8.002 2a.13.13 0 0 1 .063.016.146.146 0 0 1 .054.057l6.857 11.667c.036.06.035.124.002.183a.163.163 0 0 1-.054.06.116.116 0 0 1-.066.017H1.146a.115.115 0 0 1-.066-.017.163.163 0 0 1-.054-.06.176.176 0 0 1 .002-.183L7.884 2.073a.147.147 0 0 1 .054-.057zm1.044-.45a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566z" />,
    "Triangle"
  );
  const CircleIcon = createSvgIcon(
    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />,
    "Circle"
  );
  const DiamondIcon = createSvgIcon(
    <path d="M6.95.435c.58-.58 1.52-.58 2.1 0l6.515 6.516c.58.58.58 1.519 0 2.098L9.05 15.565c-.58.58-1.519.58-2.098 0L.435 9.05a1.482 1.482 0 0 1 0-2.098L6.95.435zm1.4.7a.495.495 0 0 0-.7 0L1.134 7.65a.495.495 0 0 0 0 .7l6.516 6.516a.495.495 0 0 0 .7 0l6.516-6.516a.495.495 0 0 0 0-.7L8.35 1.134z" />,
    "Diamond"
  );

  const Circle = createSvgIcon(
    <SVG src="/assets/icons/Nombre3.svg" width="25" height="25"></SVG>
  );
  const [quizz_to_delete, setQuizzToDelete] = useState([]);

  const [duplicateQuestions, setDuplicateQuestions] = useState(null);
  let [questions, setQuestions] = useState([
    {
      question: "",
      reponse1: "",
      reponse2: "",
      reponse3: "",
      reponse4: "",
      detail1: "",
      detail2: "",
      detail3: "",
      detail4: "",
      good_answer: "",
      image: [],
      quizz_index: [],
    },
  ]);
  const [numberQuestion, setNumberQuestion] = useState(0);
  const [checked, setChecked] = useState(null);
  const [questionIndex, setQuestionIndex] = useState(0);
  const [questionContent, setQuestionContent] = useState(questions[0]);
  const [quest, setQuest] = useState(null);
  const [rep1, setRep1] = useState(null);
  const [rep2, setRep2] = useState(null);
  const [rep3, setRep3] = useState(null);
  const [rep4, setRep4] = useState(null);
  const [detail1, setDetail1] = useState(null);
  const [detail2, setDetail2] = useState(null);
  const [detail3, setDetail3] = useState(null);
  const [detail4, setDetail4] = useState(null);
  const [answer, setAnswer] = useState(null);
  const [quizz_image, setQuizzImg] = useState([]);
  const [imgIndex, setImgIndex] = useState([]);
  const [tempImg, setTempImg] = useState([]);
  const [kahoot_img, setKahootImg] = useState([]);
  const [widthState, setWidthState] = useState(window.innerWidth);
  const [media, setMedia] = useState("grid");
  const [menu, setMenu] = useState(media);
  const [media2, setMedia2] = useState(!media);
  const [bg, setBg] = useState("white");
  const [quizz_img_to_delete, setQuizzImgToDelete] = useState([]);
  const openResponsive = () => {
    if (media == "grid") {
      setMedia("none");
      setMedia2("grid");
    }
    if (media == "none") {
      setMedia("grid");
      setMedia2("none");
    }
  };
  const openMenu = () => {
    if (media2 == "grid") {
      setMedia("grid");
      setMedia2("none");
    }
  };

  useEffect(() => {
    setQuestions((state) => [
      ...state,
      {
        question: "",
        reponse1: "",
        reponse2: "",
        reponse3: "",
        reponse4: "",
        detail1: "",
        detail2: "",
        detail3: "",
        detail4: "",
        good_answer: 1,
        image: [],
        quizz_index: [],
      },
    ]);
  }, [numberQuestion]);

  useEffect(() => {
    if (quizz) {
      setQuestions(quizz);
    }
  }, []);

  useEffect(() => {
    if (quizz !== undefined && quizz.length == 0) {
      setQuestions([
        {
          question: "",
          reponse1: "",
          reponse2: "",
          reponse3: "",
          reponse4: "",
          detail1: "",
          detail2: "",
          detail3: "",
          detail4: "",
          good_answer: "",
          image: [],
          quizz_index: [],
        },
      ]);
    }
  }, []);

  useEffect(() => {
    if (quizz !== undefined && quizz.length > 0) {
      setQuestionContent(quizz[0]);
    } else {
      setQuestionContent(questions[0]);
    }
  }, []);

  useEffect(() => {
    if (quizz && quizzImg.length > 0) {
      quizzImg?.map(
        (e) =>
          !quizz.find((dm) => {
            if (e.kahoot_id == dm.id) {
            }
          })
      );
    }
  }, []);

  useEffect(() => {
    setTempImg([]);
  }, [questionContent]);

  useEffect(() => {
    questions[questionIndex].good_answer = checked;
  }, [checked]);

  useEffect(
    (e) => {
      if (e) {
        setQuestions((state) => [...state, e]);
      }
    },
    [numberQuestion]
  );

  useEffect(() => {
    if (questionIndex + 1 > questions.length) {
      setQuestionIndex(0);
    }
  }, [questions[questionIndex]]);

  useEffect(() => {
    setFieldValue("quizz", questions);
  }, [questions]);

  useEffect(() => {
    setFieldValue("quizz_to_delete", quizz_to_delete);
  }, [quizz_to_delete]);

  const to_delete = (index, e) => {
    setQuestionIndex(index - 1);
    questions.splice(index, 1);
    if (e.id !== undefined) {
      quizz_to_delete.push(e.id);
      questions.splice(index, 1);
    }
  };
  useEffect(() => {
    if (questions[questionIndex].image !== undefined) {
      questions[questionIndex].image = tempImg;
    }
  }, [tempImg]);

  useEffect(() => {
    setFieldValue("quizz_image", kahoot_img);
  }, [kahoot_img]);

  useEffect(() => {
    if (questions[questionIndex]?.quizz_index) {
      questions[questionIndex].quizz_index = imgIndex;
    }
  }, [imgIndex]);

  useEffect(() => {
    tempImg.map((e) => {
      if (!kahoot_img?.includes(e)) {
        if (typeof e === "object" && kahoot_img.length == 0) {
          setKahootImg([e]);
        }
        if (kahoot_img.length > 0) {
          setKahootImg((state) => [...state, e]);
        }
      }
    });
  }, [tempImg]);

  useEffect(() => {
    if (tempImg.length !== kahoot_img.length && kahoot_img.length > 0) {
      kahoot_img?.filter(
        (e) =>
          !tempImg.find((dm) => {
            if (e.name == dm.name) {
              const index = kahoot_img.indexOf(e);
              kahoot_img.splice(index, 1);
            }
          })
      );
    }
  }, [tempImg]);

  useEffect(() => {
    if (quizz_image.length > 0) {
      quizz_image.forEach((e) => {
        setTempImg((state) => [...state, e]);
        setImgIndex((state) => [...state, questionIndex]);
      });
    }
  }, [quizz_image]);

  useEffect(() => {
    questions[questionIndex].question = quest;
  }, [quest]);

  useEffect(() => {
    questions[questionIndex].detail1 = detail1;
    questions[questionIndex].detail2 = detail2;
    questions[questionIndex].detail3 = detail3;
    questions[questionIndex].detail4 = detail4;
  }, [detail1, detail2, detail3, detail4]);

  useEffect(() => {
    if (questionContent !== undefined) {
      questions[questionIndex].reponse1 = rep1;
      questions[questionIndex].reponse2 = rep2;
      questions[questionIndex].reponse3 = rep3;
      questions[questionIndex].reponse4 = rep4;
      questions[questionIndex].good_answer = questionContent?.good_answer;
    }
  }, [rep1, rep2, rep3, rep4]);

  // créée pour faire le media query. Apparition du contenu de la question + disparition du menu
  useEffect(() => {
    if (media == "none") {
      setMedia2("grid");
    } else {
      setMedia2("none");
    }
  }, [media]);

  useEffect(() => {
    if (quizz_img_to_delete.length !== 0) {
      quizzImg?.filter(
        (m) =>
          !quizz_img_to_delete.find((dm) => {
            if (dm == m.file_url) {
              const index = quizzImg.indexOf(m);
              quizzImg.splice(index, 1);
            }
          })
      );
    }
  }, [quizz_img_to_delete]);

  // useEffect(() => {
  //   if (quizz_img_to_delete.length > 0) {
  //     values.deletedMedia.push(quizz_img_to_delete);
  //   }
  // }, [quizz_img_to_delete]);

  useEffect(() => {
    if (quizz) {
      if (questions[questionIndex]?.good_answer == 1) {
        setChecked(1);
      }
      if (questions[questionIndex]?.good_answer == 2) {
        setChecked(2);
      }
      if (questions[questionIndex]?.good_answer == 3) {
        setChecked(3);
      }
      if (questions[questionIndex]?.good_answer == 4) {
        setChecked(4);
      }
    }
  }, [questionIndex]);

  const onDeleteHandler = (key) => {
    if (key.type.split("/")[0] === "image") {
      const filesStateClone = kahoot_img.filter(
        (file) => file.name !== key.name
      );
      setTempImg(filesStateClone);
    }
  };
  return (
    <>
      <div className="Kahoot-page">
        {widthState <= PHONE_WIDTH ? (
          // Ici il y a la partie responsive :
          <>
            <div
              className="header-kahoot"
              style={{
                backgroundColor: "#DCDCDC",
                height: "8.5rem",
                position: "absolute",
                top: "-50px",
                width: " 100%",
                left: 0,
              }}
            >
              <div
                className="btn_kahoot"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  flexDirection: "row-reverse",
                }}
              >
                <span className="title-kahoot-header">Quizz</span>
                {media == "none" ? (
                  <Button
                    className="btn-kahoot-header"
                    type="button"
                    onClick={() => openMenu()}
                    title="< Retour"
                  ></Button>
                ) : (
                  <Button
                    className="btn-kahoot-header"
                    type="button"
                    onClick={handleClose}
                    title="< Retour"
                  />
                )}
              </div>
            </div>
            <div className="Kahoot-page__underline" style={{ display: media }}>
              {questions.map((e, index) => (
                <div
                  className="card-kahoot"
                  key={index}
                  style={{ display: media, pointer: "cursor" }}
                >
                  <div
                    className="question-card"
                    style={{ backgroundColor: bg }}
                  >
                    <div className="kahoot-question-btn">
                      <Button
                        title={<ContentCopyIcon width="12" />}
                        color="primary"
                        className="btn-left-kahoot rounded-lg"
                        onClick={() => questions.push(e)}
                      ></Button>
                    </div>
                    <div
                      onClick={() => {
                        openResponsive();
                        setQuestionIndex(index);
                        setQuestionContent(e);
                        setImgIndex([]);
                        setQuest(e.question);
                        setRep1(e.reponse1);
                        setRep2(e.reponse2);
                        setRep3(e.reponse3);
                        setRep4(e.reponse4);
                        setDetail1(e.detail1);
                        setDetail2(e.detail2);
                        setDetail3(e.detail3);
                        setDetail4(e.detail4);
                      }}
                    >
                      <p className="question-card__title">
                        Question {+index + 1}
                      </p>
                    </div>
                    <div className="kahoot-question-btn">
                      <Button
                        title={<Trash width="15" />}
                        color="danger"
                        className="btn-right-kahoot rounded-lg"
                        onClick={() => to_delete(index, e)}
                      ></Button>
                    </div>
                  </div>
                </div>
              ))}
              <>
                <Button
                  className="btn-question-more"
                  title={"Ajouter une question"}
                  onClick={() => setNumberQuestion(numberQuestion + 1)}
                ></Button>
              </>
            </div>

            <div
              className="answers-kahoot-group form-group fv-plugins-icon-container"
              style={{ display: media2 }}
            >
              <div className="group-question-kahoot">
                <label className="kahoot-title">
                  Question {questionIndex + 1 || 1}:
                </label>
                <input
                  id="question"
                  type="text"
                  placeholder="La question est la suivante..."
                  className="form-control form-control-solid h-auto py-1 px-2 w-70"
                  onChange={(e) => setQuest(e.target.value)}
                  value={quest || questions[questionIndex]?.question}
                />
                <div className="d-flex justify-content-center w-90 mt-2">
                  <label htmlFor="Quizz_images">
                    <btn
                      className="btn btn-outline-none btn-primary"
                      type="button"
                    >
                      Selectionner une image
                    </btn>
                  </label>
                </div>
                <input
                  id="Quizz_images"
                  style={{ visibility: "hidden" }}
                  accept="image/png, image/jpg, image/jpeg"
                  name="Quizz_images"
                  type="file"
                  onChange={(event) => {
                    if (event.currentTarget.files.length >= 1) {
                      if (event.currentTarget.files[0].size < 5000000) {
                        const temp_img = [...event.currentTarget.files].filter(
                          (e) =>
                            ["image/png", "image/jpg", "image/jpeg"].includes(
                              e?.type
                            )
                        );
                        setQuizzImg(temp_img);
                        setStatus(null);
                      } else {
                        setStatus({ message: "Fichier trop lourd" });
                      }
                    }
                  }}
                />
                {quizzImg?.length > 0 &&
                  quizzImg
                    ?.filter(
                      (m) =>
                        m.kahoot_id == questionContent.id
                    )
                    .map((media, index) => (
                      <div
                        className="row mb-3 d-flex align-items-center justify-content-between"
                        key={index}
                      >
                        <img src={media.file_url} height={50} width={50} />
                        <p>{media.type_file}</p>
                        <div className="col-3 d-flex flex-row-reverse ">
                          <button
                            type="button"
                            className="btn btn-outline-none btn-danger"
                            onClick={() =>
                              setQuizzImgToDelete([media.file_url])
                            }
                          >
                            X
                          </button>
                        </div>
                      </div>
                    ))}

                {tempImg?.length > 0 && tempImg.length == quizzImg.length
                  ? tempImg?.map((el) => {
                    return (
                      <ListFileElement
                        img={tempImg}
                        file={el}
                        key={el}
                        onClickHandler={onDeleteHandler}
                      ></ListFileElement>
                    );
                  })
                  : questions[questionIndex]?.image?.length > 0 &&
                  questions[questionIndex]?.image?.map((el) => {
                    return (
                      <ListFileElement
                        img={questions[questionIndex]?.image}
                        file={el}
                        key={el}
                        onClickHandler={onDeleteHandler}
                      ></ListFileElement>
                    );
                  })}
              </div>
              <div className="answers-kahoot">
                <div>
                  <div
                    className="answers1-kahoot"
                    onClick={() => setChecked(1)}
                  >
                    <div className="checked-kahoot">
                      {checked === 1 ? (
                        <>
                          <FormControlLabel
                            sx={{
                              display: "inline-flex",
                              alignContent: "center",
                              justifyContent: "center",
                              alignItems: "center",
                              margin: 0,
                            }}
                            labelPlacement="start"
                            label={
                              <Typography>
                                <input
                                  id="reponse1"
                                  type="text"
                                  className="answers"
                                  onChange={(e) => setRep1(e.target.value)}
                                  value={rep1}
                                  placeholder="Ajoute une réponse"
                                />
                              </Typography>
                            }
                            control={
                              <Checkbox
                                checkedIcon={<CheckCircleIcon />}
                                color="success"
                                checked={true}
                              />
                            }
                          />
                        </>
                      ) : (
                        <>
                          <FormControlLabel
                            sx={{
                              display: "inline-flex",
                              alignContent: "center",
                              justifyContent: "center",
                              alignItems: "center",
                              margin: 0,
                            }}
                            labelPlacement="start"
                            label={
                              <Typography>
                                <input
                                  id="reponse1"
                                  type="text"
                                  className="answers"
                                  onChange={(e) => setRep1(e.target.value)}
                                  value={
                                    rep1 || questions[questionIndex]?.reponse1
                                  }
                                  placeholder="Ajoute une réponse"
                                />
                              </Typography>
                            }
                            control={
                              <Checkbox icon={<Circle />} checked={false} />
                            }
                          />
                        </>
                      )}
                    </div>
                  </div>
                  {checked === 1 && (
                    <div>
                      <textarea
                        id="detail1"
                        type="text"
                        placeholder="Description de la bonne réponse..."
                        className="form-control form-control-solid h-auto py-1 px-2"
                        value={detail1}
                        onChange={(e) => setDetail1(e.target.value)}
                      />
                    </div>
                  )}
                </div>

                <div>
                  <div
                    className="answers2-kahoot"
                    onClick={() => setChecked(2)}
                  >
                    <div className="checked-kahoot">
                      {checked === 2 ? (
                        <>
                          <FormControlLabel
                            sx={{
                              display: "inline-flex",
                              alignContent: "center",
                              justifyContent: "center",
                              alignItems: "center",
                              margin: 0,
                            }}
                            labelPlacement="start"
                            label={
                              <Typography>
                                <input
                                  id="reponse2"
                                  type="text"
                                  className="answers"
                                  onChange={(e) => setRep2(e.target.value)}
                                  value={rep2}
                                  placeholder="Ajoute une réponse"
                                />
                              </Typography>
                            }
                            control={
                              <Checkbox
                                checkedIcon={<CheckCircleIcon />}
                                color="success"
                                checked
                              />
                            }
                          />
                        </>
                      ) : (
                        <FormControlLabel
                          sx={{
                            display: "inline-flex",
                            alignContent: "center",
                            justifyContent: "center",
                            alignItems: "center",
                            margin: 0,
                          }}
                          labelPlacement="start"
                          label={
                            <Typography>
                              <input
                                id="reponse2"
                                type="text"
                                className="answers"
                                onChange={(e) => setRep2(e.target.value)}
                                value={
                                  rep2 || questions[questionIndex]?.reponse2
                                }
                                placeholder="Ajoute une réponse"
                              />
                            </Typography>
                          }
                          control={
                            <Checkbox icon={<Circle />} checked={false} />
                          }
                        />
                      )}
                    </div>
                  </div>

                  {checked === 2 && (
                    <textarea
                      id="detail2"
                      type="text"
                      placeholder="Description de la bonne réponse..."
                      className="form-control form-control-solid h-auto py-1 px-2"
                      value={detail2}
                      onChange={(e) => setDetail2(e.target.value)}
                    />
                  )}
                </div>
                <div>
                  <div
                    className="answers3-kahoot"
                    onClick={() => setChecked(3)}
                  >
                    <div className="checked-kahoot">
                      {checked === 3 ? (
                        <>
                          <FormControlLabel
                            sx={{
                              display: "inline-flex",
                              alignContent: "center",
                              justifyContent: "center",
                              alignItems: "center",
                              margin: 0,
                            }}
                            labelPlacement="start"
                            label={
                              <Typography>
                                <input
                                  id="reponse3"
                                  type="text"
                                  className="answers"
                                  onChange={(e) => setRep3(e.target.value)}
                                  value={rep3}
                                  placeholder="Ajoute une réponse"
                                />
                              </Typography>
                            }
                            checkedIcon={<CheckCircleIcon />}
                            control={<Checkbox color="success" checked />}
                          />
                        </>
                      ) : (
                        <FormControlLabel
                          sx={{
                            display: "inline-flex",
                            alignContent: "center",
                            justifyContent: "center",
                            alignItems: "center",
                            margin: 0,
                          }}
                          labelPlacement="start"
                          label={
                            <Typography>
                              <input
                                id="reponse3"
                                type="text"
                                className="answers"
                                onChange={(e) => setRep3(e.target.value)}
                                value={
                                  rep3 || questions[questionIndex]?.reponse3
                                }
                                placeholder="Ajoute une réponse"
                              />
                            </Typography>
                          }
                          control={
                            <Checkbox
                              icon={<Circle />}
                              sx={{ borderRadius: "50%" }}
                              checked={false}
                            />
                          }
                        />
                      )}
                    </div>
                  </div>

                  {checked === 3 && (
                    <textarea
                      id="detail3"
                      type="text"
                      placeholder="Description de la bonne réponse..."
                      className="form-control form-control-solid h-auto py-1 px-2"
                      value={detail3}
                      onChange={(e) => setDetail3(e.target.value)}
                    />
                  )}
                </div>
                <div>
                  <div
                    className="answers4-kahoot"
                    onClick={() => setChecked(4)}
                  >
                    <div className="checked-kahoot">
                      {checked === 4 ? (
                        <>
                          <FormControlLabel
                            sx={{
                              display: "inline-flex",
                              alignContent: "center",
                              justifyContent: "center",
                              alignItems: "center",
                              margin: 0,
                            }}
                            labelPlacement="start"
                            label={
                              <Typography>
                                <input
                                  id="reponse4"
                                  type="text"
                                  className="answers"
                                  onChange={(e) => setRep4(e.target.value)}
                                  value={rep4}
                                  placeholder="Ajoute une réponse"
                                />
                              </Typography>
                            }
                            control={
                              <Checkbox
                                checkedIcon={<CheckCircleIcon />}
                                color="success"
                                checked
                              />
                            }
                          />
                        </>
                      ) : (
                        <FormControlLabel
                          sx={{
                            display: "inline-flex",
                            alignContent: "center",
                            justifyContent: "center",
                            alignItems: "center",
                            margin: 0,
                          }}
                          label={
                            <Typography>
                              <input
                                id="reponse4"
                                type="text"
                                className="answers"
                                onChange={(e) => setRep4(e.target.value)}
                                value={
                                  rep4 || questions[questionIndex]?.reponse4
                                }
                                placeholder="Ajoute une réponse"
                              />
                            </Typography>
                          }
                          labelPlacement="start"
                          control={
                            <Checkbox icon={<Circle />} checked={false} />
                          }
                        />
                      )}
                    </div>
                  </div>
                  {checked === 4 && (
                    <textarea
                      id="detail4"
                      type="text"
                      placeholder="Description de la bonne réponse..."
                      className="form-control form-control-solid h-auto py-1 px-2"
                      value={detail4}
                      onChange={(e) => setDetail4(e.target.value)}
                    />
                  )}
                </div>
              </div>
            </div>
          </>
        ) : (
          // Juste en dessous commence la partie desktop
          <>
            <div
              className="header-kahoot"
              style={{
                backgroundColor: "#DCDCDC",
                height: "4.5rem",
                position: "absolute",
                top: 0,
                width: " 100%",
                left: 0,
                fontWeight: "600",
              }}
            >
              <div className="btn_kahoot" style={{}}>
                <span className="title-kahoot-header">Quizz</span>

                <Button
                  className="btn-kahoot-header"
                  type="button"
                  onClick={handleClose}
                  title="X"
                />
              </div>
            </div>
            <div className="div-btw">
              <div className="Kahoot-page__underline">
                {questions.map((e, index) => (
                  <div
                    key={index}
                    onClick={() => {
                      setQuestionIndex(index);
                      setQuestionContent(e);
                      setChecked(e.good_answer || 1);
                      setImgIndex([]);
                      setQuest(e.question);
                      setRep1(e.reponse1);
                      setRep2(e.reponse2);
                      setRep3(e.reponse3);
                      setRep4(e.reponse4);
                      setDetail1(e.detail1);
                      setDetail2(e.detail2);
                      setDetail3(e.detail3);
                      setDetail4(e.detail4);
                    }}
                    style={{ display: "flex", pointer: "cursor" }}
                  >
                    <div className="question-card">
                      <div>
                        <Button
                          title={<ContentCopyIcon width="15" />}
                          color="primary"
                          className="btn-left-kahoot rounded-lg"
                          onClick={() => questions.push(e)}
                        ></Button>
                      </div>
                      <div>
                        <p className="question-card__title">
                          Question {+index + 1}
                        </p>
                      </div>
                      <div>
                        <Button
                          title={<Trash width="15" />}
                          color="danger"
                          className="btn-right-kahoot rounded-lg"
                          onClick={() => to_delete(index, e)}
                        ></Button>
                      </div>
                    </div>
                  </div>
                ))}
                <Button
                  style={{ marginTop: "1rem" }}
                  title={"Ajouter une question"}
                  onClick={() => setNumberQuestion(numberQuestion + 1)}
                ></Button>
              </div>
            </div>

            <div className="answers-kahoot-group form-group fv-plugins-icon-container">
              <div className="group-question-kahoot">
                <label className="kahoot-title">
                  Question {questionIndex + 1 || 1}:
                </label>
                <input
                  id="question"
                  type="text"
                  placeholder="La question est la suivante..."
                  className="form-control form-control-solid h-auto py-1 px-2 w-70"
                  onChange={(e) => setQuest(e.target.value)}
                  value={quest}
                />
                <div className="d-flex justify-content-center w-90 mt-2">
                  <label htmlFor="Quizz_images">
                    <btn
                      className="btn btn-outline-none btn-primary"
                      type="button"
                    >
                      Selectionner une image
                    </btn>
                  </label>
                </div>
                <input
                  id="Quizz_images"
                  style={{ visibility: "hidden" }}
                  accept="image/png, image/jpg, image/jpeg"
                  name="Quizz_images"
                  type="file"
                  onChange={(event) => {
                    if (event.currentTarget.files.length >= 1) {
                      if (event.currentTarget.files[0].size < 5000000) {
                        const temp_img = [...event.currentTarget.files].filter(
                          (e) =>
                            ["image/png", "image/jpg", "image/jpeg"].includes(
                              e?.type
                            )
                        );
                        setQuizzImg(temp_img);
                        setStatus(null);
                      } else {
                        setStatus({ message: "Fichier trop lourd" });
                      }
                    }
                  }}
                />
                {quizzImg?.length > 0 &&
                  quizzImg
                    ?.filter(
                      (m) =>
                        m.kahoot_id == questionContent.id
                    )
                    .map((media, index) => (
                      <div
                        className="row mb-3 d-flex align-items-center justify-content-between"
                        key={index}
                      >
                        <img src={media.file_url} height={50} width={50} />
                        <p>{media.type_file}</p>
                        <div className="col-3 d-flex flex-row-reverse ">
                          <button
                            type="button"
                            className="btn btn-outline-none btn-danger"
                            onClick={() =>
                              setQuizzImgToDelete([media.file_url])
                            }
                          >
                            X
                          </button>
                        </div>
                      </div>
                    ))}

                {tempImg?.length > 0 && tempImg.length == quizzImg.length
                  ? tempImg?.map((el) => {
                    return (
                      <ListFileElement
                        img={tempImg}
                        file={el}
                        key={el}
                        onClickHandler={onDeleteHandler}
                      ></ListFileElement>
                    );
                  })
                  : questions[questionIndex]?.image?.length > 0 &&
                  questions[questionIndex]?.image?.map((el) => {
                    return (
                      <ListFileElement
                        img={questions[questionIndex]?.image}
                        file={el}
                        key={el}
                        onClickHandler={onDeleteHandler}
                      ></ListFileElement>
                    );
                  })}
              </div>
              <div className="answers-kahoot">
                <div>
                  <div
                    className="answers1-kahoot"
                    onClick={() => setChecked(1)}
                  >
                    <div className="checked-kahoot">
                      {checked === 1 ? (
                        <>
                          <FormControlLabel
                            sx={{
                              display: "inline-flex",
                              alignContent: "center",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                            labelPlacement="start"
                            label={
                              <Typography>
                                <input
                                  id="reponse1"
                                  type="text"
                                  className="answers"
                                  onChange={(e) => setRep1(e.target.value)}
                                  value={rep1}
                                  placeholder="Ajoute une réponse"
                                />
                              </Typography>
                            }
                            control={
                              <Checkbox
                                checkedIcon={<CheckCircleIcon />}
                                color="success"
                                checked={true}
                              />
                            }
                          />
                        </>
                      ) : (
                        <>
                          <FormControlLabel
                            sx={{
                              display: "inline-flex",
                              alignContent: "center",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                            labelPlacement="start"
                            label={
                              <Typography>
                                <input
                                  id="reponse1"
                                  type="text"
                                  className="answers"
                                  onChange={(e) => setRep1(e.target.value)}
                                  value={
                                    rep1 || questions[questionIndex]?.reponse1
                                  }
                                  placeholder="Ajoute une réponse"
                                />
                              </Typography>
                            }
                            control={
                              <Checkbox icon={<Circle />} checked={false} />
                            }
                          />
                        </>
                      )}
                    </div>
                  </div>
                  {checked === 1 && (
                    <div>
                      <textarea
                        id="detail1"
                        type="text"
                        placeholder="Description de la bonne réponse..."
                        className="form-control form-control-solid h-auto py-1 px-2"
                        value={detail1}
                        onChange={(e) => setDetail1(e.target.value)}
                      />
                    </div>
                  )}
                </div>

                <div>
                  <div
                    className="answers2-kahoot"
                    onClick={() => setChecked(2)}
                  >
                    <div className="checked-kahoot">
                      {checked === 2 ? (
                        <>
                          <FormControlLabel
                            sx={{
                              display: "inline-flex",
                              alignContent: "center",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                            labelPlacement="start"
                            label={
                              <Typography>
                                <input
                                  id="reponse2"
                                  type="text"
                                  className="answers"
                                  onChange={(e) => setRep2(e.target.value)}
                                  value={rep2}
                                  placeholder="Ajoute une réponse"
                                />
                              </Typography>
                            }
                            control={
                              <Checkbox
                                checkedIcon={<CheckCircleIcon />}
                                color="success"
                                checked
                              />
                            }
                          />
                        </>
                      ) : (
                        <FormControlLabel
                          sx={{
                            display: "inline-flex",
                            alignContent: "center",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                          labelPlacement="start"
                          label={
                            <Typography>
                              <input
                                id="reponse2"
                                type="text"
                                className="answers"
                                onChange={(e) => setRep2(e.target.value)}
                                value={
                                  rep2 || questions[questionIndex]?.reponse2
                                }
                                placeholder="Ajoute une réponse"
                              />
                            </Typography>
                          }
                          control={
                            <Checkbox icon={<Circle />} checked={false} />
                          }
                        />
                      )}
                    </div>
                  </div>

                  {checked === 2 && (
                    <textarea
                      id="detail2"
                      type="text"
                      placeholder="Description de la bonne réponse..."
                      className="form-control form-control-solid h-auto py-1 px-2"
                      value={detail2}
                      onChange={(e) => setDetail2(e.target.value)}
                    />
                  )}
                </div>
                <div>
                  <div
                    className="answers3-kahoot"
                    onClick={() => setChecked(3)}
                  >
                    <div className="checked-kahoot">
                      {checked === 3 ? (
                        <>
                          <FormControlLabel
                            sx={{
                              display: "inline-flex",
                              alignContent: "center",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                            labelPlacement="start"
                            label={
                              <Typography>
                                <input
                                  id="reponse3"
                                  type="text"
                                  className="answers"
                                  onChange={(e) => setRep3(e.target.value)}
                                  value={rep3}
                                  placeholder="Ajoute une réponse"
                                />
                              </Typography>
                            }
                            checkedIcon={<CheckCircleIcon />}
                            control={<Checkbox color="success" checked />}
                          />
                        </>
                      ) : (
                        <FormControlLabel
                          sx={{
                            display: "inline-flex",
                            alignContent: "center",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                          labelPlacement="start"
                          label={
                            <Typography>
                              <input
                                id="reponse3"
                                type="text"
                                className="answers"
                                onChange={(e) => setRep3(e.target.value)}
                                value={
                                  rep3 || questions[questionIndex]?.reponse3
                                }
                                placeholder="Ajoute une réponse"
                              />
                            </Typography>
                          }
                          control={
                            <Checkbox
                              icon={<Circle />}
                              sx={{ borderRadius: "50%" }}
                              checked={false}
                            />
                          }
                        />
                      )}
                    </div>
                  </div>
                  {checked === 3 && (
                    <textarea
                      id="detail3"
                      type="text"
                      placeholder="Description de la bonne réponse..."
                      className="form-control form-control-solid h-auto py-1 px-2"
                      value={detail3}
                      onChange={(e) => setDetail3(e.target.value)}
                    />
                  )}
                </div>
                <div>
                  <div
                    className="answers4-kahoot"
                    onClick={() => setChecked(4)}
                  >
                    <div className="checked-kahoot">
                      {checked === 4 ? (
                        <>
                          <FormControlLabel
                            sx={{
                              display: "inline-flex",
                              alignContent: "center",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                            labelPlacement="start"
                            label={
                              <Typography>
                                <input
                                  id="reponse4"
                                  type="text"
                                  className="answers"
                                  onChange={(e) => setRep4(e.target.value)}
                                  value={rep4}
                                  placeholder="Ajoute une réponse"
                                />
                              </Typography>
                            }
                            control={
                              <Checkbox
                                checkedIcon={<CheckCircleIcon />}
                                color="success"
                                checked
                              />
                            }
                          />
                        </>
                      ) : (
                        <FormControlLabel
                          sx={{
                            display: "inline-flex",
                            alignContent: "center",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                          label={
                            <Typography>
                              <input
                                id="reponse4"
                                type="text"
                                className="answers"
                                onChange={(e) => setRep4(e.target.value)}
                                value={
                                  rep4 || questions[questionIndex]?.reponse4
                                }
                                placeholder="Ajoute une réponse"
                              />
                            </Typography>
                          }
                          labelPlacement="start"
                          control={
                            <Checkbox icon={<Circle />} checked={false} />
                          }
                        />
                      )}
                    </div>
                  </div>
                  {checked === 4 && (
                    <textarea
                      id="detail4"
                      type="text"
                      placeholder="Description de la bonne réponse..."
                      className="form-control form-control-solid h-auto py-1 px-2"
                      value={detail4}
                      onChange={(e) => setDetail4(e.target.value)}
                    />
                  )}
                </div>
              </div>
            </div>
          </>
        )}
      </div>
      <hr></hr>
      <div className="kahoot-footer">
        <Button
          title={"Précedent"}
          onClick={handleClose}
          style={{ marginTop: "7%" }}
        ></Button>
        <Button
          title={"Enregistrer"}
          onClick={() =>
            setFieldValue("quizz", questions) &&
            setFieldValue("quizz_image", kahoot_img) &&
            setQuizz(false)
          }
          style={{ marginTop: "7%" }}
        ></Button>
      </div>
    </>
  );
};

export default FormKahoot;
