import React from 'react';
import './quoteTemplate.scss';
import {toAbsoluteUrl} from "../../../utils/utils";

const QuoteTemplate = (props) => {
    return (
    <div className="quote__main-container">
        <img className="quote__image__left" style={{color: "white"}} alt="left quote" src={toAbsoluteUrl("assets/logo/quote-left.png")}/>
            <div className="quote__wrap">
                <div className="quote__top-container">
                    <p className="quote__text__content">{props.content}</p>
                </div>
                <div className="quote__bot-container">
                    <p className="quote__text__author" >({props.author})</p>
                </div>
            </div>
        <img className="quote__image__right ml-0 ml-md-2" style={{color: "white"}} alt="right quote" src={toAbsoluteUrl("assets/logo/quote-right.png")}/>
    </div>
);
};

export default QuoteTemplate;