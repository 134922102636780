import {useContext, useEffect, useState} from 'react';
import {useFormik} from 'formik';
import styled, {ThemeContext} from 'styled-components';
import {API_PATCH_TOURISTIC_SITE} from '../../../utils/api';
import touristicSiteSchema, {scheduleSchema} from '../../_schemas/touristic_site_schema';
import Button from '../../../_ui/_v2/components/Button';
import Loader from '../../../_ui/_v2/components/Loader';
import {SwitchInput, TextInput, Form} from '../../../_ui/_v2/components/FormElements';
import TouristicSiteContext from '../../../_contexts/TouristicSiteContext';

const touristicSiteSchemaForTouristicSiteOpeningHoursForm = touristicSiteSchema.pick([
  'schedule',
]);

const daysOfWeek = {
  lundi: 'Lundi',
  mardi: 'Mardi',
  mercredi: 'Mercredi',
  jeudi: 'Jeudi',
  vendredi: 'Vendredi',
  samedi: 'Samedi',
  dimanche: 'Dimanche',
};

const CheckBoxStyled = styled.input`
  display: none;
`;

const CheckBoxLabelStyled = styled.label`
  padding: 5px 10px;
  gap: 5px;
  border-radius: 100px;
  border: 1px solid ${props => props.theme.colors.darkGrey};
  color: ${props => props.theme.colors.darkGrey};
  font-weight: 600;
`;

const CheckBoxOption = styled.span`
  input:checked + label {
    background-color: ${props => props.theme.colors.primary};
    border-color: ${props => props.theme.colors.primary};
    color: ${props => props.theme.colors.white};
  }
`;

const OpeningHoursDaysSelectContainer = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

const OpeningHoursDaysSelect = ({values, name, onChange}) => {
  const [selectedValues, setSelectedValues] = useState(values || []);

  useEffect(() => {
    if (values !== undefined && JSON.stringify(selectedValues) !== JSON.stringify(values)) {
      setSelectedValues(values);
    }
  }, [values]);

  useEffect(() => {
    onChange?.(selectedValues);
  }, [selectedValues]);

  const handleChange = (event) => {
    if (event.target.checked) {
      setSelectedValues(prev => ([...prev, event.target.value]));
    } else {
      setSelectedValues(prev => ([...prev.filter(value => value !== event.target.value)]));
    }
  };

  return (
    <OpeningHoursDaysSelectContainer>
      {
        Object.keys(daysOfWeek).map((dayKey, index) => (
          <CheckBoxOption key={index}>
            <CheckBoxStyled
              type="checkbox"
              name={name}
              id={`${name}_${index}`}
              checked={selectedValues.includes(dayKey)}
              value={dayKey}
              onChange={handleChange}
            />
            <CheckBoxLabelStyled
              htmlFor={`${name}_${index}`}
            >
              {daysOfWeek[dayKey]}
            </CheckBoxLabelStyled>
          </CheckBoxOption>
        ))
      }
    </OpeningHoursDaysSelectContainer>
  );
};

const TimeSlotStyled = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1em;
`;

const TimeInput = styled(TextInput)`
  text-align: center;
  appearance: none !important;
  
  ::-webkit-calendar-picker-indicator {
    display: none;
    background: none;
  }
`;

const TimeSlot = (
  {
    slotStartName,
    slotEndName,
    slotStartLabel,
    slotEndLabel,
    onChange,
    value,
    namePrefix,
  }
) => {

  const {
    handleChange,
    handleBlur,
    values,
    errors,
    touched,
    setValues,
  } = useFormik({
    initialValues: scheduleSchema.cast(value),
    validationSchema: scheduleSchema,
  });

  useEffect(() => {
    onChange?.(values);
  }, [values]);

  useEffect(() => {
    if (JSON.stringify(value) !== JSON.stringify(values)) {
      setValues(value);
    }
  }, [value]);

  return (
    <TimeSlotStyled>
      <TimeInput
        type="time"
        name={`${namePrefix}.${slotStartName}`}
        onChange={handleChange}
        onBlur={handleBlur}
        value={values[namePrefix]?.[slotStartName] || ''}
        hasError={errors[namePrefix]?.[slotStartName] && touched[namePrefix]?.[slotStartName]}
        errorMessage={errors[namePrefix]?.[slotStartName]}
        label={slotStartLabel}
      />
      <TimeInput
        type="time"
        name={`${namePrefix}.${slotEndName}`}
        onChange={handleChange}
        onBlur={handleBlur}
        value={values[namePrefix]?.[slotEndName] || ''}
        hasError={errors[namePrefix]?.[slotEndName] && touched[namePrefix]?.[slotEndName]}
        errorMessage={errors[namePrefix]?.[slotEndName]}
        label={slotEndLabel}
      />
    </TimeSlotStyled>
  );
};

const FieldSet = styled.fieldset`
  margin-top: 1em;
  padding: 0;
  
  legend {
    font-weight: 600;
  }
`;

const OpeningHoursBuildOne = ({onChange, value, name = ''}) => {
  const {
    handleBlur,
    values,
    setValues,
  } = useFormik({
    initialValues: scheduleSchema.cast({}),
    validationSchema: scheduleSchema,
  });

  useEffect(() => {
    if (JSON.stringify(values) !== JSON.stringify(value)) {
      setValues(value);
    }
  }, [value]);

  useEffect(() => {
    onChange?.(values);
  }, [values]);

  return (
    <>
      <SwitchInput
        name={`${name}.closed_noon`}
        label={"Journée entière"}
        onChange={(event) => setValues({[name]: {closed_noon: !event.target.checked}})}
        checked={!values[name]?.closed_noon}
      />
      {
        !values[name]?.closed_noon
          ? (
            <TimeSlot
              namePrefix={name}
              slotStartName={'open_morning'}
              slotEndName={'close_night'}
              slotStartLabel={'Ouverture'}
              slotEndLabel={'Fermeture'}
              value={values}
              onChange={value => setValues(prev => ({...prev, ...value}))}
              onBlur={handleBlur}
            />
          ) : (
            <>
              <TimeSlot
                namePrefix={name}
                slotStartName={'open_morning'}
                slotEndName={'close_morning'}
                slotStartLabel={'Ouverture'}
                slotEndLabel={'Fermeture'}
                value={values}
                onChange={value => setValues(prev => ({...prev, ...value}))}
                onBlur={handleBlur}
              />
              <TimeSlot
                namePrefix={name}
                slotStartName={'open_afternoon'}
                slotEndName={'close_night'}
                slotStartLabel={'Ouverture'}
                slotEndLabel={'Fermeture'}
                value={values}
                onChange={value => setValues(prev => ({...prev, ...value}))}
                onBlur={handleBlur}
              />
            </>
          )
      }
    </>
  );
};

const OpeningHoursBuilder = ({onChange, value}) => {
  const [selectedDays, setSelectedDays] = useState(Object.keys(value) || []);
  const [openingHours, setOpeningHours] = useState(value || {});

  useEffect(() => {
    onChange?.(openingHours);
  }, [openingHours]);

  useEffect(() => {
    if (JSON.stringify(value) !== JSON.stringify(openingHours)) {
      setOpeningHours(value);
      setSelectedDays(Object.keys(value));
    }
  }, [value]);

  useEffect(() => {
    Object.keys(value).forEach(day => {
      if (!selectedDays.includes(day)) {
        const newOpeningHours = {...openingHours};
        delete newOpeningHours[day];
        setOpeningHours(newOpeningHours);
      }
    });
  }, [selectedDays]);

  return (
    <FieldSet>
      <legend>Sélectionner les jours d'ouverture&nbsp;:</legend>
      <OpeningHoursDaysSelect
        name={"days"}
        onChange={setSelectedDays}
        values={selectedDays}
      />
      {
        Object.keys(daysOfWeek).filter(dayKey => selectedDays.includes(dayKey)).map((dayKey, index) => (
          <FieldSet key={dayKey}>
            <legend style={{margin: 0}}>{daysOfWeek[dayKey]}</legend>
            <OpeningHoursBuildOne
              name={dayKey}
              onChange={value => setOpeningHours(prev => ({...prev, ...value}))}
              value={openingHours}
            />
          </FieldSet>
        ))
      }
    </FieldSet>
  );
};

const TouristicSiteOpeningHoursForm = ({onSuccess, touristicSite = {}}) => {
  const theme = useContext(ThemeContext);
  const [loading, setLoading] = useState(false);
  const { setTouristicSite } = useContext(TouristicSiteContext);

  const handleSubmitForm = (values, actions) => {
    const token = localStorage.getItem("Authorization");

    const payload = {...values};
    payload.schedule = JSON.stringify(payload.schedule);

    setLoading(true);
    fetch(
      API_PATCH_TOURISTIC_SITE(touristicSite.id),
      {
        method: 'PATCH',
        cache: 'default',
        body: JSON.stringify(payload),
        headers: {
          "Authorization": `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        mode: 'cors',
      }
    )
      .then(res => res.json())
      .then(response => {
        setTouristicSite(response);
        // setLoading(false);
        onSuccess?.(response);
      })
      .catch(error => {
        console.error(error);
        setLoading(false);
      });
  };

  const {
    handleSubmit,
    values,
    setFieldValue,
  } = useFormik({
    initialValues: touristicSiteSchemaForTouristicSiteOpeningHoursForm.cast(touristicSite),
    validationSchema: touristicSiteSchemaForTouristicSiteOpeningHoursForm,
    onSubmit: handleSubmitForm,
  });

  return (
    <Form onSubmit={handleSubmit}>
      <OpeningHoursBuilder
        onChange={value => setFieldValue('schedule', value)}
        value={values.schedule}
      />
      <Button
        rounded
        disabled={loading}
        type="submit"
        style={{
          display: 'block',
          margin: '3em auto 0 auto',
        }}
      >
        {
          loading
            ? <Loader size="1.5em" color={theme.colors.white}/>
            : 'Enregistrer'
        }
      </Button>
    </Form>
  );
};

export default TouristicSiteOpeningHoursForm;