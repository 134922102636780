import React from 'react';
import useDocumentTitle from "../../hooks/use-document-title";
import PartnerSectionsHeader from './PartnerSectionsPage/PartnerSectionsHeader';
import PartnerSectionsNavbar from "./PartnerSectionsPage/PartnerSectionsNavbar";
import PartnerSectionsSwitch from "./PartnerSectionsPage/PartnerSectionsSwitch";
import PartnerSectionsServices from "./PartnerSectionsPage/PartnerSectionsServices";
import PartnerSectionsTouristicSites from './PartnerSectionsPage/PartnerSectionsTouristicSites';
import PartnerSectionsVideo from "./PartnerSectionsPage/PartnerSectionsVideo";

const PartnerDemoPage = (props) => {
  useDocumentTitle("Henri Trip : Devenez partenaires !")

  return (
    <div style={{overflow: "hidden"}}>
      <PartnerSectionsNavbar demo={true}/>
      <PartnerSectionsHeader demo={true} background="https://general-henri-1-paris.s3.fr-par.scw.cloud/partenaire_page/header_partner-demo.png" />
      <div className="container">
        <PartnerSectionsVideo/>
        <PartnerSectionsSwitch demo={true}/>
        <PartnerSectionsServices demo={true}/>
        <PartnerSectionsTouristicSites demo={true}/>
      </div>
    </div>
  )
};

export default PartnerDemoPage;