export const addRoadTrips = payload => ({
    type: "ADD_ROADTRIPS",
    payload
})

export const pushRoadTrip = payload => ({
    type: "PUSH_ROADTRIP",
    payload
})

export const updateRoadTrip = payload => ({
    type: "UPDATE_ROADTRIP",
    payload
})

export const deleteRoadTrip = payload => ({
    type: "DELETE_ROADTRIP",
    payload
})

export const deleteRoadTrips = () => ({
    type: "DELETE_ROADTRIPS"
})

export const addUserRoadTrips = payload => ({
    type: "ADD_USER_ROADTRIPS",
    payload
})

export const addUserFavoriteRoadTrips = payload => ({
    type: "ADD_FAVORITE_ROADTRIPS",
    payload
})

export const addUserFavoriteRoadTrip = payload => ({
    type: "ADD_FAVORITE_ROADTRIP",
    payload
})

export const deleteUserFavoriteRoadTrips = payload => ({
    type: "DELETE_FAVORITE_ROADTRIPS",
    payload
})

export const pushUserRoadTrip = payload => ({
    type: "PUSH_USER_ROADTRIP",
    payload
})

export const updateUserRoadTrip = payload => ({
    type: "UPDATE_USER_ROADTRIP",
    payload
})

export const deleteUserRoadTrip = payload => ({
    type: "DELETE_USER_ROADTRIP",
    payload
})

export const deleteUserRoadTrips = () => ({
    type: "DELETE_USER_ROADTRIPS"
})