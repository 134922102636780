import React, {useEffect} from "react";
import useDocumentTitle from "../../hooks/use-document-title";
import SVG from "react-inlinesvg";
import {toAbsoluteUrl} from "../../utils/utils";
import './Checkout.scss'
import useCart from "../../hooks/Cart/use-cart";
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";

export default function CheckoutSuccessPage() {
  const {t} = useTranslation(["henriTrip"])
  const {actions} = useCart();
  useDocumentTitle(`Succès • Henri Trip`)
  useEffect(() => {
    actions.removeCart();
  },[])
  return (
    <div className="row m-0 checkout-page">
      <div className="col-12 d-flex align-items-center justify-content-center w-100" style={{minHeight: "100vh"}}>
        <div className="w-100 h-auto d-flex flex-column">
          <SVG src={toAbsoluteUrl("/assets/logo/Asset1.svg")} width="200" height="200" className="mx-auto mt-5 pb-3" alt="icon Henri Trip" />
          <div className="card-checkout w-100 h-100 d-flex flex-column align-items-center ">
            <h1>{t("checkOutPage.success")}</h1>
            <div style={{borderRadius: 200, height: 200, width: 200, background: '#F8FAF5'}} className="d-flex justify-content-center my-2">
              <i className="checkmark">✓</i>
            </div>
            <p>{t("checkOutPage.nextStepSuccess")}</p>
            <Link to={`/user/roadtrips/orders`} className="font-weight-bolder text-center mt-2">{t("checkOutPage.nextStepSuccessMyOrder")}</Link>
          </div>
        </div>
      </div>
    </div>
  );
}