import React, {useContext, useEffect, useState} from 'react';
// import ActivityContext from './ActivityContext';
// import TouristicSiteContext from "./TouristicSiteContext";

const InterestPointContext = React.createContext({});

const InterestPointProvider = ({initialInterestPoint = {}, children}) => {
  // const {activity, setActivity} = useContext(ActivityContext);
  // const {touristicSite, setTouristicSite} = useContext(TouristicSiteContext);
  const [interestPoint, setInterestPoint] = useState(initialInterestPoint);

  useEffect(() => {
    if (JSON.stringify(initialInterestPoint) !== JSON.stringify(interestPoint)) {
      setInterestPoint(initialInterestPoint);
    }
  }, [initialInterestPoint]);

  // useEffect(() => {
  //   if (interestPoint?.id && activity?.visit_sheets) {
  //     let newVisitSheets = [...activity.visit_sheets];
  //     if (newVisitSheets.find(vs => vs.id === interestPoint.id)) {
  //       newVisitSheets = newVisitSheets.map(vs => vs.id === interestPoint.id ? {...interestPoint} : vs);
  //     } else {
  //       newVisitSheets.push({...interestPoint});
  //     }
  //     // setActivity(prev => ({...prev, visit_sheets: newVisitSheets}));
  //     const newActivities = touristicSite.activities.map(act => act.id === interestPoint.activity_id ? {...act, visit_sheets: newVisitSheets} : act);
  //     setTouristicSite(prev => ({...prev, activities: [...newActivities]}));
  //   }
  // }, [interestPoint]);

  const publicMethods = {
    interestPoint,
    setInterestPoint,
  };

  return (
    <InterestPointContext.Provider value={publicMethods}>
      {children}
    </InterestPointContext.Provider>
  );
};

export {InterestPointProvider};

export default InterestPointContext;