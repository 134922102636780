import DownloadsPage from 'pages/UserPage/DownloadsPage.js/DownloadsPage';
import React from 'react';

const Downloads = () => {

    React.useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <DownloadsPage />
    );
};

export default Downloads;