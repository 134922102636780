import React from "react";
import { Button } from 'components/form/button';
import Title from 'components/header/title/title';
import './AmbassadorSectionsGrade.scss';

const grade = [{
        nom: "LE MALIN",
        description: "Découvre ta région et son patrimoine. Choisis parmi toutes les activités insolites près de chez toi et vis une expérience unique et motive ta communauté à marcher sur tes traces pour découvrir ta région !",
        image: "https://general-henri-1-paris.s3.fr-par.scw.cloud/ambassadors/categorie1.png",
        important: "1",
        content: "activité / visite offerte par mois dans ta région.",
        imageRecompense: "https://general-henri-1-paris.s3.fr-par.scw.cloud/ambassadors/ticket.png",
        margin: "mt-2 mt-lg-0",
    },
    {
        nom: "L'EXPLORATEUR",
        description: "Pars à l’aventure avec Henri Trip ! Explore ta région et découvre des lieux uniques près de chez toi. Imprègne-toi de la culture locale au travers de 2 activités exclusives et pars à la rencontre de ses habitants !",
        image: "https://general-henri-1-paris.s3.fr-par.scw.cloud/ambassadors/categorie2.png",
        important: "2",
        content: "activités / visites offertes par mois dans ta région.",
        imageRecompense: "https://general-henri-1-paris.s3.fr-par.scw.cloud/ambassadors/ticket-2.png",
    },
    {
        nom: "LE GRAND VOYAGEUR",
        description: "Arpente les quatre coins de la France et découvre les trésors touristiques qu’elle renferme.",
        secondDescription: "Découvre l’un des itinéraires Henri Trip et ses activités insolites.",
        image: "https://general-henri-1-paris.s3.fr-par.scw.cloud/ambassadors/categorie3.png",
        important: "un itinéraire",
        content: "et ses activités offert tous les mois.",
        imageRecompense: "https://general-henri-1-paris.s3.fr-par.scw.cloud/ambassadors/ticket-3.png",
    },
]

const AmbassadorSectionsGrade = () =>  (
    <div className="container mt-3">
        <Title title="Les grades des ambassadeurs Henri Trip"/>
        <div className="row">
            {
                grade?.map((el, i) =>
                    <>
                    <div className={`${el.margin ? el.margin : "mt-4 mt-lg-0"} col-12 col-lg-4`}>
                        <div className="row d-flex justify-content-center">
                            <img src={el.image} alt="ambassador-grade" className="ambassador-grade__image-grade"/>
                        </div>
                        <div className="row d-flex justify-content-center mt-3">
                            <p className="ambassador-grade__title">{el.nom}</p>
                        </div>
                        <div className="row mt-1 mt-md-3">
                            <p className="ambassador-grade__text-description">{el.description}</p>
                            { (el.secondDescription) &&
                            <p className="ambassador-grade__text-description">{el.secondDescription}</p>
                            }
                        </div>
                    </div>
                    <div className="col-12 col-lg-4 mt-3 mt-md-4 d-inline d-lg-none">
                        <div className="row d-flex justify-content-center">
                            <img src={el.imageRecompense} alt="ambassador-grade" className="ambassador-grade__image-grade"/>
                        </div>
                        <div className="row mt-3 d-flex justify-content-center">
                            <p className="ambassador-grade__text-description">LE + : <strong>{el.important}</strong> {el.content}</p>
                        </div>
                    </div>
                    </>
                )
            }
            {
                grade?.map((recompense,i) =>
                    <div className="col-12 col-md-4 mt-4 d-none d-lg-inline">
                        <div className="row d-flex justify-content-center">
                            <img src={recompense.imageRecompense} alt="ambassador-grade" className="ambassador-grade__image-grade"/>
                        </div>
                        <div className="row mt-3">
                            <p className="ambassador-grade__text-description">LE + : <strong>{recompense.important}</strong> {recompense.content}</p>
                        </div>
                    </div>
                )
            }
            <div className="col d-flex justify-content-center mt-4">
                <Button rounded className="px-3" title="Candidater" onClick={() => window.open("https://forms.office.com/r/gh46npYQ6S", '_blank')}/>
            </div>
        </div>
    </div>
)

export default AmbassadorSectionsGrade;
