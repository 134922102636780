import React, {useEffect} from 'react'

const AdsByGoogle = (props) =>{
  useEffect(() => {
    setTimeout(() => {
      window.adsbygoogle = window.adsbygoogle || []
      window.adsbygoogle.push({})
    }, 2000)
  },[props.keys])

  return(
    <React.Fragment key={props.keys}>
      {props.children}
    </React.Fragment>
  )
}

export default AdsByGoogle;