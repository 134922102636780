import React, {useEffect, useState} from 'react';
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';

const Audio = ({data}) => {
  const [urls, setUrls] = useState(
    data ? data.filter((el) => el.id).map((el) => el.url.replace(/\?.*/, '')) : []
  );
  const [currentSongIndex, setCurrentSongIndex] = useState(0);

  useEffect(() => {
    setUrls(
      data ? data.filter((el) => el.id).map((el) => el.url.replace(/\?.*/, '')) : []
    );
  }, [data]);

  return (
    <AudioPlayer
      loop={true}
      style={{
        color: '#868686 !default',
      }}
      className="my-3"
      src={urls[currentSongIndex]}
      customVolumeControls={[]}
      showSkipControls={true}
      showJumpControls={false}
      customAdditionalControls={[]}
      autoPlayAfterSrcChange={true}
      onClickPrevious={() =>
        setCurrentSongIndex((state) => (state - 1 >= 0 ? state - 1 : 0))
      }
      onClickNext={() =>
        setCurrentSongIndex((state) => (state + 1 < urls.length ? state + 1 : 0))
      }
      onEnded={() =>
        setCurrentSongIndex((state) => (state + 1 < urls.length ? state + 1 : 0))
      }
    />
  )
};

export default Audio;
