import React, { useState } from "react";
import "./dropContainer.scss";
import SVG from "react-inlinesvg";

const DropContainer = ({ titre, description, children, option, ot }) => {
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(!open);
  }

  return (
    <>
      <div className="row col-12 mb-2 pr-0" onClick={handleClick}>


          {
            option ?
              <div className="col-10">
                <div className="row">
                  <div className={`col-8  ${ ot ? "col-lg-11" : "col-lg-10"}`}>
                    <p className={`dropContainer__title  ${ option ? "dropContainer__text-option" : ""}`}>{titre}</p>
                  </div>
                  <div className={`col-4  ${ ot ? "col-lg-1" : "col-lg-2"}`}>
                    <p className="badge rounded-pill bg-secondary">OPTION</p>
                  </div>
                </div>
              </div>
              :
              <div className="col-10">
                <p className={`dropContainer__title ${ option ? "dropContainer__text-option" : ""}`}>{titre}</p>
              </div>
          }
        <div className="col-2 d-flex justify-content-end">
              {
                open ?
                  <SVG src="/assets/icons/chevron-up-solid.svg" width="20px" className="dropContainer__svg"/>
                :
                  <SVG src="/assets/icons/angle-down-solid.svg" width="20px"/>
              }
        </div>
      </div>

      {open ? (
        <>
          <div className="col-12">
            <hr className="mt-0"/>
          </div>
          <div className="col-12 mb-2 ">
            {description}
          </div>
          {children}
        </>
      ) :
        <></>
      }
    </>
  );
};


export default DropContainer;
