const breakpoints = {
    xs: 0,
    sm: 600,
    md: 900,
    lg: 1200,
    xl: 1400,
};

const fonts = {
    serif: 'Montserrat, serif',
    sans: '"Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif'
};

const colors = {
    henriBlue: '#54A8FC',

    black: '#000000',
    black80: '#000000CC',

    white: '#FFFFFF',
    white80: '#FFFFFFCC',
    white60: '#FFFFFF99',
    lightGrey: '#D5D5D7',
    lightGrey90: '#D5D5D7E6',
    darkGrey: '#101011',
    darkGrey90: '#101011E6',
    doveGray: '#6A6A6A',
    silver20: '#c9c9c933',
    congressBlue20: '#024f9133',
    congressBlue70: '#024f91b3',

    electricTomato: '#F4354B',

    honey: '#FFC736',
    teal: '#0A9F4F',

    mapLineWhite: '#024F91',
    fileInputEmpty: '#D4E4FC',

    brightOrange: '#EB7838',
    brightPink: '#EB3883',
};

const theme = {
    colors: {
        ...colors,

        primary: colors.henriBlue,
        // secondary: colors.neonBlue,
        brand: colors.henriBlue,
        error: colors.electricTomato,
        danger: colors.electricTomato,
        warning: colors.honey,
        success: colors.teal,

        text: colors.darkGrey90,
        textBody: colors.black80,

        activity: colors.brightOrange,
        interestPoint: colors.brightPink,
    },
    breakpoints,
    fonts
};

export { breakpoints, colors };
export default theme;
