import { Field } from "formik";
import React from "react";

const InputFormik = ({
  label,
  labelSuffix,
  name,
  classes,
  placeholder,
  type,
  ...props
}) => {
  return (
    <div
      className={"form-group fv-plugins-icon-container " + props.parentClasses}
    >
      {label && (
        <div className="d-flex justify-content-between">
          <label className="font-size-h6 text-dark">
            {label}
            {props.required ? " *" : ""}
          </label>
          {labelSuffix}
        </div>
      )}
      <Field
        onChange={props.handleChange}
        onBlur={props.handleBlur}
        placeholder={placeholder}
        type={type || "text"}
        className={`form-control form-control-solid h-auto py-1 px-2 ${classes}`}
        name={name}
        disabled={props.disabled}
      />
      {(props.touched[name] && props.errors[name]) || props.error ? (
        <div className="fv-plugins-message-container">
          <div className="fv-help-block text-danger">
            {props.errors[name] || props.error}
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default InputFormik;
