// eslint-disable-next-line import/no-anonymous-default-export
export default {
  formID: 'userForm',
  formField: {
    username: {
      name: 'username',
      required: true,
    },
    email: {
      name: 'email',
      type: 'email',
      required: true,
    },
    firstname: {
      name: 'firstname',
      required: true,
    },
    lastname: {
      name: 'lastname',
      required: true,
    },
    gender: {
      name: 'gender',
      noOptionsMessage: () => 'Aucune civilitée trouvée',
      required: true,
    },
    birthdate: {
      name: 'birthdate',
      type: 'date',
      required: true,
    },
    password: {
      name: 'password',
      type: 'password',
      required: true,
    },
    password_confirmation: {
      name: 'password_confirmation',
      type: 'password',
      required: true,
    },
    statement: {
      name: 'statement',
      required: true,
    },
  },
};
