import {useLayoutEffect, useRef, useState} from "react";
import styled from "styled-components";
import {breakpoint} from "styled-components-breakpoint";
import Arrow from "../Arrow";

const CardsContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: row;
  overflow-x: scroll;
  max-width: 100%;
  scroll-behavior: smooth;
  font-size: 1em;
  -ms-overflow-style: none;
  &::-webkit-scrollbar{
    display: none;
  }
`;

const CardContainer = styled.div`
  height: 100%;
  padding-bottom: 30px;
  padding-left: 10px;
  padding-right: 10px;
  
  ${props => props.selected && `
    left: 0;
  `}
`;

const ArrowNavigation = styled(Arrow)`
  display: none;
  position: absolute;
  top: 50%;
  bottom: 50%;
  transform: translateY(-50%);

  :hover {
    transform: translateY(-50%) scale(120%);
  }
  
  ${breakpoint('md')`
    display: ${props => props.disabled ? 'none' : 'block'};
  `}
`;

const SliderContainerWithArrowNavigation = ({indexSelected, children, onCardClick = () => {}, ...rest}) => {
  const [childOffsetWidth, setChildOffsetWidth] = useState();
  const [leftArrow, setLeftArrow] = useState(true);
  const [rightArrow, setRightArrow] = useState(true);
  const containerRef = useRef();
  const firstChildRef = useRef();

  const slide = direction => {
    const offsetWidth = containerRef.current.children[0]?.offsetWidth || 0;
    containerRef.current.scrollBy(direction * offsetWidth, 0);
  };

  const updateArrows = element => {
    setLeftArrow(element?.scrollLeft !== 0);
    setRightArrow(element?.scrollLeft + element?.getBoundingClientRect()?.width !== firstChildRef.current?.offsetWidth * children.length);
  };

  useLayoutEffect(() => {
    const observer = new ResizeObserver((entries) => {
      for (const entry of entries) {
        setChildOffsetWidth(entry.target?.children?.[0]?.offsetWidth);
        updateArrows(entry.target);
      }
    });
    observer.observe(containerRef.current);

    return () => observer.disconnect();
  }, [containerRef, firstChildRef]);

  return (
    <div
      style={{position: 'relative'}}
      {...rest}
    >
      <CardsContainer
        ref={containerRef}
        style={{position: 'relative', paddingLeft: 25, paddingRight: 25}}
        onScroll={event => updateArrows(event.target)}
      >
        {children?.map((child, index) => (
          <CardContainer
            ref={index === 0 ? firstChildRef : undefined}
            key={index}
            onClick={() => onCardClick(index)}
            selected={index === indexSelected}
            style={{
              position: 'absolute',
              cursor: 'pointer',
              left: index === indexSelected ? 0 : `${childOffsetWidth * (index + (indexSelected && index < indexSelected ? 1 : 0))}px`,
              transition: 'left 0.5s ease-in-out',
            }}
          >
            {child}
          </CardContainer>
        ))
        }
      </CardsContainer>
      <>
        <ArrowNavigation
          direction="left"
          disabled={!leftArrow}
          onClick={() => slide(-1)}
          style={{left: -5}}
        />
        <ArrowNavigation
          direction="right"
          disabled={!rightArrow}
          onClick={() => slide(1)}
          style={{right: -5}}
        />
      </>
    </div>
  );
};

export default SliderContainerWithArrowNavigation;