import React, {useEffect, useState} from 'react';
import {mediasTyped} from "../utils/media";

const TouristicSiteContext = React.createContext({});

const TouristicSiteProvider = ({initialTouristicSite = {}, children}) => {
  const [touristicSite, setTouristicSite] = useState(initialTouristicSite);

  useEffect(() => {
    if (JSON.stringify(initialTouristicSite) !== JSON.stringify(touristicSite)) {
      setTouristicSite(initialTouristicSite);
    }
  }, [initialTouristicSite]);

  const publicMethods = {
    touristicSite,
    setTouristicSite,
  };

  return (
    <TouristicSiteContext.Provider value={publicMethods}>
      {children}
    </TouristicSiteContext.Provider>
  );
};

export {TouristicSiteProvider};

export default TouristicSiteContext;