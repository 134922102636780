import styled from 'styled-components';
import {commonStyleMixin} from "./common";
import InputErrorWrapper from "./InputErrorWrapper";
import LabelWrapper from "./LabelWrapper";

const SelectStyled = styled.select`
  ${commonStyleMixin};
  
  margin: 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-appearance: none;
  -moz-appearance: none;
  padding-right: calc(12px + 12px + 30px);
  
  background-image:
    linear-gradient(45deg, transparent 50%, gray 50%),
    linear-gradient(135deg, gray 50%, transparent 50%),
    linear-gradient(to right, #ccc, #ccc);
  background-position:
    calc(100% - 20px) 50%,
    calc(100% - 15px) 50%,
    calc(100% - 40px) 0.5em;
  background-size:
    5px 5px,
    5px 5px,
    1px calc(100% - 1em);
  background-repeat: no-repeat;
  :focus {
    background-image:
      linear-gradient(135deg, transparent 50%, ${props => props.theme.colors.henriBlue} 50%),
      linear-gradient(45deg, ${props => props.theme.colors.henriBlue} 50%, transparent 50%),
      linear-gradient(to right, #ccc, #ccc);
  }
`;

const Select = ({hasError, errorMessage, ErrorComponent, placeholder, children, name, label, style, ...rest}) => (
  <LabelWrapper
    name={name}
    label={label}
    style={style}
  >
    <InputErrorWrapper
      errorMessage={errorMessage}
      ErrorComponent={ErrorComponent}
      hasError={hasError}
    >
      <SelectStyled
        hasError={hasError}
        name={name}
        id={name}
        { ...rest }
      >
        { placeholder && (
          <option value="" disabled>{placeholder}</option>
        )}
        {children}
      </SelectStyled>
    </InputErrorWrapper>
  </LabelWrapper>
);

export default Select;