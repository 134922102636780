import { Button } from 'components/form/button';
import { Form } from 'semantic-ui-react';
import React, { useEffect, useState } from "react";
import {Link} from "react-router-dom";
import AuthPageStructure from './AuthPageStructure';
import HCaptcha from "@hcaptcha/react-hcaptcha";
import useDocumentTitle from 'hooks/use-document-title';
import {useLocation} from "react-router";
import FacebookLoginButton from "../../components/Oauth/FacebookLoginButton";
import Divider from "../../components/common/Divider";
import { useTranslation } from "react-i18next";
import GoogleLoginButton from 'components/Oauth/GoogleLoginButton';



const LoginPage = ({ handleSubmit, error, handleFacebookSubmit, handleGoogleSubmit  }) => {
  const {t} = useTranslation(["henriTrip"])
  const query = new URLSearchParams(useLocation().search);

  useDocumentTitle(t("login.documentTitleLogin"))
  const [state, setState] = useState({
    login: '',
    password: '',
    connexionTry: 0,
    captcha: false
  })

  const captcha = React.createRef();

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      submit()
    }
  }

  const submit = () => {
    if(state.connexionTry < 3 || state.captcha){
      handleSubmit({
        login: state.login,
        password: state.password
      })
      if(state.connexionTry > 2) captcha.current.resetCaptcha();
      setState({...state, connexionTry: state.connexionTry + 1, captcha: false})
    }
  }

  const handleChange = (e, {name, value}) => {
    setState({...state, [name]: value})
  }

  // Facebook Login
  const [login, setLogin] = useState(null);
  useEffect(() => {
    if(login){
      handleFacebookSubmit(login)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [login])

  return (
    <AuthPageStructure>
      <Form onSubmit={handleSubmit} onKeyDown={handleKeyDown}>
        <h2 className="text-center pb-3">{t("login.loginButton")}</h2>
        {
          error &&
          <div className="p-3 bg-danger my-2 text-white">
            {error}
          </div>
        }
        <Form.Field>
          <FacebookLoginButton
            onLoginSuccess={setLogin}
            title={t("login.buttonLoginFacebook")}
          />
        </Form.Field>
        {/* <Form.Field>

        <GoogleLoginButton handleGoogleSubmit={handleGoogleSubmit} title={t("login.buttonLoginGoogle")}/>
        </Form.Field> */}
        <Form.Field>
          <div>
            {t("login.conditionOfUse.firstPart")}<a target="_blank" className="font-weight-bold" href={"https://support.henritrip.fr/conditions-generales-dutilisation-du-site-internet-henri-trip-et-notifications/"} rel="noreferrer">{t("login.conditionOfUse.secondPart")}</a> {t("login.conditionOfUse.thirdPart")} <a target="_blank" className="font-weight-bold" href={"https://support.henritrip.fr/politique-de-confidentialite/"} rel="noreferrer">{t("login.conditionOfUse.fourthPart")}</a>.
          </div>
        </Form.Field>
        <Form.Field>
          <Divider>ou</Divider>
        </Form.Field>
        <Form.Input
          id="login"
          name="login"
          label={t("login.inputPseudo")}
          placeholder="henri@example.fr"
          type="email"
          onChange={handleChange}
        />
        <Form.Input
          id="password"
          name="password"
          label={t("login.general.password")}
          placeholder="········"
          type="password"
          onChange={handleChange}
        />
        {
          state.connexionTry > 2 &&
          <HCaptcha
            ref={captcha}
            sitekey={process.env.REACT_APP_HCAPTCHA_API_KEY}
            onVerify={() => setState({...state, captcha: true})}
          />
        }
        <Form.Field>
          <Link className="font-weight-bold" to="/forgot-password">{t("login.forgetPassword")}</Link>
        </Form.Field>
        <Form.Field>
          <Button
            extended
            onClick={submit}
            color="henri"
            title={t("login.loginButton")}
          />
        </Form.Field>
      </Form>
      <p className="text-center pt-3">{t("login.noAccount")}<Link to={`/register?redirectURL=${query.get("redirectURL")}`} className="font-weight-bolder">{t("login.register")}</Link></p>
    </AuthPageStructure>
  );
};

export default LoginPage;
