import Title from 'components/header/title/title';
import React, {useEffect, useRef, useState} from 'react';
import useDocumentTitle from 'hooks/use-document-title';
import {useTranslation} from "react-i18next";
import {linkAdder, metaAdder, removeAccents, TABLET_WIDTH} from "../../utils/utils";
import {HeaderButton} from "../../components/header/buttons";
import {Search as SearchIcon} from "@material-ui/icons";
import AutocompletePlaceAddress from "../../components/search/autocompletesearchaddressonly";
import {Dropdown, Loader} from "semantic-ui-react";
import Pagination from "../../components/pagination/pagination";
import { ActivityCard } from '../../components/_v2/activity';
import { ThemeProvider } from "styled-components";
import theme from "../../_ui/_v2/styles/theme";

const TouristicSitesPage = ({touristicSites, loading, numberPages, handleQueryParams, sortQuery}) => {
  const {t} = useTranslation(["henriTrip"])
  const docTitle = sortQuery.place ? `Lieux à découvrir à ${sortQuery.place} (${sortQuery.insee}) • Henri Trip` : `${t("touristicSites.touristicSitesPage.documentTitle")} • Henri Trip`
  useDocumentTitle(docTitle);

  useEffect(() => {
    metaAdder('name="description"', "Découvrez l'ensemble des sites touristique partout en France, créez des itinéraires de voyages et partagez les avec la communauté d'Henri Trip.")
    metaAdder('name="author"', "HenriTrip")
    metaAdder('name="keywords"', "touriste, comparer, choses à faire, activités touristiques, tours, attractions, billets, trip, road, roadtrip, informations, henri trip")
    linkAdder(document.location.href)
  }, []);

  const perPageOptions = [
    {
      key: "25",
      text: "25",
      value: 25,
      content: 25
    },
    {
      key: "50",
      text: "50",
      value: 50,
      content: 50
    },
    {
      key: "100",
      text: "100",
      value: 100,
      content: 100
    }
  ];

  const optionsSort = [
    {
      key: "A-Z",
      text: "A-Z",
      value: "ASC",
      content: "A-Z"
    },
    {
      key: "Z-A",
      text: "Z-A",
      value: "DESC",
      content: "Z-A"
    }
    ];

  const categorieFilter = [
    {key:'Activité', text:t('general.category.activity'), value:53},
    {key:'Site Religieux', text:t('general.category.religiousSite'), value:54},
    {key:'Parc / Jardin', text:t('general.category.parkGarden'), value: 55},
    {key:'Oenotourisme / Spiritourisme', text:t('general.category.wineTourisme'), value:56},
    {key:'Chateaux / Monument historique', text:t('general.category.historicalMonument'), value:57},
    {key:'Grotte / Site préhistorique', text:t('general.category.cavePrehistoric'), value:58},
    {key:'Musée / Exposition', text:t('general.category.museum'), value:59},
    {key:'Bien-être', text:t('general.category.wellness'), value:60},
    {key:'Parc d\'attraction / Parc animalier', text:t('general.category.park'), value:61},
    {key:'Le choix d\'Henri', text:t('general.category.henriChoice'), value:62},
  ]

  const [query, setQuery] = useState({
    title_fr: sortQuery.title_fr,
    latitude: sortQuery.latitude,
    longitude: sortQuery.longitude,
    sw: sortQuery.sw,
    ne: sortQuery.ne,
    category: sortQuery.category ? sortQuery.category.split(',').map(Number) : undefined,
    current_page: sortQuery.current_page || 1,
    per_page: 50,
    order: sortQuery.order || undefined,
    insee: sortQuery.insee || undefined,
    place: sortQuery.place || undefined,
  });
  const [state, setState] = useState({
    buttonAddressDisabled: null,
    locationIsLoading: false
  })
  const [input, setInput] = useState(sortQuery.title_fr || '')
  // eslint-disable-next-line
  const [addressFocus, setAddressFocus] = useState(false)

  const addressRef = useRef();
  const titleRef = useRef();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [sortQuery])

  useEffect(() => {
    handleQueryParams(query);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query])

  const handleFilters = (n, v) => {
    setQuery(prev =>{
      return{
        ...prev,
        category: v.length === 0 ? undefined : v,
        current_page: 1
      }
    })
  }

  const handleChangeSort = (event, data) => {
    setQuery(prev =>{
      return{
        ...prev,
        order: data.value === "" ? undefined : data.value,
        current_page: 1
      }
    })
  };

  const onSelectAddress = (address) => {
    if(address.bbox){
      setQuery(prev =>{
        return{
          ...prev,
          sw: `${address.bbox[1]},${address.bbox[0]}`,
          ne: `${address.bbox[3]},${address.bbox[2]}`,
          latitude: undefined,
          longitude: undefined,
          current_page: 1,
          place: undefined,
          insee: undefined,
        }
      })
    }
    else {
      setQuery(prev =>{
        return{
          ...prev,
          latitude: address.geometry.coordinates[1],
          longitude: address.geometry.coordinates[0],
          sw: undefined,
          ne: undefined,
          current_page: 1,
          place: undefined,
          insee: undefined,
        }
      })
    }

    setState(prev =>{
      return{
        ...prev,
        buttonAddressDisabled: false,
      }
    });
    setAddressFocus(true)
  };

  const checkAddress = (b) => {
    setState(prev =>{
      return{
        ...prev,
        buttonAddressDisabled: b,
      }
    });
  };

  const onClearAddress = () => {
    setState({
      locationIsLoading: false,
      buttonAddressDisabled: true,
    });
    setQuery(prev =>{
      return{
        ...prev,
        latitude: undefined,
        longitude: undefined,
        ne: undefined,
        sw: undefined,
        current_page: 1
      }
    })
    setAddressFocus(false)
  };

  return (
    <ThemeProvider theme={theme}>
      <div className="container min-vh-75 pt-5">
        <Title title={sortQuery.place ? `Lieux à découvrir à ${sortQuery.place} (${sortQuery.insee})` : "Sites touristiques"} description={t("touristicSites.touristicSitesPage.description")} />
        <div className="my-2 pb-3 px-2 border-bottom row">
          <div className="col-md-6 col-xs-12 mb-2 mt-md-2 col-lg-6 roadtrip__input-container roadtrip__input-search"
               onClick={() => titleRef.current.focus()}>
            <input
              className="roadtrip__input"
              placeholder={t("roadTripsPage.searchTitle")}
              ref={titleRef}
              value={input}
              onInput={(e) => setInput(e.target.value)}
            />
            <HeaderButton
              materialIcon={SearchIcon}
              className="title-button"
              onClick={()=> setQuery({...query, title_fr: input !== "" ? removeAccents(input) : undefined, current_page: 1})}
            />
          </div>

          <div className="col-md-6 col-lg-6 mt-md-2 mb-5">
            <div className="roadtrip__input-container roadtrip__input-location ml-md-1" onClick={() => addressRef.current.click()}>
              <AutocompletePlaceAddress
                name="address"
                className="roadtrip__input"
                placeholder={t("roadTripsPage.searchLocation")}
                onSelect={onSelectAddress}
                onClear={onClearAddress}
                ref={addressRef}
                onFocusInp={() => setState({...state, focus: true})}
                onBlurInp={() => setState({...state, focus: false})}
                isAddressComplete={checkAddress}
              />
            </div>
          </div>
          <div className="text-center col-md-4 mb-2 mb-md-0">
            <Dropdown
              clearable
              button
              className="icon col-sm-12 col-md-12 mt-md-2 m-0"
              placeholder={t("general.category.title")}
              icon="sliders horizontal"
              labeled
              multiple
              selection
              settings={{useLabels: false}}
              options={categorieFilter}
              value={query.category || []}
              onChange={(event, data) => handleFilters("category", data?.value ?? 0) }
            />
          </div>

          <div className="text-center col-md-4 mb-2 mb-md-0 ">
            <Dropdown
              className="icon col-sm-12 col-md-12 mt-md-2 m-0"
              icon="filter"
              placeholder={t("touristicSites.touristicSitesPage.filter.order")}
              clearable
              button
              labeled
              selection
              options={optionsSort}
              value={query.order || undefined}
              onChange={handleChangeSort}
            />
          </div>

          <div className="text-center col-md-4 mb-2 mb-md-0 ">
            <Dropdown
              button
              className="icon col-sm-12 col-md-12 mt-md-2 m-0"
              labeled
              options={perPageOptions}
              value={query.per_page || 50}
              settings={{useLabels: false}}
              onChange={(event, data) => setQuery(prev =>{
                return{
                  ...prev,
                  per_page: data.value,
                  current_page: 1
                }
              })}
            />
          </div>

        </div>
        <div className="row">
          {/* <div className="col-11 col-md-8 col-lg-2" style={{height: window.innerWidth > TABLET_WIDTH ? "auto": 200, borderRadius: window.innerWidth > TABLET_WIDTH ? 0: "1em"}}>
            {
              window.innerWidth > TABLET_WIDTH ?
                <AdsByGoogle keys={document.location.href}>
                  <ins className="adsbygoogle d-flex justify-content-center align-items-center"
                      data-ad-client="ca-pub-3342109216277632"
                      data-ad-slot="7370768100"
                      data-ad-format="auto"
                      data-full-width-responsive="true"></ins>
                </AdsByGoogle>
                :
                <AdsByGoogle keys={document.location.href}>
                  <ins className="adsbygoogle d-flex justify-content-center align-items-center"
                      style={{height: 200, width: "100%"}}
                      data-ad-format="fluid"
                      data-ad-layout-key="-85+ev-16-1z+89"
                      data-ad-client="ca-pub-3342109216277632"
                      data-ad-slot="7281415997"></ins>
                </AdsByGoogle>
            }
          </div> */}
          {
            !loading ?
              touristicSites?.length !== 0 ?
                touristicSites?.map((el,i) => {
                  if (i%10 === 0 && i !== 0){
                    return <React.Fragment key={el.id}>
                      {/* <div className="w-100 my-4" style={{height: 200, borderRadius: "1em"}}>
                        <AdsByGoogle keys={document.location.href}>
                          <ins className="adsbygoogle d-flex justify-content-center align-items-center"
                               style={{height: 200, width: "100%"}}
                               data-ad-format="fluid"
                               data-ad-layout-key="-85+ev-16-1z+89"
                               data-ad-client="ca-pub-3342109216277632"
                               data-ad-slot="7281415997"></ins>
                        </AdsByGoogle>
                      </div> */}
                      <div className="col-md-6 col-lg-4 py-2 d-flex flex-column align-items-center">
                        <ActivityCard activity={el}/>
                      </div>
                    </React.Fragment>
                  }
                  else{
                    return<div key={el.id} className="col-md-6 col-lg-4 py-2 d-flex flex-column align-items-center">
                       <ActivityCard activity={el}/>
                    </div>
                  }
                })
                :
                <div className="row">
                  <p className="text-muted text-center w-100">Aucun résultat trouvé</p>
                </div>
              :
              <div className="my-5 align-items-center roadtrip__loader">
                <Loader active className="mt-4 position-fixed" />
              </div>
          }
        </div>
        {/* <div className="col-11 col-md-8 col-lg-2" style={{height: window.innerWidth > TABLET_WIDTH ? "auto": 200, borderRadius: window.innerWidth > TABLET_WIDTH ? 0: "1em"}}>
          {
            window.innerWidth > TABLET_WIDTH ?
              <AdsByGoogle keys={document.location.href}>
                <ins className="adsbygoogle d-flex justify-content-center align-items-center"
                     data-ad-client="ca-pub-3342109216277632"
                     data-ad-slot="7370768100"
                     data-ad-format="auto"
                     data-full-width-responsive="true"></ins>
              </AdsByGoogle>
              :
              <AdsByGoogle keys={document.location.href}>
                <ins className="adsbygoogle d-flex justify-content-center align-items-center"
                     style={{height: 200, width: "100%"}}
                     data-ad-format="fluid"
                     data-ad-layout-key="-85+ev-16-1z+89"
                     data-ad-client="ca-pub-3342109216277632"
                     data-ad-slot="7281415997"></ins>
              </AdsByGoogle>
          }
        </div> */}

      </div>
      
      <div className="w-100 justify-content-center pt-2 roadtrip__pagination">
        <Pagination itemsNumber={numberPages || 1} itemsPerPage={query.per_page} onChange={(page) => setQuery({...query, current_page: page + 1})}/>
      </div>
    </ThemeProvider>
  );
};

export default TouristicSitesPage;
