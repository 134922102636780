import { css } from 'styled-components';
import breakpoint from 'styled-components-breakpoint';

export const typography = css`
  h1, h2, .serif {
    font-family: ${props => props.theme.fonts.sans};

    font-weight: 700;
    font-style: normal;
    line-height: 1.3;
  }
  h1, h2, .serif {
    -webkit-font-smoothing: antialiased;
  }

  h1 {
    font-size: calc(1.2rem + 1vw);
  }

  h2, .h2 {
    font-size: calc(1.1rem + .9vw);
    margin-bottom: 15px;
  }

  h3, .h3 {
    font-family: ${props => props.theme.fonts.sans};
    font-size: calc(1rem + .8vw);
    font-weight: 600;
  }

  h4, .h4 {
    font-family: ${props => props.theme.fonts.sans};
    font-size: calc(.9rem + .7vw);
    font-weight: 500;
  }

  h5, h5 {
    font-family: ${props => props.theme.fonts.sans};
    font-size: calc(.85rem + .65vw);
  }

  h6, h6 {
    font-family: ${props => props.theme.fonts.sans};
    font-size: calc(.8rem + .6vw);
  }

  p, pre, li {
    font-family: ${props => props.theme.fonts.sans};
    font-size: calc(1rem + .2vw);
    font-weight: 500;
    color: ${props => props.theme.colors.textBody};
    margin-bottom: 1.5em;
  }

  p.small, pre.small {
    font-size: calc(.9rem + .18vw);
    font-weight: 500;
  }

  small {
    font-size: 0.8rem;
  }

  a {
    color: ${props => props.theme.colors.primary};
    text-decoration: none;
    cursor: pointer;
  }

  b, strong {
    color: ${props => props.theme.colors.text};
    font-weight: 600;
  }

  ol {
    counter-reset: num;
    padding-left: 0;

    > li {
      display: block;
      position: relative;
      margin-left: 2em;
    }

    > li::before {
      position: absolute;
      left: -0.5em;
      top: -0.25em;
      transform: translateX(-100%);
      counter-increment: num;
      content: counter(num) ". ";
      font-style: normal;
      font-weight: 400;
      color: ${props => props.theme.colors.textBody};
      font-size: 1.5rem;
    }
  }

  ${breakpoint('md')`
    h1, h2, .serif {
      line-height: 1.4;
    }
  `}
`;

export default typography;
