import { useEffect, useState } from 'react';
import isFunction from '../../../../utils/isFunction';

const Collapsible = ({ collapsed, controls, children }) => {
  const [isCollapsed, setIsCollapsed] = useState(collapsed);

  useEffect(() => {
    setIsCollapsed(collapsed);
  }, [collapsed]);
  const toggleCollapsed = (e) => {
    e?.preventDefault();
    setIsCollapsed(!isCollapsed);
  };

  return (
    <>
      {
        isFunction(children)
          ? children(isCollapsed, toggleCollapsed)
          : (
            <>
              {
                isFunction(controls)
                  ? controls(toggleCollapsed)
                  : <div onClick={toggleCollapsed}>{controls}</div>
              }
              {!isCollapsed && children}
            </>
          )
      }
    </>
  );
};

export default Collapsible;