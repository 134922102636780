import styled from "styled-components";
import { breakpoint } from "styled-components-breakpoint";
import React from "react";
import SliderContainerWithArrowNavigation from "../../../_ui/_v2/components/SliderContainerWithArrowNavigation";
import mainImage from "../../../utils/touristic_sites/mainImage";
import {API_ORIGINAL_BLOB_URL} from "../../../utils/api";

const TripDurationCaption = styled.span`
  display: block;
  background-color: ${props => props.theme.colors.white60};
  box-sizing: border-box;
  border-radius: 0 0.5em 0.5em 0;
  font-weight: 600;
  padding: 0.375em .75em;
  position: absolute;
  top: 10%;
`;

const TripDescription = styled.div`
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  border-radius: 0 0 1rem 1rem;
  display: flex;
  flex-direction: column;
  position: absolute;
  bottom: 0;
  line-height: 1.6em;
  padding: 0.5em;

  > * {
    &:first-child {   
      color: white;
      font-weight: 800;
      font-size : 1.1em;
      margin-left: 2%;
    }
    :nth-child(2) {
      font-weight: 500;
      color: white;
      font-size: 0.9em;
      margin-left: 2%;
    }
  }

  ${breakpoint('md')`
   > * {
    &:first-child {   
      color: white;
      font-weight: 800;
      font-size : 1.4em;
      margin-left: 2%;
    }
    :nth-child(2) {
      font-weight: 500;
      color: white;
      font-size: 1.1em;
      margin-left: 2%;
    }
  }
`
  }
`;

const TripRecommandationStyled = styled.div`
  position: relative;
  width: 170px;
  margin: 0.5rem;
  height: 95%;
  box-shadow:0px 10px 13px -7px #000000, 16px 16px 23px -2px rgb(0 0 0 / 17%);
  border-radius: 1em;
  
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 1rem;
    position: relative;
  }
  
  ${breakpoint('md')`
    width: 240px;
  `}
`;

const TripRecommandation = ({ site, day, ...rest }) => {
  const image = mainImage(site);
  const imageUrl = image?.blob_id
    ? API_ORIGINAL_BLOB_URL(image.signed_id, image.filename)
    : "https://general-henri-1-paris.s3.fr-par.scw.cloud/small-woman.jpg";

  return (
    <TripRecommandationStyled {...rest}>
      <img src={imageUrl} alt="" />
      <TripDescription>
        <span>{site.city}</span>
        <span>{site.country || "France"}</span>
      </TripDescription>
      <TripDurationCaption>{day} jours</TripDurationCaption>
    </TripRecommandationStyled>

  )
}

const TripRecommandationsContainer = styled(SliderContainerWithArrowNavigation)`
  height: 250px;
  
  ${breakpoint('md')`
    height: 350px;  
  `}
`;

const TripRecommandations = ({ touristicSites, day, ...rest }) => (
  <TripRecommandationsContainer {...rest}>
    {
      touristicSites?.map((site, index) => (
        <div
          key={index}
          style={{ height: '100%' }}
        >
          <TripRecommandation site={site} day={day || 6} />{/*TODO remove this before MEP*/}
        </div>
      ))
    }
  </TripRecommandationsContainer>
);

const TripRecommandationsSectionStyled = styled.div`
  h2 {
    text-align: center;
  }

  ${breakpoint('md')`
    h2 {
      text-align: left;
    }
  `}
`;

const TripRecommandationsSection = ({ touristicSites, ...rest }) => (
  <TripRecommandationsSectionStyled {...rest}>
    <h2>Recommandé en fonction des tendances d’achats</h2>
    <TripRecommandations touristicSites={touristicSites} />
  </TripRecommandationsSectionStyled>
);

export {
  TripDurationCaption,
};

export default TripRecommandationsSection;