import Button from "../../../_ui/_v2/components/Button";
import Loader from "../../../_ui/_v2/components/Loader";
import { ReactComponent as AddIcon } from '../../../assets/img/logo/addIcon.svg';
import {useContext} from "react";
import {ThemeContext} from "styled-components";

const ButtonFormValidation = (
  {
    loading = false,
    onCancel,
    placeholder = 'Enregistrer'
  }
) => {
  const theme = useContext(ThemeContext);

  return (
    <div
      style={{
        display: 'flex',
        gap: '20px',
        justifyContent: 'center'
    }}
    >
      {
        onCancel && (
          <AddIcon
            onClick={onCancel}
            style={{
              display: 'inline-block',
              transform: 'rotateZ(45deg)',
              height: '35px',
              width: '35px',
              cursor: 'pointer',
            }}
          />
        )
      }
      <Button
        type='submit'
        style={{padding: '0.5rem 2rem'}}
      >
        {
          loading
            ? <Loader color={theme.colors.white} size="20px"/>
            : placeholder
        }
      </Button>
    </div>
  );
};

export default ButtonFormValidation;