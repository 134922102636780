import { Formik } from 'formik';
import useRequest from 'hooks/use-request';
import ResetPasswordPage from 'pages/AuthPage/ResetPasswordPage';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { API_RESET_PASSWORD, API_RESET_PWD_CHECK } from "utils/api";
import { REQUIRED_MESSAGE } from 'utils/texts';
import * as Yup from 'yup';
import { StringParam, useQueryParam } from "use-query-params";

const resetPwdForm = {
  password: {
    name: "password",
    label: "Nouveau mot de passe",
    placeholder: "··········",
    required: true,
  },
  password_confirmation: {
    name: "password_confirmation",
    label: "Confirmer le mot de passe",
    placeholder: "··········",
    required: true
  }
}

const resetPwdValidation = Yup.object().shape({
  [resetPwdForm.password.name]: Yup.string().required(REQUIRED_MESSAGE).matches(
    RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9]).{8,70}$"),
    "Le mot de passe doit inclure au moins 8 caractères avec au moins 1 lettre en majuscule, 1 lettre en minuscule et 1 chiffre."
  ),
  [resetPwdForm.password_confirmation.name]: Yup.string().when({
    is: value => value && value.length > 0,
    then: Yup.string().oneOf([Yup.ref(resetPwdForm.password.name)], "Les mots de passes ne sont pas égaux")
  }).required(REQUIRED_MESSAGE)
})

const ResetPassword = ({ match }) => {
  const [handleRequest] = useRequest();
  const [valid, setValid] = React.useState("Loading");
  const [error, setError] = React.useState(false);
  const history = useHistory();

  const [token] = useQueryParam("token", StringParam)

  if(!token){
    history.push("/")
  }

  React.useEffect(() => {
    handleRequest("get", API_RESET_PWD_CHECK(token), {}, {}, (data, status) => {
      if (status === "Done") {
        setValid("Valid");
      } else if (status === "Error") {
        setValid("Error");
      }
    })// eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleSubmit = (v) => {
    handleRequest("post", API_RESET_PASSWORD, { ...v, token: token }, {}, (data, status) => {
      if (status === "Done") {
        history.push("/login");
      } else if (status === "Error") {
        setError("Le mot de passe doit inclure au moins 8 caractères avec au moins 1 lettre en majuscule, 1 lettre en minuscule et 1 chiffre.");
      }
    })
  }

  return (
    <Formik
      initialValues={{
        [resetPwdForm.password.name]: "",
        [resetPwdForm.password_confirmation.name]: ""
      }}
      onSubmit={handleSubmit}
      validationSchema={resetPwdValidation}
    >
      {(formikProps) => {
        return <ResetPasswordPage {...formikProps} resetPwdForm={resetPwdForm} error={error} valid={valid} />
      }}
    </Formik>
  );
};

export default ResetPassword;