import React from 'react';
import PropTypes from 'prop-types';

import { v4 as uuidv4 } from 'uuid';

import PropagateLoader from 'react-spinners/PropagateLoader';

import './button.scss'

const Button = props => {
    const className = [
        "btn",
        "font-weight-bold",
        "btn-" + (props.outline ? "outline-" : "") + (props.color || "primary"),
        props.extended && "w-100",
        props.disabled && "disabled",
        props.rounded && "rounded-lg",
        props.className
    ].filter(el => el).join(" ");
    const buttonId = props.id || uuidv4();

    const handleClick = (e) => {
        if (props.type === "submit")
            return;
        e.preventDefault();
        if (props.onClick)
            props.onClick();
    }

    return (
        <button
            id={buttonId}
            type={props.type || "button"}
            className={className}
            onClick={handleClick}
            disabled={props.disabled}
        >
            {
                props.preffix &&
                <span className="mr-1">{props.preffix}</span>
            }
            {
                props.status !== "Loading" ?
                  props.title
                  :
                  <div className="loading">
                    <PropagateLoader color="#fff" size={5} />
                  </div>
            }
            {
                props.suffix &&
                <span className="ml-1 position-absolute" style={{right: 2.2 + 'rem'}}>{props.suffix}</span>
            }
            {props.children}
        </button>
    );
};

Button.propTypes = {
    id: PropTypes.string,
    outline: PropTypes.bool,
    color: PropTypes.string,
    title: PropTypes.oneOfType([PropTypes.node, PropTypes.elementType]),
    extended: PropTypes.bool,
    disabled: PropTypes.bool,
    onClick: PropTypes.func,
    className: PropTypes.string,
    preffix: PropTypes.oneOfType([PropTypes.node, PropTypes.element]),
    suffix: PropTypes.oneOfType([PropTypes.node, PropTypes.element]),
    type: PropTypes.string,
    status: PropTypes.string
};

export default Button;