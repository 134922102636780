import React, { useEffect, useState } from "react";
import { Button } from 'components/form/button';
import "./AmbassadorSectionsHeader.scss";
import Title from "../../../components/header/title/title";

const AmbassadorSectionsHeader = () => {
    // eslint-disable-next-line
    const [width, setWidth] = useState(window.innerWidth)

    useEffect(() => {
        function handleResize() {
            setWidth(window.innerWidth)
        }
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize)
    }, []);

    return (
        <section className="section">
            <img src="https://general-henri-1-paris.s3.fr-par.scw.cloud/ambassadors/image-haut.png" className="image--ambassador" alt="Responsive ambassador"/>
            <div className="container ambassador__text-position d-none d-lg-inline">
                <div className="row">
                    <div className="col-12 col-md-5">
                        <p className="ambassador__header-title">Deviens ambassadeur HENRI TRIP et pars en voyage partout en France !</p>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 col-md-5">
                    <Button rounded title="Candidater" className="mt-3 px-3" onClick={() => window.open("https://forms.office.com/r/gh46npYQ6S", '_blank')}/>
                    </div>
                </div>
            </div>
            <div className="container">
                <div className="d-inline d-lg-none">
                    <div className="row">
                        <div className="col">
                            <Title noTopPad={true} title="Deviens ambassadeur HENRI TRIP et pars en voyage partout en France !"/>
                        </div>
                    </div>
                    <div className="col d-flex justify-content-center mt-1">
                        <Button rounded className="px-3" title="Candidater" onClick={() => window.open("https://forms.office.com/r/gh46npYQ6S", '_blank')}/>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default AmbassadorSectionsHeader;
