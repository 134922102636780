import * as React from "react"

function Icon9(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 88.56 106.9"
      style={{color: "#00A544"}}
      {...props}
    >
      <defs>
        <style>{".prefix__cls-2{fill:#fff}"}</style>
      </defs>
      <g id="prefix__Calque_2" data-name="Calque 2">
        <g id="prefix__Calque_1-2" data-name="Calque 1">
          <path
            fill="currentColor"
            d="M44.28 0a44.27 44.27 0 00-31.16 75.73l31.16 31.17 31.16-31.17A44.27 44.27 0 0044.28 0"
          />
          <path
            className="prefix__cls-2"
            d="M45.68 57.81h-2.8v10.37h-2.39a2.8 2.8 0 00-2.8 2.8h13.18a2.8 2.8 0 00-2.8-2.8h-2.39zM65.27 43.27A9 9 0 0062 40.73a11.78 11.78 0 00.51-3.46 11.91 11.91 0 00-8.79-11.51 9.56 9.56 0 00-18.92 0A11.91 11.91 0 0026 37.27a11.78 11.78 0 00.51 3.46 8.95 8.95 0 003.72 17.08h12.65v-6.65l-5.7-9.66 5.7 5.7v-7.33l-7.2-11.15 7.2 7.2v-4.73h2.8v10.4l10.23-10.26-10.23 14.18v12.26H58.3a8.94 8.94 0 007-14.54z"
          />
        </g>
      </g>
    </svg>
  )
}

export default Icon9
