import React, {Fragment, useEffect, useState} from "react";
import FormWrapper from "./FormWrapper";
import { ReactComponent as AddIcon } from '../../../assets/img/logo/addIcon.svg';
import { ReactComponent as VideoIcon } from '../../../assets/img/logo/AVPMBuilder/video/icon_video_simple.svg';
import { ReactComponent as CompleteVideoIcon } from '../../../assets/img/logo/AVPMBuilder/video/icon_vidoe_parcours.svg';
import TitleVideoForm from "./video/TitleVideoForm";
import VideoTitleImageArray from "./video/VideoTitleImageArray";
import styled from "styled-components";

const FormikVideo = ({IllustrationComponent, editConfig, currentObject}) => (
  <Fragment key={1}>
    <FormWrapper resourceCategory="videos" editConfig={editConfig} currentObject={currentObject}/>
    {IllustrationComponent}
  </Fragment>
);

const FormContainerStyled = styled.div`
  display:flex;
  flex-direction: column; 
  gap: 40px;
  cursor: pointer; 
  width: 70%; 
  padding: 0.5em; 
  background-color: rgba(255,255,255, 0.15); 
  border-radius: 1.3em;
`;

const VideoFormToChoose = ({onLayoutItemChange, id, activeStorageServiceName, videosToDisplay}) => {
  const [selectedForm, setSelectedForm] = useState(true);
  const [formopen, setFormOpen] = useState(false);

  useEffect(()=>{
    setFormOpen(false);
    setSelectedForm(true);
  },[id]);

  const dataInformation = [
    {
      title:
        <div style={{gap: '20px', display: 'flex', alignItems: "center", justifyContent: 'center'}}>
          <VideoIcon/>
          Titre + vidéo
        </div>,
      content:
        <TitleVideoForm
          id={id + 1}
          video={videosToDisplay}
          onLayoutItemChange={onLayoutItemChange}
          activeStorageServiceName={activeStorageServiceName}
        />
    },
    {
      title:
        <div style={{gap: '20px', display: 'flex', alignItems: "center", justifyContent: 'center'}}>
          <CompleteVideoIcon/>
          Parcours vidéo
        </div>,
      content:
        <VideoTitleImageArray
          key={id}
          id={id}
          videoTitleImageArray={[]}
          onLayoutItemChange={onLayoutItemChange}
          activeStorageServiceName={activeStorageServiceName}
        />
    },
  ];

  return(
    <FormContainerStyled>
      <div>
        <div onClick={() => {
               setFormOpen(!formopen)
               setSelectedForm(!selectedForm)
             }}>
          <AddIcon style={{cursor: "pointer", color: `${!selectedForm ? '#54A8FC' : 'white'}`}}/>
        </div>
      </div>
      {
       !selectedForm && dataInformation.map((formData, index) => (
          <div
            key={index}
            onClick={() => setSelectedForm(formData.content)}
            style={{cursor: 'pointer', padding: '0.5em', backgroundColor: 'white', borderRadius: '1.3em'}}
          >
            <span style={{color: 'black', fontWeight: '700'}}>{formData.title}</span>
          </div>
        ))
      }
      {selectedForm}
    </FormContainerStyled>
  );
};

export {VideoFormToChoose, FormikVideo};