import React from "react";
import { Button } from 'components/form/button';
import Title from 'components/header/title/title';
import QuoteTemplate from 'components/ambassador/quote/quoteTemplate';
import './AmbassadorSectionsIntro.scss';

const content = [{
    description: "Voyager gratuitement te fait rêver ?",
    image: "https://general-henri-1-paris.s3.fr-par.scw.cloud/ambassadors/airplane.png"
    },
    {
    description: "Tu aimes le challenge et tu redoubles de créativité ?",
    image: "https://general-henri-1-paris.s3.fr-par.scw.cloud/ambassadors/instagram-post.png"
    },
    {
    description: "Développer ta communauté sur les réseaux est l'un de tes objectifs ?",
    image: "https://general-henri-1-paris.s3.fr-par.scw.cloud/ambassadors/rating.png"
    },
]

const AmbassadorSectionsIntro = () =>  (
    <div className="container mt-3 mt-md-0">
        <div className="d-flex justify-content-center d-inline d-lg-none">
                <img className="ambassador__image" src="https://general-henri-1-paris.s3.fr-par.scw.cloud/ambassadors/image-community.png" alt="community"/>
        </div>
        <Title noTopPad={true} title="Et si tu étais l'un de nos 13 ambassadeurs ?"/>
        <QuoteTemplate content="Le voyage c'est la part du rêve." author="Xavier Roy"/>
        <div className="row">
            <div className="col-12 col-md-8">
                {
                    content?.map((el, i) =>
                    <div className="row align-items-center mt-3">
                        <div className="col-4 col-md-2">
                            <img src={el.image} alt="ambassador__card" className="ambassador__card-image"/>
                        </div>
                        <div className="col-8 col-md-10">
                            <p className="ambassador__text-card">{el.description}</p>
                        </div>
                    </div>
                    )
                }
                <div className="row mt-4">
                    <div className="col d-flex justify-content-center">
                        <Button rounded className="px-3" title="Candidater" onClick={() => window.open("https://forms.office.com/r/gh46npYQ6S", '_blank')}/>
                    </div>
                </div>
            </div>
            <div className="col-lg-4 d-none d-lg-inline">
                <img className="ambassador__second-image" src="https://general-henri-1-paris.s3.fr-par.scw.cloud/ambassadors/image-community.png" alt="community"/>
            </div>
        </div>

    </div>
)

export default AmbassadorSectionsIntro;
