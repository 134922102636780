import { Button } from 'components/form/button';
import useRequest from 'hooks/use-request';
import React from 'react';
import { useSelector } from 'react-redux';
import { API_EXPORT_ROADTRIP } from 'utils/api';
import ModalForm from "../../../../components/formModal/ModalForm";

const ExportRoadTrip = ({ show, onHide, match }) => {
  const [handleRequest] = useRequest();
  const config = useSelector(state => state.data);

  const handleExport = () => {
    handleRequest("get", API_EXPORT_ROADTRIP(match.params.id), {}, config, (data, status) => {
      if (status === "Done") {
        onHide();
      } else if (status === "Error") {
        console.error(data);
      }
    })
  }

  return (
    <ModalForm
      isOpen={show}
      onClose={onHide}
      title="Confirmez l'export de votre road trip"
    >
      <div className="p-3">
        <p>Dès que vous confimez l'export, vous allez recevoir un mail avec toutes les données de ce road trip.</p>
        <div className="d-flex justify-content-between">
          <Button title="Annuler" color="secondary" className="mr-1" extended onClick={onHide} />
          <Button title="Exporter" color="henri" className="ml-1" extended onClick={handleExport} />
        </div>
      </div>
    </ModalForm>
  );
};

export default ExportRoadTrip;