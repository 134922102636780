import React, { useEffect, useState } from 'react';
//import pop up
import PopupModal from '_ui/_v2/components/PopupModal';

//import components
import Button from '_ui/_v2/components/Button';
import Loader from '_ui/_v2/components/Loader';

//traduction function
import { useTranslation } from "react-i18next";

//import icons
import { ReactComponent as HenriTripIcon } from "assets/img/logo/Asset1.svg";
import { ReactComponent as ArrowRight } from "assets/img/logo/arrowRight.svg";

import styled from "styled-components";
import { colors } from '_ui/_v2/styles/theme';

import { API_RESOURCE_ACCESSES, API_RESOURCE_ACCESSES_ACTIVITY } from 'utils/api';

const CharCellContainer = styled.div`
  width: 40px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
`;

const CodeCellsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  gap: 3px;
`;


const CodePopup = ({ isOpen, setIsOpen, history }) => {
  const { t } = useTranslation(["commons"]);
  const [shortCodeChars, setShortCodeChars] = useState();
  const [errors, setErrors] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleSubmit = () => {
    setErrors(false);
    const token = shortCodeChars;
    setLoading(true);
    fetchActivity(token);
  }

  const fetchActivity = (token) => {
    let resource_type = "Activity";
    let status = "active";
    const url = API_RESOURCE_ACCESSES() + `?filters[resource_type][]=${resource_type}&filters[token][]=${token}&filters[status][]=${status}`;
    fetch(url, {
      method: "GET",
      cache: "default",
      headers: {
        "Content-Type": "application/json",
      },
      mode: "cors",
    })
      .then((res) => res.json())
      .then((response) => {

        if (response.list[0]) {
          fetchTouristicSite(response.list[0].resource_id, token);
        } else {
          setErrors(true);
          setLoading(false);
        }
      })
      .catch((error) => {
        console.error(error);
        setErrors(true);
        setLoading(false);
      });
  }

  const fetchTouristicSite = (id, token) => {
    const url = API_RESOURCE_ACCESSES_ACTIVITY(id) + `?resource_access_token=${token}`;
    fetch(url, {
      method: "GET",
      cache: "default",
      mode: "cors",
    })
      .then((res) => res.json())
      .then((response) => {
        history.push(`/touristic-sites/${response.touristic_site_id}?resource_access_token=${token}`)
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      });
  }
  return (
    <>
      <PopupModal
        overlay
        withCloseIcon
        isOpen={isOpen}
        onRequestClose={() => {
          setIsOpen(!isOpen);
          setErrors(false);
        }}
      >
        <div
          style=
          {{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: "20px"
          }}
        >
          <HenriTripIcon
            style=
            {{
              maxHeight: "100px",
              color: `${colors.henriBlue}`
            }}
          />
          <div>
            <p
              style=
              {{
                fontSize: "23px",
                fontWeight: "600",
                color: `${colors.henriBlue}`,
                maxWidth: "170px",
                textAlign: "center"
              }}
            >
              {t("visitModal.title")}
            </p>
          </div>
        </div>
        <div
          style=
          {{
            paddingTop: `${errors ? "10px" : "2px"}`
          }}>
          <p
            style=
            {{
              fontWeight: "500",
              fontSize: "23px",
              textAlign: "center",
              color: `${colors.electricTomato}`,
              marginBottom: "5px"
            }}
          >
            {errors ? t("visitModal.error") : null}
          </p>
          {
            loading
              ? <Loader />
              : null
          }
          <p
            style=
            {{
              fontWeight: "500",
              fontSize: "23px",
              textAlign: "center",
              padding: "30px",
              paddingTop: "15px",
              maxWidth: "450px"
            }}
          >
            {t("visitModal.description")}
          </p>
        </div>
        <CodeCells onChange={setShortCodeChars} />
        <div
          style=
          {{
            display: "flex",
            justifyContent: "space-around",
            padding: "22px"

          }}
        >
          <Button
            style=
            {{
              display: "flex",
              gap: "5px",
              alignItems: "center"
            }}
            onClick={handleSubmit}
          >
            {t("visitModal.buttonText")}
            <ArrowRight
              style=
              {{
                width: "20px",
                height: "20px",
              }}
            />
          </Button>
        </div>
      </PopupModal>
    </>

  );
};

const CodeCells = ({
  onChange
}) => (
  <div
    style=
    {{
      height: "60px",
      width: "100%",
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-around",
      alignItems: "center",
      padding: "40px"
    }}
  >
    <ShortCodeInput
      onChange={onChange}
    />
  </div>
);

const CodeCharCell = ({
  value,
  focused
}) => (
  <CharCellContainer
    style=
    {{
      border: `${focused ? 3 : 1}px solid ${focused ? colors.henriBlue : colors.lightGrey90}`
    }}
  >
    <p
      style=
      {{
        fontSize: "23px",
        fontWeight: "bold",
      }}
    >
      {value}
    </p>
  </CharCellContainer>
);

const ShortCodeInput = ({ onChange }) => {
  const [inputFocus, setInputFocus] = useState(false);
  const [inputValue, setInputValue] = useState("");

  const handleTextFocus = () => {
    setInputFocus(true);
    setInputValue("");
  };

  useEffect(() => {
    onChange(inputValue);
  }, [inputValue]);

  const splittedValue = inputValue.split("");

  return (
    <>
      <input
        type="text"
        name="visit_code"
        value={inputValue}
        style=
        {{
          width: 260,
          height: 70,
          position: "absolute",
          opacity: 0,
        }}
        maxLength={6}
        onChange={(event) => {
          setInputValue(event.target.value);
        }}
        onFocus={handleTextFocus}
      />
      <CodeCellsContainer >
        {
          [...Array(6)].map((_, index) => (
            <CodeCharCell
              key={index}
              value={splittedValue[index]}
              focused={inputFocus && splittedValue.length === index}
            />
          ))
        }
      </CodeCellsContainer>
    </>
  );
}

export default CodePopup;