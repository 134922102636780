import {useContext, useEffect, useState} from 'react';
import {useFormik} from 'formik';
import {useHistory} from 'react-router';
import styled, {ThemeContext} from 'styled-components';
import {API_CREATE_TOURISTIC_SITE, API_PATCH_TOURISTIC_SITE,API_LIST_AGENCIES,API_ORIGINAL_BLOB_URL} from '../../../utils/api';
import touristicSiteSchema, {mediaSchema,mainImageSchema} from '../../_schemas/touristic_site_schema';
import categories from '../../../utils/categories';
import Button from '../../../_ui/_v2/components/Button';
import Loader from '../../../_ui/_v2/components/Loader';
import {
  TextInput,
  Select,
  FileInput,
  AutocompleteInput,
  Form,
  FileInputLabel
} from '../../../_ui/_v2/components/FormElements';
import DurationMinutesInput from '../TouristicSites/DurationMinutesInput';
import {mediasTyped} from "../../../utils/media";
import TouristicSiteContext from '../../../_contexts/TouristicSiteContext';
import {ReactComponent as ImageUploadIcon} from '../../../assets/img/logo/image-upload-icon.svg';

const touristicSiteSchemaForTouristicSiteForm = touristicSiteSchema.pick([
  'title_fr',
  'categories',
  'address',
  'latitude',
  'longitude',
  'duration_minutes',
  'medias',
  'ticket_link',
  'agency_id',
]);

const FileInputContainer = styled.div`
  height: 200px;
  display: flex;
  background-color: ${props => props.theme.colors.fileInputEmpty};
  background-image: ${props => props.backgroundImageUrl ? `url('${props.backgroundImageUrl}')` : 'none'};
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  
  ${props => !props.backgroundImageUrl && `
  border: 2px dashed ${props.theme.colors.henriBlue};
  `}
`;

const TouristicSiteForm = ({ onSuccess }) => {
  const history = useHistory();
  const theme = useContext(ThemeContext);
  const [loading, setLoading] = useState(false);
  const { touristicSite, setTouristicSite } = useContext(TouristicSiteContext);
  const [previewUrlImage, setPreviewUrlImage] = useState();
  const [mainImageLayout, setMainImageLayout] = useState();

  const token = localStorage.getItem("Authorization");

  useEffect(() => {
    const mainImage = touristicSite.layout?.find(item => item.type === 'MainImage');
    if (mainImage && JSON.stringify(mainImageLayout) !== JSON.stringify(mainImage)){
      setMainImageLayout(mainImageSchema.cast({
        ...mainImage,
        content: touristicSite.medias.find(m => m.blob_id === mainImage.content.blob_id)
      }));
    }
    else {
      setMainImageLayout(mainImageSchema.cast({}))
    }
  }, [touristicSite]);

  const handleSubmitForm = (values, actions) => {
    const payload = {
      ...touristicSiteSchemaForTouristicSiteForm.cast(values),
      layout: [...(values.layout || [])].filter(item => item.type !== 'MainImage').concat([mainImageLayout]),
    };

    setLoading(true);
    fetch(
      touristicSite.id ? API_PATCH_TOURISTIC_SITE(touristicSite.id) :API_CREATE_TOURISTIC_SITE(),
      {
        method: touristicSite.id ? 'PATCH' : 'POST',
        cache: 'default',
        body: JSON.stringify({...values, ...payload, published: true}),
        headers: {
          "Authorization": `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        mode: 'cors',
      }
    )
      .then(res => res.json())
      .then((response) => {
        setTouristicSite(prev => ({...prev, ...response}));
        if (onSuccess) {
          onSuccess(response);
        } else {
          history.push(`/touristic-sites/${response.id}/edit`);//TODO export in a CONSTANTS file
        }
      })
      .catch(error => {
        console.error(error);
        setLoading(false);
      });
  };
  const {
    handleSubmit,
    handleChange,
    handleBlur,
    values,
    errors,
    touched,
    setFieldValue,
  } = useFormik({
    initialValues: touristicSiteSchemaForTouristicSiteForm.cast(touristicSite),
    validationSchema: touristicSiteSchemaForTouristicSiteForm,
    onSubmit: handleSubmitForm,
  });

  const fileImageUrl = mainImageLayout?.content?.blob_id
    ? API_ORIGINAL_BLOB_URL(mainImageLayout.content.signed_id, mainImageLayout.content.filename)
    : previewUrlImage;

  const mainImageIndex = mainImageLayout?.content?.blob_id ? values.medias.findIndex(item => item.blob_id === mainImageLayout?.content?.blob_id) : 0;
  const currentIndex = mainImageIndex === -1 ? (values.layout?.length || 0 ): mainImageIndex
  
  if ( !mainImageLayout){
    return null
  }

  return (
    <Form onSubmit={handleSubmit}>
      <FileInputContainer
        backgroundImageUrl={fileImageUrl}
      >
        <FileInput
          name={`medias[${currentIndex}].signed_id`}
          accept="image/*"
          activeStorageServiceName="touristic_site_scaleway"
          onChange={(event) => {
            if (event.target.value !== mainImageLayout?.content?.signed_id) {
              setMainImageLayout(mainImageSchema.cast({content: {signed_id: event.target.value }}));
              setFieldValue(`medias[${currentIndex}]`, mediaSchema.cast({ signed_id: event.target.value }));
            } else {
              handleChange(event);
            }
          }}
          onBlur={handleBlur}
          value={values.medias?.[currentIndex]?.signed_id}
          hasError={errors.medias?.[currentIndex]?.signed_id && touched.medias?.[currentIndex]?.signed_id}
          errorMessage={errors.medias?.[currentIndex]?.signed_id}
          onBlobUrlChange={setPreviewUrlImage}
          style={{margin: 'auto'}}
        >
          {
            ({ready, globalProgress}) => (
              <FileInputLabel
                disabled={!ready}
                progress={globalProgress}
                hasError={errors.medias?.[currentIndex]?.signed_id && touched.medias?.[currentIndex]?.signed_id}
                style={{border: "none"}}
              >
                {ready
                  ? (
                    <>
                      <ImageUploadIcon style={{marginRight: '1em', height: 25 }}/>
                      {mainImageLayout?.content?.signed_id ? 'Modifier l\'image' : 'Ajouter une image'}
                    </>
                  ) : (
                    <>
                      <Loader style={{display: 'inline', margin: '0 1em 0 0', height: 25}}/>
                      {'Upload en cours'}
                    </>
                  )
                }
              </FileInputLabel>
            )
          }
        </FileInput>
      </FileInputContainer>
      <AutocompleteInput
        name="agency_id"
        type="text"
        onChange={handleChange}
        onBlur={handleBlur}
        value={values.agency_id || ''}
        hasError={errors.agency_id && touched.agency_id}
        errorMessage={errors.agency_id}
        placeholder={'Agence'}
        fetchParams={(searchValue, signal) => ([
          `${API_LIST_AGENCIES}?name=${searchValue}`,
            {
              method: 'GET',
              headers: {
                "Authorization": `Bearer ${token}`,
                "Content-Type": "application/json",
              },
              mode: 'cors',
              signal: signal,
            
            }
        ])}
        formatResponse={(response) => (
          response.data.map((el) => ({
            label: el.name,
            value: el.id,
            data: el,
          }))
        )}
      />
      <TextInput
        type="text"
        name="title_fr"
        onChange={handleChange}
        onBlur={handleBlur}
        value={values.title_fr}
        hasError={errors.title_fr && touched.title_fr}
        errorMessage={errors.title_fr}
        placeholder={'Nom'}
        label={"Nom de l'activité"}
      />
      <Select
        name="categories[0][id]"
        onChange={handleChange}
        onBlur={handleBlur}
        value={values.categories[0]?.id || ''}
        hasError={errors.categories && touched.categories}
        errorMessage={errors.categories}
        placeholder={"Type d'activité"}
      >
        { 
          Object.keys(categories.name).map((id, index) => (
            <option key={index} value={id}>{categories.name[id]}</option>
          ))
        }
      </Select>
      <AutocompleteInput
        name="address"
        type="text"
        onChange={handleChange}
        onCurrentItemChange={(item) => {
          if (item) {
            setFieldValue('longitude', item?.data?.geometry?.coordinates?.[0] || null);
            setFieldValue('latitude', item?.data?.geometry?.coordinates?.[1] || null);
          }
        }}
        onBlur={handleBlur}
        value={values.address}
        hasError={errors.address && touched.address}
        errorMessage={errors.address}
        placeholder={'Adresse'}
        fetchParams={(searchValue, signal) => ([
          `https://api.mapbox.com/geocoding/v5/mapbox.places/${searchValue}.json?access_token=${process.env.REACT_APP_MAPBOX_ACCESS_TOKEN}&autocomplete=true&types=address&language=fr&limit=10`,
            {
              method: 'GET',
              signal: signal,
            }
        ])}
        formatResponse={(response) => (
          response.features.map((el) => ({
            label: el.place_name,
            value: el.place_name,
            data: el,
          }))
        )}
      />
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <TextInput
          type="number"
          name="latitude"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.latitude}
          hasError={errors.latitude && touched.latitude}
          errorMessage={errors.latitude}
          placeholder={'Latitude'}
        />
        <TextInput
          type="number"
          name="longitude"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.longitude}
          hasError={errors.longitude && touched.longitude}
          errorMessage={errors.longitude}
          placeholder={'Longitude'}
        />
      </div>
      <TextInput
        type="text"
        name="ticket_link"
        onChange={handleChange}
        onBlur={handleBlur}
        value={values.ticket_link}
        hasError={errors.ticket_link && touched.ticket_link}
        errorMessage={errors.ticket_link}
        placeholder={'Lien de réservation'}
      />
      <DurationMinutesInput
        name="duration_minutes"
        onChange={handleChange}
        onBlur={handleBlur}
        value={values.duration_minutes}
        hasError={errors.duration_minutes && touched.duration_minutes}
        errorMessage={errors.duration_minutes}
        hoursPlaceholder={"Heure(s)"}
        minutesPlaceholder={"Minute(s)"}
        label={'Temps estimé de visite'}
      />
      <Button
        rounded
        disabled={loading}
        type="submit"
        style={{
          display: 'block',
          margin: '3em auto 0 auto',
        }}
      >
        {
          loading
            ? <Loader size="1.5em" color={theme.colors.white}/>
            : 'Enregistrer'
        }
      </Button>
    </Form>
  );
};

export default TouristicSiteForm;
