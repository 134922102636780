import {useMemo} from 'react';
import LabelWrapper from '../../../_ui/_v2/components/FormElements/LabelWrapper';
import InputErrorWrapper from '../../../_ui/_v2/components/FormElements/InputErrorWrapper';
import {Select} from '../../../_ui/_v2/components/FormElements';

const DurationMinutesInput = (
  {
    onChange,
    onBlur,
    value,
    name,
    hasError,
    errorMessage,
    label,
    hoursPlaceholder,
    minutesPlaceholder,
    minDuration = 0,
    maxDuration = 1440,
    stepDuration = 5,
  }
) => {
  const parsedValue = parseInt(value);
  const integerValue = parsedValue || 0;
  const [hoursValue, minutesValue] = [integerValue, integerValue % 60];
  const cursorOption = hoursValue - minutesValue;

  const keysArray = useMemo(() => (
    Object
      .keys([...Array(maxDuration - minDuration)])
      .map((_, index) => index + minDuration)
  ), [minDuration, maxDuration]);

  const handleChangeHours = (event) => {
    const newValue = minutesValue + parseInt(event.target.value);
    event.target.value = newValue.toString();
    onChange(event);
  };

  return (
    <LabelWrapper
      name={name}
      label={label}
    >
      <InputErrorWrapper
        hasError={hasError}
        errorMessage={errorMessage}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <Select
            onChange={handleChangeHours}
            onBlur={onBlur}
            value={parsedValue ? hoursValue.toString() : value}
            name={name}
            placeholder={hoursPlaceholder}
            hasError={hasError}
            style={{
              flexGrow: 1,
              maxWidth: 'calc(50% - .5em)',
            }}
          >
            {
              keysArray.map((minute, index) => (
                <option
                  key={index}
                  hidden={minute % 60}
                  value={minute}
                >
                  {Math.floor(minute / 60)}
                </option>
              ))
            }
          </Select>
          <Select
            onChange={onChange}
            onBlur={onBlur}
            value={value}
            name={name}
            placeholder={minutesPlaceholder}
            hasError={hasError}
            style={{
              flexGrow: 1,
              maxWidth: 'calc(50% - .5em)',
            }}
          >
            {
              keysArray.map((minute, index) => (
                <option
                  key={index}
                  hidden={
                    minute % stepDuration ||
                    minute >= cursorOption + 60 ||
                    minute < cursorOption
                  }
                  value={minute}
                >
                  {minute % 60}
                </option>
              ))
            }
          </Select>
        </div>
      </InputErrorWrapper>
    </LabelWrapper>
  );
};

export default DurationMinutesInput;