import {useContext, useEffect, useState} from "react";
import styled from "styled-components";
import NavBarContext from "../../../_contexts/NavBarProvider";

const ParallaxHeaderStyled = styled.div`
  min-width: 100%;
  max-width: 100%;
  height: calc(100vh + 2rem);
  position: relative;
`;

const ParallaxImage = styled.img`
  min-width: 100%;
  min-height: calc(100vh + 2rem + 40px);
  position: absolute;
  inset: 0;
  transition: transform .5s ease-out;
  object-position: 50% 50%;
  object-fit: cover;
  transform: translateY(${props => props.index * props.ratio * -20}px) scale(${props => 1.05 - props.ratio / 20});
`;

// const ParallaxImageContainer = styled.div`
//   min-width: 100%;
//   height: calc(100vh + 2rem + 40px);
//   position: absolute;
//   top: 0;
//   background-attachment: fixed;
//   background-position: center;
//   background-repeat: no-repeat;
//   background-size: cover;
//   background-image: url("${props => props.url}");
//   transition: transform .5s ease-out;
//   transform: translateY(${props => props.index * props.ratio * -20 * (1.05 - props.ratio / 20)}px) scale(${props => 1.05 - props.ratio / 20});
//   -webkit-backface-visibility: hidden;
//   backface-visibility: hidden;
// `;

const Parallax = ({parallaxes, fallback, ...rest}) => {
  const [ratio, setRatio] = useState(0);
  const navBarContext = useContext(NavBarContext);

  const parallaxOrdered = parallaxes.sort((a, b) => a.order - b.order);

  const calcPercentageParallax = event => {
    // const ratio = event.target.scrollTop / event.target.scrollHeight;
    const ratio = event.target.scrollTop / 200;
    setRatio(Math.min(ratio, 1));
  };

  useEffect(() => {
    navBarContext.subscribeOnPageScroll(calcPercentageParallax);
    return () => {
      navBarContext.unsubscribeOnPageScroll();
    };
  }, []);

  return (
    <ParallaxHeaderStyled {...rest}>
      {
        parallaxOrdered.length <= 0
          ? (
            <ParallaxImage src={fallback} index={0} ratio={ratio}/>
          )
          : (
            <>
              <ParallaxImage src={parallaxOrdered[0].file_url} index={0} ratio={ratio}/>
              <ParallaxImage src={parallaxOrdered[1].file_url} index={1} ratio={ratio}/>
              <ParallaxImage src={parallaxOrdered[2].file_url} index={2} ratio={ratio}/>
            </>
          )
      }
      {/*<ParallaxImageContainer url={parallaxOrdered[0].file_url} index={0} ratio={ratio}/>*/}
      {/*<ParallaxImageContainer url={parallaxOrdered[1].file_url} index={1} ratio={ratio}/>*/}
      {/*<ParallaxImageContainer url={parallaxOrdered[2].file_url} index={2} ratio={ratio}/>*/}
    </ParallaxHeaderStyled>
  );
};

export default Parallax;