import {setSelectedActivities} from 'actions/touristicSites';
import useRequest from 'hooks/use-request';
import React, {useContext, useEffect, useState} from "react";
import {useDispatch, useSelector} from 'react-redux';
import {API_GET_TOURISTIC_SITE, API_GET_TOURISTIC_SITE_DETAILS} from 'utils/api';
import {ImageURL} from 'utils/texts';
import {useHistory} from "react-router-dom";
import DayActivityDetails from "../../../components/_v2/experiences/DayActivityDetails";
import theme from "../../../_ui/_v2/styles/theme";
import GlobalStyle from "../../../_ui/_v2/styles/global_style";
import styled, {ThemeProvider} from "styled-components";
import NavBarContext from "../../../_contexts/NavBarProvider";
import Arrow from "../../../_ui/_v2/components/Arrow";
import PageContainer from '../../../_ui/_v2/components/PageContainer';
import {Helmet} from 'react-helmet';
import LanguageSelector from "../../../_ui/_v2/components/LanguageSelector";
import useTouristicSiteTranslation from "../../../hooks/touristic_sites/useTouristicSiteTranslation";
import TouristicSiteContext, {TouristicSiteProvider} from '../../../_contexts/TouristicSiteContext';
import {useLocation, useParams} from 'react-router';

const TitleContainer = styled.div`
  margin: auto auto auto 2rem;
  display: inline-block;
  vertical-align: middle;
`;

const Container = styled.div`
  padding: 2rem;
  display: block;
  text-align: left;
  align-self: center;
`;

const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${props => props.theme.colors.white};
  height: 100vh;
`;

const MediaContainer = ({HeaderToShow, headerProps, onRequestClose, open, TitleComponent, editable = false}) => {
  const [fullDetails, setFullDetails] = useState(true);
  const { touristicSite } = useContext(TouristicSiteContext);

  return(
    <ModalContainer>
      <Container {...headerProps} open={open}>
        <Arrow
          direction="left"
          onClick={onRequestClose}
        />
        <TitleContainer>
          {HeaderToShow}
        </TitleContainer>
        <LanguageSelector/>
      </Container>
      <div
        style={{
          overflow: 'auto',
          height: '100%',
        }}
      >
        <DayActivityDetails
          editable={editable}
          sites={[touristicSite]}
          fullDetails={fullDetails}
          setFullDetails={setFullDetails}
        />
      </div>

    </ModalContainer>
  );
};
const NewTouristicSite = ({editable = false}) => {
  // const [handleRequest, status] = useRequest();
  const { touristicSite } = useContext(TouristicSiteContext);
  // const [address, setAddress] = useState();
  const history = useHistory();
  const location = useLocation();
  const resourceAccessToken = new URLSearchParams(location.search).get('resource_access_token');
  const navBarContext = useContext(NavBarContext);
  const { title } = useTouristicSiteTranslation(touristicSite);

  useEffect(() => {
    localStorage.setItem('resourceAccessToken', resourceAccessToken || '');

    window.scrollTo(0, 0)
    navBarContext.setNavBar('v2');
    navBarContext.setFooterVisible(false);
    navBarContext.setBottomBarAppearOnScroll(true);
    return () => {
      navBarContext.restoreNavBar();
      navBarContext.restoreFooterVisible();
      navBarContext.setBottomBarAppearOnScroll(false);
    };
  }, []);

  // useEffect(() => {
  //   if(!/bot|crawl|prerender|spider/i.test(window.navigator.userAgent)){
  //     if (touristicSite?.longitude) {
  //       handleRequest("get",
  //         `https://api.mapbox.com/geocoding/v5/mapbox.places/${touristicSite.longitude},${touristicSite.latitude}.json?access_token=${process.env.REACT_APP_MAPBOX_ACCESS_TOKEN}&language=fr&types=address`,
  //         {}, {}, (response, status) => {
  //           if (status === "Done") setAddress(response.features[0]?.place_name_fr)
  //         }, true)
  //     }
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [touristicSite?.longitude, touristicSite?.latitude]);
  //
  // if (!touristicSite?.category) {
  //   return null;
  // }

  return (
    <PageContainer>
      <Helmet>
        <title>{title} - Expérience | Henri Trip</title>
      </Helmet>
      <MediaContainer
        editable={editable}
        onRequestClose={() => {
          if (history.length <= 1) {
            history.push('/touristic-sites');
          } else {
            if (editable) {
              history.goBack();
            } else {
              history.push('/');
            }
          }
        }}
        HeaderToShow={<h1>{title}</h1>}
        headerProps={{
          style: {
            width: '100%',
            alignSelf: 'center',
            display: 'flex',
            alignItems: 'center'
          }
        }}
      />
    </PageContainer>
  );
};

const NewTouristicSiteWithProviders = (props) => {
  const history = useHistory();
  const config = useSelector(state => state.data);
  const dispatch = useDispatch();
  const [handleRequest, status] = useRequest();
  const [ts, setTS] = useState();
  const { touristic_site_id } = useParams();

  useEffect(() => {
    if (ts?.id === parseInt(touristic_site_id)) {
      return ;
    }
    handleRequest(
      "get",
      API_GET_TOURISTIC_SITE_DETAILS(touristic_site_id, localStorage.getItem('resourceAccessToken')),
      {},
      config,
      (data, status) => {
        if (status === "Done") {
          const parsedActivities = data.activities?.map(el => ({
            ...el,
            feedbacks: {
              note: el.avg_note || 5,
              comments: [],
              len: el.notes_counter
            },
          }))
          setTS(state => ({
            ...state,
            tickets: data.tickets || [],
            activities: parsedActivities,
            events: data.events.map(el => ({
              ...el,
              images: [{
                picture: ImageURL + "/touristic_site/event/" + el.touristic_site_event_image?.image
              }]
            }))
          }))
          dispatch(setSelectedActivities(parsedActivities));
        } else if (status === "Error") {
          console.error(data);
          history.push('/error/404');
        }
      });
    handleRequest("get", API_GET_TOURISTIC_SITE(touristic_site_id), {}, config, (data, status) => {
      if (status === "Done") {
        let tourScanner = data.city ? new URL(`https://tourscanner.com/fr/s/${data.city.split(' ')[0].toLowerCase()}/?q=${data.title}&a=vnnmb`).href : null
        setTS(state => ({
          ...state,
          ...data,
          category: data.categories[0] || {
            color: "#EF7E26",
            id: 53,
            title: "Activité",
            url: "https://general-henri-1-paris.s3.fr-par.scw.cloud/categories/activites_sportives.jpg"
          },
          henri_certified: !!data.agency_id,
          feedbacks: {
            note: data.avg_note,
            comments: data.touristic_site_reviews,
            len: data.notes_counter
          },
          tickets_link: data?.tickets_link || (!!data.agency_id ? undefined : tourScanner)
        }))
      } else if (status === "Error") {
        console.error(data);
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [touristic_site_id]);

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <TouristicSiteProvider initialTouristicSite={ts}>
        <NewTouristicSite {...props}/>
      </TouristicSiteProvider>
    </ThemeProvider>
  );
};

export default NewTouristicSiteWithProviders;
