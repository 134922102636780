import { Button } from 'components/form/button';
import Title from 'components/header/title/title';
import { RoadTripCard } from 'components/roadTrips/roadTripCard';
import React, {useEffect, useState} from "react";
import { Pagination } from 'react-bootstrap';

import useDocumentTitle from "../../../../hooks/use-document-title";
import {Loader} from "semantic-ui-react";
import { useTranslation } from "react-i18next";

const ITEMS_PER_PAGE = 6

const OrdersRoadTripsPage = ({ loading, roadtrips, onChangeStep }) => {
  const {t} = useTranslation(["henriTrip"])
  useDocumentTitle(t("roadTripPage.orders.title"));
  const [modal] = useState({
    create: false,
    delete: false,
    id: null
  });
  const [viewElements, setViewElements] = useState(6);
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [modal.create])
  return (
    <div className="min-vh-75">
      <Title title={t("roadTripPage.orders.title")} description={t("roadTripPage.orders.subtitle")}/>
      <div className="row">
        {
          loading ?
            <Loader active className="mt-4" />
            :
            (
              roadtrips?.length !== 0 ?
                <>
                  {
                    roadtrips?.slice(0, viewElements)?.map(el =>
                      <div key={el.id} className="col-12 col-md-4 py-2 position-relative">
                        <RoadTripCard roadtrip={el} />
                      </div>
                    )
                  }
                  <div className="w-100 d-flex justify-content-center pt-2 ">
                    <Button rounded className="px-4 shadow-lg" title={t("general.showMore")}
                            onClick={() => setViewElements(state => state + 6)} outline />
                  </div>
                </>
                :
                <p className="text-muted mt-2">{t("roadTripPage.orders.noOrdersRoadTrip")}</p>
            )
        }
      </div>
      <Pagination list={roadtrips} itemsperpage={ITEMS_PER_PAGE} onChange={onChangeStep} />
    </div>
  );
};

export default OrdersRoadTripsPage;