import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

const touristic_sites = {
    touristic_sites: [],
    download_activities: [],
    activities: []
};

export default persistReducer({storage, key: "touristic-site-data", whitelist: ["download_activities"]}, (state = touristic_sites, action) => {
    switch (action.type) {
        case 'ADD_TOURISTIC_SITES':
            return {
                touristic_sites: action.payload
            };
        case 'REMOVE_TOURISTIC_SITES':
            return {
                touristic_sites: []
            }
        case 'ADD_DOWNLOAD_TOURISTIC_SITE':
            if (state.download_activities.find(el => el.id === action.payload.id)) {
                return state;
            }
            return {
                ...state,
                download_activities: [
                    ...state.download_activities,
                    action.payload
                ]
            }
        case 'REMOVE_DOWNLOAD_TOURISTIC_SITE':
            return {
                ...state,
                download_activities: state.download_activities.filter(el => el.id === action.payload)
            }
        case 'SET_SELECTED_ACTIVITIES':
            return {
                ...state,
                activities: action.payload
            }
        case 'REMOVE_SELECTED_ACTIVITIES':
            return {
                ...state,
                activities: []
            }
        default:
            return state;
    }
})