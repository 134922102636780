import React from 'react';
import useDocumentTitle from "../../hooks/use-document-title";
import AmbassadorSectionsHeader from './AmbassadorSectionsPage/AmbassadorSectionsHeader';
import AmbassadorSectionsNavbar from "../AmbassadorPage/AmbassadorSectionsPage/AmbassadorSectionsNavbar";
import AmbassadorSectionsIntro from "../AmbassadorPage/AmbassadorSectionsPage/AmbassadorSectionsIntro";
import AmbassadorSectionsMiddle from "../AmbassadorPage/AmbassadorSectionsPage/AmbassadorSectionsMiddle";
import AmbassadorSectionsText from "../AmbassadorPage/AmbassadorSectionsPage/AmbassadorSectionsText";
import AmbassadorSectionsGrade from "../AmbassadorPage/AmbassadorSectionsPage/AmbassadorSectionsGrade";
import AmbassadorSectionsJoin from "../AmbassadorPage/AmbassadorSectionsPage/AmbassadorSectionsJoin";

const AmbassadorPage = (props) => {
    useDocumentTitle("Henri Trip : Devenez ambassadeur !")

    return (
        <div style={{overflow: "hidden"}}>
            <AmbassadorSectionsNavbar/>
            <AmbassadorSectionsHeader/>
            <div className="container">
            <AmbassadorSectionsIntro/>
            <AmbassadorSectionsMiddle/>
            <AmbassadorSectionsText/>
            <AmbassadorSectionsGrade/>
            <AmbassadorSectionsJoin/>
            </div>
        </div>
    )
};

export default AmbassadorPage;