import styled from 'styled-components';
import {Helmet} from 'react-helmet';

const PageContainerStyled = styled.div`
  width: 100%;
  background-color: ${props => props.theme.colors.white};
  margin-right: auto;
  margin-left: auto;
`;

const ContentWrapper = styled.div`
  width: 100%;
  max-width: 1200px;
  margin-right: auto;
  margin-left: auto;
`;

const PageContainer = ({children, ...rest}) => (
  <PageContainerStyled {...rest}>
    <Helmet>
      <title>Expérience | Henri Trip</title>
    </Helmet>
    <ContentWrapper>
      {children}
    </ContentWrapper>
  </PageContainerStyled>
);

export default PageContainer;