import { Button } from 'components/form/button';
import InputFormik from 'components/form/input/inputFormik';
import { Form } from 'formik';
import React from 'react';
import AuthPageStructure from './AuthPageStructure';
import {useTranslation} from "react-i18next";

const ForgotPasswordPage = (props) => {
  const {t} = useTranslation(["henriTrip"])
  return (
    <AuthPageStructure>
      <Form onSubmit={props.handleSubmit}>
        {
          props.error &&
          <div className="p-3 bg-danger mb-3">
            {props.error}
          </div>
        }
        {
          props.success &&
          <p>{t("authPage.resetPasswordEmail1")} {props.email}. {t("authPage.checkSpam")}</p>
        }
        {
          !props.success &&
            <>
              <InputFormik placeholder={t("authPage.emailPlaceholder")} name="email" label={t("authPage.emailPlaceholder")} {...props} />
              <Button extended type="submit" color="henri" title={t("authPage.sendEmail")} />
              <p className="text-muted pt-2">{t("authPage.resetPasswordEmail2")}</p>
            </>
        }
      </Form>
    </AuthPageStructure>
  );
};

export default ForgotPasswordPage;