import Title from 'components/header/title/title';
import React, {useEffect, useState} from 'react';
import useDocumentTitle from "../../hooks/use-document-title";
import ModalForm from "../../components/formModal/ModalForm";
import Login from "../../containers/Auth/Login";
import {useTranslation} from "react-i18next";
import Divider from "../../components/common/Divider";
import {Form} from "semantic-ui-react";
import {Field, Formik} from "formik";
import InitialValues from "./Formfield/InitialValues"
import ValidationSchema from "./Formfield/ValidationSchema"
import UserForm from "./Formfield/UserForm";
import Select from 'react-select';
import StripeContainer from "./StripeContainer";
import {API_REGISTER} from "../../utils/api";
import {useSelector} from "react-redux";
import useRequest from "../../hooks/use-request";
import {Checkbox, FormControlLabel} from '@material-ui/core';

const {
  formField: {
    username,
    email,
    firstname,
    lastname,
    gender,
    birthdate,
    password,
    password_confirmation,
    statement
  },
} = UserForm;

const CheckoutPage = ({cart}) => {
  const [open, setOpen] = useState(false);
  const {t} = useTranslation(["henriTrip"]);
  const [handleRequest] = useRequest();
  const config = useSelector(state => state.data);
  const [token, setToken] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  },[])
  const genders = [
    {
      label: t("general.male"),
      value: 'Male',
    },
    {
      label: t("general.female"),
      value: 'Female',
    },
    {
      label: t("general.other"),
      value: 'Other',
    },
  ];
  const handleSignup = (values, formikBag) => {
    setLoading(true);
    handleRequest("post", API_REGISTER, values, {}, (data, status) => {
      if (status === "Done") {
        setLoading(false);
        setToken(
          {
            headers: {
              // "content-type": "multipart/form-data",
              'Authorization': "Token " + data.token,
            }
          })
      } else {
        const msg = {
          code: data?.status,
          msg: data?.text,
        };
        window.scrollTo(0, 0);
        setLoading(false);
        formikBag.setStatus(msg);
      }
    })
  }

  useDocumentTitle(`Checkout • Henri Trip`)
    return (
      <>
        <ModalForm
          isOpen={open}
          onClose={() => setOpen(false)}
        >
          <Login modal={true} checkout={true} handleSubmit={() => setOpen(false)} handleClose={() => setOpen(false)}/>
        </ModalForm>
        <div className="container min-vh-100 py-5">
          <Title title={t("checkOutPage.title")} />
          <section className="bg-white shadow-sm rounded overflow-hidden my-2 d-flex flex-row" >
            <img src={cart.roadtrip.picture} alt={cart.roadtrip.title} height="100" width="100" style={{objectFit: "cover", objectPosition: "center"}}/>
            <p className="m-0 col-6 col-md-6 font-weight-bold d-flex justify-content-center flex-column text-truncate">{cart.roadtrip.title}<small className="text-truncate">{cart.roadtrip.description}</small></p>
            <p className="m-0 ml-auto col-2 col-md-2 text-dark font-weight-bold d-flex align-items-center justify-content-end">{!cart.roadtrip_premium.new_price ? t("checkOutPage.free") : `${cart.roadtrip_premium.new_price.toFixed(2)} €`}</p>
          </section>
          {/*<div className="row py-2">*/}
          {/*  <p className="col-10 m-0 font-weight-bold">TOTAL</p>*/}
          {/*  <p className="col-2 m-0 font-weight-bold text-right">{!cart.roadtrip_premium.new_price ? "Gratuit" : `${cart.roadtrip_premium.new_price.toFixed(2)} €`}</p>*/}
          {/*</div>*/}
          {
            !(token?.headers?.Authorization || config?.headers?.Authorization) &&
            <>
              <h4 className="font-weight-bold">{t("general.personnalInfo")}</h4>
              <section className="bg-white shadow-sm rounded overflow-hidden my-2 p-4">
                <div className="d-flex justify-content-center">
                  <button className="btn font-weight-bold btn-primary rounded-lg px-5 align-items-center" onClick={() => setOpen(true)}>
                    {t("login.loginButton")}
                  </button>
                </div>
                <Form.Field className="my-2">
                  <Divider>{t("login.or")}</Divider>
                </Form.Field>
                <div className="px-2">
                  <Formik
                    initialValues={InitialValues}
                    validationSchema={ValidationSchema[0]}
                    validateOnMount={false}
                    validateOnChange={false}
                    validateOnBlur={false}
                    onSubmit={handleSignup}
                  >
                    {({values,isSubmitting, submitForm, ...props}) => {
                      return (
                        <div className="w-100">
                          <Form
                            className="form fv-plugins-bootstrap fv-plugins-framework"
                            id="kt_form"
                          >
                            <div className="row justify-content-center">
                              <div className="w-100">
                                {props.status && (
                                  <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
                                    <div className="text-danger font-weight-bold">
                                      Une erreur de type {props.status.code} est
                                      survenue...
                                      <p>Message: {props.status.msg}</p>
                                    </div>
                                  </div>
                                )}
                                {!props.isValid && props.submitCount > 0 && (
                                  <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
                                    <div className="alert-text font-weight-bold">
                                      Vérifiez que toutes les données entrées sont
                                      justes...
                                    </div>
                                  </div>
                                )}
                                <InputInline {...props} label={t("register.username")} placeholder={t("register.username")} {...username} />
                                <InputInline {...props} label={t("general.password")} placeholder={"•••••••••"} {...password} />
                                <InputInline {...props} label={t("general.confirmPassword")} placeholder={"•••••••••"} {...password_confirmation} />
                                <InputInline {...props} label="Email" placeholder="Hello@henri.fr" {...email} />
                                <InputInline {...props} label={t("general.firstName")} placeholder={"Henri"} {...firstname} />
                                <InputInline {...props} label={t("general.name")} placeholder={"De Toulouse-Lautrec"} {...lastname} />
                                <MySelectInline
                                  options={genders}
                                  {...props}
                                  label={t("general.gender")}
                                  placeholder={t("general.gender")}
                                  {...gender}
                                />
                                <InputInline {...props} label={t("general.birthdate")} {...birthdate} />
                                <MyCheckbox {...props} label={
                                  <label>
                                    {t("register.accept")} <a target="_blank" className="font-weight-bold" href={"https://support.henritrip.fr/conditions-generales-dutilisation-du-site-internet-henri-trip-et-notifications/"} rel="noreferrer">{t("login.conditionOfUse.secondPart")}</a> {t("login.conditionOfUse.thirdPart")} <a target="_blank" className="font-weight-bold" href={"https://support.henritrip.fr/politique-de-confidentialite/"} rel="noreferrer">{t("login.conditionOfUse.fourthPart")}</a>.
                                  </label>
                                } {...statement} />
                              </div>
                            </div>
                            <div className="d-flex justify-content-center">
                              <button disabled={loading} className="btn font-weight-bold btn-primary rounded-lg px-5 align-items-center" type="submit" onClick={submitForm}>
                                {
                                  loading ?
                                    <div className="spinner-border text-white" role="status">
                                      <span className="sr-only">Loading...</span>
                                    </div>
                                    :
                                    t("general.register")
                                }
                              </button>
                            </div>
                          </Form>
                        </div>
                      );
                    }}
                  </Formik>
                </div>
              </section>
            </>
          }
          <h4 className="font-weight-bold">{t("checkOutPage.payment")}</h4>
          <section className="bg-white shadow-sm rounded overflow-hidden my-2 p-4">
            {
              (token?.headers?.Authorization || config?.headers?.Authorization) &&
              <StripeContainer config={token?.headers?.Authorization || config?.headers?.Authorization} cart={cart}/>
            }
          </section>
        </div>
      </>

    );
};

export default CheckoutPage;

export const InputInline = ({label, placeholder, type, ...props}) => {
  return (
    <div className="form-group row fv-plugins-icon-container">
      <label className="col-xl-3 col-lg-3 col-form-label">
        {label}
        {props.required ? ' *' : ''}
      </label>
      <div className="col-lg-9 col-xl-9">
        <Field
          onChange={props.handleChange}
          onBlur={props.handleBlur}
          name={props.name}
          className="form-control form-control-solid form-control-lg"
          placeholder={placeholder}
          type={type}
        />
        <div className="fv-plugins-message-container"></div>
        {props.touched[props.name] && props.errors[props.name] ? (
          <div className="fv-plugins-message-container">
            <div className="fv-help-block text-danger">{props.errors[props.name]}</div>
          </div>
        ) : null}
      </div>
    </div>
  );
};

const styles = {
  control: (provided) => ({
    ...provided,
    borderColor: '#EBEDF3',
    borderRadius: 0.3 + 'rem',
    backgroundColor: '#EBEDF3',
    padding: 0.3 + 'rem',
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    color: '#707070',
  }),
};

const MySelectInline = (props) => {
  const classNames = [
    'pt-1',
    'form-group row fv-plugins-icon-container',
    props.classNames,
  ]
    .filter((el) => el)
    .join(' ');

  const handleChange = (v) => {
    if (props.isMulti) {
      props.setFieldValue(
        props.name,
        v.map((el) => el.value)
      );
      return;
    }
    props.setFieldValue(props.name, v.value);
  };

  return (
    <div className={classNames}>
      {props.label && (
        <label className="col-xl-3 col-lg-3 col-form-label">
          {' '}
          {props.label}
          {props.required ? ' *' : ''}
        </label>
      )}
      <Select
        {...props}
        onChange={handleChange}
        onBlur={() => props.setFieldTouched(props.name, true)}
        placeholder={props.placeholder}
        className="col-lg-9 col-xl-9"
        styles={styles}
      />
      {props.touched[props.name] && props.errors[props.name] ? (
        <div className="fv-plugins-message-container">
          <div className="fv-help-block text-danger">{props.errors[props.name]}</div>
        </div>
      ) : null}
      {props.error && <p className="text-danger">{props.invalidMessage}</p>}
      {props.helper && <small>{props.helper}</small>}
    </div>
  );
};




const MyCheckbox = (props) => {
  const classNames = ['text-primary', props.className].filter((el) => el).join(' ');

  if (props.arrayHelpers) {
    return (
      <>
        <FormControlLabel
          control={
            <Checkbox
              className="text-primary"
              value={props.el.id}
              key={props.el.title}
              name={toString(props.el.id)}
              checked={props.values[props.name]?.includes(props.el.id)}
              onChange={() => {
                if (!props.values[props.name]?.includes(props.el.id)) {
                  props.arrayHelpers.push(props.el.id);
                } else {
                  var id = props.values[props.name]?.indexOf(props.el.id);
                  props.arrayHelpers.remove(id);
                }
              }}
            ></Checkbox>
          }
          label={props.el.title}
        ></FormControlLabel>
        {props.touched[props.name] && props.errors[props.name] ? (
          <div className="fv-plugins-message-container">
            <div className="fv-help-block text-danger">{props.errors[props.name]}</div>
          </div>
        ) : null}
      </>
    );
  }

  if (props.onChange) {
    return (
      <FormControlLabel
        control={
          <Field
            component={({field, form, ...props}) => {
              return <Checkbox {...field} {...props} />;
            }}
            className={classNames}
            name={props.name}
            value={props.value}
            checked={props.checked}
            type="checkbox"
            onChange={props.onChange}
          />
        }
        label={props.label}
      />
    );
  }

  return (
    <>
      <FormControlLabel
        control={
          <Field
            component={({field, form, ...props}) => {
              return <Checkbox {...field} {...props} />;
            }}
            className={classNames}
            name={props.name}
            value={props.value}
            type="checkbox"
            onChange={props.handleChange}
            onBlur={props.handleBlur}
          />
        }
        label={props.label}
      />
      {props.touched[props.name] && props.errors[props.name] ? (
        <div className="fv-plugins-message-container">
          <div className="fv-help-block text-danger">{props.errors[props.name]}</div>
        </div>
      ) : null}
    </>
  );
};