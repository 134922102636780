import styled from "styled-components";

const TriangleIcon = styled.div`
  --cross-color: ${(props) => props.theme.colors.white};

  width: 0px;
  height: 0px;
  background: none;
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
  border-bottom: 15px solid var(--cross-color);
  border-top: 15px solid transparent;
  vertical-align: bottom;
  position: absolute;
  bottom: 0px;
  left: 3px;
`;

export default TriangleIcon;
