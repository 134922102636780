import * as React from "react"

function Icon4(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 88.56 106.9"
      style={{color: "#A42422"}}
      {...props}
    >
      <defs>
        <style>{".prefix__cls-2{fill:#fff}"}</style>
      </defs>
      <g id="prefix__Calque_2" data-name="Calque 2">
        <g id="prefix__Calque_1-2" data-name="Calque 1">
          <path
            fill="currentColor"
            d="M44.28 0a44.27 44.27 0 00-31.16 75.73l31.16 31.17 31.16-31.17A44.27 44.27 0 0044.28 0"
          />
          <path
            className="prefix__cls-2"
            d="M50.44 68.18h-4.76V47.36a14.13 14.13 0 0012.46-16.79l-2.56-13H33l-2.56 13a14.13 14.13 0 0012.44 16.79v20.82h-4.76a6.79 6.79 0 00-5.5 2.8h23.32a6.81 6.81 0 00-5.5-2.8zM36.28 41.31a11.29 11.29 0 01-3.1-10.19l2.11-10.73h18l2.11 10.73a11.32 11.32 0 01-19.1 10.19z"
          />
          <path
            className="prefix__cls-2"
            d="M50.3 39.32a8.51 8.51 0 002.33-7.66h-16.7a8.51 8.51 0 002.33 7.66A8.67 8.67 0 0041 41.16a8.5 8.5 0 006.52 0 8.67 8.67 0 002.78-1.84z"
          />
        </g>
      </g>
    </svg>
  )
}

export default Icon4
