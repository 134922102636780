import React, { useEffect, useState } from "react";
import { Form } from "semantic-ui-react";
import { HeaderButton } from "../../header/buttons";
import EmailIcon from '@mui/icons-material/Email';

import './RegisterForms.scss';
import FacebookLoginButton from "../../Oauth/FacebookLoginButton";
import Divider from "../../common/Divider";
import { useTranslation } from "react-i18next";
import GoogleLoginButton from "components/Oauth/GoogleLoginButton";

const BaseRegisterForm = ({currentStep, nextStep, handleFacebookSubmit, handleGoogleSubmit}) => {
  const {t} = useTranslation(["henriTrip"])
  const [login, setLogin] = useState(null);

  useEffect(() => {
    if(login){
      handleFacebookSubmit(login)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [login])

  if(currentStep !== 0){
    return null
  }

  return(
    <>
      <Form.Field>
        <FacebookLoginButton
          onLoginSuccess={setLogin}
          title={t("register.buttonRegisterFacebook")}
        />
      </Form.Field>
      {/* <Form.Field>
        <GoogleLoginButton
          handleGoogleSubmit={handleGoogleSubmit}
          title={t("register.buttonRegisterGoogle")}
        />
      </Form.Field> */}
      <Form.Field>
        <div>
          {t("login.conditionOfUse.firstPart")} <a target="_blank" className="font-weight-bold" href={"https://support.henritrip.fr/conditions-generales-dutilisation-du-site-internet-henri-trip-et-notifications/"} rel="noreferrer">{t("login.conditionOfUse.secondPart")}</a> {t("login.conditionOfUse.thirdPart")} <a target="_blank" className="font-weight-bold" href={"https://support.henritrip.fr/politique-de-confidentialite/"} rel="noreferrer">{t("login.conditionOfUse.thirdPart")} {t("login.conditionOfUse.fourthPart")}</a>.
        </div>
      </Form.Field>
      <Form.Field>
        <Divider>{t("login.or")}</Divider>
      </Form.Field>
      <Form.Field>
        <HeaderButton
          className="button-register"
          text={t("register.buttonRegisterEmail")}
          materialIcon={EmailIcon}
          rounded
          onClick={nextStep}
        />
      </Form.Field>
    </>
  )
}

export default BaseRegisterForm;
