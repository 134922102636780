import React from 'react';
import SVG from 'react-inlinesvg';
import "./header-buttons.scss";
import { Button } from "../../form/button";
import { SvgIcon } from "@material-ui/core";
import { isMobile } from 'react-device-detect';

const HeaderButtons = ({text, icon, onClick, className, color, materialIcon, showText}) => {
    return (
      <Button color={color ?? "primary"} className={`header-button ${className}`} rounded onClick={onClick}>
        {
          icon &&
          <SVG src={icon} width="20" height="20" style={{marginRight: 5}}/>
        }
        {
          materialIcon &&
          <SvgIcon component={materialIcon} width="20" height="20" style={{marginRight: 5}}/>
        }
        { text &&
          (showText ? (isMobile ? <></> : <>{text}</>) : <>{text}</>)
        }
      </Button>
    );
};

export default HeaderButtons;