import React from "react";
import styled from "styled-components";
import Modal from "../Modal";
import Arrow from "../Arrow";
import LanguageSelector from '../LanguageSelector';

const TitleContainer = styled.div`
  margin: auto auto auto 2rem;
  display: inline-block;
  vertical-align: middle;
`;

const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${props => props.theme.colors.white};
  height: 100vh;
`;

const ModalChildrenContainer = styled.div `
  overflow-y: scroll;
  height: 100%;
`;

const FullScreenNavigationModal = ({TitleComponent, children, isOpen, onRequestClose, headerProps = {}, ...rest}) => (
  <Modal
    fullScreen
    isOpen={isOpen}
    onRequestClose={onRequestClose}
    {...rest}
  >
    <ModalContainer>
      <div
        className="align-left"
        {...headerProps}
        style={{display: 'flex', padding: '2rem', ...headerProps.style}}
      >
        <Arrow
          direction="left"
          onClick={onRequestClose}
        />
        <TitleContainer>
          {TitleComponent}
        </TitleContainer>
        <LanguageSelector/>
      </div>
      <ModalChildrenContainer>
        {children}
      </ModalChildrenContainer>
    </ModalContainer>
  </Modal>
);

export default FullScreenNavigationModal;