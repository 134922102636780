import React from 'react';

const usePagination = (list, numberItemsPage, itemsNumber = 0, firstPage = 0) => {
  const [currentPage, setCurrentPage] = React.useState(firstPage);
  const numberPages = React.useMemo(() => (list?.length ?? itemsNumber) / numberItemsPage, [list, itemsNumber, numberItemsPage]);
  const pages = React.useMemo(() => {
    let list = [];

    for (let i = 0; i < numberPages; i++) {
      list.push((i + 1));
    }

    return list;
  }, [numberPages])

  return [currentPage, pages, setCurrentPage];
};

export default usePagination;
