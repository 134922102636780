import {useTranslation} from "react-i18next";

const LegalSection = (props) => {
  const {t,} = useTranslation(["experiences"]);
  return (
    <div {...props}>
      <p
        className="small"
        style={{
          fontSize: 10,
        }}
      >
        {t("legal")}
      </p>
    </div>
  );
};

export default LegalSection;