import React, {useEffect, useState} from 'react';
import {useSelector} from "react-redux";
import useRequest from "../../../hooks/use-request";
import {API_GET_RESERVATIONS} from "../../../utils/api";
import AppointmentsPage from "../../../pages/UserPage/AppointmentsPage/AppointmentsPage";

const Appointments = (props) => {
  const [state, setState] = useState(null);
  const [handleRequest, status] = useRequest();
  const config = useSelector(state => state.data);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])

  useEffect(() => {
    handleRequest("get", API_GET_RESERVATIONS, {}, config, (data, status) => {
      if (status === "Done") {
        setState(data);
      } else if (status === "Error") {
        console.error(data);
      }
    })
    // eslint-disable-next-line
  }, [])

  return state ?
    <AppointmentsPage
      loading={status !== "Done"}
      reservations={state}
    />
    : null;
}

export default Appointments;