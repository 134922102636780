import React, { Component } from "react";
import DatePicker, { registerLocale } from "react-datepicker"
import fr from 'date-fns/locale/fr'

import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css';
import "react-datepicker/dist/react-datepicker.css";
import AutocompletePlace from "./autocompletesearch";
import { Clear, Search as SearchIcon } from "@material-ui/icons";
import { IconButton } from "@material-ui/core";
import { Button } from "react-bootstrap";
import { PHONE_WIDTH, TABLET_WIDTH } from "../../utils/utils";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import axios from "axios";
import { withTranslation } from "react-i18next";
import user from "reducers/user";

class Search extends Component {

  constructor(props) {
    super(props);

    registerLocale("fr", fr)
    this.handleDatesChanged = this.handleDatesChanged.bind(this)
    this.getDate = this.getDate.bind(this)
    this.onSelectAddress = this.onSelectAddress.bind(this)
    this.onClearAddress = this.onClearAddress.bind(this)
    this.handleDatesClear = this.handleDatesClear.bind(this)
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this)
    this.onSelectTS = this.onSelectTS.bind(this)

    this.state = {

      startDate: null,
      endDate: null,
      address: null,
      width: 0,
      focus: false,
      locationIsLoading: false
    }

    this.addressRef = React.createRef();
    this.datepickerRef = React.createRef();
  }
  componentDidMount() {
    this.setState({ isLoggedIn: user.loggingIn() });


  }

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  updateWindowDimensions() {
    this.setState({ width: window.innerWidth });
  }

  onSelectAddress(address) {
    this.setState({
      address: address
    }, () => {
      if (this.state.width <= PHONE_WIDTH || this.props.isNavBar) {

        this.handleSubmit()
      }
    })
  }

  onSelectTS(id) {
    this.props.history.push(`/touristic-site/${id}`)
  }

  onClearAddress() {
    this.setState({ address: null, locationIsLoading: false })
  }

  handleDatesChanged(d) {
    const [start, end] = d;
    this.setState({ startDate: start, endDate: end })
  }

  handleDatesClear() {
    this.setState({ startDate: null, endDate: null })
  }

  getDate = () => {
    let dateStr = this.state.startDate ? this.state.startDate.toLocaleDateString() : "";
    if (dateStr && this.state.endDate) {
      dateStr = `${dateStr} - ${this.state.endDate?.toLocaleDateString()}`;
    }
    return dateStr;
  };

  handleSubmit() {
    const url = new URLSearchParams()
    url.append("place", this.state.address.text_fr)
    url.append("longitude", this.state.address.center[0])
    url.append("latitude", this.state.address.center[1])

    if (this.state.startDate && this.state.endDate) {
      url.append("start_date", this.state.startDate.toLocaleDateString())
      url.append("end_date", this.state.endDate.toLocaleDateString())
    }

    this.props.history.push(`/search-view?${url.toString()}`)
  }

  dateOnClick = () => {
    if (!this.datepickerRef.current.state.open) {
      this.datepickerRef.current.setOpen(true);
    }

  }

  addressOnClick = () => {
    this.addressRef.current.click();
  }

  getLocation = () => {
    if (navigator.geolocation) {
      this.setState({ locationIsLoading: true })
      navigator.geolocation.getCurrentPosition(this.showLocation)
    } else {
      console.error(`pas de pos`)
    }
  }

  showLocation = (pos) => {
    const loc = `${pos.coords.longitude},${pos.coords.latitude}`
    axios.get(`https://api.mapbox.com/geocoding/v5/mapbox.places/${loc}.json?access_token=${process.env.REACT_APP_MAPBOX_ACCESS_TOKEN}&language=fr`)
      .then(response => {
        this.onSelectAddress(response.data.features[0])
      })
  }


  render() {

    //console.log("coucou", this.props?.user)
    return (
      <div className="overlap-group">

        {this.state.width > PHONE_WIDTH && !this.props.isNavBar &&
          <>
            <div className="dates" onClick={this.dateOnClick}>
              <div className="dates__title font-weight-bold">Date</div>
              <DatePicker
                value={this.getDate()}
                className="dates__input font-weight-medium"
                placeholderText={this.props.t("searchComponent.date.placeholder")}
                dateFormat="dd/MM/yyyy"
                locale="fr"
                monthsShown={2}
                selectsRange
                minDate={new Date()}
                startDate={this.state.startDate}
                endDate={this.state.endDate}
                onChange={(update) => {
                  this.handleDatesChanged(update)
                }}
                isClearable={true}
                shouldCloseOnSelect={false}
                ref={this.datepickerRef}
              />

              {
                this.state.startDate &&
                <div className="dates__clear-icon">
                  <IconButton onClick={this.handleDatesClear}>
                    <Clear />
                  </IconButton>
                </div>
              }
            </div>

            <Button
              variant="primary"
              className="search-button shadow-none font-weight-bold"
              disabled={this.state.address === null}
              onClick={this.handleSubmit}
            >
              <SearchIcon
                fontSize={this.state.width > TABLET_WIDTH ? "medium" : "large"}
                className="search-button__icon"
              />

              <div className="search-button__text">{this.props.t("searchComponent.button")}</div>
            </Button>
          </>
        }

        <div className="adresse-gauche" onClick={this.addressOnClick}>
          <AutocompletePlace
            className="o-allez-vous"
            placeholder={this.props.t("searchComponent.address.placeholder")}
            onSelect={this.onSelectAddress}
            onClear={this.onClearAddress}
            onSelectTS={this.onSelectTS}
            newAddress={this.state.address}
            ref={this.addressRef}
            onFocusInp={() => this.setState({ focus: true })}
            onBlurInp={() => this.setState({ focus: true })}
          />
        </div>

        {
          (this.state.focus && !this.props.isNavBar && !this.state.address) &&
          <div className="locate-user bg-white shadow-lg font-weight-bold" onClick={this.getLocation}>
            {this.state.locationIsLoading ? this.props.t("searchComponent.loading") : this.props.t("searchComponent.nearbyDestination")}
          </div>
        }
      </div>
    );
  }
}

Search.propTypes = {
  isNavBar: PropTypes.bool.isRequired
}

export default withTranslation("henriTrip", { withRef: true })(withRouter(Search))
