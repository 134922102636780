import React from 'react';
import './HomeCard.scss';

const HomeCard = (props) => {
  return (
    <div key={props.key} className={props.margin ? props.margin : ""}>
      <img src={props.image} className="image-style" alt="" />
      <div className="mt-2 mt-md-3">
        <h1>{props.title}</h1>
          { (props.topDescription) &&
            <p className={props.showMoreHomePage ? "mt-2 mb-md-2 show-text-style" : "mt-2 mb-md-2 top-text-style"}>{props.topDescription}</p>
          }
        <p className={ props.showMoreHomePage ? "mt-2 mt-md-1 show-text-style" : "mt-2 mt-md-1 top-text-style"  }>{props.description}</p>
      </div>
    </div>
  );
};

export default HomeCard;