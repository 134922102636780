import React, { useEffect, useState } from "react";
import {Button} from "components/form/button";
import {Modal, Form} from 'semantic-ui-react'
import { Link } from "react-router-dom";
import HCaptcha from "@hcaptcha/react-hcaptcha";
import FacebookLoginButton from "../Oauth/FacebookLoginButton";
import Divider from "../common/Divider";
import { useTranslation } from "react-i18next";
import GoogleLoginButton from "components/Oauth/GoogleLoginButton";

const LoginModal = ({handleSubmit, handleFacebookSubmit, handleGoogleSubmit, handleClose, error}) => {
  const {t} = useTranslation(["henriTrip"])
  const [state, setState] = useState({
    login: '',
    password: '',
    connexionTry: 0,
    captcha: false
  })
  const captcha = React.createRef()
  const submit = () => {
    if(state.connexionTry < 3 || state.captcha){
      handleSubmit({
        login: state.login,
        password: state.password
      })
      if(state.connexionTry > 2) captcha.current.resetCaptcha();
      setState({...state, connexionTry: state.connexionTry + 1, captcha: false})
    }
  }

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      submit()
    }
  }

  const handleChange = (e, {name, value}) => {
    setState({...state, [name]: value})
  }

  // Facebook Login
  const [login, setLogin] = useState(null);
  useEffect(() => {
    if(login){
      handleFacebookSubmit(login)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [login])

  return(
    <>
      <Modal.Content>
        <Form onSubmit={handleSubmit} onKeyDown={handleKeyDown}>
          {
            error &&
            <div className="p-3 bg-danger text-white mb-1">
              {error}
            </div>
          }
          <Form.Field>
            <FacebookLoginButton
              onLoginSuccess={setLogin}
              title={t("login.buttonLoginFacebook")}
            />
          </Form.Field>
          {/* <Form.Field>
        <GoogleLoginButton
          handleGoogleSubmit={handleGoogleSubmit}
          title={t("login.buttonLoginGoogle")}
        />
      </Form.Field> */}
          <Form.Field>
            <div>
              {t("login.conditionOfUse.firstPart")} <a target="_blank" className="font-weight-bold" href={"https://support.henritrip.fr/conditions-generales-dutilisation-du-site-internet-henri-trip-et-notifications/"} rel="noreferrer">{t("login.conditionOfUse.secondPart")}</a> {t("login.conditionOfUse.thirdPart")} <a target="_blank" className="font-weight-bold" href={"https://support.henritrip.fr/politique-de-confidentialite/"} rel="noreferrer">{t("login.conditionOfUse.fourthPart")}</a>.
            </div>
          </Form.Field>
          <Form.Field>
            <Divider>{t("login.or")}</Divider>
          </Form.Field>
          <Form.Input
            id="login"
            name="login"
            label={t("login.inputPseudo")}
            placeholder="henri@example.fr ou @nomdutilisateur"
            type={"email" || "name"}
            onChange={handleChange}
          />
          <Form.Input
            id="password"
            name="password"
            label={t("general.password")}
            placeholder="········"
            type="password"
            onChange={handleChange}
          />
          {
            state.connexionTry > 2 &&
            <HCaptcha
              ref={captcha}
              sitekey={process.env.REACT_APP_HCAPTCHA_API_KEY}
              onVerify={() => setState({...state, captcha: true})}
            />
          }
          <Form.Field>
            <Link className="font-weight-bold" to="/forgot-password" onClick={handleClose}>{t("login.forgetPassword")}</Link>
          </Form.Field>
          <p className="text-center py-0 py-md-2">{t("login.noAccount")} <Link onClick={handleClose} to={"/register"} className="font-weight-bolder">{t("login.register")}</Link></p>
          <Form.Field>
            <Button
              extended
              color={"henri"}
              onClick={submit}
              disabled={state.connexionTry > 2 && !state.captcha}
              title={t("login.loginButton")}
            />
          </Form.Field>
        </Form>

      </Modal.Content>
    </>
  )
}

export default LoginModal
