import Title from 'components/header/title/title';
import { Pagination } from 'components/pagination';
import React, {useEffect, useMemo, useState} from "react";
import { useSelector } from 'react-redux';
import {Link, useLocation} from 'react-router-dom';
import { API_CREATE_FEEDBACK_ACTIVITY, API_CREATE_FEEDBACK_ROAD_TRIP, API_CREATE_FEEDBACK_TOURISTIC_SITE } from 'utils/api';
import AddFeedback from './add-feedback';
import FeedbackElement from './feedback-element';
import { Button } from "../form/button";

import "./feedback.scss";
import { useTranslation } from "react-i18next";
import { FilterButton } from './filter-buttons';

const ITEMS_PER_PAGE = 3
const ORIGIN_GOOGLE= "Google"
const ORIGIN_HENRITRIP= "Henritrip"
const ORIGIN_ALL= "All"
const Feedbacks = ({ feedbacks, type, id, className, askUserConnectModal}) => {
  const {t} = useTranslation(["henriTrip"])

  const typeInfo = {
    touristic_site: {
      title: t("feedbacks.touristicSite"),
      api_route: API_CREATE_FEEDBACK_TOURISTIC_SITE
    },
    road_trip: {
      title: "road trip",
      api_route: API_CREATE_FEEDBACK_ROAD_TRIP
    },
    activity: {
      title: "activité",
      api_route: API_CREATE_FEEDBACK_ACTIVITY
    }
  }

  const [filteredFeedbacks, setFeedBackList] = useState(feedbacks);
  const [openCreate, setOpenCreate] = useState(false);
  const [filterStatus, setFilterStatus] = useState( ORIGIN_ALL);
  const [page, setPage] = useState(0);
  // eslint-disable-next-line
  const typedData = useMemo(() => typeInfo[type], [type])
  const {user} = useSelector(state => state.user);
  const location = useLocation()

  useEffect(() => {
    setFeedBackList(feedbacks);
  },[feedbacks])

  const handleButton = () => {
    if (!user?.id) {
      askUserConnectModal()
      return
    }
    setOpenCreate(true);
  }
const sortFeedback = (f1,f2)=>{
  // on affiche les avis Henritrip avant
    if (f1?.origin===ORIGIN_GOOGLE && f2?.origin===ORIGIN_HENRITRIP) return 1;
    if (f1?.origin===ORIGIN_HENRITRIP && f2?.origin===ORIGIN_GOOGLE) return -1;
    //on affiche les avis les plus récents
    return f1?.updated_at>f2?.updated_at ? -1 :1;
}

  const filterGoogleButton = () => {
    setFilterStatus(ORIGIN_GOOGLE);
    setFeedBackList(feedbacks?.sort(sortFeedback).filter(f=> f.origin===ORIGIN_GOOGLE))
    setPage(0)
  }
  const filterHenriTripButton = () => { 
    setFilterStatus(ORIGIN_HENRITRIP);
    setFeedBackList(feedbacks?.sort(sortFeedback).filter(f=> f.origin===ORIGIN_HENRITRIP))
    setPage(0)
  }
  const filterNoneButton = () => {
    setFilterStatus(ORIGIN_ALL);
    setFeedBackList(feedbacks.sort(sortFeedback));
    setPage(0)
  }



  const handleChangePage = (page) => {
    setPage(page)
  }

  return (
    <section className={"feedback-section " + className}>
      <Title title={t("feedbacks.title")} description={`${t("feedbacks.description")} ${typedData.title}`}/>
      {
        type !== 'road_trip' &&
        <>
          <FilterButton logo={"https://general-henri-1-paris.s3.fr-par.scw.cloud/google.png"} text={t("feedbacks.filteredGoogle")} color="information-block" className={"m-1 px-1"+(filterStatus===ORIGIN_GOOGLE ?" border-5 border-dark":"")} onClick={filterGoogleButton}/>
          <FilterButton logo={"https://support.henritrip.fr/wp-content/uploads/elementor/thumbs/cropped-logo-favicon-p5ng39bdmpo2gotrmquovkqilvx6ohzmgpovqdnsww.png"} text={ t("feedbacks.filteredHenriTrip")} color="information-block" className={"m-1 px-1"+(filterStatus===ORIGIN_HENRITRIP ?" border-5 border-dark":"")} onClick={filterHenriTripButton}/>
        </>
        }
      <FilterButton text={t("feedbacks.filteredNone")} color="information-block" className={"m-1 px-1"+(filterStatus===ORIGIN_ALL ?" border-5 border-dark":"")} onClick={filterNoneButton}/>
      <Button rounded title={t("feedbacks.addButtonReview")} color="information-block" className="m-1 px-4 float-right" onClick={handleButton}/>
      {
        filteredFeedbacks?.length !== 0 ?
        filteredFeedbacks?.slice(page * ITEMS_PER_PAGE, (page * ITEMS_PER_PAGE) + ITEMS_PER_PAGE)?.map(el => (
            <FeedbackElement key={el.id} {...el} />
          ))
          :
          <p className="text-muted text-center pt-5 pb-3">
            {t("feedbacks.noReview")}
            {
              user?.id ?
                <span className="text-primary font-weight-bold span-click" onClick={handleButton}>{t("feedbacks.callToActionButton")}</span>
                :
                <Link to={`/login?redirectURL=${location.pathname}`} className="text-primary font-weight-bold">{t("feedbacks.connectButton")}</Link>
            }
          </p>
      }
      <Pagination list={filteredFeedbacks} itemsPerPage={ITEMS_PER_PAGE} onChange={handleChangePage} />
      <AddFeedback show={openCreate} onHide={() => setOpenCreate(false)} apiRoute={typedData.api_route(id)} />
    </section>
  );
};

export default Feedbacks;
