import React, {useEffect, useState} from 'react';
import {Link} from "react-router-dom";
import {PHONE_WIDTH} from "../../../utils/utils";
import Button from "../../../components/form/button/button";
import { useTranslation } from "react-i18next";


const PartnerSectionsNavbar = ({demo}) => {
  const {t} = useTranslation(["henriTrip"])
  const [widthState, setWidthState] = useState(window.innerWidth)

  useEffect(() => {
    function handleResize() {
      setWidthState(window.innerWidth)
    }
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize)
  }, []);

  return (
    <>
      { widthState > PHONE_WIDTH ?
        <nav className="navbar navbar-expand-lg navbar-dark bg-transparent position-absolute">
          <div className="container">
            <Link className="navbar-brand" to="/">
              <img className="navbar-logo" width="150" src="/assets/logo/Logo_henri-side.svg" alt="logo" />
            </Link>
          </div>
        </nav>
        :
        <nav className="navbar navbar-expand-lg bg-white">
          <div className="container">
            <div className="col ml-1">
              <Link className="navbar-brand" to="/">
                <img width="30" src="/assets/logo/Asset1.svg" alt="logoSVG" />
              </Link>
            </div>
            {
              demo ?
                <Button rounded title="Essayer gratuitement" onClick={() => window.open("https://calendly.com/henri-trip/30min", '_blank')}/>
                :
                <Button rounded title={t("partner.becomePartner")} onClick={() => window.document.getElementById('searchBar').scrollIntoView({block: "center"})}/>
            }
          </div>
        </nav>
      }
    </>
  )
};

export default PartnerSectionsNavbar;