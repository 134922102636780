import React from 'react';
import L from 'leaflet';
import ReactDOMServer from 'react-dom/server';
import Icon7 from '../ReactIcons/icon7';
import { ICON_ANCHOR, ICON_SIZE } from '.';

const mapIcon7 = L.divIcon({
    html: ReactDOMServer.renderToString(<Icon7 />),
    iconSize: ICON_SIZE,
    iconAnchor: ICON_ANCHOR
});

export default mapIcon7;