import './404.scss';
import { Button } from 'components/form/button';
import React from 'react';
import { useHistory } from "react-router-dom";
import useDocumentTitle from "../../hooks/use-document-title";

const Error404 = () => {
  useDocumentTitle("Page non trouvée - Erreur 404");
  const history = useHistory();
    return (
           <div className="error404__main-container">
                 <div className="error404__text-container">
                    <p className="error404__text">Nous ne parvenons pas à trouver la page que vous recherchez.</p>
                    <h1 className="error404__number">404</h1>
                    <div className="error404__button-container">
                        <p className="error404__text">Page non trouvée</p>
                        <Button className="error404__button" title="Accueil" onClick={() => history.push('/')}/>
                    </div>
                </div>
            </div>
    );
};

export default Error404;