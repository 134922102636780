import Title from 'components/header/title/title';
import React from 'react';
import './AccountPage.scss';
import {ReactComponent as Shield} from 'assets/img/logo/shield.svg';
import {ReactComponent as Identification} from 'assets/img/logo/identification.svg';
import {ReactComponent as CreditCard} from 'assets/img/logo/credit_card.svg';
import {ReactComponent as Megaphone} from 'assets/img/logo/megaphone.svg';
import {ReactComponent as Oeil} from 'assets/img/logo/oeil.svg';
import {ReactComponent as Henri} from 'assets/img/logo/Asset1.svg';
import AccountCard from 'containers/User/Account/AccountCard';
import useDocumentTitle from 'hooks/use-document-title';
import { Button } from "../../../components/form/button";
import { useHistory } from "react-router";
import {useLocation} from "react-router-dom";
import { useTranslation } from "react-i18next";

const AccountPage = ({user}) => {
  const {t} = useTranslation(["henriTrip"])
  useDocumentTitle(t("account.accountPage.documentTitle"))
  const history = useHistory();
  const location = useLocation();

  return (
    
    <div className="w-100 bg-light">
      <Title title={`${user.firstname} ${user.lastname}`} description={t("account.accountPage.subtitle")} />
        <div className="container-fluid mt-3 text-justify">
          <div className="row">
            <AccountCard link="/user/info" title={t("general.personnalInfo")} description={t("account.accountPage.personalInfoDesc")}>
              <Identification width="100px" height="50px" className="pr-2"/>
            </AccountCard>
            
            <AccountCard link="/user/security" title={t("account.accountPage.loginSecurity")} description={t("account.accountPage.loginSecurityDesc")}>
              <Shield width="100px" height="50px" className="pr-2"/>
            </AccountCard>
          
            <AccountCard link="/user/transactions" title={t("account.accountPage.payment")} description={t("account.accountPage.paymentDesc")}>
              <CreditCard width="100px" height="50px" className="pr-2"/>
            </AccountCard>
          </div>
          <div className="row mt-lg-4 mt-0">
              <AccountCard  link="/user/notification" title={t("general.notifications")} description={t("account.accountPage.notificationDesc")}>
                  <Megaphone width="100px" height="50px" className="pr-2"/>
              </AccountCard>
              <AccountCard link="/user/privacy" title={t("general.privacy&sharing")} description={t("account.accountPage.privacyDesc")}>
                  <Oeil width="100px" height="50px" className="pr-2"/>
              </AccountCard>
            {
              user.ambassador === true ?
              <AccountCard link="/user/ambassador" title={t("ambassador.dashboard.ambassador")} description={t("account.accountPage.ambassadorDesc")}>
                <Henri width="100px" height="50px" className="pr-2"/>
              </AccountCard>
              :
              <AccountCard link="/ambassador" title={t("general.ambassadorHenriTrip")} description={t("account.accountPage.ambassadorDesc")}>
                <Henri width="100px" height="50px" className="pr-2"/>
              </AccountCard>
            }
          </div>
        <div className="row mt-4">
            <Button title={t("general.buttonLogout")} rounded color="information-block" className="m-1" onClick={()=> history.push(`/logout?redirectURL=${location.pathname}`)} />
        </div>
      </div>
    </div>
  );
};

export default AccountPage;