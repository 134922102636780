import React, {useState} from 'react';
import {useFormik} from 'formik';
import {carouselSchema, imageSchema} from '../../../_schemas/media_layout_builder_schemas';
import SliderContainer from '../../../../_ui/_v2/components/SliderContainer';
import ButtonFormValidation from '../../avpmBuilder/ButtonFormValidation';
import CardImageEditable from '../_commons/CardImageEditable';

const CarouselBuilder = ({carousel = {}, onLayoutItemChange, editable = false, onSuccess, onCancel, activeStorageServiceName}) => {
  const [loading, setLoading] = useState(false);
  const handleSubmitForm = (values) => {
    setLoading(true);

    const medias = values.items?.filter(item => !item.content?.blob_id).map(item => item.content);

    onLayoutItemChange(
      values,
      medias,
      {
        onSuccess: (response) => {
          onSuccess?.(response);
          setLoading(false);
        },
        onError: (error) => {
          console.error(error);
          setLoading(false);
        },
      }
    );
  };

  const {
    handleSubmit,
    setFieldValue,
    values,
  } = useFormik({
    initialValues: carouselSchema.cast(carousel),
    validationSchema: carouselSchema,
    onSubmit: handleSubmitForm,
  });

  return (
    <form
      onSubmit={handleSubmit}
      style={{width: '100%'}}
    >
      <SliderContainer
        withArrows
        centered
        style={{height: '20em', width: '100%'}}
      >
        {
          values.items.map((item, index) => (
            <CardImageEditable
              isLast = {index !== (carousel?.items?.length || 0) - 1}
              isFirst = {index !== 0}
              key={index}
              name={`items[${index}].content.signed_id`}
              onChange={(event) => {
                setFieldValue(
                  `items[${index}]`,
                  imageSchema.cast({content: {signed_id: event.target.value}})
                );
              }}
              value={item.content?.signed_id || ''}
              media={item.content}
              onDelete={() => {

                setFieldValue(
                  'items',
                  [...values.items.filter((_, i) => index !== i)]
                );
              }}
              onMoveRight={() => {
                let temp = values.items[index]
                values.items[index] = values.items[index + 1]
                values.items[index + 1] = temp
                setFieldValue(
                  'items',
                  [...values.items]
                  
                );
              }}
              onMoveLeft={() => {
                let temp = values.items[index]
                values.items[index] = values.items[index - 1]
                values.items[index - 1] = temp
                setFieldValue(
                  'items',
                  [...values.items]
                  
                );
              }}
              editable={editable}
              activeStorageServiceName={activeStorageServiceName}
            />
          )).concat(
            editable
              ? [
                <CardImageEditable
                  key={values.items.length}
                  name={`items[${values.items.length}].content.signed_id`}
                  onChange={(event) => {
                    if (event.target.value !== '') {
                      setFieldValue(
                        `items[${values.items.length}]`,
                        imageSchema.cast({content: {signed_id: event.target.value}})
                      );
                    }
                  }}
                  value={''}
                  editable={true}
                  activeStorageServiceName={activeStorageServiceName}
                />
              ]
              : []
          )
        }
      </SliderContainer>
      {
        editable && (
          <ButtonFormValidation
            loading={loading}
            onCancel={onCancel}
          />
        )
      }
    </form>
  );
};

export default CarouselBuilder;