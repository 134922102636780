import styled from 'styled-components';
import {useEffect, useRef, useState} from "react";

const VerticalTabbedNavigationStyled = styled.div`
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: auto 4fr;
  column-gap: 1rem;
  box-sizing: border-box;
`;

const VerticalTabbedNavigationTitles = styled.div`
  text-align: center;
  cursor: pointer;
`;

const VerticalTabbedNavigationTitle = styled.div`
  width: 100%;
  padding: 5px 12px;
  
  ${props => props.active && `
    font-weight: 800;
  `}

  ${props => props.withSeparator && `
    &::after, &:last-child::before {
      display: block;
      width: 1px;
      content: '';
      margin: auto auto auto auto;
      visibility: hidden;
      opacity: 0;
      height: 0;
      transition: height 0.5s, visibility 0.5s, opacity 0.5s ease-in-out;
      background-color: ${props.theme.colors.black};
    }
  
    ${props.active && `
      &::after {
        height: 8rem;
        margin: 12px auto auto auto;
        visibility: visible;
        opacity: 1;
        transition: height 0.5s, visibility 0.5s, opacity 0.5s ease-in-out;
      }
    
      &:last-child::after {
        display: none;
      }
      
      ${props.titlesCount > 1 && `
        &:last-child::before {
          height: 8rem;
          margin: auto auto 12px auto;
          visibility: visible;
          opacity: 1;
          transition: height 0.5s, visibility 0.5s, opacity 0.5s ease-in-out;
        }
      `}
    `}
  `}
`;

const VerticaTabbedNavigationContent = styled.div`

`;

const VerticalTabbedNavigation = ({activeTabIndex, onActiveTabIndexChange, items, withSeparator = false}) => {
  const [activeTab, setActiveTab] = useState(activeTabIndex || 0);
  const titlesRef = useRef();

  useEffect(() => {
    onActiveTabIndexChange?.(activeTab);
  }, [activeTab]);

  const titlesCount = items.length;
  return (
    <VerticalTabbedNavigationStyled>

      <VerticalTabbedNavigationTitles ref={titlesRef}>
        {
          items.map((item, index) => (
            <VerticalTabbedNavigationTitle
              key={index}
              withSeparator={withSeparator}
              titlesCount={titlesCount}
              active={index === activeTab}
              onClick={() => {
                if (index !== activeTab) {
                  setActiveTab(index);
                }
              }}
            >
              { item.TitleComponent }
            </VerticalTabbedNavigationTitle>
          ))
        }
      </VerticalTabbedNavigationTitles>
      <VerticaTabbedNavigationContent>
        { items[activeTab]?.ContentComponent }
      </VerticaTabbedNavigationContent>
    </VerticalTabbedNavigationStyled>
  );
};

export default VerticalTabbedNavigation;