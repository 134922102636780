import React from 'react';
import CreateRoadTrip from '../CreateRoadTrip/CreateRoadTrip';

const EditRoadTrip = ({loading, roadtrip, onCancel, handleModifyRT}) => {

  if (!roadtrip)
    return null;
  return (
    <CreateRoadTrip loading={loading} onCancel={onCancel} roadTrip={roadtrip} handleModifyRT={handleModifyRT} />
  );
};

export default EditRoadTrip;