import { Button } from 'components/form/button';
import Title from 'components/header/title/title';
import { RoadTripCard } from 'components/roadTrips/roadTripCard';
import React, {useEffect, useState} from "react";
import { Pagination } from 'react-bootstrap';
import { useHistory, useRouteMatch } from "react-router-dom";

import DeleteRoadTrip from 'containers/User/Roadtrips/DeleteRoadTrip/DeleteRoadTrip';
import useDocumentTitle from "../../../../hooks/use-document-title";
import {Loader} from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import { ReactComponent as Trash } from "../../../../assets/img/logo/delete.svg";
import { API_DELETE_FAVORITE_ROADTRIP } from "../../../../utils/api";
import { deleteUserFavoriteRoadTrips } from "../../../../actions/road-trips";
import useRequest from "../../../../hooks/use-request";
import { useDispatch, useSelector } from "react-redux";

const ITEMS_PER_PAGE = 6

const FavoriteRoadTripsPage = ({ loading, roadtrips, onChangeStep }) => {
  const {t} = useTranslation(["henriTrip"])
  useDocumentTitle(t("general.myFavorite"));
  const match = useRouteMatch();
  const [handleRequest, status] = useRequest();
  const config = useSelector(state => state.data);
  const history = useHistory();
  const dispatch = useDispatch()
  const [modal, setModal] = useState({
    create: false,
    delete: false,
    id: null
  });
  const [viewElements, setViewElements] = useState(6);
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [modal.create])


  const handleDeleteFavorite = (id) =>{
    handleRequest("delete", API_DELETE_FAVORITE_ROADTRIP(id), {}, config, (data, status) => {
      if (status === "Done") {
        dispatch(deleteUserFavoriteRoadTrips(parseInt(id)))
        history.push('/user/roadtrips/favorite');
      } else if (status === "Error") {
        console.error(data);
      }
    })
  }
  return (
      <div className="min-vh-75">
        <Title title={t("general.myFavorite")} description={t("general.listOfFavorite")}/>
        <div className="row">
          {
            loading ?
              <Loader active className="mt-4" />
              :
              (
                roadtrips?.length !== 0 ?
                  <>
                    {
                      roadtrips?.slice(0, viewElements)?.map(el =>
                        <div key={el.id} className="col-12 col-md-4 py-2 position-relative">
                          <div className="shadow-lg" style={{
                            position: "absolute",
                            top: 20,
                            left: 20,
                            zIndex: 9
                          }}>
                            <Button color="danger" title={<Trash width="18" />} onClick={() => handleDeleteFavorite(el.id)} />
                          </div>
                          <RoadTripCard roadtrip={el} />
                        </div>
                      )
                    }
                    <div className="w-100 d-flex justify-content-center pt-2 ">
                      <Button rounded className="px-4 shadow-lg" title={t("general.showMore")}
                              onClick={() => setViewElements(state => state + 6)} outline />
                    </div>
                  </>
                  :
                  <p className="text-muted mt-2">{t("myRoadTrip.noRoadTrip")}</p>
              )
          }
        </div>
        <Pagination list={roadtrips} itemsperpage={ITEMS_PER_PAGE} onChange={onChangeStep} />

        <DeleteRoadTrip show={modal.delete} onHide={() => setModal(state => ({...state, delete: false, id: null}))} id={match.params.id || modal.id } />
      </div>
  );
};

export default FavoriteRoadTripsPage;