import SignUpForm from './SignUpForm';
import * as Yup from "yup";
import {REQUIRED_MESSAGE} from "../../../../../utils/texts";

const {
  username,
  firstname,
  lastname,
  email,
  password,
  password_confirmation
} = SignUpForm;

export default Yup.object().shape(
  {
    [username.name]: Yup.string().matches(/^([A-Za-z0-9_]){4,15}$/,
      'Le format du nom d\'utilisateur n\'est pas le bon, 4 à 15 caractères, seuls les chiffres, les lettres et le tiret du bas sont autorisés. Exemple : henritrip_01')
      .required(REQUIRED_MESSAGE),
    [firstname.name]: Yup.string().required(REQUIRED_MESSAGE),
    [lastname.name]: Yup.string().required(REQUIRED_MESSAGE),
    [email.name]: Yup.string().email().required(REQUIRED_MESSAGE),
    [password.name]: Yup.string().matches(
      /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,70}$/,
      'Le mot de passe doit contenir au moins 8 caractères, Incluant des lettres en majuscule, minuscule et au moins un chiffre.'
    ).required(REQUIRED_MESSAGE),
    [password_confirmation.name]: Yup.string()
      .oneOf([Yup.ref('password'), null], 'Ces mots de passe ne correspondent pas!')
      .required(REQUIRED_MESSAGE),
  }
);