import React, { useState } from "react";
import { Button } from "../../form/button";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

const UpdateContainer = ({ titre, description, children, formId, sousTitre, buttonDisabled, handleSubmit }) => {
  const {t} = useTranslation(["henriTrip"])
  const [open, setOpen] = useState(false);

  /*
  const handleClickSaveButton = () => {
    handleSubmit();
    if(saveValid){
      setOpen(!open);
    }
  }
*/
  const onSubmit = () =>{
    let res = handleSubmit()
    if (res){
      setOpen(false)
    }
    else{
      setOpen(true)
    }
  }
  const handleClickModifyButton = () => {
    setOpen(!open);
  }

  return (
    <>
      <div className="row col-12 mt-3">
        <div className="col-9">
          <h3>{titre}</h3>
        </div>
        <div className="col-3">
          <strong>
            <button
              type="button"
              onClick={handleClickModifyButton}
              className="btn btn-link"
            >
              {open ? t("general.buttonCancel") : t("general.buttonModify") }
            </button>
          </strong>
        </div>
      </div>

      {open ? (
        <div>
          <div className="col-12 ">{description}</div>
          {children}
          <div className="mt-2 col-12">
            <Button
              title={t("general.buttonSave")}
              id={formId}
              disabled={buttonDisabled}
              className="col-3"
              onClick={onSubmit}
            />
          </div>
        </div>
      ) : (
        <div className="col-9">
          <h6>{sousTitre}</h6>
        </div>
      )}
    </>
  );
};

UpdateContainer.propTypes = {
  titre: PropTypes.string.isRequired,
  description: PropTypes.string,
  children: PropTypes.element.isRequired,
  formId: PropTypes.string,
  sousTitre: PropTypes.string,
  buttonDisabled: PropTypes.bool,
  handleSubmit: PropTypes.func.isRequired
}

UpdateContainer.defaultProps = {
  description: "",
  formId: "",
  sousTitre: "",
  buttonDisabled: false
}

export default UpdateContainer;
