import React from 'react';

const FooterPageElementLink = (props) => {
    return (
      <a href={props.link} target="_blank" rel="noreferrer">
        <div className="m-0 text-white w-100 h-100 my-1 px-2 d-flex">{props.text}</div>
      </a>
    );
};

export default FooterPageElementLink;