import AudioPlayer from "react-h5-audio-player";
import {API_ORIGINAL_BLOB_URL} from "../../../../utils/api";
import styled from "styled-components";
import breakpoint from "styled-components-breakpoint";
import { ReactComponent as PlayIcon } from 'assets/img/logo/AudioCustom/play_icon.svg';
import { ReactComponent as PauseIcon } from 'assets/img/logo/AudioCustom/pause_icon.svg';
import { ReactComponent as NextIcon } from 'assets/img/logo/AudioCustom/next_icon.svg';
import { ReactComponent as PreviousIcon } from 'assets/img/logo/AudioCustom/previous_icon.svg';
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";

const AudioContainer = styled.div `
  height: max-content;
  width: 100%;
  padding: 2em;

  .rhap_volume-controls {
    display: none;
  }

  .rhap_controls-section {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .audio_control{
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    margin-top: 10px;
  }
  
  .rhap_additional-controls {
    display: none;
  }
  
  .rhap_main-controls-button {
    width: 100%;
    height: 100%;
    display: flex;
    justify-items: center;
  }
  
  .rhap_container {
    background-color: transparent;
  }
  
  .rhap_container svg {
    $rhap_theme-color: black !important;
    $rhap_background-color: black !important;
    $rhap_bar-color: black !important;
    color: black !important;
  }

  .rhap_progress-indicator {
    background-color: #CFCFCF !important;
    color: #CFCFCF  !important;
  }

  .rhap_download-progress {
    color: black !important;
  }

  .rhap_progress-filled {
    background-color: #CFCFCF !important;
  }

  .rhap_time {
    display: none;
  }
`;

const AudioContent = styled.div `
  margin-top: 3rem;
  width: 100%;
  ${breakpoint('md')`
    margin-top: 0;
  `}
`;

const ImageContainer = styled.div `
  display: flex;
  justify-content: center;
  
  img {
    max-width: 100%;
    max-height: 200px;
    object-fit: cover;
    border-radius: 1rem;
    margin-bottom: 1.5rem;
  }
`;

const AudioToShow = ({audio, site = {}}) => {
  const {i18n} = useTranslation();
  const siteLayout = site?.layout?.filter(l => l.language === i18n.language);
  const audioLayout = siteLayout?.filter(text => text.type === "TitleAudio") || [];
  const audioImageLayout = siteLayout?.filter(text => text.type === "TitleAudioImage") || [];
  const mixedAudioLayout =  audioLayout.concat(audioImageLayout);
  const audioToChoose = mixedAudioLayout || audio;

  audioLayout && audioLayout?.forEach((elm, index) => {
    elm.items.forEach((item) => {
      if (item.type === 'Title') {
        item.text = site.texts.find(text => text.id === item.content.id)
      }
      if (item.type === 'Audio') {
        item.audio = site.medias.find(media => media.blob_id === item?.content?.blob_id)
      }
    })
  });

  audioImageLayout && audioImageLayout?.forEach((elm, index) => {
    elm.items.forEach((item) => {
      if (item.type === 'Title') {
        item.text = site.texts.find(text => text.id === item.content.id)
      }
      if (item.type === 'Audio') {
        item.audio = site.medias.find(media => media.blob_id === item?.content?.blob_id)
      }
      if (item.type === 'Image') {
        item.image = site.medias.find(media => media.blob_id === item?.content?.blob_id)
      }
    })
  });

  const [currentSong, setCurrentSong] = useState(mixedAudioLayout.length > 0 ? mixedAudioLayout[0] : audio ? audio[0] : []);
  const [currentText, setCurrentText] = useState('');
  let [currentSongIndex, setCurrentSongIndex] = useState(0);

  const textKey = `text_${i18n.language}`;
  useEffect(() => {
    if (currentSongIndex !== null && currentSongIndex <= audioToChoose.length - 1) {
      setCurrentSong(audioToChoose[currentSongIndex] || "");
      if(audioToChoose[currentSongIndex]?.items?.[0]?.text){
        setCurrentText(audioToChoose[currentSongIndex].items[0].text?.[textKey])
      }
    }
  }, [currentSongIndex]);

  return(
    <>
      {
        mixedAudioLayout?.length > 0 ?
            <AudioContainer>
              {
                currentSong?.items?.[2]?.image ? (
                  <ImageContainer>
                    <img src={currentSong?.items?.[2]?.image?.url} key={currentSong} />
                  </ImageContainer>
                  )
                  :
                  <></>
              }
              <h3 style={{textAlign: 'center', color: 'white'}}>{currentText || ''}</h3>
              <AudioContent>
                <AudioPlayer
                  src={API_ORIGINAL_BLOB_URL(currentSong?.items?.[1].audio?.signed_id, currentSong?.items?.[1].audio?.filename)}
                  showJumpControls={false}
                  showSkipControls={true}
                  customAdditionalControls={[]}
                  showFilledVolume={false}
                  customIcons={{
                    play: <PlayIcon width="47" height="54" />,
                    previous: <PreviousIcon width="38" height="38" />,
                    next: <NextIcon width="38" height="38"/>,
                    pause: <PauseIcon width="47" height="54" />
                  }}
                  autoPlayAfterSrcChange={false}
                  onClickPrevious={() =>
                    currentSongIndex <= audioToChoose?.length - 1 && currentSongIndex > 0 && setCurrentSongIndex((currentSongIndex -= 1))
                  }
                  onClickNext={() => currentSongIndex < audioToChoose?.length - 1 && setCurrentSongIndex((currentSongIndex += 1))}
                  onEnded={() => setCurrentSongIndex((currentSongIndex += 1))}
                />
                <p style={{textAlign: 'center', color: 'white'}}>{currentSongIndex + 1} / {audioToChoose.length}</p>
              </AudioContent>
            </AudioContainer>
          :
          (
            <AudioContainer>
              <AudioContent>
                <AudioPlayer
                  loop={true}
                  src={API_ORIGINAL_BLOB_URL(currentSong?.signed_id, currentSong?.filename) || audio}
                  showJumpControls={false}
                  showSkipControls={true}
                  customAdditionalControls={[]}
                  customIcons={{
                    play: <PlayIcon width="47" height="54" />,
                    previous: <PreviousIcon width="38" height="38" />,
                    next: <NextIcon width="38" height="38"/>,
                    pause: <PauseIcon width="47" height="54" />
                  }}
                  autoPlayAfterSrcChange={false}
                  onClickPrevious={() =>
                    currentSongIndex <= audio.length - 1 && currentSongIndex > 0 && setCurrentSongIndex((currentSongIndex -= 1))
                  }
                  onClickNext={() => currentSongIndex < audio.length - 1 && setCurrentSongIndex((currentSongIndex += 1))}
                  onEnded={() => setCurrentSongIndex((currentSongIndex += 1))}
                />
              </AudioContent>
            </AudioContainer>
          )
        }
    </>
  );
};


const OneAudioToShow = ({audio}) => {
  const [currentSong, setCurrentSong] = useState(audio ? audio[0] : []);
  let [currentSongIndex, setCurrentSongIndex] = useState(0);

  useEffect(() => {
    if (currentSongIndex !== null && currentSongIndex <= audio.length - 1) {
      setCurrentSong(audio[currentSongIndex] || "");
    }
  }, [currentSongIndex]);

  return(
    <AudioContainer>
      <AudioContent>
        <AudioPlayer
          loop={true}
          src={audio}
          customVolumeControls={[]}
          showJumpControls={false}
          customAdditionalControls={[]}
          customIcons={{
            play: <PlayIcon width="47" height="54" />,
            previous: <PreviousIcon width="38" height="38" />,
            next: <NextIcon width="38" height="38"/>,
            pause: <PauseIcon width="47" height="54" />
          }}
          autoPlayAfterSrcChange={false}
          onClickPrevious={() =>
            currentSongIndex <= audio.length - 1 && currentSongIndex > 0 && setCurrentSongIndex((currentSongIndex -= 1))
          }
          onClickNext={() => currentSongIndex < audio.length - 1 && setCurrentSongIndex((currentSongIndex += 1))}
          onEnded={() => setCurrentSongIndex((currentSongIndex += 1))}
        />
      </AudioContent>
    </AudioContainer>
  );
};

export {AudioToShow, OneAudioToShow};

export default AudioToShow;