import ImgLazy from "../../Lazyload/ImgLazy/ImgLazy";
import { toAbsoluteUrl, transformSmallImageURL } from "utils/utils";
import SVG from "react-inlinesvg";
import AudioPlayer from "react-h5-audio-player";
import "react-h5-audio-player/lib/styles.css";
import "./touristicSiteModal.scss";
import React, { useEffect, useState } from "react";
import ReactPlayer from "react-player";
import { Button } from "components/form/button";
import SlickSlider from "../../../components/form/slider/slider";
import useSlick from "../../../hooks/use-slick";
import { ReactComponent as Close } from "../../../assets/img/logo/Fermer.svg";
import { Dropdown, Icon, Modal } from "semantic-ui-react";
import { SvgIcon } from "@material-ui/core";

import Kahoot from "../../kahoot/kahoot";
import Slider from "react-slick";
import {mediasTyped} from "../../../utils/media";


const SliderButton = ({ current, onChange, isInfinite }) => {
  const isFirst = React.useMemo(() => {
    if (isInfinite) return false;
    return current === 0;
  }, [current, isInfinite]);
  return (
    <>
      <div className="custom-touristic-btn-slick">
        <div
          className={isFirst ? "" : "slider-button"}
          onClick={() => {
            if (!isFirst) {
              onChange(isInfinite ? -1 : isFirst ? 0 : -1);
            }
          }}
        >
          <SVG
            src="/assets/icons/Chevron_right_font_awesome.svg"
            width="35"
            className={`text-${isFirst ? "muted slider-button__svg" : "dark slider-button__svg"
              }`}
            style={{ transform: "scaleX(-1)" }}
          />
        </div>
        <div className="slider-button" onClick={() => onChange(1)}>
          <SVG
            src="/assets/icons/Chevron_right_font_awesome.svg"
            width="35"
            className="ml-3 text-dark slider-button__svg"
          />
        </div>
      </div>
    </>
  );
};

const TouristicSiteModal = ({ touristicSite, setTsModal }) => {

  const [quizz, setQuizz] = useState(false);
  const [songs, setSongs] = useState([]);
  const [images, setImages] = useState([]);
  const [videos, setVideos] = useState([]);
  const [tsVideo, setTsVideo] = useState();
  const [extLink, setExtLink] = useState([]);
  const [lang_available, setLangAvailable] = useState([]);
  const [text, setText] = useState([]);
  const { current, onChange, slick, setCurrent, total } = useSlick(images);
  let [currentSongIndex, setCurrentSongIndex] = useState(null);
  const [currentSong, setCurrentSong] = useState(null);
  const [languages, setLanguages] = useState("fr");
  const allLang = [
    { key: "Français", text: "Français", value: "fr", flag: "fr" },
    { key: "English", text: "English", value: "en", flag: "gb" },
    { key: "German", text: "German", value: "ge", flag: "de" },
    { key: "Italian", text: "Italian", value: "it", flag: "it" },
    { key: "Spanish", text: "Spanish", value: "sp", flag: "es" },
    { key: "Chinese", text: "Chinese", value: "cn", flag: "cn" },
    { key: "Arab", text: "Arab", value: "sa", flag: "sa" },
    { key: "Portuguese", text: "Portuguese", value: "pt", flag: "pt" },
    { key: "Japanese", text: "Japanese", value: "jp", flag: "jp" },
    { key: "Russian", text: "Russian", value: "ru", flag: "ru" },
  ];

  useEffect(() => {
    allLang.map((e) => {
      if (
        touristicSite[`title_${e.value}`] !== null &&
        !lang_available.includes(e)
      ) {
        setLangAvailable((state) => [...state, e]);
      }
    });
  }, []);

  useEffect(() => {
    if (currentSongIndex !== null && currentSongIndex <= songs.length - 1) {
      setCurrentSong(songs[currentSongIndex]?.url || "");
    }
  }, [currentSongIndex]);

  if (currentSongIndex >= songs.length - 1) {
    currentSongIndex = songs.length - 1;
  }

  if (currentSongIndex < 0) {
    currentSongIndex = 0;
  }
  useEffect(() => {
    const temp_video = mediasTyped(touristicSite.medias)?.video;
    temp_video?.map((e) => {
      if (languages === e.language) {
        if (videos.length > 0) {
          setVideos((state) => [...state, e.url]);
        } else {
          setVideos([]);
        }
      } else {
        setVideos([]);
      }
    });
  }, [languages]);
  useEffect(() => {
    const temp_video = mediasTyped(touristicSite.medias)?.video;
    temp_video?.map((e) => {
      if (languages === e.language) {
        if (videos.length > 0) {
          setVideos((state) => [...state, e.url]);
        } else {
          setVideos([]);
        }
      } else {
        setVideos([]);
      }
    });
  }, []);
  useEffect(() => {
    const img = mediasTyped(touristicSite.medias).image;
    img.map((e) => {
      if (e) {
        setImages((state) => [...state, e]);
      }
    });
    if (img.length === 0) {
      setImages([
        {
          url:
            "https://general-henri-1-paris.s3.fr-par.scw.cloud/small-woman.jpg",
        },
      ]);
    }
  }, []);

  useEffect(() => {
    const temp_audio = mediasTyped(touristicSite.medias).audio;
    temp_audio.map((e) => {
      if (languages === e.language) {
        setSongs((state) => [...state, e]);
        setCurrentSongIndex(0);
        setCurrentSong(temp_audio?.url || "");
      } else {
        setSongs([]);
        setCurrentSong();
      }
    });
  }, [languages]);
  return (
    <>
      <Modal open={quizz} onClose={() => setQuizz(false)} size="small">
        <Kahoot
          kahoots={touristicSite.kahoots}
          setTsModal={setTsModal}
          setQuizz={setQuizz}
          quizz={quizz}
          image={touristicSite.custom_touristic_site_files} // TODO
        />
      </Modal>
      <div
        style={{
          backgroundColor: "#DCDCDC",
          height: "120px",
          position: "absolute",
          top: "-50px",
          width: " 100%",
          left: 0,
          zIndex: "-1",
        }}
      ></div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <span>
          <Dropdown
            id={lang_available.id}
            className="ui floating downward dropdown"
            fluid
            onChange={(n, v) => setLanguages(v.value)}
            options={lang_available}
            defaultValue={allLang[0].value}
          ></Dropdown>
        </span>
        <Button
          className="btn-header__close"
          onClick={() => setTsModal(false)}
          title={<Close />}
        />
      </div>
      <br></br>
      <div className="touristic-card-modal__container-modal">
        <div className="touristic-card-modal__content row m-0 no-gutter d-flex flex-column">
          <div className="h-100 p-0 w-100">
            <div className="ts-card-title">
              <div className="mr-2">
                <div style={{ display: "flex" }}>
                  <div className="ts-card-rank-container">
                    {touristicSite.rank < 10 ? (
                      <span className="ts-card-rank">
                        0{touristicSite.rank}
                      </span>
                    ) : (
                      <span className="ts-card-rank">{touristicSite.rank}</span>
                    )}
                    <SVG
                      src="/assets/icons/Nombre3.svg"
                      width="35"
                      height="35"
                    ></SVG>
                  </div>
                </div>
              </div>
              <h2 className="m-0 text-center font-weight-bold touristic-card-modal__title">
                {touristicSite[`title_${languages}`]}
              </h2>
            </div>
            {images.length > 0 && (
              <section className="w-100">

                <Slider ref={slick} {...{
                  slidesToShow: 1,
                  infinite: images.length > 1,
                  dots: true,
                  autoplay: false,
                  slidesToScroll: 1,
                }} beforeChange={(oV, nV) => setCurrent(nV)}>
                  {
                    images?.map((el, i) =>
                      <div key={i} className="custom-touristic-container">
                        <img
                          alt={i}
                          src={el.url || "https://general-henri-1-paris.s3.fr-par.scw.cloud/small-woman.jpg"}
                          className="custom-touristic-card"
                        />
                      </div>
                    )
                  }
                </Slider>
                {
                  images.length > 1 &&
                  <SliderButton current={current} total={total} onChange={onChange} />
                }
              </section>
            )}
          </div>
          <div
            className="align-items-center"
            style={{ marginTop: "10px", border: "none", width: "100%" }}
          >
            {currentSong && (
              <AudioPlayer
                loop={true}
                style={{
                  color: "#868686 !default",
                }}
                src={currentSong}
                customVolumeControls={[]}
                showSkipControls={true}
                showJumpControls={false}
                customAdditionalControls={[]}
                autoPlayAfterSrcChange={true}
                onClickPrevious={() =>
                  setCurrentSongIndex((currentSongIndex -= 1))
                }
                onClickNext={() => setCurrentSongIndex((currentSongIndex += 1))}
                onEnded={() => setCurrentSongIndex((currentSongIndex += 1))}
              />
            )}
          </div>

          <div
            className="mt-3 mb-3 text-dark text-left m-3"
            style={{
              fontWeight: "500",
              lineHeight: "1.5em",
              whiteSpace: "pre-wrap",
            }}
          >
            {touristicSite[`description_${languages}`]}
          </div>

          {touristicSite[`additional_title_${languages}`] && (
            <div
              className="font-weight-bold m-3"
              style={{
                fontWeight: "500",
                lineHeight: "1.5em",
                whiteSpace: "pre-wrap",
              }}
            >
              {touristicSite[`additional_title_${languages}`]}
            </div>

          )}
          <div className="align-items-center" style={{
            display: "flex",
            flexDirection: "column"
          }}>

            {tsVideo !== null && tsVideo !== undefined && tsVideo !== "" &&
              <div className="align-items-center w-100" style={{ marginTop: "5%" }} >
                <ReactPlayer
                  className='react-player__custom'
                  width='100%'
                  url={tsVideo || ""}
                  controls
                />
              </div>
            }
            {
              videos.length !== 0 &&
              <div className="align-items-center">
                <ReactPlayer
                  className="react-player__custom"
                  width="100%"
                  height="30%"
                  url={[]}
                  controls
                />
              </div>
            }

            {touristicSite.kahoots.length > 0 && (
              <div className="div-btn-kahoot">
                <p style={{ marginBottom: "0" }}>
                  Vous souhaitez tester vos connaissances ?{" "}
                </p>
                <Button
                  type="button"
                  onClick={() => setQuizz(true)}
                  className="btn-quizz"
                >
                  Commencer
                </Button>
              </div>
            )}
            {touristicSite.external_link && (
              <p style={{ marginLeft: "6%", marginRight: "6%" }}>
                <a
                  target="_blank"
                  href={touristicSite.external_link}
                  style={{
                    whiteSpace: "pre-wrap",
                    wordBreak: "break-all",
                    textDecoration: "underline blue",
                    color: "blue",
                  }}
                >
                  {touristicSite.external_link}
                </a>
              </p>
            )}
          </div>
          <div className="div-btn-close">
            <Button
              type="button"
              onClick={() => setTsModal(false)}
              className="btn-close"
            >
              Fermer
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};
export default TouristicSiteModal;
