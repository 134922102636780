import Button from "../form/button/button";
import { Modal } from "semantic-ui-react";
import { useState, useEffect, useForm } from "react";
import { createSvgIcon } from "@material-ui/core";
import SquareIcon from "@mui/icons-material/Square";
import { Dropdown, Icon } from "semantic-ui-react";
import { ReactComponent as Close } from "../../assets/img/logo/Fermer.svg";
import "./kahoot.scss";
import { ReactComponent as Triangle } from "../../assets/img/logo/triangle-kahoot.svg";
import { ReactComponent as Circle } from "../../assets/img/logo/circle.svg";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
import Kahoot_result from "./kahoot_result";
import pouce_vert from "../../assets/img/logo/pouce_vert.png";
import pouce_rouge from "../../assets/img/logo/pouce_rouge.png";

const Kahoot = ({ kahoots, image, setQuizz, quizz }) => {
  const [openResult, setOpenResult] = useState(false);
  let [index, setIndex] = useState(0);
  const [languages, setLanguages] = useState("fr");
  const [color, setColor] = useState(null);
  const [imgAvailable, setImgAvailable] = useState([]);
  const [color1, setColor1] = useState("#730072");
  const [color2, setColor2] = useState("blue");
  const [color3, setColor3] = useState("#BF8700");
  const [color4, setColor4] = useState("dark");
  const [goodAnswer, setGoodAnswer] = useState([]);
  const [int, setInt] = useState(null);
  const [blur1, setBlur1] = useState(100);
  const [blur2, setBlur2] = useState(100);
  const [blur3, setBlur3] = useState(100);
  const [blur4, setBlur4] = useState(100);
  const [count, setCount] = useState(0);
  const [question, setQuestion] = useState(0);
  let [moyenne, setMoyenne] = useState(0);
  const [recap, setRecap] = useState(null);
  const [pouce, setPouce] = useState(null);
  const [det, setDet] = useState(null);
  const [clickedBtn, setClickedBtn] = useState(false);
  const allLang = [
    { key: "Français", text: "Français", value: "fr", flag: "fr" },
    { key: "English", text: "English", value: "en", flag: "gb" },
    { key: "German", text: "German", value: "ge", flag: "de" },
    { key: "Italian", text: "Italian", value: "it", flag: "it" },
    { key: "Spanish", text: "Spanish", value: "sp", flag: "es" },
    { key: "Chinese", text: "Chinese", value: "cn", flag: "cn" },
    { key: "Arab", text: "Arab", value: "sa", flag: "sa" },
    { key: "Portuguese", text: "Portuguese", value: "pt", flag: "pt" },
    { key: "Japanese", text: "Japanese", value: "jp", flag: "jp" },
    { key: "Russian", text: "Russian", value: "ru", flag: "ru" },
  ];

  const [lang_available, setLangAvailable] = useState([allLang[0]]);

  const next = () => {
    setQuestion((state) => state + 1);
    setDet(null);
    setPouce(null);
    setClickedBtn(false)
  };

  useEffect(() => {
    const img = image.filter((e) => e.kahoot_id === kahoots[index]?.id);

    setImgAvailable(img);
    setColor1("#730072");
    setColor2("#004f91");
    setColor3("#BF8700");
    setColor4("#000000");
    setBlur1(100);
    setBlur2(100);
    setBlur3(100);
    setBlur4(100);
  }, []);

  useEffect(() => {
    const img = image.filter((e) => e.kahoot_id === kahoots[index]?.id);
    setImgAvailable(img);
    setInt(null);
    setColor1("#730072");
    setColor2("#004f91");
    setColor3("#BF8700");
    setColor4("#000000");
    setBlur1(100);
    setBlur2(100);
    setBlur3(100);
    setBlur4(100);
  }, [index]);
  const [maxLength, setMaxLength] = useState(null);
  useEffect(() => {
    setMaxLength(kahoots.length - 1);
  }, []);

  useEffect(() => {
    const div = 20 / kahoots.length;
    const moy = Math.round(count * div);
    setMoyenne(moy);
  }, [count]);
  useEffect(() => {
    if (index < maxLength) {
      setIndex((state) => state + 1);
    }
    if (index === maxLength) {
      index = index;
    }
  }, [question]);
  const setChecked = (int) => {
    if (clickedBtn == false) {
      if (int === kahoots[index].good_answer) {
        if (int === 1) {
          setColor1("#43A047");
          setBlur2("40%");
          setBlur3("40%");
          setBlur4("40%");
          setRecap(true);
          setCount((state) => state + 1);
          setDet(kahoots[index].detail1);
          setPouce(pouce_vert);
        }
        if (int === 2) {
          setColor2("#43A047");
          setBlur1("40%");
          setBlur3("40%");
          setBlur4("40%");
          setCount((state) => state + 1);
          setRecap(true);
          setDet(kahoots[index].detail2);
          setPouce(pouce_vert);
        }
        if (int === 3) {
          setColor3("#43A047");
          setBlur1("40%");
          setBlur2("40%");
          setBlur4("40%");
          setCount((state) => state + 1);
          setRecap(true);
          setDet(kahoots[index].detail3);
          setPouce(pouce_vert);
        }
        if (int === 4) {
          setBlur1("40%");
          setBlur2("40%");
          setBlur3("40%");
          setColor4("#43A047");
          setCount((state) => state + 1);
          setRecap(true);
          setDet(kahoots[index].detail4);
          setPouce(pouce_vert);
        }
      }
      if (int !== kahoots[index].good_answer) {
        if ((kahoots[index].good_answer === 1) & (int === 2)) {
          setColor1("#43A047");
          setColor2("#D32F2F");
          setBlur3("40%");
          setBlur4("40%");
          setDet(kahoots[index].detail2);
          setPouce(pouce_rouge);
        }
        if ((kahoots[index].good_answer === 1) & (int === 3)) {
          setColor1("#43A047");
          setColor3("#D32F2F");
          setBlur2("40%");
          setBlur4("40%");
          setDet(kahoots[index].detail3);
          setPouce(pouce_rouge);
        }
        if ((kahoots[index].good_answer === 1) & (int === 4)) {
          setColor1("#43A047");
          setBlur2("40%");
          setBlur3("40%");
          setColor4("#D32F2F");
          setDet(kahoots[index].detail4);
          setPouce(pouce_rouge);
        }
        if ((kahoots[index].good_answer === 2) & (int === 1)) {
          setColor1("#D32F2F");
          setColor2("#43A047");
          setBlur3("40%");
          setBlur4("40%");
          setDet(kahoots[index].detail1);
          setPouce(pouce_rouge);
        }
        if ((kahoots[index].good_answer === 2) & (int === 3)) {
          setColor2("#43A047");
          setColor3("#D32F2F");
          setBlur1("40%");
          setBlur4("40%");
          setDet(kahoots[index].detail3);
          setPouce(pouce_rouge);
        }
        if ((kahoots[index].good_answer === 2) & (int === 4)) {
          setColor2("#43A047");
          setBlur3("40%");
          setBlur4("40%");
          setColor4("#D32F2F");
          setDet(kahoots[index].detail4);
          setPouce(pouce_rouge);
        }
        if ((kahoots[index].good_answer === 3) & (int === 1)) {
          setColor3("#43A047");
          setColor1("#D32F2F");
          setBlur2("40%");
          setBlur4("40%");
          setDet(kahoots[index].detail1);
          setPouce(pouce_rouge);
        }
        if ((kahoots[index].good_answer === 3) & (int === 2)) {
          setColor3("#43A047");
          setColor2("#D32F2F");
          setBlur1("40%");
          setBlur4("40%");
          setDet(kahoots[index].detail2);
          setPouce(pouce_rouge);
        }
        if ((kahoots[index].good_answer === 3) & (int === 4)) {
          setColor3("#43A047");
          setBlur1("40%");
          setBlur2("40%");
          setColor4("#D32F2F");
          setDet(kahoots[index].detail4);
          setPouce(pouce_rouge);
        }
        if ((kahoots[index].good_answer === 4) & (int === 1)) {
          setColor4("#43A047");
          setColor1("#D32F2F");
          setBlur2("40%");
          setBlur3("40%");
          setDet(kahoots[index].detail1);
          setPouce(pouce_rouge);
        }
        if ((kahoots[index].good_answer === 4) & (int === 2)) {
          setColor4("#43A047");
          setColor2("#D32F2F");
          setBlur1("40%");
          setBlur3("40%");
          setDet(kahoots[index].detail2);
          setPouce(pouce_rouge);
        }
        if ((kahoots[index].good_answer === 4) & (int === 3)) {
          setColor4("#43A047");
          setColor3("#D32F2F");
          setBlur2("40%");
          setBlur1("40%");
          setDet(kahoots[index].detail3);
          setPouce(pouce_rouge);
        }
        setRecap(false);
      }
    }
  };


  const TriangleIcon = createSvgIcon(
    <path d="M7.938 2.016A.13.13 0 0 1 8.002 2a.13.13 0 0 1 .063.016.146.146 0 0 1 .054.057l6.857 11.667c.036.06.035.124.002.183a.163.163 0 0 1-.054.06.116.116 0 0 1-.066.017H1.146a.115.115 0 0 1-.066-.017.163.163 0 0 1-.054-.06.176.176 0 0 1 .002-.183L7.884 2.073a.147.147 0 0 1 .054-.057zm1.044-.45a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566z" />,
    "Triangle"
  );
  const CircleIcon = createSvgIcon(
    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />,
    "Circle"
  );
  const DiamondIcon = createSvgIcon(
    <path d="M6.95.435c.58-.58 1.52-.58 2.1 0l6.515 6.516c.58.58.58 1.519 0 2.098L9.05 15.565c-.58.58-1.519.58-2.098 0L.435 9.05a1.482 1.482 0 0 1 0-2.098L6.95.435zm1.4.7a.495.495 0 0 0-.7 0L1.134 7.65a.495.495 0 0 0 0 .7l6.516 6.516a.495.495 0 0 0 .7 0l6.516-6.516a.495.495 0 0 0 0-.7L8.35 1.134z" />,
    "Diamond"
  );


  return (
    <>
      <Modal open={openResult} size="small">
        <Kahoot_result setQuizz={setQuizz} result={moyenne}></Kahoot_result>
      </Modal>
      <div
        style={{
          backgroundColor: "#DCDCDC",
          height: "8.5rem",
          position: "absolute",
          top: "-50px",
          width: " 100%",
          left: 0,
          zIndex: "-1",
        }}
      ></div>
      <div
        className="bottom-header"
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <span>
          <Dropdown
            id={lang_available.id}
            className="ui floating downward dropdown"
            fluid
            onChange={(n, v) => setLanguages(v.value)}
            options={lang_available}
            defaultValue={allLang[0].value}
          ></Dropdown>
        </span>
        <Button
          className="btn-header__close"
          onClick={() => setQuizz(false)}
          title={<Close />}
        />
      </div>

      <div className="Kahoot-get-page">
        {
          // si la question a une img :
          imgAvailable.length > 0 ? (
            <div
              className="kahoot-content"
              style={{ gridTemplateColumns: "1fr 1fr" }}
            >
              <div className="quest-img-kahoot">
                <div className="Kahoot-question">
                  <p
                    style={{
                      fontSize: "2rem",
                      color: "#004f91",
                      fontWeight: "800",
                      margin: "1rem",
                    }}
                  >
                    Question {+index + 1}
                  </p>
                </div>
                <div>
                  <img
                    className="kahoot-img"
                    src={imgAvailable[0]?.file_url}
                    alt="kahoot_img"
                  ></img>
                </div>
              </div>
              <div className="question-kahoot">
                <p>{kahoots[index].question}</p>
                <div
                  className="container-detail-pouce"
                  style={{ marginTop: "5%" }}
                >
                  {pouce !== null && (
                    <div style={{ marginRight: "7%" }}>
                      <img
                        className="kahoot-pouce"
                        src={pouce}
                        alt="kahoot_img"
                      ></img>
                    </div>
                  )}

                  {det !== null && (
                    <>
                      <div className="detail-kahoot">
                        <p>{det}</p>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          ) : (
            // lorsque la question est sans image
            <div
              className="kahoot-content"
              style={{ gridTemplateColumns: "1fr" }}
            >
              <div className="Kahoot-question">
                <p
                  style={{
                    fontSize: "2rem",
                    color: "#004f91",
                    fontWeight: "800",
                    margin: "1rem",
                    textAlign: "center",
                  }}
                >
                  Question {+index + 1}
                </p>
              </div>

              <div className="question-kahoot" style={{ marginTop: "5%" }}>
                <p>{kahoots[index].question}</p>
              </div>
              <div
                className="container-detail-pouce"
                style={{ marginLeft: "5%", marginTop: "5%" }}
              >
                {pouce !== null && (
                  <div className="container-kahoot-pouce">
                    <img
                      className="kahoot-pouce"
                      src={pouce}
                      alt="kahoot_img"
                    ></img>
                  </div>
                )}

                {det !== null && (
                  <>
                    <div className="detail-kahoot" style={{ marginLeft: "5%" }}>
                      <p>{det}</p>
                    </div>
                  </>
                )}
              </div>
            </div>
          )
        }
      </div>

      <div className="get-answers-kahoot">
        <div
          className="get-answers1-kahoot"
          style={{
            marginRight: "7%",
            backgroundColor: `${color1}`,
            opacity: `${blur1}`,
          }}
          onClick={() => {
            setChecked(1)
            setClickedBtn(true)
          }}
        >
          <div className="get-kahoot-answers-container">
            <Triangle
              style={{
                fill: "white",
                height: "35",
                width: "35",
                marginRight: "2rem",
                marginLeft: "2rem",
              }}
            />
            <span>
              <p className="get-kahoot-answers">{kahoots[index].reponse1}</p>
            </span>
          </div>
          {color1 === "#43A047" && (
            <>
              <DoneIcon
                style={{ height: "35", width: "35", marginRight: "2rem" }}
              />
            </>
          )}
          {color1 === "#D32F2F" && (
            <>
              <CloseIcon
                style={{ height: "35", width: "35", marginRight: "2rem" }}
              />
            </>
          )}
        </div>

        <div
          className="get-answers2-kahoot"
          style={{
            marginLeft: "7%",
            backgroundColor: `${color2}`,
            opacity: `${blur2}`,
          }}
          onClick={() => {
            setChecked(2)
            setClickedBtn(true)
          }}
        >
          <div className="get-kahoot-answers-container">
            <SquareIcon
              style={{
                fill: "white",
                height: "35",
                width: "35",
                marginRight: "2rem",
                marginLeft: "2rem",
                rotate: "45deg",
              }}
            ></SquareIcon>

            <span>
              <p className="get-kahoot-answers">{kahoots[index].reponse2}</p>
            </span>
          </div>
          {color2 === "#43A047" && (
            <>
              <DoneIcon
                style={{ height: "35", width: "35", marginRight: "2rem" }}
              />
            </>
          )}
          {color2 === "#D32F2F" && (
            <>
              <CloseIcon
                style={{ height: "35", width: "35", marginRight: "2rem" }}
              />
            </>
          )}
        </div>

        <div
          className="get-answers3-kahoot"
          style={{
            marginRight: "7%",
            backgroundColor: `${color3}`,
            opacity: `${blur3}`,
          }}
          onClick={() => {
            setChecked(3)
            setClickedBtn(true)
          }}
        >
          <div className="get-kahoot-answers-container">
            <Circle
              style={{
                fill: "white",
                height: "35",
                width: "35",
                marginRight: "2rem",
                marginLeft: "2rem",
              }}
            />

            <span>
              <p className="get-kahoot-answers">{kahoots[index].reponse3}</p>
            </span>
          </div>
          {color3 === "#43A047" && (
            <>
              <DoneIcon
                style={{ height: "35", width: "35", marginRight: "2rem" }}
              />
            </>
          )}
          {color3 === "#D32F2F" && (
            <>
              <CloseIcon
                style={{ height: "35", width: "35", marginRight: "2rem" }}
              />
            </>
          )}
        </div>

        <div
          className="get-answers4-kahoot"
          style={{
            marginLeft: "7%",
            backgroundColor: `${color4}`,
            pointer: "cursor",
            opacity: `${blur4}`,
          }}
          onClick={() => {
            setChecked(4)
            setClickedBtn(true)
          }}
        >
          <div className="get-kahoot-answers-container">
            <SquareIcon
              style={{
                fill: "white",
                height: "35",
                width: "35",
                marginRight: "2rem",
                marginLeft: "2rem",
              }}
            ></SquareIcon>

            <span>
              <p className="get-kahoot-answers">{kahoots[index].reponse4}</p>
            </span>
          </div>
          <div>
            {color4 === "#43A047" && (
              <>
                <DoneIcon
                  style={{ height: "35", width: "35", marginRight: "2rem" }}
                />
              </>
            )}
            {color4 === "#D32F2F" && (
              <>
                <CloseIcon
                  style={{ height: "35", width: "35", marginRight: "2rem" }}
                />
              </>
            )}
          </div>
        </div>
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginTop: "5%",
          marginBottom: "2%",
        }}
      >
        <Button
          type="button"
          onClick={() => setQuizz(false)}
          className="btn-close"
        >
          Précedent
        </Button>
        {index < maxLength ? (
          <>
            <Button type="button" onClick={() => next()} className="btn-next">
              Suivant
            </Button>
          </>
        ) : (
          <>
            <Button
              type="button"
              onClick={() => {
                setOpenResult(true) && setQuizz(false);
              }}
              className="btn-next"
            >
              Suivant
            </Button>
          </>
        )}
      </div>
    </>
  );
};

export default Kahoot;
