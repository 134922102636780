import { Formik } from 'formik';
import useRequest from 'hooks/use-request';
import ForgotPasswordPage from 'pages/AuthPage/ForgotPasswordPage';
import React from 'react';
import { API_FORGOT_PASSWORD } from 'utils/api';
import { REQUIRED_MESSAGE } from 'utils/texts';
import * as Yup from 'yup';

const ForgotPassword = () => {
  const [handleRequest] = useRequest();
  const [success, setSuccess] = React.useState(false);
  const [error, setError] = React.useState(false);

  const handleSubmit = (v) => {
    handleRequest("post", API_FORGOT_PASSWORD, v, {}, (data, status) => {
      if (status === "Done") {
        setSuccess(true);
      } else if (status === "Error") {
        setError(data.message);
        console.error(data);
      }
    })
  }

  return (
    <Formik
      initialValues={{
        email: ""
      }}
      onSubmit={handleSubmit}
      validationSchema={Yup.object().shape({
        email: Yup.string().email("Entrez une adresse mail valide.").required(REQUIRED_MESSAGE)
      })}
    >
      {(formikProps) => {
        return <ForgotPasswordPage {...formikProps} success={success} error={error} />
      }}
    </Formik>
  );
};

export default ForgotPassword;