import PrivacyPage from 'pages/UserPage/PrivacyPage/PrivacyPage';
import React from 'react';




const Privacy = () => {

    return (
        <PrivacyPage/>
    );
};

export default Privacy;