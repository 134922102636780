import React, {useState} from "react";
import {API_DIRECT_UPLOAD_TEXT, API_UPDATE_TEXTS} from "../../../../utils/api";
import {useFormik} from "formik";
import {
  audioSchema,
  audioTitleImageSchema,
  imageSchema,
  titleSchema
} from "../../../_schemas/media_layout_builder_schemas";
import {FileInput, FileInputLabel} from "../../../../_ui/_v2/components/FormElements";
import Loader from "../../../../_ui/_v2/components/Loader";
import {OneAudioToShow} from "../../../../_ui/_v2/components/Audio/AudioToShow";
import ButtonFormValidation from "../ButtonFormValidation";
import styled from "styled-components";
import { ReactComponent as AudioIcon } from "../../../../assets/img/logo/Activity/icon_headphones.svg";
import {ReactComponent as ImageUploadIcon} from '../../../../assets/img/logo/image-upload-icon.svg';
import {useTranslation} from "react-i18next";



const FormVideoStyled = styled.form `
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 25px;
`;

const FirstPartOfVideoStyled = styled.div `
  border: 2px dashed ${props => props.theme.colors.black};
  width: 70%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 25px;
`;

const VideoFormContainer = styled.div `
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  margin-top: 1em;
  margin-bottom: 1em;
`;

const InputStyled = styled.input`
  background-color: rgba(0, 0, 0, .8);
  border: 1px solid rgba(169, 169, 169, 0.6);
  color: white;  
  padding: .7em 1em .5em .5em;
  resize: none;
  border-radius: .5em;
  width: 100%;
  ::placeholder{
    color: white;
  }
`;

const ContainerVideo = styled.div `
  width: 90%;
  
  label{
    width: 90%
  }
`;

const FirstPartOfAudioStyled = styled.div`
  border: 2px dashed ${(props) => props.theme.colors.black};
  border: ${(props) => props.finished && "none"};
  width: 75%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 25px;
`;

const FileInputContainer = styled.div`
  height: 200px;
  display: flex;
  background-color: ${(props) => props.theme.colors.fileInputEmpty};
  background-image: ${(props) =>
  props.backgroundImageUrl ? `url('${props.backgroundImageUrl}')` : "none"};
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  ${(props) =>
  !props.backgroundImageUrl &&
  `
  border: 2px dashed ${props.theme.colors.henriBlue};
  `}
`;

const TitleAudioImageForm = ({onLayoutItemChange, id, audiosToDisplay, activeStorageServiceName, onSuccess  }) => {
  const [previewUrlAudio, setPreviewUrlAudio] = useState();
  const [previewUrlImage, setPreviewUrlImage] = useState();
  const [loading, setLoading] = useState(false);
  const { i18n } = useTranslation();
  const language = i18n.language;

  const callback = (payload) => {
    const medias = values?.items.filter((e,index) => e.type !== "Title").filter(item => !item.content?.blob_id).map(item => item.content);
    const update = Object.keys(audiosToDisplay || []).length > 0;
    const builderSchema = {
      type: 'Title',
      content: payload
    }
    values.items.splice(0, 1, builderSchema)
    onLayoutItemChange(
      values,
      medias,
      update,
      {
        onSuccess: (response) => {
          onSuccess?.(response);
          setLoading(false);
        },
        onError: (error) => {
          console.error(error);
          setLoading(false);
        },
      }
    );
  };

  const handleSubmitForm = (values) => {
    setLoading(true);
    const token = localStorage.getItem("Authorization");
    const arrayToPush = [];
    const title = values.items.filter(item => item.type === 'Title')[0].content;
    arrayToPush.push(title);
    setLoading(true);
    fetch(
      title?.id ? API_UPDATE_TEXTS : API_DIRECT_UPLOAD_TEXT,
      {
        method: title?.id ? 'PUT' : 'POST',
        cache: 'default',
        body: JSON.stringify({texts: arrayToPush}),
        headers: {
          "Authorization": `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        mode: 'cors',
      }
    )
      .then(res => res.json())
      .then(response => {
        callback(response.length > 1 ? response : {id: response[0].id})
      })
      .catch(error => {
        setLoading(false);
        console.error(error);
      });
  };
  const {
    handleSubmit,
    setFieldValue,
    values,
    handleChange
  } = useFormik({
    initialValues: audioTitleImageSchema.cast({}),
    validationSchema: audioTitleImageSchema,
    onSubmit: handleSubmitForm,
  });

  const textKey = `text_${i18n.language}`;
  return(
    <FormVideoStyled onSubmit={handleSubmit}>
      <FirstPartOfAudioStyled>
        {
          <VideoFormContainer>
            <div style={{width: '80%'}}>
              <InputStyled
                id={textKey}
                name={`items[0].content.${textKey}`}
                value={values?.items?.[0]?.content?.[textKey]}
                placeholder='Titre'
                onChange={(event) => {
                  setFieldValue(
                    `items[0]`,
                    titleSchema.cast({content: {[textKey]: event.target.value}})
                  );
                }}
                type="text"
              />
            </div>
            <ContainerVideo >
              <FileInput
                id={id}
                name={`items[1].content.signed_id`}
                accept="audio/aac, audio/mp3, audio/ogg"
                activeStorageServiceName={activeStorageServiceName}
                value={values?.items?.[1]?.content?.signed_id}
                onBlobUrlChange={setPreviewUrlAudio}
                onChange={(event) => {
                  setFieldValue(
                    `items[1]`,
                    audioSchema.cast({
                      content: {
                        signed_id: event.target.value,
                        language: language,
                      }
                    })
                  );
                }}
              >
                {
                  ({ready, globalProgress}) => (
                    <FileInputLabel
                      disabled={!ready}
                      progress={globalProgress}
                      style={{border: "none", backgroundColor: 'black'}}
                    >
                      {ready
                        ? (
                          <div style={{display: 'flex', gap: '25px', alignItems: 'center'}}>
                            <AudioIcon />
                            {/*trouver condition pour modifier/charger */}
                            {values?.items?.[1]?.content?.signed_id ? 'Modifier votre audio' : 'Charger votre audio'}
                          </div>
                        ) : (
                          <>
                            <Loader style={{display: 'inline', margin: '0 1em 0 0', height: 25}}/>
                            {'Upload en cours'}
                          </>
                        )
                      }
                    </FileInputLabel>
                  )}
              </FileInput>
              {
                values?.items?.[1]?.content?.signed_id && (
                  <OneAudioToShow audio={[previewUrlAudio]} />
                )
              }
              <FileInputContainer
                backgroundImageUrl={previewUrlImage}
              >
                <FileInput
                  id = {id}
                  name={`items[2].content.signed_id`}
                  accept="image/*"
                  activeStorageServiceName="touristic_site_scaleway"
                  onChange={(event) => {
                    if (event.target.value !== values?.items?.[2]?.signed_id) {
                      setFieldValue(
                        `items[2]`,
                        imageSchema.cast({content: {signed_id: event.target.value}})
                      );
                    } else {
                      handleChange(event);
                    }
                  }}
                  value={values?.items?.[2]?.content?.signed_id}
                  onBlobUrlChange={setPreviewUrlImage}
                  style={{margin: 'auto'}}
                >
                  {
                    ({ready, globalProgress}) => (
                      <FileInputLabel
                        disabled={!ready}
                        progress={globalProgress}
                        style={{border: "none"}}
                      >
                        {ready
                          ? (
                            <div style={{color: 'black'}}>
                              <ImageUploadIcon style={{marginRight: '1em', height: 25 }}/>
                              {values[2]?.signed_id ? 'Modifier l\'image' : 'Ajouter une image'}
                            </div>
                          ) : (
                            <>
                              <Loader style={{display: 'inline', margin: '0 1em 0 0', height: 25}}/>
                              {'Upload en cours'}
                            </>
                          )
                        }
                      </FileInputLabel>
                    )
                  }
                </FileInput>
                <input
                  type="hidden"
                  name={`items[2]`}
                  value={values?.items?.[2]}
                />
              </FileInputContainer>
            </ContainerVideo>
          </VideoFormContainer>
        }
      </FirstPartOfAudioStyled>
      <ButtonFormValidation loading={loading} placeholder='Enregistrer'/>

    </FormVideoStyled>
  );
};

export default TitleAudioImageForm;