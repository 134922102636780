import styled from 'styled-components';
import {useCallback, useEffect, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {ReactComponent as TranslateIcon} from '../../../../assets/img/logo/translate-icon.svg';

const LanguageList = styled.ul`
  list-style: none;
  margin: 0;
  position: absolute;
  z-index: 1;
  border-radius: 5px;
  box-shadow: 10px 10px 13px -7px rgb(0 0 0 / 17%), 16px 16px 23px -2px rgb(0 0 0 / 17%);
  box-sizing: border-box;
  
  li:first-child {
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
  }

  li:last-child {
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
  }
`;

const LanguageOption = styled.li`
  padding: 10px 20px;
  margin: 0;
  list-style: none;
  background-color: ${props => (
  props.selected
    ? props.theme.colors.lightGrey
    : props.theme.colors.white
)};
  box-sizing: border-box;
  display: flex;

  ${props => !props.disabled && `
  cursor: pointer;
  
  :hover {
    background-color: ${props.theme.colors.lightGrey};
  }
  `}
  
  #flag {
    padding-right: 10px;
    box-sizing: border-box;
  }
`;

const LanguageIconStyled = styled.div`
  display: inline-block;
  width: 35px;
  height: 35px;
  padding: 5px;
  border: 1px solid;
  border-radius: 50%;
  vertical-align: middle;
  background-color: ${props => props.theme.colors.white60};
  transition: transform 0.5s ease-in-out;
  cursor: pointer;
  user-select: none;
  box-sizing: border-box;
  
  :hover {
    transform: scale(120%);
  }
  
  svg {
    height: 100%;
    width: 100%;
    display: block;
    margin: auto auto;
    color: ${props => props.theme.colors.black};
  }
`;

const LanguageSelectorStyled = styled.div`
  width: 35px;
  position: relative;
  box-sizing: border-box;
  overflow: visible;
`;

const LanguageSelector = ({align = 'right', ...rest}) => {
  const [listVisible, setListVisible] = useState(false);
  const containerRef = useRef();
  const { t, i18n } = useTranslation('commons');
  const languages = t('commons:languages', { returnObjects: true });
  const availableLanguages = Object.keys(languages);

  const handleClick = (lang) => {
    i18n.changeLanguage(lang);
    setListVisible(false);
  };

  const toggleListVisibility = () => setListVisible(prevValue => !prevValue);

  const handleClickOutside = useCallback((event) => {
    const clickOutside = !(
      containerRef.current?.contains(event.target)
    );

    if (clickOutside && listVisible) {
      setListVisible(false);
    }
  }, [containerRef, listVisible]);

  useEffect(() => {
    window.addEventListener('click', handleClickOutside);

    return () => window.removeEventListener('click', handleClickOutside);
  }, [handleClickOutside]);

  return (
    <LanguageSelectorStyled
      ref={containerRef}
      {...rest}
    >
      <LanguageIconStyled
        onClick={toggleListVisibility}
      >
        <TranslateIcon/>
      </LanguageIconStyled>
      {listVisible && (
        <LanguageList
          style={{
            [align]: 0,
            marginTop: 10,
          }}
        >
          {
            availableLanguages.map((lang, index) => (
              <LanguageOption
                key={index}
                onClick={(event) => {
                  handleClick(lang);
                  event.stopPropagation();
                }}
                selected={i18n.language === lang}
              >
                <span id="flag">{languages[lang].flag}</span>{languages[lang].label}
              </LanguageOption>
            ))
          }
        </LanguageList>
      )}
    </LanguageSelectorStyled>
  );
};

export default LanguageSelector;