import Title from 'components/header/title/title';
import React, { useEffect, useState, Suspense, useContext } from "react";
import {ThemeContext} from 'styled-components';
import { PHONE_WIDTH } from 'utils/utils';
import { BottomSheet } from "react-spring-bottom-sheet";
import { ActivityCard } from 'components/_v2/activity';
import 'react-spring-bottom-sheet/dist/style.css'
import './search-view-page.scss'
import useDocumentTitle from "../../../hooks/use-document-title";
import { Dropdown, Loader, Icon } from "semantic-ui-react";
import Pagination from "../../../components/pagination/pagination";
import { useTranslation } from "react-i18next";
import ModalForm from "../../../components/formModal/ModalForm";
import Login from "../../../containers/Auth/Login";
import { Modal } from "react-bootstrap";
import { Button } from 'components/form/button';
import Suggest from "../../../containers/Suggest/Suggest";
import CategoriesFilter from "../../../components/informations/categories/categoriesFilter/CategoriesFilter"
import theme from '_ui/_v2/styles/theme';
import { ThemeProvider } from "styled-components";

const Map = React.lazy(() => new Promise(resolve => resolve(import('../../../components/maps/Map'))));

/*const ChangeView = ({ centerPos, options }) => {
    const map = useMap();

    React.useEffect(() => {
        if (!centerPos)
            return;
        map.setView(centerPos, 20)
    }, [centerPos, map])

    React.useEffect(() => {
        if (!options)
            return;
        map.fitBounds(options?.map(el => el.position))
    }, [options, map])

    return <></>
}*/



const HeaderTitle = ({ noTopPad, search }) => {
  const { t } = useTranslation(["henriTrip"])

  return (
    <Title title={t("searchComponent.searchPage.title")}
      noTopPad={noTopPad}
      className=""
      description={(
        <div className="align-items-center pt-1">
          <h3>{t("searchComponent.searchPage.subtitle")} {search}</h3>
        </div>
      )} />
  )
}

const HeaderMobile = ({ mobile, noTopPad, search, handleFilters }) => {
  const { t } = useTranslation(["henriTrip"])

  const categorieFilter = [
    { key: 'Activité', text: t("general.category.activity"), value: 53},
    { key: 'Site Religieux', text: t("general.category.religiousSite"), value: 54},
    { key: 'Parc / Jardin', text: t("general.category.parkGarden"), value: 55 },
    { key: 'Oenotourisme / Spiritourisme', text: t("general.category.wineTourisme"), value: 56 },
    { key: 'Chateaux / Monument historique', text: t("general.category.historicalMonument"), value: 57 },
    { key: 'Grotte / Site préhistorique', text: t("general.category.cavePrehistoric"), value: 58 },
    { key: 'Musée / Exposition', text: t("general.category.museum"), value: 59 },
    { key: 'Bien-être', text: t("general.category.wellness"), value: 60 },
    { key: 'Parc d\'attraction / Parc animalier', text: t("general.category.park"), value: 61 },
    { key: 'Le choix d\'Henri', text: t("general.category.henriChoice"), value: 62 },
  ]

  return (
    <div className={mobile ? '' : 'result-list--title'}>
      <HeaderTitle noTopPad={noTopPad} search={search} />
      <div className="text-center col-md-5 col-lg-5 pl-2 mb-md-0">
        {
          mobile ?
            <></>
            :
            <Dropdown
              clearable
              button
              className="icon col-sm-12 col-md-12 mt-md-2 mt-1 m-0"
              placeholder={t("general.category.title")}
              icon="sliders horizontal"
              labeled
              multiple
              selection
              settings={{ useLabels: false }}
              options={categorieFilter}
              onChange={(event, data) => handleFilters(data.value)}
            />
        }
      </div>
    </div>
  )
}

const ResultListView = ({ touristicSites, loading, search, noTopPad, className, displayTitle, handleFilters, scrollToTop, openFilter, openCategoriesFilter }) => {
  const [viewElements, setViewElements] = useState(12);
  const [numberPages, setNumberPages] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const theme = useContext(ThemeContext);

  useEffect(() => {
    setViewElements(12)
    setNumberPages(touristicSites.length)
    setCurrentPage(1)
  }, [touristicSites])

  useEffect(() => {
    scrollToTop()
  }, [currentPage, scrollToTop])

  return (
    <div className={`col-12 col-md-6 p-2 ${className} result-list`}>
      {
        displayTitle &&
        <HeaderMobile
          noTopPad={noTopPad}
          search={search}
          handleFilters={handleFilters}
        />
      }
      {
        window.innerWidth <= PHONE_WIDTH &&
          <div className="row justify-content-center">
            <Button title="Filtres" rounded color="information-block " className="m-1" onClick={openCategoriesFilter} />
          </div>
      }


      <div className="result-list--cards" >

        {
          loading ?
            <Loader active className="mt-4" />
            :
            <div className="py-2 mx-2 row justify-content-center">
              {
                touristicSites?.slice(12 * (currentPage - 1), viewElements * currentPage).map(el => (
                  <div key={el.id} className={`mb-4 justify-content-center p-1 col`}>
                    <ActivityCard activity={el} />
                  </div>
                ))
              }
              <div className="w-100 justify-content-center pt-2 roadtrip__pagination">
                <Pagination itemsNumber={numberPages || 1} itemsPerPage={viewElements} onChange={(page) => setCurrentPage(page + 1)} />
              </div>
            </div>
        }
      </div>
    </div>
  )
}

const SearchViewPageMobile = ({ children, header }) => {
  return (
    <BottomSheet
      data-body-scroll-lock-ignore="true"
      open
      blocking={false}
      defaultSnap={({ maxHeight }) => maxHeight / 5}
      header={header}
      snapPoints={({ maxHeight }) => [
        maxHeight - maxHeight / 6,
        90
      ]}
      className="bottom-sheet"
    >
      {children}
    </BottomSheet>
  )
}

const SearchViewPage = ({ search, categoryIcon, touristicSites, loading, position, searchInThisZone, handleFilters, scrollToTop }) => {
  const { t } = useTranslation(["henriTrip"])
  const [widthState, setWidthState] = useState(window.innerWidth);

  useDocumentTitle(`${t("searchComponent.documentTitle")} ${search ? "• " + search : ""} • Henri Trip`);

  useEffect(() => {
    function handleResize() {
      setWidthState(window.innerWidth)
    }
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize)
  }, []);

  const [loginModal, setLoginModal] = useState(false);
  const openLoginModal = () => {
    setLoginModal(true);
  }

  const [suggestModal, setSuggestModal] = useState(false);
  const openSuggestModal = () => {
    setSuggestModal(true);
  }

  const closeSuggestModal = () => {
    setSuggestModal(false);
  }

  const [categoriesFilter, setCategoriesFilter] = useState([
    {
      key: 'Activité',
      text: t("general.category.activity"),
      value: 53,
      selected: false
    },
    {
      key: 'Site Religieux',
      text: t("general.category.religiousSite"),
      value: 54,
      selected: false
    },
    {
      key: 'Parc / Jardin',
      text: t("general.category.parkGarden"),
      value: 55,
      selected: false
    },
    {
      key: 'Oenotourisme / Spiritourisme',
      text: t("general.category.wineTourisme"),
      value: 56,
      selected: false
    },
    {
      key: 'Chateaux / Monument historique',
      text: t("general.category.historicalMonument"),
      value: 57,
      selected: false
    },
    {
      key: 'Grotte / Site préhistorique',
      text: t("general.category.cavePrehistoric"),
      value: 58,
      selected: false
    },
    {
      key: 'Musée / Exposition',
      text: t("general.category.museum"),
      value: 59,
      selected: false
    },
    {
      key: 'Bien-être',
      text: t("general.category.wellness"),
      value: 60,
      selected: false
    },
    {
      key: 'Parc d\'attraction / Parc animalier',
      text: t("general.category.park"),
      value: 61,
      selected: false
    },
    {
      key: 'Le choix d\'Henri',
      text: t("general.category.henriChoice"),
      value: 62,
      selected: false
    },
  ])


  const [openFilter, setOpenFilter] = useState(false);

  const closeMenu = () => {
    setOpenFilter(false);
  };

  const refreshFilters = () => {
    setCategoriesFilter(state => state.map(el => {
      return { ...el, selected: false }
    }))
  }

  const changeFilter = () => {
    const finalFilter = [];
    categoriesFilter.map(el => {
      if(el.selected === true) {
        finalFilter.push(el.value)
      }
    })
    closeMenu();
    return handleFilters(finalFilter)
  }

  const handlerClick = (value) => {
    setCategoriesFilter(state => state.map(el => {
      if (el.value === value) {
        return { ...el, selected: !el.selected };
      } else {
        return el
      }
    }))
  }

  const openCategoriesFilter = () => {
    setOpenFilter(!openFilter);
  }

  return (
    <ThemeProvider theme={theme}>
      <ModalForm
        isOpen={loginModal}
        title={t("register.title", { ns: "navbar" })}
        onClose={() => setLoginModal(false)}
      >
        <Login modal={true} handleSubmit={() => setLoginModal(false)} handleClose={() => setLoginModal(false)} />
      </ModalForm>

      <Modal
        data-body-scroll-lock-ignore="true"
        className="suggest-modal"
        show={suggestModal}
        onHide={closeSuggestModal}
        centered
        size="lg"
      >
        <Suggest onHide={() => setSuggestModal(false)} />
      </Modal>
      <div className="search-view-page row m-0">
        {
          widthState > PHONE_WIDTH ?
            <ResultListView
              displayTitle={true}
              touristicSites={touristicSites}
              loading={loading}
              categoryIcon={categoryIcon}
              search={search}
              noTopPad="pt-0"
              handleFilters={handleFilters}
              scrollToTop={scrollToTop}
            />
            :
            <>
              {
                openFilter ?
                  <div className="filterDiv">
                    <div className="row p-3 align-items-center bg-light">
                      <div className="col d-flex justify-content-start">
                        <Icon size="large" name='close' onClick={() => closeMenu()} />
                      </div>
                      <div className="col">
                        <h4>Filtres</h4>
                      </div>
                      <div className="col">
                        <u><h4 onClick={() => refreshFilters()}>Effacer</h4></u>
                      </div>
                    </div>
                    <div className="container mt-2">
                       <div className="row justify-content-center">
                         <h1>Expériences à proximité</h1>
                       </div>
                        <div className="row mt-2">
                          {
                            categoriesFilter.map(el => (
                              <div className="col-12">
                                <CategoriesFilter
                                  key={el.value}
                                  value={el.value}
                                  name={el.text}
                                  selected={el.selected}
                                  handleClick={handlerClick}
                                />
                              </div>
                            ))
                          }
                        </div>
                        <div className="row mt-4 justify-content-center">
                          <Button title="Afficher les résultats" onClick={changeFilter} />
                        </div>
                    </div>

                  </div>
                  :
                  <></>
              }
              <SearchViewPageMobile header={<HeaderMobile
                mobile
                noTopPad={"pt-1"}
                search={search}
                handleFilters={handleFilters}
              />}>
                <ResultListView
                  displayTitle={false}
                  touristicSites={touristicSites}
                  loading={loading}
                  categoryIcon={categoryIcon}
                  search={search}
                  noTopPad="pt-1"
                  className="mb-5"
                  handleFilters={handleFilters}
                  scrollToTop={scrollToTop}
                  openCategoriesFilter={openCategoriesFilter}
                />
              </SearchViewPageMobile>
            </>
        }

        <div className="maps col-12 col-md-6 px-0" >
          {
            position &&
            <Suspense fallback={<Loader active className="mt-4 position-fixed" />}>
              <Map
                openLoginModal={openLoginModal}
                openSuggestModal={openSuggestModal}
                markers={touristicSites}
                centerPos={position}
                searchInThisZone={(lat, long, sw, ne) => searchInThisZone(lat, long, sw, ne)}
              />
            </Suspense>

          }
        </div>
      </div>
    </ThemeProvider>

  );
};

export default SearchViewPage;
