import {TouristicSiteCard} from "../touristicSiteCard";
import React from "react";

const TouristicSiteElement = ({openTsModal, el}) => {
  return (
    <div className="roadtrip-elements py-2">
      <div className="roadtrip-element" style={{opacity: 1}}>
        <div className="roadtrip-element__obj">
          <TouristicSiteCard openTsModal={openTsModal}  touristicSite={el} roadTripPage={true}/>
        </div>
      </div>
    </div>
  )
}

export default TouristicSiteElement;