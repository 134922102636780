import useRequest from 'hooks/use-request';
import RegisterPage from 'pages/AuthPage/RegisterPage';
import React, { useState } from 'react';
import { API_FACEBOOK_LOGIN, API_REGISTER, API_GOOGLE_LOGIN } from "utils/api";
import RegisterModal from "../../components/formModal/RegisterModal";
import { addToken } from "../../actions/token";
import { addUser } from "../../actions/user";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { useLocation } from "react-router";

const Register = ({ modal, handleClose }) => {
  const history = useHistory();
  const [handleRequest] = useRequest();
  const [error, setError] = useState("");
  const [success, setSuccess] = useState(false)
  const dispatch = useDispatch();
  const query = new URLSearchParams(useLocation().search)

  const handleSubmit = (v) => {
    handleRequest("post", API_REGISTER, v, {}, (data, status) => {
      if (status === "Done") {
        setSuccess(true)
      } else {
        setError(data)
        console.log(data)
      }
    })
  }

  


  const handleFacebookSubmit = (v) => {
    const auth = {
      provider: "facebook",
      uid: v.userID,
      data_access_expiration_time: v.data_access_expiration_time,
      code: v.signedRequest,
      token: v.accessToken,
      expires_at: v.expiresIn,
      expires: true
    }

    handleRequest('post', API_FACEBOOK_LOGIN, auth, {}, (data, status) => {
      if (status === "Done") {
        if (data.errors) {
          setError(data.errors)
          return;
        }
        dispatch(addToken(data.token));
        const userData = {
          ...data.user,
          ...data.profile,
        };
        dispatch(addUser(userData));
        if (modal) {
          handleClose()
          history.push('/thanks-you')
        } else {
          history.push('/thanks-you')
        }
      }
    })
  }

  const handleGoogleSubmit = (v) => {

    const auth = {
       
      provider: "google", 
      access_token: v.access_token,
      expires_at:  3000000,
      code: v.code 
    }

    handleRequest('post', API_GOOGLE_LOGIN, auth, {}, (data, status) => {

      if (status === "Done") {
        if (data.errors) {
          setError(data.errors)
          return;
        }
        dispatch(addToken(data.token));
        const userData = {
          ...data.user,
          ...data.profile,
        };
        dispatch(addUser(userData));
        if (modal) {
          handleClose()
          history.push('/thanks-you')
        } else {
          history.push('/thanks-you')
        }
      }
    })
  }


  return (
    modal ?
      <RegisterModal success={success} handleSubmit={handleSubmit} handleFacebookSubmit={handleFacebookSubmit} handleGoogleSubmit={handleGoogleSubmit} error={error} />
      :
      <RegisterPage success={success} handleSubmit={handleSubmit} handleFacebookSubmit={handleFacebookSubmit} handleGoogleSubmit={handleGoogleSubmit} error={error} />
  );
};

export default Register;
