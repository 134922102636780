import React from "react"
import { Form, Message } from "semantic-ui-react";
import { useTranslation } from "react-i18next";


const ProfileForm = (props) => {

  const {t} = useTranslation(["henriTrip"])

  const genders = [
    {text: t("general.male"), value: "Male"},
    {text: t("general.female"), value: "Female"},
    {text: t("general.other"), value: "Other"}]

  if(props.currentStep !== 1) {
    return null
  }
  let today = new Date();
  today = today.getFullYear() + '-'
    + String(today.getMonth() + 1).padStart(2, '0') + '-'
    + String(today.getDate()).padStart(2,'0');

  return(
    <>
      <Form.Input
        id="firstname"
        required={true}
        name="firstname"
        type="name"
        onChange={props.inputChange}
        value={props.firstname}
        label={t("general.firstName")}
        placeholder={"Henri"}
      />
      <Form.Input
        id="lastname"
        required={true}
        name="lastname"
        type="name"
        onChange={props.inputChange}
        value={props.lastname}
        placeholder={"De Toulouse-Lautrec"}
        label={t("general.name")}
      />
      <Form.Select
        fluid
        id="gender"
        required={true}
        name="gender"
        onChange={props.inputChange}
        value={props.gender}
        options={genders}
        label={t("general.gender")}
        placeholder={t("general.gender")}/>
      <Form.Input
        id="birthdate"
        name="birthdate"
        type="date"
        min="1900-01-01"
        max={today}
        required={true}
        onChange={props.inputChange}
        value={props.birthdate}
        label={t("general.birthdate")}
      />
      <Message
        color="red"
        hidden={props.birthdateCorrect}
        header={t("register.errorBirthdate.header")}
        content={t("register.errorBirthdate.content")}
      />
    </>
  )
}

export default ProfileForm;