import styled from 'styled-components';
import {commonStyleMixin} from "./common";

const ButtonInputStyled = styled.input`
  ${commonStyleMixin}
  
  padding: 5px 16px;
  font-weight: 600;
`;

const ButtonInput = props => (
  <ButtonInputStyled
    type="button"
    { ...props }
  />
);

const SubmitButton = props => (
  <ButtonInput
    type="submit"
    { ...props }
  />
);

export {
  SubmitButton,
};

export default ButtonInput;