import useRequest from 'hooks/use-request';
import SearchViewPage from 'pages/SearchPage/SearchViewPage/SearchViewPage';
import React, {useEffect, useState} from "react";
import {useSelector} from 'react-redux';
import {ArrayParam, NumberParam, StringParam, withDefault, withQueryParams} from "use-query-params";
import {API_GET_TOURISTIC_SITES} from 'utils/api';
import {mediasTyped} from "../../../utils/media";

const SearchView = ({query, setQuery}) => {
  const {
    latitude,
    longitude,
    sw,
    ne,
    place,
    start_date,
    end_date,
    category
  } = query;

  const config = useSelector(state => state.data);
  const [handleRequest, status] = useRequest();
  const [TSResults, setTSResults] = useState([]);

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    if (latitude && longitude) {
      setQuery({latitude: latitude, longitude: longitude, place: place})
      handleRequest("get", API_GET_TOURISTIC_SITES(longitude, latitude, sw, ne, 48, start_date, end_date, place, category), {}, config, (data, status) => {
        if (status === "Done") {
          const parsedResults = data?.map((el) => {
            el.henri_certified = !!el.agency_id;
            el.category = el.categories[0] || {
              color: "#EF7E26",
              id: 53,
              title: "Activité",
              url: "https://general-henri-1-paris.s3.fr-par.scw.cloud/categories/activites_sportives.jpg"
            };
            el.image = mediasTyped(el.medias).image?.[0]?.url;
            el.feedbacks = {
              note: el.avg_note,
              len: el.notes_counter
            };
            return el;
          });
          setTSResults(parsedResults);
        } else if (status === "Error") {
          console.error(data);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [latitude, longitude, sw, ne, category])

  const searchInThisZone = (lat, long, sw, ne) => {
    setQuery({latitude: lat, longitude: long, sw: sw, ne: ne}, 'pushIn')
  }

  const scrollToTop = ()=> {
    document.querySelector('[data-rsbs-scroll]')?.scrollTo(0, 0)
  }

  const handleFilters = (cat) => {
    setQuery({category: cat}, 'pushIn')
  }

  return (
    <SearchViewPage
      search={place}
      touristicSites={TSResults}
      loading={status === "Loading"}
      position={{latitude: latitude, longitude: longitude}}
      searchInThisZone={(lat, long, sw, ne) => searchInThisZone(lat, long, sw, ne)}
      handleFilters={handleFilters}
      scrollToTop={scrollToTop}
    />
  );
};

export default withQueryParams(
  {
    latitude: withDefault(NumberParam, 48.85658),
    longitude: withDefault(NumberParam, 2.35183),
    sw: StringParam,
    ne: StringParam,
    place: StringParam,
    start_date: StringParam,
    end_date: StringParam,
    category: ArrayParam
  }, SearchView);
