import React from 'react';

export const cartContext = React.createContext({});

export const CART_REDUCER_TYPES = {
    ADD_ELEMENT: "ADD_CART_ELEMENT",
    SET_CART: "SET_CART_ELEMENTS",
    REMOVE_CART: "REMOVE_CART_ELEMENTS",
    REMOVE_ELEMENT: "REMOVE_CART_ELEMENT",
    UPDATE_ELEMENT: "UPDATE_CART_ELEMENT"
}

const cartReducer = (state, action) => {
    switch (action.type) {
        case CART_REDUCER_TYPES.ADD_ELEMENT:
            return {
                ...state,
                cart: {
                    ...state.cart,
                    elements: [
                        ...state.cart?.elements,
                        action.payload
                    ]
                }
            };
        case CART_REDUCER_TYPES.REMOVE_CART:
            return {
                ...state,
                cart: undefined
            };
        case CART_REDUCER_TYPES.REMOVE_ELEMENT:
            return {
                ...state,
                cart: {
                    ...state.cart,
                    elements: state.cart.elements.filter(el => el.el.id !== action.payload)
                }
            };
        case CART_REDUCER_TYPES.SET_CART:
            return {
                ...state,
                cart: action.payload
            };
        case CART_REDUCER_TYPES.UPDATE_ELEMENT:
            return {
                ...state,
                cart: {
                    ...state.cart,
                    elements: state.cart.elements.map(el => {
                        if (el.el.id === action.payload.el.id) {
                            return action.payload;
                        }

                        return el;
                    })
                }
            }
        default:
            return state;
    }
}

const CartProvider = ({ children }) => {
    const [stripe, setStripe] = React.useState({});
    const [cart, dispatch] = React.useReducer(cartReducer, {})

    return (
        <cartContext.Provider value={{
            cart,
            dispatch,
            stripe,
            setStripe
        }}>
            {children}
        </cartContext.Provider>
    );
}

export default CartProvider;