import AmbassadorDashboardPage from 'pages/UserPage/AmbassadorDashboardPage/AmbassadorDashboardPage';
import React from 'react';




const AmbassadorDashboard = () => {

  return (
    <AmbassadorDashboardPage/>
  );
};

export default AmbassadorDashboard;