import {useEffect, useState} from "react";
import styled from "styled-components";
import {breakpoint} from "styled-components-breakpoint";
import {Formik} from "formik";
import * as Yup from "yup";
import useRequest from "../../../hooks/use-request";
import {API_POST_EXPERIENCE_MESSAGE} from "../../../utils/api";

import {REQUIRED_MESSAGE} from "../../../utils/texts";

import Button from "../../../_ui/_v2/components/Button";
import {TextArea, TextInput} from "../../../_ui/_v2/components/FormElements";

import { ReactComponent as MessageIcon } from "../../../../src/assets/img/logo/message-icon.svg";
import { ReactComponent as SendIcon } from "../../../assets/img/logo/enveloppe-icon.svg";
import {useTranslation} from "react-i18next";

const ConfirmationFormSubmittedStyled = styled.div `
  color: green;
  font-size: 0.9em;
  
  ${breakpoint('md')`
  font-size: 1em;  
  `};
`;

const AnimatedConfirmationForm = () => {
  return(
    <>
      <span>
        Votre message a bien été envoyé !
      </span>
      <br></br>
      <span style={{color: "black", fontWeight: "500"}}>
        Vous recevrez une réponse de la part d'HenriTrip prochainement.
      </span>
    </>
  );
};

const MessageFormStyled = styled.form`
  width: 100%;
  
  input, textarea {
    width: 100%;
    padding: 1em;
  }
  
  textarea {
    margin-top: 1em;
  }
`;


const ButtonSubmit = styled(Button)`
  margin: 20px 0 20px 0.5em;
  padding: 4px 16px;
  font-size: 20px;
  position: relative;
  height: 34px;
  width: 114px;
  background: ${props => props.theme.colors.white};
  color: ${props => props.send ? props.theme.colors.white : props.theme.colors.black};
  border-radius: 5px;
  border: 1px solid;

  ${breakpoint('md')`
    margin-left: 0
  `}
  
  &:before {
    transition: all 1.0s cubic-bezier(0.68, -0.55, 0.265, 1.55);
    height: 100%;
    content: ' ';
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 3px;
    width: 0;
    background-color: #024F91;

    ${props => props.send && `
      width: 100%;
    `}
  }

  svg {
    color: ${props => props.theme.colors.white};
    filter: grayscale(1);
  }
`;

const ButtonForm = ({confirmationModal, ...rest}) => {
  const {t} = useTranslation(["experiences"]);
  const [buttonIcon, setButtonIcon] = useState(confirmationModal);

  useEffect(()=>{
      setButtonIcon(confirmationModal);
  },[confirmationModal]);

    return (
        <div {...rest}>
          <ButtonSubmit
            type='submit'
            send={confirmationModal}
            disabled={confirmationModal}
          >
            {
              buttonIcon
                ? <SendIcon height="25" width="25" />
                : `${t("message-form.button")}`
            }
          </ButtonSubmit>
        </div>
    );
};

export const MessageForm = ({experience_title}) => {
  const {t} = useTranslation(["experiences"]);
  const [handleRequest] = useRequest();
  const [confirmationModal, setConfirmationModal] = useState(null);
  const email = {
    name: 'email',
    type: 'email',
    placeholder: t("message-form.email"),
    required: true,
  };
  const text = {
    name: 'text',
    placeholder: t("message-form.text"),
    required: true,
  };
  const handleSubmit = (values, formikHelpers) => {
    const data = {
      experience_title: experience_title,
      ...values,
    }
    handleRequest("post", API_POST_EXPERIENCE_MESSAGE, data, {}, (data, status) => {
      if (status === "Done") {
        setConfirmationModal(true);
        setTimeout(() => {
          formikHelpers.resetForm();
          setConfirmationModal(false);
        }, 5000);
      }
    })
  };

  return(
    <Formik
      initialValues={{email: '', text: ''}}
      validationSchema={Yup.object().shape(
        {
          [email.name]: Yup.string().required(REQUIRED_MESSAGE),
          [text.name]: Yup.string().required(REQUIRED_MESSAGE)
        }
      )}
      onSubmit={handleSubmit}
      validateOnMount={false}
      validateOnChange={false}
      validateOnBlur={false}
    >
      {({values, handleSubmit, ...props}) => {
        return (
          <MessageFormStyled onSubmit={handleSubmit}>
            <TextInput
              {...email}
              {...props}
              value={values[email.name]}
              hasError={props.errors[email.name]}
              errorMessage={props.errors[email.name]}
              onChange={props.handleChange}
            />
            <TextArea
              {...text}
              {...props}
              rows={10}
              value={values[text.name]}
              hasError={props.errors[text.name]}
              errorMessage={props.errors[text.name]}
              onChange={props.handleChange}
            />
            <div style={{
              display: 'flex',
              justifyContent: `${confirmationModal ? 'space-between' : 'center' }`
            }}>
              {
                props.isValid && confirmationModal && (
                  <ConfirmationFormSubmittedStyled>
                    <AnimatedConfirmationForm>
                    </AnimatedConfirmationForm>
                  </ConfirmationFormSubmittedStyled>
                )
              }
                  <ButtonForm valid={props.isValid} confirmationModal={confirmationModal}/>
            </div>
          </MessageFormStyled>
        )}}
    </Formik>
  );
};

const MessageSectionStyled = styled.div`
  h2 {
    text-align: left;
  }

  ${breakpoint('md')`
    h2 {
      text-align: center;
    }
  `}
`;

const MessageSectionFormStyled = styled.div`
  display: grid;
  grid-template-columns: 3fr;
  
  h2 {
    text-align: left;
  }
  
  ${breakpoint('md')`
    grid-template-columns: 3fr 2fr;
    h2 {
      margin: 0 auto;
    }
  `}
`;

const SVGContainer = styled.div`
  display: none;
  max-width: 100%;
  max-height: 100%;
  margin: auto;
  padding: 10px;
  
  svg {
    max-width: 100%;
    max-height: 300px;
  }
  
  ${breakpoint('md')`
    display: block;
  `}
`;
const MessageSection = ({roadtrip, translation, ...rest}) => {
  const {t, i18n} = useTranslation(['experiences']);
  const title = roadtrip.title?.[`text_${i18n.language}`] || roadtrip.title?.title_fr;

  return (
    <MessageSectionStyled {...rest}>
      <h2>{t("message-form.title")}&nbsp;:</h2>
      <MessageSectionFormStyled>
        <MessageForm
          experience_title={title}
        />
        <SVGContainer>
          <MessageIcon />
        </SVGContainer>
      </MessageSectionFormStyled>
    </MessageSectionStyled>
  );
};

export default MessageSection;