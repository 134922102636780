import styled from "styled-components";
import { breakpoint } from "styled-components-breakpoint";
import SVG from "react-inlinesvg";
import SliderContainerWithArrowNavigation from "../../../_ui/_v2/components/SliderContainerWithArrowNavigation";
import categories from "../../../utils/categories";
import Button from "../../../_ui/_v2/components/Button";
import useTouristicSiteTranslation from "../../../hooks/touristic_sites/useTouristicSiteTranslation";
import {useState,useContext} from "react";
import FullScreenNavigationModal from "../../../_ui/_v2/components/FullScreenNavigationModal";
import TouristicSiteForm from "../../../_forms/_components/avpm/TouristicSiteForm";
import PageContainer from "../../../_ui/_v2/components/PageContainer";
import {ReactComponent as PencilIcon} from "../../../assets/img/logo/pencil-icon.svg";
import {API_ORIGINAL_BLOB_URL,API_PATCH_TOURISTIC_SITE} from "../../../utils/api";
import mainImage from "../../../utils/touristic_sites/mainImage";
import TouristicSiteContext from '../../../_contexts/TouristicSiteContext';
import ActivityContext from "../../../_contexts/ActivityContext";
import {useTranslation} from "react-i18next";

const DayActivityStyled = styled.div`
  position: relative;
  box-sizing: border-box;
  box-shadow: 0px 10px 13px -7px #000000, 16px 16px 23px -2px rgb(0 0 0 / 17%);
  border-radius: 1rem;
  width: 14rem;
  height: 100%;

  ${props => !(props.site?.title_fr || props.site?.title) && `
    filter: blur(4px) !important;
    cursor: default;
  `}
  
  ${props => props.selected && `
    border: 2px solid ${(props.site?.categories?.[0] || props.site?.category).color};
    border-radius: 1em;
  `}    

  > div {
    position: absolute; 
    bottom: 10%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    width: 100%;
    padding: 5px 10px;

  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 1rem;
  }

  span {
    color: ${props => props.theme.colors.white};
    font-weight: 700;
    font-size : 1.2em;
    margin-left: 0.5em;
  }

  ${breakpoint('md')`
    width: 19rem;

    span {
      font-size: 1.2em;
    }
  `}
`;

const SVGContainer = styled.div`
  display: ${props => props.category || props.custom_category_icons ? 'block' : 'none'};
  padding: 0.4em;
  background-color: ${props => props.theme.colors.white};
  border-radius: 100%;
  
  img {
    max-width: 20px;
    max-height: 20px;
    border-radius: 0;
  }
`;

const DayActivityEditButton = styled(Button)`
  background-color: ${props => props.theme.colors.white60};
  border-radius: 50%;
  color: ${props => props.theme.colors.darkGrey};
  
  svg {
    width: 30px;
    height: 30px;
    margin-bottom: .5em;
  }
`;

const DayActivityEdit = ({touristicSite, ...rest}) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);

  return (
    <DayActivityEditButton
      onClick={() => setModalIsOpen(true)}
      {...rest}
    >
      <PencilIcon/>
      Modifier
      {
        modalIsOpen && (
          <FullScreenNavigationModal
            TitleComponent={<h1>Modification de l'activité</h1>}
            isOpen={modalIsOpen}
            onRequestClose={(event) => {
              setModalIsOpen(false);
              event.stopPropagation();
            }}
          >
            <PageContainer style={{maxWidth: 500, padding: '1em'}}>
              <TouristicSiteForm
                touristicSite={touristicSite}
                onSuccess={() => setModalIsOpen(false)}
              />
            </PageContainer>
          </FullScreenNavigationModal>
        )
      }
    </DayActivityEditButton>
  );
};

const ActivityCard = ({activity, ...rest}) => {
  const image = mainImage(activity);
  const imageUrl = (
    image
      ? API_ORIGINAL_BLOB_URL(image.signed_id, image.filename)
      : 'https://general-henri-1-paris.s3.fr-par.scw.cloud/small-woman.jpg'
  );
  return (
    <DayActivityStyled site={activity} {...rest}>
      <img src={imageUrl} alt={activity.title} />
      {
        activity.title && (
          <div>
            <span>{activity.title}</span>
          </div>
        )
      }
    </DayActivityStyled>
  );
};

const InterestPointCard = ({visitSheet,  image, ...rest}) => {
  const category = visitSheet?.category || visitSheet?.categories?.[0];
  const custom_category_icon = category?.id ? categories.custom_icons[category.id] : undefined;
  const category_icon = category?.color ? categories.icons_with_current_color[category.color] : undefined;

  const {i18n} = useTranslation();
  const textKey = `text_${i18n.language}`;

  const title = visitSheet.title?.[textKey] || visitSheet.title?.text_fr;

  return (
    <DayActivityStyled site={visitSheet} {...rest}>
      <img src={image} alt={title} />
      {
        title && (
          <div>
            {/* ici il faut que j'ajoute la balise img */}
            <SVGContainer category={category} custom_category_icons={custom_category_icon}>
              {
                custom_category_icon ?
                  <img
                    src={custom_category_icon}
                    color={"black"}
                    alt={category.title}
                  />
                  :
                  <SVG
                    src={category_icon}
                    color={category?.color}
                    height="25px"
                    width="25px"
                  />
              }
            </SVGContainer>
            <span>{title}</span>
          </div>
        )
      }
    </DayActivityStyled>
  );
};

const DayActivity = ({ site, media, editable = false, onButtonClick, ...rest }) => {
  const {t} = useTranslation(["touristic-site"]);
  const category = site?.category || site?.categories?.[0];
  const custom_category_icon = category?.id ? categories.custom_icons[category.id] : undefined;
  const category_icon = category?.color ? categories.icons_with_current_color[category.color] : undefined;
  const image = media || mainImage(site);
  const imageUrl = (
    image
      ? API_ORIGINAL_BLOB_URL(image.signed_id, image.filename)
      : 'https://general-henri-1-paris.s3.fr-par.scw.cloud/small-woman.jpg'
  );
  const { title } = useTouristicSiteTranslation(site);

  const buttonVisible = !editable && onButtonClick && site.available_activities_count > 0;

  return (
    <DayActivityStyled site={site} {...rest}>
      <img
        src={imageUrl}
        alt={title}
      />
      {
        (custom_category_icon || category_icon) && (
          <div
            style={{
              position: 'absolute',
              top: '5%',
              right: '5%',
              width: 60,
              height: 50,
              background: 'none',
              boxSizing: 'border-box',
            }}
          >
            <SVGContainer
              category={category}
              custom_category_icons={custom_category_icon}
              style={{
                display: 'flex',
                alignItems: 'center',
                padding: '5px 10px',
                width: 40,
                height: 40,
              }}
            >
              {
                custom_category_icon ?
                  <img
                    src={custom_category_icon}
                    color={"black"}
                    alt={category.title}
                  />
                  :
                  <SVG
                    src={category_icon}
                    color={category?.color}
                    height="25px"
                    width="25px"
                  />
              }
            </SVGContainer>
          </div>
        )}
      {
        editable && (
          <DayActivityEdit
            touristicSite={site}
            style={{
              position: 'absolute',
              inset: 0,
              margin: 'auto',
              width: '125px',
              height: '125px',
            }}
          />
        )
      }
      {
        title && (
          <div>
            <span>{title}</span>
          </div>
        )
      }
      {
        buttonVisible && (
          <Button
            rounded
            onClick={onButtonClick}
            style={{
              position: 'absolute',
              bottom: 0,
              transform: 'translateY(50%)',
              left: '5%',
              right: '5%',
            }}
          >
            {t("button.start_visit")}
          </Button>
        )
      }
    </DayActivityStyled>
  );
};


const DayActivitiesContainer = styled(SliderContainerWithArrowNavigation)`
  position: relative;
  height: 22rem;
  
  ${breakpoint('md')`
    height: 30rem;
  `}
`;

const DayActivitiesContainerObfuscate = styled.div`
  position: relative;
`;

const Obfuscate = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  inset: 0;
  background-color: ${props => props.theme.colors.darkGrey90};
  align-items: center;
  justify-content: center;
  gap: 1em;
  
  h3 {
    font-size: 24px;
    color: ${props => props.theme.colors.white};
  }
`;

const DayActivities = ({ sites, siteIndexSelected, onBuyButtonClick = () => {}, obfuscate = false, onCardClick, translation, ...rest }) => {
  const indexSelected = sites?.findIndex(site => site.id === siteIndexSelected);
  return (
    <DayActivitiesContainerObfuscate {...rest}>
      <DayActivitiesContainer
        indexSelected={indexSelected}
        onCardClick={onCardClick}
      >
        {
          sites.map((site, index) => (
            <DayActivity
              key={index}
              selected={site.id === siteIndexSelected}
              site={site}
              selectedMarker={siteIndexSelected}
              media={mainImage(site)}
            />
          ))
        }
      </DayActivitiesContainer>
      {
        obfuscate && (
          <Obfuscate>
            <h3>Acheter l’Expérience</h3>
            <Button
              rounded
              onClick={onBuyButtonClick}
            >
              {translation("title-component.button")}
            </Button>
          </Obfuscate>
        )
      }
    </DayActivitiesContainerObfuscate>
  );
};

const DayActivitiesSection = ({ selectedDay, sites, onBuyButtonClick = () => {}, obfuscate = false, translation, ...rest }) => (
  <div>
    <h3>{`${sites.length} ${translation("activities")} n°${selectedDay + 1}`}</h3>
    <DayActivities
      sites={sites}
      obfuscate={obfuscate}
      onBuyButtonClick={onBuyButtonClick}
      translation={translation}
      {...rest}
    />
  </div>
);

export {
  DayActivity,
  InterestPointCard,
  ActivityCard
};

export default DayActivitiesSection;