import React, {Fragment, useContext, useEffect, useLayoutEffect, useRef, useState} from "react";
import styled, {ThemeContext} from "styled-components";
import breakpoint from "styled-components-breakpoint";
import TabbedNavigation from "../../../_ui/_v2/components/TabbedNavigation";
import SliderContainer from "../../../_ui/_v2/components/SliderContainer";
import Button from "../../../_ui/_v2/components/Button";
import {ActivityCard, DayActivity, InterestPointCard} from "./DayActivitiesSection";
import TipsSection from "./TipsSection";
import MainVisitCardStyled, {
  EmptyInterestPointCard,
  EmptyVisitCard
} from "../../../components/_v2/touristic-site/MainVisitCard";
import medias from '../touristic-site/Medias';
import MediaToShow from '../../_v2/touristic-site/MediaToShow';
import { ReactComponent as GoogleMapsIcon } from '../../../assets/img/logo/google-maps.svg';
import { ReactComponent as CopyPhoneIcon } from '../../../assets/img/logo/copy_Phone.svg';
import { ReactComponent as CopyWebsiteIcon } from '../../../assets/img/logo/copy_website.svg';
import useTouristicSiteTranslation from "../../../hooks/touristic_sites/useTouristicSiteTranslation";
import TouristicSiteMainInformationForm from "../../../_forms/_components/avpm/TouristicSiteMainInformationForm";
import TouristicSitePricingForm from "../../../_forms/_components/avpm/TouristicSitePricingForm";
import TouristicSiteAccessTransportForm from "../../../_forms/_components/avpm/TouristicSiteAccessTransportForm";
import TouristicSiteTipsForm from "../../../_forms/_components/avpm/TouristicSiteTipsForm";
import TouristicSiteOpeningHoursForm from "../../../_forms/_components/avpm/TouristicSiteOpeningHoursForm";
import TouristicSiteAmbassadorReviewForm from "../../../_forms/_components/avpm/TouristicSiteAmbassadorReviewForm";
import Collapsible from '../../../_ui/_v2/components/Collapsible';
import ToolTip from "../../../_ui/_v2/components/ToolTip/ToolTip";
import mediasModify from "../touristic-site/MediasToModifiy";
import {
  API_ORIGINAL_BLOB_URL,
  API_PATCH_ACTIVITY,
  API_PATCH_TOURISTIC_SITE,
  API_PATCH_VISIT_SHEET
} from "../../../utils/api";
import ActivityContext from "../../../_contexts/ActivityContext";
import mainImage from "../../../utils/touristic_sites/mainImage";
import InterestPointContext from "../../../_contexts/InterestPointContext";
import TouristicSiteContext, {TouristicSiteProvider} from "../../../_contexts/TouristicSiteContext";
import {useTranslation} from "react-i18next";
import Reviews from "_ui/_v2/components/Reviews";
import ChatGPTButton from "../touristic-site/ChatGPT";
import TouristicSitePhotoSpotForm from "../../../_forms/_components/avpm/TouristicSitePhotoSpotForm";

const TabWrapper = styled.div`
  display: grid;
  grid-gap: 2em;

  ${breakpoint('md')`
    grid-template-columns: auto 45%;
  `}
`;

const IllustrationWrapper = styled.div`
  display: ${props => !props.aside ? 'block' : 'none' };

  ${breakpoint('md')`
    display: ${props => props.aside ? 'block' : 'none' };
  `}
`;

const BtnReservationContainer = styled.div `
  text-align: center;
`;

const DescriptionStyled = styled.p `
  color: black;
  white-space: pre-wrap;
  
  ${breakpoint('md')`
    overflow-y: auto;
    height: 70%;
  `}
  `
;

const InformationStyled = styled.div `
  text-align: left;
  overflow-y: auto;
`;

const SitesStyled = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-grow: 1 !important;
  align-items: center;
  gap: 20px;
  margin-bottom: 1em;
  margin-top: 1em;
  
  ${breakpoint('md')`
    display: none;
  `}
`;

const SitesStyledDesktop = styled.div`
  width: 16em;
  margin-left: 20px; 
  margin-top: 20px; 
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-grow: 1 !important;
  align-items: center;
  gap: 20px;
`;

const DayActivityDetailsStyled = styled.div`
  padding-bottom: 3rem;
`;

const ButtonReservation = styled(Button) `
  display: flex;
  background-color: ${props => props.theme.colors.darkGrey};
  color: ${props => props.theme.colors.white};
  height: 42px;
  width: 118px;
  align-items: center;
  border-radius: 5px;
  justify-content: center;
`;

const GroupSpanStyled = styled.div `
  padding-bottom: .75em;
  padding-top: .75em;
  display: grid;
  grid-template-columns: 1fr;
  font-weight: 800;
`;

const HeaderDataTouristicSiteStyled = styled.div `
  display: grid;
  grid-template-columns: auto 90px;
  align-items: center;
  
  ${breakpoint('md')`
     grid-template-columns: auto 150px;
  `}
`;

const ContainerStyled = styled.div `
  max-width: 70%;
  > a {
    text-decoration: revert;
  }
`;

const Information = ({site}) => {
  const {t, i18n} = useTranslation(["touristic-site"]);
  const { title, description } = useTouristicSiteTranslation(site);
  return (
    <InformationStyled>
      <h2>{title}</h2>
      <GroupSpanStyled>
        {site?.phone_number &&
          <HeaderDataTouristicSiteStyled>
            <div>
              <span>{`${t("information.telephone")} : `}</span>
              <span>{site?.phone_number}</span>
            </div>
            <ToolTip dataToShow={site?.phone_number} elementToClick={<CopyPhoneIcon />} />
          </HeaderDataTouristicSiteStyled>
        }
        {site?.website_url &&
          <HeaderDataTouristicSiteStyled>
            <ContainerStyled>
              <span>{`${t("information.website")} : `}</span>
              <a target='_blank' href={site?.website_url}>{t("information.see_website")}</a>
            </ContainerStyled>
            <ToolTip redirect={true} dataToShow={site?.website_url} elementToClick={<CopyWebsiteIcon />} text={["Aller sur le site","Redirection"]}/>
          </HeaderDataTouristicSiteStyled>
        }
        {site?.duration_minutes &&
          <HeaderDataTouristicSiteStyled>
            <div>
              <span>Estimation du temps de la visite :</span>
            </div>
            <div style={{width: "10%"}}>
              {
                site.duration_minutes >= 60 && (
                  <span>{Math.trunc(site.duration_minutes / 60)}h</span>
                )
              }
              {
                site.duration_minutes > 60 && Math.round(site.duration_minutes % 60) > 0 && (
                  <span>{String(Math.round(site.duration_minutes % 60)).padStart(2, '0')}</span>
                )
              }
              {
                site.duration_minutes < 60 && (
                  <span>{Math.round(site.duration_minutes % 60)}min</span>
                )
              }
            </div>
          </HeaderDataTouristicSiteStyled>}
      </GroupSpanStyled>
      <DescriptionStyled>
        {description}
      </DescriptionStyled>
      <DescriptionStyled>
        {site?.specificities?.[`text_${i18n.language}`] || site?.specificities?.text_fr}
      </DescriptionStyled>
    </InformationStyled>
  );
};

const Prices = ({site}) => {
  const {t, i18n} = useTranslation(["touristic-site"]);
  const { title } = useTouristicSiteTranslation(site);
  const price_list = JSON.parse(site?.price_list || '[]');

  return (
    <div className="align-left">
      <h2>{title}</h2>
      {(!price_list || price_list.length <= 0)
        ? t("price.notice")
        : (
          <div>
            {
              price_list.map((price, index) => (
                <div
                  key={index}
                  style={{
                    display: 'grid',
                    gridTemplateColumns: '3fr 1fr',
                  }}
                >
                  <span>{price[`text_${i18n.language}`] || price.text}</span>
                  <span>{price.price}€</span>
                </div>
              ))
            }
          </div>
        )
      }
    </div>
  );
};

const groupBy = (items, key) => items.reduce(
  (result, item) => ({
    ...result,
    [item[key]]: [
      ...(result[item[key]] || []),
      item,
    ],
  }),
  {},
);

const OpeningHours = ({site}) => {
  const {t} = useTranslation(["touristic-site"]);
  const schedule = site.schedule ? JSON.parse(site?.schedule || '{}') : undefined;
  const scheduleGrouped = schedule
    ? groupBy(Object.keys(schedule).map((key) => ({day: key, value: schedule[key], valueForGroupBy: JSON.stringify(schedule[key])})), 'valueForGroupBy')
    : undefined;

  return (
    <div className="align-left">
      <h2>{t("schedule.title")}</h2>
      {(!scheduleGrouped || scheduleGrouped.length <= 0)
        ? t("schedule.notice")
        : (
          <div
            style={{
              maxWidth: 600,
              margin: '0 auto',
            }}
          >
            {
              Object.keys(scheduleGrouped).map((key, index) => (
                <div
                  key={index}
                  style={{
                    textAlign: 'center',
                    borderTop: index === 0 ? '1px solid grey' : 'none',
                    borderBottom: '1px solid grey',
                    padding: '.25em',
                  }}
                >
                  <div
                    style={{
                      fontWeight: 600,
                      fontSize: 22,
                    }}
                  >
                    {
                      scheduleGrouped[key]?.[0]?.value?.closed_noon
                        ? (
                          <>
                            <div>
                              {scheduleGrouped[key]?.[0]?.value?.open_morning} --> {scheduleGrouped[key]?.[0]?.value?.close_morning}
                            </div>
                            <div>
                              {scheduleGrouped[key]?.[0]?.value?.open_afternoon} --> {scheduleGrouped[key]?.[0]?.value?.close_night}
                            </div>
                          </>
                        ) : (
                          <div>
                            {scheduleGrouped[key]?.[0]?.value?.open_morning} --> {scheduleGrouped[key]?.[0]?.value?.close_night}
                          </div>
                        )
                    }
                  </div>
                  <div
                    style={{
                      fontWeight: 600,
                      fontSize: 14,
                    }}
                  >
                    <span>{scheduleGrouped[key].map(s => s.day).join(', ')}</span>
                  </div>
                </div>
              ))
            }
          </div>
        )
      }
    </div>
  );
};

const Access = ({site}) => {
  const {t, i18n} = useTranslation(["touristic-site"]);
  const { title } = useTouristicSiteTranslation(site);

  return (
    <div className="align-left">
      <h2 style={{margin: '0'}}>{title}</h2>
      <p>
        {site?.access_transport?.[`text_${i18n.language}`] || site?.access_transport?.text_fr || t("transport.notice")}
      </p>
    </div>
  );
};

const TouristicSiteChatGPT = ({site}) => {
  const { title } = useTouristicSiteTranslation(site);
  const { setTouristicSite } = useContext(TouristicSiteContext);

  // const handleSubmitForm = (values, actions) => {
  //   const token = localStorage.getItem("Authorization");
  //
  //   fetch(
  //     API_PATCH_TOURISTIC_SITE(site.id),
  //     {
  //       method: 'PATCH',
  //       cache: 'default',
  //       body: JSON.stringify(values),
  //       headers: {
  //         "Authorization": `Bearer ${token}`,
  //         "Content-Type": "application/json",
  //       },
  //       mode: 'cors',
  //     }
  //   )
  //     .then(res => res.json())
  //     .then(response => {
  //       setTouristicSite(response);
  //     })
  //     .catch(error => {
  //       console.error(error);
  //     });
  // };
  //
  // const formatAndPushData = (data) => {
  //   console.log(data);
  //
  //   const dataValues = Object.values(data);
  //
  //
  //   const indexes = [1, 2, 3, 4, 6, 7].concat(
  //     Array(dataValues.length - 9).map((_, index) => index + 9)
  //   );
  //
  //   const getValue = (stepValue) => {
  //     if (typeof stepValue === 'string') {
  //       return stepValue;
  //     }
  //     const newValue = Object.values(stepValue)[0];
  //     if (typeof newValue === 'string') {
  //       return newValue;
  //     }
  //     return JSON.stringify(newValue);
  //   }
  //
  //   const descriptions = Object.keys(site).filter((key) => (
  //     key.split('_')[0] === 'description' && key.split('_')[1] !== 'fr'
  //   ))
  //
  //   const values = {
  //     ...site,
  //     description_fr: getValue(dataValues[0]),
  //     access_transport: {
  //       text_fr: getValue(dataValues[5]),
  //     },
  //     ambassador_review: {
  //       text_fr: getValue(dataValues[8]),
  //     },
  //     touristic_site_tips: indexes.map(index => (
  //       {
  //         title: {
  //           text_fr: Object.keys(dataValues[index])[0],
  //         },
  //         description: {
  //           text_fr: getValue(dataValues[index]),
  //         },
  //       }
  //     ))
  //   };
  //
  //   descriptions.forEach(key => {
  //     values[key] = null
  //   });
  //
  //   console.log(values)
  // }

  return (
    <div className="align-left">
      <h2 style={{margin: '0'}}>{title}</h2>
      <ChatGPTButton
        id={site.id}
        // onChange={formatAndPushData}
      />
    </div>
  );
};


// const PhotoSpot = ({site}) => {
//   const {t, i18n} = useTranslation(["touristic-site"]);
//   const { title } = useTouristicSiteTranslation(site);
//
//   return (
//     <div className="align-left">
//       <h2 style={{margin: '0'}}>{title}</h2>
//       <p>
//         {site?.access_transport?.[`text_${i18n.language}`] || site?.access_transport?.text_fr || t("transport.notice")}
//       </p>
//     </div>
//   );
// };

const IllustrationComponent = ({centered, selectedIndex, setSelectedIndex, children, ...rest}) => (
  <SliderContainer
    withArrows
    withScale
    centered={centered}
    selectedIndex={selectedIndex}
    onSelectedIndexChange={setSelectedIndex}
    {...rest}
  >
    {children}
  </SliderContainer>
);

const StyledButton = styled.div`
  border-radius: 5px;
  width: 118px;
  button {
    background-color: white;
    border: 2px black solid;
    padding: 4px 8px 4px 0;
    color: black;
    
    a {
      color: black;
    }
  }
`;
const ButtonStyled = styled.div`
  align-items: center;
  border-radius: 5px;
`;

const ButtonToMaps = ({longitude, latitude}) => {
  const {t} = useTranslation(["touristic-site"]);
  return ( 
    <StyledButton>
      <Button>
        <ButtonStyled>
          <GoogleMapsIcon height="30" width="30"/>
          <a type="button" target="_blank" href={`http://maps.google.com/maps?q=${latitude},${longitude}`}>
          {t("button.itinerary")}
          </a>
        </ButtonStyled>
      </Button>
    </StyledButton>
  )
};

const ButtonPlanning = ({selectedSite}) => {
  const latitude = selectedSite?.latitude;
  const longitude = selectedSite?.longitude;
  const {t} = useTranslation(["touristic-site"]);

  return (
    <>
      {
        selectedSite?.ticket_link && (
          <BtnReservationContainer>
              <ButtonReservation
                onClick={() => window.open(selectedSite.ticket_link, '_blank')}
              >
                {t("button.reserve")}
              </ButtonReservation>
          </BtnReservationContainer>
        )
      }
      {
        longitude && latitude && (
          <ButtonToMaps longitude={longitude} latitude={latitude}/>
        )
      }
    </>
  );
};

const EditButton = styled(Button)`
  background-color: ${props => props.theme.colors.darkGrey};
`;

const CopyRight = ({credits, datatourisme_last_update}) => (
  credits && datatourisme_last_update && (
    <div
      className="small"
      style={{
        paddingTop: '3em',
        paddingBottom: '1em',
      }}
    >
      © {credits} - {datatourisme_last_update}
    </div>
  ) || null
);

const EditTabWrapper = ({editable, touristicSite, EditComponent, ReadComponent, translation}) => (
  <Collapsible
    collapsed={false}
  >
    {
      (collapsed, toggleCollapsed) => (
        <div style={{
          height: '100%',
          marginBottom: 'calc(4rem + 2em)',
        }}>
          {
            collapsed
              ? (
                <EditComponent
                  touristicSite={touristicSite}
                  onSuccess={() => toggleCollapsed()}
                />
              ) : (
                <>
                  <ReadComponent site={touristicSite} translation = {translation}/>
                  {
                    editable && ReadComponent !== EditComponent && (
                      <EditButton
                        rounded
                        onClick={toggleCollapsed}
                        style={{
                          display: 'block',
                          margin: '3em auto 0 auto',
                        }}
                      >
                        Modifier
                      </EditButton>
                    )
                  }
                  <CopyRight {...touristicSite}/>
                </>
              )
          }
        </div>
      )
    }
  </Collapsible>
);

const items = (site, IllustrationComponent, MediaComponent, isNotHome, editable, t) => {
  const items =  [
    {
      TitleComponent: t("tabulations.information"),
      ContentComponent: (
        <TabWrapper>
          {
            isNotHome
              ? MediaComponent
              : (
                <EditTabWrapper
                  key={'information'}
                  editable={editable}
                  touristicSite={site}
                  EditComponent={TouristicSiteMainInformationForm}
                  ReadComponent={Information}
                />
              )
          }
          {IllustrationComponent}
        </TabWrapper>
      ),
    },
    {
      TitleComponent: t("tabulations.price"),
      ContentComponent: (
        <TabWrapper>
          <EditTabWrapper
            key={'pricing'}
            editable={editable}
            touristicSite={site}
            EditComponent={TouristicSitePricingForm}
            ReadComponent={Prices}
          />
          {IllustrationComponent}
        </TabWrapper>
      ),
    },
    {
      TitleComponent: t("tabulations.transport"),
      ContentComponent: (
        <TabWrapper>
          <EditTabWrapper
            key={'access'}
            editable={editable}
            touristicSite={site}
            EditComponent={TouristicSiteAccessTransportForm}
            ReadComponent={Access}
          />
          {IllustrationComponent}
        </TabWrapper>
      ),
    },
    {
      TitleComponent: t("tabulations.schedule"),
      ContentComponent: (
        <TabWrapper>
          <EditTabWrapper
            key={'openingHours'}
            editable={editable}
            touristicSite={site}
            EditComponent={TouristicSiteOpeningHoursForm}
            ReadComponent={OpeningHours}
          />
          {IllustrationComponent}
        </TabWrapper>
      ),
    },
    {
      TitleComponent: t("tabulations.tips"),
      ContentComponent: (
        <TabWrapper>
          <EditTabWrapper
            key={'tips'}
            editable={editable}
            touristicSite={site}
            EditComponent={TouristicSiteTipsForm}
            ReadComponent={({site, ...rest}) => TipsSection({tips: site?.touristic_site_tips, ...rest})}
          />
          {IllustrationComponent}
        </TabWrapper>
      ),
    },
    {
      TitleComponent: t("tabulations.review"),
      ContentComponent: (
        <TabWrapper>
          <EditTabWrapper
            key={'review'}
            editable={editable}
            touristicSite={site}
            EditComponent={TouristicSiteAmbassadorReviewForm}
            translation = {t}
            ReadComponent={({site,translation, ...rest}) =>  Reviews(
              {
              avg_notes: site?.feedbacks?.note,
              notes_counter: site?.feedbacks?.len,
              road_trip_reviews :site?.feedbacks?.comments,
              ambassador_review: site?.ambassador_review,
              translation,
              ...rest})}
          />
          {IllustrationComponent}
        </TabWrapper>
      ),
    },
  ];

  if (editable) {
    items.push(
      {
        TitleComponent: t("tabulations.chat_gpt"),
        ContentComponent: (
          <TabWrapper>
            <EditTabWrapper
              key={'chatGPT'}
              editable={editable}
              touristicSite={site}
              EditComponent={TouristicSiteChatGPT}
              translation = {t}
              ReadComponent={TouristicSiteChatGPT}
            />
            {IllustrationComponent}
          </TabWrapper>
        ),
      },
    );
    items.push(
      {
        TitleComponent: t("tabulations.photo_spot"),
        ContentComponent: (
          <TabWrapper>
            <EditTabWrapper
              key={'photoSpot'}
              editable={editable}
              touristicSite={site}
              EditComponent={TouristicSitePhotoSpotForm}
              translation = {t}
              ReadComponent={TouristicSitePhotoSpotForm}
            />
            {IllustrationComponent}
          </TabWrapper>
        ),
      },
    );
  }

  return items;
};
  

//Tableau a creer pour pouvoir par la suite naviguer sur la page visite. Creer une OL et Li qui va permettre de lister les visites. Au click il faudra
// Renvoyer sur le composant basique avec le isMedia a true.
// Fermez les cahiers posez les stylos

// const VisitItemsToShow = (site, IllustrationComponent) =>


const DayActivityWrapperForVs = ({site, finalLap, index}) => {
  const media = mainImage(site);

  return(
    <InterestPointCard
      key={index}
      visitSheet={site}
      image={
        media
          ? API_ORIGINAL_BLOB_URL(media.signed_id, media.filename)
          : JSON.parse(site?.visit_sheet_contents?.[0]?.layout || '[]')?.[0]?.url || "https://general-henri-1-paris.s3.fr-par.scw.cloud/small-woman.jpg"
      }
      finalLap={finalLap}
      style={{width: '16em'}}
    />
  );
};

const editConfigs = {
  touristicSite: {
    activeStorageServiceName: 'touristic_site_scaleway',
    api: API_PATCH_TOURISTIC_SITE,
  },
  activity: {
    activeStorageServiceName: 'activity_scaleway',
    api: API_PATCH_ACTIVITY,
  },
  interestPoint: {
    activeStorageServiceName: 'activity_scaleway',
    api: API_PATCH_VISIT_SHEET,
  },
};

const DayActivityDetails = (
  {
    sites,
    finalLap = false,
    addCard = false,
    showAllVisitCards = false,
    cardToFix,
    selectedSiteIndex = 0,
    fullDetails = false,
    visitModalIsOpen = false,
    setFullDetails,
    editable = false,
    ...rest
  }
) => {
  const [selectedIndex, setSelectedIndex] = useState(selectedSiteIndex);
  const [isMedia, setIsMedia] = useState(true);
  const [isVisit, setIsVisit] = useState(false);
  const [isNotHome, setIsNotHome] = useState(false);
  const maRef2 = useRef(0);
  const [activeTab, setActiveTab] = useState(0);
  const [openMediaModal, setOpenMediaModal] = useState(false);
  const maRef3 = useRef(0);
  const theme = useContext(ThemeContext);
  const {activity} = useContext(ActivityContext);
  const {interestPoint} = useContext(InterestPointContext);
  const {t} = useTranslation(["touristic-site"]);

  useEffect(() => {
    setSelectedIndex(selectedSiteIndex || 0);
  }, [selectedSiteIndex]);

  useEffect(()=> {
    // setIsMedia(fullDetails && selectedIndex === 1 || visitModalIsOpen );
    setIsVisit(!finalLap && fullDetails && selectedIndex === 1);
  },[selectedIndex]);

  useEffect(()=> {
    setIsVisit(fullDetails && selectedIndex === sites.length);
  },[selectedIndex]);

  useEffect(()=> {
    if (isVisit){
      setIsMedia(false);
      setIsNotHome(false);
    }
    if (cardToFix){
      setIsMedia(false);
      setIsNotHome(true);
    }
    if(!isVisit && !isNotHome){
      setIsMedia(true);
    }
  },[isVisit, isNotHome]);

  const arrayOfSites = (
    sites.map((site, index) => (
      <DayActivity
        key={index}
        site={site}
        editable={editable}
        media={mainImage(site)}
        style={{width: '16em'}}
      />
    ))
  );

  //La const qui est utilisé quand on est sur la page activité du TS
  const allVisitCards =
    finalLap ? (
        sites.map((site, index) => (
            <DayActivityWrapperForVs
              key={index}
              site={site}
              finalLap={finalLap}
              style={{width: '16em'}}
            />
          )
        )
      )
      : (
        sites.map((site, index) => (
          <ActivityCard
            key={index}
            activity={site}
            style={{width: '16em'}}
          />
        )
      )
    );

  if (!finalLap && !addCard) {
    if (activity?.visit_sheets?.length > 0) {
      allVisitCards.push(
        <MainVisitCardStyled
          TitleComponent="Vos points d'intérêt"
          colorChip={theme.colors.interestPoint}
          key={sites.length}
        >
          {(activity?.visit_sheets || []).map((visitSheet, index) => {
            const layout = JSON.parse(visitSheet?.visit_sheet_contents?.[0]?.layout || "{}");
            const image = mainImage(visitSheet) ||
              visitSheet?.visit_sheet_contents?.[0]?.files?.filter(f => f.content_type.includes('image/'))?.find(img => img.blob_id === Object.values(layout)?.[0]?.blob_id);

            return (
              <div
                key={index}
                style={{
                  height: '100%',
                  width: '100%',
                  backgroundImage: `url("${image
                    ? API_ORIGINAL_BLOB_URL(image.signed_id, image.filename)
                    : 'https://general-henri-1-paris.s3.fr-par.scw.cloud/small-woman.jpg'
                  }")`,
                  backgroundRepeat: 'no-repeat',
                  backgroundPosition: 'center',
                  backgroundSize: 'cover',
                  borderRadius: 10
                }}
              />
            );
          })
          }
        </MainVisitCardStyled>
      );
    } else if (editable) {
      allVisitCards.push(
        <EmptyInterestPointCard key={sites.length}/>
      );
    }
  }

  //La const qui est utilisé quand on est sur la page home du site touristique
  const fullDetailsSite = [
    <DayActivity
      key={0}
      site={sites[0]}
      media={mainImage(sites[0])}
      editable={editable}
      onButtonClick={() => setSelectedIndex(1)}
      style={{width: '16em'}}
    />
  ];

  if (sites[0]?.activities?.length > 0) {
    fullDetailsSite.push(
      <MainVisitCardStyled
        TitleComponent='Vos visites'
        colorChip={theme.colors.activity}
        key={1}
      >
        {(sites[0]?.activities || []).map((activity, index) => {
          const image = mainImage(activity);

          return (
            <div
              key={index}
              style={{
                height: '100%',
                width: '100%',
                backgroundImage: `url("${image
                  ? API_ORIGINAL_BLOB_URL(image.signed_id, image.filename)
                  : 'https://general-henri-1-paris.s3.fr-par.scw.cloud/small-woman.jpg'
                }")`,
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
                backgroundSize: 'cover',
                borderRadius: 10
              }}
            />
          );
        })}
      </MainVisitCardStyled>
    );
  } else if (editable) {
    fullDetailsSite.push(
      <EmptyVisitCard key={1}/>
    );
  }
  //WrapSites va choisir sa valeur en fonction de l'etat de fulldetails. Si True il s'agit de la page media/TS
  const wrapSites = (
    fullDetails ? fullDetailsSite : arrayOfSites
  );

  const wrapperOfAllSites = (
    visitModalIsOpen ? allVisitCards : wrapSites
  );
  const [topState, setTopState] = useState(0);
  const [topMenu, setTopMenu] = useState(0);
  const AsideIllustrationComponent = (
    <IllustrationWrapper aside>
      <IllustrationComponent
        selectedIndex={selectedIndex}
        onSelectedIndexChange={setSelectedIndex}
        style={{height: '26em', paddingTop: '2em'}}
      >
        {wrapperOfAllSites}
      </IllustrationComponent>
      {
        wrapperOfAllSites === wrapSites &&
        <SitesStyledDesktop>
          <ButtonPlanning
            selectedSite={sites[selectedIndex]}
          />
        </SitesStyledDesktop>
      }
    </IllustrationWrapper>
  );

  useLayoutEffect(() => {
    const observer = new ResizeObserver((entries) => {
      for (const entry of entries) {
        setTopMenu(Math.max(maRef2.current?.offsetHeight + maRef2.current?.getBoundingClientRect()?.top, maRef2.current?.getBoundingClientRect()?.top) + 20)
      }
    });
    observer.observe(maRef2.current);

    return () => observer.disconnect();
  }, [maRef2]);

  const objectType = (
    finalLap
      ? 'interestPoint'
      : isNotHome
        ? 'activity'
        : 'touristicSite'
  );
  const editConfig = editConfigs[objectType];

  const MediaEditableComponent = (
    <MediaToShow
      finalLap={finalLap}
      topMenu={topMenu}
      topState={topState}
      isMedia={isMedia}
      isVisit={isVisit}
      experience={true}
      editable={editable}
      setOpenMediaModal={setOpenMediaModal}
      openMediaModal={openMediaModal}
      setSelectedIndex={setSelectedIndex}
      setIsMedia={setIsMedia}
      setIsNotHome={setIsNotHome}
      isNotHome={isNotHome}
      sites={sites}
      selectedIndex={selectedIndex}
      items={
        mediasModify(
          selectedIndex,
          interestPoint?.id ? interestPoint : activity || sites[selectedIndex],
          <></>,
          isVisit,
          editConfig,
          isMedia
        )
      }
    />
  );

  const MediaComponent = (
    <MediaToShow
      finalLap={finalLap}
      topMenu={topMenu}
      topState={topState}
      isMedia={isMedia}
      isVisit={isVisit}
      experience={true}
      editable={editable}
      setOpenMediaModal={setOpenMediaModal}
      openMediaModal={openMediaModal}
      setSelectedIndex={setSelectedIndex}
      setIsMedia={setIsMedia}
      setIsNotHome={setIsNotHome}
      isNotHome={isNotHome}
      sites={sites}
      selectedIndex={selectedIndex}
      items={
        medias(
          sites[selectedIndex],
          <></>,
          isMedia,
          isVisit,
          isNotHome,
          finalLap,
          setOpenMediaModal
        )
      }
    />
  );

  const ProviderOrNot = sites.length > 1 ? TouristicSiteProvider : Fragment;
  const providerOrNotProps = sites.length > 1 ? {initialTouristicSite: sites[selectedIndex]} : {};

  return (
    <ProviderOrNot {...providerOrNotProps}>
      <DayActivityDetailsStyled
        ref={maRef3}
        {...rest}
        style={{
          ...rest.style,
          position: 'relative',
          height: '100%',
          overflowY: 'auto'
        }}
      >
        <IllustrationWrapper
          ref={maRef2}
          style={{
            boxSizing: 'border-box'
          }}
        >
          <IllustrationComponent
            centered
            selectedIndex={selectedIndex}
            onSelectedIndexChange={setSelectedIndex}
            style={{height: "23em", paddingTop: '2em'}}
          >
            {wrapperOfAllSites}
          </IllustrationComponent>
          {
            wrapperOfAllSites === wrapSites && (
              <SitesStyled>
                <ButtonPlanning
                  selectedSite={sites[selectedIndex]}
                />
              </SitesStyled>
            )
          }
        </IllustrationWrapper>
        <TabbedNavigation
          visibleTitles={isMedia}
          centerTitles
          activeTabIndex={isMedia ? 0 : activeTab}
          onActiveTabIndexChange={setActiveTab}
          items={
            items(
              sites[selectedIndex],
              AsideIllustrationComponent,
              <div style={{display: (isVisit || isNotHome) ? 'inherit' : 'none'}}>
                {MediaComponent}
              </div>,
              isVisit || isNotHome,
              editable,
              t
            )
          }
        />
        {
          editable ? (
              <div>
                {MediaEditableComponent}
              </div>
            )
            :
            <div>
              {MediaComponent}
            </div>
        }
        {
          openMediaModal &&
          <div
            style={{
              position: 'absolute',
              top: '0',
              right: '0',
              width: '100%',
              height: '100%',
              opacity: '0.5',
              backdropFilter: 'blur(1000px)',
            }}
            onClick={(event) => event.preventDefault()}
          />
        }
      </DayActivityDetailsStyled>
    </ProviderOrNot>
  );
};

export default DayActivityDetails;