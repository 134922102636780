import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Title from "../../../components/header/title/title";
import { PHONE_WIDTH } from "../../../utils/utils";
import SliderButton from "../../../components/form/slider/slider-button";
import SlickSlider from "../../../components/form/slider/slider";
import useSlick from "../../../hooks/use-slick";
import { API_GET_TOURISTIC_SITE_PARTNER } from "../../../utils/api";
import useRequest from "../../../hooks/use-request";
import { TouristicSiteCard } from "../../../components/touristicSites/touristicSiteCard";
import {mediasTyped} from "../../../utils/media";

const RequestPartnerTouristicSites = ({touristicSites}) => {
  const {t} = useTranslation(["henriTrip"]);
  const {current, onChange, slick, setCurrent, total} = useSlick(touristicSites);
  return (
    <section className="categories-section">
      <div className="row">
        <div className="col">
          <Title title={t("partner.tsPartner")}/>
        </div>
        {
          window.innerWidth > PHONE_WIDTH ?
            <div className="col-2 pt-3 pt-lg-4 p-0">
              <SliderButton isInfinite current={current} total={total} onChange={onChange} />
            </div> : <></>
        }
      </div>
      <SlickSlider slick={slick} settings={{
        infinite: true,
        dots: false,
        autoplay: true,
      }} handleBeforeChange={(oV, nV) => setCurrent(nV)}>
        {
          touristicSites?.map((el, i) =>
            <div key={i} className="py-2 h-100 d-flex flex-column align-items-center">
              <TouristicSiteCard touristicSite={el} />
            </div>)
        }
      </SlickSlider>
    </section>
  )
}


const PartnerSectionsTouristicSites = () => {
  const {t} = useTranslation(["henriTrip"]);
  const [handleRequest] = useRequest();
  let [touristicSites, setTouristicSites] = useState();

  useEffect(() => {
    handleRequest("get", API_GET_TOURISTIC_SITE_PARTNER, {}, {}, (data, status) => {
      if (status === "Done") {
        const parsedResults = data?.map((el) => {
          el.category = el.categories[0] || {
            color: "#EF7E26",
            id: 53,
            title: "Activité",
            url: "https://general-henri-1-paris.s3.fr-par.scw.cloud/categories/activites_sportives.jpg"
          };
          el.image = mediasTyped(el.medias).image?.[0]?.url;
          el.feedbacks = {
            note: el.avg_note,
            len: el.notes_counter
          };
          return el;
        })


        setTouristicSites(parsedResults);
      } else {
        console.error(data);
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
          touristicSites ?
            <RequestPartnerTouristicSites touristicSites={touristicSites}/> :
            <section className="categories-section">
              <div className="row">
                <div className="col">
                  <Title title={t("partner.tsPartner")}/>
                </div>
              </div>
            </section>
  );
};


export default PartnerSectionsTouristicSites;
