import React from 'react';
import L from 'leaflet';
import ReactDOMServer from 'react-dom/server';
import Icon1 from '../ReactIcons/icon1';
import { ICON_ANCHOR, ICON_SIZE } from '.';

const mapIcon1 = L.divIcon({
    html: ReactDOMServer.renderToString(<Icon1 />),
    iconSize: ICON_SIZE,
    iconAnchor: ICON_ANCHOR
});

export default mapIcon1;