import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

const categories = {
    categories: []
};

export default persistReducer({storage, key: "categories-data", whitelist: ["categories"]}, (state = categories, action) => {
    switch (action.type) {
        case 'ADD_CATEGORIES':
            return {
                categories: action.payload
            };
        case 'REMOVE_CATEGORIES':
            return {
                categories: []
            }
        default:
            return state;
    }
})