import React from 'react';
import SVG from 'react-inlinesvg';
import {toAbsoluteUrl, transformSmallImageURL} from 'utils/utils';

import categoryIcons from 'utils/categories';
import { Link } from 'react-router-dom';

import "./touristicSiteCardPremium.scss";
import ImgLazy from "../../Lazyload/ImgLazy/ImgLazy";
import {mediasTyped} from "../../../utils/media";

const TouristicSiteCardPremium = ({openTsModal,touristicSite}) => {
  const color = touristicSite.category?.color || "#EF7E26"
  const id = touristicSite.category_id
  const temp_img = mediasTyped(touristicSite?.medias).image?.[0]
  return (
    <div className="touristic-card-premium" onClick={() => openTsModal && openTsModal(touristicSite)} style={{cursor: 'pointer'}}>
      <div className="touristic-card-premium bg-white shadow-lg row m-0 no-gutter position-relative d-flex flex-column" >
        <div className="h-100 p-0">
          {
            touristicSite.custom ?
              <ImgLazy
                small
                errorImg="https://general-henri-1-paris.s3.fr-par.scw.cloud/small-woman.jpg"
                src={temp_img?.url || "https://general-henri-1-paris.s3.fr-par.scw.cloud/small-woman.jpg"}
                className="touristic-card-premium--img"
                offset={1400}
                overflow={false}
                visible={true}
              />
              :
              <ImgLazy
                small
                errorImg="https://general-henri-1-paris.s3.fr-par.scw.cloud/small-woman.jpg"
                src={transformSmallImageURL(touristicSite.image)}
                className="touristic-card-premium--img"
                offset={1400}
                overflow={false}
                visible={true}
              />
          }
        </div>
        <div className="pl-3 pb-2 d-flex flex-row position-absolute text-white fixed-bottom align-items-center" style={{zIndex:0}} >
          {
            touristicSite.custom ?
              <>
                <div>
                  <img width="30" height="30" className="mr-1 align-top" src={(categoryIcons.custom_icons_white[id])} />
                </div>
                <div>
                  <h6 className="m-0 text-white font-weight-bold" >
                    {touristicSite.title_fr}
                  </h6>
                </div>
              </>
              :
              <>
                <div>
                  <SVG width="30" height="30" className="mr-1 align-top" color={color} src={toAbsoluteUrl(categoryIcons.icons[color])} />
                </div>
                <div className="mb-0 ">
                  <Link className="touristic-card-premium--title" target="_blank" to={"/touristic-site/" + touristicSite.id} data-toggle="tooltip" title={touristicSite.title}>
                    <h6 className="m-0 text-white font-weight-bold">
                      {touristicSite.title}
                    </h6>
                  </Link>
                </div>
              </>
          }
        </div>
      </div>
    </div>
  );
};

export default TouristicSiteCardPremium;
