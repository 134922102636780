import * as Yup from 'yup';
import UserForm from './UserForm';
import {REQUIRED_MESSAGE} from "../../../utils/texts";

const {
  formField: {
    username,
    email,
    firstname,
    lastname,
    gender,
    birthdate,
    password,
    password_confirmation,
    statement,
  },
} = UserForm;

// eslint-disable-next-line import/no-anonymous-default-export
export default [
  Yup.object().shape(
    {
      [username.name]: Yup.string()
        .matches(
          /^([A-Za-z0-9_]){4,15}$/,
          "Le format du nom d'utilisateur n'est pas le bon, 4 à 15 caractères, seuls les chiffres, les lettres et le tiret du bas sont autorisés. Exemple : henritrip_01"
        ).required(REQUIRED_MESSAGE),

      [email.name]: Yup.string()
        .matches(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
          'Adresse mail incorrect'
        ).required(REQUIRED_MESSAGE),
      [firstname.name]: Yup.string().required(REQUIRED_MESSAGE),
      [lastname.name]: Yup.string().required(REQUIRED_MESSAGE),
      [gender.name]: Yup.string().required(REQUIRED_MESSAGE),
      [birthdate.name]: Yup.string().required(REQUIRED_MESSAGE),
      [password.name]: Yup.string()
        .matches(
          /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,70}$/,
          'Le mot de passe doit contenir au moins 8 caractères, incluant des lettres en majuscule, minuscule et au moins un chiffre.'
        )
        .min(8, 'Le mot de passe doit contenir au moins 8 caractères')
        .required(REQUIRED_MESSAGE),
      [password_confirmation.name]: Yup.string()
        .oneOf([Yup.ref('password'), null], 'Ces mots de passe ne correspondent pas!')
        .required(REQUIRED_MESSAGE),

      [statement.name]: Yup.bool().required(REQUIRED_MESSAGE).oneOf([true], REQUIRED_MESSAGE),
    }
  ),
];
