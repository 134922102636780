import * as React from "react"

function Icon2(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 88.56 106.9"
      style={{color: "#EF7E26"}}
      {...props}
    >
      <defs>
        <style>{".prefix__cls-2{fill:#fff}"}</style>
      </defs>
      <g id="prefix__Calque_2" data-name="Calque 2">
        <g id="prefix__Calque_1-2" data-name="Calque 1">
          <path
            fill="currentColor"
            d="M44.28 0a44.27 44.27 0 00-31.16 75.73l31.16 31.17 31.16-31.17A44.27 44.27 0 0044.28 0"
          />
          <path
            className="prefix__cls-2"
            d="M60.3 42.49l7.14-6.55a2.27 2.27 0 00.13-3.21 2.27 2.27 0 00-3.21-.14l-5.07 4.66-.86-2.35-1.88-5.15a5.45 5.45 0 00-3.81-3.4L48 25.19l-6.27-1.53a2.66 2.66 0 00-3.12 1.62L35 34.6a2.26 2.26 0 001.28 2.94 2.27 2.27 0 002.95-1.28l3.06-7.79 9.86 2.41h-6.42l-5.84 14.56L34 55.07l-12.1 4.21a2.27 2.27 0 00-1.4 2.9 2.27 2.27 0 002.89 1.4l12.74-4.43A2.71 2.71 0 0037.55 58l4.88-8 8.21 7.52L42 67.19a2.27 2.27 0 00.18 3.21 2.27 2.27 0 003.21-.18l9.84-11a2.67 2.67 0 00-.19-3.75l-10.58-9.78 4.47 1.73 6.43-7.67.62 1.7a2.68 2.68 0 004.32 1.04z"
          />
          <circle className="prefix__cls-2" cx={59.7} cy={22.62} r={5.04} />
        </g>
      </g>
    </svg>
  )
}

export default Icon2
