import Title from 'components/header/title/title';
import React from 'react';
import useDocumentTitle from "../../../hooks/use-document-title";

const DownloadsPage = () => {
  useDocumentTitle('Mes téléchargements • Henri Trip')
    return (
        <div>
            <Title title="Mes téléchargements" description="Liste de toutes mes activités téléchargées" />
            <p className="text-muted">Vous n'avez pas encore téléchargé d'activités</p>
        </div>
    );
};

export default DownloadsPage;