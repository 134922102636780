import { Button } from 'components/form/button';
import InputFormik from 'components/form/input/inputFormik';
import InputMaskFormik from 'components/form/input/inputMaskFormik';
import SelectFormik from 'components/form/select/selectFormik';
import TextAreaFormik from 'components/form/text-area/textAreaFormik';
import { Form, Formik } from 'formik';
import useRequest from 'hooks/use-request';
import React from 'react';
import { REQUIRED_MESSAGE } from 'utils/texts';
import * as Yup from 'yup';
import FeedbackNotation from './feedback-notation';

const formField = {
    note_site: {
        name: "note_site",
        label: "Notez le lieu de l'activité",
        required: true,
    },
    note_experience: {
        name: "note_experience",
        label: "Notez votre expérience",
        required: true
    },
    content: {
        name: "content",
        label: "Votre commentaire",
        placeholder: "Votre commentaire...",
        required: true,
    },
    title: {
        name: "title",
        label: "Donnez un titre à votre avis",
        placeholder: "Donnez un titre à votre avis",
        required: true
    },
    when: {
        name: "when",
        label: "Quand y êtes-vous allé ?",
        placeholder: "JJ/MM/AAAA",
        mask: "99/99/9999",
        required: true
    },
    with: {
        name: "with",
        label: "Qui est venu avec vous ?",
        placeholder: "Qui est venu avec vous ?",
        options: [
            {
                label: "En couple",
                value: 1,
            },
            {
                label: "Famille (jeunes enfants)",
                value: 2
            },
            {
                label: "Famille (ados)",
                value: 3,
            },
            {
                label: "Amis",
                value: 4,
            },
            {
                label: "Affaires",
                value: 5
            },
            {
                label: "Voyage solo",
                value: 6
            }
        ],
        noOptionsMessage: () => "Aucune donnée trouvée",
        required: true,
    },
    more: {
        name: "more",
        label: "Pouvez-vous nous en dire plus ?",
        placeholder: "Écrivez ici d'autres renseignements"
    }
}

const formValidation = Yup.object().shape({
    [formField.note_site.name]: Yup.number().min(1, REQUIRED_MESSAGE).required(REQUIRED_MESSAGE),
    [formField.note_experience.name]: Yup.number().min(1, REQUIRED_MESSAGE).required(REQUIRED_MESSAGE),
    [formField.content.name]: Yup.string().min(100, min => `Vous devez écrire au moins 100 caratères (${min.value.length}/${min.min} caratères)`).required(REQUIRED_MESSAGE),
    [formField.title.name]: Yup.string().required(REQUIRED_MESSAGE),
    [formField.when.name]: Yup.date().required(REQUIRED_MESSAGE),
    [formField.with.name]: Yup.string().required(REQUIRED_MESSAGE)
})

const formInitial = {
    [formField.note_site.name]: 0,
    [formField.note_experience.name]: 0,
    [formField.content.name]: "",
    [formField.title.name]: "",
    [formField.when.name]: "",
    [formField.with.name]: "",
}
const AddActivityFeedback = ({ apiRoute, onHide }) => {
    const [handleRequest] = useRequest();

    const handleSubmit = (v, {setStatus}) => {
        handleRequest("post", apiRoute, v, {
            headers: {
                "Content-type": "application/json"
            }
        }, (data, status) => {
            if (status === "Done") {
                console.log(data);
            } else if (status === "Error") {
                console.error(data)
                setStatus("Une erreur est survenue")
            }
        })
    }

    return (
        <Formik
            initialValues={formInitial}
            onSubmit={handleSubmit}
            validationSchema={formValidation}
        >
            {(formikProps) => {
                return (
                    <Form onSubmit={formikProps.handleSubmit} className="p-4">
                        <h2 className="mb-3 text-primary">Ajoutez un nouvel avis</h2>
                        <FeedbackNotation {...formikProps} {...formField.note_site} />
                        <FeedbackNotation {...formikProps} {...formField.note_experience} />
                        <TextAreaFormik {...formikProps} {...formField.content} />
                        <InputFormik {...formikProps} {...formField.title} />
                        <InputMaskFormik {...formikProps} {...formField.when} />
                        <SelectFormik {...formikProps} {...formField.with} value={formField.with.options.find(el => el.value === formikProps.values[formField.with.name])} />
                        <TextAreaFormik {...formikProps} {...formField.more} />
                        <div className="d-flex justify-content-end">
                            <Button title="Annuler" color="secondary" className="mr-1" onClick={onHide} />
                            <Button title="Ajouter" type="submit" />
                        </div>
                    </Form>
                )
            }}
        </Formik>
    );
};

export default AddActivityFeedback;