import React, { useState, useEffect } from "react";

import { PHONE_WIDTH, toAbsoluteUrl } from 'utils/utils';
import SVG from 'react-inlinesvg';
import categoryIcons from 'utils/categories';
import "./RoadTripMapCard.scss"
import "./RoadTripMapPopUp.scss"
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import categories from "../../../utils/categories";
import { ReactComponent as ChevronDown } from "../../../../src/assets/img/logo/chevron_down.svg"
import { ReactComponent as Hours } from '../../../assets/img/logo/horloge.svg';
import { ReactComponent as Information } from '../../../assets/img/logo/information.svg';
import {mediasTyped} from "../../../utils/media";

const SvgIcon = ({ data, image }) => {
  const category = data;

  return (
    <SVG width="20" color={category?.color} height="20" src={image} />
  )
}

// pour finir la page Experience il faut ajouter a la selection d'une activité une animation pour cibler l'element selectionné
const RoadTripMapPopupPremium = ({setActivityModalIsOpen, data, handlePurchase, setPopup, openMap, openMapFull, allData, setMapModalIsOpen }) => {
  const { t } = useTranslation(["henriTrip"]);
  const [widthState, setWidthState] = useState(window.innerWidth);
  const history = useHistory();
  const style = {
    marginTop: "6%"
  };
  useEffect(() => {
    function handleResize() {
      setWidthState(window.innerWidth)
    }
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize)
  }, []);
  const [heures, setHeures] = useState(null);
  const [minutes, setMinutes] = useState(null);
  useEffect(()=>{
    setHeures( Math.floor(data.duration/60))
    data.duration%60 > 15 && data.duration%60 < 45 && setMinutes(30);
  },[data.duration]);

  const image = mediasTyped(data.medias).image?.[0]?.url || undefined;
  const category = data.category || data.categories?.[0];
  const custom_category_icon = category?.id ? categories.custom_icons[category.id] : undefined;
  const category_icon = category?.color ? categories.icons_with_current_color[category.color] : undefined;
  return (
    <div className={`rt-popup-widget__touristiccard `} >
      {
        widthState < "1200" ?
          <div style={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}><hr style={{ width: "20%" }}></hr><div onClick={() => setPopup(null)} ><ChevronDown width="25" height="25" color="#B0B2B5" /></div><hr style={{ width: "20%" }}></hr></div>
          :
          <div style={{   display: 'flex', width: '90%', justifyContent: 'flex-end', marginTop: '0.5em' }} onClick={() => setPopup(null)} ><div style={{border : "1px solid #B0B2B5", borderRadius: "100%", padding: '0.4em', height: "38px", width: "38px"}}><ChevronDown width="20" height="20" color="#D7D7D7" /></div></div>
      }
        <div style={{ display: "flex", width: "100%", justifyContent: 'center', gap: '40px'}} className="container-popup-map">
          <div className="rt-popup-widget__image_container" onClick={() => setActivityModalIsOpen(allData.findIndex(elm => elm.id === data.id))}>
            {
              image &&
                <>
                  <img src={image || 'https://general-henri-1-paris.s3.fr-par.scw.cloud/woman.jpg'} style={{objectFit: "cover",
                    objectPosition: "center",
                    boxShadow: "6px 7px 13px -10px #000000",
                    borderRadius: '0.7em 0.7em 0.7em 0.7em',
                    width: '100%',
                    height: '100%',
                  }} alt="..." />
                  <div className="rt-popup-widget__svg">
                    <Information color="rgba(255,255,255, 0.8)" />
                  </div>
                </>
            }
          </div>
        <div className="rt-popup-widget__touristicBody">
          {
            custom_category_icon ?
              <div className="rt-popup-widget__header-card">
                <div className="rt-popup-widget__container_category">
                  <img width="20" height="20" color={data?.category?.color} src={custom_category_icon} />
                </div>
                <h6 className="rt-popup-widget__title">
                  {categories.name?.[data.category_id]}
                </h6>
              </div>
              :
              <div className="rt-popup-widget__header-card">
                <div className="rt-popup-widget__container_category" style={{ border: `1px solid ${data.category?.color || data?.categories?.[0]?.color}`, height: 42, width: 42 }}>
                  <SvgIcon data={category} image={category_icon}/>
                </div>
                <h6 className="rt-popup-widget__title">
                  {
                    data.categories &&
                    data.categories[0].title
                  }
                  {
                    data.category &&
                    data.category.title
                  }
                </h6>
              </div>
          }

          {
            data.title &&
            <div>
              <span style={{ fontWeight: "800" }} className="rt-popup-widget__iconCat">{data.title}</span>
            </div>
          }

          {
            data.title_fr &&
            <div >
              <span style={{ fontWeight: "800" }} className="rt-popup-widget__iconCat">{data.title_fr}</span>
            </div>
          }
          {
            data.duration &&
            <div style={{ fontSize: "0.8rem", display: "flex" }}>
              <span>Estimation du temps de visite</span>
              <span style={{width: "90%"}}><Hours />{heures} heures</span>
            </div>
          }

           {
            data.incontournable &&
            <div style={{ backgroundColor: "#54A8FC", borderRadius: "0.2em", textAlign: "center" }}>
              <span style={{ color: "white", fontWeight: "800", fontSize: "0.8em" }}>Incontournable</span>
            </div>
          }
        </div>
      </div>
    </div>
  );
};

export default RoadTripMapPopupPremium;
