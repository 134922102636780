import styled from "styled-components";
import React, { useState } from "react";
import { API_ORIGINAL_BLOB_URL } from "../../../utils/api";
import categories from "../../../utils/categories";
import SVG from "react-inlinesvg";
import { ReactComponent as PineLocationIcon } from "../../../assets/img/logo/pine-location-icon.svg";
import { ReactComponent as StarIcon } from "assets/img/logo/star-icon.svg";
import { mediasTyped } from "../../../utils/media";
import mainImage from "../../../utils/touristic_sites/mainImage";

const CardContainer = styled.div`
  display: flex;
  gap: 30px;
`;

const Card = styled.div`
  position: relative;
  width: 253px;
  height: 360px;
  background: ${(props) => props.theme.colors.white};
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 17px;
`;

const CardHead = styled.div`
  display: flex;
  width: 253px;
  height: 184px;
  background: no-repeat url(${(props) => props.image});
  border-radius: 17px 17px 0 0;
  background-size: cover;
  justify-content: space-between;
`;

const FirstContainer = styled.div`
  width: 253px;
  height: fit-content;
  display: flex;
  justify-content: space-between;
  margin: 20px 10px 0 0;
  align-items: center;
  flex-direction: row-reverse;
`;

const NumberViewContainer = styled.span`
  background: rgba(255, 255, 255, 0.65);
  border-radius: 3px;
  width: 64px;
  height: 23px;

  font-family: "Inter", "serif";
  font-style: normal;
  font-weight: 900;
  font-size: 12px;
  line-height: 140%;

  display: flex;
  align-items: center;
  justify-content: center;
`;

const SVGContainer = styled.span`
  display: ${(props) =>
    props.category || props.custom_category_icons ? "block" : "none"};
  padding: 0.4em;
  background-color: ${(props) => props.theme.colors.white};
  border-radius: 100%;

  img {
    width: 25px;
    height: 25px;
    border-radius: 0;
  }
`;

const TitleContainer = styled.div`
  display: flex;
  justify-content: center;
  margin: 9px 15px 8px;
  text-align: center;
  p {
    font-weight: 700;
    font-size: 14px;
    line-height: 14px;
  }
`;

const SecondeContainer = styled.div`
  display: flex;
  width: 253px;
  height: fit-content;
  justify-content: space-between;
  align-items: center;
  line-height: 0.5;
`;

const DestinationContainer = styled.div`
  width: 100%;
  display: flex;
  gap: 6px;
  margin: 7px 15px;
  align-items: center;
  height: 23px;
`;

const PineLocationContainer = styled.div`
  width: 16px;
  height: 23px;
`;

const DestinationFont = styled.p`
  font-weight: 600;
  font-size: 14px;
  color: ${(props) => props.theme.colors.darkGrey90};
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
`;

const PriceContainer = styled.div`
  width: fit-content;
  height: fit-content;
  background-color: ${(props) => props.theme.colors.primary};
  border-radius: 20px;
  margin-right: 10px;
`;

const Price = styled.p`
  padding: 4px 15px;
  font-weight: 600;
  font-size: 15px;
  line-height: 19px;
  color: ${(props) => props.theme.colors.white};
`;

const ThirdContainer = styled.div`
  display: flex;
  height: fit-content;
  ${(props) =>
    props.customStyled
      ? "justify-content: center;"
      : "margin-top: 20px; justify-content: space-between;"};
  align-items: center;
  line-height: 0.5;
`;

const SeeMoreFont = styled.a`
  display: flex;
  justify-content: center;
  padding: 12px 0;
  ${(props) =>
    props.customStyle ? "margin: 30px 10px 0 10px;" : "margin: 0 10px 0 10px;"};
  align-items: center;
  p {
    font-weight: 700;
    // font-size: 13px;
    font-size: 15px;
    line-height: 16px;
    color: ${(props) => props.theme.colors.primary};
    :hover {
      color: ${(props) => props.theme.colors.darkGrey90} !important;
    }
  }
`;

const StarIconStyled = styled(StarIcon)`
  width: 10px;
  height: 10px;
  stroke: ${(props) => props.theme.colors.primary};
  fill: ${(props) => (props.filled ? props.theme.colors.primary : "none")};
`;

const StarListStyled = styled.div`
  display: inline-block;
`;

const StarList = ({ note }) => {
  const roundedNote = Math.floor(note);

  return (
    <StarListStyled>
      {[...Array(5)].map((_, i) => (
        <StarIconStyled key={i} filled={i < roundedNote} />
      ))}
    </StarListStyled>
  );
};

const ReviewBlock = styled.div`
  display: flex;
  align-items: center;
  gap: 9px;
  margin-left: 10px;
`;

const Note = styled.span`
  color: ${(props) => props.theme.colors.primary};
  display: inline-block;
  font-family: "Inter";
  font-weight: 700;
  font-size: 33px;
  line-height: 40px;
`;

const NoteCounter = styled.span`
  color: ${(props) => props.theme.colors.darkGrey90};
  display: block;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const ButtonAddTrip = styled.div`
  width: 171px;
  height: 35px;
  display: flex;
  width: 181px;
  height: 35px;
  background-color: ${(props) => props.theme.colors.white};
  border: 1px solid ${(props) => props.theme.colors.primary};
  border-radius: 68px;
  justify-content: center;
  align-items: center;

  font-weight: 700;
  font-size: 13px;
  line-height: 16px;

  color: ${(props) => props.theme.colors.primary};

  :hover {
    // filter: invert(1);
    background-color: #f5f5f5;
  }
`;

const ForthContainer = styled.div`
  width: 253px;
  position: absolute;
  bottom: 13px;
`;

const ActivityCardV2 = ({ activity }) => {
  const category = activity?.category || activity?.categories?.[0];
  const custom_category_icon = category?.id
    ? categories.custom_icons[category.id]
    : undefined;
  const category_icon = category?.color
    ? categories.icons_with_current_color[category.color]
    : undefined;
  const price = activity?.price_list ? activity.price_list[0].price : undefined;
  const image_default =
    "https://general-henri-1-paris.s3.fr-par.scw.cloud/small-woman.jpg";
  const image = mainImage(activity);
  const image_url = image
    ? API_ORIGINAL_BLOB_URL(image.signed_id, image.filename)
    : image_default;
  const note = activity?.avg_note || undefined;
  const noteFormatted = new Intl.NumberFormat("en-EN", {
    minimumFractionDigits: 1,
    maximumFractionDigits: 1,
  }).format(activity.avg_note);

  return (
    <a href={`/touristic-sites/${activity.id}`}>
      <Card>
        <CardHead image={image_url}>
          <FirstContainer>
            <SVGContainer
              category={category}
              custom_category_icons={custom_category_icon}
            >
              {custom_category_icon ? (
                <img
                  src={custom_category_icon}
                  color={"black"}
                  alt={category.title}
                />
              ) : (
                <SVG
                  src={category_icon}
                  color={category?.color}
                  height="30px"
                  width="30px"
                />
              )}
            </SVGContainer>
          </FirstContainer>
        </CardHead>
        <TitleContainer>
          <p>{activity.title_fr}</p>
        </TitleContainer>
        <SecondeContainer>
          <DestinationContainer>
            <PineLocationContainer>
              <PineLocationIcon />
            </PineLocationContainer>
            <DestinationFont title={activity.city}>
              {activity.city}
            </DestinationFont>
          </DestinationContainer>
          {/*<PriceContainer>*/}
          {/*  {price ? (*/}
          {/*    <Price>*/}
          {/*      {price} {activity.price_list[0].currency}*/}
          {/*    </Price>*/}
          {/*  ) : (*/}
          {/*    <Price>Gratuit</Price>*/}
          {/*  )}*/}
          {/*</PriceContainer>*/}
        </SecondeContainer>
        {note ? (
          <ThirdContainer>
            <ReviewBlock>
              <Note>{noteFormatted}</Note>
              <div>
                <StarList note={activity.avg_note} />
                <NoteCounter>{activity.notes_counter} votes</NoteCounter>
              </div>
            </ReviewBlock>
            <SeeMoreFont href={`/touristic-sites/${activity.id}`}>
              <p>En savoir +</p>
            </SeeMoreFont>
          </ThirdContainer>
        ) : (
          <ThirdContainer customStyled>
            <SeeMoreFont href={`/touristic-sites/${activity.id}`} customStyle>
              <p>En savoir +</p>
            </SeeMoreFont>
          </ThirdContainer>
        )}

        {/* <ForthContainer>
        <ButtonContainer>
          <a>
            <ButtonAddTrip>Ajouter à ma liste</ButtonAddTrip>
          </a>
        </ButtonContainer>
      </ForthContainer> */}
      </Card>
    </a>
  );
};

export default ActivityCardV2;
