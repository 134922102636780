import i18n from 'i18next';
import groupBy from '../groupBy';

const touristicSiteTranslationKeys = [
  'title',
  'description',
  'additional_title',
  'additional_description',
];

const touristicSiteTranslationKeyRegex = new RegExp("^("+touristicSiteTranslationKeys.join('|')+")_(fr|en|it|ge|sp|cn|sa|pt|jp|ru)$");

const addTouristicSiteToI18n = (touristicSite) => {
  if (!touristicSite?.id) {
    return ;
  }
  const keys = Object.
    keys(touristicSite).
    filter((key) => touristicSiteTranslationKeyRegex.test(key));

  const keysGroupedByLang = groupBy(
    keys,
    (key) => key.split('_').pop(),
  );

  Object.keys(keysGroupedByLang).forEach((lang) => {
    const keys = keysGroupedByLang[lang];

    const translations = {};

    keys.forEach((key) => {
      const translateKey = key.match(touristicSiteTranslationKeyRegex)[1];
      if (![undefined, null].includes(touristicSite[key])) {
        translations[translateKey] = touristicSite[key];
      }
    });

    const resources = {
      touristicSites: {
        [touristicSite.id]: translations,
      },
    };

    i18n.addResourceBundle(lang, 'api', resources, true, true);
  });
};

const getTouristicSiteFromI18n = (touristicSite = {}, t) => {
  const translations = {};

  touristicSiteTranslationKeys.forEach((key) => {
    translations[key] = t([`api:touristicSites.${touristicSite.id}.${key}`, 'commons:emptyString']);
  });

  return translations;
};

export {
  addTouristicSiteToI18n,
  getTouristicSiteFromI18n,
};