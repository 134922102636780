import Search from 'components/search/oldSearch';
import React from 'react';
import { Modal } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';

const SearchPage = ({match, history}) => {
    return (
        <Modal
            show={match}
            onHide={() => {
                const path = history.location.pathname?.split("/").filter(el => el !== "search").join("/")
                history.push(path);
            }}
            size="lg"
            centered
        >
            <Search defaultSelect={match.params.page === "road-trip" ? "road-trip" : "activity"} />
        </Modal>
    );
};

export default withRouter(SearchPage);