import Title from 'components/header/title/title';
import useSlick from 'hooks/use-slick';
import React from 'react';

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import SlickSlider from 'components/form/home-slider/home-slider-settings';
import { useSelector } from 'react-redux';

import "./PartnerSectionsTrustSlider.scss"
import { useTranslation } from "react-i18next";



const PartnerSectionsSlider = ( props ) => {
  const {t} = useTranslation(["henriTrip"])
  const {touristic_sites} = useSelector(state => state.touristicSites);
  const {slick, setCurrent} = useSlick(touristic_sites || []);

  const partners = [
    {
      title: "Orange",
      image: "https://general-henri-1-paris.s3.fr-par.scw.cloud/partenaire_page/partenaires/Orange-logo.png",
    },
    {
      title: "Bpi_france",
      image: "https://general-henri-1-paris.s3.fr-par.scw.cloud/partenaire_page/partenaires/BPI-logo.png",
    },
    {
      title: "Region_occitanie",
      image: "https://general-henri-1-paris.s3.fr-par.scw.cloud/partenaire_page/partenaires/Occitanie-logo.jpg",
    },
    {
      title: "French_tech",
      image: "https://general-henri-1-paris.s3.fr-par.scw.cloud/partenaire_page/partenaires/French-tech-logo.png",
    },
    {
      title: "SpotyRide",
      image: "https://general-henri-1-paris.s3.fr-par.scw.cloud/partenaire_page/partenaires/Spotyride-logo.png",
    },
    {
      title: "Lumiplan",
      image: "https://general-henri-1-paris.s3.fr-par.scw.cloud/partenaire_page/partenaires/Lumiplan-logo.png",
    },
    {
      title: "Generation_voyage",
      image: "https://general-henri-1-paris.s3.fr-par.scw.cloud/partenaire_page/partenaires/Generation-voyage-logo.png",
    },
    {
      title: "Get_your_guide",
      image: "https://general-henri-1-paris.s3.fr-par.scw.cloud/partenaire_page/partenaires/Getyourguide-logo.png",
    },
    {
      title: "StyQR",
      image: "https://general-henri-1-paris.s3.fr-par.scw.cloud/partenaire_page/partenaires/StyQR-logo.png",
    },
    {
      title: "Musement",
      image: "https://general-henri-1-paris.s3.fr-par.scw.cloud/partenaire_page/partenaires/Musement-logo.png",
    },
    {
      title: "Tiqets",
      image: "https://general-henri-1-paris.s3.fr-par.scw.cloud/partenaire_page/partenaires/Tiqets-logo.png",
    },
  ]

  return (
    <section>
      <div className="container mt-3">
        <div className="row">
          <div className="col">
            <Title title={t("partner.trustSliderSection.title")}/>
          </div>
          <div className="trust-slider__slider mt-2">
            <SlickSlider
              slick={slick}
              handleBeforeChange={(oV, nV) => setCurrent(nV)}
              settings={{
                infinite: true,
                dots: false,
                autoplay: true
              }}
            >
              {
                partners?.map((el,i) =>
                  <div className="trust-slider__conteneur">
                    <img src={el.image} alt={el.title} className="trust-slider__image"/>
                  </div>
                )
              }
            </SlickSlider>
          </div>
        </div>
      </div>
    </section>
  );
};

export default PartnerSectionsSlider;