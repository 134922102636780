import React, {useEffect, useState} from "react";
import {
  Elements,
  useStripe,
  useElements,
  PaymentElement
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js"
import {API_STRIPE_PAYMENT_INTENT} from "../../utils/api";
import useRequest from "../../hooks/use-request";
import {useTranslation} from "react-i18next";

const stripePromise = loadStripe(`${process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY}`);


export default function StripeContainer({config, cart}) {
  const [options, setOptions] = useState();
  const [orderId, setOrderId] = useState(null);
  const [handleRequest] = useRequest();

  useEffect(() => {
    const formData = {
      road_trip_premium_id: cart?.roadtrip_premium.id
    }
    handleRequest("post", API_STRIPE_PAYMENT_INTENT, formData, {
      headers: {
        // "content-type": "multipart/form-data",
        'Authorization': config,
      }
    }, (data, status) => {
      if (status === "Done") {
        setOptions({
          // passing the client secret obtained from the server
          clientSecret: data.client_secret,
        });
        setOrderId(data.transaction)
      }
    })
  },[])

  return (
    options ?
    <Elements stripe={stripePromise} options={options}>
      <CheckoutForm orderId={orderId}/>
    </Elements>
      : null
  )
}
const CheckoutForm = ({orderId}) => {
  const {t} = useTranslation(["henriTrip"]);
  const stripe = useStripe();
  const elements = useElements();
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (event) => {
    setLoading(true);
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();

    if (!stripe || !elements) {
      setLoading(false);
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    const result = await stripe.confirmPayment({
      //`Elements` instance that was used to create the Payment Element
      elements,
      confirmParams: {
        return_url: `${window.location.origin}/order/${orderId}/complete`,
      },
    });


    if (result.error) {
      // Show error to your customer (for example, payment details incomplete)
      setError(result.error.message);
      setLoading(false);
    } else {
      // Your customer will be redirected to your `return_url`. For some payment
      // methods like iDEAL, your customer will be redirected to an intermediate
      // site first to authorize the payment, then redirected to the `return_url`.
    }
  };
  return (
    <form onSubmit={handleSubmit}>
      {error && (
        <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
          <div className="alert-text font-weight-bold">
            Une erreur est survenue...
            <p>Message: {error}</p>
          </div>
        </div>
      )}
      <PaymentElement />
      <div className="w-100 d-flex justify-content-center mt-3">
        <button disabled={!stripe || loading} className="btn font-weight-bold btn-primary rounded-sm px-5 align-items-center">
          {loading ?
            <div className="spinner-border text-white" role="status">
              <span className="sr-only">Loading...</span>
            </div>
            :
            t("checkOutPage.pay")
          }
        </button>
      </div>
    </form>
  );
};