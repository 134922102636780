import * as React from "react"

const Icon10 = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 88.56 106.9"
      style={{color: "#967DB3"}}
      {...props}
    >
      <g id="prefix__Calque_2" data-name="Calque 2">
        <g id="prefix__Calque_1-2" data-name="Calque 1">
          <path
            fill="currentColor"
            d="M44.28 0a44.27 44.27 0 00-31.16 75.73l31.16 31.17 31.16-31.17A44.27 44.27 0 0044.28 0"
          />
          <path
            className="prefix__cls-2"
            d="M44.28 63.35H33.63l-1.17 7.63H56.1l-1.17-7.63H44.28zM67.2 31.68h-3.07a20.93 20.93 0 00-2.91-5.49 4.66 4.66 0 002.05-3.85v-4.76H51.66V19a21 21 0 00-14.68 0v-1.42h-11.6v4.76a4.62 4.62 0 002 3.8 21.13 21.13 0 00-2.94 5.53h-4.65v4.76A4.66 4.66 0 0023.37 41a21 21 0 001.52 5.86h-2.46v4.76a4.64 4.64 0 004.64 4.63c2.38 0 3.36.15 4.71-.66a20.88 20.88 0 005 2.73l-.33 2.29h15.68l-.32-2.28a20.89 20.89 0 005-2.76c1.39.86 2.45.69 4.75.69a4.64 4.64 0 004.64-4.63v-4.82h-2.53a21 21 0 001.52-5.87 4.64 4.64 0 003.58-4.51v-4.75zm-22.92 5.37a1.6 1.6 0 011.15 2.7 1.6 1.6 0 01-2.7-1.14 1.56 1.56 0 011.55-1.56zm15.93 9.76h-5.6v4.76a4.67 4.67 0 00.25 1.5 17.85 17.85 0 01-3.5 2L49.8 44.15l-.05-.39a7.49 7.49 0 01-10.88 0l-.05.39-1.62 10.93a18.13 18.13 0 01-3.43-2 4.67 4.67 0 00.23-1.51v-4.76h-5.65a17.59 17.59 0 01-1.81-5.74 4.66 4.66 0 004.86-4.64v-4.76h-3.62A17.63 17.63 0 0130.71 27h1.64A4.64 4.64 0 0037 22.34a17.81 17.81 0 015.74-1.49v13.34a4.69 4.69 0 103.12 0V20.81a17.75 17.75 0 015.82 1.53A4.64 4.64 0 0056.3 27h1.55a17.84 17.84 0 012.93 4.7h-3.6v4.75A4.64 4.64 0 0062 41.06a17.82 17.82 0 01-1.79 5.75z"
          />
        </g>
      </g>
    </svg>
  )
}

export default Icon10
