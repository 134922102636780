import { Form, Formik } from 'formik';
import React from 'react';
import * as Yup from 'yup';
import './search.scss';
import { withRouter } from 'react-router-dom';
import { useSelector } from 'react-redux';
import useRequest from 'hooks/use-request';
import { API_SEARCH_CITIES } from 'utils/api';
// import {isMobile} from 'react-device-detect';

import AsyncSelectFormik from 'components/form/select/async-select';
import InputFormik from 'components/form/input/inputFormik';
import SelectFormik from 'components/form/select/selectFormik';
import SelectorInline from 'components/selectors/selector-inline';

const fieldCity = {
    name: "city",
    noOptionsMessage: () => "Pas de villes trouvées",
    loadingMessage: () => "Chargement...",
    placeholder: "Ville",
}

const options = [
    {
        id: 1,
        title: "Expérience",
        slug: "activity",
        initialValues: {
            category: 0,
            city: ""
        },
        form: {
            category: {
                name: "category",
                noOptionsMessage: () => "Pas de catégories trouvées",
                placeholder: "Catégorie",
            },
            city: fieldCity
        },
        validation: Yup.object().shape({
            category: Yup.number(),
            // city: Yup.number()
            city: Yup.string()
        })
    },
    {
        id: 2,
        slug: "road-trip",
        title: "Road Trip",
        initialValues: {
            roadtrip: "",
            city: 0
        },
        form: {
            roadTrip: {
                name: "roadtrip",
                placeholder: "Nom du Road Trip",
            },
            city: fieldCity
        },
        validation: Yup.object().shape({
            roadtrip: Yup.string(),
            // city: Yup.number(),
            city: Yup.string()
        })
    }
]

const BASE_BORDER_WIDTH = 3;

const selectorStyle = {
    container: provided => ({
        ...provided,
        height: "100%"
    }),
    control: (provided, state) => ({
        ...provided,
        height: "100%",
        margin: 0,
        borderWidth: BASE_BORDER_WIDTH,
        borderColor: state.isFocused ? "#000" : "#ced4da",
        borderRadius: 0,
        padding: "0.15rem",
        backgroundColor: "#fff",
        width: "100%",
        ":hover": {
          borderColor: state.isFocused ? "#000" : "#ced4da"
        }
    }),
    valueContainer: provided => ({
        ...provided,
        flex: 1,
        justifyContent: "center"
    }),
    dropdownIndicator: provided => ({
        ...provided,
        color: "#707070"
    })
}

const OldSearch = (props) => {
    const [select, setSelect] = React.useState((props.defaultSelect && options.filter(el => el.slug === props.defaultSelect)[0]?.id) || options[0].id);
    const option = React.useMemo(() => options.filter(el => el.id === select)[0], [select]);
    const {categories} = useSelector(state => state.categories);
    const [handleRequest] = useRequest();

    const handleSubmit = (values) => {
        const url = new URLSearchParams()

        if (values.city) {
            url.append("q", values.city)
            url.append("cn", values.city_name)
        }

        if (select === 1) {
            if (values.category)
                url.append("c", values.category)

            if (!values.city && !values.category)
                return;

            props.history.push(`/search-view?${url.toString()}`)
        } else {
            if (values.roadtrip)
                url.append("n", values.roadtrip)

            if (!values.city && !values.roadtrip)
                return;

            props.history.push(`/roadtrips?${url}`)
        }
    }

    const debounceSearch = (v, cb) => {
        handleRequest("get", API_SEARCH_CITIES(v), {}, {}, (data, status) => {
            if (status === "Done") {
                cb(data.map(el => ({
                    label: el.text,
                    value: el.id
                })));
            } else if (status === "Error") {
                console.error(data);
            }
        })
    }

    return (
        <div className={`w-100 px-2 pt-2 bg-white ${props.className}`} style={{
            paddingBottom: "2rem"
        }}>
            <div className="col-12 col-md-4">
                <SelectorInline options={options} onSelect={setSelect} selection={select} />
            </div>
            <Formik
                initialValues={option.initialValues}
                validationSchema={option.validation}
                onSubmit={handleSubmit}
            >
                {
                    ({handleSubmit, ...formik}) => (
                        <Form onSubmit={handleSubmit}>
                            <div className="row m-0">
                                <div className="col-12 pr-md-0 col-md-4">
                                    {
                                        option?.form?.roadTrip ?
                                        <InputFormik {...option.form.roadTrip} {...formik} classes="search-field h-100" parentClasses="m-0" />
                                        :
                                        <SelectFormik isClearable {...option.form.category} {...formik} styles={selectorStyle} options={categories.map(el => ({
                                            label: el.title,
                                            value: el.id,
                                        }))} classNames="m-0 h-100" />
                                    }
                                </div>
                                <div className="col-12 px-md-0 col-md-4">
                                    <AsyncSelectFormik defaultOptions cacheOptions loadOptions={debounceSearch} styles={selectorStyle} {...option.form.city} {...formik} classNames="m-0 h-100" />
                                </div>
                                <div className="col-12 pl-md-0 col-md-4">
                                    <button type="submit" className="btn btn-dark rounded-0 w-100 h-100 font-weight-bold">Rechercher</button>
                                </div>
                            </div>
                        </Form>
                    )
                }
            </Formik>
        </div>
    );
};

export default withRouter(OldSearch);