import { Formik } from 'formik';
import useRequest from 'hooks/use-request';
import SuggestPage from 'pages/SuggestPage/SuggestPage';
import React, {useState} from 'react';
import { Modal } from 'react-bootstrap';
import { API_SEND_SUGGESTIONS } from 'utils/api';
import * as Yup from 'yup';

import './suggest.scss'
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Message } from "semantic-ui-react";


const Suggest = ({ onHide }) => {

  const {t} = useTranslation(["henriTrip"])

  const suggestForm = {
    name: {
      name: "title",
      label: t("touristicSites.suggest_touristicSite.name"),
      placeholder: t("touristicSites.suggest_touristicSite.name"),
      required: true
    },
    description: {
      name: "description",
      label: t("touristicSites.suggest_touristicSite.descriptionSite"),
      placeholder: "Description",
      required: true
    },
    category:{
      name: "category",
      label: t("touristicSites.suggest_touristicSite.category"),
      required: true,
      noOptionsMessage: () => t("touristicSites.suggest_touristicSite.noCategory"),
      placeholder: t("touristicSites.suggest_touristicSite.category"),
      options:
        [
          {label: t("general.category.activity"), value: '53'},
          {label: t("general.category.religiousSite"), value: '54'},
          {label: t("general.category..parkGarden"), value: '55'},
          {label: t("general.category.wineTourisme"), value: '56'},
          {label: t("general.category.historicalMonument"), value: '57'},
          {label: t("general.category.cavePrehistoric"), value: '58'},
          {label: t("general.category.museum"), value: '59'},
          {label: t("general.category.wellness"), value: '60'},
          {label: t("general.category.park"), value: '61'},
          {label: t("general.category.henriChoice"), value:'62'}
        ]
    },


    email: {
      name: "email",
      label: t("touristicSites.suggest_touristicSite.email"),
      placeholder: t("touristicSites.suggest_touristicSite.mail"),
      required: false
    },
    phone_number: {
      name: "phone_number",
      label: t("touristicSites.suggest_touristicSite.phone"),
      placeholder: t("touristicSites.suggest_touristicSite.phone"),
      required: false,
      mask: "+33 9 99 99 99 99"
    },
    address: {
      name: "address",
      label: t("touristicSites.suggest_touristicSite.address"),
      placeholder: t("touristicSites.suggest_touristicSite.address"),
      required: true
    },
    image: {
      name: "images",
      new_images_name: "new_images",
      label: t("touristicSites.suggest_touristicSite.image"),
      required: true,
      isMulti: true,
    },
    website: {
      name: "website_url",
      label: t("general.website"),
      placeholder: "www.votresite.fr",
      required: false
    },

  }

  const suggestValidation = Yup.object().shape({
    [suggestForm.description.name]: Yup.string().required(t("general.require")),
    [suggestForm.name.name]: Yup.string().required(t("general.require")),
    [suggestForm.category.name]: Yup.string().required(t("general.require")),
    [suggestForm.address.name]: Yup.string().required(t("general.require")),
    [suggestForm.image.name]: Yup.array().min(1).required(t("general.require")),
    [suggestForm.website.name]: Yup.string(),
    [suggestForm.email.name]: Yup.string(),
    [suggestForm.phone_number.name]: Yup.string(),
    // [suggestForm.image.name]: "",
    // [suggestForm.image.new_images_name]: "",
  })

  const suggestInitial = {
    [suggestForm.description.name]: "",
    [suggestForm.category.name]: "",
    [suggestForm.address.name]: "",
    [suggestForm.name.name]: "",
    [suggestForm.website.name]: "",
    [suggestForm.phone_number.name]: "",
    [suggestForm.email.name]: "",
    [suggestForm.image.name]: [],
    [suggestForm.image.new_images_name]: [],
  }


  const [handleRequest,status] = useRequest();
  const {user} = useSelector(state => state.user);
  const config = useSelector(state => state.data);
  const [success, setSuccess] = useState(false);
  const handleSubmit = (v, formikBag) => {
    handleRequest("post", API_SEND_SUGGESTIONS, v, config, (data, status) => {
      if (status === "Done") {
        console.log(data);
        setSuccess(true);
      } else if (status === "Error") {
        console.error(data);
        formikBag.setStatus(t("touristicSites.suggest_touristicSite.error"))
      }
    })
  }

  return (
    <Modal.Body>
      {
        success ?
          <Message
            success
            header={t("touristicSites.suggest_touristicSite.errorHeader")}
            content={t("touristicSites.suggest_touristicSite.errorContent")}
          />
          :
        <Formik
        initialValues={suggestInitial}
        validationSchema={suggestValidation}
        onSubmit={handleSubmit}
        >
        {
          (formikProps) => {
            return <SuggestPage loading={status} formikProps={formikProps} suggestForm={suggestForm} onHide={onHide} user={user} />
          }
        }
        </Formik>
      }
    </Modal.Body>
  );
};

export default Suggest;