import PaymentPage from 'pages/UserPage/PaymentPage/PaymentPage';
import React, { useEffect, useState } from "react";
import useRequest from "../../../../hooks/use-request";
import { useSelector } from "react-redux";
import { API_GET_TRANSACTIONS } from "../../../../utils/api";




const PaymentMethod = () => {

    const [handleRequest, status] = useRequest()
    const config = useSelector(state => state.data);
    const {user} = useSelector(state => state.user);
    const [transactions, setTransactions] = useState(null);

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    useEffect(() => {
        handleRequest("get", API_GET_TRANSACTIONS, {}, config, (data, status) => {
            if (status === "Done") {
                const parsedRTTransaction = data.map(rt => {
                    return rt
                });
                setTransactions(parsedRTTransaction)
            } else if (status === "Error") {
                console.error(data);
            }
        })
        // eslint-disable-next-line
    }, [])

    return (
        <PaymentPage transactions={transactions}/>
    );
};

export default PaymentMethod;