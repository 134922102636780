import useRequest from 'hooks/use-request';
import OrdersRoadTripsPage from 'pages/UserPage/RoadtripsPage/OrdersRoadTripsPage/OrdersRoadTripsPage';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { API_GET_ORDERS } from 'utils/api';
import { groupsId, seasonsId, transportsId } from "../../../utils/utils";

const OrdersRoadtrips = () => {
  const [currentPage, onChangePage] = useState(0);
  const [handleRequest, status] = useRequest()
  const config = useSelector(state => state.data);
  const {user} = useSelector(state => state.user);
  const [roadtrip, setRoadtrip] = useState(null);

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    handleRequest("get", API_GET_ORDERS, {}, config, (data, status) => {
      if (status === "Done") {
        const parsedRTOrders = data.map(rt => {
          let categoriesTransport = [];
          let categoriesGroup = [];
          let categoriesSeasons = [];
          rt.record.is_user_roadtrip = rt.record.user_id === rt.user_id;
          rt.record.categoriesTransport= categoriesTransport
          rt.record.categoriesGroup= categoriesGroup
          rt.record.categoriesSeasons = categoriesSeasons

          rt.record.categories.forEach(el =>
          {
            if(transportsId.includes(el.id)){
              categoriesTransport.push(el)
            }
          })
          rt.record.categories.forEach(el =>
          {
            if(groupsId.includes(el.id)){
              categoriesGroup.push(el)
            }
          })

          rt.record.categories.forEach(el =>
          {
            if(seasonsId.includes(el.id)){
              categoriesSeasons.push(el)
            }
          })
          rt.record.feedbacks = {
            note: rt.record.avg_notes || 0,
            len: rt.record.notes_counter,
          }

          rt.record.user = {
            firstname: user?.firstname,
            lastname: user?.lastname,
          }
          return rt.record
        });
        setRoadtrip(parsedRTOrders)
      } else if (status === "Error") {
        console.error(data);
      }
    })
    // eslint-disable-next-line
  }, [])
  return (
    roadtrip ?
    <OrdersRoadTripsPage
      loading={status !== "Done"}
      roadtrips={roadtrip}
      currentPage={currentPage}
      onChangeStep={onChangePage}
    />
    :
      null
  );
};

export default OrdersRoadtrips;