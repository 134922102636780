import React, {useEffect, useState} from 'react';
import ImgLazy from "../../../components/Lazyload/ImgLazy/ImgLazy";

const ActivityImages = ({activeContent, srcDefault}) => {
  const [bg, setBG] = useState(srcDefault);

  useEffect(() => {
    const l = activeContent?.layout ? JSON.parse(activeContent.layout) : {};
    setBG(l['0']?.url?.replace(/\?.*/, '') || srcDefault?.replace(/\?.*/, ''));
  }, [activeContent, srcDefault]);
  return (
    <ImgLazy
      visible={true}
      src={bg}
      className="px-1 touristic-site-page--header-image mt-sm-3"
    />
  )
};

export default ActivityImages;
