import React from 'react';
import L from 'leaflet';
import ReactDOMServer from 'react-dom/server';
import Icon9 from '../ReactIcons/icon9';
import { ICON_ANCHOR, ICON_SIZE } from '.';

const mapIcon9 = L.divIcon({
        html: ReactDOMServer.renderToString(<Icon9 />),
        iconSize: ICON_SIZE,
        iconAnchor: ICON_ANCHOR
    });

export default mapIcon9;