import SecurityPage from 'pages/UserPage/SecurityPage/SecurityPage';
import React from 'react';




const Security = () => {

    return (
        <SecurityPage/>
    );
};

export default Security;