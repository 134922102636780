import React, { useEffect, useState } from "react";
import useRequest from 'hooks/use-request';
import { useQueryParam } from "use-query-params";
import { useHistory } from 'react-router-dom'
import { API_CONFIRM_EMAIL } from "../../utils/api";
import ConfirmEmailPage from "../../pages/AuthPage/ConfirmEmailPage";

const ConfirmEmail = () => {
  const [handleRequest] = useRequest();
  const history = useHistory();
  const [token] = useQueryParam('token')
  const [valid, setValid] = useState("Loading");

  if(!token){
    history.push('/')
  }

  useEffect(() => {
    handleRequest("get", API_CONFIRM_EMAIL(token), {}, {}, (data, status) => {
      if (status === "Done") {
        setValid("Valid")
      }else if (status === "Error"){
        setValid("Error")
      }
    })
  },[])

  return <ConfirmEmailPage valid={valid} />
}

export default ConfirmEmail;