import React from 'react';
import { Radio } from 'semantic-ui-react'
import useDocumentTitle from 'hooks/use-document-title';
import { useTranslation } from "react-i18next";


const PrivacyPage = () => {
    const {t} = useTranslation(["henriTrip"])
    useDocumentTitle(t("account.privacyPage.documentTitle"))

    return (
    <div className="container">
        <div className="col-lg-7 offset-md-1 mt-4">
            <div className="row">
                <h2>{t("general.privacy&sharing")}</h2>
            </div>
            <div className="row mt-4">
                <div className="col-lg-12">
                    <h5>{t("account.privacyPage.privacy.title")}</h5>
                </div>
            </div>
            <div className="row mt-2">
                <div className="col-lg-10">
                    <p>{t("account.privacyPage.privacy.description")}</p>
                </div>
            </div>
            <hr/>
            <div className="row mt-3">
                <div className="col-lg-12">
                    <h5>{t("account.privacyPage.shareFacebook.title")}</h5>
                </div>
            </div>
            <div className="row mt-2">
                <div className="col-lg-10">
                    <p>{t("account.privacyPage.shareFacebook.description")}</p>
                </div>   
                <div className="col-lg-2">
                    <Radio toggle />
                </div>    
            </div>
            <hr/>
            <div className="row mt-3">
                <div className="col-lg-12">
                    <h5>{t("account.privacyPage.shareBrowser.title")}</h5>
                </div>
            </div>
            <div className="row mt-2">
                <div className="col-lg-10">
                    <p>{t("account.privacyPage.shareBrowser.description")}</p>
                </div>
                <div className="col-lg-2">
                    <Radio toggle />
                </div>
            </div>
        </div>    
    </div>
               
    );
};

export default PrivacyPage;