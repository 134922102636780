import React, { useMemo } from "react";
import { REQUIRED_IDENTIFIER } from 'utils/texts';

import "./feedback-notation.scss"

const FeedbackNotation = ({ values, name, value, label, required, className, errors, onChange, setFieldValue, setFieldTouched }) => {
  const note = useMemo(() =>
    parseInt(values ? values[name] : value),
    [values, name, value]
  )

  const handleNote = (v) => {
    if (onChange) {
      onChange(v);
      return;
    }
    setFieldTouched(name, true)
    setFieldValue(name, v)
  }

  return (
    <>
      {
        label &&
        <label>{label}{required && REQUIRED_IDENTIFIER}</label>
      }
      <div className={"d-flex " + className}>
        {
          [1, 2, 3, 4, 5].map(el => {
            return (
              <button
                key={el}
                type="button"
                onClick={() => handleNote(el)}
                className={`feedback-pill-notation font-weight-medium ${note >= el ? "active" : ""}`}
              >
                {el}
              </button>
            )
          })
        }
      </div>
      {
        (errors && errors[name]) &&
        <p className="text-danger">{errors[name]}</p>
      }
    </>
  );
};

export default FeedbackNotation;
