import Collapsible from "../Collapsible";
import { ReactComponent as ChevronDown } from "../../../../../src/assets/img/logo/chevron_down.svg";
import React from "react";

const TipContainer = ({HeaderComponent, children, id, collapsed, setActiveTip, ...rest}) => (
  <Collapsible collapsed={collapsed}>
    {(isCollapsed, toggleCollapsed) => (
      <div
        style={{borderRadius: '5px', border: '1px solid', padding: '1rem'}}
        {...rest}
      >
        <div
          onClick={(e) => {
            toggleCollapsed(e);
            setActiveTip(id);
          }}
          style={{
            display: 'flex',
            gridTemplateColumns: 'auto 1fr',
            gridGap: '1rem',
            justifyContent: 'space-between',
          }}
        >
          <div>
            {HeaderComponent}
          </div>
          <ChevronDown
            style={{
              height: 15,
              width: 'auto',
              margin: 0,
              transform: isCollapsed ? 'rotate(180deg)' : 'none',
              transition: 'transform 0.3s ease-in-out',
            }}
          />
        </div>
        {isCollapsed && (
          <div style={{marginTop: '1rem'}}>
            {children}
          </div>
        )}
      </div>
    )}
  </Collapsible>
);

export default TipContainer;