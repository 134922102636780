import SharePage from 'pages/SharePage/SharePage';
import React from 'react';

const Share = ({ show, onHide, link, title, error }) => {

  return (
    <SharePage link={link} title={title} error={error} show={show} onHide={onHide} />
  );
};

export default Share;