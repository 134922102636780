import React from "react";
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { useHistory } from "react-router";
import './AccountCard.scss';


const AccountCard = (props) => {
  const history = useHistory();
  return(
    <div className="col-lg-4 col-12 mt-3 mt-lg-0">
      <div className="shadow-lg col-12 bg-white ml-0 card" onClick={()=>history.push(props.link)}>
        <div className="row mt-3">
          {props.children}
        </div>
        <div className="row pl-lg-3 pl-1 mt-2">
          <h5>{props.title}</h5><ArrowForwardIosIcon className="ml-lg-2 d-none d-lg-block"/>
        </div>
        <div className="row pl-lg-3 pl-1 pr-1 pr-lg-3 mt-lg-2">
          <p>{props.description}</p>
        </div>
      </div>
    </div>
  );
}

export default AccountCard;