import styled, { keyframes } from 'styled-components';
import { rgba } from 'polished';

const spin = keyframes`
  0% {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(720deg);
  }
`;

const Loader = styled.div`
  width: ${props => props.size || '2em'};
  height: ${props => props.size || '2em'};
  border: ${props => props.thickness || '0.25em'} solid ${props => rgba(props.color || props.theme.colors.primary, 0.25)};
  border-left-color: ${props => props.color || props.theme.colors.primary};
  border-radius: 2em;
  animation: ${spin} 1.5s cubic-bezier(.53,.14,.38,.86) infinite;
  margin: auto;
  box-sizing: border-box;
`;

export default Loader;