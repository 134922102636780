import {useTranslation} from 'react-i18next';
import {addTouristicSiteToI18n, getTouristicSiteFromI18n} from '../../utils/touristic_sites/i18n_conversion';

const useTouristicSiteTranslation = (touristicSite = {}) => {
  const { t } = useTranslation(['api']);

  addTouristicSiteToI18n(touristicSite);

  return getTouristicSiteFromI18n(touristicSite, t);
};

export default useTouristicSiteTranslation;