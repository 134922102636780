import React from 'react';
import Title from 'components/header/title/title';

import "./PartnerSectionsVideo.scss"
import { useTranslation } from "react-i18next";
import HomeCard from "../../../components/homePage/homeCard/HomeCard";


const PartnerSectionsVideo = (props) => {

  const content = [
    {
      title : "NUMÉRISER",
      description : "Mettez à disposition de vos visiteurs une application que vous pouvez administrer de manière simple et autonome.",
      image : "https://general-henri-1-paris.s3.fr-par.scw.cloud/partenaire_page/gerer-logo.png",
      margin: "mt-2 mt-md-2"
    },
    {
      title : "CENTRALISER",
      description : "Regroupez l'ensemble de vos services digitaux sur une seule et même plateforme.",
      image : "https://general-henri-1-paris.s3.fr-par.scw.cloud/partenaire_page/centraliser.png",
      margin: "mt-4 mt-md-4 mt-lg-2"
    },
    {
      title : "COMMUNIQUER",
      description : "Utilisez un système intégré de messagerie et de remontée d'incidents en temps réel.",
      image : "https://general-henri-1-paris.s3.fr-par.scw.cloud/partenaire_page/communiquer.png",
      margin: "mt-4 mt-md-4 mt-lg-2"
    }
  ]


  return (
    <div className="container">
      <div className="row">
        <div className="col">
          <Title title="Notre solution : une application clé en main pour votre site touristique"/>
        </div>
      </div>
      <div className="row">
        <div className="col-12 col-lg-6 d-flex align-items-center">
          <div className="section-video__video-responsive">
            <video controls autoPlay className="section-video__video" loop muted >
              <source src="https://general-henri-1-paris.s3.fr-par.scw.cloud/partenaire_page/Video%20BtoB%20Motion%20Design%20VFinale.mp4" type="video/mp4"/>
            </video>
          </div>
        </div>
        <div className="col-12 col-lg-6">
          <div className="row mt-2 mt-md-0">
            <div className="col-12">
              <div className="row mt-3">
                {
                  content?.map((el, i) =>
                    <div className="row mt-2 mt-lg-2">
                      <div className="col-2 mt-0 mt-lg-2">
                        <img src={el.image} className="section-video__image" alt="" />
                      </div>
                      <div className="col-10">
                        <div className="mt-2 mt-md-3">
                          <h3>{el.title}</h3>
                          <p className="section-video__text">{el.description}</p>
                        </div>
                      </div>
                    </div>
                  )
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
};

export default PartnerSectionsVideo;