export const addTouristicSite = (payload) => ({
    type: 'ADD_TOURISTIC_SITES',
    payload
})

export const removeTouristicSite = () => ({
    type: 'REMOVE_TOURISTIC_SITES'
})

export const addDownloadTS = (payload) => ({
    type: 'ADD_DOWNLOAD_TOURISTIC_SITE',
    payload
})

export const removeDownloadTS = (payload) => ({
    type: 'REMOVE_DOWNLOAD_TOURISTIC_SITE',
    payload
})

export const setSelectedActivities = (payload) => ({
    type: 'SET_SELECTED_ACTIVITIES',
    payload
})

export const removeSelectedActivities = () => ({
    type: 'REMOVE_SELECTED_ACTIVITIES'
})