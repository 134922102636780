import useRequest from 'hooks/use-request';
import React, { useState, useEffect, useContext } from 'react';
import { useSelector } from 'react-redux';
import { API_GET_EXPERIENCE } from "utils/api";
import { useHistory } from 'react-router-dom';
import ExperiencePage from "../../../pages/RoadTripsPage/ExperiencePage/ExperiencePage";
import Loader from "../../../_ui/_v2/components/Loader";
import styled, { ThemeProvider } from "styled-components";
import theme from "../../../_ui/_v2/styles/theme";
import GlobalStyle from "../../../_ui/_v2/styles/global_style";
import NavBarContext from "../../../_contexts/NavBarProvider";
import { Helmet } from "react-helmet";
import {useLocation} from "react-router";

const PageContainer = styled.div`
  background-color: ${props => props.theme.colors.white};
`;

const ExperienceLanding = ({ match, roadtripType= 'experience' }) => {
  const [handleRequest, status] = useRequest();
  const config = useSelector(state => state.data);
  const [roadtrip, setRoadtrip] = useState({});
  const history = useHistory();
  const navBarContext = useContext(NavBarContext);

  const query = new URLSearchParams(useLocation().search);

  useEffect(() => {
    if (roadtrip.id) {
      const isExperience = roadtrip.amount > 0;
      if (isExperience && roadtripType !== 'experience') {
        history.replace(`/experiences/${match.params.id}`);
      } else if (!isExperience && roadtripType === 'experience') {
        history.replace(`/trips/${match.params.id}`);
      }
    }
  }, [roadtrip.amount]);

  const fetchRoadtrip = () => {
    // a modifier
    const token = query.get('resource_access_token');
    const params = token ? `?resource_access_token=${query.get('resource_access_token')}` : '';
    handleRequest("get", `${API_GET_EXPERIENCE(match.params.id)}${params}`, {}, config, (response, status) => {
      if (status === "Done") {
        const data = { ...response };
        data.trip_days.sort((a, b) => a.day_number - b.day_number)
        setRoadtrip(data);
      } else if (response.status === 404) {
        history.push('/error/404')
      } else {
        console.error(response)
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  useEffect(() => {
    fetchRoadtrip();
    navBarContext.setNavBar('v2');
    navBarContext.setFooterVisible(false);
    navBarContext.setBottomBarAppearOnScroll(true);
    return () => {
      navBarContext.restoreNavBar();
      navBarContext.restoreFooterVisible();
      navBarContext.setBottomBarAppearOnScroll(false);
    };
  }, []);

  return (
    <PageContainer>
      <Helmet>
        <title>
          {
            roadtrip.title?.text_fr
              ? `${roadtrip.title.text_fr} - Expérience | Henri Trip`
              : 'Expérience | Henri Trip'
          }
        </title>
      </Helmet>
      {roadtrip?.id
        ? (
          <ExperiencePage
            roadtrip={roadtrip}
            fetchRoadtrip={fetchRoadtrip}
          />
        )
        : (
          <div style={{ position: 'relative', height: '100vh', width: '100vw' }}>
            <Loader style={{ position: 'absolute', inset: 0 }} />
          </div>
        )
      }
    </PageContainer>
  )
};

const ExperienceLandingWithTheme = props => (
  <ThemeProvider theme={theme}>
    <GlobalStyle />
    <ExperienceLanding {...props} />
  </ThemeProvider>
);
export default ExperienceLandingWithTheme;
