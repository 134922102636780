import React from 'react';
import { Link, Redirect, Route, Switch } from 'react-router-dom';
import Conversations from 'containers/User/Chat/Conversations';
import Account from 'containers/User/Account/Account';
import Downloads from 'containers/User/Downloads/Downloads';
import BoughtHistory from 'containers/User/BoughtHistory/BoughtHistory';
import FavoriteRoadtrips from 'containers/User/FavoriteRoadtrips/FavoriteRoadtrips';
import Roadtrips from 'containers/User/Roadtrips/Roadtrips';
import PaymentMethod from 'containers/User/Account/PaymentMethod/Payment';
import Notification from 'containers/User/Account/Notification/Notification';
import Privacy from 'containers/User/Account/Privacy/Privacy';
import Security from 'containers/User/Account/Security/Security';
import Information from 'containers/User/Account/Information/Information';
import AmbassadorDashboard from 'containers/User/Account/AmbassadorDashboard/AmbassadorDashboard';
import AmbassadorPayment from 'containers/User/Account/AmbassadorPayment/AmbassadorPayment';
import OrdersRoadtrips from "../../containers/User/OrdersRoadtrips/OrdersRoadtrips";
import Appointments from "../../containers/User/Appointments/Appointments";

const UserPage = ({ userLinks, activeItem, unreadMsg }) => {
    return (
        <>
            <nav className="navbar navbar-expand-lg navbar-light shadow-sm position-sticky bg-white" style={{zIndex: 98, top: 0, left: 0, right: 0}}>
                <Link to="/home">
                    <img  width="250" src="/assets/logo/Logo_henri-side.svg" alt="lol" />
                </Link>
            </nav>
            <div className="container min-vh-75">
                <Switch>
                    <Route path="/user/account" component={Account} />
                    <Route path="/user/downloads" component={Downloads} />
                    <Route path="/user/conversations" component={Conversations} />
                    <Route path="/user/bought-history" component={BoughtHistory} />
                    <Route path="/user/roadtrips/orders" component={OrdersRoadtrips} />
                    <Route path="/user/roadtrips/favorite" component={FavoriteRoadtrips} />
                    <Route path="/user/roadtrips" component={Roadtrips} />
                    <Route path="/user/info" component={Information} />
                    <Route path="/user/appointments" component={Appointments} />
                    <Route path="/user/security" component={Security} />
                    <Route path="/user/transactions" component={PaymentMethod} />
                    <Route path="/user/notification" component={Notification} />
                    <Route path="/user/privacy" component={Privacy} />
                    <Route path="/user/ambassador" component={AmbassadorDashboard}/>
                    <Route path="/user/ambassador-payment" component={AmbassadorPayment}/>
                    <Redirect to="/user/account" />
                </Switch>
            </div>
        </>
    );
};

export default UserPage;