import { Button } from 'components/form/button';
import FileInput from 'components/form/file-browser/fileInput';
import InputFormik from 'components/form/input/inputFormik';
import InputMaskFormik from 'components/form/input/inputMaskFormik';
import TextAreaFormik from 'components/form/text-area/textAreaFormik';
import SelectFormik from 'components/form/select/selectFormik';
import { Form } from 'formik';
import React, { useState } from "react";
import "./SuggestPage.scss";
import { useTranslation } from "react-i18next";
import AutocompletePlaceAddress from "../../components/search/autocompletesearchaddressonly";
import { SvgIcon } from '@material-ui/core';
import CloseIcon from '@mui/icons-material/Close';
const SuggestPage = ({ formikProps, suggestForm, onHide, user, loading }) => {
    const {t} = useTranslation(["henriTrip"])
    const [address, setAddress] = useState({
        address : user.address
    });
    const [state, setState] = useState();

    const onSelectAddress = (address) => {
        setAddress({
            address: address.place_name,
            buttonAddressDisabled: false,
        });
        formikProps.setFieldValue("address", address.place_name)
    };

    const onClearAddress = () => {
        setAddress({
            address: null,
            locationIsLoading: false,
            buttonAddressDisabled: true,
        });
    };

    return (
        <Form onSubmit={formikProps.handleSubmit}>

            <div className="d-flex justify-content-end">
                <SvgIcon
                    className="me-3 mb-1"
                    component={CloseIcon}
                    onClick={() => onHide()}
                    width="20"
                    height="20"
                />
            </div>
            <div>
                <h4 className="pb-2 text-primary font-weight-bold">{t("general.suggestSite")}</h4>
                <p>{t("touristicSites.suggest_touristicSite.description")}
                </p>
                <p>
                    {t("touristicSites.suggest_touristicSite.descriptionSuite")}
                </p>
                {
                    formikProps.status &&
                    <div className="bg-danger rounded p-2 text-white">
                        {formikProps.status}
                    </div>
                }
                <InputFormik {...formikProps} {...suggestForm.name} />
                <TextAreaFormik {...formikProps} {...suggestForm.description} />
                <SelectFormik {...formikProps} {...suggestForm.category} onChange={formikProps.handleChange}  />
                <InputFormik {...formikProps} {...suggestForm.website} />
                <InputFormik {...formikProps} {...suggestForm.email} />
                <InputMaskFormik {...formikProps} {...suggestForm.phone_number} />
                <p>{t("touristicSites.suggest_touristicSite.address")} *</p>
                <div className="overlap-group-suggest">
                    <div className="adresse-gauche">
                        <AutocompletePlaceAddress
                          placeholder="Ex : 45 Rue Alfred Dumeril, 31000 Toulouse"
                          onSelect={onSelectAddress}
                          onClear={onClearAddress}
                          onFocusInp={() => setState({ focus: true })}
                          onBlurInp={() => setState({ focus: true })}
                        />
                    </div>
                    {(formikProps.touched['address'] && formikProps.errors['address']) || formikProps.error ? (
                      <div className="fv-plugins-message-container">
                          <div className="fv-help-block text-danger">
                              {formikProps.errors['address'] || formikProps.error}
                          </div>
                      </div>
                    ) : null}
                </div>
                <FileInput accepts={'image/png,image/jpg,image/jpeg'} maxSize={2000000} {...formikProps} {...suggestForm.image} buttonStyle={{color:"dark", outline: true, title:t("touristicSites.suggest_touristicSite.addImage")}}/>
                <div className="d-flex justify-content-end">
                    <Button title={t("general.buttonCancel")} onClick={onHide} color="secondary" />
                    <Button status={loading} title={t("touristicSites.suggest_touristicSite.send")} type="submit" className="mx-2" color="primary"/>
                </div>
            </div>
        </Form>
    );
};

export default SuggestPage;