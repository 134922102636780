import React from 'react';

const FooterPageElementTitle = (props) => {
    return (
          <a href={props.link} target="_blank" rel="noreferrer">
            <h3 className="m-0 text-white w-100 h-100 my-1 px-2 d-flex">{props.text}</h3>
          </a>
    );
};

export default FooterPageElementTitle;