import React from "react";
import { Button } from 'components/form/button';
import Title from 'components/header/title/title';
import './AmbassadorSectionsJoin.scss';

const contentEtre = [
    {
        content: "Passionné(e) de voyage et d'aventure;"
    },
    {
        content: "Actif(ve) sur les réseaux sociaux et proche de ta communauté;"
    },
    {
        content: "Motivé(e) et engagé(e) à évoluer sur Instagram tel un(e) réel(e) entrepreneur;"
    },
    {
        content: "Déterminé(e) à voyager à nos côtés;"
    },
]

const contentAvoir = [
    {
        content: "Des valeurs correspondantes à celles d'Henri Trip;"
    },
    {
        content: "La volonté de faire partie d'une aventure unique;"
    },
    {
        content: "Les qualités requises pour être sélectionné(e) parmi des dizaines de candidats;"
    }
]

const AmbassadorSectionsJoin = () =>  (
    <div className="container mt-4">
        <div className="d-flex justify-content-center d-inline d-lg-none">
            <img className="ambassador-join__image-top" src="https://general-henri-1-paris.s3.fr-par.scw.cloud/ambassadors/influenceurs.png" alt="influenceurs"/>
        </div>
        <Title noTopPad={true} title="Comment rejoindre l'aventure ?"/>
        <div className="wrap">
                <h3>Tu penses être :</h3>
                <img className="ambassador-join__image-right d-none d-lg-inline" src="https://general-henri-1-paris.s3.fr-par.scw.cloud/ambassadors/influenceurs.png" alt="influenceur"/>
                {
                    contentEtre?.map((el,i) =>
                        <div className="ambassador-join__text-div">
                            <div className="ambassador-join__dot-div">
                                <span className="ambassador-join__dot"></span>
                            </div>
                            <p className="ambassador-join__text">
                                {el.content}
                            </p>
                        </div>
                    )
                }

            <h3 className="mt-3">Tu penses avoir :</h3>
            {
                contentAvoir?.map((el,i) =>
                    <div className="ambassador-join__text-div">
                        <div className="ambassador-join__dot-div">
                            <span className="ambassador-join__dot"></span>
                        </div>
                        <p className="ambassador-join__text">
                            {el.content}
                        </p>
                    </div>
                )
            }
        </div>
        <div className="col d-flex justify-content-center mt-3 mt-md-4">
            <Button rounded className="px-3" title="Candidater" onClick={() => window.open("https://forms.office.com/r/gh46npYQ6S", '_blank')}/>
        </div>
    </div>
)

export default AmbassadorSectionsJoin;
