import React, { useMemo } from "react";

import "./feedback.scss";

const FeedbackRater = ({ size, active }) => {
  return (
    <div className="px-0 pt-1">
      <div className={`feedback-pill-${size} bg-${active ? "primary" : "muted"}`} />
    </div>
  )
}

const Feedback = ({note, feedbacks, size = "md", className, theme, noPadding, hideCounter}) => {
  const color = useMemo(() => theme === "dark" ? "white" : "dark", [theme]);

  return (
    <div className={`${noPadding ? "" : "py-0"} mb-1 d-flex align-items-center ` + (className ? className : "")}>

      {
        feedbacks !== 0 ?
        <>
        <div className="row m-0">
          {
            [1, 2, 3, 4, 5].map(el => <FeedbackRater key={el} active={el <= Math.round(note)} size={size} />)
          }
          <div className="font-weight-bold text-primary">{note?.toFixed(1)}</div>
        </div>
        {
          !hideCounter &&
          (
            size === "sm" ?
              <h6 className={`m-0 px-1 text-${color} font-weight-medium`}>({feedbacks || 0} avis)</h6>
              :
              <h3 className={`m-0 px-1 text-${color} font-weight-medium`}>({feedbacks || 0} avis)</h3>
          )
        }
      </>: <div style={{height: 21}}></div>}
    </div>
  );
};

export default Feedback;
