import React from 'react';
import {useTranslation} from "react-i18next";
import useDocumentTitle from "../../../hooks/use-document-title";
import Title from "../../../components/header/title/title";
import {Loader} from "semantic-ui-react";
import AppointmentsCard from "../../../components/appointments/appointmentsCard";

const AppointmentsPage = ({ loading, reservations }) => {
  const {t} = useTranslation(["henriTrip"])
  useDocumentTitle(t("general.myAppointments"));

  return (
    <div className="min-vh-75">
      <Title title={t("general.myAppointments")}/>
      <div className="row">
        {
          loading ?
            <Loader active className="mt-4" />
            :
            reservations.sort(el => new Date() - new Date(el.start_date)).map( el => (
              <div key={el.id} className="col-12 col-md-4 py-2 position-relative">
                <AppointmentsCard info={el}/>
              </div>
            ))
        }
      </div>
    </div>
  )
}

export default AppointmentsPage;