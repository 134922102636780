import { deleteUserRoadTrip } from 'actions/road-trips';
import { Button } from 'components/form/button';
import useRequest from 'hooks/use-request';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { API_DELETE_ROADTRIP } from 'utils/api';
import ModalForm from "../../../../components/formModal/ModalForm";
import {useHistory} from "react-router-dom";

const DeleteRoadTrip = ({ show, onHide, id }) => {
  const [handleRequest, status] = useRequest();
  const dispatch = useDispatch()
  const config = useSelector(state => state.data);
  const history = useHistory();

  const handleDelete = () => {
    handleRequest("delete", API_DELETE_ROADTRIP(id), {}, config, (data, status) => {
      if (status === "Done") {
        dispatch(deleteUserRoadTrip(parseInt(id)))
        onHide();
        history.push('/user/roadtrips');
      } else if (status === "Error") {
        console.error(data);
      }
    })
  }

  return (
    <ModalForm
      isOpen={show}
      title={'Voulez-vous effacer ce road trip ?'}
      onClose={onHide}
    >
      <div className="p-3">
        <p>Toutes les données de ce road trip seront effacées et ne pourront pas être récupérées.</p>
        <div className="d-flex justify-content-between">
          <Button title="Annuler" color="secondary" className="mr-1" extended onClick={onHide} />
          <Button title="Supprimer" color="danger" className="ml-1" extended onClick={handleDelete} status={status === "Loading" ? status : null} disabled={status === "Loading"}/>
        </div>
      </div>
    </ModalForm>
  );
};

export default DeleteRoadTrip;