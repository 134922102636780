import { addUserRoadTrips } from "actions/road-trips";
import { Formik } from 'formik';
import useRequest from 'hooks/use-request';
import CreateRoadTripPage from 'pages/UserPage/RoadtripsPage/CreateRoadTripPage/CreateRoadTripPage';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
//import { NumberParam, useQueryParam } from 'use-query-params';
import { API_CREATE_ROADTRIP, API_GET_USER_ROADTRIPS, API_UPDATE_ROADTRIP } from "utils/api";
import { REQUIRED_MESSAGE } from 'utils/texts';
import * as Yup from 'yup';
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import CreateExperiencePage from 'pages/UserPage/ExperiencePage/ExperiencePage'

const CreateExperience = ({ onCancel, setSuggestModalXp, roadTrip, createRT }) => {
    const { t } = useTranslation(["henriTrip"])
    const [handleRequest, status] = useRequest();
    const config = useSelector(state => state.data);
    const dispatch = useDispatch();
    const { user } = useSelector(state => state.user);
    const history = useHistory();
    const createRTForm = {
        title: {
            name: "title",

            placeholder: t("general.name"),
            required: true
        },
        description: {
            name: "description",
            label: t("roadTripPage.createRoadTrip.description"),
            placeholder: t("general.description"),
        },
        image: {
            name: "image",
            new_images_name: "image",
            label: "Image du Road Trip"
        },
        state: {
            name: "public",
            label: t("roadTripPage.createRoadTrip.public")
        },
        touristic_sites: {
            name: "touristic_sites_ids",
            label: "Sites touristiques",
            placeholder: "Sites touristiques",
        },
        category: {
            name: "category",
            label: "Category",
        },
        place: {
            name: "place",
            placeholder: "Zone Geographique, Région ou Ville"
        },
        nbDay: {
            name: "day_number",
            placeholder: "Nombre de jours"
        },
        resume: [{
            image: "",
            description: "",
            ts: []
        }],
    }

    const createRTValidation = Yup.object().shape({
        [createRTForm.title.name]: Yup.string().min(3, "3 caractères minimum").max(50, "50 caractères maximum").required(REQUIRED_MESSAGE),
        [createRTForm.description.name]: Yup.string().max(2000, "2000 caractères maximum").nullable(),
        [createRTForm.state.name]: Yup.bool(),
        [createRTForm.category.name]: Yup.array(),
        [createRTForm.nbDay.name]: Yup.number().max(31, "31 jours maximum").required(REQUIRED_MESSAGE),
        [createRTForm.place.name]: Yup.string().min(3, "3 caractères minimum"),
    })

    const createRTInitial = {
        [createRTForm.title.name]: "",
        [createRTForm.description.name]: "",
        [createRTForm.image.name]: [],
        [createRTForm.touristic_sites.name]: [],
        [createRTForm.state.name]: true,
        [createRTForm.category.name]: [],
        [createRTForm.resume]: [{
            image: "",
            description: "",
            ts: []
        }],
    }

    //const [tsToAdd] = useQueryParam("ts", NumberParam)

    const updateRTInitial = {
        [createRTForm.title.name]: roadTrip?.title,
        [createRTForm.description.name]: roadTrip?.description,
        [createRTForm.image.name]: [],
        [createRTForm.touristic_sites.name]: [],
        [createRTForm.state.name]: roadTrip?.public,
        [createRTForm.category.name]: [],
    }


    const requestRoadTrip = () => {
        if (config.headers.Authorization) {
            handleRequest("get", API_GET_USER_ROADTRIPS, {}, config, (data, status) => {

                if (status === "Done") {
                    const parsedRT = data.road_trips.map(el => {
                        el.feedbacks = {
                            note: el.avg_notes || 0,
                            len: el.notes_counter,
                        }

                        el.user = {
                            firstname: user?.firstname,
                            lastname: user?.lastname,
                        }

                        return el;
                    });
                    dispatch(addUserRoadTrips(parsedRT))
                } else if (status === "Error") {
                    console.error(data);
                }
            })
        }
    }

    const handleSubmit = (v) => {
        /*const sendData = v;
    
        sendData[createRTForm.image.name] = sendData[createRTForm.image.name] ? sendData[createRTForm.image.name][0] : undefined
        if (tsToAdd)
          sendData[createRTForm.touristic_sites.name] = JSON.stringify([...sendData[createRTForm.touristic_sites.name]?.map(el => el.touristic_site.id), tsToAdd])
        else
          sendData[createRTForm.touristic_sites.name] = JSON.stringify(sendData[createRTForm.touristic_sites.name]?.map(el => el.touristic_site.id))*/
        if (v.image.length > 0) {
            v.image = v.image[0];
        }

        if (v.password === null || v.password === "" || v.password === undefined) {
            delete v['password'];
        }

        if (roadTrip) {

            handleRequest("put", API_UPDATE_ROADTRIP(roadTrip.id), v, config, (data, status) => {
                if (status === "Done") {
                    requestRoadTrip();
                    onCancel();
                } else if (status === "Error") {
                    console.error(data);
                }
            })
        } else {
            handleRequest("post", API_CREATE_ROADTRIP, v, config, (data, status) => {
                if (status === "Done") {
                    requestRoadTrip();
                    history.push(`/roadtrip/${data.id}`)
                } else if (status === "Error") {
                    console.error(data);
                }
            })
        }
    }

    return (
        <div>
            <Formik
                initialValues={roadTrip ? updateRTInitial : createRTInitial}
                validationSchema={createRTValidation}
                onSubmit={handleSubmit}
            >
                {
                    (formikProps) => {
                        return <CreateExperiencePage setSuggestModalXp={setSuggestModalXp} createRT={createRT} loading={status === "Loading"} createRTForm={createRTForm} formikProps={formikProps} isEdit={!!roadTrip} onHide={onCancel} roadtrip={roadTrip} />
                    }
                }
            </Formik>
        </div>
    );
};

export default CreateExperience;
