import React from "react";
import { Button } from 'components/form/button';
import Title from 'components/header/title/title';
import './AmbassadorSectionsText.scss';

const AmbassadorSectionsText = () =>  (
    <div className="container mt-4">
        <div className="d-flex justify-content-center d-inline d-lg-none">
            <img className="ambassador-text__image" src="https://general-henri-1-paris.s3.fr-par.scw.cloud/ambassadors/image-recompense.png" alt="ambassadors"/>
        </div>
        <Title noTopPad={true} title="Et si ta communauté te faisait voyager gratuitement ?"/>
        <h3>Tu veux voyager encore plus ?</h3>
        <h3>Converti tes abonnés en utilisateurs Henri Trip et découvre la France sans limite !</h3>
        <div className="row mt-2 mt-md-3">
            <div className="col-12 col-lg-7">
                <div className="row mt-md-1">
                    <div className="col">
                        <p className="ambassador-text__text-style">Entrepreneur dans l’âme, développe ton réseau et invite ta communauté à rejoindre l’aventure Henri Trip.
                            Pour cela, ta mission est d’inciter tes abonnés à cliquer sur le lien personnalisé dont tu disposes et qui redirige chaque abonné vers la plateforme.
                            Lorsque tes abonnés cliquent sur ce lien, tu gagnes des points.
                        </p>
                    </div>
                </div>
                <div className="row mt-3 mt-md-2">
                    <div className="col">
                        <p className="ambassador-text__text-style">
                            Et ces points à quoi servent-ils ?
                            Ils te permettent de monter en catégorie d’ambassadeurs et de te voir offrir plus d’activités et de voyages !
                        </p>
                    </div>
                </div>
                <div className="row mt-3 mt-md-2">
                    <div className="col">
                        <p className="ambassador-text__text-style">
                            Motivé(e) pour tenter ta chance ?
                        </p>
                    </div>
                </div>
            </div>
            <div className="col-5 d-none d-lg-inline">
                <img className="ambassador-text__image" src="https://general-henri-1-paris.s3.fr-par.scw.cloud/ambassadors/image-recompense.png" alt="ambassadors"/>
            </div>
            <div className="col d-flex justify-content-center justify-content-lg-start mt-4">
                <Button rounded className="px-3" title="Candidater" onClick={() => window.open("https://forms.office.com/r/gh46npYQ6S", '_blank')}/>
            </div>
        </div>
    </div>
)

export default AmbassadorSectionsText;
