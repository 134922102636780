import React, {useEffect, useMemo, useState} from "react";
import useDocumentTitle from "../../hooks/use-document-title";
import {linkAdder, metaAdder, TABLET_WIDTH} from "../../utils/utils";
import ContentLoader from "react-content-loader";
import _ from 'underscore';
import 'flag-icons/css/flag-icons.min.css';
import MySelect from "./MySelect";
import {ActivityCard} from "./ActivityCard";
import ActivityImages from "./VSModules/ActivityImages";
import TextVS from "./VSModules/TextVS";
import Audio from "./VSModules/Audio";
import File from "./VSModules/File";
import ImageVS from "./VSModules/ImageVS";
import Video from "./VSModules/Video";
import LinkVS from "./VSModules/Link";
import MapModule from "./VSModules/MapModule";
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import {useTranslation} from "react-i18next";

const ClassicPostLoader = props => {
  return (
    <ContentLoader viewBox="0 0 500 400" height={375} width={500} {...props}>
      <path d="M484.52,64.61H15.65C7.1,64.61.17,71.2.17,79.31V299.82c0,8.12,6.93,14.7,15.48,14.7H484.52c8.55,0,15.48-6.58,15.48-14.7V79.31C500,71.2,493.07,64.61,484.52,64.61Zm-9,204.34c0,11.84-7.14,21.44-15.94,21.44H436.39L359.16,171.52c-7.1-10.92-19.67-11.16-27-.51L258.64,277.94C253.78,285,245.73,286,240,280.2l-79.75-80.62c-6-6.06-14.33-5.7-20,.88L62.34,290.39H40.63c-8.8,0-15.94-9.6-15.94-21.44V110.19c0-11.84,7.14-21.44,15.94-21.44H459.54c8.8,0,15.94,9.6,15.94,21.44Z" />
      <ellipse cx="27.53" cy="26.15" rx="27.53" ry="26.15" />
      <rect x="69.36" y="0.5" width="87.36" height="16.48" rx="4.5" />
      <rect x="0.53" y="328.35" width="87.36" height="16.48" rx="4.5" />
      <rect x="95.84" y="328.35" width="87.36" height="16.48" rx="4.5" />
      <rect x="195.38" y="328.35" width="304.45" height="16.48" rx="4.5" />
      <rect x="412.47" y="358.52" width="87.36" height="16.48" rx="4.5" />
      <rect x="291.22" y="358.52" width="113.31" height="16.48" rx="4.5" />
      <rect x="0.53" y="358.52" width="282.21" height="16.48" rx="4.5" />
      <rect x="69.36" y="25.22" width="164.67" height="27.07" rx="3.83" />
    </ContentLoader>
  )
}

const ActivityPage = ({loading, activity}) => {
  const {t, i18n} = useTranslation(["henriTrip"]);
  const title = activity.title?.[`text_${i18n.language}`];
  const description = activity.description?.[`text_${i18n.language}`];
  useDocumentTitle(title);
  const [activeVisit, setActiveVisit] = useState(activity.visit_sheets[0] || null)
  const [language, setLanguage] = useState(null);
  const [activeContent, setActiveContent] = useState(
    language ?
    activeVisit?.visit_sheet_contents.find((x) => x.language === language.value) ||
    null :null
  );
  const [activeLayout, setActiveLayout] = useState(null);
  const languages = useMemo(
    () => [
      {
        label: 'French',
        value: 'French',
        icon: <span className="fi fi-fr"></span>,
      },
      {
        label: 'English',
        value: 'English',
        icon: <span className="fi fi-gb"></span>,
      },
      {
        label: 'German',
        value: 'German',
        icon: <span className="fi fi-de"></span>,
      },
      {
        label: 'Spanish',
        value: 'Spanish',
        icon: <span className="fi fi-es"></span>,
      },
      {
        label: 'Italian',
        value: 'Italian',
        icon: <span className="fi fi-it"></span>,
      },
      {
        label: 'Chinese',
        value: 'Chinese',
        icon: <span className="fi fi-cn"></span>,
      },
      {
        label: 'Arabic',
        value: 'Arabic',
        icon: <span className="fi fi-sa"></span>,
      },
      {
        label: 'Portuguese',
        value: 'Portuguese',
        icon: <span className="fi fi-pt"></span>,
      },
      {
        label: 'Japanese',
        value: 'Japanese',
        icon: <span className="fi fi-jp"></span>,
      },
      {
        label: 'Russian',
        value: 'Russian',
        icon: <span className="fi fi-ru"></span>,
      },
    ].filter(el => Object.keys(
        _.groupBy(activeVisit?.visit_sheet_contents, 'language')).includes(el.value)),
    [activeVisit]
  );


  useEffect(() => {
    setLanguage(languages[0] || null);
    const content =
      language ?
      activeVisit?.visit_sheet_contents.find((x) => x.language === language.value) ||
      null : null;
    setActiveContent(content);
    // eslint-disable-next-line
  }, [activeVisit]);

  useEffect(() => {
    const content =
      language ?
      activeVisit?.visit_sheet_contents.find((x) => x.language === language.value) ||
      null : null;
    setActiveContent(content);
    // eslint-disable-next-line
  }, [language]);

  useEffect(() => {
    const l = activeContent?.layout ? JSON.parse(activeContent.layout) : null;
    setActiveLayout(l);
    // eslint-disable-next-line
  }, [activeContent]);




  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    let text = `Découvrez ${title} avec toutes les photos, le plan d'accès, les avis et les infos pratiques : téléphone, site internet, horaires, billetterie ... ${description?.slice(0,170)}`.slice(0, 200)
    metaAdder('name="description"', text)
    metaAdder('name="author"', "Henri Trip")
    metaAdder('name="keywords"', "autour de moi, quoi faire, voyage, touriste, comparer, choses à faire, activités touristiques, tours, attractions, billets, trip, road, roadtrip, informations, henri trip, " + title)
    linkAdder(document.location.href)
  }, [title, description]);
  return (
    <div className="container min-vh-75 pt-5 activity-page">
      {
        loading ?
          <ClassicPostLoader className="touristic-site-page--header-image" width="100%" height="100%"/>
          :
          <ActivityImages
            srcDefault={activity?.images[0]?.url?.replace(/\?.*/, '') || 'https://general-henri-1-paris.s3.fr-par.scw.cloud/woman.jpg' }
            activeContent={activeContent} />
      }
      <div className="container">
        <div className="w-100 d-flex flex-row align-items-center mt-2">
          <div
            style={{width: 30, height: 30, borderRadius: '50%', border: '1px solid black'}}
            className="d-flex justify-content-center align-items-center font-weight-bolder"
          >
            <p className="bold font-size-h1">{activeVisit?.rank}</p>
          </div>
          <h1 className="m-0 font-weight-bold font-size-h4 px-2 text-truncate text-primary">
            {activeContent?.title || 'Pas de Titre'}
          </h1>
          <div className={`ml-auto ${window.innerWidth > TABLET_WIDTH && 'w-25'}`}>
            <MySelect
              name="language"
              value={language}
              onChange={(e) => setLanguage(e)}
              defaultValue={languages[0]}
              options={languages}
              getOptionLabel={(e) => (
                <div style={{display: 'flex', alignItems: 'center'}}>
                  {e.icon}
                  {
                    window.innerWidth > TABLET_WIDTH &&
                    <span style={{margin: '0 5px'}}>{e.label}</span>
                  }
                </div>
              )}
            />
          </div>
        </div>
        <div className="row">
          <div className={`${window.innerWidth > TABLET_WIDTH ? 'col-9' : 'col-12'} overflow-hidden`}>
            { language && Object.keys(
              _.groupBy(activeVisit?.visit_sheet_contents, 'language')
            ).includes(language.value) ? (
              <>
                <p>{activeContent?.description || 'Pas de description'}</p>
                {activeLayout &&
                  Object.keys(activeLayout)
                    .filter((key) => key !== '0')
                    .map((key) => {
                      switch (activeLayout[key][0].sortable_item) {
                        case 'text':
                          return (
                            <TextVS
                              key={key}
                              data={activeLayout[key][0]}
                              activeContent={activeContent}
                            />
                          );
                        case 'audio':
                          return (
                            <Audio
                              key={key}
                              data={activeLayout[key]}
                            />
                          );
                        case 'file':
                          return (
                            <File
                              key={key}
                              data={activeLayout[key]}
                            />
                          );
                        case 'image':
                          return (
                            <ImageVS
                              key={key}
                              data={activeLayout[key]}
                            />
                          );
                        case 'video':
                          return (
                            <Video
                              key={key}
                              data={activeLayout[key]}
                            />
                          );

                        case 'link':
                          return (
                            <LinkVS
                              key={key}
                              data={activeLayout[key][0]}
                              activeContent={activeContent}
                            />
                          );
                        case 'map':
                          return (
                            <MapModule
                              key={key}
                              data={{
                                location: activeContent.location || '',
                                longitude:
                                  activeContent.longitude || activity.longitude,
                                latitude:
                                  activeContent.latitude || activity.latitude,
                              }}
                            />
                          );
                        default:
                          return null;
                      }
                    })}
              </>
            ) : (
              <div className="d-flex flex-column m-auto">
                <p className="text-muted text-center px-2 my-3">
                  {t("activityPage.languagesNotAvailable")}
                </p>
              </div>
            )}
          </div>
          <div className={`${window.innerWidth > TABLET_WIDTH ? 'col-3' : 'col-12'}`}>
            {
              window.innerWidth > TABLET_WIDTH ?
                <ul className="d-flex flex-column align-items-center p-0 m-0">
                  {activity.visit_sheets.map((el) => (
                    <ActivityCard
                      key={el.id}
                      item={el}
                      defaultImg={activity?.images[0]?.url?.replace(/\?.*/, '') || 'https://general-henri-1-paris.s3.fr-par.scw.cloud/woman.jpg' }
                      language={language}
                      activeVisitSheet={activeVisit}
                      setActiveVisitSheet={setActiveVisit}
                    />
                  ))}
                </ul>
                :
                <>
                  <hr/>
                  <section className="row pl-2 d-block overflow-hidden activities-list">
                    <h2 className="text-dark font-weight-bold pb-2">
                      {t("activityPage.nextItem")} :
                    </h2>
                    <ul className="p-0 m-0 w-100">
                      <Slider {...{
                        infinite: false,
                        dots: false,
                        autoplay: false,
                        arrows: false,
                        accessibility: false,
                        center: false,
                        slidesToShow: activity.visit_sheets?.length > 1 ? 2 : 1,
                        slidesToScroll: activity.visit_sheets?.length > 1 ? 2 : 1,
                        responsive: [
                          {
                            breakpoint: 1600,
                            settings: {
                              slidesToShow: activity.visit_sheets?.length > 1 ? 2 : 1,
                              slidesToScroll: activity.visit_sheets?.length > 1 ? 2 : 1,
                              adaptiveHeight: true,
                            }
                          },
                          {
                            breakpoint: 1250,
                            settings: {
                              slidesToShow: activity.visit_sheets?.length > 1 ? 2 : 1,
                              slidesToScroll: 1,
                              adaptiveHeight: true,
                            }
                          },
                          {
                            breakpoint: 767,
                            settings: {
                              slidesToShow: 1,
                              slidesToScroll: 1,
                              adaptiveHeight: true,
                            }
                          },
                          {
                            breakpoint: 480,
                            settings: {
                              slidesToShow: 1,
                              slidesToScroll: 1,
                              adaptiveHeight: true,
                            }
                          }
                        ]}}>
                          {activity.visit_sheets.map((el) => (
                            <ActivityCard
                              key={el.id}
                              item={el}
                              defaultImg={activity?.images[0]?.url?.replace(/\?.*/, '') || 'https://general-henri-1-paris.s3.fr-par.scw.cloud/woman.jpg' }
                              language={language}
                              activeVisitSheet={activeVisit}
                              setActiveVisitSheet={setActiveVisit}
                            />
                          ))}
                      </Slider>
                    </ul>
                  </section>
                </>
            }
          </div>
        </div>
      </div>
    </div>
  );
};

export default ActivityPage;
