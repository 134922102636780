import { updateUser } from 'actions/user';
import useRequest from 'hooks/use-request';
import AccountPage from 'pages/UserPage/AccountPage/AccountPage';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { API_MODIFY_PROFILE } from 'utils/api';
import { REQUIRED_MESSAGE } from 'utils/texts';
import * as Yup from 'yup';
import moment from 'moment';

const userForm = {
    firstname: {
        name: "firstname",
        label: "Prénom",
        placeholder: "Prénom",
        required: true
    },
    lastname: {
        name: "lastname",
        label: "Nom",
        placeholder: "Nom",
        required: true
    },
    avatar: {
        name: "avatar",
    },
    address: {
        name: "address",
        label: "Adresse",
        placeholder: "Adresse",
    },
    city: {
        name: "city",
        label: "Ville",
        placeholder: "Ville"
    },
    zipcode: {
        name: "zipcode",
        label: "Code postal",
        placeholder: "00000",
        mask: "99999",
    },
    gender: {
        name: "gender",
        label: "Civilité",
        placeholder: "Civilité",
        noOptionsMessage: () => "Aucune civilité trouvée",
        required: true
    },
    birthdate: {
        name: "birthdate",
        label: "Année de naissance",
        placeholder: "JJ/MM/AAAA",
        mask: "99/99/9999",
        required: true
    }
}

const genders = [
    {text: "Homme", value: "Male"},
    {text: "Femme", value: "Female"},
    {text: "Autre", value: "Other"}
]

Yup.addMethod(Yup.date, 'format', function(formats, parseStrict) {
    return this.transform((value, originalValue) => {
        value = moment(originalValue, formats, parseStrict);
        return value.isValid() ? value.toDate() : new Date('');
    });
});

const userValidation = Yup.object().shape({
    [userForm.firstname.name]: Yup.string().required(REQUIRED_MESSAGE),
    [userForm.lastname.name]: Yup.string().required(REQUIRED_MESSAGE),
    [userForm.address.name]: Yup.string(),
    [userForm.city.name]: Yup.string(),
    [userForm.zipcode.name]: Yup.string(),
    [userForm.gender.name]: Yup.number().required(REQUIRED_MESSAGE),
    [userForm.birthdate.name]: Yup.date().format("DD/MM/YYYY", true).max(new Date(), "Votre date de naissance doit être antérieure à la date actuelle.").required(REQUIRED_MESSAGE).typeError("La date n'est pas valide. Utilisez le format suivant JJ/MM/AAAA.")
})

const Account = () => {
    const {user} = useSelector(state => state.user);
    const [handleRequest] = useRequest();
    const config = useSelector(state => state.data);
    const dispatch = useDispatch()

    React.useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const handleSubmit = (v) => {
        handleRequest("post", API_MODIFY_PROFILE, {
            ...v,
            birthdate: moment(v[userForm.birthdate.name], "DD/MM/YYYY", true).locale("fr").toDate().toISOString().split("T")[0],
        }, config, (data, status) => {
            if (status === "Done") {
                dispatch(updateUser(data))
            } else if (status === "Error") {
                console.error(data);
            }
        })
    }

    return (
        <AccountPage user={user} userForm={userForm} userValidation={userValidation} handleSubmit={handleSubmit} genders={genders} />
    );
};

export default Account;
