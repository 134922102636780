import Feedbacks from 'components/feedbacks/feedbacks';
import Title from 'components/header/title/title';
import React, { useEffect, useState, Suspense } from "react";
import { Link } from 'react-router-dom';
import _ from "underscore";
import FormModal from "../../../components/formModal/InterestForm";
import "./roadtrip-page.scss"
import EditRoadTrip from 'containers/User/Roadtrips/EditRoadTrip/EditRoadTrip';
import DeleteRoadTrip from 'containers/User/Roadtrips/DeleteRoadTrip/DeleteRoadTrip';
import ExportRoadTrip from 'containers/User/Roadtrips/ExportRoadTrip/ExportRoadTrip';
import CopyRoadTrip from 'containers/User/Roadtrips/CopyRoadTrip/CopyRoadTrip';
import ImgLazy from "../../../components/Lazyload/ImgLazy/ImgLazy";
import { Button } from "components/form/button";
import { Status } from "../../../components/informations/status";
import useDocumentTitle from 'hooks/use-document-title';
import { Modal } from 'semantic-ui-react'
import { ReactComponent as More } from "../../../assets/img/logo/Plus.svg";
import { HeaderButton } from "components/header/buttons";
import Share from "../../../containers/Share/Share";
import { Share as ShareIcon, VisibilityOutlined } from "@material-ui/icons";
import ModalForm from "../../../components/formModal/ModalForm";
import { useDispatch, useSelector } from "react-redux";
import Login from "../../../containers/Auth/Login";
import Feedback from "../../../components/informations/feedback/feedback";

import ContentLoader from 'react-content-loader'
import { Loader } from 'semantic-ui-react'
import TouristicSiteElement from "../../../components/touristicSites/touristicSiteElement/TouristicSiteElement";
import TouristicSiteSuggestions from "../../../components/suggestions/TouristicSiteSuggestions";
import { useTranslation } from "react-i18next";
import { groupsId, linkAdder, metaAdder, seasonsId, transportsId } from "../../../utils/utils";
import AdsByGoogle from "../../../components/googleAdSense/AdsByGoogle";
import { SvgIcon } from "@material-ui/core";
import CloseIcon from '@mui/icons-material/Close';
import { useQueryParam, BooleanParam } from "use-query-params";
import CategoriesIcons from '../../../components/informations/categories/categoriesIcons/CategoriesIcons'
import { PHONE_WIDTH } from '../../../utils/utils';
import MapIcon from '@mui/icons-material/Map';
import RoadTripMap from './RoadTripMap';
import { BottomSheet } from "react-spring-bottom-sheet";
import 'react-spring-bottom-sheet/dist/style.css'
import TouristicSiteModal from "../../../components/touristicSites/touristicSiteModal/touristicSiteModal";
import { API_ADD_FAVORITE_ROADTRIP, API_DELETE_FAVORITE_ROADTRIP } from "../../../utils/api";
import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import {
  deleteUserFavoriteRoadTrips,
  addUserFavoriteRoadTrip
} from "../../../actions/road-trips";
import useRequest from "../../../hooks/use-request";


import { Icon, Menu, Sidebar } from 'semantic-ui-react'
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';



const ClassicPostLoader = props => {
  return (
    <ContentLoader viewBox="0 0 500 400" height={375} width={500} {...props}>
      <path
        d="M484.52,64.61H15.65C7.1,64.61.17,71.2.17,79.31V299.82c0,8.12,6.93,14.7,15.48,14.7H484.52c8.55,0,15.48-6.58,15.48-14.7V79.31C500,71.2,493.07,64.61,484.52,64.61Zm-9,204.34c0,11.84-7.14,21.44-15.94,21.44H436.39L359.16,171.52c-7.1-10.92-19.67-11.16-27-.51L258.64,277.94C253.78,285,245.73,286,240,280.2l-79.75-80.62c-6-6.06-14.33-5.7-20,.88L62.34,290.39H40.63c-8.8,0-15.94-9.6-15.94-21.44V110.19c0-11.84,7.14-21.44,15.94-21.44H459.54c8.8,0,15.94,9.6,15.94,21.44Z" />
      <rect x="0.53" y="328.35" width="87.36" height="16.48" rx="4.5" />
      <rect x="95.84" y="328.35" width="87.36" height="16.48" rx="4.5" />
      <rect x="195.38" y="328.35" width="304.45" height="16.48" rx="4.5" />
      <rect x="412.47" y="358.52" width="87.36" height="16.48" rx="4.5" />
      <rect x="291.22" y="358.52" width="113.31" height="16.48" rx="4.5" />
      <rect x="0.53" y="358.52" width="282.21" height="16.48" rx="4.5" />
    </ContentLoader>
  )
}

const HeaderMobile = ({ noTopPad, roadtrip }) => {

  return (
    <div>
      <Title noTopPad={noTopPad} roadtrip={roadtrip} title={roadtrip.title} />
    </div>
  )
}

export const TouristicSiteSection = ({ loading, rtTs, isUserRT, openModal, openTsModal, setTsModal, checkedPassword, openSuggestModal }) => {
  const { t } = useTranslation(["henriTrip"])
  const [openMap] = useState(window.innerWidth > PHONE_WIDTH);
  const [widthState, setWidthState] = useState(window.innerWidth);
  const sites = _.groupBy(rtTs, "day_number")
  const days = Object.keys(sites)
  const { user } = useSelector(state => state.user)
  const formStyle = {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    marginBottom: 10,
    cursor: 'pointer'
  }
  useEffect(() => {
    function handleResize() {
      setWidthState(window.innerWidth)
    }
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize)
  }, []);
  return (
    <section className={`${openMap ? 'px-1' : ""}  ${widthState <= PHONE_WIDTH || widthState <= 1280 ? 'mx-0' : ''}`}>

      {
        loading ?
          <div className="position-relative">
            <Loader active className="mt-4" />
          </div>
          :
          days.length !== 0 ?
            days.map((day) =>
              <div key={day}
                className={`col-sm-12 col-md-6 ${days.length > 2 ? 'col-lg-4' : 'col-lg-6'} ${openMap ? 'pt-4' : ""} d-inline-block align-top roadtrip-day`}>
                <div style={{
                  display: "flex",
                  justifyContent: "center"
                }}>
                  <HeaderButton
                    text={`${t("roadTripPage.day")} ${day}`}
                    color="information-block"
                    className="mb-2 mx-0"
                  />
                </div>
                {
                  <>
                    {
                      user?.id || checkedPassword === true ?
                        sites[day].map((site) =>
                          <TouristicSiteElement openTsModal={openTsModal} setTsModal={setTsModal} key={site.id} el={site} />
                        )
                        :
                        <>
                          {
                            sites[day].slice(0, 1).map((site) =>
                              <TouristicSiteElement openTsModal={openTsModal} setTsModal={setTsModal} key={site.id} el={site} />
                            )
                          }
                          {
                            sites[day].slice(1).map((site) =>
                              <div key={site.id} className="position-relative blur-roadtrip-container">
                                <TouristicSiteElement openTsModal={openTsModal} setTsModal={setTsModal} key={site.id} el={site} />
                                <div className="blur-roadtrip d-flex justify-content-center align-items-center">
                                  <HeaderButton
                                    icon="/assets/icons/Fichier 40.svg"
                                    className="lock-btn p-2"
                                  />
                                  <Button className="roadtrip-auth__btn" rounded
                                    title={t("login.loginButton")} onClick={openModal} />
                                </div>
                              </div>
                            )
                          }
                        </>
                    }
                  </>
                }
                {isUserRT &&

                  <div style={formStyle}>
                    <hr noshade="true" width="290" size="1" align="center" style={{ borderTop: '1px solid rgba(0, 0, 0, 0.1)' }}></hr>
                    <Button className='add-custom-ts'
                      title={<More />} onClick={() => openSuggestModal(day)} />
                  </div>
                }
              </div>
            )
            :
            <>
              <p className="text-muted text-center px-2">{t("roadTripPage.noTouristicSite")} {
                isUserRT &&
                <Link to="/touristic-sites" className="text-primary font-weight-bold">{t("roadTripPage.noTouristicSiteUser")}</Link>

              }
              </p>
              <p className="text-muted text-center px-2"> {t("login.or")} </p>
              {
                isUserRT &&
                <div className="text-primary font-weight-bold" style={formStyle} onClick={() => openSuggestModal()}>{t("roadTripPage.addRT.addToRTManuel")}
                </div>
              }
            </>
      }
    </section>
  )
}
const SearchViewPageMobile = ({ children, header }) => {
  return (
    <BottomSheet
      open
      blocking={false}
      defaultSnap={({ maxHeight }) => maxHeight / 5}
      header={header}
      snapPoints={({ maxHeight }) => [
        maxHeight - maxHeight / 6,
        90
      ]}
      className="bottom-sheet"
      style={{ zIndex: 111, position: "absolute" }}
    >
      {children}
    </BottomSheet>
  )
}

const ModalInterest = ({ title, isOpen, onClose, children }) => {
  const [show, setShow] = useState(false)

  const handleClose = () => {
    onClose()
    setShow(false)
  }
  const handleShow = () => setShow(true)

  useEffect(() => {
    if (isOpen === true) {
      handleShow()
    } if (isOpen === false) {
      handleClose()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen])

  return (
    <Modal
      data-body-scroll-lock-ignore="true"
      size={"large"}
      open={show}
      onOpen={handleShow}
      onClose={handleClose}
    >
      {
        title &&
        <Modal.Header >
          {title}
        </Modal.Header>
      }
      {children}
    </Modal>
  )
}


const RoadTripPage = ({ loading, roadtrip, rtTs, isUserRT, matchRoute, handleModifyRT, checkedPassword, enterPassword }) => {
  const { t } = useTranslation(["henriTrip"])
  const [auth_required] = useQueryParam('auth_required', BooleanParam)
  useDocumentTitle(`${roadtrip?.title ? roadtrip.title + " • " : ""}Road Trip • Henri Trip`)
  const { user } = useSelector(state => state.user)
  const [open, setOpen] = useState(false);
  const [widthState, setWidthState] = useState(window.innerWidth);
  const [modalDay, setModalDay] = useState(null);
  const [suggestModal, setSuggestModal] = useState(false);
  const dispatch = useDispatch()
  const [handleRequest] = useRequest();
  const config = useSelector(state => state.data);
  const [favorite, setFavorite] = useState();
  const [pdfModal, setPDFModal] = useState(false);
  const openPDFModal = () => {
    setPDFModal((state) => !state);
  };
  const [pdfCompletModal, setPDFCompletModal] = useState(false);
  const openPDFCompletModal = () => {
    setPDFCompletModal(true);
  };

  const handleClickFavorite = (id) => {
    if (favorite !== true) {
      handleAddFavorite(id)
    } else {
      handleDeleteFavorite(id)
    }
  }

  useEffect(() => {
    setFavorite(roadtrip.is_favorite)
  }, [roadtrip.is_favorite])


  const handleAddFavorite = (id) => {
    handleRequest("post", API_ADD_FAVORITE_ROADTRIP(id), {}, config, (data, status) => {
      if (status === "Done") {
        setFavorite(favorite => !favorite)
        const parsedRTFavorite = data.map(rt => {

          let categoriesTransport = [];
          let categoriesGroup = [];
          let categoriesSeasons = [];

          rt.categoriesTransport = categoriesTransport
          rt.categoriesGroup = categoriesGroup
          rt.categoriesSeasons = categoriesSeasons

          rt.categories.forEach(el => {
            if (transportsId.includes(el.id)) {
              categoriesTransport.push(el)
            }
          })

          rt.categories.forEach(el => {
            if (groupsId.includes(el.id)) {
              categoriesGroup.push(el)
            }
          })

          rt.categories.forEach(el => {
            if (seasonsId.includes(el.id)) {
              categoriesSeasons.push(el)
            }
          })

          rt.feedbacks = {
            note: rt.road_trip.avg_notes || 0,
            len: rt.road_trip.notes_counter,
          }

          rt.user = {
            firstname: user?.firstname,
            lastname: user?.lastname,
          }

          return rt
        });
        dispatch(addUserFavoriteRoadTrip(parsedRTFavorite))
      } else if (status === "Error") {
        console.error(data);
      }
    })
  }

  const handleDeleteFavorite = (id) => {
    handleRequest("delete", API_DELETE_FAVORITE_ROADTRIP(id), {}, config, (data, status) => {
      if (status === "Done") {
        setFavorite(favorite => !favorite)
        dispatch(deleteUserFavoriteRoadTrips(parseInt(id)))
      } else if (status === "Error") {
        console.error(data);
      }
    })
  }

  const openSuggestModal = (day) => {
    if (day == null) {
      day = 1
    }
    setModalDay(day)
    setSuggestModal(true);
  }
  const [tsContent, setTsContent] = useState(null)
  const [tsModal, setTsModal] = useState(false);
  const openTsModal = (el) => {
    setTsContent(el)
    setTsModal(true);
  }

  const [formModal, setFormModal] = useState({
    edit: false,
    delete: false,
    export: false,
    copy: false,
    share: false,
    shareError: false
  });
  const [openMap, setOpenMap] = useState(false);

  const [openOptions, setOpenOptions] = useState(false)

  const onOptionsClick = () => {
    setOpenOptions(state => !state)
  }
  const onMapClick = () => {
    setOpenMap(state => !state)
  }
  useEffect(() => {
    if (roadtrip && roadtrip.title) {
      let keywords = roadtrip?.touristic_sites?.map(e => e.title_fr).join(",")
      let description = `Découvrez et partagez avec la communauté d'Henri Trip l'ensemble des sites touristiques du road trip "${roadtrip?.title}": ${keywords}`.slice(0, 200)
      metaAdder('name="description"', description)
      metaAdder('name="author"', "Henri Trip")
      metaAdder('name="keywords"', "autour de moi, quoi faire, voyage, touriste, comparer, choses à faire, activités touristiques, tours, attractions, billets, trip, road, roadtrip, informations, henri trip, " + keywords)
      linkAdder(document.location.href)
    }
    if (roadtrip?.suggestions) {
      setOpen(user?.id ? false : auth_required)
    }
  }, [roadtrip]);

  useEffect(() => {
    function handleResize() {
      setWidthState(window.innerWidth)
    }
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize)
  }, []);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [formModal.edit]);

  const openModal = () => {
    setOpen(true);
  };

  const roadTripIsPublic = roadtrip.public;
  const handleShare = () => {
    const roadTripIsPublic = roadtrip.public;
    setFormModal((state) => ({
      ...state,
      share: true,
      shareError: !roadTripIsPublic,
    }));
  };

  const handleCopyTrip = () => {
    if (user.id) {
      setFormModal((state) => ({
        ...state,
        copy: true,
      }));
    } else {
      openModal();
    }
  }
  const lang_available = [
    { key: 'Francais', text: ('Francais'), value: ('Francais'), flag: ('fr') },
    { key: 'English', text: ('English'), value: ('English'), flag: ('gb') },
    { key: 'German', text: ('German'), value: ('German'), flag: ('de') },
    { key: 'Italian', text: ('Italian'), value: ('Italian'), flag: ('it') },
    { key: 'Spanish', text: ('Spanish'), value: ('Spanish'), flag: ('es') },
    { key: 'Chinese', text: ('Chinese'), value: ('Chinese'), flag: ('cn') },
    { key: 'Arab', text: ('Arab'), value: ('Arab'), flag: ('sa') },
    { key: 'Portuguese', text: ('Portuguese'), value: ('Portuguese'), flag: ('pt') },
    { key: 'Japanese', text: ('Japanese'), value: ('Japanese'), flag: ('jp') },
    { key: 'Russian', text: ('Russian'), value: ('Russian'), flag: ('ru') },
  ]





  const categoriesTransport = roadtrip.categoriesTransport;
  const categoriesGroup = roadtrip.categoriesGroup;
  const categoriesSeasons = roadtrip.categoriesSeasons;
  const views = roadtrip.count_view;

  return (
    <>
      <ModalForm isOpen={pdfCompletModal} onClose={() => setPDFCompletModal(false)} >

        <div className="d-flex justify-content-end">
          <SvgIcon
            className="me-3 mb-1 svg1"
            component={CloseIcon}
            onClick={() => setPDFCompletModal(false)}
            width="20"
            height="20"
          />
        </div>

      </ModalForm>

      <ModalForm isOpen={pdfModal} onClose={() => setPDFModal(false)} >
        <div className="d-flex justify-content-end">
          <SvgIcon
            className="me-3 mb-1"
            component={CloseIcon}
            onClick={() => setPDFModal(false)}
            width="20"
            height="20"
          />
        </div>
      </ModalForm>
      <ModalInterest isOpen={tsModal} onClose={() => setTsModal(false)}>
        <TouristicSiteModal touristicSite={tsContent} setTsModal={setTsModal} />
      </ModalInterest>
      <Modal
        open={suggestModal}
        onClose={() => setSuggestModal(false)}
      >

        <FormModal handleModifyRT={handleModifyRT} day={modalDay} roadtrip={roadtrip} handleClose={() => setSuggestModal(false)} />

      </Modal>
      <ModalForm
        isOpen={open}
        title={t("register.title")}
        onClose={() => setOpen(false)}
      >
        <Login
          modal={true}
          handleSubmit={() => setOpen(false)}
          handleClose={() => setOpen(false)}
        />
      </ModalForm>
      {
        widthState <= PHONE_WIDTH && openMap && (
          <>
            <SearchViewPageMobile
              header={
                <HeaderMobile mobile noTopPad={"pt-1"} roadtrip={roadtrip} />
              }
            >
              <TouristicSiteSection
                className={`${widthState <= PHONE_WIDTH || widthState <= 1280 && openMap ? "mx-3" : ""
                  }`}
                rtTs={rtTs}
                openModal={openModal}
                checkedPassword={checkedPassword}
                openTsModal={openTsModal}
                setTsModal={setTsModal}
                loading={loading}
                isUserRT={isUserRT}
              />
            </SearchViewPageMobile>
            <div
              className={`${widthState >= PHONE_WIDTH || widthState <= 1280 && !openMap
                ? "d-none"
                : "col-12 col-md-6 px-0"
                } position-sticky`}
              style={{ zIndex: 100, top: 0 }}
            >
              <div
                className="map-rt col-12 col-md-12 px-0 d-block"
                style={{
                  position: "relative",
                  zIndex: 100,
                  width: "100%",
                  height: "90vh",
                  top: 0,
                }}
              >
                {roadtrip?.touristic_sites && (
                  <Suspense
                    fallback={<Loader active className="mt-4 fixed-top" />}
                  >
                    <RoadTripMap
                      user={user}
                      checkedPassword={checkedPassword}
                      onMapClick={onMapClick}
                      rtTs={rtTs}
                      openModal={openModal}
                      openTsModal={openTsModal}
                      setTsModal={setTsModal}
                    />
                  </Suspense>
                )}
              </div>
            </div>
          </>
        )
      }
      {
        formModal.edit ? (
          <div className="container min-vh-75 pt-5">
            <EditRoadTrip loading={loading} roadtrip={roadtrip} handleModifyRT={handleModifyRT} onCancel={() => {
              handleModifyRT()
              setFormModal(state => ({ ...state, edit: false }))
            }} />
          </div>
        ) : (
          <div
            className={`${openMap ? "" : "container"
              } roadtrip-page min-vh-75 pt-5 ${widthState <= PHONE_WIDTH ? "px-0 py-3 mx-0 my-0" : ""
              }`}
          >
            {openOptions && (
              <Sidebar
                className={"side-bar bg-white"}
                width={widthState <= PHONE_WIDTH || widthState <= 1280 ? "very wide" : "wide"}
                as={Menu}
                animation="overlay"
                icon="labeled"
                inverted
                direction="right"
                onHide={() => setOpenOptions(false)}
                vertical
                visible={openOptions}
              >
                <div className="d-flex flex-direction-row justify-content-between align-items-center">
                  <h1 className="title text-primary pb-1 ml-4">Options</h1>
                  <Button
                    icon
                    className="rounded-circle btn-sidebar"
                    onClick={() => setOpenOptions(false)}
                  >
                    <Icon name="close" />
                  </Button>
                </div>

                <h2 className="secondary-title text-left ml-4">
                  {t("roadTripPage.options")}
                </h2>
                <section className="d-flex flex-column gap-4">
                  <div>
                    {
                      roadtrip?.user_transactions[0]?.user_id !== roadtrip?.user_id &&
                      <Menu.Item
                        as="a"
                        className="text-dark ml-3"
                        onClick={handleCopyTrip}
                      >
                        <div className="d-flex flex-direction-row">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            height="24"
                            viewBox="0 0 24 24"
                            width="24"
                          >
                            <path d="M0 0h24v24H0z" fill="none" />
                            <path d="M16 1H4c-1.1 0-2 .9-2 2v14h2V3h12V1zm3 4H8c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h11c1.1 0 2-.9 2-2V7c0-1.1-.9-2-2-2zm0 16H8V7h11v14z" />
                          </svg>
                          <p className="ml-1 menu-items">{t("roadTripPage.duplicate")}</p>
                        </div>

                      </Menu.Item>
                    }

                    {
                      roadtrip?.user_transactions[0]?.user_id !== roadtrip?.user_id &&
                      <Menu.Item as='a' className='text-dark ml-3' onClick={() => !user?.id && openModal}>
                        <div className='d-flex flex-direction-row'>
                          {
                            favorite ?
                              <>
                                <FavoriteIcon height="27" width="27" sx={{ color: "red" }} />
                                <p onClick={() => handleClickFavorite(roadtrip.id)} className='ml-1 menu-items'>{t("roadTripPage.deleteFavorite")}</p>
                              </>
                              :
                              <>
                                <FavoriteBorderIcon height="40" width="40" />
                                <p onClick={() => handleClickFavorite(roadtrip.id)} className='ml-1 menu-items'>{t("roadTripPage.addFavorite")}</p>
                              </>
                          }

                        </div>
                      </Menu.Item>
                    }
                    <hr />
                    {!user?.id ? (
                      <>
                        <Menu.Item
                          as="a"
                          className="text-dark ml-3"
                        >
                          <div className="d-flex flex-direction-row">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              height="24"
                              viewBox="0 0 24 24"
                              width="24"
                            >
                              <path d="M0 0h24v24H0z" fill="none" />
                              <path d="M20 2H8c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2zm-8.5 7.5c0 .83-.67 1.5-1.5 1.5H9v2H7.5V7H10c.83 0 1.5.67 1.5 1.5v1zm5 2c0 .83-.67 1.5-1.5 1.5h-2.5V7H15c.83 0 1.5.67 1.5 1.5v3zm4-3H19v1h1.5V11H19v2h-1.5V7h3v1.5zM9 9.5h1v-1H9v1zM4 6H2v14c0 1.1.9 2 2 2h14v-2H4V6zm10 5.5h1v-3h-1v3z" />
                            </svg>
                            <p className="ml-1 menu-items">

                            </p>
                          </div>
                        </Menu.Item>
                      </>
                    ) : (

                      <>
                        <Menu.Item as="a" className="text-dark ml-3" >

                          <div className="d-flex flex-direction-row">

                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              height="24"
                              viewBox="0 0 24 24"
                              width="24"
                            >
                              <path d="M0 0h24v24H0z" fill="none" />
                              <path d="M20 2H8c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2zm-8.5 7.5c0 .83-.67 1.5-1.5 1.5H9v2H7.5V7H10c.83 0 1.5.67 1.5 1.5v1zm5 2c0 .83-.67 1.5-1.5 1.5h-2.5V7H15c.83 0 1.5.67 1.5 1.5v3zm4-3H19v1h1.5V11H19v2h-1.5V7h3v1.5zM9 9.5h1v-1H9v1zM4 6H2v14c0 1.1.9 2 2 2h14v-2H4V6zm10 5.5h1v-3h-1v3z" />
                            </svg>
                            <p className="ml-1 menu-items"
                              onClick={openPDFModal}
                            >
                              {t("roadTripPage.simplePDF")}
                            </p>

                          </div>

                        </Menu.Item>
                      </>
                    )}

                    {!user?.id ? (
                      <>
                        <Menu.Item
                          as="a"
                          className="text-dark ml-3"
                          onClick={openModal}
                        >
                          <div className="d-flex flex-direction-row">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              height="24"
                              viewBox="0 0 24 24"
                              width="24"
                            >
                              <path d="M0 0h24v24H0z" fill="none" />
                              <path d="M20 2H8c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2zm-8.5 7.5c0 .83-.67 1.5-1.5 1.5H9v2H7.5V7H10c.83 0 1.5.67 1.5 1.5v1zm5 2c0 .83-.67 1.5-1.5 1.5h-2.5V7H15c.83 0 1.5.67 1.5 1.5v3zm4-3H19v1h1.5V11H19v2h-1.5V7h3v1.5zM9 9.5h1v-1H9v1zM4 6H2v14c0 1.1.9 2 2 2h14v-2H4V6zm10 5.5h1v-3h-1v3z" />
                            </svg>
                            <p className="ml-1 menu-items">
                              {t("roadTripPage.fullPDF")}
                            </p>

                          </div>
                        </Menu.Item>
                      </>
                    ) : (
                      <>
                        <Menu.Item
                          as="a"
                          className="text-dark ml-3"
                          onClick={() => !user?.id && openModal}

                        >
                          <div className="d-flex flex-direction-row">


                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              height="24"
                              viewBox="0 0 24 24"
                              width="24"
                            >
                              <path d="M0 0h24v24H0z" fill="none" />
                              <path d="M20 2H8c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2zm-8.5 7.5c0 .83-.67 1.5-1.5 1.5H9v2H7.5V7H10c.83 0 1.5.67 1.5 1.5v1zm5 2c0 .83-.67 1.5-1.5 1.5h-2.5V7H15c.83 0 1.5.67 1.5 1.5v3zm4-3H19v1h1.5V11H19v2h-1.5V7h3v1.5zM9 9.5h1v-1H9v1zM4 6H2v14c0 1.1.9 2 2 2h14v-2H4V6zm10 5.5h1v-3h-1v3z" />
                            </svg>

                            <p className="ml-1 menu-items" onClick={openPDFCompletModal}>{t("roadTripPage.fullPDF")}</p>

                          </div>
                        </Menu.Item>


                      </>
                    )}
                  </div>
                  {isUserRT ? (
                    <div>
                      <hr />
                      <Menu.Item as="a" className="text-dark ml-3">
                        <div className="d-flex flex-direction-row">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            enableBackground="new 0 0 24 24"
                            height="24"
                            viewBox="0 0 24 24"
                            width="24"
                          >
                            <g>
                              <path d="M0,0h24v24H0V0z" fill="none" />
                              <path d="M19.14,12.94c0.04-0.3,0.06-0.61,0.06-0.94c0-0.32-0.02-0.64-0.07-0.94l2.03-1.58c0.18-0.14,0.23-0.41,0.12-0.61 l-1.92-3.32c-0.12-0.22-0.37-0.29-0.59-0.22l-2.39,0.96c-0.5-0.38-1.03-0.7-1.62-0.94L14.4,2.81c-0.04-0.24-0.24-0.41-0.48-0.41 h-3.84c-0.24,0-0.43,0.17-0.47,0.41L9.25,5.35C8.66,5.59,8.12,5.92,7.63,6.29L5.24,5.33c-0.22-0.08-0.47,0-0.59,0.22L2.74,8.87 C2.62,9.08,2.66,9.34,2.86,9.48l2.03,1.58C4.84,11.36,4.8,11.69,4.8,12s0.02,0.64,0.07,0.94l-2.03,1.58 c-0.18,0.14-0.23,0.41-0.12,0.61l1.92,3.32c0.12,0.22,0.37,0.29,0.59,0.22l2.39-0.96c0.5,0.38,1.03,0.7,1.62,0.94l0.36,2.54 c0.05,0.24,0.24,0.41,0.48,0.41h3.84c0.24,0,0.44-0.17,0.47-0.41l0.36-2.54c0.59-0.24,1.13-0.56,1.62-0.94l2.39,0.96 c0.22,0.08,0.47,0,0.59-0.22l1.92-3.32c0.12-0.22,0.07-0.47-0.12-0.61L19.14,12.94z M12,15.6c-1.98,0-3.6-1.62-3.6-3.6 s1.62-3.6,3.6-3.6s3.6,1.62,3.6,3.6S13.98,15.6,12,15.6z" />
                            </g>
                          </svg>
                          <p
                            onClick={() =>
                              setFormModal((state) => ({ ...state, edit: true }))
                            }
                            className="ml-1 menu-items"
                          >
                            {t("roadTripPage.modifyRoadTrip")}
                          </p>
                        </div>
                      </Menu.Item>
                      {
                        roadtrip?.user_transactions[0]?.user_id !== roadtrip?.user_id &&
                        <Menu.Item as="a" className="text-dark ml-3">
                          <div className="d-flex flex-direction-row">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              height="24"
                              viewBox="0 0 24 24"
                              width="24"
                            >
                              <path d="M0 0h24v24H0V0z" fill="none" />
                              <path
                                fill="red"
                                d="M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6v12zM8 9h8v10H8V9zm7.5-5l-1-1h-5l-1 1H5v2h14V4z"
                              />
                            </svg>
                            <p
                              onClick={() =>
                                setFormModal((state) => ({
                                  ...state,
                                  delete: true,
                                }))
                              }
                              className="text-danger ml-1 menu-items"
                            >
                              {t("roadTripPage.deleteRoadTrip")}
                            </p>
                          </div>
                        </Menu.Item>
                      }
                    </div>
                  ) : (
                    <></>
                  )}
                </section>
              </Sidebar>
            )}
            <div className={` ${openOptions ? "blur-page" : ""}`}>
              <div className={`row m-0 `}>
                <div
                  className={`col-12 col-md-${openMap ? 6 : 12} p-2 result-list ${widthState <= PHONE_WIDTH && openMap ? "d-none" : ""
                    }`}
                >
                  {loading ? (
                    <ClassicPostLoader
                      className="roadtrip-img "
                      width="100%"
                      height="100%"
                    />
                  ) : (
                    <ImgLazy
                      src={roadtrip.picture}
                      className="
                      px-1 touristic-site-page--header-image mt-sm-3"
                    />
                  )}

                  <div className="container">
                    <div
                      className={`${widthState <= PHONE_WIDTH || widthState <= 1280
                        ? "roadtrip-header mx-1"
                        : "roadtrip-header mx-3"
                        }`}
                    >
                      {loading ? (
                        <ContentLoader
                          speed={2}
                          width="100%"
                          height={200}
                          viewBox="0 0 500 200"
                          backgroundColor="#f3f3f3"
                          foregroundColor="#ecebeb"
                        >
                          <rect
                            x="0"
                            y="15"
                            rx="5"
                            ry="5"
                            width="220"
                            height="10"
                          />
                          <rect
                            x="0"
                            y="45"
                            rx="5"
                            ry="5"
                            width="220"
                            height="10"
                          />
                          <rect
                            x="0"
                            y="77"
                            rx="5"
                            ry="5"
                            width="100%"
                            height="75"
                          />
                        </ContentLoader>
                      ) : (
                        <>
                          <div
                            className={`${widthState <= PHONE_WIDTH
                              ? "d-flex flex-column-reverse"
                              : "row"
                              }`}
                          >
                            <div
                              className={`${widthState <= PHONE_WIDTH || widthState <= 1280 ? "" : "col-6"
                                }`}
                            >
                              <Title
                                title={roadtrip.title}
                                description={`${t("roadTripPage.by")} ${roadtrip.user?.firstname || ""
                                  } ${roadtrip.user?.lastname.toUpperCase() || ""}`}
                                noTopPad={true}
                              />
                              {isUserRT && <Status state={roadtrip.public} />}

                              <div className="d-flex justify-content-start mt-2">
                                <SvgIcon
                                  className="me-3"
                                  component={VisibilityOutlined}
                                  width="20"
                                  height="20"
                                />
                                <p className="ml-1">
                                  <strong>{views}</strong>
                                  {views < 2 ? " vue" : " vues"}
                                </p>

                                {roadtrip?.feedbacks && (
                                  <Feedback
                                    className="touristic-site-page--header__feedback mx-2"
                                    size="sm"
                                    note={roadtrip?.feedbacks?.note}
                                    feedbacks={roadtrip.feedbacks?.len}
                                  />
                                )}
                              </div>
                            </div>
                            <div
                              className={`${widthState <= PHONE_WIDTH ? "" : "col-6"
                                }`}
                            >
                              <div
                                className={`${widthState <= PHONE_WIDTH
                                  ? "row justify-content-center my-2"
                                  : "d-flex justify-content-end"
                                  }`}
                              >
                                {roadTripIsPublic && isUserRT && (
                                  <div>
                                    <HeaderButton
                                      text={t("roadTripPage.shareButton")}
                                      materialIcon={ShareIcon}
                                      color="information-block"
                                      onClick={handleShare}
                                      className={`${widthState <= PHONE_WIDTH || widthState <= 1280 ? "p-1" : ""
                                        }`}
                                    />
                                  </div>
                                )}

                                {!roadTripIsPublic && !isUserRT ? (
                                  <></>
                                ) : (
                                  <div className={`mx-${!openMap ? 1 : 1}`}>
                                    <HeaderButton
                                      text={t("general.options")}
                                      materialIcon={FormatListBulletedIcon}
                                      color="information-block"
                                      className={`${widthState <= PHONE_WIDTH || widthState <= 1280 ? "p-1" : ""
                                        }`}
                                      onClick={onOptionsClick}
                                    />
                                  </div>
                                )}

                                <div>
                                  <HeaderButton
                                    text={t("general.map")}
                                    materialIcon={MapIcon}
                                    color="information-block"
                                    className={`${widthState <= PHONE_WIDTH || widthState <= 1280 ? "p-1" : ""
                                      }`}
                                    onClick={onMapClick}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>

                          <h5
                            className="text-justify"
                            style={{ whiteSpace: "pre-wrap" }}
                          >
                            {roadtrip.description}
                          </h5>

                          <div className="my-3">
                            <div className={`${openMap ? "col p-0" : "row"} `}>
                              {categoriesTransport?.length > 0 ? (
                                <div
                                  className={`${openMap ? "row-md-4" : "col-12 col-md-4"
                                    } mb-3`}
                                >
                                  <h4 className="categories">{t("general.category.transport.title")} :</h4>
                                  <div className="row">
                                    {categoriesTransport?.map((el) => (
                                      <CategoriesIcons
                                        key={el.url}
                                        name={el.title}
                                        color={el.color}
                                        icon={el.url}
                                      />
                                    ))}
                                  </div>
                                </div>
                              ) : (
                                <></>
                              )}
                              {categoriesGroup?.length > 0 ? (
                                <div
                                  className={`${openMap ? "row-md-4" : "col-12 col-md-4"
                                    } mb-3 `}
                                >
                                  <h4 className="categories">{t("general.category.withWhom.title")}</h4>
                                  <div className="row">
                                    {categoriesGroup?.map((el) => (
                                      <CategoriesIcons
                                        key={el.url}
                                        name={el.title}
                                        color={el.color}
                                        icon={el.url}
                                      />
                                    ))}
                                  </div>
                                </div>
                              ) : (
                                <></>
                              )}
                              {categoriesSeasons?.length > 0 ? (
                                <div
                                  className={`${openMap ? "row-md-4" : "col-12 col-md-4"
                                    } mb-3`}
                                >
                                  <h4 className="categories">{t("general.category.season.title")} :</h4>
                                  <div className="row">
                                    {categoriesSeasons?.map((el) => (
                                      <CategoriesIcons
                                        key={el.url}
                                        name={el.title}
                                        color={el.color}
                                        icon={el.url}
                                      />
                                    ))}
                                  </div>
                                </div>
                              ) : (
                                <></>
                              )}
                            </div>
                          </div>
                        </>
                      )}
                    </div>

                    <TouristicSiteSection
                      rtTs={rtTs}
                      openModal={openModal}
                      loading={loading}
                      isUserRT={isUserRT}
                      checkedPassword={checkedPassword}
                      openTsModal={openTsModal}
                      setTsModal={setTsModal}
                      enterPassword={enterPassword}
                      openSuggestModal={openSuggestModal}
                    />
                  </div>
                </div>
                {widthState >= PHONE_WIDTH && (
                  <div
                    className={`${widthState >= PHONE_WIDTH && !openMap
                      ? "d-none"
                      : "col-12 col-md-6 px-0"
                      }`}
                  >
                    <div
                      className="map-rt col-12 col-md-12 px-0 d-block position-sticky"
                      style={{
                        width: "100%",
                        height: "calc(100vh - 89px)",
                        top: 89,
                      }}
                    >
                      {roadtrip?.touristic_sites && (
                        <Suspense
                          fallback={<Loader active className="mt-4 fixed-top" />}
                        >
                          <RoadTripMap
                            user={user}
                            checkedPassword={checkedPassword}
                            onMapClick={onMapClick}
                            openModal={openModal}
                            rtTs={rtTs}
                            openTsModal={openTsModal}
                            setTsModal={setTsModal}
                          />
                        </Suspense>
                      )}
                    </div>
                  </div>
                )}
              </div>

              <AdsByGoogle keys="1100745944">
                <ins
                  className="adsbygoogle d-flex justify-content-center align-items-center"
                  data-ad-client="ca-pub-3342109216277632"
                  data-ad-slot="1100745944"
                  data-ad-format="auto"
                  data-full-width-responsive="true"
                ></ins>
              </AdsByGoogle>
              <Feedbacks
                feedbacks={roadtrip.feedbacks?.comments}
                type="road_trip"
                id={roadtrip.id}
                className={`${widthState <= PHONE_WIDTH || widthState <= 1280 ? "px-3" : "px-3 px-lg-5"
                  }`}
                askUserConnectModal={openModal}
              />
              {roadtrip?.suggestions && roadtrip?.suggestions.length !== 0 && (
                <TouristicSiteSuggestions
                  suggestions={roadtrip.suggestions}
                  classNames={`${widthState <= PHONE_WIDTH || widthState <= 1280
                    ? "mx-3 pt-0"
                    : "roadtrip-header px-1 px-lg-5"
                    }`}
                  text={t("general.suggestions", {
                    ns: "henriTrip",
                  })}
                />
              )}

              <DeleteRoadTrip
                show={formModal.delete}
                onHide={() =>
                  setFormModal((state) => ({ ...state, delete: false }))
                }
                id={matchRoute.params.id}
              />
              <ExportRoadTrip
                show={formModal.export}
                onHide={() =>
                  setFormModal((state) => ({ ...state, export: false }))
                }
                match={matchRoute}
              />
              <CopyRoadTrip
                show={formModal.copy}
                onHide={() =>
                  setFormModal((state) => ({ ...state, copy: false }))
                }
                id={matchRoute.params.id}
              />
              <Share
                link={window.location.href}
                title={`Henri - Road trip - ${roadtrip.title}`}
                error={formModal.shareError}
                show={formModal.share}
                onHide={() =>
                  setFormModal((state) => ({ ...state, share: false }))
                }
              />
            </div>
          </div>
        )
      }
    </>
  );
};

export default RoadTripPage;