import React from 'react';
import SVG from 'react-inlinesvg';

import "./slider-button.scss";

const SliderButton = ({current, onChange, isInfinite}) => {
  const isFirst = React.useMemo(() => {
    if (isInfinite)
      return false;
    return current === 0
  }, [current, isInfinite]);

  return (
    <div className="align-items-center d-flex pt-2">
      <div className={isFirst ? "" : "slider-button"} onClick={() => {if (!isFirst) { onChange(isInfinite ? -1 : (isFirst ? 0 : -1));}}}>
        <SVG src="/assets/icons/Chevron_right_font_awesome.svg" width="35" className= {`text-${isFirst ? "muted slider-button__svg" : "dark slider-button__svg"}`} style={{transform: "scaleX(-1)"}} />
      </div>
      <div className="slider-button" onClick={() => onChange(1)}>
        <SVG src="/assets/icons/Chevron_right_font_awesome.svg" width="35" className="ml-3 text-dark slider-button__svg" />
      </div>
    </div>
    );
};

export default SliderButton;
