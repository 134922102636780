import React from 'react';
import {
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon,
  WhatsappShareButton,
  WhatsappIcon,
  TelegramShareButton,
  TelegramIcon,
  EmailShareButton,
  EmailIcon,
  LinkedinShareButton,
  LinkedinIcon,
} from 'react-share';
import ModalForm from "../../components/formModal/ModalForm";
import { Button } from "../../components/form/button";
import { useTranslation } from "react-i18next";

const SharePage = ({ show, onHide, title, link, error }) => {
  const {t} = useTranslation(["henriTrip"])
  return (
    <ModalForm
      isOpen={show}
      onClose={onHide}
      title={t("account.share.title")}
    >
      <div className="p-3">

        {
          (!title && !link && !error) ?
            <></>
            :
            (error) ?
              <div className="bg-danger p-2 text-white">
                {t("account.share.error")}
              </div>
              :
              <div className="d-flex flex-wrap justify-content-left align-items-center pt-2">
                <FacebookShareButton
                  url={link}
                  quote={title}
                  className="pr-1"
                >
                  <FacebookIcon size={52} round />
                </FacebookShareButton>
                <TwitterShareButton
                  url={link}
                  title={title}
                  className="pr-1"
                >
                  <TwitterIcon size={52} round />
                </TwitterShareButton>
                <WhatsappShareButton
                  url={link}
                  title={title}
                  className="pr-1"
                >
                  <WhatsappIcon size={52} round />
                </WhatsappShareButton>
                <TelegramShareButton
                  url={link}
                  title={title}
                  className="pr-1"
                >
                  <TelegramIcon size={52} round />
                </TelegramShareButton>
                <EmailShareButton
                  url={link}
                  subject={title}
                  className="pr-1"
                >
                  <EmailIcon size={52} round />
                </EmailShareButton>
                <LinkedinShareButton
                  url={link}
                  title={title}
                  className="pr-1"
                >
                  <LinkedinIcon size={52} round />
                </LinkedinShareButton>
              </div>
        }
        <div className=" mt-3">
          <Button title={t("general.buttonCancel")} color="secondary" className="mr-1" extended onClick={onHide} />
        </div>
      </div>
    </ModalForm>
  );
};

export default SharePage;