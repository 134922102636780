import React, { useEffect, useRef, useState } from "react";
import { Create as CreateIcon } from "@material-ui/icons";
import { SvgIcon } from "@material-ui/core";
import { Button } from "../button";
import "./fileInputExperience.scss";
import Compressor from 'compressorjs';

const ListFileElement = ({ file, onClickHandler }) => {
    const [loading, setLoading] = useState(true)
    const [thumb, setThumb] = useState(undefined)
    const [fileExtension, setFileExtension] = useState(undefined)

    useEffect(() => {
        if (!file) { return; }

        setFileExtension(file.name.match(/\.[0-9a-z]+$/i))
        if (file.type.match('image/*')) {
            let reader = new FileReader();
            reader.onloadend = () => {
                setThumb(reader.result)
                setLoading(false)
            };
            reader.readAsDataURL(file);
        }
        else {
            setLoading(false)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    if (!file) { return null; }

    if (loading) { return <p>loading...</p>; }

    return (
        <div className="d-flex justify-content-between align-items-center my-1 px-lg-3">
            {
                thumb ?
                    <img src={thumb} alt={file.name} height={50} width={50} />
                    :
                    <div style={{ height: 50, width: 50, backgroundColor: '#efefef' }} className="d-flex justify-content-center align-items-center">
                        <span className="fw-bold text-black">{fileExtension}</span>
                    </div>
            }
            <div className="w-75 text-truncate"><span>{file.name}</span></div>
            <button className="btn btn-outline-none btn-danger" type="button" onClick={() => onClickHandler(file.name)}>X</button>
        </div>
    )
}

const DropZone = ({ srcDefault, filesState, onFileChange, accepts, imgClass }) => {
    const inputRef = useRef()
    const dropZoneRef = useRef()
    const [bg, setBG] = useState(srcDefault)
    const [backColor, setBackColor] = useState("#A3D3FF")
    useEffect(() => {
        if (filesState.length > 0) {
            setBG(URL.createObjectURL(filesState[0]))
        }
    }, [filesState])
    useEffect(() => {

        if (filesState.length > 0) {
            setBackColor("#0008")
            if (dropZoneRef.current) {


            }
        }
    }, [bg])



    return (
        <div ref={dropZoneRef} className={`dropZone w-100 h-100 d-flex justify-content-center align-items-center position-relative ${imgClass} overflow-hidden experience-header-page`} style={{ '--backgroundColor': `${backColor}` }}>
            <img
                className={imgClass || ''}
                width={"100%"}
                height={"100%"}
                src={bg}
                alt={bg}
                onError={() => setBG("https://general-henri-1-paris.s3.fr-par.scw.cloud/woman.jpg")}
            />
            <input ref={inputRef} style={{ display: 'none' }} id="file" name="file" type="file" onChange={onFileChange} accept={accepts} />
            <button type="button" onClick={() => inputRef.current.click()} className="dropZone__btn position-absolute d-flex justify-content-center align-items-center">
                <svg width="39" height="39" viewBox="0 0 39 39" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path opacity="0.8" d="M19.5989 3.85251H10.8921C7.77955 3.85251 6.22329 3.85251 5.03447 4.45825C3.98875 4.99107 3.13856 5.84126 2.60574 6.88698C2 8.0758 2 9.63206 2 12.7446V28.3057C2 31.4182 2 32.9744 2.60574 34.1633C3.13856 35.209 3.98875 36.0592 5.03447 36.592C6.22329 37.1977 7.77955 37.1977 10.8921 37.1977H27.9352C29.658 37.1977 30.5194 37.1977 31.2261 37.0084C33.1439 36.4945 34.642 34.9965 35.1559 33.0786C35.3452 32.3719 35.3452 31.5105 35.3452 29.7877M31.6402 13.1151V2M26.0827 7.55754H37.1977M15.8938 14.0413C15.8938 16.0876 14.235 17.7464 12.1888 17.7464C10.1426 17.7464 8.48379 16.0876 8.48379 14.0413C8.48379 11.9951 10.1426 10.3363 12.1888 10.3363C14.235 10.3363 15.8938 11.9951 15.8938 14.0413ZM24.2117 20.3735L8.5415 34.6191C7.6601 35.4204 7.2194 35.821 7.18042 36.1681C7.14663 36.4689 7.26198 36.7673 7.48934 36.9672C7.75164 37.1977 8.34723 37.1977 9.5384 37.1977H26.9274C29.5934 37.1977 30.9265 37.1977 31.9735 36.7498C33.2879 36.1876 34.3351 35.1404 34.8973 33.826C35.3452 32.779 35.3452 31.446 35.3452 28.7799C35.3452 27.8828 35.3452 27.4343 35.2472 27.0166C35.1239 26.4917 34.8876 26 34.5547 25.5758C34.2898 25.2383 33.9395 24.9581 33.2391 24.3977L28.0571 20.2522C27.3561 19.6913 27.0055 19.4109 26.6195 19.3119C26.2793 19.2247 25.9212 19.236 25.5872 19.3445C25.2082 19.4676 24.876 19.7696 24.2117 20.3735Z" stroke="black" stroke-width="2.0113" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
            </button>
        </div>
    )
}

const FileInputExperience = ({ dropZone, srcDefault, imgClass, accepts, maxSize, buttonStyle, name, setFieldValue, isMulti, parentClasses, label, required, labelSuffix, helper, errors }) => {
    const [filesState, setFilesState] = React.useState([])
    const inputRef = useRef()
    useEffect(() => {
        setFieldValue(name, filesState);
        console.log(name)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filesState])


    const compressFile = (image, quality) => {
        if (quality < 0.6) {
            return
        }
        if (image.size <= maxSize) {
            setFilesState(state => [
                ...state,
                image
            ])
        }
        else {
            new Compressor(image, {
                quality: quality || NaN,
                convertTypes: 'image/png',
                convertSize: 1000000,
                maxHeight: 1080,
                success: (compressedResult) => {
                    const q = quality ? (quality - 0.1) : 0.9
                    compressFile(compressedResult, q)
                },
            });
        }
    }

    const onFileChange = (event) => {
        const validFiles = [...event.currentTarget.files].filter(file => {
            if (accepts.includes(file.type) && !filesState.some(file1 => file1.name === file.name) && (file.size > maxSize)) {
                compressFile(file)
            }
            return accepts.includes(file.type) && !filesState.some(file1 => file1.name === file.name) && (file.size <= maxSize)
        })
        if (isMulti) {
            setFilesState(state => [
                ...state,
                ...validFiles
            ])
        }
        else {
            setFilesState(validFiles)
        }
    }

    const onDeleteHandler = (key) => {
        const filesStateClone = [...filesState].filter(file => file.name !== key);
        setFilesState(filesStateClone)
    }

    return (
        (dropZone) ?
            <DropZone filesState={filesState} srcDefault={srcDefault} onFileChange={onFileChange} accept={accepts} imgClass={imgClass} />
            :
            <div
                className={`form-group fv-plugins-icon-container ${parentClasses}`}
            >
                {label && (
                    <div className="d-flex justify-content-between mt-n5">
                        <label className="font-size-h6 text-dark pt-5">
                            {label}
                            {required ? " *" : ""}
                        </label>
                        {labelSuffix}
                    </div>
                )}
                <div className="d-flex flex-column">
                    {/*<span className="mb-1">{`Taille maximale pour les nouveaux fichiers : ${maxSize/1000000}Mo`}</span>*/}
                    <input ref={inputRef} style={{ display: 'none' }} id="file" name="file" type="file" onChange={onFileChange} accept={accepts} multiple={isMulti || false} />
                    <Button {...buttonStyle} onClick={() => inputRef.current.click()} />
                    <div>
                        {
                            filesState.map((el) => {
                                return <ListFileElement file={el} key={el.name} onClickHandler={onDeleteHandler} />
                            })
                        }
                    </div>
                </div>
                {
                    helper &&
                    <p className="m-0 text-muted">{helper}</p>
                }
                {errors[name] ? (
                    <div className="fv-plugins-message-container">
                        <div className="fv-help-block text-danger">
                            {errors[name]}
                        </div>
                    </div>
                ) : null}
            </div>
    );
};

export default FileInputExperience;
