import React, { Component } from 'react'
import "./autocomplete.scss"
import axios from 'axios'
import PropTypes from "prop-types";
import {IconButton} from "@material-ui/core";
import {Clear} from "@material-ui/icons"
import { withTranslation } from "react-i18next";

class AutocompletePlaceAddress extends Component {
  constructor(props) {
    super(props)
    this.state = {
      search: '',
      results: [],
      isLoading: false,
      focus: false
    }
    this.impRef = React.createRef();
    this.handleSearchChange = this.handleSearchChange.bind(this)
    this.handleClearClicked = this.handleClearClicked.bind(this)

    if (!process.env.REACT_APP_MAPBOX_ACCESS_TOKEN) {
      throw new Error("You don't have any 'process.env.REACT_APP_MAPBOX_ACCESS_TOKEN'")
    }
  }

  componentDidUpdate(prevProps) {
    if(this.props.newAddress !== prevProps.newAddress && this.props.newAddress){
      this.setState({search: this.props.newAddress.place_name})
    }
  }

  handleSearchChange(e) {
    if (this.props.isAddressComplete) this.props.isAddressComplete(true)
    this.setState({
      search: e.target.value,
      isLoading: true
    })

    // Stop the previous setTimeout if there is one in progress
    clearTimeout(this.timeoutId)

    // Launch a new request in 1000ms
    this.timeoutId = setTimeout(() => {
      this.performSearch()
    }, 1000)
  }
  performSearch() {
    if (this.state.search === "") {
      this.setState({
        results: [],
        isLoading: false
      })
      return
    }
    const requestOne = axios.get(`https://api.mapbox.com/geocoding/v5/mapbox.places/${this.state.search}.json?access_token=${process.env.REACT_APP_MAPBOX_ACCESS_TOKEN}&cachebuster=1623166568366&autocomplete=true${this.props.worldWideSearch ? "" : "&country=fr%2Cmq%2Cmf%2Cyt%2Cpf&language=fr"}`)
    axios.all([requestOne]).then(axios.spread((...responses) => {
      const responseOne = responses[0]
      this.setState({
        results: responseOne.data.features,
        isLoading: false
      })
    })).catch(errors => {
      console.log(errors)
    })
  }
  handleItemClicked(place) {
    if (this.props.isAddressComplete) this.props.isAddressComplete(false)
    this.setState({
      search: place.place_name,
      results: []
    })
    this.props.onSelect(place)
  }

  handleClearClicked(){
    this.setState({
      search: '',
      results: []
    })
    this.props.onClear()
  }

  click = ()=>{
    this.impRef.current.focus();
  }

  render() {
    return (
      <div className="w-100">
        {this.state.search &&
          <div className="autocompletePlace-clear">
            <IconButton onClick={this.handleClearClicked}>
              <Clear/>
            </IconButton>
          </div>
        }
        <div className="adresse font-weight-bold">{this.props.t("general.address")}</div>
        <input
          className="autocompletePlace-input font-weight-medium"
          type="text"
          value={this.state.search}
          onChange={this.handleSearchChange}
          placeholder={this.props.placeholder}
          ref={this.impRef}
          onFocus={() => this.props.onFocusInp()}
          onBlur={() => this.props.onBlurInp()}
        />

        {this.state.search &&
          <ul className="autocompletePlace-results font-weight-medium">
            {this.state.results.map(place => (
              <li
                key={place.id}
                className="autocompletePlace-items"
                onClick={() => this.handleItemClicked(place)}
              >
                {place.place_name}
              </li>
            ))}
            {this.state.isLoading &&
              <li className="autocompletePlace-items">{this.props.t("loading")}</li>
            }
          </ul>
        }
      </div>
    )
  }
}

AutocompletePlaceAddress.propTypes = {
  placeholder: PropTypes.string,
  onSelect: PropTypes.func.isRequired,
  onClear: PropTypes.func.isRequired,
  onFocusInp: PropTypes.func,
  onBlurInp: PropTypes.func,
  newAddress: PropTypes.object
}

export default withTranslation("henriTrip", { withRef: true })(AutocompletePlaceAddress);