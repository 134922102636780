import React, {useState} from "react";
import styled from "styled-components";
import breakpoint from "styled-components-breakpoint";
import Modal from "../../../_ui/_v2/components/Modal";
import Arrow from "../../../_ui/_v2/components/Arrow";
import ExperienceCartTicket from "./ExperienceCartTicket";
import categories from "../../../utils/categories";

import {ReactComponent as PineIcon} from '../../../assets/img/logo/pine_localisation.svg';
import {ReactComponent as HenriSideLogo} from '../../../assets/img/logo/henri-side-logo.svg';
import SVG from "react-inlinesvg";
import PaymentFormContainer from "../../../_ui/_v2/components/PaymentFormContainer";
import {useTranslation} from "react-i18next";
import TextInput from "../../../_ui/_v2/components/FormElements/TextInput";
import Button from "../../../_ui/_v2/components/Button";
import {Label} from "../../../_ui/_v2/components/FormElements";
import {API_STRIPE_PROMOTION_CODES} from "../../../utils/api";
import {useSelector} from "react-redux";

const RemoveInMobile = styled.div`
  display: none;
  
  ${breakpoint('md')`
    display: initial;
  `}
`;
const RemoveInDesktop = styled.div`
  display: inline-block;
  
  ${breakpoint('md')`
    display: none;
  `}
`;

const accumulatorSites = (acc, trip_day) => acc + trip_day.touristic_sites.length;

const groupBy = (items, key) => items.reduce(
  (result, item) => ({
    ...result,
    [item[key]]: [
      ...(result[item[key]] || []),
      item,
    ],
  }),
  {},
);

const SVGContainer = styled.div`
  box-sizing: border-box;
  padding: 5px;
  background-color: ${props => props.theme.colors.white};
  border-radius: 100%;
  min-width: 30px;
  
  img {
    margin: 0 auto;
    width: 20px;
    height: 20px;
    border-radius: 0;
  }
`;

const CheckOutResumeLine = ({categoryStuff}) => {
  const category = categoryStuff?.[0]?.category;
  const category_name = categoryStuff?.[0]?.category_name;
  const custom_category_icon = category.id ? categories.custom_icons[category.id] : undefined;
  const category_icon = category.color ? categories.icons_with_current_color[category.color] : undefined;
  return (
    <div
      style={{
        display: 'flex',
        gap: '.5em',
      }}
    >
      <SVGContainer>
        {
          custom_category_icon ?
            <img
              src={custom_category_icon}
              color={category.color}
              alt={category.title}
            />
            :
            <SVG
              src={category_icon}
              color={category.color}
              height="20px"
              width="20px"
            />
        }
      </SVGContainer>
      <span
        style={{
          display: 'block',
          margin: 'auto 0'
        }}
      >
        {category_name}
      </span>
      <span
        style={{
          height: 15,
          margin: 'auto 0',
          flex: '1 1 auto',
          borderBottom: '1px dotted black'
        }}
      />
      <span
        style={{
          display: 'block',
          margin: 'auto 0'
        }}
      >
        {categoryStuff.length}
      </span>
    </div>
  );
};

const CheckOutResume = ({roadtrip, promotionCodeChange}) => {
  const {i18n} = useTranslation();
  const config = useSelector(state => state.data);
  const [promoCode, setPromoCode] = useState('');
  const [promoCodeResponse, setPromoCodeResponse] = useState(null);
  const countActivities = roadtrip.trip_days.reduce(accumulatorSites, 0);

  const allSites = roadtrip.trip_days.flatMap(trip_day => [...trip_day.touristic_sites]);
  const allSitesFormatted = allSites.map(site => (
    {
      id: site.id,
      category: {...site.categories?.[0]},
      category_name: site.categories?.[0]?.title || 'Autre'
    }
  ));
  const sitesGroupByCategoryName = groupBy(allSitesFormatted, 'category_name');
  const priceFormatted = new Intl.NumberFormat('fr-FR', { style: 'currency', currency: roadtrip.currency }).format(roadtrip.amount / 100);

  const verifyPromotionCode = (code) => {
    return fetch(
      process.env.REACT_APP_API_URL + API_STRIPE_PROMOTION_CODES + `?code=${code}`,
      {
        method: "GET",
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': config?.headers?.Authorization,
        },
      }
    ).then(
      response => response.json()
    ).then(response => {
      setPromoCodeResponse(response);
      promotionCodeChange(response?.data?.[0]?.code);
    });
  };

  const computePricePromo = () => {
    const stripePromotionCode = promoCodeResponse?.data?.[0];

    if (promoCodeResponse?.data?.length <= 0) {
      return priceFormatted;
    }
    const amountDiscount = stripePromotionCode.coupon.amount_off
      ? Math.max(roadtrip.amount - stripePromotionCode.coupon.amount_off, 0)
      : roadtrip.amount - Math.round(roadtrip.amount * stripePromotionCode.coupon.percent_off / 100);
    return new Intl.NumberFormat('fr-FR', { style: 'currency', currency: roadtrip.currency }).format(amountDiscount / 100);
  };


  return (
    <div
      style={{
        padding: '.5rem 2rem 1rem 2rem'
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: 'space-between',
          alignItems: 'center',
          gap: '1em',
          flexWrap: 'wrap',
        }}
      >
        <div
          style={{
            display: "inline-flex",
            alignItems: 'center',
          }}
        >
          <PineIcon width="20" height="30"/>
          <h1
            style={{margin: "0 0 0 0.5em", fontWeight: 700}}
          >
            {roadtrip.title[`text_${i18n.language}`] || roadtrip.title?.text_fr}
          </h1>
        </div>
        <span style={{fontWeight: 600}}>{roadtrip.trip_days.length} jours</span>
      </div>
      <div
        className="align-left"
        style={{marginBottom: '1em'}}
      >
        <span>{countActivities} activités</span>
      </div>

      {
        Object.keys(sitesGroupByCategoryName).map((category_name, index) => (
          <CheckOutResumeLine
            key={index}
            categoryStuff={sitesGroupByCategoryName[category_name]}
          />
        ))
      }
      {(!promoCodeResponse?.data || promoCodeResponse?.data?.length === 0) && (
        <div style={{
          borderTop: '1px solid grey',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          padding: '10px 0',
          gap: 20,
        }}
        >
          <Label htmlFor='code'>Code promo</Label>
          <TextInput
            value={promoCode}
            onChange={(e) => setPromoCode(e.target.value)}
            name='code'
            type='text'
            placeholder='Code'
            hasError={promoCodeResponse?.data?.length === 0}
            errorMessage={"Code invalide"}
            style={{display: 'inline'}}
          />
          <Button
            onClick={() => verifyPromotionCode(promoCode)}
          >
            Appliquer
          </Button>
        </div>
      )}
      <div style={{
        borderTop: '1px solid grey',
        display: 'flex',
        justifyContent: 'space-between',
        padding: '10px 0',
      }}
      >
        <span style={{fontWeight: 600}}>
          Prix
        </span>
        <span
          style={{
            fontWeight: 700,
            textDecoration: promoCodeResponse?.data?.length > 0 ? 'line-through' : 'none',
          }}
        >
          {priceFormatted}
        </span>
      </div>
      {promoCodeResponse?.data?.length > 0 && (
        <div style={{
          marginBottom: '1rem',
          borderTop: '1px solid grey',
          display: 'flex',
          justifyContent: 'space-between',
          padding: '10px 0',
        }}
        >
        <span style={{fontWeight: 600}}>
          Prix avec {promoCodeResponse?.data?.[0]?.code}
        </span>
          <span style={{fontWeight: 700}}>
          {computePricePromo()}
        </span>
        </div>
      )}
      <p
        className="small"
        style={{
          fontSize: 10,
          maxWidth: 300,
          marginLeft: 'auto',
          marginRight: 'auto',
        }}
      >
        J'accepte les <a href="https://support.henritrip.fr/conditions-generales-dutilisation-du-site-internet-henri-trip-et-notifications/" target="_blank">conditions générales de&nbsp;vente</a>
      </p>
      {/*<p*/}
      {/*  style={{*/}
      {/*    fontWeight: 600,*/}
      {/*    maxWidth: 300,*/}
      {/*    margin: 'auto',*/}
      {/*  }}*/}
      {/*>*/}
      {/*  Votre road trip est modifiable à&nbsp;volonté*/}
      {/*</p>*/}
    </div>
  );
};

const FirstComponent = ({roadtrip, promotionCodeChange, Header, ...rest}) => (
  <div {...rest}>
    {Header}
    <CheckOutResume roadtrip={roadtrip} promotionCodeChange={promotionCodeChange}/>
  </div>
);

const SecondComponent = ({roadtrip, promotionCode, onPaymentSucceeded, onPaymentCanceled, ...rest}) => {
  // const trip_day = roadtrip.trip_days[0];
  // const site = [...trip_day.touristic_sites, ...trip_day.custom_touristic_sites][0];
  // const image = site.ts_type === 'custom'
  //   ? site.custom_touristic_site_files.filter(f => f.type_file.includes("image"))?.[0]?.file_url
  //   : site.touristic_site_images?.[0]?.picture;

  return (
    <div
      style={{display: 'flex', justifyContent: 'center'}}
      {...rest}
    >
      <PaymentFormContainer
        promotionCode={promotionCode}
        onPaymentSucceeded={onPaymentSucceeded}
        onPaymentCanceled={onPaymentCanceled}
        roadtrip_id={roadtrip.id}
        style={{
          maxWidth: 350,
          padding: '.5rem 2rem 1rem 2rem',
        }}
      />
      {/*{*/}
      {/*  image && (*/}
      {/*    <img*/}
      {/*      src={image}*/}
      {/*      alt={site.title}*/}
      {/*      style={{*/}
      {/*        maxWidth: '250px',*/}
      {/*      }}*/}
      {/*    />*/}
      {/*  )*/}
      {/*}*/}
    </div>
  );
};

const TitleContainer = styled.div`
  margin: auto auto auto auto;
  display: inline-block;
  vertical-align: middle;
  text-align: center;
  font-size: 1.7rem;
  font-weight: 500;
  ${breakpoint('md')`
    display: block;
  `}
`;

const NavigationHeaderContainer = styled.div`
  padding: 2rem 2rem .5rem 2rem;
  
  ${breakpoint('md')`
    padding-bottom: 2rem;
  `}
`;

const NavigationHeader = ({onRequestClose}) => (
  <NavigationHeaderContainer className="align-left">
    <RemoveInDesktop
      style={{marginRight: '2rem'}}
    >
      <Arrow
        direction="left"
        onClick={onRequestClose}
      />
    </RemoveInDesktop>
    <TitleContainer>
      TICKET
    </TitleContainer>
  </NavigationHeaderContainer>
);

const NavigationWithLogoContainer = styled.div`
  padding: 2rem 2rem 2rem 2rem;
  position: relative;
`;

const LogoContainer = styled.div`
  display: inline-block;
  position: absolute;
  left: 0;
  width: 100%;
  
  svg {
    display: block;
    margin: auto;
    max-width: 300px;
    max-height: 35px;
    color: ${props => props.theme.colors.brand};
  }
`;
const NavigationWithLogo = ({onRequestClose}) => (
  <NavigationWithLogoContainer className="align-left">
    <LogoContainer>
      <HenriSideLogo/>
    </LogoContainer>
    <Arrow
      direction="left"
      onClick={onRequestClose}
    />
  </NavigationWithLogoContainer>
);

const ModalFormContainer = styled.div`
  
  ${breakpoint('md')`
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: linear-gradient(black, black), url("${props => props.image_url}");
    background-blend-mode: saturation;
  `}
`;

const ModalContainer = styled.div`
  ${breakpoint('md')`
    width: 100vw;
    height: 100vh;
    background-color: ${props => props.theme.colors.white};
  `}
`;

const ExperiencePaymentModal = ({roadtrip, onPaymentSucceeded, onRequestClose, ...rest}) => {
  const [promotionCode, setPromotionCode] = useState();

  return (
    <Modal
      overlay
      onRequestClose={onRequestClose}
      {...rest}
    >
      <ModalContainer style={{display: 'flex', flexDirection: 'column'}}>
        <RemoveInMobile style={{boxSizing: 'border-box',}}>
          <NavigationWithLogo
            onRequestClose={onRequestClose}
          />
        </RemoveInMobile>
        <ModalFormContainer
          image_url={'https://general-henri-1-paris.s3.fr-par.scw.cloud/woman.jpg'}//TODO roadtrip image
          style={{
            padding: 10,
            height: '100%',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            boxSizing: 'border-box',
            maxWidth: 1400,
            width: '100%',
            alignSelf: 'center',
          }}
        >
          <ExperienceCartTicket
            FirstComponent={
              <FirstComponent
                roadtrip={roadtrip}
                promotionCodeChange={setPromotionCode}
                Header={<NavigationHeader onRequestClose={onRequestClose}/>}
                style={{
                  maxWidth: 600,
                }}
              />
            }
            SecondComponent={
              <SecondComponent
                promotionCode={promotionCode}
                onPaymentSucceeded={onPaymentSucceeded}
                onPaymentCanceled={onRequestClose}
                roadtrip={roadtrip}
              />
            }
            style={{
              maxWidth: 1000,
              margin: '0 auto'
            }}
          />
        </ModalFormContainer>
      </ModalContainer>
    </Modal>
  );
};

export default ExperiencePaymentModal;