import React, { useEffect, useState, Suspense } from "react";
import _ from "underscore";
import ImgLazy from "../../../components/Lazyload/ImgLazy/ImgLazy";
import ContentLoader from 'react-content-loader'
import { Loader } from "semantic-ui-react";
import { PHONE_WIDTH } from '../../../utils/utils';
import 'react-spring-bottom-sheet/dist/style.css'
import './ExperienceToComplete.scss'
import FileInputExperience from 'components/form/file-browser/fileInputExperience';
import { SvgIcon } from "@material-ui/core";
import { ReactComponent as More } from "../../../assets/img/logo/Plus.svg";
import { Button } from "components/form/button";

const ExperienceToComplete = ({ formikProps, createRTForm }) => {
    const [widthState, setWidthState] = useState(window.innerWidth);
    const [openMap] = useState(window.innerWidth > PHONE_WIDTH);
    const [menu, setMenu] = useState('planning')
    // const [xp, setXp] = useState({
    //     picture: '',
    // })
    useEffect(() => {
        for (let i = 1; i < formikProps.values.day_number; i++) {
            if (xpHasTs.length < formikProps.values.day_number) {
                indexXP.push(i)
                setXpHasTs(state => [...state, {
                    ts: [],
                    description_resume: "",
                    img: {},
                }])
            }
        }
    }, [])
    const [xpHasTs, setXpHasTs] = useState([{
        ts: [],
        description_resume: "Je suis le test ",
        img: {},
    }])
    const [indexXP, setIndexXP] = useState([])
    const [currentIndex, setCurrentIndex] = useState(0)
    const [xpIndex, setXpIndex] = useState(xpHasTs[currentIndex])
    useEffect(() => {
        if (indexXP.length > xpHasTs.length) {
            setXpHasTs(state => [...state, {
                ts: [],
                description_resume: "Je suis le test ",
                img: {},
            }])
        }
    }, [indexXP])

    const addDay = () => {
        let lastElement = xpHasTs.length
        if (xpHasTs.length !== xpHasTs.at(-1)) {
            setIndexXP(state => [...state, lastElement])
        }

    }



    return (
        <form >
            <div style={{ width: "102%", position: "relative", left: "-14px", top: "-14px" }}>
                <img src={"https://general-henri-1-paris.s3.fr-par.scw.cloud/woman.jpg"} className='img-header-experience' />
                <div className="day-number-experience">
                    {/* <p>{formikProps.values?.day_number} jours</p> */}
                </div>
            </div>
            <div className="container-experience-data">
                <div className="container-experience-header">
                    <div className="experience-first-data" >
                        {/* icone  */}
                        <i class="map marker alternate icon"></i>
                        <h2>{formikProps.values.title}</h2>
                        {/* flag */}
                    </div>
                    <div>
                        <button>Modifier</button>
                    </div>
                </div>
                <div>
                    <textarea className="text-description-experience" placeholder="La description de votre voyage"></textarea>
                </div>
            </div>
            <div className="menu-experience">
                <a onClick={() => setMenu("planning")}>Planning</a>
                <a onClick={() => setMenu("carte")}>Carte</a>
            </div>
            {
                menu == "planning" &&
                <div className="experience_day">
                    <div className="experience_day_render">
                        {
                            xpHasTs.map((e, index) => (
                                <div style={{ width: '10%' }}>
                                    <p className="select-day-experience" onClick={() => setCurrentIndex(index)}>{index + 1}</p>
                                </div>
                            ))
                        }
                        <button className='add_day_experience'
                            onClick={() => addDay()} ><More></More></button>
                    </div>
                    {
                        <div className="experience_display_activity">
                            <div style={{ height: "50%", width: "50%" }}>
                                <FileInputExperience dropZone srcDefault={xpHasTs[currentIndex]?.img || "https://general-henri-1-paris.s3.fr-par.scw.cloud/woman.jpg"} imgClass={"touristic-site-page--header-image mt-0"} className='experience-image-page' accepts={'image/png,image/jpg,image/jpeg'} maxSize={1000000} name={xpHasTs[currentIndex]?.img} {...formikProps} {...xpHasTs[currentIndex]?.img} />
                            </div>
                            <textarea className="text-description-experience" placeholder="La description de votre journée" value={xpHasTs[currentIndex]?.description_resume}></textarea>
                        </div>
                    }
                </div>
            }
            {
                menu == "carte" &&
                <div>
                    <p>La carte svvvvvvvvvvvvvvp </p>
                </div>

            }

        </form>

    )
}

export default ExperienceToComplete;