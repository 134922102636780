import UserPage from 'pages/UserPage/UserPage';
import React from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import useChat from './Chat/hooks/useChat';




const userLinks = [
    {
        id: 1,
        title: "Mon Compte",
        separator: true,
        href: "/user/account",
        icon: ""
    },
    {
        id: 2,
        title: "Téléchargements",
        separator: true,
        href: "/user/downloads"
    },
    {
        id: 3,
        title: "Conversations",
        notifications: true,
        separator: true,
        href: "/user/conversations"
    },
    {
        id: 4,
        title: "Achats",
        separator: true,
        href: "/user/bought-history"
    },
    {
        id: 5,
        title: "Road trips Favoris",
        separator: true,
        href: "/user/roadtrips/favorite"
    },
    {
        id: 6,
        title: "Road trips",
        separator: true,
        href: "/user/roadtrips"
    },
    {
        id: 7,
        title: "Déconnection",
        href: "/logout"
    },
]

const User = () => {
    const location = useLocation();
    const [stateWS] = useChat();
    const activeItem = React.useMemo(() => userLinks?.find(el => location.pathname.includes(el.href)), [location])
    const {user} = useSelector(state => state.user);
    const unreadMsg = React.useMemo(() => stateWS?.conversations?.find(el=> el?.unread?.includes(user?.id)),[stateWS, user])

    return (
        <UserPage userLinks={userLinks} activeItem={activeItem} unreadMsg={unreadMsg} />
    );
};

export default User;