import React from 'react';
import ReactInputMask from 'react-input-mask';

const InputMaskFormik = (props) => {

    const handleChange = (v) => {
        if (!props.setFieldValue) {
            props.onChange(v.target.value);
            return;
        }
        props.setFieldValue(props.name, v.target.value);
    }

    const handleBlur = () => {
        if (props.setFieldTouched)
            props.setFieldTouched(props.name, true);
    }

    return (
        <div className={"form-group " + props.parentClasses}>
            {
                props.label &&
                <label className="font-size-h6 text-dark">
                    {props.label}
                    {props.required ? " *" : ""}
                </label>
            }
            <ReactInputMask
                mask={props.mask}
                maskChar={props.maskChar}
                className={"form-control rounded-0 h-auto py-1 px-2 " + props.classes}
                placeholder={props.placeholder}
                onChange={handleChange}
                onBlur={handleBlur}
                value={props.name ? props.values[props.name] : props.value}
            />
            {(props.touched[props.name] && props.errors[props.name]) || props.error ? (
                <div className="fv-plugins-message-container">
                <div className="fv-help-block text-danger">
                    {props.errors[props.name] || props.error}
                </div>
                </div>
            ) : null}
        </div>
    );
};

export default InputMaskFormik;