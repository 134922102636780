import styled from 'styled-components';
import {commonStyleMixin} from "./common";
import InputErrorWrapper from "./InputErrorWrapper";
import LabelWrapper from "./LabelWrapper";

const TextAreaStyled = styled.textarea`
  ${commonStyleMixin}
`;

const TextArea = ({hasError, errorMessage, ErrorComponent, name, label, ...rest}) => (
  <LabelWrapper
    name={name}
    label={label}
  >
    <InputErrorWrapper
      errorMessage={errorMessage}
      ErrorComponent={ErrorComponent}
      hasError={hasError}
    >
      <TextAreaStyled
        hasError={hasError}
        name={name}
        id={name}
        { ...rest }
      />
    </InputErrorWrapper>
  </LabelWrapper>
);

export default TextArea;