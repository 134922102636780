import React from "react";
import Title from 'components/header/title/title';
import './PartnerSectionsTop.scss';
import { useTranslation } from "react-i18next";




const PartnerSectionsTop = () => {
  const {t} = useTranslation(["henriTrip"])

  const content = [
    {
      title : t("partner.topSection.firstCard.title"),
      topDescription : t("partner.topSection.firstCard.topDescription"),
      description : t("partner.topSection.firstCard.description"),
      image : "https://general-henri-1-paris.s3.fr-par.scw.cloud/partenaire_page/centraliser.png",
      margin: "mt-1 mt-md-3 mt-lg-2"
    },
    {
      title : t("partner.topSection.secondCard.title"),
      topDescription : t("partner.topSection.secondCard.topDescription"),
      description : t("partner.topSection.secondCard.description"),
      image : "https://general-henri-1-paris.s3.fr-par.scw.cloud/partenaire_page/connecter-logo.png",
      margin: "mt-4 mt-md-4 mt-lg-2"
    },
    {
      title : t("partner.topSection.thirdCard.title"),
      topDescription : t("partner.topSection.thirdCard.topDescription"),
      description : t("partner.topSection.thirdCard.description"),
      image : "https://general-henri-1-paris.s3.fr-par.scw.cloud/partenaire_page/gerer-logo.png",
      margin: "mt-4 mt-md-4 mt-lg-2"
    }
  ]


  return (
          <div className="container">
            <Title title={t("partner.topSection.title")}/>
            <div className="row">
              {
                content?.map((el, i) =>
                  <div className="col-lg-4 mt-2 mt-md-3">
                    <div className="row">
                      <div className="col-3 col-lg-12">
                        <img src={el.image} className="card-image-style" alt="" />
                      </div>
                      <div className="mt-2 mt-md-3 col-9 col-lg-12">
                        <h1>{el.title}</h1>
                        { (el.topDescription) &&
                        <p className="mt-2 mb-md-2 top-text-style">{el.topDescription}</p>
                        }
                        <p className="mt-2 mt-md-1 top-text-style">{el.description}</p>
                      </div>
                    </div>
                  </div>
                )
              }
            </div>
          </div>
  );
};

export default PartnerSectionsTop;