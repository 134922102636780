import { addUserFavoriteRoadTrips } from 'actions/road-trips';
import useRequest from 'hooks/use-request';
import FavoriteRoadTripsPage from 'pages/UserPage/RoadtripsPage/FavoriteRoadTripsPage/FavoriteRoadTripsPage';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { API_GET_FAVORITE } from 'utils/api';
import { groupsId, seasonsId, transportsId } from "../../../utils/utils";

const FavoriteRoadtrips = () => {
  const dispatch = useDispatch();
  const [currentPage, onChangePage] = useState(0);
  const [handleRequest, status] = useRequest()
  const config = useSelector(state => state.data);
  const { userFavoriteRoadtrips } = useSelector(state => state.roadtrips)
  const {user} = useSelector(state => state.user);

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
      handleRequest("get", API_GET_FAVORITE, {}, config, (data, status) => {
        if (status === "Done") {
          console.log("DONE")
          const parsedRTFavorite = data.map(rt => {

            let categoriesTransport = [];
            let categoriesGroup = [];
            let categoriesSeasons = [];

            rt.road_trip.categoriesTransport= categoriesTransport
            rt.road_trip.categoriesGroup= categoriesGroup
            rt.road_trip.categoriesSeasons = categoriesSeasons

            rt.road_trip.categories.forEach(el =>
            {
              if(transportsId.includes(el.id)){
                categoriesTransport.push(el)
              }
            })

            rt.road_trip.categories.forEach(el =>
            {
              if(groupsId.includes(el.id)){
                categoriesGroup.push(el)
              }
            })

            rt.road_trip.categories.forEach(el =>
            {
              if(seasonsId.includes(el.id)){
                categoriesSeasons.push(el)
              }
            })

            rt.road_trip.feedbacks = {
              note: rt.road_trip.avg_notes || 0,
              len: rt.road_trip.notes_counter,
            }

            rt.road_trip.user = {
              firstname: user?.firstname,
              lastname: user?.lastname,
            }
            console.log(rt.road_trip, "parsedRTFavorite")
            return rt.road_trip
            });

          dispatch(addUserFavoriteRoadTrips(parsedRTFavorite))
        } else if (status === "Error") {
          console.error(data);
        }
      })
    // eslint-disable-next-line
  }, [])

  return (
    <FavoriteRoadTripsPage
      loading={status !== "Done"}
      roadtrips={userFavoriteRoadtrips}
      currentPage={currentPage}
      onChangeStep={onChangePage}
    />
  );
};

export default FavoriteRoadtrips;