import React from 'react';
import FacebookIcon from "@mui/icons-material/Facebook";
import { HeaderButton } from "../header/buttons";
import FacebookLogin from '@greatsumini/react-facebook-login';
import "./FacebookLoginButton.scss"

const FacebookLoginButton = ({ title, onLoginSuccess, className, onError }) => {

  return (
    <FacebookLogin
      appId={process.env.REACT_APP_FACEBOOK_LOGIN_APP_ID}
      initParams={{
        version: 'v12.0'
      }}
      fields={"first_name, last_name, email, picture"}
      onFail={(error) => {
        console.log('Login Failed!', error);
        onError(error)
      }}
      onSuccess={(response) => {
        onLoginSuccess(response)
      }}
      dialogParams={{ response_type: 'code' }}
      render={({ onClick }) =>
        <HeaderButton
          className={"button-register fb-button " + className}
          onClick={onClick}
          text={title}
          materialIcon={FacebookIcon}
        />
      }
    />

  )
}

export default FacebookLoginButton;
