import BoughtHistoryPage from 'pages/UserPage/BoughtHistoryPage/BoughtHistoryPage';
import React from 'react';

const BoughtHistory = () => {

    React.useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <BoughtHistoryPage />
    );
};

export default BoughtHistory;