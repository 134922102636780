import React, {useLayoutEffect, useRef, useState} from "react";
import styled from "styled-components";
import Modal from "../Modal";

const DefaultCloseIcon = styled.a`
  display: inline-block;
  width: 32px;
  height: 32px;
  opacity: 0.3;
  
  :hover {
    opacity: 1;
  }
  
  :before, :after {
    position: absolute;
    content: ' ';
    height: 30px;
    width: 4px;
    background-color: ${props => props.theme.colors.darkGrey};
  }
  
  :before {
    transform: rotate(45deg);
  }
  
  :after {
    transform: rotate(-45deg);
  }
`;

const PopupModal = ({children, withCloseIcon, CloseIcon, isOpen, onRequestClose, ...rest}) => {
  const [headerHeight, setHeaderHeight] = useState(0);
  const headerRef = useRef();

  useLayoutEffect(() => {
    setHeaderHeight(headerRef.current?.offsetHeight || 0);
  }, []);

  const Icon = CloseIcon || DefaultCloseIcon;

  return (
    <Modal
      popup
      withPadding
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      {...rest}
    >
      <div
        ref={headerRef}
        style={{
          display: 'flex',
          flexDirection: 'row-reverse',
          position: 'relative',
          marginTop: 10,
          marginRight: 10,
        }}
      >
        {withCloseIcon && <Icon onClick={onRequestClose}/>}
      </div>
      <div
        style={{
          height: `calc(100% - ${headerHeight}px)`,
          maxHeight: `calc(100% - ${headerHeight}px)`,
        }}
      >
        { children }
      </div>
    </Modal>
  );
};

export default PopupModal;