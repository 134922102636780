import { Form, Formik } from "formik";
import * as Yup from "yup";
import React from "react";
import { useSelector } from "react-redux";
import SVG from "react-inlinesvg";
import ConversationContent from "./ConversationContent";
import { ImagePreviewCreate } from "components/form/preview/image-preview-create";
import InputFormik from "components/form/input/inputFormik";
import FileInput from "components/form/file-browser/fileInput";
import useChat from "../hooks/useChat";
import { Button } from "components/form/button";
import { useHistory } from "react-router-dom";

const MAX_MESSAGE_SIZE = 5000;

const Chat = ({ match }) => {
  const [stateWS, sendMessage, sendImage, sendRead] = useChat();
  const {user} = useSelector(state => state.user);
  const history = useHistory();

  // eslint-disable-next-line
  const conversation = React.useMemo(
    () => stateWS?.conversations?.find(el => el.id === match.params?.id),
    [stateWS, match.params]
  );
  const users = React.useMemo(
    () => {
      const conv = stateWS?.conversations?.find(el => el.id === match.params?.id)

      return ({
          receiver: conv?.user2,
          sender: conv?.user1
        })
      },
    // eslint-disable-next-line
    [stateWS?.conversations, match.params?.id]
  );

  const handleSubmit = (v, d) => {
    if (v.input === "" && !v.file.length) {
      return;
    }

    if (v.file.length) {
      sendImage(v.input, v.file, match.params?.id);
    } else {
      sendMessage("message", v.input, match.params?.id);
    }
    d.resetForm({
      input: "",
      file: ""
    });
  };

  const onRead = () => {
    sendRead(match.params?.id);
  };

  return (
    <div style={{minHeight: "70vh"}}>
      {!conversation ? (
        <div className="bg-danger p-2">
          <p className="text-white m-0">
            Nous n'avons pas trouvé cette conversation...
          </p>
        </div>
      ) : stateWS.connected ? (
        <>
          <div className="bg-white shadow-lg p-3 rounded mb-1">
            <h1 className="m-0">
              <span style={{cursor: "pointer"}}><SVG onClick={() => history.push("/user/conversations")} className="text-primary mr-1 d-lg-none" src="/assets/icons/Fichier 31.svg" width="15" style={{transform: "rotate(180deg)"}} /></span> {users.receiver?.firstname} {users.receiver?.lastname}
            </h1>
          </div>
          <>
            <ConversationContent
              stateWS={stateWS}
              conversation={conversation}
              users={users}
              id={user.id}
              onRead={onRead}
            />
            <Formik
              initialValues={{
                input: "",
                file: []
              }}
              validationSchema={Yup.object().shape({
                input: Yup.string().max(MAX_MESSAGE_SIZE, max => `Vous avez atteint la limite de taille du message (${max.value.length}/${max.max} charactères)`).nullable(),
                file: Yup.array().nullable()
              })}
              validateOnChange={true}
              onSubmit={handleSubmit}
            >
              {props => {
                return (
                  <div className="bg-white shadow-lg">
                    {props.values?.file.length !== 0 && (
                      <div className="pl-4 pt-3 d-inline-block">
                        <ImagePreviewCreate
                          size="md"
                          src={URL.createObjectURL(props.values.file[0])}
                          alt=""
                          onClick={() => {
                            props.setFieldValue("file", "");
                            props.setFieldTouched("file", true);
                          }}
                        >
                          <SVG
                            src={"/assets/icons/close.svg"}
                            className="align-self-center text-primary"
                            width="25"
                            style={{padding: ".2rem"}}
                          ></SVG>
                        </ImagePreviewCreate>
                      </div>
                    )}
                    <Form className="px-3">
                      <div className="d-flex justify-content-between align-items-center py-3">
                        <InputFormik
                          classes="w-100"
                          parentClasses="w-100 m-0"
                          placeholder="Votre message..."
                          {...props}
                          name="input"
                        />
                        <FileInput
                          cancelPreview
                          parentClasses="m-0 px-2"
                          normal
                          multiple={false}
                          new_images_name="file"
                          {...props}
                          name="file"
                        >
                          <SVG
                            src={"/assets/icons/image.svg"}
                            width="40"
                            className="ml-2"
                            style={{cursor: "pointer"}}
                          />
                        </FileInput>
                        <Button color="henri" outline className="mx-0 pl-2 pr-0" type="submit">
                            <SVG
                              src={"/assets/icons/send-button.svg"}
                              width="35"
                              className="text-primary"
                            />
                        </Button>
                      </div>
                    </Form>
                  </div>
                );
              }}
            </Formik>
          </>
        </>
      ) : (
        <div>Une erreur de connection avec la messagerie est survenue.<Button onClick={() => window.location.reload()} title="Recharcher la page" /></div>
      )}
    </div>
  );
};

export default Chat;
