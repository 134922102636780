import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import {ReactComponent as HenriIcon} from '../../../../assets/img/logo/Asset1.svg';
import {breakpoint} from "styled-components-breakpoint";
import FacebookLoginButton from "../FacebookLoginButton";
import TextInput from "../FormElements/TextInput";
import {Checkbox} from "@material-ui/core";
import {SubmitButton} from "../FormElements";
import * as Yup from "yup";
import {REQUIRED_MESSAGE} from "../../../../utils/texts";
import useRequest from "../../../../hooks/use-request";
import {Formik} from "formik";
import {API_FACEBOOK_LOGIN, API_LOGIN} from "../../../../utils/api";
import {addToken} from "../../../../actions/token";
import {addUser} from "../../../../actions/user";
import {useDispatch} from "react-redux";
import PopupModal from "../PopupModal";
import SignUp from "../SignUp";
import Loader from "../Loader";

const LoginFormStyled = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  .form-error-message {
    display: inline-block;
    color: ${props => props.theme.colors.error};
  }
  button {
    width: 100%;
    margin-top: 1rem;
  }
  hr {
    width: 100%;
    margin-top: 1rem;
    margin-bottom: 1rem;
    border: 0;
    border-top: 3px solid rgba(0, 0, 0, 0.1);
  }
`;

const LoginHeaderStyled = styled.div`
  display: flex;
  flex-wrap: nowrap;
  color: ${props => props.theme.colors.primary};
  width: 95%;
  padding: 1rem 0;
  svg{
    width: 100px;
    margin-right: 1rem;
  }
  path{
    fill: ${props => props.theme.colors.primary};
  }
`;

const InputWrapperStyled = styled.div`
  width: 100%;
  margin-top: 1rem;
  input {
    width: 100%;
  }
`;

const LoginOptionsStyled = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 1rem 0;
  p, span, input, label {
    margin: 0;
    padding: 0;
  }
  label{
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`;

const SubmitButtonWrapperStyled = styled.div`
  width: 100%;
  margin-bottom: 1rem;
  input {
    padding: 1rem;
    background-color: ${props => props.theme.colors.black};
    color: ${props => props.theme.colors.white};
    width: 100%;
  }
`;
const Login = ({onLoginSucceeded = () => {}}) => {
  const dispatch = useDispatch();
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [handleRequest] = useRequest();
  // Facebook Login
  const [fbLogin, setFbLogin] = useState(null);
  useEffect(() => {
    if(fbLogin){
      handleFacebookSubmit(fbLogin);
    }
  }, [fbLogin]);

  const login = {
    name: 'login',
    type: 'text',
    placeholder: 'Email',
    required: true,
  };
  const password = {
    name: 'password',
    type: 'password',
    placeholder: 'Mot de passe',
    required: true,
  };
  const handleSubmit = (v, formikBagHelper) => {
    setLoading(true);
    handleRequest("post", API_LOGIN, v, {}, (data, status) => {
      if (status === "Done") {
        if (data.errors) {
          setError(data.errors);
          setLoading(false);
          return;
        }
        else if (!data.user) {
          setError("Vous n'êtes pas inscrit sur la plateforme client")
          return;
        }
        dispatch(addToken(data.token));
        const userData = {
          ...data.user,
          ...data.profile,
        };
        dispatch(addUser(userData));
        onLoginSucceeded(data);

      } else {
        console.error(data);
        setError("Vos identifiants sont invalides");
      }
    })
  };

  const handleFacebookSubmit = (v) => {
    const auth = {
      provider: "facebook",
      uid: v.userID,
      data_access_expiration_time: v.data_access_expiration_time,
      code: v.signedRequest,
      token: v.accessToken,
      expires_at: v.expiresIn,
      expires: true
    }
    setLoading(true);
    handleRequest('post', API_FACEBOOK_LOGIN, auth, {}, (data, status) => {
      if (status === "Done") {
        if (data.errors) {
          setError(data.errors);
          setLoading(false);
          return;
        }
        dispatch(addToken(data.token));
        const userData = {
          ...data.user,
          ...data.profile,
        };
        dispatch(addUser(userData));
        onLoginSucceeded(data);
        // setLoading(false);
      }
    })
  };
  return (
    <Formik
      initialValues={{login: '', password: ''}}
      validationSchema={Yup.object().shape(
        {
          [login.name]: Yup.string().required(REQUIRED_MESSAGE),
          [password.name]: Yup.string().matches(
            /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,70}$/,
            'Le mot de passe doit contenir au moins 8 caractères, Incluant des lettres en majuscule, minuscule et au moins un chiffre.'
          ).required(REQUIRED_MESSAGE)
        }
      )}
      onSubmit={handleSubmit}
      validateOnMount={false}
      validateOnChange={false}
      validateOnBlur={false}
    >
      {({values, handleSubmit, ...props}) => {
        return (
          <LoginFormStyled onSubmit={handleSubmit}>
            <LoginHeaderStyled>
              <HenriIcon/>
              <h2>Vous devez être connecté à votre compte Henri Trip pour continuer</h2>
            </LoginHeaderStyled>
            <FacebookLoginButton
              onLoginSuccess={setFbLogin}
              onError={() => {}}
            />
            <hr/>
            <span className="form-error-message small">{ error }</span>
            <InputWrapperStyled>
              <TextInput
                {...login}
                {...props}
                value={values[login.name]}
                hasError={props.errors[login.name]}
                errorMessage={props.errors[login.name]}
                onChange={props.handleChange}
              />
            </InputWrapperStyled>
            <InputWrapperStyled>
              <TextInput
                {...password}
                {...props}
                value={values[password.name]}
                hasError={props.errors[password.name]}
                errorMessage={props.errors[password.name]}
                onChange={props.handleChange}
              />
            </InputWrapperStyled>
            <LoginOptionsStyled>
              <label>
                <Checkbox
                  type="checkbox"
                  color="primary"
                />
                Remember me
              </label>
              <small>Informations de compte oubliées ?</small>
            </LoginOptionsStyled>
            <SubmitButtonWrapperStyled
              disabled={loading}
            >
              {
                loading
                  ? <Loader/>
                  : <SubmitButton value="Se connecter ➔"/>
              }
            </SubmitButtonWrapperStyled>
          </LoginFormStyled>
        )}}
    </Formik>
  )
};

const LoginContainer = styled.div`
  padding: 0 20px 20px 20px;
  max-width: 400px;
`;

const ClickableSpan = styled.span`
  font-weight: 600;
  text-decoration: underline;
  cursor: pointer;
`;

const LoginModal = ({onLoginSucceeded, onRequestClose, type = 'login', ...rest}) => {
  const [hasAccount, setHasAccount] = useState(type === 'login');

  return (
    <PopupModal
      overlay
      withCloseIcon
      onRequestClose={onRequestClose}
      {...rest}
    >
      <LoginContainer>
        {
          hasAccount
            ? (
              <>
                <Login onLoginSucceeded={onLoginSucceeded}/>
                <small>Vous n'avez pas encore de compte&nbsp;? <ClickableSpan onClick={() => setHasAccount(false)}>Inscrivez-vous</ClickableSpan></small>
              </>
            )
            : (
              <>
                <SignUp onLoginSucceeded={onLoginSucceeded}/>
                <small>Vous avez un compte&nbsp;? <ClickableSpan onClick={() => setHasAccount(true)}>Connectez-vous</ClickableSpan></small>
              </>
            )
        }
      </LoginContainer>
    </PopupModal>
  );
};

export default Login;

export {
  LoginModal,
};