import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

const roadtrips = {
    roadtrips: [],
    userRoadtrips: [],
    userFavoriteRoadtrips: []
};

export default persistReducer({storage, key: "roadtrips-data", whitelist: ["roadtrips", "userRoadtrips", "userFavoriteRoadtrips"]}, (state = roadtrips, action) => {
    switch (action.type) {
        case 'ADD_ROADTRIPS':
            return {
                ...state,
                roadtrips: [...action.payload]
            };
        case 'PUSH_ROADTRIP':
            return {
                ...state,
                roadtrips: [
                    ...state.roadtrips,
                    action.payload
                ]
            }
        case 'EDIT_ROADTRIP':
            return {
                ...state,
                roadtrips: state.roadtrips.map(el => {
                    if (el.id === action.payload.id) {
                        return {
                            ...el,
                            ...action.payload
                        }
                    }

                    return el;
                })
            };
        case 'DELETE_ROADTRIP':
            return {
                ...state,
                roadtrips: state.roadtrips.filter(el => el.id !== action.payload)
            }
        case 'REMOVE_ROADTRIPS':
            return {
                ...state,
                roadtrips: []
            }

        case 'ADD_USER_ROADTRIPS':
            return {
                ...state,
                userRoadtrips: action.payload
            };
        case 'PUSH_USER_ROADTRIP':
            return {
                ...state,
                userRoadtrips: [
                    ...state.userRoadtrips,
                    action.payload
                ]
            }
        case 'EDIT_USER_ROADTRIP':
            return {
                ...state,
                userRoadtrips: state.userRoadtrips.map(el => {
                    if (el.id === action.payload.id) {
                        return {
                            ...el,
                            ...action.payload
                        }
                    }

                    return el;
                })
            };
        case 'DELETE_USER_ROADTRIP':
            return {
                ...state,
                userRoadtrips: state.userRoadtrips.filter(el => el.id !== action.payload)
            }
        case 'REMOVE_USER_ROADTRIPS':
            return {
                ...state,
                userRoadtrips: []
            }
        case 'ADD_FAVORITE_ROADTRIPS':
            return {
                ...state,
                userFavoriteRoadtrips: action.payload
            }
        case 'ADD_FAVORITE_ROADTRIP':
            return {
                ...state,
                userFavoriteRoadtrips: [...state.userFavoriteRoadtrips,
                    action.payload]
            }

        case 'DELETE_FAVORITE_ROADTRIPS':
            return {
                ...state,
                userFavoriteRoadtrips: state.userFavoriteRoadtrips.filter(el => el.id !== action.payload)
            }

        default:
            return state;
    }
})