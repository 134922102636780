import React, { useEffect, useState } from "react";
import {Modal} from 'semantic-ui-react'
import useDigitInput from "../../hooks/use-digit-input";
import  './activity.scss'
import useRequest from "../../hooks/use-request";
import {API_CHECK_ACTIVITY_PASSWORD} from "../../utils/api";

const ActivityPasswordModal = ({password, uuid, handleClose}) => {
  const [value, onChange] = useState(password || '');
  const [error, setError] = useState(null);
  const [handleRequest, status] = useRequest();
  const digits = useDigitInput({
    acceptedCharacters: /^[0-9]$/,
    length: 4,
    value,
    onChange,
  });

  useEffect(() => {
    if (value.replace(/\s/g, "").length === 4){
      const data = {
        password: value
      }
      handleRequest("post", API_CHECK_ACTIVITY_PASSWORD(uuid), data, {}, (data, status) => {
        if (status === "Done") {
          setError(null);
          const items = JSON.parse(localStorage.getItem("allow_activities"))
          if (items) {
            items.push(uuid)
            localStorage.setItem("allow_activities", JSON.stringify(items))
          }
          else {
            localStorage.setItem("allow_activities", JSON.stringify([uuid]))
          }
          handleClose();
        }
        else {
          setError("Code invalid");
          onChange('');
        }
      })
    }
  }, [value])

  return(
    <>
      <Modal.Content>
        <div>
          {
            error &&
            <div className="p-3 bg-danger text-white mb-1">
              {error}
            </div>
          }
          <div className="input-password-group d-flex">
            <div className="col d-flex justify-content-center align-items-center">
              <input inputMode="decimal" autoFocus disabled={status === 'Loading'} {...digits[0]} />
              <input inputMode="decimal" disabled={status === 'Loading'} {...digits[1]} />
              <input inputMode="decimal" disabled={status === 'Loading'} {...digits[2]} />
              <input inputMode="decimal" disabled={status === 'Loading'} {...digits[3]} />
              {
                status === 'Loading' &&
                <div className="spinner-border" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              }
            </div>
          </div>
        </div>
      </Modal.Content>
    </>
  )
}

export default ActivityPasswordModal
