import { updateUser } from 'actions/user';
import useRequest from 'hooks/use-request';
import InformationPage from 'pages/UserPage/InformationPage/InformationPage';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {  API_MODIFY_PROFILE } from "utils/api";

const userForm = {
    firstname: {
        name: "firstname",
        label: "Prénom",
        placeholder: "Prénom",
        required: true
    },
    lastname: {
        name: "lastname",
        label: "Nom",
        placeholder: "Nom",
        required: true
    },
    avatar: {
        name: "avatar",
    },
    address: {
        name: "address",
        label: "Adresse",
        placeholder: "Adresse",
    },
    city: {
        name: "town",
        label: "Ville",
        placeholder: "Ville"
    },
    postalCode: {
        name: "postal_code",
        label: "Code postal",
        placeholder: "00000",
        mask: "99999",
    },
    gender: {
        name: "gender",
        label: "Civilité",
        placeholder: "Civilité",
        noOptionsMessage: () => "Aucune civilité trouvée",
        required: true
    },
    birthdate: {
        name: "age",
        label: "Année de naissance",
        placeholder: "JJ/MM/AAAA",
        mask: "99/99/9999",
        required: true
    }
}


const Information = () => {
    const token = localStorage.getItem("Authorization");
    const headers = {'Authorization': `Token ${token}`}
    const {user} = useSelector(state => state.user);
    const [handleRequest] = useRequest();
    const dispatch = useDispatch();

    React.useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const handleSubmit = (v) => {
        handleRequest("put", API_MODIFY_PROFILE,v , {headers} , (data, status) => {
            if (status === "Done") {
                dispatch(updateUser(data))
            } else {
                console.error(data);
            }
        })
    }

    return (
        <InformationPage user={user} userForm={userForm} handleSubmit={handleSubmit} />
    );
};

export default Information;