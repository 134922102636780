import {useContext, useEffect} from 'react';
import styled, {ThemeProvider} from 'styled-components';
import GlobalStyle from '../../_ui/_v2/styles/global_style';
import NavBarContext from '../../_contexts/NavBarProvider';
import PageContainer from '../../_ui/_v2/components/PageContainer';
import theme from '../../_ui/_v2/styles/theme';
import {Helmet} from 'react-helmet';
import TouristicSiteForm from '../../_forms/_components/avpm/TouristicSiteForm';
import {TouristicSiteProvider} from '../../_contexts/TouristicSiteContext';

const FormContainer = styled.div`
  max-width: 500px;
  margin: 0 auto;
  padding: 2rem 1rem 1rem 1rem;
`;

const TouristicSitesNewPage = () => {
  const navBarContext = useContext(NavBarContext);

  useEffect(() => {
    navBarContext.setNavBar('v2');
    navBarContext.setFooterVisible(false);
    return () => {
      navBarContext.restoreNavBar();
      navBarContext.restoreFooterVisible();
    };
  }, []);

  return (
    <PageContainer>
      <Helmet>
        <title>Création d'une activité</title>
      </Helmet>
      <FormContainer>
        <TouristicSiteProvider initialTouristicSite={{}}>
          <TouristicSiteForm/>
        </TouristicSiteProvider>
      </FormContainer>
    </PageContainer>
  );
};


const TouristicSitesNewPageWithTheme = () => (
  <ThemeProvider theme={theme}>
    <GlobalStyle />
    <TouristicSitesNewPage/>
  </ThemeProvider>
);

export default TouristicSitesNewPageWithTheme;