import React, {useEffect, useState} from 'react';

const TextVS = ({data, activeContent}) => {
  const [object, setObject] = useState(
    data ? activeContent[data.table_name].find((x) => x.id === data.id) : null
  );

  useEffect(() => {
    setObject(data ? activeContent[data.table_name].find((x) => x.id === data.id) : null);
  }, [activeContent, data]);

  return (
    <p className="my-3" style={{whiteSpace: 'pre-wrap'}}>{object?.paragraph}</p>
  );
};

export default TextVS;
