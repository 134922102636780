import React from "react";
import { Card } from "react-bootstrap";
import moment from "moment";
import SVG from "react-inlinesvg";
import fileDownload from "js-file-download";
// eslint-disable-next-line
const Message = props => {
  const onDownload = async () => {
    const blob = await fetch(
      "https://henri-messaging.slapp.me/uploads/" + props.payload.hash_name,
      {
        method: "GET",
        cache: "default",
        headers: {},
        mode: "cors"
      }
    ).then(r => r.blob());

    fileDownload(blob, props.payload.image_name);
  };

  return (
    <div
      className={`d-flex py-2 justify-content-${
        props.isSender ? "end" : "start"
      }`}
    >
      <Card
        className={`d-inline-block bg-${
          props.isSender ? "primary" : "secondary"
        }`}
        style={{ maxWidth: 75 + "%" }}
      >
        {props.payload.hash_name && (
          <div className="position-relative chat-image">
            <img
              className="card-img-top"
              src={"https://henri-messaging.slapp.me/uploads/" + props.payload.hash_name}
              alt={props.image_name}
            />
            <div
              className="download-image btn btn-primary position-absolute"
              style={{ top: 10, left: 10, cursor: "pointer" }}
              onClick={onDownload}
            >
              <span className="svg-icon m-0 svg-icon-md svg-icon-primary">
                <SVG
                  src={"/assets/icons/Fichier 25.svg"}
                  width="30"
                />
              </span>
            </div>
          </div>
        )}
        <Card.Body className={`p-2 text-${props.isSender ? "right" : "left"}`}>
          {!props.isSender && (
            <p
              className={`m-0 text-${
                props.isSender ? "white" : "dark"
              } font-weight-bolder`}
            >
              {props.users?.firstname} {props.user?.lastname}
            </p>
          )}
          <p className={`m-0 text-${props.isSender ? "white" : "dark"} paragraph`}>
            {props.payload.content}
          </p>
          <small
            className={`m-0 text-${props.isSender ? "muted" : "muted"} ml-auto`}
          >
            {moment(new Date(props.created_date))
              .locale("fr-FR")
              .format("DD MMM YYYY - HH:mm")}
          </small>
        </Card.Body>
      </Card>
    </div>
  );
};
// eslint-disable-next-line
const ConversationMessages = ({ conversation, users, id, onRead }) => {
  /*const toBottom = useScrollToBottom();
  const [isBottom] = useAtBottom();*/

  React.useEffect(() => {
    /*if (isBottom) {
      onRead();
    }*/
    // eslint-disable-next-line
  }, [isBottom]);

  return (
    <>
      {/*{conversation?.messages?.map(el => (
        <Message
          key={el.id}
          {...el}
          users={el.sender_type === "user" ? users?.sender : users?.receiver}
          isSender={el.sender_type === "user"}
        />
      ))}
      {!isBottom && (
        <div className="position-absolute" style={{ right: 20, bottom: 20 }}>
          <div className="position-relative d-inline-block">
            <div
              style={{cursor: "pointer"}}
              className="btn-primary border-0 shadow px-3 py-1"
              onClick={() => toBottom({ behavior: "smooth" })}
            >
              <span className="svg-icon m-0 svg-icon-md svg-icon-primary">
                <SVG
                  src={"/assets/icons/Fichier 31.svg"}
                  style={{transform: "rotate(90deg)"}}
                  width="15"
                />
              </span>
              {conversation.unread?.includes(id) && (
                <div
                  className="position-absolute"
                  style={{ top: -5, left: -5 }}
                >
                  <div
                    className="bg-dark rounded-circle"
                    style={{ height: 15, width: 15 }}
                  ></div>
                </div>
              )}
            </div>
          </div>
        </div>
      )}*/}
    </>
  );
};

const ConversationContent = ({ stateWS, conversation, users, id, onRead }) => {
  const scrollHeightRef = React.useRef(false);

  React.useEffect(() => {
    document.addEventListener('visibilitychange', () => {
      scrollHeightRef.current = !scrollHeightRef.current
    })
  }, [])

  return (
    <>
      {/*{stateWS?.conversations && conversation && (
        <ScrollToBottom
          followButtonClassName="d-none"
          className="scroll-conversation pb-2 px-2"
          mode="bottom"
          scroller={({ maxValue, minValue, offsetHeight, scrollHeight, scrollTop }) => scrollHeightRef.current ? scrollHeightRef.current : maxValue}
        >
          <ConversationMessages
            conversation={conversation}
            users={users}
            id={id}
            onRead={onRead}
          />
        </ScrollToBottom>
      )}
      {stateWS?.conversations &&
        conversation &&
        conversation.messages?.length === 0 && (
          <div
            className="w-100 d-flex justify-content-center align-items-center"
          >
            <p className="m-0">
              Vous n'avez pas encore échangé de messages avec cette personne.
            </p>
          </div>
        )}*/}
    </>
  );
};

export default ConversationContent;
