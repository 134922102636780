import * as React from "react"

function Icon7(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 88.56 106.9"
      style={{color: "#E3CB9B"}}
      {...props}
    >
      <defs>
        <style>{".prefix__cls-2{fill:#fff}"}</style>
      </defs>
      <g id="prefix__Calque_2" data-name="Calque 2">
        <g id="prefix__Calque_1-2" data-name="Calque 1">
          <path
            fill="currentColor"
            d="M44.28 0a44.27 44.27 0 00-31.16 75.73l31.16 31.17 31.16-31.17A44.27 44.27 0 0044.28 0"
          />
          <path
            className="prefix__cls-2"
            d="M15.43 58.63l11.4 12.35 9.36-9.11-10.67-10.84-10.09 7.6zM36.7 25.78L34 47.52a1.82 1.82 0 01-.68 1.21l-3.5 2.84 6.13 6.23L40 53.73a9.59 9.59 0 002.84-6.82V17.58a10.34 10.34 0 00-6.14 8.2zM63.04 51.03L52.37 61.87l9.36 9.11 11.4-12.35-10.09-7.6zM58.76 51.57l-3.5-2.84a1.82 1.82 0 01-.68-1.21l-2.72-21.74a10.34 10.34 0 00-6.17-8.2v29.33a9.59 9.59 0 002.84 6.82l4.1 4.07z"
          />
        </g>
      </g>
    </svg>
  )
}

export default Icon7
