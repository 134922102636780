import SignUpForm from './SignUpForm';

const {
  username,
  firstname,
  lastname,
  email,
  password,
  password_confirmation
} = SignUpForm;

export default {
  [username.name]: '',
  [firstname.name]: '',
  [lastname.name]: '',
  [email.name]: '',
  [password.name]: '',
  [password_confirmation.name]: '',
  statement: true
};