import React, {useContext, useState} from "react";
import styled, {ThemeContext} from "styled-components";
import {API_DIRECT_UPLOAD_TEXT, API_ORIGINAL_BLOB_URL, API_UPDATE_TEXTS} from "../../../../utils/api";
import {
  imageSchema,
  titleSchema,
  videoSchema,
  videoTitleImageSchema
} from "../../../_schemas/media_layout_builder_schemas";
import {useFormik} from "formik";
import FileInput, {FileInputLabel} from "../../../../_ui/_v2/components/FormElements/FileInput";
import Loader from "../../../../_ui/_v2/components/Loader";
import ButtonFormValidation from "../ButtonFormValidation";
import { ReactComponent as VideoIcon } from '../../../../assets/img/logo/Activity/icon_video.svg';
import { ReactComponent as FilesIcon } from '../../../../assets/img/logo/AVPMBuilder/video/fileinput_icon.svg';
import {useTranslation} from "react-i18next";

const FormVideoStyled = styled.form `
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 25px;
`;

const FirstPartOfVideoStyled = styled.div `
  border: 2px dashed ${props => props.theme.colors.black};
  width: 75%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 25px;
`;

const VideoFormContainer = styled.div `
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  margin-top: 1em;
  margin-bottom: 1em;
`;

const InputStyled = styled.input`
  background-color: rgba(0, 0, 0, .8);
  border: 1px solid rgba(169, 169, 169, 0.6);
  color: white;  
  padding: .7em 1em .5em .5em;
  resize: none;
  border-radius: .5em;
  width: 100%;
  ::placeholder{
    color: white;
  }
`;

const ContainerVideo = styled.div `
  width: 80%;
  
  label{
    width: 80%
  }
`;

const VideoToDisplayContainer = styled.div `
  padding: 3rem 0 3rem 0;
  position: relative;
`;

const CardVideoPreview = styled.div `
  width: 120px;
  height: 120px;
  margin: auto;
  display: flex;
  background-image: url(${props => props.url});
`;

const CardVideo = styled.div `
  width: 80px;
  margin: auto;
  height: 80px;
  display: flex;
  background-image: url(${props => props.url});
`;

const ButtonDelete = styled.button`
  display: inline-block;
  box-sizing: border-box;
  padding: 0.5rem 2rem;
  font-weight: 600;
  border: none;
  background-color: ${props => props.theme.colors.danger};
  color: ${props => props.theme.colors.white};
  border-radius: 0.2rem;
  vertical-align: middle;
`;

const VideoUploadContainer = styled.div`
  background-image: ${props => props.backgroundImageUrl ? `url('${props.backgroundImageUrl}')` : 'none'};
  background: ${props => props.backgroundImageUrl ? '' : 'linear-gradient(203.51deg, rgba(255, 255, 255, 0.3) 1.43%, rgba(255, 255, 255, 0.045) 103.65%)'};
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 10px;
  ${props => !props.backgroundImageUrl && `
     border: 0.669205px solid #FFFFFF;
  `}
`;

const VideoTitleImageForm = ({id, onLayoutItemChange, videoTitleImage, activeStorageServiceName, onDelete}) => {
  const [previewUrlVideo, setPreviewUrlVideo] = useState(null);
  const theme = useContext(ThemeContext);
  const [previewUrlImage, setPreviewUrlImage] = useState(null);
  const [loading, setLoading] = useState(false);
  const {i18n} = useTranslation();
  const textKey = `text_${i18n.language}`;

  const handleSubmitForm = (values) => {
    setLoading(true);
    const token = localStorage.getItem("Authorization");
    const arrayToPush = [];
    const title = values.items.filter(item => item.type === 'Title')[0].content;
    arrayToPush.push(title);
    fetch(
      title?.id ? API_UPDATE_TEXTS : API_DIRECT_UPLOAD_TEXT,
      {
        method: title?.id ? 'PUT' : 'POST',
        cache: 'default',
        body: JSON.stringify({texts: arrayToPush}),
        headers: {
          "Authorization": `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        mode: 'cors',
      }
    )
      .then(res => res.json())
      .then(response => {
        const builderSchema = {
          type: 'Title',
          content: {id: response[0].id}
        }
        values.items.splice(0, 1, builderSchema)
        const medias = values.items?.filter(item => !item.content?.blob_id).map(item => item.content);
        onLayoutItemChange(
          values,
          medias,
          {
            onSuccess: () => setLoading(false),
            onError: () => setLoading(false)
          }
        );
        resetForm({
          values: {},
          fileUrl: '',
          previewUrlImage: ''
        })
        setPreviewUrlVideo('')
        setPreviewUrlImage('')
        setFieldValue(
          `items[0]`,
          titleSchema.cast({content: {[textKey]: ""}})
        );
      })
      .catch(error => {
        setLoading(false);
        console.error(error);
      });
  };

  const {
    handleSubmit,
    setFieldValue,
    values,
    resetForm,
  } = useFormik({
    initialValues: videoTitleImageSchema.cast(videoTitleImage),
    enableReinitialize: true,
    validationSchema: videoTitleImageSchema,
    onSubmit: handleSubmitForm,
  });

  return(
    <FormVideoStyled onSubmit={handleSubmit}>
      <FirstPartOfVideoStyled>
        <VideoFormContainer>
          <div style={{width: '65%'}}>
            <InputStyled
              id={textKey}
              name={`items[0].content.${textKey}`}
              value={values?.items?.[0]?.content?.[textKey] || ''}
              placeholder='Titre'
              key={values}
              onChange={(event) => {
                setFieldValue(
                  `items[0]`,
                  titleSchema.cast({content: {[textKey]: event.target.value}})
                );
              }}
              type="text"
            />
          </div>
          <ContainerVideo>
            <FileInput
              id={id}
              name={`items[1].content.signed_id`}
              accept="video/*"
              activeStorageServiceName={activeStorageServiceName}
              value={values?.items?.[1]?.content?.signed_id}
              onBlobUrlChange={setPreviewUrlVideo}
              onChange={(event) => {
                if (event.target.value !== '') {
                  const filteredFiles = event.target.value
                  setFieldValue(
                    `items[1]`,
                    videoSchema.cast({content: {signed_id: filteredFiles}})
                  );
                }
              }
              }
            >
              {
                ({ready, globalProgress}) => (
                  <FileInputLabel
                    disabled={!ready}
                    progress={globalProgress}
                    style={{border: "none", backgroundColor: 'black', display: 'block'}}
                  >
                    {ready
                      ? (
                        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-evenly'}}>
                          <div>
                            <VideoIcon/>
                          </div>
                          <div>
                            {values?.items?.[1]?.content?.signed_id ? 'Modifier votre vidéo' : 'Charger votre vidéo'}
                          </div>
                          <div>
                            <FilesIcon />
                          </div>
                        </div>
                      ) : (
                        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-evenly'}}>
                          <Loader style={{display: 'inline', margin: '0 1em 0 0', height: 25}}/>
                          {'Upload en cours'}
                          <div></div>
                        </div>
                      )
                    }
                  </FileInputLabel>
                )}
            </FileInput>
            <FileInput
              name={`items[2].content.signed_id`}
              accept="image/*"
              id={id + 1}
              activeStorageServiceName="touristic_site_scaleway"
              value={values?.items?.[2]?.content?.signed_id}
              onBlobUrlChange={setPreviewUrlImage}
              onChange={(event) => {
                if (event.target.value !== '') {
                  const filteredFiles = event.target.value
                  setFieldValue(
                    `items[2]`,
                    imageSchema.cast({content: {signed_id: filteredFiles}})
                  );
                }
              }
              }
            >
              {
                ({ready, globalProgress}) => (
                  <FileInputLabel
                    disabled={!ready}
                    progress={globalProgress}
                    style={{border: "none", backgroundColor: 'black', display: 'block'}}
                  >
                    {ready
                      ? (
                        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-evenly'}}>
                          <div>
                            <VideoIcon/>
                          </div>
                          <div>
                            {values?.items?.[2]?.content?.signed_id ? 'Modifier votre vignette' : 'Charger votre vignette'}
                          </div>
                          <div style={{width: '15px', height: '15px'}}></div>
                        </div>
                      ) : (
                        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-evenly'}}>
                          <Loader style={{display: 'inline', margin: '0 1em 0 0', height: 25}}/>
                          {'Upload en cours'}
                          <div></div>
                        </div>
                      )
                    }
                  </FileInputLabel>
                )}
            </FileInput>
          </ContainerVideo>
        </VideoFormContainer>
        {
          values?.items?.[1]?.content?.blob_id && (
            <VideoToDisplayContainer key={values?.items?.[1]?.content?.blob_id}>
              <video controls width="100%">
                <source
                  src={values?.items?.[1]?.content?.blob_id ? API_ORIGINAL_BLOB_URL(values?.items?.[1]?.content?.signed_id, values?.items?.[1]?.content?.filename) : previewUrlVideo}
                  type={values?.items?.[1]?.content?.content_type}
                />
              </video>
            </VideoToDisplayContainer>
          )
        }
        {
          previewUrlVideo && (
            <VideoToDisplayContainer key={previewUrlVideo}>
              <video controls width="100%">
                <source
                  src={previewUrlVideo}
                  type={values?.items?.[1]?.content?.content_type}
                />
              </video>
            </VideoToDisplayContainer>
          )
        }
        {
          previewUrlImage && (
            <CardVideo
              style={{flexDirection: 'row', height: '7em'}}
            >
              <VideoUploadContainer
                backgroundImageUrl={previewUrlImage}
                style={{border: "none"}}
                key={previewUrlImage}
              >
                <CardVideoPreview
                  style={{
                    alignItems: 'flex-end',
                    justifyContent: 'center'
                  }}
                >
                </CardVideoPreview>
              </VideoUploadContainer>
            </CardVideo>
          )
        }
      </FirstPartOfVideoStyled>
      <div style={{display: 'flex', flexDirection: 'row', gap: '20px'}}>
        <ButtonFormValidation loading={loading} placeholder='Enregistrer' onClick={() => resetForm()}/>
        {
          videoTitleImage && (
            <ButtonDelete onClick={onDelete} type="button" theme={theme}>
              Supprimer
            </ButtonDelete>
          )
        }
      </div>
    </FormVideoStyled>
  );
};

export default VideoTitleImageForm;