import Feedback from 'components/informations/feedback/feedback';
import { Avatar } from 'components/profile/avatar';
import React from 'react';
import {useTranslation} from "react-i18next";

const FeedbackElement = ({ user, content, note, created_at, ...props }) => {
const {t} = useTranslation(["henriTrip"])
  return(
  <div className="feedback-element">
    <Avatar size="md" classNames="feedback-avatar" />
    <div className="feedback-user">
      <div className="m-0 font-weight-bold">{user?.user_profile?.firstname || user?.user_profile?.lastname ? `${user?.user_profile?.firstname} ${user?.user_profile?.lastname}` : `${props?.username}` }</div>
      <div className="m-0 font-weight-medium">{user?.username}</div>
      <div className="d-flex pt-1" >
        <Feedback note={note} size="sm" noPadding hideCounter />
        <div className="px-1 font-italic font-weight-normal">
          {t("feedbacks.postedDate")} {new Date(created_at).toLocaleDateString('fr-FR',{day: "numeric", month: "long", year: "numeric"})}
        </div>
      </div>
    </div>
    <div className="feedback-content">
      <p className="paragraph font-weight-medium">{content}</p>
    </div>
  </div>
  )
};


export default FeedbackElement;
