import React from 'react';
import FormAccount from 'components/form/formulaireAccount/formAccount';
import useDocumentTitle from "../../../hooks/use-document-title";
import { useTranslation } from "react-i18next";

const SecurityPage = () => {
    const {t} = useTranslation(["henriTrip"])
    useDocumentTitle(t("account.loginSecurityPage.documentTitle"));
    return (
    <div className="container">
        <div className="col-lg-10 offset-md-1 mt-4">
            {/* <div className="row">
                <h2>Connexion</h2>
            </div>
            <div className="row mt-3">
                <div className="col-9">
                    <h5>Mot de passe</h5>
                </div>
                <div className="col-3">
                    <strong><button type="button" className="btn btn-link" >Mettre à jour</button></strong>
                </div>
            </div>
            <div className="row mt-2">
                <div className="col-12">
                    <p>Ajoutez un mode de paiement à l’aide de notre système de paiement sécurisé, puis achetez vos billets chez nos partenaires.</p>
                </div>
            </div> */}
            <FormAccount/>

            <hr/>
            {/* <div className="row mt-4">
                <h2>Réseaux sociaux</h2>
            </div>
            <div className="row mt-3">
                <div className="col-9">
                    <h5>Facebook</h5>
                </div>
                <div className="col-3">
                    <strong><p style={{color:"#004f91"}}>Connexion</p></strong>
                </div>
            </div>
            <hr></hr>
            <div className="row mt-3">
                <div className="col-9">
                    <h5>Google</h5>
                </div>
                <div className="col-3">
                    <strong><p style={{color:"#004f91"}}>Connexion</p></strong>
                </div>
            </div> */}
        </div>
    </div>
    );
};

export default SecurityPage;