import React from 'react';
import SVG from 'react-inlinesvg';
import {toAbsoluteUrl} from 'utils/utils';
import useDocumentTitle from "../../hooks/use-document-title";

const AuthPageStructure = ({ children }) => {
    React.useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
  useDocumentTitle(`Mot de passe oublié • Henri Trip`)
    return (
        <div className="row m-0">
            <div className="col-12 col-lg-5 px-4 px-md-5 px-lg-4 px-xl-5 py-5 d-flex align-items-center" style={{minHeight: "100vh"}}>
                <div className="w-1 d-flex flex-column">
                    <SVG src={toAbsoluteUrl("/assets/logo/Asset1.svg")} width="20%" height="10%" className="mx-auto mt-3 pb-3" alt="icon Henri Trip" />
                    {children}
                </div>
            </div>
            <div className="col-7 d-md-block bg-primary"
                 style={{
                     backgroundImage: 'url("https://general-henri-1-paris.s3.fr-par.scw.cloud/authImg1.jpg")',
                     backgroundRepeat: "no-repeat",
                     backgroundSize: "cover",
                     backgroundPosition: "center"
                 }}
            >
            </div>
        </div>
    );
};

export default AuthPageStructure;