import UserForm from './UserForm';

const {
  formField: {
    username,
    email,
    firstname,
    lastname,
    gender,
    birthdate,
    password,
    password_confirmation,
    statement,
  },
} = UserForm;

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  [username.name]: '',
  [email.name]: '',
  [firstname.name]: '',
  [lastname.name]: '',
  [gender.name]: '',
  [birthdate.name]: '',
  [password.name]: '',
  [password_confirmation.name]: '',
  [statement.name]: false,
};
