import { Button } from 'react-bootstrap';
import { Formik } from "formik";
import * as Yup from "yup";

const RTModal = ({ handleSubmit }) => {
  return (
    <div style={{
      textAlign: 'center',
    }}>
    <Formik
      initialValues={{ password: "" }}
      validationSchema={Yup.object({
        password: Yup
          .string().min(4, 'Must be 4 characters or more')
          .required("Obligatoire"),
      })}
      onSubmit={handleSubmit}

    >
      {formik => (
        <form onSubmit={formik.handleSubmit}>
          <label htmlFor="password" >Mot de passe du RoadTrip</label>
          <input
            required
            id="password"
            style={{textAlign:'center', padding:'5px'}}
            placeholder="Mot de passe"
            type="text"
            className="form-control form-control-solid h-auto py-1 px-2"
            onChange={formik.handleChange}
          />
          <Button type="submit" className="mx-2" style={{marginTop:'5px'}} color="primary">Envoyer</Button>
        </form>
      )}
    </Formik>
    </div>
  )
}

export default RTModal