export default {
  username: {
    name: 'username',
    type: 'text',
    placeholder: 'henritrip_01',
    required: true,
  },
  firstname: {
    name: 'firstname',
    type: 'text',
    placeholder: 'Henri',
    required: true,
  },
  lastname: {
    name: 'lastname',
    type: 'text',
    placeholder: 'Dupond',
    required: true,
  },
  email: {
    name: 'email',
    type: 'text',
    placeholder: 'Hello@henri.fr',
    required: true,
  },
  password: {
    name: 'password',
    type: 'password',
    placeholder: '········',
    required: true,
  },
  password_confirmation: {
    name: 'password_confirmation',
    type: 'password',
    placeholder: '········',
    required: true,
  }
};