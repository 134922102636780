import { Button } from 'components/form/button';
import TextAreaFormik from 'components/form/text-area/textAreaFormik';
import { Form, Formik } from 'formik';
import useRequest from 'hooks/use-request';
import React from 'react';
import { Modal } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import FeedbackNotation from './feedback-notation';
import * as Yup from 'yup';
import AddActivityFeedback from './add-activity-feedback';
import ModalForm from "../formModal/ModalForm";
import { useHistory } from "react-router";
import {useTranslation} from "react-i18next";


const AddFeedback = ({ show, onHide, apiRoute, type }) => {
  const {t} = useTranslation(["henriTrip"])
  const formField = {
    note: {
      name: "note",
      label: t("feedbacks.addFeedbackModal.note"),
      required: true,
    },
    content: {
      name: "content",
      label: t("feedbacks.addFeedbackModal.comments"),
      placeholder: t("feedbacks.addFeedbackModal.commentsPlaceholder")
    }
  }
  const formValidation = Yup.object().shape({
    [formField.note.name]: Yup.number().min(1, t("general.require")).required(t("general.require")),
    [formField.content.name]: Yup.string().max(1000, max => `${max.value.length}/${max.max} ${t("feedbacks.addFeedbackModal.error")}`)
  })

  const formInitial = {
    [formField.note.name]: 0,
    [formField.content.name]: ""
  }
  const [handleRequest] = useRequest();
  const config = useSelector(state => state.data)
  const history = useHistory();


  const handleSubmit = (v) => {
    handleRequest("post", apiRoute, v, config, (data, status) => {
      if (status === "Done") {
        onHide();
        history.go(0);

      } else if (status === "Error") {
        console.error(data);
      }
    })
  }

  if (type === "activity") {
    return (
      <Modal
        show={show}
        onHide={onHide}
        centered
        size="lg"
      >
        <AddActivityFeedback apiRoute={apiRoute} onHide={onHide} />
      </Modal>
    )
  }

  return (
    <ModalForm
      isOpen={show}
      title={t("feedbacks.addFeedbackModal.header")}
      onClose={onHide}
    >
      <Formik
        initialValues={formInitial}
        validationSchema={formValidation}
        onSubmit={handleSubmit}
      >
        {(formikProps) => {
          return (
            <Form onSubmit={formikProps.handleSubmit} className="py-2 px-3">
              <FeedbackNotation {...formikProps} {...formField.note} />
              <TextAreaFormik {...formikProps} {...formField.content} />
              <div className="d-flex justify-content-end">
                <Button rounded title={t("general.buttonCancel")} color="secondary" className="px-3 mr-1" onClick={onHide} />
                <Button rounded title={t("general.add")} className="px-3" type="submit" />
              </div>
            </Form>
          )
        }}
      </Formik>
    </ModalForm>
  );
};

export default AddFeedback;
