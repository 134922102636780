import styled, {keyframes} from 'styled-components';
import { breakpoint } from 'styled-components-breakpoint';
import React, { useContext, useEffect, useState } from "react";
import TabbedNavigation from "../../../_ui/_v2/components/TabbedNavigation";
import VerticalTabbedNavigation from "../../../_ui/_v2/components/VerticalTabbedNavigation";
import ExperienceMap from "../../../pages/RoadTripsPage/ExperienceLandingPage/ExperienceMap";
import InformationFlipCardsSection from "../../../components/_v2/experiences/InformationFlipCardsSection";
import MessageSection from "../../../components/_v2/experiences/MessageFormSection";
import RoadTripHeaderSection from "../../../components/_v2/experiences/RoadTripHeaderSection";
import DayActivitiesSection from "../../../components/_v2/experiences/DayActivitiesSection";
import DayActivityDetails from "../../../components/_v2/experiences/DayActivityDetails";
import FullScreenNavigationModal from "../../../_ui/_v2/components/FullScreenNavigationModal";
import TipsSection from "../../../components/_v2/experiences/TipsSection";
// import Reviews from "../../../_ui/_v2/components/Reviews";
// import GuaranteesSection from "../../../components/_v2/experiences/GuaranteesSection";
import TripRecommandationsSection, {
  TripDurationCaption
} from "../../../components/_v2/experiences/TripRecommandationsSection";
import LegalSection from "../../../components/_v2/experiences/LegalSection";
import Parallax from "../../../components/_v2/experiences/ParallaxHeader";
import { PostPurchaseCardsModal } from "../../../components/_v2/experiences/PostPurchaseCards";
import ExperiencePaymentModal from "../../../components/_v2/experiences/ExperiencePaymentModal";
import NavBarContext from "../../../_contexts/NavBarProvider";
import { ReactComponent as CartIcon } from '../../../assets/img/logo/cart-icon.svg';
import { ReactComponent as ProfileIcon } from '../../../assets/img/logo/profile-icon.svg';
import { ReactComponent as HomeIcon } from '../../../assets/img/logo/home-svgrepo-com.svg';
import { ReactComponent as PadlockIcon } from '../../../assets/img/logo/padlock-icon.svg';
import { ReactComponent as ChevronDown } from '../../../assets/img/logo/chevron_down.svg';
import { ReactComponent as HeartFillIcon } from '../../../assets/img/logo/heart-fill-icon.svg';
import { ReactComponent as HeartBorderIcon } from '../../../assets/img/logo/heart-border-icon.svg';
import {API_ADD_FAVORITE_ROADTRIP, API_DELETE_FAVORITE_ROADTRIP} from "../../../utils/api";
import {useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import {useLocation} from "react-router";
import {setCookie} from "../../../utils/cookies";

const LazyImage = React.lazy(() => import('../../../_ui/_v2/components/LazyImage'));

const displayRecommandationSection = false;

const Informations = styled.div`
  --padding-main-information: 1rem;
  
  max-width: 1200px;
  margin: 0 auto;
  box-sizing: border-box;
  background-color: ${props => props.theme.colors.white};
  padding: 2rem var(--padding-main-information) var(--padding-main-information) var(--padding-main-information);
  border-radius: 2rem 2rem 0 0;
  transform: translate(0, -2rem);
 
  > * {
    margin-bottom: calc(3rem + 1.5vw);
  }
  
  ${breakpoint('md')`
    --padding-main-information: 6rem;
    
    padding: 8rem var(--padding-main-information) var(--padding-main-information) var(--padding-main-information);
    border-radius: 0;
    transform: translate(0, -4rem);
    background: linear-gradient(to bottom, #FFFFFF00, #FFFFFFFF 4rem, #FFFFFFFF);
  `}
`;

const Illustration = styled.div`
  max-height: calc(100vh + 2rem);
  height: calc(100vh + 2rem);
  max-width: 1200px;
  margin: 0 auto;
  overflow: hidden;
  display: flex;
  align-items: center;
  
  img {
    min-width: 100%;
    max-width: 100%;
    height: auto;
    align-self: center;
    margin: auto;
  }
  
  ${breakpoint('md')`
    max-height: 50vh;
    height: auto;
  `}
`;

const HeaderContainer = styled.div`
  width: 100%;
  position: relative;
`;

const ParallaxContainer = styled.div`
  display: block;
  ${breakpoint('md')`
    display: none;
  `}
`;

const LazyImageStyled = styled(LazyImage)`
  display: none;
  
  ${breakpoint('md')`
    display: block;
  `}
`;

const ImageStyled = styled.div`
  img {
    margin: 0 auto;
    max-height: 400px;
    max-width: 100%;
    
    ${breakpoint('md')`
      max-height: 500px;
  `}
  }
`;

const HeaderTripDurationCaption = styled(TripDurationCaption)`
  display: block;
  position: absolute;
  top: 10%;
  
  ${breakpoint('md')`
    display: none;
  `}
`;

const TitleTab = styled.span`
  font-size: calc(.9rem + .7vw);
  font-weight: 600;
`;

const ExperienceMainPresentation = (props) => {
  const [playVideo, setPlayVideo] = useState(false);
  const {t} = useTranslation(['experiences']);

  return (
    <div {...props}>
      <p>
        {t('text')}
      </p>
      {playVideo
        ? (
            <video
            autoPlay={true}
            preload="none"
            width="100%"
            height="100%"
            controls={true}
            onEnded={() => setPlayVideo(false)}
          >
            <source
              src={"https://general-henri-1-paris.s3.fr-par.scw.cloud/medias/experiences/videos/Profiter.mp4"}
              type="video/mp4"
            />
          </video>
        ) : (
          <img
            style={{
              maxWidth: '100%',
            }}
            onClick={() => setPlayVideo(true)}
            decoding="async"
            src={"https://general-henri-1-paris.s3.fr-par.scw.cloud/medias/experiences/videos/Profiter.mp4.thumbnail.png"}
            alt={"Présentation de l'Expérience Henri Trip"}
          />
        )
      }
    </div>
  );
};

const float = keyframes`
  0% {
    filter: drop-shadow(0 5px 2px rgba(0, 0, 0, 0.6));
    transform: translateX(-50%) translateY(0px);
  }

  50% {
    filter: drop-shadow(0 25px 2px rgba(0, 0, 0, 0.2));
    transform: translateX(-50%) translateY(-20px) scale(80%);
  }

  100% {
    filter: drop-shadow(0 5px 2px rgba(0, 0, 0, 0.6));
    transform: translateX(-50%) translateY(0px);
  }
`;

const FloatingArrow = styled.div`
  svg {
    transform: translateX(-50%) translateY(0px);
    color: ${props => props.theme.colors.white};
    filter: drop-shadow(0 5px 2px rgba(0, 0, 0, 0.6));
    animation: ${float} 4s ease-in-out infinite;
  }
  display: block;
  position: absolute;
  bottom: 5%;
  left: 50%;
`;

const ExperiencePage = ({ roadtrip, fetchRoadtrip = () => {} }) => {
  const query = new URLSearchParams(useLocation().search);
  const purchaseModalOpen = query.get('checkout') === 'true';
  const partner = query.get('partner');

  const [selectedPin, setSelectedPin] = useState(undefined);
  const [selectedDay, setSelectedDay] = useState(0);
  const [activityModalIsOpen, setActivityModalIsOpen] = useState(false);
  const [purchaseCardsModalIsOpen, setPurchaseCardsModalIsOpen] = useState(purchaseModalOpen);
  const [postPurchaseCardsModalIsOpen, setPostPurchaseCardsModalIsOpen] = useState(false);
  const [floatingArrowOpacity, setFloatingArrowOpacity] = useState(1);
  const [favoriteLoading, setFavoriteLoading] = useState(false);
  const navBarContext = useContext(NavBarContext);

  const visitProgram = roadtrip.trip_days;
  const touristicSitesCurrentDay = visitProgram[selectedDay]?.touristic_sites || [];
  const touristicSitesBlinded = visitProgram[0]?.touristic_sites ||[];
  const [mapModalIsOpen, setMapModalIsOpen] = useState(false);
  const visitDuration = visitProgram.length;

  const isExperience = roadtrip.amount > 0;
  const config = useSelector(state => state.data);
  const {t, i18n} = useTranslation(["experiences"]);
  const title = roadtrip.title?.[`text_${i18n.language}`] || roadtrip.title?.title_fr;
  const description = roadtrip.description?.[`text_${i18n.language}`];

  useEffect(() => {
    if (partner) {
      setCookie('webflowPartnerItemId', partner, 24*60*60);
    }
  }, [partner]);

  const navBarList = [
    {
      Icon: ProfileIcon,
      label: 'Profil',
      to: '/user/account',
    },
  ];

  if (isExperience) {
    navBarList.push(
      {
        Icon: CartIcon,
        label: 'Acheter',
        onClick: () => setPurchaseCardsModalIsOpen(true),
      },
    );
  } else {
    navBarList.push(
      {
        Icon: roadtrip.favorite ? HeartFillIcon : HeartBorderIcon,
        label: roadtrip.favorite ? 'Retirer de mes favoris' : 'Ajouter à mes favoris',
        onClick: () => toggleFavorite(),
      },
    );
  }

  const navBarListAfterPurchase = [
    {
      Icon: HomeIcon,
      label: 'Accueil',
      to: '/',
    },
    {
      Icon: ProfileIcon,
      label: 'Profil',
      to: '/user/account',
    },
  ];

  const calcFloatingArrowOpacity = event => {
    const ratio = event.target.scrollTop / (window.innerHeight / 2);
    setFloatingArrowOpacity(1 - Math.min(ratio, 1));
  };

  useEffect(() => {
    navBarContext.setNavBarList(
      roadtrip.purchased
        ? navBarListAfterPurchase
        : navBarList
    );
    navBarContext.subscribeOnPageScroll(calcFloatingArrowOpacity);
    return () => {
      navBarContext.unsubscribeOnPageScroll();
      navBarContext.restoreNavBarList();
    }
  }, [roadtrip]);

  useEffect(() => {
    setSelectedPin(undefined);
  }, [selectedDay]);

  const toggleFavorite = (token) => {
    if (favoriteLoading) {
      return ;
    }
    setFavoriteLoading(true);
    fetch(
      process.env.REACT_APP_API_URL + (roadtrip.favorite ? API_DELETE_FAVORITE_ROADTRIP : API_ADD_FAVORITE_ROADTRIP)(roadtrip.id),
      {
        method: roadtrip.favorite ? 'DELETE' : 'POST',
        mode: 'cors',
        headers: {
          'Authorization': token ? `Bearer ${token}` : config?.headers?.Authorization,
          'Content-Type': 'application/json'
        },
      }
    ).then((response) => {
      if (!response.ok) {
        if (response.status === 401) {
          navBarContext.openLoginModal(
            {
              onRequestClose: () => {
              },
              onLoginSucceeded: (data) => toggleFavorite(data.token),
            }
          );
        } else {
          console.error(response);
        }
      } else {
        fetchRoadtrip();// TODO update roadtrip state
      }
      setFavoriteLoading(false);
    }).catch((error) => {
      console.error(error)
    });
  }

  const dayPrograms = Object.values(visitProgram).map((dayProgram, index) => (
    {
      TitleComponent: (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          {
            isExperience && index !== 0 && !roadtrip.purchased && (
              <PadlockIcon
                height="10px"
                width="10px"
                style={{
                  alignSelf: 'center',
                  marginRight: 5,
                }}
              />
            )
          }
          <span>{(index + 1).toString().padStart(2, '0')}</span>
        </div>
      ),
      ContentComponent: (
        <>
          <ImageStyled>
            <img src={visitProgram[selectedDay].picture} />
          </ImageStyled>
          <p
            className="small"
            style={{
              display: 'block',
              margin: '1rem',
            }}
          >
            {visitProgram[selectedDay][`description_${i18n.language}`] || visitProgram[selectedDay].description_fr}
          </p>
        </>
      ),
    }
  ));

  const dayMaps = Object.values(visitProgram).map((dayProgram, index) => (
    {
      TitleComponent: (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          {
            isExperience && index !== 0 && !roadtrip.purchased && (
              <PadlockIcon
                height="10px"
                width="10px"
                style={{
                  alignSelf: 'center',
                  marginRight: 5,
                }}
              />
            )
          }
          <span>{(index + 1).toString().padStart(2, '0')}</span>
        </div>
      ),
      ContentComponent:
        isExperience && !roadtrip.purchased && index + 1 !== 1
          ?
          <div style={{position: "relative"}}>
            <div style={{
              display: 'flex',
              inset: '0',
              justifyContent: 'center',
              alignItems: 'center',
              zIndex: '2',
              position: 'absolute'
            }}>
              <PadlockIcon width="120" height="120"
                           style={{height: 'calc(60px + 2vw)', width: 'calc(60px + 2vw)', color: 'white'}}/>
            </div>
            <div style={{filter: "brightness(25%)", position: "relative"}}>
              <div onClick={(event) => event.stopPropagation()}>
                <ExperienceMap
                  scrollable={false}
                  mapModalIsOpen={mapModalIsOpen}
                  setMapModalIsOpen={setMapModalIsOpen}
                  day={isExperience && !roadtrip.purchased ? 1 : index + 1}
                  rtTs={isExperience && !roadtrip.purchased ? touristicSitesBlinded : touristicSitesCurrentDay}
                  setSelectedPin={setSelectedPin}
                  setActivityModalIsOpen={setActivityModalIsOpen}
                />
              </div>
            </div>
          </div>
          :
          <ExperienceMap
            mapModalIsOpen={mapModalIsOpen}
            setMapModalIsOpen={setMapModalIsOpen}
            day={isExperience && !roadtrip.purchased ? 1 : index + 1}
            rtTs={isExperience && !roadtrip.purchased ? touristicSitesBlinded : touristicSitesCurrentDay}
            setSelectedPin={setSelectedPin}
            scrollable={true}
            setActivityModalIsOpen={setActivityModalIsOpen}
          />
    }
  ));

  const tabs = (onDayChange,t) => [
    {
      TitleComponent: <TitleTab>{t("tabulations.planning")}</TitleTab>,
      ContentComponent: (
        <VerticalTabbedNavigation
          withSeparator
          items={dayPrograms}
          onActiveTabIndexChange={onDayChange}
        />
      ),
    },
    {
      TitleComponent: <TitleTab>{t("tabulations.map")}</TitleTab>,
      ContentComponent: (
        <VerticalTabbedNavigation
          withSeparator
          items={dayMaps}
          onActiveTabIndexChange={onDayChange}
        />
      ),
    },
    {
      TitleComponent: <TitleTab>{t("tabulations.tips")}</TitleTab>,
      ContentComponent: (
        <TipsSection tips={roadtrip.road_trip_tips} />
      )
    },
    // {
    //   TitleComponent: <TitleTab>{t("tabulations.reviews")}</TitleTab>,
    //   ContentComponent: (
    //     <Reviews
    //       road_trip_reviews={roadtrip.road_trip_reviews}
    //       avg_notes={roadtrip.avg_notes}
    //       notes_counter={roadtrip.notes_counter}
    //     />
    //   )
    // },
  ];

  return (
    <>
      <Illustration>
        <HeaderContainer>
          <ParallaxContainer>
            <Parallax
              parallaxes={roadtrip.parallaxes}
              fallback={roadtrip.picture}
            />
            <FloatingArrow
              style={{
                opacity: floatingArrowOpacity,
              }}
            >
              <ChevronDown height={'1.5em'}/>
            </FloatingArrow>
          </ParallaxContainer>
          <LazyImageStyled src={roadtrip.picture} alt={title} />
        </HeaderContainer>
        <HeaderTripDurationCaption>{visitDuration} jours</HeaderTripDurationCaption>
      </Illustration>
      <Informations>
        <RoadTripHeaderSection
          roadtrip={roadtrip}
          days={visitDuration}
          onBuyButtonClick={() => setPurchaseCardsModalIsOpen(true)}
          style={{marginBottom: 'calc(2rem + 1.5vw)'}}
        />
        {
          isExperience && (
            <ExperienceMainPresentation
              style={{
                marginBottom: '1.5em',
              }}
            />
          )
        }
        <p style={{ display: 'block' }}>
          {description}
        </p>
        <TabbedNavigation
          centerTitles
          items={tabs(setSelectedDay,t).slice(visitProgram[0].description_fr ? 0 : 1)}
          style={{
            maxWidth: 800,
            marginLeft: 'auto',
            marginRight: 'auto',
            marginBottom: '.75em',
          }}
        />
        <DayActivitiesSection
          sites={touristicSitesCurrentDay || []}
          selectedDay={selectedDay}
          siteIndexSelected={selectedPin}
          obfuscate={isExperience && selectedDay !== 0 && !roadtrip.purchased}
          onBuyButtonClick={() => setPurchaseCardsModalIsOpen(true)}
          onCardClick={index => {
            if (touristicSitesCurrentDay[index].title_fr) {
              setActivityModalIsOpen(index);
            }
          }}
          translation={t}
        />
        {
          isExperience && (
            <>
              <InformationFlipCardsSection/>
              {/*<GuaranteesSection/>*/}
              <MessageSection roadtrip={roadtrip} translation = {t}/>
              {
                displayRecommandationSection && (
                  <TripRecommandationsSection touristicSites={touristicSitesCurrentDay} />
                )
              }
              <LegalSection
                style={{
                  marginBottom: 0
                }}
              />
            </>
          )
        }
        {activityModalIsOpen !== false && (
          <FullScreenNavigationModal
            isOpen={activityModalIsOpen !== false}
            onRequestClose={() => setActivityModalIsOpen(false)}
            TitleComponent={(
              <h1>
              {
                visitProgram[selectedDay]?.[`title_${i18n.language}`] ||
                  visitProgram[selectedDay]?.title_fr ||
                  `Planning : ${t("title-component.day")} ${selectedDay + 1}`
              }
              </h1>
            )}
            headerProps={{
              style: {
                width: '100%',
                maxWidth: 1200,
                alignSelf: 'center',
              }
            }}
          >
            <DayActivityDetails
              sites={touristicSitesCurrentDay}
              selectedSiteIndex={activityModalIsOpen}
              style={{
                maxWidth: 1200,
                margin: '0 auto',
              }}
            />
          </FullScreenNavigationModal>
        )
        }

        <FullScreenNavigationModal
            isOpen={mapModalIsOpen !== false}
            onRequestClose={() => setMapModalIsOpen(false)}
            TitleComponent={<h1>{`La carte du jour ${selectedDay + 1}`}</h1>}
        >
          <ExperienceMap
            scrollable={true}
            mapModalIsOpen={mapModalIsOpen}
            setMapModalIsOpen={setMapModalIsOpen}
            day={selectedDay + 1}
            rtTs={isExperience && !roadtrip.purchased ? visitProgram[0]?.touristic_sites || [] : touristicSitesCurrentDay}
            setSelectedPin={setSelectedPin}
            selectedPin={selectedPin}
            setActivityModalIsOpen={setActivityModalIsOpen}
          />
        </FullScreenNavigationModal>

        <PostPurchaseCardsModal
          withPadding
          isOpen={postPurchaseCardsModalIsOpen}
          onRequestClose={() => {
            setPostPurchaseCardsModalIsOpen(false);
            fetchRoadtrip();
          }}
        />

        <ExperiencePaymentModal
          roadtrip={roadtrip}
          isOpen={purchaseCardsModalIsOpen}
          onRequestClose={() => setPurchaseCardsModalIsOpen(false)}
          onPaymentSucceeded={() => {
            setPurchaseCardsModalIsOpen(false);
            setPostPurchaseCardsModalIsOpen(true)
          }}
        />
      </Informations>
    </>
  );
};

export default ExperiencePage;