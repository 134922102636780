
import React, { useRef, useEffect, useState } from "react";
import MapGL, { GeolocateControl, NavigationControl, Layer, Marker, Source } from "react-map-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import { Dropdown } from "semantic-ui-react";
import _ from "underscore";
import { ClickAwayListener } from "@material-ui/core";
import { Button, Icon } from 'semantic-ui-react';
import { PHONE_WIDTH } from '../../../utils/utils';
import './roadtripMap.scss';
import { useTranslation } from "react-i18next";
import ModalForm from "../../../components/formModal/ModalForm";
import Login from "../../../containers/Auth/Login";
import TouristicSiteMapPopupPremium
  from "../../../components/touristicSites/touristicSiteMapPopup/TouristicSiteMapPopupPremium";

const filterControlStyle = {
  width: 210,
  top: 40,
  left: 120,
  background: 'white',
  transform: "scale(1.25)",
  position: 'absolute',
  zIndex: 1

};

const filterCloseStyle = {
  width: 40,
  height: 40,
  position: 'absolute',
  top: 35,
  left: 20,
  background: 'white',
  zIndex: 1
}

const filterCloseStyleDekstop = {
  width: 40,
  height: 40,
  position: 'absolute',
  top: 50,
  left: 20,
  background: 'white',
  zIndex: 1
}

const textPinStyle = {
  color: 'white',
  fontSize: 21,

};

const ICON = `M20.2,15.7L20.2,15.7c1.1-1.6,1.8-3.6,1.8-5.7c0-5.6-4.5-10-10-10S2,4.5,2,10c0,2,0.6,3.9,1.6,5.4c0,0.1,0.1,0.2,0.2,0.3
  c0,0,0.1,0.1,0.1,0.2c0.2,0.3,0.4,0.6,0.7,0.9c2.6,3.1,7.4,7.6,7.4,7.6s4.8-4.5,7.4-7.5c0.2-0.3,0.5-0.6,0.7-0.9
  C20.1,15.8,20.2,15.8,20.2,15.7z`;

const pinStyle = {
  cursor: 'pointer',
  fill: '#004f91',
  stroke: 'none',
  height: 35,
  width: 35
};
const numberMarkerStyle = {
  zIndex: 1,
  color: 'white',
  top: '9px',
  left: '12px'

}

const RoadTripPremiumMap = ({ onMapClick, openMap, btndisable, rtTs, handlePurchase }) => {
  const [widthState, setWidthState] = useState(window.innerWidth);
  const [dataFiltered, setDataFiltered] = useState(rtTs)
  const sites = _.groupBy(rtTs, "day_number");
  const days = Object.keys(sites);
  const ref = useRef();
  const [popup, setPopup] = useState(null);
  const _onClick = (datum) => {
    setPopup(datum)
  };
  const [source, setSource] = useState([{}]);
  const [layer, setLayer] = useState([]);
  const [viewport, setViewport] = useState({
    latitude: dataFiltered[0]?.latitude || 46.227638,
    longitude: dataFiltered[0]?.longitude || 2.213749,
    zoom: 7.5,
    bearing: 0,
    pitch: 0,
  });
  let zoom = (arr) => {
    let mean = arr.reduce((acc, curr) => {
      return acc + curr
    }, 0) / arr.length

    // Assigning (value - mean) ^ 2 to every array item
    arr = arr.map((k) => {
      return (k - mean) ** 2
    })

    // Calculating the sum of updated array
    let sum = arr.reduce((acc, curr) => acc + curr, 0);
    // Calculating the variance
    let variance = sum / arr.length

    // Returning the Standered deviation
    return Math.sqrt(sum / arr.length)

  }

  const mean = (arr) => {
    let mean_provide = arr.reduce((acc, curr) => {
      return acc + curr
    }, 0) / arr.length

    return mean_provide
  }

  const { t } = useTranslation(["henriTrip"])

  useEffect(() => {
    getRoute();
  }, [dataFiltered]);

  useEffect(() => {
    function handleResize() {
      setWidthState(window.innerWidth)
    }
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize)
  }, []);
  useEffect(() => {
    const temp = []
    let counter = 1
    const sites = _.groupBy(rtTs, "day_number");
    const days = Object.keys(sites);
    days?.forEach(day => {
      sites[day].sort((a, b) => a.rank - b.rank)
      sites[day].forEach(e => {
        temp.push(
          {
            ...e,
            rank: counter
          }
        )
        counter++
      })
    })
    setDataFiltered(temp)
  }, [rtTs]);

  async function getRoute() {
    if (dataFiltered.length == 0) {
      let latLong = "2.213749,46.227638;2.213749,46.227638"
      const query = await fetch(
        `https://api.mapbox.com/directions/v5/mapbox/cycling/${latLong}?geometries=geojson&access_token=${process.env.REACT_APP_MAPBOX_ACCESS_TOKEN}`,
        { method: "GET" }
      );


      const json = await query.json();
      const data = json.routes[0];
      const route = data.geometry.coordinates;
      const geojson = {
        features: [
          {
            type: "Feature",
            properties: {},
            geometry: {
              type: "LineString",
              coordinates: route,
            },

          },
        ],
        type: "FeatureCollection",
      };
      setSource(geojson);
      setLayer([
        {
          id: "route",
          type: "line",
          source: {
            type: "geojson",
            data: geojson,
          },
          layout: {
            "line-join": "round",
            "line-cap": "round",
          },
          paint: {
            "line-color": "#3887be",
            "line-width": 5,
            "line-opacity": 0.75,
          },
        },
      ]);
    }
    if (dataFiltered.length == 1) {
      const arr_zoom = []
      let bounds = [];
      dataFiltered?.forEach((element) => {
        arr_zoom.push(element.longitude)
        arr_zoom.push(element.latitude)
        bounds.push([element.longitude, element.latitude].toString());
      });
      let latLong = bounds.join(";");
      const query = await fetch(
        `https://api.mapbox.com/directions/v5/mapbox/cycling/${latLong}?geometries=geojson&access_token=${process.env.REACT_APP_MAPBOX_ACCESS_TOKEN}`,
        { method: "GET" }
      );
      const test = zoom(arr_zoom) - 10
      setViewport({ latitude: dataFiltered[0].latitude, longitude: dataFiltered[0].longitude, zoom: test })

      // setViewPort({
      //     latitude: dataFiltered[0]?.latitude || 46.227638,
      //     longitude: dataFiltered[0]?.longitude || 2.213749,
      //     zoom: zoom,
      //     bearing: 0,
      //     pitch: 0,
      // })

      const json = await query.json();
      const data = json?.routes[0];
      const route = data.geometry.coordinates;

      const geojson = {
        features: [
          {
            type: "Feature",
            properties: {},
            geometry: {
              type: "LineString",
              coordinates: route,
            },

          },
        ],
        type: "FeatureCollection",
      };

      setSource(geojson);
      setLayer([
        {
          id: "route",
          type: "line",
          source: {
            type: "geojson",
            data: geojson,
          },
          layout: {
            "line-join": "round",
            "line-cap": "round",
          },
          paint: {
            "line-color": "#3887be",
            "line-width": 5,
            "line-opacity": 0.75,
          },
        },
      ]);
    }
    if (dataFiltered.length > 1 && dataFiltered.length < 25) {
      let bounds = [];
      let arr_long = [];
      let arr_lat = [];

      let zoom_calc = 0;
      dataFiltered?.forEach((element) => {
        arr_long.push(element.longitude)
        arr_lat.push(element.latitude)
        bounds.push([element.longitude, element.latitude].toString());
      });
      let lat = mean(arr_lat)
      let long = mean(arr_long)
      let latLong = bounds.join(";");
      let test = zoom(arr_lat)
      let test2 = zoom(arr_long)
      if (test + test2 < Math.pow(10, -3) || test + test2 == 0) {
        zoom_calc = 13
      }
      else if (test + test2 > Math.pow(10, -3) && test + test2 < Math.pow(10, -2)) {
        zoom_calc = 15
      }
      else if (test + test2 > Math.pow(10, -2) && test + test2 < Math.pow(10, -1)) {
        zoom_calc = 11
      }
      else if (test + test2 > 0, 1) {
        zoom_calc = 9
        lat = dataFiltered[0].latitude
        long = dataFiltered[0].longitude
      }
      else if (test + test2 > 1) {
        zoom_calc = 8
        lat = dataFiltered[0].latitude
        long = dataFiltered[0].longitude
      }
      else {
        zoom_calc = 6
        lat = dataFiltered[0].latitude
        long = dataFiltered[0].longitude
      }

      const query = await fetch(
        `https://api.mapbox.com/directions/v5/mapbox/cycling/${latLong}?geometries=geojson&access_token=${process.env.REACT_APP_MAPBOX_ACCESS_TOKEN}`,
        { method: "GET" }
      );
      const json = await query.json();
      const data = json?.routes[0];
      const route = data.geometry.coordinates;
      setViewport({ latitude: lat, longitude: long, zoom: zoom_calc })

      const geojson = {
        features: [
          {
            type: "Feature",
            properties: {},
            geometry: {
              type: "LineString",
              coordinates: route,
            },

          },
        ],
        type: "FeatureCollection",
      };

      setSource(geojson);
      setLayer([
        {
          id: "route",
          type: "line",
          source: {
            type: "geojson",
            data: geojson,
          },
          layout: {
            "line-join": "round",
            "line-cap": "round",
          },
          paint: {
            "line-color": "#3887be",
            "line-width": 5,
            "line-opacity": 0.75,
          },
        },
      ]);
    }

    if (dataFiltered.length > 25) {
      let arr_long = [];
      let arr_lat = [];
      let zoom_calc = 0;
      const ts_daynumber = _.groupBy(rtTs, "day_number");
      let days = Object.keys(ts_daynumber);
      let random = 0;

      dataFiltered.forEach(e => {
        arr_long.push(e.longitude)
        arr_lat.push(e.latitude)
      })

      let test = zoom(arr_lat)
      let test2 = zoom(arr_long)
      let lat = mean(arr_lat)
      let long = mean(arr_long)
      if (test + test2 < Math.pow(10, -3) || test + test2 == 0) {
        zoom_calc = 13
      }
      else if (test + test2 > Math.pow(10, -3) && test + test2 < Math.pow(10, -2)) {
        zoom_calc = 15
      }
      else if (test + test2 > Math.pow(10, -2) && test + test2 < Math.pow(10, -1)) {
        zoom_calc = 11
      }
      else if (test + test2 > 0, 1 && test + test2 < 1) {
        zoom_calc = 9
        lat = dataFiltered[0].latitude
        long = dataFiltered[0].longitude
      }
      else if (test + test2 >= 1) {
        zoom_calc = 8
        lat = dataFiltered[0].latitude
        long = dataFiltered[0].longitude
      }
      else {
        zoom_calc = 5
        lat = dataFiltered[0].latitude
        long = dataFiltered[0].longitude
      }

      setViewport({ latitude: lat, longitude: long, zoom: zoom_calc })


      for await (let day of days) {
        let days = Object.keys(ts_daynumber);
        let temp = []
        ts_daynumber[day]?.map(e => {
          temp.push([e.longitude, e.latitude].toString())
        })
        const latLong = temp.join(";");

        const query = await fetch(
          `https://api.mapbox.com/directions/v5/mapbox/cycling/${latLong}?geometries=geojson&access_token=${process.env.REACT_APP_MAPBOX_ACCESS_TOKEN}`,
          { method: "GET" }
        );

        const json = await query.json();
        const data = await json?.routes[0];
        const route = data.geometry.coordinates;

        const geojson = {
          features: [
            {
              type: "Feature",
              properties: {},
              geometry: {
                type: "LineString",
                coordinates: route,
              },

            },
          ],
          type: "FeatureCollection",
        };
        if (day == 1) {
          setSource(state => {
            return {
              features: [
                {
                  type: "Feature",
                  properties: {},
                  geometry: {
                    type: "LineString",
                    coordinates: route,
                  },

                },
              ],
              type: "FeatureCollection",
            }
          })
        }
        else {
          setSource(state => {
            return {
              features: [
                {
                  type: "Feature",
                  properties: {},
                  geometry: {
                    type: "LineString",
                    coordinates: [...state?.features[0].geometry.coordinates, ...route],
                  },

                },
              ],
              type: "FeatureCollection",
            }
          })
        }
        setLayer(state => [...state, ...[
          {
            id: `route`,
            type: "line",
            source: {
              type: "geojson",
              data: geojson,
            },
            layout: {
              "line-join": "round",
              "line-cap": "round",
            },
            paint: {
              "line-color": "#3887be",
              "line-width": 5,
              "line-opacity": 0.75,
            },
          },
        ]]);
        random++
        day++
      }
    }
  }

  const [open, setOpen] = useState(false);
  const openModal = () => {
    setOpen(true);
  }

  const dayOptions = [
    {
      key: "tout",
      text: t("general.viewAll"),
      value: "tout",
      content: t("general.viewAll")
    },
    ...days.map(e => ({
      key: e,
      text: e + ' '+ t("roadTripPage.day"),
      value: e,
      content: e + ' '+ t("roadTripPage.day")
    }))
  ]
  const handleChange = (event) => {

    if (event == 'tout') {
      const temp = []
      let counter = 1
      const sites = _.groupBy(rtTs, "day_number");
      const days = Object.keys(sites);
      days.forEach(day => {
        sites[day].sort((a, b) => a.rank - b.rank)
        sites[day].forEach(e => {
          temp.push(
            {
              ...e,
              rank: counter
            }
          )
          counter++
        })
      })
      setDataFiltered(temp)
    }
    else {
      setDataFiltered(rtTs.filter(e => e.day_number == event).sort((a, b) => a.rank - b.rank))
    }

  }


  if (!source || !layer || layer.length === 0) {
    return null/**Ajouter un Loader */
  }
  return (
    <>

      <ModalForm
        isOpen={open}
        title={t("register.title")}
        onClose={() => setOpen(false)}
      >
        <Login modal={true} handleSubmit={() => setOpen(false)} handleClose={() => setOpen(false)} />
      </ModalForm>
      {

        dataFiltered.length == 0 ?
          <MapGL
            ref={ref}
            mapboxAccessToken={process.env.REACT_APP_MAPBOX_ACCESS_TOKEN}
            {...viewport}
            mapStyle={"mapbox://styles/mapbox/streets-v9"}
            onViewportChange={(viewport) => setViewport({ ...viewport })}
            width="100%"
            height="100%"
            scrollZoom={openMap}
          >
            {
              btndisable === false &&
              <>

                <Button
                  icon
                  className="rounded-circle"
                  style={widthState <= PHONE_WIDTH ? filterCloseStyle : filterCloseStyleDekstop}
                  onClick={onMapClick}>
                  <Icon name='close' />
                </Button>
                <Dropdown
                  style={filterControlStyle}
                  button
                  className="icon col-sm-12 col-md-12 mt-md-2 m-0"
                  placeholder={t("general.days")}
                  icon="calendar"
                  labeled
                  options={dayOptions}
                  onChange={(event, data) => handleChange(data?.value ?? 0)}
                  settings={{ useLabels: false }}
                />

                <Button
                  icon
                  className="rounded-circle"
                  style={widthState <= PHONE_WIDTH ? filterCloseStyle : filterCloseStyleDekstop}
                  onClick={onMapClick}>
                  <Icon name='close' />
                </Button>
                <Dropdown
                  style={filterControlStyle}
                  button
                  className="icon col-sm-12 col-md-12 mt-md-2 m-0"
                  placeholder={t("general.days")}
                  icon="calendar"
                  labeled
                  options={dayOptions}
                  onChange={(event, data) => handleChange(data?.value ?? 0)}
                  settings={{ useLabels: false }}
                />


                <GeolocateControl
                  className="geolocateControlRTMapStyle"
                  label={"ma position"}
                  disabledLabel={"disabledLocalisation"}
                  positionOptions={{ enableHighAccuracy: true }}
                  trackUserLocation={true}
                  showAccuracyCircle={true}
                />
                <NavigationControl
                  className="zoomControlStyle" />

              </>
            }
          </MapGL>
          :
          <MapGL
            ref={ref}
            mapboxAccessToken={process.env.REACT_APP_MAPBOX_ACCESS_TOKEN}
            {...viewport}
            mapStyle={"mapbox://styles/mapbox/streets-v9"}
            onViewportChange={(viewport) => setViewport({ ...viewport })}
            width="100%"
            height="100%"
            interactive={popup === null}
            scrollZoom={openMap}
          >

            <Source id="my-data" type="geojson" data={source}>
              {layer?.map(({ source, ...e }, i) => (
                <Layer key={i} {...e} source="my-data" />
              ))}
            </Source>


            {
              btndisable === false &&
              <>

                <Button
                  icon
                  className="rounded-circle"
                  style={widthState <= PHONE_WIDTH ? filterCloseStyle : filterCloseStyleDekstop}
                  onClick={onMapClick}>
                  <Icon name='close' />
                </Button>
                <Dropdown
                  style={filterControlStyle}
                  button
                  className="icon col-sm-12 col-md-12 mt-md-2 m-0"
                  placeholder={t("general.days")}
                  icon="calendar"
                  labeled
                  options={dayOptions}
                  onChange={(event, data) => handleChange(data?.value ?? 0)}
                  settings={{ useLabels: false }}
                />

                <Button
                  icon
                  className="rounded-circle"
                  style={widthState <= PHONE_WIDTH ? filterCloseStyle : filterCloseStyleDekstop}
                  onClick={onMapClick}>
                  <Icon name='close' />
                </Button>
                <Dropdown
                  style={filterControlStyle}
                  button
                  className="icon col-sm-12 col-md-12 mt-md-2 m-0"
                  placeholder={t("general.days")}
                  icon="calendar"
                  labeled
                  options={dayOptions}
                  onChange={(event, data) => handleChange(data?.value ?? 0)}
                  settings={{ useLabels: false }}
                />


                <GeolocateControl
                  className="geolocateControlRTMapStyle"
                  label={"ma position"}
                  disabledLabel={"disabledLocalisation"}
                  positionOptions={{ enableHighAccuracy: true }}
                  trackUserLocation={true}
                  showAccuracyCircle={true}
                />
                <NavigationControl
                  className="zoomControlStyle" />

              </>
            }

            <ClickAwayListener onClickAway={() => setPopup(null)}>
              <div>
                {dataFiltered?.map((e) => (
                  <Marker
                    key={`${e.id}_${e.day_number}_${e.rank}`}
                    longitude={e.longitude}
                    latitude={e.latitude}
                    offsetLeft={-10}
                    offsetTop={-15}
                  > {
                      e.custom ? <div className="SVG" onClick={() => _onClick(e)}>
                        <div className="layout position-relative">
                          <div className="svg-inline">
                            <svg className="classNameSVG" viewBox="0 0 24 24" style={pinStyle}>
                              <path d={ICON} />
                            </svg>
                          </div>
                          <span className="position-absolute top-0 start-0 translate-middle" style={numberMarkerStyle}  >
                            {e.rank}
                          </span>
                        </div>
                      </div>
                        :

                        <div className="SVG" onClick={() => _onClick(e)}>

                          <div className="layout position-relative">
                            <div className="svg-inline">
                              <svg className="classNameSVG" viewBox="0 0 24 24" style={pinStyle}>
                                <path d={ICON} />
                              </svg>
                            </div>
                            <span className="position-absolute top-0 start-0 translate-middle" style={numberMarkerStyle}  >
                              {e.rank}
                            </span>
                          </div>

                        </div>
                    }
                  </Marker>
                ))}
                {popup ?
                  <TouristicSiteMapPopupPremium data={popup} handlePurchase={handlePurchase} />
                  :
                  <></>
                }
              </div>
            </ClickAwayListener>
          </MapGL>
      }
    </>

  );
}
// eslint-disable-line react-hooks/exhaustive-deps
export default RoadTripPremiumMap;
