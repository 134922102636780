import React, { useEffect, useState } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import SVG from "react-inlinesvg";
import { isMobile } from "react-device-detect";
import Search from "../../components/search/search";
import { AccountCircle, GTranslateOutlined } from "@material-ui/icons";
import { Button, Dropdown } from "react-bootstrap";
import ModalForm from "../../components/formModal/ModalForm";
import ModalExperience from "../../components/formModal/ModalExperience";
import Login from "../../containers/Auth/Login";
import Register from "../../containers/Auth/Register";
import { Flag } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import "./NavbarPage.scss";
import i18next from "i18next";
import { Icon } from "semantic-ui-react";
import { ThemeProvider } from "styled-components";
import theme from "../../_ui/_v2/styles/theme";
import GlobalStyle from "../../_ui/_v2/styles/global_style";

import Suggest from "containers/Suggest/Suggest";
import { useSelector } from "react-redux";
import QueryStatsIcon from "@mui/icons-material/QueryStats";
import { SvgIcon } from "@material-ui/core";
import CreateRoadTrip from "containers/User/Roadtrips/CreateRoadTrip/CreateRoadTrip";
import { PHONE_WIDTH } from "../../utils/utils";
import CreateExperience from "containers/User/Experience/CreateExperience"

import LanguageSelector from "_ui/_v2/components/LanguageSelector";

const NavbarLinkPage = (props, navbarOpen) => {
  const location = useLocation();
  const { user } = useSelector((state) => state.user);
  const { t } = useTranslation(["henriTrip"]);
  const [registerModal, setRegisterModal] = useState(false);
  const [scrollState, setScrollState] = useState("navbar-dark bg-transparent");
  const options = [
    {
      name: t("general.travelTools.plane"),
      travel_code: "plane",
      link: "https://www.kiwi.com/deep?affilid=henritriphenrideeplink&lang=fr&pageName=tilesPage&return=anytime&transport=aircraft",
    },
    {
      name: t("general.travelTools.train"),
      travel_code: "train",
      link: "https://www.kiwi.com/deep?affilid=henritriphenrideeplink&lang=fr&pageName=tilesPage&return=anytime&transport=bus",
    },
    {
      name: t("general.travelTools.car"),
      travel_code: "car",
      link: "https://www.discovercars.com/?a_aid=henri-trip&a_bid=efec0e0d",
    },
    {
      name: t("general.travelTools.bus"),
      travel_code: "bus",
      link: "https://www.kiwi.com/deep?affilid=henritriphenrideeplink&lang=fr&pageName=tilesPage&return=anytime&transport=train",
    },
    {
      name: t("general.travelTools.hotel"),
      travel_code: "hotel",
      link: "https://www.stay22.com/allez/booking?&aid=henritrip&checkin=&checkout=&adults=&children=&link=https://booking.com/",
    },
    {
      name: t("general.travelTools.ticketing"),
      travel_code: "ticket",
      link: "https://www.getyourguide.com?partner_id=21JVMM5&cmp=share_to_earn",
    },
    {
      name: t("general.travelTools.show"),
      travel_code: "university",
      link: "https://ticketmaster-fr.tm7516.net/henritrip",
    },
  ];

  useEffect(() => {
    document.addEventListener("scroll", (e) => {
      let scrolled = document.scrollingElement.scrollTop;
      if (scrolled >= 350) {
        if (scrollState) {
          setScrollState(false);
        }
      } else if (!scrollState) {
        setScrollState(true);
      }
    });
  });

  return (
    <>
      <li
        className={`${!user.id ? "nav-item-logout" : "nav-item m-0"} p-0`}
        data-toggle={isMobile ? "collapse" : ""}
        data-target="#navbarTogglerDemo01"
        aria-controls="navbarTogglerDemo01"
      >
        {props.extern && (
          <a
            role="button"
            className={`nav-link henri-pro btn-sm btn-primary rounded-pill m-0  ${props.active ? "active" : ""
              }`}
            href={props.href}
            rel="noreferrer"
            target="_blank"
          >
            {props.title || (
              <SVG
                src={props.icon}
                className="text-primary"
                alt=""
                width="15"
              />
            )}
          </a>
        )}
        {!props.extern && !props.outils && !props.account && (
          <Link className={`nav-link m-0 p-0 `} style={{}} to={props.href}>
            {props.title || (
              <SVG
                src={props.icon}
                className="text-primary"
                alt=""
                width="15"
              />
            )}
          </Link>
        )}
        {!user.id && props.account && (
          <>
            <ModalForm
              isOpen={registerModal}
              title={t("register.title")}
              onClose={() => setRegisterModal(false)}
            >
              <Register
                modal={true}
                handleClose={() => setRegisterModal(false)}
              />
            </ModalForm>

            {!scrollState ||
              (location.pathname !== "/home" && navbarOpen === true) ? (
              <></>
            ) : (
              <a
                className={`nav-link m-0 p-0 ${scrollState && "d-none"}
                  ${props.active ? "active" : ""}`}
                onClick={() => setRegisterModal(true)}
              >
                {props.title}
              </a>
            )}

            {!scrollState ||
              location.pathname === "/home" ||
              (location.pathname === "/" && navbarOpen === false && (
                <a
                  className={`nav-link mr-3 p-0 ${props.active ? "active" : ""
                    }`}
                  onClick={() => setRegisterModal(true)}
                >
                  {props.title}
                </a>
              ))}
          </>
        )}

        {props.outils && (
          <Dropdown className="offline">
            <Dropdown.Toggle className="navbar-dropdown--toggle bg-transparent border-0 shadow-none">
              <a
                className={`nav-link mr-3 p-0  ${props.active ? "active" : ""}`}
              >
                {" "}
                {t("general.travelTools.title")}{" "}
                {!scrollState ? (
                  <Icon name="angle down" className="grey" />
                ) : (
                  <Icon name="angle down" />
                )}{" "}
                {scrollState &&
                  location.pathname !== "/home" &&
                  location.pathname !== "/" ? (
                  <Icon name="angle down" className="grey" />
                ) : (
                  ""
                )}
              </a>
            </Dropdown.Toggle>
            <Dropdown.Menu className={"navbar-dropdown--menu "}>
              <a href="#" className="titre-comparateurs">
                {t("general.travelTools.comparators")}
              </a>
              <hr className="m-0 hr" />
              {options.map(({ name, travel_code, link }) => (
                <Dropdown.Item
                  key={name}
                  className={"navbar-dropdown--item "}
                  onClick={() => window.open(link)}
                >
                  <Icon name={travel_code} />

                  {name}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
        )}
      </li>
    </>
  );
};

const NavbarPage = (props) => {
  const { user } = useSelector((state) => state.user);
  const { t } = useTranslation(["henriTrip"]);
  const languages = [
    {
      code: "fr",
      name: "Français",
      country_code: "fr",
    },
    {
      code: "en",
      name: "English",
      country_code: "gb",
    },
    {
      code: 'es',
      name: 'Spanish',
      country_code: 'es'
    },
    {
      code: 'pt',
      name: 'Portuguese',
      country_code: 'pt'
    },
    {
      code: 'jp',
      name: 'Japanese',
      country_code: 'jp'
    },
    {
      code: 'cn',
      name: 'Chinese',
      country_code: 'cn'
    },
    {
      code: 'ru',
      name: 'Russian',
      country_code: 'ru'
    }
  ];
  const options = [
    {
      name: t("general.travelTools.plane"),
      travel_code: "plane",
      link: "https://www.kiwi.com/deep?affilid=henritriphenrideeplink&lang=fr&pageName=tilesPage&return=anytime&transport=aircraft",
    },
    {
      name: t("general.travelTools.train"),
      travel_code: "train",
      link: "https://www.kiwi.com/deep?affilid=henritriphenrideeplink&lang=fr&pageName=tilesPage&return=anytime&transport=bus",
    },
    {
      name: t("general.travelTools.car"),
      travel_code: "car",
      link: "https://www.discovercars.com/?a_aid=henri-trip&a_bid=efec0e0d",
    },
    {
      name: t("general.travelTools.bus"),
      travel_code: "bus",
      link: "https://www.kiwi.com/deep?affilid=henritriphenrideeplink&lang=fr&pageName=tilesPage&return=anytime&transport=train",
    },
    {
      name: t("general.travelTools.hotel"),
      travel_code: "hotel",
      link: "https://www.stay22.com/allez/booking?&aid=henritrip&checkin=&checkout=&adults=&children=&link=https://booking.com/",
    },
    {
      name: t("general.travelTools.ticketing"),
      travel_code: "ticket",
      link: "https://tourscanner.com/fr/?a=vnnmb&s=fr",
    },
    {
      name: t("general.travelTools.show"),
      travel_code: "university",
      link: "https://ticketmaster-fr.tm7516.net/henritrip",
    },
  ];


  const history = useHistory();
  const location = useLocation();
  const [scrollState, setScrollState] = useState("navbar-dark bg-transparent");
  const [widthState, setWidthState] = useState(window.innerWidth);
  const [openOutils, setOpenOutils] = useState(false);
  const [loginModal, setLoginModal] = useState(false);
  const [registerModal, setRegisterModal] = useState(false);
  const [suggestModal, setSuggestModal] = useState(false);
  const [suggestModalRT, setSuggestModalRT] = useState(false);
  const [suggestModalXp, setSuggestModalXp] = useState(false);
  const [navbarOpen, setNavbarOpen] = useState(false);
  const handleClick = (props) => {
    i18next.changeLanguage(props);
    localStorage.setItem("i18nextLng", props);
  };
  const [createRT, setCreateRT] = useState(false)
  useEffect(() => {
    if (suggestModalRT === true) {
      setCreateRT(true)
    }
  }, [suggestModalRT]);

  const handleClickOutils = () => {
    setOpenOutils(!openOutils);
  };

  const handleToggle = () => {
    setNavbarOpen(!navbarOpen);
  };

  const closeMenu = () => {
    setNavbarOpen(false);
  };
  // const handleToggle = () => {
  //   setNavbarOpen(!navbarOpen)
  // }
  useEffect(() => {
    if (localStorage.getItem("i18nextLng")?.length > 2) {
      i18next.changeLanguage("en");
    }
  }, []);
  useEffect(() => {
    function handleResize() {
      setWidthState(window.innerWidth);
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    document.addEventListener("scroll", (e) => {
      let scrolled = document.scrollingElement.scrollTop;
      if (scrolled >= 350) {
        if (scrollState) {
          setScrollState(false);
        }
      } else if (!scrollState) {
        setScrollState(true);
      }
    });
  });
  return (
    props.location.pathname !== "/partner" &&
    props.location.pathname !== "/partner-demo" &&
    props.location.pathname !== "/ambassador" && (
      <>
        <ModalForm
          isOpen={loginModal}
          title={t("login.loginButton")}
          onClose={() => setLoginModal(false)}
        >
          <Login
            modal={true}
            handleSubmit={() => setLoginModal(false)}
            handleClose={() => setLoginModal(false)}
          />
        </ModalForm>
        <ModalForm
          isOpen={registerModal}
          title={t("register.title")}
          onClose={() => setRegisterModal(false)}
        >
          <Register modal={true} handleClose={() => setRegisterModal(false)} />
        </ModalForm>
        <ModalForm
          data-body-scroll-lock-ignore="true"
          className="suggest-modal"
          isOpen={suggestModal}
          onClose={() => setSuggestModal(false)}
          centered
          size="large"
        >
          <Suggest modal={true} onHide={() => setSuggestModal(false)} />
        </ModalForm>
        <ModalForm
          data-body-scroll-lock-ignore="true"
          className="suggest-modal"
          isOpen={suggestModalRT}
          onClose={() => setSuggestModalRT(false)}
          centered
          size="large"
        >
          <CreateRoadTrip

            modal={true}
            createRT={createRT}
            onCancel={() => setSuggestModalRT(false)}
          />
        </ModalForm>

        <ModalExperience
          data-body-scroll-lock-ignore="true"
          className="suggest-modal"
          isOpen={suggestModalXp}
          onClose={() => setSuggestModalXp(false)}
          centered
          size="large"
        >
          <CreateExperience

            modal={true}
            setSuggestModalXp={setSuggestModalXp}
            onCancel={() => setSuggestModalRT(false)}
          />
        </ModalExperience>

        <nav
          className={`${navbarOpen ? "navbar " : "navbar navbar-expand-lg"}
          ${(props.location.pathname === "/" && scrollState) ||
              (props.location.pathname.includes("/search-view") &&
                widthState < 1400) ||
              props.location.pathname === "/partner" ||
              props.location.pathname === "/partner-demo"
              ? `navbar-dark bg-transparent position-absolute `
              : `navbar-light bg-white `
            }`}>
          <div
            className={`${widthState < 1400 && widthState > PHONE_WIDTH
              ? `navbar-mobile d-flex justify-content-between  ${navbarOpen && "mb-0"}`
              : "navbar-mobile d-flex justify-content-between"
              } container`}
          >
            {
              props.location.pathname.includes("/search-view") &&
                widthState < 1400 &&
                navbarOpen ? (
                <Link className="navbar-brand" to="/">
                  <img
                    className="navbar-logo"
                    width="150"
                    src="/assets/logo/Logo_henri-side.svg"
                    alt="logo"
                  />
                </Link>
              ) : (
                <Link className="navbar-brand" to="/">
                  <img
                    className="navbar-logo"
                    width="150"
                    src="/assets/logo/Logo_henri-side.svg"
                    alt="logo"
                  />
                </Link>
              )
            }

            {(!scrollState || props.location.pathname !== "/") &&
              props.location.pathname !== "/partner" &&
              props.location.pathname !== "partner-demo" &&
              ["/login", "/register"].indexOf(props.location.pathname) ===
              -1 && (
                <>
                  <div
                    className={`${!user?.id ? `navbar-search ` : "navbar-search"
                      } ${navbarOpen ? "d-none" : ""}`}
                  >
                    <Search isNavBar={true} user={user} />


                  </div>
                  {widthState < 1410 && !scrollState && (
                    <div className={`burger ${navbarOpen ? "d-none" : ""}`} onClick={handleToggle}>
                      {navbarOpen ? (
                        ""
                      ) : (
                        <>
                          <div className="line1"></div>
                          <div className="line2"></div>
                          <div className="line3"></div>
                        </>
                      )}
                    </div>
                  )}
                </>
              )}

            {
              widthState < 1315 && scrollState && (
                <>
                  <div style={{
                    display: "flex",
                    alignItems: "center"
                  }}
                  >
                    <div style={{
                      position: "absolute",
                      right: "6rem",
                      top: "2rem"
                    }}>
                      <ThemeProvider theme={theme}>
                        <GlobalStyle />
                        <LanguageSelector />
                      </ThemeProvider>
                    </div>
                    <div className={`burger2 ${navbarOpen ? "d-none" : ""}`} onClick={handleToggle}>
                      {navbarOpen ? (
                        ""
                      ) : (
                        <>
                          <div className="line1"></div>
                          <div className="line2"></div>
                          <div className="line3"></div>
                        </>
                      )}
                    </div>
                  </div>
                </>
              )
            }

            {
              (props.location.pathname !== "/partner" ||
                props.location.pathname !== "/partner-demo") && (
                <div
                  className={` ${navbarOpen ? "menuNav" : "collapse"
                    } ${((widthState > 1315 && scrollState) || (widthState > 1410 && !scrollState)) && "navbar-collapse"} ${navbarOpen && widthState >= 500 && widthState <= 800 ? "w-50" : "menuNavTablet"} ${widthState <= 500 && "w-100"}`}
                  id="navbarTogglerDemo01"
                >
                  {widthState < 1400 && navbarOpen && (
                    <li
                      onClick={() => closeMenu()}
                      exact
                      className={`${widthState > 1400 ? "d-none" : ""}`}
                    >
                      <i className="close icon mt-2 mr-2"></i>
                    </li>
                  )}
                  {navbarOpen && widthState < 1400 && (
                    <div
                      className={`mr-2 ml-2 w-75 mt-3 navbar-search navbar-search-responsive  ${navbarOpen && user.id ? "ml-2" : "ml-2"
                        }`}
                    >
                      <Search isNavBar={true} user={user} />
                    </div>
                  )}

                  <ul
                    className={`d-flex align-items-right navbar-nav m-0 ${navbarOpen && (user.id || !user.id) ? "ml-3 " : ""
                      }`}
                    style={{
                      alignItems: "center"
                    }}
                  >
                    {navbarOpen && !user.id && (
                      <>
                        <div
                          onClick={() => setLoginModal(true)}
                          className="menu-nav-lateral mb-1"
                        >
                          <Icon name="sign in" className="account menu-nav-lateral" />
                          {t("login.loginButton")}
                        </div>
                        <div
                          onClick={() => setRegisterModal(true)}
                          className="menu-nav-lateral mb-1"
                        >
                          <Icon name="signup" className="account menu-nav-lateral" />
                          {t("register.title")}
                        </div>
                        <Button
                          onClick={() => window.open("https://pro.henritrip.fr")}
                          className="btn-sm btn-primary mt-1 menu-nav-lateral"
                          style={{ width: "15rem", left: "40px" }}
                        >
                          {t("general.henriPro")}
                        </Button>

                        <hr
                          style={{
                            width: "100%",
                            marginLeft: "-7px",
                            color: "grey !important",
                          }}
                        />

                        <div
                          className=" col mt-1 dropdown-outils"
                          onClick={handleClickOutils}
                        >
                          <div className="menu-nav-lateral row">
                            <SvgIcon
                              component={QueryStatsIcon}
                              width="20"
                              height="20"
                            />

                            <div
                              className=" menu-nav-lateral nav-link mr-3 p-0"
                              style={{ color: "black", marginLeft: "4px" }}
                            >
                              {t("general.travelTools.title")}
                            </div>
                            <SVG
                              src="/assets/icons/angle-down-solid.svg"
                              width="10px"
                            />
                          </div>

                          {openOutils && (
                            <>
                              {options.map(({ name, travel_code, link }) => (
                                <div
                                  key={name}
                                  className={"menu-nav-lateral ml-0 mt-2 "}
                                  onClick={() => window.open(link)}
                                >
                                  <Icon className={"menu-nav-lateral"} name={travel_code} />
                                  {name}
                                </div>
                              ))}
                            </>
                          )}
                        </div>
                      </>
                    )}

                    {navbarOpen && user.id && (
                      <>
                        <div
                          onClick={() => { history.push("/user/account"); closeMenu() }}
                          className="menu-nav-lateral mb-1"
                        >
                          <Icon name="user outline" className="menu-nav-lateral account" />
                          {t("account.title")}
                        </div>

                        <div
                          onClick={() => setSuggestModalRT(true)}
                          className="menu-nav-lateral mb-1"
                        >
                          <Icon name="pencil alternate" className="menu-nav-lateral account" />
                          {t("general.createRoadTrip")}
                        </div>
                        <div
                          onClick={() => setSuggestModalXp(true)}
                          className="menu-nav-lateral mb-1"
                        >
                          <Icon name="pencil alternate" className="menu-nav-lateral account" />
                          {"Créer une Expérience"}
                        </div>

                        <div
                          onClick={() => setSuggestModal(true)}
                          className="menu-nav-lateral mb-1"
                        >
                          <Icon name="plus circle" className="menu-nav-lateral account" />
                          {t("general.suggestSite")}
                        </div>
                        <Button
                          onClick={() => window.open("https://pro.henritrip.fr")}
                          className="menu-nav-lateral btn-sm btn-primary w-50"
                          style={{ bottom: "67px", left: "40px" }}
                        >
                          {t("general.henriPro")}
                        </Button>

                        <hr
                          className="menu-nav-lateral w-100"
                          style={{
                            marginLeft: "-7px",
                            color: "grey !important",
                          }}
                        />

                        <div
                          onClick={() => { history.push("/user/roadtrips"); closeMenu() }}
                          className="menu-nav-lateral bold mt-1 mb-1"
                        >
                          <Icon name="map outline" className="account" />
                          {t("general.myRoadTrips")}
                        </div>

                        <div
                          className="menu-nav-lateral bold mb-1"
                          onClick={() => { history.push("/user/roadtrips/favorite"); closeMenu() }}
                        >
                          <Icon name="heart outline" className="menu-nav-lateral account" />
                          {t("general.myFavorite")}
                        </div>
                        <div
                          className="menu-nav-lateral bold mb-1"
                          onClick={() => { history.push("/user/appointments"); closeMenu() }}
                        >
                          <Icon name="calendar" className="menu-nav-lateral account" />
                          {t("general.myAppointments")}
                        </div>
                        <div
                          className="menu-nav-lateral bold mb-1"
                          onClick={() => { history.push("/user/roadtrips/orders"); closeMenu() }}
                        >
                          <Icon name="cart" className="menu-nav-lateral account" />
                          {t("roadTripPage.orders.title")}
                        </div>
                        <hr
                          className="w-100"
                          style={{

                            marginLeft: "-7px",
                            color: "grey !important",
                          }}
                        />

                        <div
                          className="col mt-1 mb-1 dropdown-outils"
                          onClick={handleClickOutils}
                        >
                          <div className="row menu-nav-lateral">
                            <SvgIcon
                              component={QueryStatsIcon}
                              width="20"
                              height="20"
                            />

                            <div
                              className="nav-link menu-nav-lateral mr-3 p-0"
                              style={{ color: "black", marginLeft: "4px" }}
                            >
                              {t("general.travelTools.title")}
                            </div>
                            <SVG
                              src="/assets/icons/angle-down-solid.svg"
                              width="10px"
                            />
                          </div>
                          {openOutils && (
                            <>
                              {options.map(({ name, travel_code, link }) => (
                                <Dropdown.Item
                                  key={name}
                                  className={"navbar-dropdown--item menu-nav-lateral "}
                                  onClick={() => window.open(link)}
                                >
                                  <Icon name={travel_code} className={"menu-nav-lateral"} />
                                  {name}
                                </Dropdown.Item>
                              ))}
                            </>
                          )}
                        </div>

                        <hr
                          className="w-100"
                          style={{
                            marginLeft: "-7px",
                            color: "grey !important",
                          }}
                        />

                        <div
                          className={"menu-nav-lateral mt-1 mb-3 black"}
                          onClick={() =>
                            history.push(
                              `/logout?redirectURL=${location.pathname}`
                            )
                          }
                        >
                          <Icon name="signup" className="account menu-nav-lateral" />
                          {t("general.buttonLogout")}
                        </div>
                      </>
                    )}

                    {navbarOpen === false && (
                      <>
                        {props.pages?.map((el) => (
                          <NavbarLinkPage key={el.id} {...el} />
                        ))}

                        <li
                          className={` ${!user.id && !navbarOpen
                            ? "nav-item-logout"
                            : "nav-item m-0"
                            } p-0`}
                          data-toggle={isMobile ? "collapse" : ""}
                          data-target="#navbarTogglerDemo02"
                          aria-controls="navbarTogglerDemo02"
                        >
                          <ThemeProvider theme={theme}>
                            <GlobalStyle />
                            <LanguageSelector />
                          </ThemeProvider>
                        </li>
                        <li
                          className={` ${!user.id ? "nav-item-logout" : "nav-item m-0"
                            } p-0`}
                          data-toggle={isMobile ? "collapse" : ""}
                          data-target="#navbarTogglerDemo01"
                          aria-controls="navbarTogglerDemo01"
                        >
                          <Dropdown>
                            <Dropdown.Toggle className="navbar-dropdown--toggle bg-transparent border-0 shadow-none">
                              <AccountCircle
                                className="navbar-dropdown--toggle__icon"
                                fontSize={"large"}
                                style={{
                                  width: "35px",
                                  height: "35px"
                                }}
                              />
                            </Dropdown.Toggle>
                            {props.hrefUser === "/login" ? (
                              <Dropdown.Menu className={"navbar-dropdown--menu"}>
                                <Dropdown.Item
                                  className={"navbar-dropdown--item"}
                                >
                                  <div onClick={() => setLoginModal(true)}>
                                    <Icon name="sign in" className="account" />{" "}
                                    {t("login.loginButton")}
                                  </div>
                                </Dropdown.Item>
                                <Dropdown.Item
                                  className={"navbar-dropdown--item"}
                                >
                                  <div onClick={() => setRegisterModal(true)}>
                                    <Icon name="signup" className="account" />
                                    {t("register.title")}
                                  </div>
                                </Dropdown.Item>
                              </Dropdown.Menu>
                            ) : (
                              <Dropdown.Menu className={"navbar-dropdown--menu"}>
                                <Dropdown.Item
                                  className={"navbar-dropdown--item"}
                                  onClick={() => history.push("/user/account")}
                                >
                                  <Icon name="user outline" className="account" />
                                  {t("account.title")}
                                </Dropdown.Item>
                                <Dropdown.Item
                                  className={"navbar-dropdown--item"}
                                >
                                  <div onClick={() => setSuggestModalRT(true)}>
                                    <Icon
                                      name="pencil alternate"
                                      className="account"
                                    />
                                    {t("general.createRoadTrip")}
                                  </div>
                                </Dropdown.Item>
                                <Dropdown.Item
                                  className={"navbar-dropdown--item"}
                                >
                                  <div
                                    onClick={() => setSuggestModalXp(true)}
                                    className="menu-nav-lateral mb-1"
                                  >
                                    <Icon name="pencil alternate" className="menu-nav-lateral account" />
                                    {"Créer une Expérience"}
                                  </div>
                                </Dropdown.Item>
                                <Dropdown.Item
                                  className={"navbar-dropdown--item"}
                                >
                                  <div onClick={() => setSuggestModal(true)}>
                                    <Icon
                                      name="plus circle"
                                      className="account"
                                    />
                                    {t("general.suggestSite")}
                                  </div>
                                </Dropdown.Item>
                                <hr className="m-0 hr" />

                                <Dropdown.Item
                                  className={"navbar-dropdown--item"}
                                >
                                  <div
                                    onClick={() =>
                                      history.push("/user/roadtrips")
                                    }
                                    className="bold"
                                  >
                                    <Icon
                                      name="map outline"
                                      className="account"
                                    />
                                    {t("general.myRoadTrips")}
                                  </div>
                                </Dropdown.Item>
                                <Dropdown.Item
                                  className={"navbar-dropdown--item"}
                                  onClick={() => history.push("/user/roadtrips/favorite")}
                                >
                                  <div className="bold">
                                    <Icon
                                      name="heart outline"
                                      className="account"
                                    />
                                    {t("general.myFavorite")}
                                  </div>
                                </Dropdown.Item>
                                <Dropdown.Item
                                  className={"navbar-dropdown--item"}
                                  onClick={() => history.push("/user/appointments")}
                                >
                                  <div className="bold">
                                    <Icon
                                      name="calendar"
                                      className="account"
                                    />
                                    {t("general.myAppointments")}
                                  </div>
                                </Dropdown.Item>
                                <Dropdown.Item
                                  className={"navbar-dropdown--item"}
                                  onClick={() => history.push("/user/roadtrips/orders")}
                                >
                                  <div className="bold">
                                    <Icon
                                      name="cart"
                                      className="account"
                                    />
                                    {t("roadTripPage.orders.title")}
                                  </div>
                                </Dropdown.Item>
                                <hr className="m-0 hr" />
                                {/*
                          <Dropdown.Item className={"navbar-dropdown--item"} onClick={()=>history.push("/user/conversations")}>Messages</Dropdown.Item>
                          <Dropdown.Item className={"navbar-dropdown--item"} onClick={()=>history.push("/user/downloads")}>Mes téléchargements</Dropdown.Item>*/}
                                <Dropdown.Item
                                  className={"navbar-dropdown--item"}
                                  onClick={() =>
                                    history.push(
                                      `/logout?redirectURL=${location.pathname}`
                                    )
                                  }
                                >
                                  <Icon name="signup" className="account" />
                                  {t("general.buttonLogout")}
                                </Dropdown.Item>
                              </Dropdown.Menu>
                            )}
                          </Dropdown>
                        </li>
                      </>
                    )}
                  </ul>
                </div>
              )
            }
          </div>
        </nav>
      </>
    )
  );
};

export default NavbarPage;