import React from 'react';
import Slider from 'react-slick';


const slickSettings = {
    infinite: true,
    slidesToShow: 6,
    slidesToScroll: 1,
    adaptiveHeight: true,
    draggable:true,
    swipeToSlide: true,

    responsive: [
        {
            breakpoint: 1400,
            settings: {
                slidesToShow: 4,
                slidesToScroll: 1,
            }
        },
        {
            breakpoint: 770,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 1
            }
        },
        {
            breakpoint: 380,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1
            }
        }
    ],
    arrows: false
}

const SlickSlider = ({ children, settings, slick, handleBeforeChange }) => {
    const allSettings = React.useMemo(() => {
        return {
            ...slickSettings,
            ...settings,
        }
    }, [settings])

    return (
        <Slider ref={slick} {...allSettings} beforeChange={handleBeforeChange}>
            {children}
        </Slider>
    );
};

export default SlickSlider;