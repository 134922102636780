import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import {ReactComponent as HenriIcon} from '../../../../assets/img/logo/Asset1.svg';
import {ReactComponent as UserIcon} from '../../../../assets/img/logo/user.svg';
import {ReactComponent as EmailIcon} from '../../../../assets/img/logo/email.svg';
import {ReactComponent as LockIcon} from '../../../../assets/img/logo/lock.svg';
import FacebookLoginButton from "../FacebookLoginButton";
import TextInput from "../FormElements/TextInput";
import {SubmitButton} from "../FormElements";
import useRequest from "../../../../hooks/use-request";
import {Formik} from "formik";
import {API_FACEBOOK_LOGIN, API_REGISTER} from "../../../../utils/api";
import {addToken} from "../../../../actions/token";
import {addUser} from "../../../../actions/user";
import {useDispatch} from "react-redux";
import SignUpForm from "./FormField/SignUpForm";
import SignUpInitialValues from "./FormField/SignUpInitialValues";
import SignUpValidationSchema from "./FormField/SignUpValidationSchema";
import Loader from "../Loader";

const {username, firstname, lastname, email, password, password_confirmation} = SignUpForm;

const IconWrapper = styled.div`
  display: grid;
  place-items: center;
  width: 40px;
  height: 40px;
  background-color: ${props => props.focus ? props.theme.colors.congressBlue20 : props.theme.colors.silver20};
  margin-right: 0.5rem;
  border-radius: 10px;
  transition: all 0.25s ease;

  svg {
    fill: ${props => !props.outline ?
            (props.focus ? props.theme.colors.congressBlue70 : 'none')
            : 'none'};
    stroke: ${props => props.focus ? props.theme.colors.congressBlue70 : props.theme.colors.doveGray};
    stroke-width: ${props => props.focus ? 0 : 2.19101};
    stroke-linecap: round;
    stroke-linejoin: round;
    transition: all 0.5s ease-in-out;
  }
`;
const LoginFormStyled = styled.form`
  width: 100%;
  padding: 2rem 1rem;
  display: flex;
  flex-direction: column;

  hr {
    width: 100%;
    margin-top: 1rem;
    margin-bottom: 1rem;
    border: 0;
    border-top: 3px solid rgba(0, 0, 0, 0.1);
  }

  > h1 {
    margin-bottom: 0;
  }

  p {
    text-align: center;
  }

  strong {
    font-weight: 900;
  }

  p:last-of-type {
    margin-top: 1rem;
  }

  > small {
    margin-top: 2rem;
  }
`;

const LoginHeaderStyled = styled.div`
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  text-align: center;
  color: ${props => props.theme.colors.primary};
  width: 95%;
  padding: 1rem 0;

  svg {
    width: 50px;
    margin-right: 1rem;
  }

  path {
    fill: ${props => props.theme.colors.primary};
  }
`;

const InputWrapperStyled = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  margin-top: 1rem;

  > div:last-of-type {
    flex: 1;
  }

  input {
    width: 100%;
  }
`;

const SubmitButtonWrapperStyled = styled.div`
  width: 100%;
  margin: 1rem 0;

  input {
    padding: 1rem;
    background-color: ${props => props.theme.colors.black80};
    color: ${props => props.theme.colors.white};
    width: 100%;
  }
`;
const SignUp = ({onLoginSucceeded = () => {}}) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();
  const [handleRequest] = useRequest();
  // Facebook Login
  const [fbLogin, setFbLogin] = useState(null);
  useEffect(() => {
    if (fbLogin) {
      handleFacebookSubmit(fbLogin);
    }
  }, [fbLogin]);

  const handleSubmit = (v) => {
    setLoading(true);
    handleRequest("post", API_REGISTER, {gender: 'Other', ...v}, {}, (data, status) => {
      if (status === "Done") {
        dispatch(addToken(data.token));
        const userData = {
          ...data.user,
          ...data.profile,
        };
        dispatch(addUser(userData));
        onLoginSucceeded(data);
        // setLoading(false);
      } else {
        setError(JSON.parse(data.text));
        setLoading(false);
      }
    });
  };

  const handleFacebookSubmit = (v) => {
    const auth = {
      provider: "facebook",
      uid: v.userID,
      data_access_expiration_time: v.data_access_expiration_time,
      code: v.signedRequest,
      token: v.accessToken,
      expires_at: v.expiresIn,
      expires: true
    };

    setLoading(true);
    handleRequest('post', API_FACEBOOK_LOGIN, auth, {}, (data, status) => {
      if (status === "Done") {
        if (data.errors) {
          setError(data.errors);
          setLoading(false);
          return;
        }
        dispatch(addToken(data.token));
        const userData = {
          ...data.user,
          ...data.profile,
        };
        dispatch(addUser(userData));
        onLoginSucceeded(data);
        // setLoading(false);
      }
    });
  };
  return (
    <Formik
      initialValues={SignUpInitialValues}
      validationSchema={SignUpValidationSchema}
      onSubmit={handleSubmit}
      validateOnMount={false}
      validateOnChange={false}
      validateOnBlur={false}
    >
      {({values, handleSubmit, ...props}) => {
        return (
          <LoginFormStyled onSubmit={handleSubmit}>
            <LoginHeaderStyled>
              <HenriIcon/>
              <h1>Bienvenue <br/> sur Henri Trip </h1>
            </LoginHeaderStyled>
            <h1>Créer un compte</h1>
            <hr/>
            <InputWrapperStyled>
              <IconWrapper
                focus={!!values[username.name]}
              >
                <UserIcon/>
              </IconWrapper>
              <TextInput
                {...username}
                {...props}
                value={values[username.name]}
                hasError={props.errors[username.name] || (error && error[username.name])}
                errorMessage={props.errors[username.name] || (error && error[username.name])}
                onChange={props.handleChange}
              />
            </InputWrapperStyled>
            <InputWrapperStyled>
              <IconWrapper
                focus={!!values[firstname.name]}
              >
                <UserIcon/>
              </IconWrapper>
              <TextInput
                {...firstname}
                {...props}
                value={values[firstname.name]}
                hasError={props.errors[firstname.name] || (error && error[firstname.name])}
                errorMessage={props.errors[firstname.name] || (error && error[firstname.name])}
                onChange={props.handleChange}
              />
            </InputWrapperStyled>
            <InputWrapperStyled>
              <IconWrapper
                focus={!!values[lastname.name]}
              >
                <UserIcon/>
              </IconWrapper>
              <TextInput
                {...lastname}
                {...props}
                value={values[lastname.name]}
                hasError={props.errors[lastname.name] || (error && error[lastname.name])}
                errorMessage={props.errors[lastname.name] || (error && error[lastname.name])}
                onChange={props.handleChange}
              />
            </InputWrapperStyled>
            <InputWrapperStyled>
              <IconWrapper
                focus={!!values[email.name]}
              >
                <EmailIcon/>
              </IconWrapper>
              <TextInput
                {...email}
                {...props}
                value={values[email.name]}
                hasError={props.errors[email.name] || (error && error[email.name])}
                errorMessage={props.errors[email.name] || (error && error[email.name])}
                onChange={props.handleChange}
              />
            </InputWrapperStyled>
            <InputWrapperStyled>
              <IconWrapper
                outline
                focus={!!values[password.name]}
              >
                <LockIcon/>
              </IconWrapper>
              <TextInput
                {...password}
                {...props}
                value={values[password.name]}
                hasError={props.errors[password.name] || (error && error[password.name])}
                errorMessage={props.errors[password.name] || (error && error[password.name])}
                onChange={props.handleChange}
              />
            </InputWrapperStyled>
            <small>Confirmation du mot de passe</small>
            <InputWrapperStyled>
              <IconWrapper
                outline
                focus={!!values[password_confirmation.name]}
              >
                <LockIcon/>
              </IconWrapper>
              <TextInput
                {...password_confirmation}
                {...props}
                value={values[password_confirmation.name]}
                hasError={props.errors[password_confirmation.name] || (error && error[password_confirmation.name])}
                errorMessage={props.errors[password_confirmation.name] || (error && error[password_confirmation.name])}
                onChange={props.handleChange}
              />
            </InputWrapperStyled>
            <SubmitButtonWrapperStyled>
              {
                loading
                  ? <Loader/>
                  : <SubmitButton value="Se connecter ➔"/>
              }
            </SubmitButtonWrapperStyled>
            <hr/>
            <p>En continuant avec Facebook, vous acceptez
              <a
                target="_blank"
                href="https://support.henritrip.fr/conditions-generales-dutilisation-du-site-internet-henri-trip-et-notifications/">
                <strong> les conditions générales d'utilisation </strong>
              </a>
              et la
              <a
                target="_blank"
                href="https://support.henritrip.fr/politique-de-confidentialite/">
                <strong> politique de confidentialité.</strong>
              </a>
            </p>
            <FacebookLoginButton
              onLoginSuccess={setFbLogin}
            />
          </LoginFormStyled>
        )
      }}
    </Formik>
  )
};

export default SignUp;