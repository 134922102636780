import React from "react";

export const ChatContext = React.createContext();

const ChatProvider = ({ url, children }) => {
  const [stateWs, setStateWS] = React.useState({});
  const conversationsRef = React.useRef([]);
  const [conversations, setConversations] = React.useState([]);
  const [authorized, setAuthorized] = React.useState(false);
  const senderType = React.useMemo(() => "user", [])

  React.useEffect(() => {
    if (stateWs.connected)
      return;

    const ws = new WebSocket(url);
    ws.onopen = () => {
      setStateWS(state => ({
        ...state,
        connected: true
      }));
      console.log("Connected to WebSocket");
    };

    ws.onclose = () => {
      setStateWS(state => ({
        ...state,
        connected: false
      }));
      console.log("WebSocket disconnected...");
    };

    ws.onerror = err => {
      setStateWS(state => ({
        ...state,
        connected: false,
        error: true,
        errorData: err
      }));
      console.log("WebSocket found an error",err);
      ws.close();
    };

    ws.onmessage = evt => {
      const message = JSON.parse(evt.data);
      let oldConvs;
      let convs;

      switch (message.type) {
        case "conversations":
          setConversations(JSON.parse(message.payload.content));
          conversationsRef.current = JSON.parse(message.payload.content);
          break;

        case "message":
          oldConvs = conversationsRef.current;
          convs = oldConvs.map(el => {
            if (el.id === message.conversation_id) {
              if (!el.unread) el.unread = [];
              return {
                ...el,
                messages: el.messages.concat(message),
                unread:
                  !el.unread?.includes(el.user_1_id) && message.sender_id !== el.user_1_id
                    ? [...el.unread, el.user_1_id]
                    : el.unread
              };
            }
            return el;
          });
          conversationsRef.current = convs;
          setConversations(convs);
          break;
        case "authentication":
          setAuthorized(true);
          break;
        case "read_conv":
          oldConvs = conversationsRef.current;
          convs = oldConvs.map(el => {
            if (el.id === message.conversation_id) {
              return {
                ...el,
                unread: el.unread?.filter(elUnread => elUnread !== el.user_1_id)
              };
            }
            return el;
          });
          conversationsRef.current = convs;
          setConversations(convs);
          break;
        default:
          return;
      }
    };

    setStateWS(state => ({ ...state, ws: ws }));
    // eslint-disable-next-line
  }, [url]);

  return (
    <ChatContext.Provider
      value={{
        ...stateWs,
        conversations: conversations,
        authorized: authorized,
        sender_type: senderType
      }}
    >
      {children}
    </ChatContext.Provider>
  );
};

export default ChatProvider;
