export const addUser = (payload) => ({
    type: 'ADD_USER',
    payload
})

export const removeUser = () => ({
    type: 'REMOVE_USER'
})

export const addCoords = (payload) => ({
    type: 'ADD_COORDS',
    payload
})

export const removeCoords = () => ({
    type: 'REMOVE_COORDS'
})

export const updateUser = payload => ({
    type: 'MODIFY_USER',
    payload
})