import UpdateContainer from "components/informations/update-container/update-container";
import React, { useState } from "react";
import { Form } from "semantic-ui-react";
import AutocompletePlaceAddress from "components/search/autocompletesearchaddressonly";
import moment from "moment";
import useDocumentTitle from 'hooks/use-document-title';
import { useTranslation } from "react-i18next";


const InformationPage = ({user, userForm, handleSubmit}) => {
  const {t} = useTranslation(["henriTrip"])

  const genderOptions = [
    { text: t("general.male"), value: "Male" },
    { text: t("general.female"), value: "Female" },
    { text: t("general.other"), value: "Other" },
  ];

  const convertDate = (date) =>
    date.getFullYear() + '-'
    + String(date.getMonth() + 1).padStart(2, '0') + '-'
    + String(date.getDate()).padStart(2,'0');



  useDocumentTitle(t("account.personalInfoPage.documentTitle"));

  const [userInfo, setUserInfo] = useState({
    firstname: user.firstname,
    lastname: user.lastname,
    birthdate: user.birthdate,
    gender: user.gender,
  });
  // const [userInfo, setUserInfo] = useState(user)
  const [address, setAddress] = useState({
    address : user.address
  });
  // eslint-disable-next-line
  const [state, setState] = useState();
  const [correct, setCorrect] = useState({
    firstname : true,
    lastname : true,
    birthdate : true,
  });

  const setChange = (e, { name, value }) => {
    setUserInfo({...userInfo, [name]: value });
  };

  const checkValidChanges = (e) =>{
    switch (e) {
      case "nom":
        const firstnameCorrect = userInfo.firstname.match(/^[A-z À-ú]+$/);
        const lastnameCorrect = userInfo.lastname.match(/^[A-z À-ú]+$/);
        setCorrect({
          ...correct,
          firstname : firstnameCorrect,
          lastname : lastnameCorrect
        })
        if (firstnameCorrect && lastnameCorrect){
          return {
            firstname: userInfo.firstname,
            lastname: userInfo.lastname,
          }
        }
        else{
          return false
        }
      case "age":
        const today = new Date();
        const minDate = new Date(1900,1,1);
        const birthdateCorrect = !(userInfo.birthdate < convertDate(minDate) || userInfo.birthdate > convertDate(today))
        setCorrect({
          ...correct,
          birthdate : birthdateCorrect,
        })
        if (birthdateCorrect){
          return {
            birthdate: userInfo.birthdate,
          }
        }
        else{
          return false
        }
      case "genre":
        return {gender: userInfo.gender}
      case "address":
        return {address: address.address}
      default:
        return false
    }
  }

  const updateChanges = (e) =>{
    let res = checkValidChanges(e)
    if (res){
      handleSubmit(res)
      return true
    }
    return false
  }

  const onSelectAddress = (address) => {
    setAddress({
      address: address.place_name,
      buttonAddressDisabled: false,
    });
    setUserInfo({...userInfo, address: address.place_name })
  };

  const checkAddress = (b) => {
    setAddress({
      buttonAddressDisabled: b,
    });
  };

  const onClearAddress = () => {
    setAddress({
      address: null,
      locationIsLoading: false,
      buttonAddressDisabled: true,
    });
  };

  return (
    <div className="container">
      <div className="col-lg-8 offset-md-1 mt-4">
        <div className="row col-12 mt-3">
          <h1>{t("general.personnalInfo")}</h1>
        </div>
        <UpdateContainer
          titre={t("general.name")}
          sousTitre={
            user.firstname + " " + user.lastname
          }
          description={t("account.personalInfoPage.name.subtitle")}
          handleSubmit={() => updateChanges("nom")}
        >
          <div>
            <div className="col-12">
              <Form>
                <Form.Group className="mt-3" widths="equal">
                  {correct.firstname ? (
                  <Form.Input
                    onChange={setChange}
                    name="firstname"
                    value={userInfo.firstname}
                    label={t("general.firstName")}
                    placeholder={user.firstname}
                  />
                  ) : (
                  <Form.Input
                    onChange={setChange}
                    name="firstname"
                    value={userInfo.firstname}
                    label={t("general.firstName")}
                    placeholder={user.firstname}
                    error={{
                      content: t("account.personalInfoPage.name.errorFirstName"),
                      pointing: "above",
                    }}
                  />

                  )}
                  {correct.lastname ? (
                  <Form.Input
                    onChange={setChange}
                    name="lastname"
                    value={userInfo.lastname}
                    label={t("general.name")}
                    placeholder={user.lastname}
                  />
                  ) : (
                    <Form.Input
                      onChange={setChange}
                      name="lastname"
                      value={userInfo.lastname}
                      label={t("general.name")}
                      placeholder={user.lastname}
                      error={{
                        content: t("account.personalInfoPage.name.errorLastName"),
                        pointing: "above",
                      }}
                    />
                    )}
                </Form.Group>
              </Form>
            </div>
          </div>
        </UpdateContainer>
        <hr />
        <UpdateContainer
          titre={t("general.gender")}
          sousTitre={user.gender}
          handleSubmit={() => updateChanges("genre")}
        >
          <div className="col-6">
            <Form.Group>
              <Form.Select
                onChange={setChange}
                name="gender"
                value={userInfo.gender}
                options={genderOptions}
                placeholder={user.lastname}
              />
            </Form.Group>
          </div>
        </UpdateContainer>
        <hr />
        <UpdateContainer
          titre={t("general.birthdate")}
          sousTitre={moment(user.birthdate).format("DD/MM/YYYY")}
          handleSubmit={() => updateChanges("age")}
        >
          <div className="col-12">
            <Form>
              {correct.birthdate ? (
                <Form.Input
                  onChange={setChange}
                  type="date"
                  name="birthdate"
                  value={userInfo.birthdate}
                />
              ) : (
                <Form.Input
                  onChange={setChange}
                  type="date"
                  name="birthdate"
                  value={userInfo.birthdate}
                  error={{
                    content: t("account.personalInfoPage.birthdate.error"),
                    pointing: "above",
                  }}
                />
              )}
            </Form>
          </div>
        </UpdateContainer>
        <hr />
        {/* <UpdateContainer titre="Numéro de téléphone" sousTitre="Non fournie" description="Pour recevoir des notifications, des rappels et de l'aide pour vous connecter">
            <div className="col-12">
                <Form className="mt-2">
                    <Form.Input  placeholder='E-mail' />
                </Form>
                <div className="mt-2">
                    <Button className="col-3" type='submit'>Enregistrer un nouveau numéro</Button>
                </div>
            </div>
        </UpdateContainer>
        <hr/>
        ///
        ///Pas de téléphone dans la base de données
        ///
        */}
        <UpdateContainer
          titre={t("general.address")}
          sousTitre={user.address}
          description={t("account.personalInfoPage.address.subtitle")}
          handleSubmit={() => updateChanges("address")}
          buttonDisabled={false}
        >
          <div className="col-12 information_page">
            <Form className="mt-3">
              <div>
                <AutocompletePlaceAddress
                  name={t("general.address")}
                  placeholder="Ex : 45 Rue Alfred Dumeril, 31000 Toulouse"
                  onSelect={onSelectAddress}
                  onClear={onClearAddress}
                  onFocusInp={() => setState({ focus: true })}
                  onBlurInp={() => setState({ focus: true })}
                  isAddressComplete={checkAddress}
                />
              </div>
            </Form>
          </div>
        </UpdateContainer>
        <hr />
      </div>
    </div>
  );

}
export default InformationPage;
