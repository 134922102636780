import Title from 'components/header/title/title';
import Chat from 'containers/User/Chat/ChatMessages/Chat';
import React from 'react';
import { useSelector } from 'react-redux';
import { Link, Route, Switch, useLocation } from 'react-router-dom';
import useDocumentTitle from 'hooks/use-document-title';

import "./conversations-page.scss"

const ConversationsPage = ({ conversations }) => {
    useDocumentTitle(`Conversations • Henri Trip`)
    const location = useLocation();
    const {user} = useSelector(state => state.user)

    return (
        <div className="row">
            <div className={`col-12 col-lg-4 ${location.pathname.replace("/user/conversations/", "") !== "" ? "d-none" : "d-block"} d-lg-block`}>
                <Title title="Mes conversations" />
                <ul className="list-group pt-2 mt-1" style={{maxHeight: "50vh", overflow: "scroll"}}>
                    {
                        conversations?.length ?
                        conversations.map((el, i) => {
                            return <Link className="conversation-list-item" to={`/user/conversations/${el.id}`}><li className={`list-group-item ${location.pathname.replace("/user/conversations/", "") === el.id ? "active" : ""} d-flex justify-content-between align-items-center`}><span>{el.user2?.firstname} {el.user2?.lastname}</span> {el.unread?.includes(user?.id) && <span className="badge badge-pill badge-primary p-1"> </span>}</li></Link>
                        })
                        :
                        <p>Vous n'avez pas de conversations actives, dès que vous contacterez un site touristique, la conversation sera affichée ici.</p>
                    }
                </ul>
            </div>
            <div className={`col-12 col-lg-8 pt-lg-5 pt-3 ${location.pathname.replace("/user/conversations/", "") !== "" ? "d-block" : "d-none"} d-lg-block`}>
                <Switch>
                    <Route path="/user/conversations/:id" component={Chat} exact />
                    <Route>
                        <div className="h-100 d-flex align-items-center justify-content-center">
                            <h3 className="text-muted">Choisissez une conversation afin d'en voir le contenu.</h3>
                        </div>
                    </Route>
                </Switch>
            </div>
        </div>
    );
};

export default ConversationsPage;