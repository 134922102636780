import React, {useContext, useState} from 'react';
import styled, {ThemeContext} from 'styled-components';
import {API_ORIGINAL_BLOB_URL} from '../../../../utils/api';
import FileInput from '../../../../_ui/_v2/components/FormElements/FileInput';
import Loader from '../../../../_ui/_v2/components/Loader';
import TriangleIcon from "../../../../_forms/_components/avpm/_commons/TriangleIcon";
import { ReactComponent as AddImage } from '../../../../assets/img/logo/add_image.svg';
import { ReactComponent as AddIcon } from '../../../../assets/img/logo/addIcon.svg';
import { ReactComponent as DeleteIcon } from '../../../../assets/img/logo/delete.svg';

const FileUploadContainer = styled.div`
  position: relative;
  box-sizing: border-box;
  box-shadow: 0 10px 13px -7px #000000, 16px 16px 23px -2px rgb(0 0 0 / 17%);
  width: 14rem;
  height: 100%;
  background-image: ${props => props.backgroundImageUrl ? `url('${props.backgroundImageUrl}')` : 'none'};
  background: ${props => props.backgroundImageUrl ? '' : 'linear-gradient(203.51deg, rgba(255, 255, 255, 0.3) 1.43%, rgba(255, 255, 255, 0.045) 103.65%)'};  
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  border-radius: 10px;
  ${props => !props.backgroundImageUrl && `
     border: 0.669205px solid #FFFFFF;
  `}
`;
const ContainerStyled = styled.div `
  border: none;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const SVGContainer = styled.div `
  border-radius: 50%;
  border: 1px white solid;
  padding: 1em;
  width: fit-content;
`;

const CardImageEditable = ({isLast, isFirst, onChange, value, name, media, onDelete, onMoveRight, onMoveLeft, editable = false, activeStorageServiceName, ...rest}) => {
  const [previewUrl, setPreviewUrl] = useState();
  const theme = useContext(ThemeContext);
  return (
    <>
    <FileUploadContainer
      backgroundImageUrl={previewUrl || (media?.id ? API_ORIGINAL_BLOB_URL(media.signed_id, media.filename) : '')}
      {...rest}
    >
      <FileInput
        name={name}
        accept="image/*"
        activeStorageServiceName={activeStorageServiceName}
        onBlobUrlChange={setPreviewUrl}
        value={value}
        onChange={(event) => {
          if (event.target.value !== value) {
            onChange?.(event);
          }
        }}
        style={{margin: 'auto', height: '300px', display: 'flex', alignItems: 'center', justifyContent: 'center'}}
      >
        {
          ({ready, globalProgress}) => (
            editable && <ContainerStyled
              disabled={!ready}
              progress={globalProgress}
              // hasError={errors.medias?.[firstIndexWithImage]?.signed_id && touched.medias?.[firstIndexWithImage]?.signed_id}
            >
              {ready
                ? (
                  value
                    ? (
                      <>
                        <SVGContainer><AddImage height='30' width='30'/></SVGContainer>
                        {"Modifier l'image"}
                      </>
                    )
                    : <AddIcon height='90' width='90'/>
                ) : (
                  <>
                    <Loader
                      size="20px"
                      style={{display: 'inline', margin: '0 auto'}}
                    />
                    {'Upload en cours'}
                  </>
                )
              }
            </ContainerStyled>
          )
        }
      </FileInput>
      {
        onDelete && editable && (
            <span
              onClick={onDelete}
            >
              <DeleteIcon
                color={theme.colors.danger}
                style={{
                  position: 'absolute',
                  bottom: '1em',
                  right: '1em',
                  width: '2em',
                  height: '2em',
                }}
              />
            </span>
        )
      }
    </FileUploadContainer>
    {onMoveRight && onMoveLeft && (
        <>
          {isLast &&
            <TriangleIcon
              style={
                {
                  rotate: "90deg",
                  display: "block",
                  position: "flex",
                  bottom: "-50px",
                  left: "60%"
                }
              }
              onClick={onMoveRight}
            />
          }
          {isFirst && 
            <TriangleIcon
              style={
                {
                  rotate: "270deg",
                  display: "block",
                  position: "flex",
                  bottom: "-50px",
                  left: "30%"
                }
              }
              onClick={onMoveLeft}
            />
          }
      </>
      )}
    </>
  );
};

export default CardImageEditable;