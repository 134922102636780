import ConversationsPage from 'pages/UserPage/ChatPage/ConversationsPage';
import React from 'react';
import useChat from './hooks/useChat';

const Conversations = () => {
    const [stateWS] = useChat();

    React.useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <ConversationsPage conversations={stateWS.conversations} />
    );
};

export default Conversations;