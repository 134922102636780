import RoadTripPremiumPage from 'pages/RoadTripsPage/RoadTripPremiumPage/RoadTripPremiumPage';
import useRequest from 'hooks/use-request';
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { API_GET_ROADTRIP_PREMIUM } from "utils/api";
import { useHistory } from 'react-router-dom';
import _ from "underscore";
import { transportsId, groupsId, seasonsId } from "utils/utils"
import { Facebook } from "../../../components/messengerChat/Facebook";
import {mediasTyped} from "../../../utils/media";

const RoadTripPremium = ({ match }) => {
  const [handleRequest, status] = useRequest();
  const config = useSelector(state => state.data);
  const { user } = useSelector(state => state.user);
  const [roadtrip, setRoadtrip] = useState({});
  const [roadTripPremium, setRoadTripPremium] = useState({});
  const [rtTs, setRtTs] = useState([]);
  const history = useHistory();

  useEffect(() => {
    // a modifier 
    handleRequest("get", API_GET_ROADTRIP_PREMIUM(match.params.id), {}, config, (response, status) => {
      if (status === "Done") {
        const data = { ...response.data };
        setRoadTripPremium(response.data_premium)
        let categoriesTransport = [];
        let categoriesGroup = [];
        let categoriesSeasons = [];

        data?.categories.forEach(el => {
          if (transportsId.includes(el.id)) {
            categoriesTransport.push(el)
          }
        })

        data?.categories.forEach(el => {
          if (groupsId.includes(el.id)) {
            categoriesGroup.push(el)
          }
        })

        data?.categories.forEach(el => {
          if (seasonsId.includes(el.id)) {
            categoriesSeasons.push(el)
          }
        })
        setRoadtrip(prev => {
          return {
            ...prev,
            ...data,
            suggestions: [],
            feedbacks: {
              note: data.avg_notes,
              comments: data.road_trip_reviews,
              len: data.notes_counter
            },
            user: {
              firstname: data.user.user_profile.firstname,
              lastname: data.user.user_profile.lastname,
              username: data.user.username,
            },
            categoriesTransport: categoriesTransport,
            categoriesGroup: categoriesGroup,
            categoriesSeasons: categoriesSeasons,
          }
        })
        const parsedTouristicSite = siteParser(data)
        const custom_site = custom_siteParser(data)
        setRtTs([...parsedTouristicSite.sort((a, b) => a.rank - b.rank), ...custom_site.sort((a, b) => a.rank - b.rank)].sort((a, b) => a.rank - b.rank))
      } else if (response.status === 404) {
        history.push('/error/404')
      } else {
        console.error(response)
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const siteParser = (data) => {
    const trip_has_sites_group_by_id = _.groupBy(data.road_trip_has_touristic_sites, "touristic_site_id")
    return data.touristic_sites.map((el, i) => {
      el.henri_certified = !!el.agency_id
      el.category = el.categories[0];
      el.image = mediasTyped(el.medias).image?.[0]?.url || undefined
      el.feedbacks = {
        note: el.avg_note,
        len: el.notes_counter
      }

      el.rank = trip_has_sites_group_by_id[el.id][0].rank
      el.day_number = trip_has_sites_group_by_id[el.id][0].day_number
      trip_has_sites_group_by_id[el.id] = [...trip_has_sites_group_by_id[el.id].slice(1)]
      return el;
    })
  }

  const custom_siteParser = (data) => {
    const custom_trip_has_sites_group_by_id = _.groupBy(data.road_trip_has_touristic_sites, "custom_touristic_site_id")
    return data.custom_touristic_sites.map((el, i) => {
      el.henri_certified = !!el.agency_id
      el.custom = true
      el.category = { color: "#FFEE00" };
      el.image = mediasTyped(el.medias).image?.[0]?.url || undefined
      el.feedbacks = {
        note: el.avg_note,
        len: el.notes_counter
      }

      el.rank = custom_trip_has_sites_group_by_id[el.id][0].rank
      el.day_number = custom_trip_has_sites_group_by_id[el.id][0].day_number
      custom_trip_has_sites_group_by_id[el.id] = [...custom_trip_has_sites_group_by_id[el.id].slice(1)]
      return el;
    })
  }


  return (
    <>
      <Facebook />
      <RoadTripPremiumPage
        loading={status !== "Done"}
        roadtrip={roadtrip}
        rtTs={rtTs}
        isUserRT={user?.user_id === roadtrip?.user_id}
        roadTripPremium={roadTripPremium}
      />
    </>
  )
};

export default RoadTripPremium;
