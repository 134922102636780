import { addRoadTrips } from 'actions/road-trips';
import useRequest from 'hooks/use-request';
import { RoadTripsPage } from 'pages/RoadTripsPage';
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { StringParam, NumberParam, useQueryParams } from 'use-query-params';
import { API_GET_ROADTRIPS_FILTERS } from 'utils/api';
import { transportsId, groupsId, seasonsId } from "utils/utils"

const RoadTrips = () => {

//  const [maxResults, setMaxResults] = useQueryParams("max_results", NumberParam)
  const [sortQuery, setSortQuery] = useQueryParams({
    number_days: StringParam,
    latitude: NumberParam,
    longitude: NumberParam,
    distance: NumberParam,
    sort_type: StringParam,
    sort_direction: StringParam,
    title: StringParam,
    current_page: NumberParam,
    category: StringParam,
    category_roadtrip: StringParam,
    roadtrip_type: StringParam
  });

  const location = useLocation();
  const dispatch = useDispatch();
  const {user} = useSelector(state => state.user);
  const {roadtrips} = useSelector(state => state.roadtrips);
  const [numberPages, setNumberPages] = useState(0)
  const [handleRequest, status] = useRequest()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    handleRequest("get", API_GET_ROADTRIPS_FILTERS(sortQuery), {}, {}, (data, status) => {

      if (status === "Done") {
        const parsedRTs = data.road_trips.map((el, i) => {
          let categoriesTransport = [];
          let categoriesGroup = [];
          let categoriesSeasons = [];

          el.categoriesTransport= categoriesTransport
          el.categoriesGroup= categoriesGroup
          el.categoriesSeasons = categoriesSeasons

          el.categories.forEach(el =>
          {
            if(transportsId.includes(el.id)){
              categoriesTransport.push(el)
            }
          })

          el.categories.forEach(el =>
          {
            if(groupsId.includes(el.id)){
              categoriesGroup.push(el)
            }
          })

          el.categories.forEach(el =>
          {
            if(seasonsId.includes(el.id)){
              categoriesSeasons.push(el)
            }
          })


          el.feedbacks = {
            note: el.avg_notes || 0,
            len: el.notes_counter || 0,
          }
          el.user = {
            firstname: el.user?.user_profile?.firstname,
            lastname: el.user?.user_profile?.lastname,
          }
          return el;
        });

        dispatch(addRoadTrips(parsedRTs))

        if (numberPages !== data.count) {
          setNumberPages(data.count);
        }
      } else if(status === "Error") {
        console.error(data);
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortQuery])
  const handleSetQueryParams = ({
    number_days = undefined,
    latitude = undefined,
    longitude = undefined,
    distance = undefined,
    sort_type = undefined,
    sort_direction = undefined,
    title = undefined,
    current_page = undefined,
    category = undefined,
    category_roadtrip = undefined,
    roadtrip_type = undefined
  }) => {

    setSortQuery({
      number_days: number_days,
      latitude: latitude,
      longitude: longitude,
      distance: distance,
      sort_type: sort_type,
      sort_direction: sort_direction,
      title: title,
      current_page: current_page,
      category: category,
      category_roadtrip: category_roadtrip,
      roadtrip_type: roadtrip_type
    });
  }


  return (
    <RoadTripsPage
      user={user}
      location={location}
      loading={status === "Loading"}
      numberPages={numberPages}
      roadtrips={roadtrips}
      handleQueryParams={handleSetQueryParams}
      sortQuery={sortQuery}
    />
  );
};

export default RoadTrips;
