import RoadTripPage from "pages/RoadTripsPage/RoadTripPage/RoadTripPage";
import useRequest from "hooks/use-request";
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import {
  API_GET_ROADTRIP,
  API_GET_ROADTRIP_SUGGESTIONS,
  API_GET_ROADTRIP_PASSWORD,
} from "utils/api";
import { useHistory, useRouteMatch } from "react-router-dom";
import _ from "underscore";
import { transportsId, groupsId, seasonsId } from "utils/utils";
import RTModal from "../../../components/formModal/rtForm/RTform";
import { Modal } from "semantic-ui-react";
import {mediasTyped} from "../../../utils/media";

const RoadTrip = ({ match }) => {
  const [handleRequest, status] = useRequest();
  const config = useSelector((state) => state.data);
  const { user } = useSelector((state) => state.user);
  const [roadtrip, setRoadtrip] = useState({});
  const [rtTs, setRtTs] = useState([]);
  const history = useHistory();
  const matchRoute = useRouteMatch();
  const [enterPassword, setEnterPassword] = useState(false);
  const [checkedPassword, setCheckedPassword] = useState(false);
  const [rtUpdate, setRtUpdate] = useState(0);

  useEffect(() => {
    window.scrollTo(0, 0);
    handleRequest(
      "get",
      API_GET_ROADTRIP_SUGGESTIONS(match.params.id),
      {},
      config,
      (data, status) => {
        if (status === "Done") {
          if (data) {
            const newData = data.map((el) => {
              el.henri_certified = !!el.agency_id;
              el.category = el.categories[0];
              el.password = el.password || "";
              el.image = mediasTyped(el.medias).image?.[0]?.url || undefined;
              el.feedbacks = {
                note: el.avg_note,
                len: el.notes_counter,
              };
              return el;
            });
            setRoadtrip((prev) => {
              return {
                ...prev,
                suggestions: newData || [],
              };
            });
          }
        } else {
          console.error(data);
        }
      }
    );
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    handleRequest(
      "get",
      API_GET_ROADTRIP(match.params.id),
      {},
      config,
      (dataAPI, status) => {
        if (status === "Done") {
          const data = { ...dataAPI };
          let categoriesTransport = [];
          let categoriesGroup = [];
          let categoriesSeasons = [];

          data?.categories.forEach((el) => {
            if (transportsId.includes(el.id)) {
              categoriesTransport.push(el);
            }
          });

          data?.categories.forEach((el) => {
            if (groupsId.includes(el.id)) {
              categoriesGroup.push(el);
            }
          });

          data?.categories.forEach((el) => {
            if (seasonsId.includes(el.id)) {
              categoriesSeasons.push(el);
            }
          });
          const items = localStorage.getItem("allow_rt");

          if (items) {
            const arr = JSON.parse(items);
            if (arr.includes(match.params.id)) {
              setCheckedPassword(true);
              setEnterPassword(false);
            } else {
              setEnterPassword(data.password && !data.public);
            }
          } else {
            setEnterPassword(data.password && !data.public);
          }
          const parsedTouristicSite = siteParser(data);
          setRtTs(
            [
              ...parsedTouristicSite.sort((a, b) => a.rank - b.rank),
            ]
          );
          setRoadtrip((prev) => {
            return {
              ...prev,
              ...data,
              feedbacks: {
                note: data.avg_notes,
                comments: data.road_trip_reviews,
                len: data.notes_counter,
              },
              user: {
                firstname: data.user.user_profile.firstname,
                lastname: data.user.user_profile.lastname,
                username: data.user.username,
              },
              categoriesTransport: categoriesTransport,
              categoriesGroup: categoriesGroup,
              categoriesSeasons: categoriesSeasons,
            };
          });
        } else if (dataAPI.status === 404) {
          history.push("/error/404");
        } else {
          console.error(dataAPI);
        }
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps

  }, [rtUpdate, match.params.id])

  const checkPassword = (v, formikBag) => {
    handleRequest(
      "post",
      API_GET_ROADTRIP_PASSWORD(match.params.id),
      v,
      config,
      (data, status) => {
        if (status === "Done") {
          const items = JSON.parse(localStorage.getItem("allow_rt"));
          if (items) {
            items.push(match.params.id);
            localStorage.setItem("allow_rt", JSON.stringify(items));
          } else {
            localStorage.setItem("allow_rt", JSON.stringify([match.params.id]));
          }
          setCheckedPassword(true);
          setEnterPassword(false);
        } else {
          setCheckedPassword(false);
          history.push("/error/404");
        }
      }
    );
  };

  const siteParser = (data) => {
    const trip_has_sites_group_by_id = _.groupBy(
      data.road_trip_has_touristic_sites,
      "touristic_site_id"
    );
    return data.touristic_sites.map((el, i) => {
      el.custom = !!el.provider
      el.henri_certified = !!el.agency_id;
      el.category = el.categories[0];
      el.image = mediasTyped(el.medias).image?.[0]?.url || undefined;
      el.feedbacks = {
        note: el.avg_note,
        len: el.notes_counter,
      };

      el.rank = trip_has_sites_group_by_id[el.id][0].rank;
      el.day_number = trip_has_sites_group_by_id[el.id][0].day_number;
      trip_has_sites_group_by_id[el.id] = [
        ...trip_has_sites_group_by_id[el.id].slice(1),
      ];
      return el;
    });
  };

  const handleModifyRT = () => {
    setRtUpdate(rtUpdate + 1);
  };

  return enterPassword && !(user?.user_id === roadtrip?.user_id) ? (
    <Modal size={"tiny"} open={enterPassword}>
      <RTModal handleSubmit={checkPassword} />
    </Modal>
  ) : (
    <RoadTripPage
      loading={status !== "Done"}
      roadtrip={roadtrip}
      rtTs={rtTs}
      isUserRT={user?.user_id === roadtrip?.user_id}
      matchRoute={matchRoute}
      handleModifyRT={handleModifyRT}
      enterPassword={enterPassword}
      checkedPassword={checkedPassword}
    />
  );
};

export default RoadTrip;
