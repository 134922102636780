import { removeToken } from 'actions/token';
import { removeUser } from 'actions/user';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {useLocation} from "react-router";

const Logout = () => {
    const dispatch = useDispatch();
    const history = useHistory()
    const query = new URLSearchParams(useLocation().search)

    React.useEffect(() => {
        dispatch(removeUser());
        dispatch(removeToken());
        history.push(`/login?redirectURL=${query.get("redirectURL")}`)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return null;
};

export default Logout;