import React from 'react';
import Slider from 'react-slick';

import "./slider.scss";

const slickSettings = {
    dots: false,
    infinite: false,
    slidesToShow: 3,
    slidesToScroll: 1,
    adaptiveHeight: true,
    draggable:true,
    swipeToSlide: true,

    responsive: [
        {
            breakpoint: 1600,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 1,
                adaptiveHeight: true,
            }
        },
        {
            breakpoint: 1250,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                adaptiveHeight: true,
            }
        },
        {
            breakpoint: 767,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                adaptiveHeight: true,
            }
        },
        {
            breakpoint: 480,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                adaptiveHeight: true,
            }
        }
    ],
    arrows: false
}

const SlickSlider = ({ children, settings, responsive, slick, handleBeforeChange }) => {
    const allSettings = React.useMemo(() => {
        return {
            ...slickSettings,
            ...settings,
        }
    }, [settings])

    return (
        <Slider ref={slick} {...allSettings} beforeChange={handleBeforeChange}>
            {children}
        </Slider>
    );
};

export default SlickSlider;