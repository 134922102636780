
import React, { useRef, useEffect, useState } from "react";
import MapGL, { GeolocateControl, NavigationControl, Layer, Marker, Source } from "react-map-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import _ from "underscore";
import { Button, Icon, Dropdown } from 'semantic-ui-react';
import { PHONE_WIDTH } from '../../../utils/utils';
import './experienceMap.scss';
import { useTranslation } from "react-i18next";
import ModalForm from "../../../components/formModal/ModalForm";
import Login from "../../../containers/Auth/Login";
import RoadTripPopupPremium from "../../../components/roadTrips/roadTripMapPopUp/RoadTripMapPopUp"
import { ReactComponent as PineMarker } from "../../../../src/assets/img/logo/pine_marker.svg";
import { ReactComponent as PineSelect } from "../../../../src/assets/img/logo/Pine_select.svg";
import { ReactComponent as Zoom } from "../../../../src/assets/img/logo/icon_zoom.svg";
import { ReactComponent as Dezoom } from "../../../../src/assets/img/logo/icon_dezoom.svg";
import { ReactComponent as FullscreenIcon } from "../../../../src/assets/img/logo/fullscreen-icon.svg";
import categories from "../../../utils/categories"

const filterControlStyle = {
  width: 210,
  top: 40,
  left: 120,
  background: 'white',
  transform: "scale(1.25)",
  position: 'absolute',
  zIndex: 1

};

const filterCloseStyle = {
  width: 40,
  height: 40,
  position: 'absolute',
  top: 100,
  left: 20,
  background: 'white',
  zIndex: 1,
  border: '1px black solid',
  borderRadius: '1rem'
}

const filterLoopStyle = {
  width: 40,
  height: 40,
  position: 'absolute',
  bottom: '10%',
  right: '3%',
  background: 'rgba(0, 0, 0, 0.4)',
  zIndex: 10,
  borderRadius: '0.5rem',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
}


const filterCloseStyleDekstop = {
  width: 40,
  height: 40,
  position: 'absolute',
  top: 0,
  left: 20,
  background: 'white',
  zIndex: 1
}

const numberMarkerStyle = {
  zIndex: 1,
  color: 'white',
  top: '5px',
  left: '10px'
};

const numberMarkerSelectedStyled = {
  top: '7px',
  right: '21px'
};


const ExperienceMap = ({setActivityModalIsOpen, onMapClick, mapModalIsOpen, setMapModalIsOpen, openMap, btndisable, rtTs, handlePurchase, setSelectedPin, scrollable, selectedPin }) => {
  const [widthState, setWidthState] = useState(window.innerWidth);
  const [dataFiltered, setDataFiltered] = useState(rtTs[0]);
  const sites = _.groupBy(rtTs, "day_number");
  const days = Object.keys(sites);
  const ref = useRef();
  const [openMapFull, setOpenMapFull] = useState(false);
  const [popup, setPopup] = useState(null);
  const [loop, setLoop] = useState(false);
  const [selectedMarker, setSelectedMarker] = useState();

  const _onClick = (event, datum) => {
    setLoop(true);
    setSelectedMarker(datum.id);
    setViewport({
      latitude: datum.latitude,
      longitude: datum.longitude,
      zoom: 15,
      bearing: 0,
      pitch: 0,
    })
    event.stopPropagation();
    if(mapModalIsOpen){
      setPopup(datum)
    }
  };


  const ClickAway = () => {
    setPopup(null);
    setSelectedMarker();
    setLoop(false);

  }

  const [source, setSource] = useState([{}]);
  const [layer, setLayer] = useState([]);
  const [viewport, setViewport] = useState({
    latitude: dataFiltered?.[0]?.latitude || 48.866667,
    longitude: dataFiltered?.[0]?.longitude || 2.333333,
    zoom: 7.5,
    bearing: 0,
    pitch: 0,
  });
  useEffect(() => {
    setSelectedPin?.(selectedMarker);
  }, [selectedMarker]);

  useEffect(()=>{
    if(selectedPin){
      setSelectedMarker(selectedPin)
    }
  },[])


  useEffect(()=>{
    setViewport({
      longitude: rtTs?.[0]?.longitude,
      latitude: rtTs?.[0]?.latitude,
      zoom: 15
    })
  },[])
  let zoom = (arr) => {
    let mean = arr.reduce((acc, curr) => {
      return acc + curr
    }, 0) / arr.length

    // Assigning (value - mean) ^ 2 to every array item
    arr = arr.map((k) => {
      return (k - mean) ** 2
    })
    // Clculating the sum of updated array
    let sum = arr.reduce((acc, curr) => acc + curr, 0);
    // Returning the Standered deviation
    return Math.sqrt(sum / arr.length)
  }
  const mean = (arr) => {
    let mean_provide = arr.reduce((acc, curr) => {
      return acc + curr
    }, 0) / arr.length

    return mean_provide
  }

  const { t } = useTranslation(["henriTrip"])

  useEffect(() => {
    getRoute();
  }, [dataFiltered]);

  useEffect(() => {
    function handleResize() {
      setWidthState(window.innerWidth)
    }
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize)
  }, []);
  useEffect(() => {
    const temp = []
    let counter = 1
    if (rtTs) {
        rtTs.sort((a, b) => a.rank - b.rank)
        rtTs.forEach(e => {
          temp.push(
            {
              ...e,
              rank: counter
            }
          )
          counter++
        })
      setDataFiltered(temp)
    }
  }, [rtTs]);

  async function getRoute() {
    if (dataFiltered?.length === 0) {
      let latLong = "2.213749,46.227638;2.213749,46.227638"
      const query = await fetch(
        `https://api.mapbox.com/directions/v5/mapbox/cycling/${latLong}?geometries=geojson&access_token=${process.env.REACT_APP_MAPBOX_ACCESS_TOKEN}`,
        { method: "GET" }
      );


      const json = await query.json();
      const data = json?.routes[0];
      const route = data.geometry.coordinates;
      const geojson = {
        features: [
          {
            type: "Feature",
            properties: {},
            geometry: {
              type: "LineString",
              coordinates: route,
            },

          },
        ],
        type: "FeatureCollection",
      };
      setSource(geojson);
      setLayer([
        {
          id: "route",
          type: "line",
          source: {
            type: "geojson",
            data: geojson,
          },
          layout: {
            "line-join": "round",
            "line-cap": "round",
          },
          paint: {
            "line-color": "#3887be",
            "line-width": 5,
            "line-opacity": 0.75,
          },
        },
      ]);
    }
    if (dataFiltered?.length === 1) {
      const arr_zoom = []
      let bounds = [];
      dataFiltered?.forEach((element) => {
        arr_zoom.push(element.longitude)
        arr_zoom.push(element.latitude)
        bounds.push([element.longitude, element.latitude].toString());
        bounds.push([element.longitude, element.latitude].toString());
      });
      let latLong = bounds.join(";");
      const query = await fetch(
        `https://api.mapbox.com/directions/v5/mapbox/cycling/${latLong}?geometries=geojson&access_token=${process.env.REACT_APP_MAPBOX_ACCESS_TOKEN}`,
        { method: "GET" }
      );
      const test = zoom(arr_zoom) - 10
      setViewport({ latitude: dataFiltered[0].latitude, longitude: dataFiltered[0].longitude, zoom: test })

      // setViewPort({
      //     latitude: dataFiltered[0]?.latitude || 46.227638,
      //     longitude: dataFiltered[0]?.longitude || 2.213749,
      //     zoom: zoom,
      //     bearing: 0,
      //     pitch: 0,
      // })

      const json = await query.json();
      const data = json?.routes[0];
      const route = data.geometry.coordinates;

      const geojson = {
        features: [
          {
            type: "Feature",
            properties: {},
            geometry: {
              type: "LineString",
              coordinates: route,
            },

          },
        ],
        type: "FeatureCollection",
      };

      setSource(geojson);
      setLayer([
        {
          id: "route",
          type: "line",
          source: {
            type: "geojson",
            data: geojson,
          },
          layout: {
            "line-join": "round",
            "line-cap": "round",
          },
          paint: {
            "line-color": "#3887be",
            "line-width": 5,
            "line-opacity": 0.75,
          },
        },
      ]);
    }
    if (dataFiltered?.length > 1 && dataFiltered?.length < 25) {
      let bounds = [];
      dataFiltered?.forEach((element) => {
        if (element.longitude && element.latitude){
          bounds.push([element.longitude, element.latitude].toString());
        }
      });
      let latLong = bounds.join(";");
      const query = await fetch(
        `https://api.mapbox.com/directions/v5/mapbox/cycling/${latLong}?geometries=geojson&access_token=${process.env.REACT_APP_MAPBOX_ACCESS_TOKEN}`,
        { method: "GET" }
      );
      const json = await query.json();
      const data = json?.routes?.[0];
      const route = data?.geometry?.coordinates;
      const geojson = {
        features: [
          {
            type: "Feature",
            properties: {},
            geometry: {
              type: "LineString",
              coordinates: route,
            },

          },
        ],
        type: "FeatureCollection",
      };
      setSource(geojson);
      setLayer([
        {
          id: "route",
          type: "line",
          source: {
            type: "geojson",
            data: geojson,
          },
          layout: {
            "line-join": "round",
            "line-cap": "round",
          },
          paint: {
            "line-color": "#3887be",
            "line-width": 5,
            "line-opacity": 0.75,
          },
        },
      ]);
    };

    if (dataFiltered.length > 25) {
      let arr_long = [];
      let arr_lat = [];
      const MidLength = dataFiltered.length/2;
      const first_part = dataFiltered.slice(0, MidLength);
      const last_part = dataFiltered.slice(MidLength);

      const DayProgram = {1: [...first_part], 2: [...last_part]};
      let random = 0;

      let days = Object.keys(DayProgram);
      for await (let day of days) {
        let temp = [];
        DayProgram[day]?.map(e => {
          temp.push([e.longitude, e.latitude].toString())
        });
        const latLong = temp.join(";");
        const query = await fetch(
          `https://api.mapbox.com/directions/v5/mapbox/cycling/${latLong}?geometries=geojson&access_token=${process.env.REACT_APP_MAPBOX_ACCESS_TOKEN}`,
          { method: "GET" }
        );

        const json = await query.json();
        const data = await json?.routes[0];
        const route = data.geometry.coordinates;

        const geojson = {
          features: [
            {
              type: "Feature",
              properties: {},
              geometry: {
                type: "LineString",
                coordinates: route,
              },

            },
          ],
          type: "FeatureCollection",
        };
        if (day == 1) {
          setSource(state => {
            return {
              features: [
                {
                  type: "Feature",
                  properties: {},
                  geometry: {
                    type: "LineString",
                    coordinates: route,
                  },

                },
              ],
              type: "FeatureCollection",
            }
          })
        }
        else {
          setSource(state => {
            return {
              features: [
                {
                  type: "Feature",
                  properties: {},
                  geometry: {
                    type: "LineString",
                    coordinates: [...state?.features[0].geometry.coordinates, ...route],
                  },

                },
              ],
              type: "FeatureCollection",
            }
          })
        };
        setLayer(state => [...state, ...[
          {
            id: `route`,
            type: "line",
            source: {
              type: "geojson",
              data: geojson,
            },
            layout: {
              "line-join": "round",
              "line-cap": "round",
            },
            paint: {
              "line-color": "#3887be",
              "line-width": 5,
              "line-opacity": 0.75,
            },
          },
        ]]);
        random++
        day++
      };
    };
  };

  const [open, setOpen] = useState(false);

  const dayOptions = [
    {
      key: "tout",
      text: t("general.viewAll"),
      value: "tout",
      content: t("general.viewAll")
    },
    ...days.map(e => ({
      key: e,
      text: e + ' ' + t("roadTripPage.day"),
      value: e,
      content: e + ' ' + t("roadTripPage.day")
    }))
  ];
  const handleChange = (event) => {

    if (event === 'tout') {
      const temp = []
      let counter = 1
      const sites = _.groupBy(rtTs, "day_number");
      const days = Object.keys(sites);
      days.forEach(day => {
        sites[day].sort((a, b) => a.rank - b.rank)
        sites[day].forEach(e => {
          temp.push(
            {
              ...e,
              rank: counter
            }
          )
          counter++
        })
      })
      setDataFiltered(temp)
    }
    else {
      setDataFiltered(rtTs.filter(e => e.day_number === event).sort((a, b) => a.rank - b.rank))
    }
  };


  if (!source || !layer || layer.length === 0) {
    return null/**Ajouter un Loader */
  };


  return (
      <div style={{  height: `${!mapModalIsOpen ? "400px" : "100%"}` }} className="ts-card-body" onClick={ClickAway}>
        <ModalForm
          isOpen={open}
          title={t("register.title")}
          onClose={() => setOpen(false)}
        >
        <Login modal={true} handleSubmit={() => setOpen(false)} handleClose={() => setOpen(false)} />
      </ModalForm>
      {

        dataFiltered.length === 0 ?
          <MapGL
            ref={ref}
            mapboxApiAccessToken={process.env.REACT_APP_MAPBOX_ACCESS_TOKEN}
            {...viewport}
            mapStyle={"mapbox://styles/mapbox/streets-v9"}
            onViewportChange={(viewport) => setViewport({ ...viewport })}
            width="100%"
            height="100%"
            scrollZoom={openMap}
          >
            {
              btndisable === false &&
              <>

                <Button
                  icon
                  className="rounded-circle"
                  onClick={onMapClick}>
                  <Icon name='close' />
                </Button>
                <Dropdown
                  style={filterControlStyle}
                  button
                  className="icon col-sm-12 col-md-12 mt-md-2 m-0"
                  placeholder={t("general.days")}
                  icon="calendar"
                  labeled
                  options={dayOptions}
                  onChange={(event, data) => handleChange(data?.value ?? 0)}
                  settings={{ useLabels: false }}
                />

                <Button
                  icon
                  className="rounded-circle"
                  onClick={onMapClick}>
                  <Icon name='close' />
                </Button>
                <Dropdown
                  style={filterControlStyle}
                  button
                  className="icon col-sm-12 col-md-12 mt-md-2 m-0"
                  placeholder={t("general.days")}
                  icon="calendar"
                  labeled
                  options={dayOptions}
                  onChange={(event, data) => handleChange(data?.value ?? 0)}
                  settings={{ useLabels: false }}
                />


                <GeolocateControl
                  className="geolocateControlRTMapStyle"
                  label={"ma position"}
                  disabledLabel={"disabledLocalisation"}
                  positionOptions={{ enableHighAccuracy: true }}
                  trackUserLocation={true}
                  showAccuracyCircle={true}
                />
                <NavigationControl
                  className="zoomControlStyle" />

              </>
            }
          </MapGL>
          :
          <>
            {/* Pour avoir le theme dark de la map il faut remplacer dans le mapstyle : mapbox://styles/mapbox/dark-v10 */}
            <MapGL
              ref={ref}
              mapboxApiAccessToken={process.env.REACT_APP_MAPBOX_ACCESS_TOKEN}
              {...viewport}
              mapStyle={"mapbox://styles/mapbox/light-v10"}
              onViewportChange={(viewport) => scrollable && setViewport({ ...viewport })}
              width="100%"
              height={widthState <= PHONE_WIDTH ? "90%" : "100%"}
              interactive={popup === null}
              scrollZoom={openMap}
              className="map-gl-canvas"
            >
              <div style={{height: "100%", width: "100%", cursor: `${scrollable === false ?  "default" : "grab" }`}}>
              {
                openMapFull &&
                <Button
                  icon
                  className="icon p-0 m-0"
                  onClick={() => setOpenMapFull(false)}>
                  <Dezoom />
                </Button>
              }

              {
                mapModalIsOpen !== true && scrollable &&
                <Button
                  icon
                  className="icon p-0 m-0"
                  style={filterLoopStyle }
                  onClick={(event) => {
                    setMapModalIsOpen(true);
                    event.stopPropagation();
                  }}
                >
                  <FullscreenIcon width="25" height="25" color='white' />
                </Button>
              }
              <Source id="my-data" type="geojson" data={source}>
                {layer?.map(({ source, ...e }, i) => (
                  <Layer key={i} {...e} source="my-data" />
                ))}
              </Source>
                <GeolocateControl
                  positionOptions={{ enableHighAccuracy: true }}
                  trackUserLocation={true}
                  style={{position: 'absolute', right: '5%', bottom: '10%'}}
                />

                <div>
                  {dataFiltered?.map((e) => (
                    <Marker
                      key={`${e.id}_${e.rank}`}
                      longitude={e.longitude}
                      latitude={e.latitude}
                      offsetLeft={-15}
                      offsetTop={-20}
                    > {
                        e.id === selectedMarker ?
                          <div className="SVG" onClick={(event) => scrollable ? _onClick(event, e) : event.preventDefault}>
                            <div className="layout position-relative">
                              <div className="svg-inline">
                                {/* <PineMarker height="25" width="25" color={e.category.color} /> */}
                                <span className="position-absolute font-weight-bold" style={{
                                  top: '7px',
                                  right: '20px',
                                  color: `${e.ts_type === "custom" ? e?.category?.color : e?.categories[0]?.color}`
                                }}>
                                      {e.rank.toString().padStart(2, '0')}
                                </span>
                                <PineSelect height="50" width="50" color={e.ts_type === "custom" ? e?.category?.color : e?.categories[0]?.color || "initial"} />
                              </div>
                            </div>
                          </div>
                          :
                          <div className="SVG" onClick={(event) => scrollable ?  _onClick(event, e , dataFiltered) : event.preventDefault()}>
                            <div className="layout position-relative">
                              <div className="svg-inline">
                                {/* <PineMarker height="25" width="25" color={e.category.color} /> */}
                                <span className="position-absolute font-weight-bold" style={numberMarkerStyle}>
                                      {e.rank.toString().padStart(2, '0')}
                                </span>
                                <PineMarker height="40" width="40" color={e.ts_type === "custom" ? e?.category?.color : e?.categories[0]?.color} />
                              </div>
                            </div>
                          </div>
                      }
                    </Marker>
                  ))}
                  {popup ?
                    <RoadTripPopupPremium setMapModalIsOpen={setMapModalIsOpen} allData={dataFiltered} setActivityModalIsOpen={setActivityModalIsOpen} openMapFull={openMapFull} openMap={openMap} data={popup} categories={categories} setPopup={setPopup} handlePurchase={handlePurchase} />
                    :
                    <></>
                  }
                </div>
              </div>
            </MapGL>
          </>
      }
    </div>

  );
};
// eslint-disable-line react-hooks/exhaustive-deps
export default ExperienceMap;
