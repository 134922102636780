import React, { useEffect, useRef, useState } from "react";
import { Create as CreateIcon } from "@material-ui/icons";
import { SvgIcon } from "@material-ui/core";
import { Button } from "../button";
import "./fileInput.scss";
import Compressor from 'compressorjs';
import '../../../pages/UserPage/ExperiencePage/ExperiencePage.scss'
const ListFileElement = ({ file, onClickHandler }) => {
  const [loading, setLoading] = useState(true)
  const [thumb, setThumb] = useState(undefined)
  const [fileExtension, setFileExtension] = useState(undefined)

  useEffect(() => {
    if (!file) { return; }

    setFileExtension(file.name.match(/\.[0-9a-z]+$/i))
    if (file.type.match('image/*')) {
      let reader = new FileReader();
      reader.onloadend = () => {
        setThumb(reader.result)
        setLoading(false)
      };
      reader.readAsDataURL(file);
    }
    else {
      setLoading(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (!file) { return null; }

  if (loading) { return <p>loading...</p>; }

  return (
    <div className="d-flex justify-content-between align-items-center my-1 px-lg-3">
      {
        thumb ?
          <img src={thumb} alt={file.name} height={50} width={50} />
          :
          <div style={{ height: 50, width: 50, backgroundColor: '#efefef' }} className="d-flex justify-content-center align-items-center">
            <span className="fw-bold text-black">{fileExtension}</span>
          </div>
      }
      <div className="w-75 text-truncate"><span>{file.name}</span></div>
      <button className="btn btn-outline-none btn-danger" type="button" onClick={() => onClickHandler(file.name)}>X</button>
    </div>
  )
}

const DropZone = ({ srcDefault, filesState, onFileChange, accepts, imgClass }) => {
  const inputRef = useRef()
  const dropZoneRef = useRef()
  const [bg, setBG] = useState(srcDefault)

  useEffect(() => {
    if (filesState.length > 0) {
      setBG(URL.createObjectURL(filesState[0]))
    }
  }, [filesState])

  return (
    <div ref={dropZoneRef} className={`dropZone w-100 h-100 d-flex justify-content-center align-items-center position-relative ${imgClass} mt-sm-3 overflow-hidden experience-header-page`}>
      <img
        className={imgClass || ''}
        width={"100%"}
        height={"100%"}
        src={bg}
        alt={bg}
        onError={() => setBG("https://general-henri-1-paris.s3.fr-par.scw.cloud/woman.jpg")}
      />
      <input ref={inputRef} style={{ display: 'none' }} id="file" name="file" type="file" onChange={onFileChange} accept={accepts} />
      <button type="button" onClick={() => inputRef.current.click()} className="dropZone__btn position-absolute d-flex justify-content-center align-items-center">
        <SvgIcon component={CreateIcon} width="20" height="20" />
      </button>
    </div>
  )
}


const FileInput = ({ dropZone, srcDefault, imgClass, accepts, maxSize, buttonStyle, name, setFieldValue, isMulti, parentClasses, label, required, labelSuffix, helper, errors }) => {
  const [filesState, setFilesState] = React.useState([])
  const inputRef = useRef()

  useEffect(() => {
    //filesState.forEach((f) => console.log(f))
    setFieldValue(name, filesState);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filesState])

  const compressFile = (image, quality) => {
    if (quality < 0.6) {
      return
    }
    if (image.size <= maxSize) {
      setFilesState(state => [
        ...state,
        image
      ])
    }
    else {
      new Compressor(image, {
        quality: quality || NaN,
        convertTypes: 'image/png',
        convertSize: 1000000,
        maxHeight: 1080,
        success: (compressedResult) => {
          const q = quality ? (quality - 0.1) : 0.9
          compressFile(compressedResult, q)
        },
      });
    }
  }

  const onFileChange = (event) => {
    const validFiles = [...event.currentTarget.files].filter(file => {
      if (accepts.includes(file.type) && !filesState.some(file1 => file1.name === file.name) && (file.size > maxSize)) {
        compressFile(file)
      }
      return accepts.includes(file.type) && !filesState.some(file1 => file1.name === file.name) && (file.size <= maxSize)
    })
    if (isMulti) {
      setFilesState(state => [
        ...state,
        ...validFiles
      ])
    }
    else {
      setFilesState(validFiles)
    }
  }

  const onDeleteHandler = (key) => {
    const filesStateClone = [...filesState].filter(file => file.name !== key);
    setFilesState(filesStateClone)
  }

  return (
    (dropZone) ?
      <DropZone filesState={filesState} srcDefault={srcDefault} onFileChange={onFileChange} accept={accepts} imgClass={imgClass} />
      :
      <div
        className={`form-group fv-plugins-icon-container ${parentClasses}`}
      >
        {label && (
          <div className="d-flex justify-content-between mt-n5">
            <label className="font-size-h6 text-dark pt-5">
              {label}
              {required ? " *" : ""}
            </label>
            {labelSuffix}
          </div>
        )}
        <div className="d-flex flex-column">
          {/*<span className="mb-1">{`Taille maximale pour les nouveaux fichiers : ${maxSize/1000000}Mo`}</span>*/}
          <input ref={inputRef} style={{ display: 'none' }} id="file" name="file" type="file" onChange={onFileChange} accept={accepts} multiple={isMulti || false} />
          <Button {...buttonStyle} onClick={() => inputRef.current.click()} />
          <div>
            {
              filesState.map((el) => {
                return <ListFileElement file={el} key={el.name} onClickHandler={onDeleteHandler} />
              })
            }
          </div>
        </div>
        {
          helper &&
          <p className="m-0 text-muted">{helper}</p>
        }
        {errors[name] ? (
          <div className="fv-plugins-message-container">
            <div className="fv-help-block text-danger">
              {errors[name]}
            </div>
          </div>
        ) : null}
      </div>
  );
};

export default FileInput;
