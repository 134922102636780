import React, { useState } from 'react';
import styled from "styled-components";
import breakpoint from "styled-components-breakpoint";

import { ThemeProvider } from "styled-components";
import theme from "_ui/_v2/styles/theme";
import GlobalStyle from '_ui/_v2/styles/global_style';
import { Link, useHistory } from 'react-router-dom/cjs/react-router-dom';

//import icons
import { ReactComponent as LensIcon } from "assets/img/logo/lens-icon.svg"
import { ReactComponent as HomeIcon } from "assets/img/logo/home-icon.svg"
import { ReactComponent as ProfileIcon } from "assets/img/logo/profile2-icon.svg"
import { ReactComponent as RoadTripIcon } from "assets/img/logo/Picto_voyage.svg"

//traduction function
import { useTranslation } from "react-i18next";

import CodePopup from 'components/_v2/modals/CodePopup';

const StickyBottomNavBarStyled = styled.nav`
  display: block;
  position: fixed;
  bottom: 0;
  background-color:white;
  width: 100%;
  min-height: 60px;
  z-index: 100;
  
  ${breakpoint('md')`
    display: none;
  `}
  
  ul {
    list-style: none;
    margin: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    
    li {
      margin: 0;
      flex-grow: 1;
      text-align: center;
      padding: 10px;
      cursor: pointer;
      font-weight: 700;
      font-size: 11px;
      
      svg {
        color : ${props => props.theme.colors.brand};

      }
      a {
        color: ${props => props.theme.colors.text};

      }
    }
  }
`;

const NavBarBottomV2 = () => {
  const { t } = useTranslation(["henriTrip"]);
  const [isOpen, setIsOpen] = useState(false);
  const history = useHistory();

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <StickyBottomNavBarStyled>
        <ul>
          <li>
            <Link to="/">
              <HomeIcon
                style=
                {{
                  width: "25px",
                  paddingBottom: "5px"
                }}
              />
              <div>
                {t("navbar.mobileNavbar.home")}
              </div>
            </Link>
          </li>
          <li>
            <Link to="/roadtrips">
              <RoadTripIcon
                style={{
                  width: "25px",
                  maxHeight: "31px",
                  paddingBottom: "5px"
                }}
              />
              <div>
                {t("navbar.mobileNavbar.roadTrip")}
              </div>
            </Link>
          </li>
          <li>
            <div onClick={() => {
              setIsOpen(!isOpen);
            }}>
              <LensIcon
                style={{
                  width: "25px",
                  paddingBottom: "5px"
                }}
              />
              <div>
                {t("navbar.mobileNavbar.explore")}
              </div>
            </div>
          </li>
          <li>
            <Link to="/user/account">
              <ProfileIcon style=
                {{
                  width: "25px",
                  paddingBottom: "5px"
                }}
              />
              <div>
                {t("navbar.mobileNavbar.profile")}
              </div>
            </Link>
          </li>
        </ul>
      </StickyBottomNavBarStyled>
      {isOpen && (
        <CodePopup
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          history={history}
        />
      )}
    </ThemeProvider>
  );
};

export default NavBarBottomV2;




