import { addToken } from 'actions/token';
import { addUser } from 'actions/user';
import useRequest from 'hooks/use-request';
import LoginPage from 'pages/AuthPage/LoginPage';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { API_FACEBOOK_LOGIN, API_LOGIN, API_GOOGLE_LOGIN } from "utils/api";
import LoginModal from "../../components/formModal/LoginModal";
import { useLocation } from "react-router";

const Login = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [handleRequest] = useRequest();
  const [error, setError] = React.useState("");
  const query = new URLSearchParams(useLocation().search)

  const handleSubmit = (v) => {
    handleRequest("post", API_LOGIN, v, {}, (data, status) => {
      if (status === "Done") {
        if (data.errors) {
          setError(data.errors)
          return;
        }
        else if (!data.user) {
          setError("Vous n'êtes pas inscrit sur la plateforme client")
          return;
        }
        dispatch(addToken(data.token));
        const userData = {
          ...data.user,
          ...data.profile,
        };
        dispatch(addUser(userData));

        if (props.modal) {
          props.handleSubmit()
          if(!props.checkout){
            history.go(0)
          }
        } else {
          history.push(query.get("redirectURL") || '/')
        }
      } else {
        console.error(data);
        setError("Vos identifiants sont invalides");
      }
    })
  }

  const handleFacebookSubmit = (v) => {
    const auth = {
      provider: "facebook",
      uid: v.userID,
      data_access_expiration_time: v.data_access_expiration_time,
      code: v.signedRequest,
      token: v.accessToken,
      expires_at: v.expiresIn,
      expires: true
    }

    handleRequest('post', API_FACEBOOK_LOGIN, auth, {}, (data, status) => {
      if (status === "Done") {
        if (data.errors) {
          setError(data.errors)
          return;
        }
        dispatch(addToken(data.token));
        const userData = {
          ...data.user,
          ...data.profile,
        };
        dispatch(addUser(userData));
        if (props.modal) {
          props.handleSubmit()
          if(!props.checkout){
            history.go(0)
          }
        } else {
          history.push(query.get("redirectURL") || '/')
        }
      }
    })
  }

  const handleGoogleSubmit = (v) => {


    const auth = {
       
      provider: "google", 
      access_token: v.access_token,
      expires_at:  3000000,
      code: v.code 
    }

handleRequest('post', API_GOOGLE_LOGIN, auth, {}, (data, status) => {
  if (status === "Done") {
    if (data.errors) {
      setError(data.errors)
      return;
    }
    dispatch(addToken(data.token));
    const userData = {
      ...data.user,
      ...data.profile,
    };

    dispatch(addUser(userData));
    if (props.modal) {
      props.handleSubmit()
      history.go(0)
    } else {
      history.push(query.get("redirectURL") || '/')
    }
  }
})
  }
  
  return (
    props.modal ?
      <LoginModal handleSubmit={handleSubmit} handleFacebookSubmit={handleFacebookSubmit} handleGoogleSubmit={handleGoogleSubmit} handleClose={props.handleClose} error={error} />
      :
      <LoginPage handleSubmit={handleSubmit} handleFacebookSubmit={handleFacebookSubmit} handleGoogleSubmit={handleGoogleSubmit} error={error} />
  );
};

export default Login;
