import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

const appData = {
  headers: {
    // "content-type": "multipart/form-data"
  }
};

export default persistReducer({storage, key: "app-auth", whitelist: []}, (state = appData, action) => {
  switch (action.type) {
    case 'ADD_LOGIN_TOKEN':
      if (localStorage.getItem('Authorization') !== action.payload) {
        localStorage.setItem('Authorization', action.payload);
      }
      return {
        headers: {
          // "content-type": "multipart/form-data",
          'Authorization': "Token " + action.payload,
        }
      };
    case 'REMOVE_LOGIN_TOKEN':
      localStorage.removeItem("Authorization");
      return {
        headers: {}
      }
    default:
      if(localStorage.getItem('Authorization')){
        return {
          headers: {
            // "content-type": "multipart/form-data",
            'Authorization': "Token " + localStorage.getItem('Authorization'),
          }
        };
      }else{
        return state;
      }
  }
})
