import {useContext, useState} from 'react';
import {useFormik} from 'formik';
import {ThemeContext} from 'styled-components';
import {API_PATCH_TOURISTIC_SITE} from '../../../utils/api';
import touristicSiteSchema, {tipSchema} from '../../_schemas/touristic_site_schema';
import Button from '../../../_ui/_v2/components/Button';
import Loader from '../../../_ui/_v2/components/Loader';
import {TextArea, TextInput, Form} from '../../../_ui/_v2/components/FormElements';
import PlusButton from './_commons/PlusButton';
import PlusIcon from './_commons/PlusIcon';
import RemoveIcon from './_commons/RemoveIcon';
import TouristicSiteContext from '../../../_contexts/TouristicSiteContext';
import {useTranslation} from "react-i18next";

const touristicSiteSchemaForTouristicSiteTipsForm = touristicSiteSchema.pick([
  'touristic_site_tips',
]);

const TouristicSiteTipsForm = ({onSuccess, touristicSite = {}}) => {
  const theme = useContext(ThemeContext);
  const [loading, setLoading] = useState(false);
  const { setTouristicSite } = useContext(TouristicSiteContext);
  const {i18n} = useTranslation(["touristic-site"]);

  const handleSubmitForm = (values, actions) => {
    const token = localStorage.getItem("Authorization");

    setLoading(true);
    fetch(
      API_PATCH_TOURISTIC_SITE(touristicSite.id),
      {
        method: 'PATCH',
        cache: 'default',
        body: JSON.stringify(values),
        headers: {
          "Authorization": `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        mode: 'cors',
      }
    )
      .then(res => res.json())
      .then(response => {
        setTouristicSite(response);
        // setLoading(false);
        onSuccess?.(response);
      })
      .catch(error => {
        console.error(error);
        setLoading(false);
      });
  };

  const {
    handleSubmit,
    handleChange,
    handleBlur,
    values,
    errors,
    touched,
    setFieldValue,
    setFieldError,
    setFieldTouched,
  } = useFormik({
    initialValues: touristicSiteSchemaForTouristicSiteTipsForm.cast(touristicSite),
    validationSchema: touristicSiteSchemaForTouristicSiteTipsForm,
    onSubmit: handleSubmitForm,
  });

  const addTip = () => {
    setFieldValue(
      'touristic_site_tips',
      values.touristic_site_tips.concat([tipSchema.cast({})])
    );
  };

  const removeTip = (index) => {
    setFieldValue(
      'touristic_site_tips',
      values.touristic_site_tips.filter((_, tempIndex) => tempIndex !== index)
    );
    errors.touristic_site_tips && setFieldError(
      'touristic_site_tips',
      errors.touristic_site_tips.filter((_, tempIndex) => tempIndex !== index)
    );
    touched.touristic_site_tips && setFieldTouched(
      'touristic_site_tips',
      touched.touristic_site_tips.filter((_, tempIndex) => tempIndex !== index)
    );
  };

  return (
    <Form onSubmit={handleSubmit}>
      {
        values.touristic_site_tips.map((_, index) => (
          <Form
            as="div"
            key={index}
          >
            <div
              style={{
                display: 'grid',
                gridTemplateColumns: 'auto 20px',
                gap: '1em',
              }}
            >
              <TextInput
                type="text"
                name={`touristic_site_tips[${index}][title][text_${i18n.language}]`}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.touristic_site_tips?.[index]?.title?.[`text_${i18n.language}`] || ''}
                hasError={errors.touristic_site_tips?.[index]?.title?.[`text_${i18n.language}`] && touched.touristic_site_tips?.[index]?.title?.[`text_${i18n.language}`]}
                errorMessage={errors.touristic_site_tips?.[index]?.title?.[`text_${i18n.language}`]}
                placeholder={'Titre de l\'astuce'}
                label={`Astuce ${index + 1}`}
              />
              <RemoveIcon
                onClick={() => removeTip(index)}
                style={{
                  margin: 'auto',
                }}
              />
            </div>
            <TextArea
              name={`touristic_site_tips[${index}][description][text_${i18n.language}]`}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.touristic_site_tips?.[index]?.description?.[`text_${i18n.language}`] || ''}
              hasError={errors.touristic_site_tips?.[index]?.description?.[`text_${i18n.language}`] && touched.touristic_site_tips?.[index]?.description?.[`text_${i18n.language}`]}
              errorMessage={errors.touristic_site_tips?.[index]?.description?.[`text_${i18n.language}`]}
              placeholder={'Description'}
            />
            <TextInput
              type="text"
              name={`touristic_site_tips[${index}][link]`}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.touristic_site_tips?.[index]?.link || ''}
              hasError={errors.touristic_site_tips?.[index]?.link && touched.touristic_site_tips?.[index]?.link}
              errorMessage={errors.touristic_site_tips?.[index]?.link}
              placeholder={'Lien'}
            />
          </Form>
        ))
      }
      <PlusButton
        type="button"
        onClick={addTip}
      >
        <PlusIcon/>
      </PlusButton>
      <Button
        rounded
        disabled={loading}
        type="submit"
        style={{
          display: 'block',
          margin: '3em auto 0 auto',
        }}
      >
        {
          loading
            ? <Loader size="1.5em" color={theme.colors.white}/>
            : 'Enregistrer'
        }
      </Button>
    </Form>
  );
};

export default TouristicSiteTipsForm;