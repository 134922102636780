import React, {useEffect, useState} from 'react';
import {Link} from "react-router-dom";
import Button from "../../../components/form/button/button";


const PartnerSectionsNavbar = () => {
    const [widthState, setWidthState] = useState(window.innerWidth)

    useEffect(() => {
        function handleResize() {
            setWidthState(window.innerWidth)
        }
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize)
    }, []);

    return (
        <>
            { widthState > 993 ?
                <nav className="navbar navbar-expand-lg bg-transparent position-absolute">
                    <div className="container">
                        <Link className="navbar-brand" to="/">
                            <img className="navbar-logo" width="150" src="/assets/logo/Logo_henri-side.svg" alt="logo" />
                        </Link>
                        <div className="collapse navbar-collapse">
                            <ul className="navbar-nav ml-auto mt-2 mt-lg-0 d-flex align-items-center">
                                <li className="nav-item nav-link">
                                    <a className="nav-link text-dark" href="/register">Rejoindre la communauté</a>
                                </li>
                                <li className="nav-item nav-link">
                                    <a className="nav-link text-dark" href="/roadtrips">Road Trip</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </nav>
                :
                <nav className="navbar navbar-expand-lg bg-white">
                    <div className="container">
                        <div className="col ml-1">
                            <Link className="navbar-brand" to="/">
                                <img width="30" src="/assets/logo/Asset1.svg" alt="logoSVG" />
                            </Link>
                        </div>
                        <Button rounded className="px-3" title="Devenir ambassadeur" onClick={() => window.open("https://forms.office.com/r/gh46npYQ6S", '_blank')}/>
                    </div>
                </nav>
            }
        </>
    )
};

export default PartnerSectionsNavbar;
