import { useState, useEffect } from 'react';
import "./categoriesIconsXp.scss"
import { SvgIcon } from "@material-ui/core";
import { Add, DeleteOutlined } from "@material-ui/icons";
const CategoriesIconsXp = ({ selected, name, color, icon, circle, movable, handleClick }) => {

  const [textColor, setTextColor] = useState("white")

  useEffect(() => {
    if (color === "#FFFFFF") {
      setTextColor("black");
    }
    if (color === "#F5E200") {
      setTextColor("black");
    }
  }, [color])


  return (
    circle ?
      <div className="card_container_circle" style={{ backgroundColor: color }}>
        <img src={icon} height="20" width="20" alt="icon" />
      </div>
      :
      movable ?
        <div onClick={() => handleClick(name)}>
          <div className="card_image">
            <img style={{ filter: selected ? "invert(1)" : "invert(0)" }} src={icon} height="35" width="35" alt="icon" />
          </div>

        </div >
        :
        <div className="card_container" style={{ backgroundColor: color }}>
          <div className="card_text" style={{ color: textColor }}>
            {name}
          </div>
          <div className="card_image">
            <img src={icon} height="26" width="26" alt="icon" />
          </div>
        </div>
  );
};

export default CategoriesIconsXp;