import React, {useLayoutEffect, useRef, useState} from "react";
import styled from "styled-components";
import breakpoint from "styled-components-breakpoint";
import StickyBottomNavBar from "../StickyBottomNavBar";
import LinkWrapper from "../LinkWrapper";
import {ReactComponent as HenriSideLogo} from '../../../../assets/img/logo/henri-side-logo.svg';

const StickyNavBarStyled = styled.nav`
  display: none;
  position: fixed;
  top: -${props => props.top}px;
  background-color: ${props => props.theme.colors.white};
  width: 100%;
  padding: 0.2rem 6rem;
  opacity: ${props => props.opacity};
  
  ${breakpoint('md')`
    display: block;
    top: ${props => props.opacity > 0 ? '0' : -props.top}px;
  `}
`;

const StickyNavBarStyledContainer = styled.div`
  display: flex;
  justify-content: space-between;
  max-width: 1200px;
  margin: 0 auto;
  
  a > svg {
    color: ${props => props.theme.colors.brand};
  }

  ul {
    list-style: none;
    margin: 0;
    display: flex;
    flex-direction: row;
    justify-content: right;

    li {
      margin: 0;
      text-align: center;
      padding: 10px 20px;
      cursor: pointer;
      font-weight: 700;
      font-size: 11px;

      a {
        color: ${props => props.theme.colors.darkGrey};

        svg {
          color: ${props => props.theme.colors.darkGrey};
        }
      }
    }
  }
`;

const StickyNavBar = (
  {
    withOffsetTop = false,
    onPageScroll = () => {},
    bottomBarAppearOnScroll = false,
    entries = [],
    children
  }
) => {
  const navBarRef = useRef();
  const [offsetHeight, setOffsetHeight] = useState(0);
  const [opacity, setOpacity] = useState(bottomBarAppearOnScroll ? 0 : 1);

  useLayoutEffect(() => {
    const observer = new ResizeObserver(() => {
      setOffsetHeight(navBarRef.current?.offsetHeight);
    });
    observer.observe(navBarRef.current);

    return () => observer.disconnect();
  }, [navBarRef]);

  return (
    <>
      <StickyBottomNavBar
        entries={entries}
        topGap={bottomBarAppearOnScroll ? 0 : offsetHeight}
        top={offsetHeight}
        onScroll={event => {
          const divider = Math.max(offsetHeight * 12, 1);
          setOpacity(Math.min(event.target.scrollTop / divider, 1));
          onPageScroll(event);
        }}
        bottomBarAppearOnScroll={bottomBarAppearOnScroll}
      >
        {children}
      </StickyBottomNavBar>
      <StickyNavBarStyled
        ref={navBarRef}
        opacity={opacity}
      >
        <StickyNavBarStyledContainer>
          <LinkWrapper
            to="/"
            style={{display: 'flex'}}
          >
            <HenriSideLogo
              height="45px"
              width="188px"
              style={{margin: 'auto 0'}}
            />
          </LinkWrapper>
          <ul>
            {
              entries.map(({Icon, label, to, href, onClick, ...rest}, index) => (
                <li
                  key={index}
                  onClick={event => onClick?.(event)}
                >
                  <LinkWrapper
                    to={to}
                    href={href}
                    {...rest}
                  >
                    <Icon
                      style={{
                        display: 'block',
                        margin: '0 auto',
                        width: 35,
                        height: 35
                      }}
                    />
                  </LinkWrapper>
                </li>
              ))
            }
          </ul>
        </StickyNavBarStyledContainer>
      </StickyNavBarStyled>
    </>
  );
};

export default StickyNavBar;