import styled from "styled-components";
import React, {useEffect, useState} from "react";
import breakpoint from "styled-components-breakpoint";

const ToolTipStyled = styled.div `
  cursor: pointer;
  margin: 0 0 0 1em;
  display: none;
  position: absolute;
  
  > p {
    visibility: hidden;
    background-color: ${props => props.theme.colors.primary};
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 7px;
    font-size: 0.8em;
    text-align: center;
  }
  ${breakpoint('md')`
    display: block;
  `}
`;

const HoveredDivStyled = styled.div `
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const SVGstyled = styled.div `
  cursor: pointer;
 
  :hover { 
    + div > p {
      visibility: visible;
    }
  }
`;

const ToolTip = ({dataToShow, elementToClick, text = ['Copier' , 'Collé !'], redirect=false}) => {
  const [copy, setCopy] = useState(false);
  const copyElement = (text) => {
    redirect ? (window.open(`${text}`, '_blank')) : navigator.clipboard.writeText(text);
    setCopy(true);
  };

  useEffect(() => {
    setTimeout(() => {
      setCopy(false);
    }, 2000);
  });

  return (
    <HoveredDivStyled>
      <SVGstyled onClick={() => copyElement(dataToShow)}>
        {elementToClick}
      </SVGstyled>
      <ToolTipStyled>
        <p className="copy_button">{!copy ? text[0] : text[1]}</p>
      </ToolTipStyled>
    </HoveredDivStyled>
  );
};

export default ToolTip;