import React, {useState} from 'react';
import { Link } from 'react-router-dom';
import FooterPageElementTitle from "./FooterPageElements/FooterPageElementTitle";
import FooterPageElementLink from "./FooterPageElements/FooterPageElementLink";
import { useLocation } from 'react-router-dom';
import './footer.scss'
import {Button} from "../../components/form/button";
import ModalForm from "../../components/formModal/ModalForm";
import { useTranslation } from "react-i18next";

const FooterPage = () => {
    const {t} = useTranslation(["henriTrip"])
    const location = useLocation();
    const [modal, setModal] = useState(false);
    const handleCloseModal = () => {
        setModal(false)
    }
    return (
        <div className={`mt-3 bg-dark footer ${location.pathname.includes('search-view') ? 'd-none' : ''}`}>
            <div className="container">
                <div>
                    <div className="d-inline-block col-md-6 col-lg-3 align-top">
                        <FooterPageElementTitle text={t("footer.firstColumn.title")} link="https://support.henritrip.fr/a-propos-dhenri/" />
                        <FooterPageElementLink text={t("footer.firstColumn.henriTeam")} link="https://support.henritrip.fr/henri-et-son-equipe/" />
                        <FooterPageElementLink text={t("footer.firstColumn.henriInvest")} link="https://support.henritrip.fr/relations-investisseurs/"/>
                        <FooterPageElementLink text={t("footer.firstColumn.henriContact")} link="https://support.henritrip.fr/contactez-nous/"/>
                        <FooterPageElementLink text={t("footer.firstColumn.henriPresse")} link="https://support.henritrip.fr/presse/"/>
                        <a href="https://facebook.com/trip.Henri/" target="_blank" rel="noreferrer">
                            <img src="https://general-henri-1-paris.s3.fr-par.scw.cloud/facebook.png" alt="facebook image" className="logo"/>
                        </a>
                        <a href="https://www.instagram.com/henri_trip/" target="_blank" rel="noreferrer">
                            <img src="https://general-henri-1-paris.s3.fr-par.scw.cloud/instagram.png" alt="instagram image" className="logo"/>
                        </a>
                        <a href="https://www.tiktok.com/@henri_trip/" target="_blank" rel="noreferrer">
                            <img src="https://general-henri-1-paris.s3.fr-par.scw.cloud/tiktok.png" alt="tiktok image" className="logo-tiktok"/>
                        </a>
                        <a href="https://www.linkedin.com/company/henri-trip/" target="_blank" rel="noreferrer">
                            <img src="https://general-henri-1-paris.s3.fr-par.scw.cloud/linkdin.png" alt="linkdin image" className="logo"/>
                        </a>
                        <a href="https://www.pinterest.fr/henri_trip/" target="_blank" rel="noreferrer">
                            <img src="https://general-henri-1-paris.s3.fr-par.scw.cloud/pinterest.png" alt="pinterest image" className="logo"/>
                        </a>
                        <a href="https://blog.henritrip.fr/" target="_blank" rel="noreferrer">
                            <img src="https://general-henri-1-paris.s3.fr-par.scw.cloud/blog.png" alt="blog image" className="logo"/>
                        </a>

                    </div>
                    <div className="d-inline-block col-md-6 col-lg-3 align-top">
                        <FooterPageElementTitle text={t("footer.secondColumn.title")} link="https://support.henritrip.fr/ressources-et-reglements/"/>
                        <FooterPageElementLink text={t("footer.secondColumn.content")} link="https://support.henritrip.fr/notre-contenu/"/>
                        <FooterPageElementLink text={t("general.confidentiality")} link="https://support.henritrip.fr/politique-de-confidentialite/"/>
                        <FooterPageElementLink text={t("footer.secondColumn.CGU")} link="https://support.henritrip.fr/conditions-generales-dutilisation-du-site-internet-henri-trip-et-notifications/"/>
                        <FooterPageElementLink text={t("footer.secondColumn.integrité")} link="https://support.henritrip.fr/politique-dintegrite-des-contenus/"/>
                        <FooterPageElementLink text={t("footer.secondColumn.cookie")} link="https://support.henritrip.fr/charte-sur-les-cookies/" />
                    </div>
                    <div className="d-inline-block col-md-6 col-lg-3 align-top">
                        <FooterPageElementTitle text={t("footer.thirdColumn.title")} link="https://support.henritrip.fr/ecrire-un-avis/" />
                        <FooterPageElementLink text={t("footer.thirdColumn.addPlace")} link="/touristic-sites" />
                        <FooterPageElementLink text={t("general.register")} link={`/register?redirectURL=${location.pathname}`}/>
                        <FooterPageElementLink text={t("footer.thirdColumn.support")} link="https://support.henritrip.fr/assistance/" />
                        <Button color="primary" className="donation-button my-1 ml-2" onClick={()=>setModal(true)}>
                            <img src="https://general-henri-1-paris.s3.fr-par.scw.cloud/certif_don.png" alt="donation icon" width="20" height="20"/>
                            <p>{t("footer.thirdColumn.donate.title")}</p>
                        </Button>
                    </div>
                    <div className="d-inline-block col-md-6 col-lg-3 align-top">
                        <FooterPageElementTitle text={t("footer.fourthColumn.title")} link="https://support.henritrip.fr/espace-recrutement/"/>
                        <FooterPageElementLink text={t("footer.fourthColumn.fiability")} link="https://support.henritrip.fr/fiabilite-du-contenu/"/>
                        <FooterPageElementLink text={t("footer.fourthColumn.touristicOperator")} link="https://support.henritrip.fr/operateurs-touristiques/"/>
                        <FooterPageElementLink text={t("footer.fourthColumn.ads")} link="https://support.henritrip.fr/faites-votre-publicite-avec-nous/"/>
                        <FooterPageElementLink text={t("footer.fourthColumn.takeControl")} link="https://support.henritrip.fr/prenez-le-controle-de-votre-page/" />
                        <FooterPageElementLink text={t("footer.fourthColumn.jooble")} link="https://fr.jooble.org/emploi-guide-accompagnateur-touristes" />
                    </div>
                </div>
                <div className="d-flex d-md-none align-items-center justify-content-between">
                    <Link to="#" className="m-0 text-white w-100 h-100 my-1 px-2 d-flex justify-content-center"><small>© 2021 Henri Trip, Inc.</small></Link>
                </div>
            </div>
            <ModalForm
              isOpen={modal}
              onClose={handleCloseModal}
              title={t("footer.thirdColumn.donate.title")}
            >
                <div className="p-2">
                    <div className="d-flex flex-column align-items-center">
                        <img src="https://general-henri-1-paris.s3.fr-par.scw.cloud/certif_don.png" alt="donation icon" width="50" height="50"/>
                        <h4 className="text-center font-weight-bold py-2">{t("footer.thirdColumn.donate.subtitle")}</h4>
                    </div>
                    <div className="d-flex justify-content-between row">
                        <a href="https://buy.stripe.com/9AQ7vBdtD5q861a9AC" target="_blank" rel="noreferrer" className="col">
                            <div className="text-white w-100 h-100 d-flex flex-column align-items-center donation-card">
                                <h4 className="text-center font-weight-bold px-3 py-3">{t("footer.thirdColumn.donate.firstCard.title")}</h4>
                                <img src="https://general-henri-1-paris.s3.fr-par.scw.cloud/bulle_coeur.png" alt="donation icon" width="50" height="50" className="mt-auto"/>
                                <h1 className="text-center font-weight-bold py-2">29,99€</h1>
                            </div>
                        </a>
                        <a href="https://buy.stripe.com/9AQaHNdtD05O75edQR" target="_blank" rel="noreferrer" className="col">
                            <div className="text-white w-100 h-100 d-flex flex-column align-items-center donation-card">
                                <h4 className="text-center font-weight-bold px-3 py-2">{t("footer.thirdColumn.donate.secondCard.title")}</h4>
                                <img src="https://general-henri-1-paris.s3.fr-par.scw.cloud/bulle_coeur.png" alt="donation icon" width="80" height="80" className="mt-auto"/>
                                <h1 className="text-center font-weight-bold py-3">89,99€</h1>
                            </div>
                        </a>
                    </div>
                </div>
            </ModalForm>
        </div>
    );
};

export default FooterPage;
