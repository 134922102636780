import styled from "styled-components";
import { ReactComponent as CameraIcon } from '../../../assets/img/logo/Activity/icon_camera.svg';
import { ReactComponent as VideoIcon } from '../../../assets/img/logo/Activity/icon_video.svg';
import { ReactComponent as AnnotationIcon } from '../../../assets/img/logo/Activity/icon_annotation.svg';
import { ReactComponent as HeadphonesIcon } from '../../../assets/img/logo/Activity/icon_headphones.svg';
import { ReactComponent as TextIcon } from '../../../assets/img/logo/Activity/icon_text.svg';

import React from 'react';
import useTouristicSiteTranslation from "../../../hooks/touristic_sites/useTouristicSiteTranslation";
import {FormikVideo} from "../../../_forms/_components/avpmBuilder/FormikVideo";
import FormWrapper from "../../../_forms/_components/avpmBuilder/FormWrapper";
import breakpoint from "styled-components-breakpoint";

const isAvailable = (element) => ![undefined, null].includes(element);

const IconWrapper = styled.div`
  display: inline-block;
  svg {
    color: ${props => props.disabled ? props.theme.colors.black : props.theme.colors.white};
  }
`;

const MediaTextTitle = ({site}) => {
  const { title } = useTouristicSiteTranslation(site);

  return (
    <IconWrapper
      disabled={!isAvailable(title)}
    >
      <TextIcon
        width="30"
        height="30"
      />
    </IconWrapper>
  );
};

const QuizzContainerStyled = styled.div `
  overflow-y: auto;
  height: max-content;
  max-height: 400px;
  overflow-y: auto;
  text-align: center;
  width: 100%;
  color: ${props => props.theme.colors.white};
  flex-direction: row
  ${breakpoint('md')`
    padding: 5px 12px;
  `}
`;

const TabStyled = styled.div `
  padding: 5px 12px;
  color: ${props => props.theme.colors.white};
  overflow-y: auto;
  height: max-content;
  max-height: 400px;
  overflow-y: auto;
  text-align: center;
  width: 100%;
`;

const mediasModify = (selectedIndex, site, IllustrationComponent, isVisit , editConfig, openMediaModal) => {
  return(
    [
      {
        TitleComponent: <MediaTextTitle site={site}/>,
        ContentComponent: (
          <TabStyled openMediaModal={openMediaModal}>
            <FormWrapper resourceCategory="texts"  editConfig={editConfig} currentObject={site}/>
            {IllustrationComponent}
          </TabStyled>
        ),
      },
      {
        TitleComponent: (
          <IconWrapper>
            <CameraIcon
              width="30"
              height="30"
            />
          </IconWrapper>
        ),
        ContentComponent:
          <TabStyled>
            <FormWrapper resourceCategory="images" editConfig={editConfig} currentObject={site}/>
            {IllustrationComponent}
          </TabStyled>,
      },
      {
        TitleComponent: (
          <IconWrapper
          >
            <VideoIcon
              width="30"
              height="30"
            />
          </IconWrapper>
        ),
        ContentComponent: (
          <TabStyled>
            <FormikVideo editConfig={editConfig} currentObject={site} IllustrationComponent={IllustrationComponent}/>
          </TabStyled>
        ),
      },
      {
        TitleComponent: (
          <IconWrapper>
            <HeadphonesIcon
              width="30"
              height="30"
            />
          </IconWrapper>
        ),
        ContentComponent: (
          <TabStyled style={{justifyContent: 'center'}}>
            <FormWrapper resourceCategory="audios" editConfig={editConfig} currentObject={site}/>
          </TabStyled>
        ),
      },
      {
        TitleComponent: (
          <IconWrapper>
            <AnnotationIcon
              width="30"
              height="30"
              color='white'
            />
          </IconWrapper>
        ),
        ContentComponent: (
          <QuizzContainerStyled>
            <FormWrapper resourceCategory="quizzes" editConfig={editConfig} currentObject={site}/>
            {IllustrationComponent}
          </QuizzContainerStyled>
        ),
      },
    ]
  );
}

export default mediasModify;
